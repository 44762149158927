import React, { Fragment, useEffect, useState } from 'react';
import Layout from '../layout';
import { Link, useHistory } from 'react-router-dom';

// utility
import { connect } from 'react-redux';
import { fetchSearchItem } from '../../../actions/search';

// styles
import { ItemsWeAcceptStyled } from '../../styles/itemsWeAccept.style';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { pick } from 'ramda.pick/pick';
import { Helmet } from 'react-helmet';

const ItemsWeAccept = ({ doFetchSearchItem, category }) => {
  const history = useHistory();
  const [options, optionsHandler] = useState([]);
  const [selected, selectedHandler] = useState(false);
  const [toggle, toggleHandler] = useState(false);
  const [key, keyHandler] = useState('');

  const fireSearch = () => {
    if (selected) {
      //user select
      history.push(
        `/items-we-accept/${selected.slug}/${selected.categorySlug}`
      );
    } else {
      if (options) {
        const _options = options[0];
        console.log('_options', _options);
        if (_options) {
          history.push(
            `/items-we-accept/${_options.slug}/${_options.categorySlug}`
          );
        }
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      doSearch(key);
    }, 300);

    return () => clearTimeout(timeout);

    //eslint-disable-next-line
  }, [key]);

  const doSearch = (key) => {
    if (!isEmpty(key.trim()) && key.trim().length > 2) {
      doFetchSearchItem({ key })
        .then((data) => {
          if (data) {
            optionsHandler(data);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else {
      optionsHandler([]);
    }
  };

  return (
    <>
      <Layout>
        {/* REACT-HELMET FRONT-END META */}
        <Helmet>
          <title>
            Pawnable Items - Items You Can Pawn Quick & Easy | PawnHero
          </title>
        </Helmet>

        <ItemsWeAcceptStyled className='page'>
          <section className='banner'>
            <div>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/items-we-accept-banner.svg'
                alt=''
              />
              <h1>Items We Accept</h1>
              <p>
              Unlike traditional pawnshops, at PawnHero, we accept a wide variety of valuable items such as luxury items
              like designer bags, luxury watches, designer belts, designer sunglasses, designer shoes and more. In addition,
              we also accept an expanded list of items like smartphones, other electronic gadgets like soundbars and speakers,
              gaming consoles, gold jewelry, precious metals, gemstones and more that are already traditionally accepted by pawnshops.
              All of these are acceptable as loan collateral at PawnHero Pawnshop. For a full list of items we accept, see the list below.
              </p>

              <div className='search-wrapper'>
                <div className='search-bar'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-green.svg'
                    alt=''
                  />
                  <input
                    type='text'
                    placeholder='Search for your item you want to pawn'
                    value={key}
                    onChange={(e) => {
                      keyHandler(e.target.value);
                      selectedHandler(false);
                    }}
                    onFocus={() => {
                      toggleHandler(true);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        toggleHandler(false);
                      }, 300);
                    }}
                  />
                  {!isEmpty(options) && (
                    <div
                      className={`option-wrapper ${toggle ? 'open' : 'close'}`}>
                      {options.map((e, i) => (
                        <Fragment key={i}>
                          <div
                            className='option'
                            onClick={() => {
                              keyHandler(
                                `${e.name} ${
                                  e.categoryName ? `(${e.categoryName})` : ``
                                }`
                              );
                              selectedHandler(e);
                            }}>
                            {e.name}{' '}
                            {e.categoryName ? `(${e.categoryName})` : ``}
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
                <button
                  onClick={() => fireSearch()}
                  id={`items_we_accept_btn_search_now`}>
                  Search now
                </button>
              </div>
            </div>
          </section>

          <section>
            <div>
              <div className='card-wrapper'>
                {isEmpty(category) && (
                  <>
                    <div
                      style={{
                        margin: 'auto',
                        display: 'block',
                      }}>
                      <img
                        className='loader'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                        alt='loading'
                        style={{
                          width: '32px',
                          height: '32px',
                          margin: 'auto',
                          display: 'flex',
                        }}
                      />
                    </div>
                  </>
                )}
                {category.map((e, i) => (
                  <Fragment key={i}>
                    <Link
                      id={`items_we_accept_categoy_${e.slug}`}
                      to={`/items-we-accept/${e.slug}`}
                      className='card'>
                      <div className='image-wrapper'>
                        {e.seo_image && e.seo_image !== 'false' && (
                          <img src={e.seo_image} alt={e.category_name} />
                        )}
                      </div>
                      <p>{e.category_name}</p>
                    </Link>
                  </Fragment>
                ))}
              </div>
            </div>
          </section>
        </ItemsWeAcceptStyled>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['category']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSearchItem: (key) => dispatch(fetchSearchItem(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsWeAccept);
