import { handleSuccess, handleError } from './globals';

const axios = require('axios');
const config = require('./axios.config').config;

export const getUserByEmail = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { key } = payload;

    try {
      const response = await axios.get('/api/user/search/' + key, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCurrentUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/user', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCurrentGuestUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/guest', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postEmailVerify = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/user/email/verify`,
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getMyAddress = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/user/address`, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUserProfile = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/user/profile`, payload, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
