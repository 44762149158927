import React from 'react';

// components
import Layout from '../layout';
import WhyPawnhero from '../../components/whyPawnhero';

// style
import { ContainerStyled } from '../../styles/layout.style';

import { Link } from 'react-router-dom';
import { PolicyStyled } from '../../styles/policy.style';

const PrivacyPolicy = () => {
  return (
    <>
      <Layout>
        <PolicyStyled>
          <ContainerStyled>
            <div className='breadcrum'>
              <p>
                <Link to='/'>Home</Link> • <span>Privacy Policy</span>
              </p>
            </div>
            <div className='content-wrapper'>
              <div className='content'>
                <section>
                  <h1>Privacy Policy</h1>
                  <p>
                    Pawnhero Pawnshop Philippines, Inc. ("PawnHero", "us", “our” or "we") is committed to protect your privacy 
                    by ensuring that physical, technical, and organizational measures are in place with every process and 
                    application systems. 
                  </p>

                  <p>
                  This Data Privacy Statement (“Statement”) pronounces how PawnHero 
                  collects, uses, shares, retains, and protects your personal data. 
                  This follows and is consistent with the Data Privacy Act of 2012 (“DPA”), 
                  its Implementing Rules and Regulations, and other issuances of National 
                  Privacy Commission (“NPC”), Bangko Sentral ng Pilipinas (“BSP”), 
                  Anti-Money Laundering Council (“AMLC”) and other government agencies.
                  </p>

                  <p>For the purpose of this Statement, personal data refers to all 
                    types of personal information, sensitive personal information and privileged information.
                  </p>

                  <p>
                  Personal information refers to any information whether recorded in a material form or not, 
                  from which the identity of an individual is apparent or can reasonably and directly be 
                  ascertained by the entity holding the information, or when put together with other 
                  information would directly and certainly identify an individual.
                  </p>

                  <p>
                  Sensitive personal information refers to personal information: (1) About an individual's race, 
                  ethnic origin, marital status, age, color, and religious, philosophical or political affiliations; 
                  (2) About an individual's health, education, genetic or sexual life of a person, or to any proceeding 
                  for any offense committed or alleged to have been committed by such person, the disposal of such proceedings, 
                  or the sentence of any court in such proceedings; (3) Issued by government agencies peculiar to an individual 
                  which includes, but not limited to, social security numbers, previous or current health records, licenses or 
                  its denials, suspension or revocation, and tax returns; and (4) Specifically established by an executive 
                  order or an act of Congress to be kept classified.
                  </p>

                  <p>Privileged information refers to any and all forms of data which under the Rules of 
                    Court and other pertinent laws constitute privileged communication.
                  </p>

                </section>
                <section>
                  <h3>Why does PawnHero collect your personal data?</h3>
                  <p>PawnHero may use your personal data for various purposes, including but not limited to:
                  </p>
                  <p>
                  <ul>
                    <li>Assess, process, and manage your transaction(s) when you avail our service;</li>
                    <li>Customer identification-related activities or customer examination as may be required by law;</li>
                    <li>Send notices necessary for continued use of our service;</li>
                    <li>Address your requests, inquiries, concerns or complaints, and provide service-related support;</li>
                    <li>Comply with legal and regulatory requirements of BSP, AMLC, Securities and Exchange Commission (“SEC”), Bureau of Internal Revenue (“BIR”), and other government agencies;</li>
                    <li>Comply with the requirements of external audit and other audits;</li>
                    <li>Inform you about our new service offerings and promotions; </li>
                    <li>Profiling, data analysis, behavioral and market research, cross-selling and direct marketing; and</li>
                    <li>Perform other activities permitted by law or with your consent</li>
                  </ul>
                  </p>
                </section>
                <section>
                  <h3>What personal data PawnHero collects?</h3>
                  <p>The personal data collected by PawnHero from you, generally may include the following: </p>
                  <p>
                  <ul>
                    <li>Name, present address, permanent address, contact number, email address, gender, date of birth, place of birth and nationality;</li>
                    <li>Source of funds and source of wealth;</li>
                    <li>Employment and/or business information;</li>
                    <li>Financial and payment information;</li>
                    <li>Details of requests, inquiries, concerns or complaints;</li>
                    <li>Specimen signature;</li>
                    <li>Official identification documents such as government-issued IDs, other IDs, birth certificate, and marriage certificate;</li>
                    <li>Images captured via CCTV and other similar recording devices when visiting our offices, and/or using our facilities;</li>
                    <li>Voice recordings of our conversations with you; and</li>
                    <li>Information provided by the electronic device you are using such as the IP address, operating system, browser type and version, and other machine-related identifiers</li>
                  </ul>
                  </p>

                  <p>You are responsible for ensuring that all personal data you provide to PawnHero is accurate, complete and up-to-date.</p>
                  
                </section>
                <section>
                  <h3>How PawnHero collects or generates your personal data?</h3>
                  <p>We collect personal data when you:</p>
                  <p>
                    <ul>
                      <li>Fill-up our forms such as Know-Your-Customer form, acknowledgment receipt, return form, etc.;</li>
                      <li>Visit and/or enter your data in our website (https://pawnhero.ph); and</li>
                      <li>Disclose your personal data through email, SMS, social media platform, phone call, or verbal communication with our authorized representatives</li>
                    </ul>
                  </p>
                </section>
                <section>
                  <h3>Do PawnHero's website uses web analytics?</h3>
                  <p>
                    Our website engages third-party service providers such as Google Analytics 
                  to analyze the web traffic data for us. This service does use cookies or web beacons. 
                  </p>
                  <p>
                  Cookies are small files that our website puts on your personal computer (“PC”) to store 
                  information about your preferences. Cookies can improve your browsing experience by allowing 
                  our website to remember your preferences or letting you avoid signing in 
                  each time you visit our website. If you don't want our website to store cookies on your PC, 
                  you can block cookies. But blocking cookies might prevent some pages from displaying 
                  correctly, or you might get a message from our website letting you know that you 
                  need to allow cookies to view our website. 
                  </p>

                  <p>
                  For you to fully enjoy your visit and browsing experience, only non-identifiable web traffic 
                  data are collected and analyzed, including:
                  </p>

                  <p>
                    <ul>
                      <li>Your internet protocol (“IP”) address;</li>
                      <li>The search terms you used;</li>
                      <li>The pages and internal links accessed on our site;</li>
                      <li>Demographics;</li>
                      <li>The date and time you visited the site, including frequency of visits;</li>
                      <li>Geolocation;</li>
                      <li>Your operating system; and</li>
                      <li>Web browser type</li>
                    </ul>
                  </p>

                  <p>
                  Use the options in your web browser if you do not wish to receive cookies or you wish 
                  to set your browser to notify you when you receive cookies. 
                  Click on the "Help" section of your browser to learn how to change your cookie preferences. 
                  If you disable all cookies, you may not be able to take advantage of all the features of our website.
                  </p>
                </section>
                <section>
                  <h3>Who PawnHero shares your personal data with?</h3>
                  <p>
                    PawnHero may, and when necessary, to cater your needs, seek to share, verify or augment your information with:
                  </p>

                  <p>
                    <ul>
                      <li>Third-party entities, such as auditing firms, service providers, and other financial institutions, for legitimate purposes such as but not limited to external audit, security and other services; and</li>
                      <li>Government agencies, such as but not limited to BSP, AMLC, SEC and BIR, to comply with legal and regulatory requirements</li>
                    </ul>
                  </p>

                  <p>
                    Such data sharing activities shall be done in a manner that is compliant with the DPA and with utmost care and confidentiality.
                  </p>
                  
                </section>
                <section>
                  <h3>How PawnHero protects your personal data?</h3>

                  <p>
                    We take reasonable steps to ensure that the personal data we collect, 
                    use or disclose are accurate, complete, and up-to-date. We strictly 
                    enforce this Statement within PawnHero and we have implemented technological, 
                    organizational and physical security measures to protect your personal data from loss, 
                    misuse, modification, unauthorized or accidental access or disclosure, alteration or 
                    destruction. We restrict access to your personal data only to qualified and authorized 
                    personnel who hold your personal data with strict confidentiality.
                  </p>

                </section>
                <section>
                  <h3>Where and how long PawnHero keeps your personal data?</h3>

                  <p>
                  Your personal data shall be stored in our facilities located in the Philippines, 
                  and cloud servers provided by Amazon Web Services, Inc. It shall be retained for as 
                  long as the purpose for which it was collected, and such other purposes that 
                  you may have consented to from time to time, remains in effect and until such 
                  time as it is no longer required nor necessary to keep your information for any 
                  other legal, regulatory or business purposes.
                  </p>

                </section>
                <section>
                  <h3>What if there are changes in PawnHero's Data Privacy Statement?</h3>
                  <p>
                    PawnHero may revise this Statement as the need arises or when dictated by 
                    issuances of the NPC or any amendment to the DPA. Any change to this Statement 
                    shall be duly posted in this website and will take effect immediately. Data 
                    subjects are encouraged to periodically check for such updates. Changes shall 
                    not be retroactively applied and will not alter how we handle personal data 
                    previously collected without obtaining your consent, unless required by law.
                  </p>
                </section>
                <section>
                  <h3>How to contact PawnHero?</h3>
                  <p>
                  To exercise your rights which include right to access, modify, 
                  erase and object to processing your personal data within a reasonable 
                  time after such request, or should you have any inquiries, feedbacks on this 
                  Statement, and/or concerns to PawnHero, please visit our office or email us at
                   <u> customer@pawnhero.ph.</u>
                  </p>

                  <p>
                  PawnHero may, however, choose not to grant access, modify, erase, or 
                  object to processing your personal data if it will hinder us 
                  to provide the service you avail or if it is contrary with 
                  legal and regulatory requirements. 
                  </p>

                </section>
                <p>This Data Privacy Statement was last amended on 25 AUG 2022.</p>
              </div>
              <WhyPawnhero />
            </div>
          </ContainerStyled>
        </PolicyStyled>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
