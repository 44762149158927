import React, { useState, useEffect } from 'react';
import OTPInput from 'otp-input-react';

const OTPfield = ({ otpHandler, optLength }) => {
  const [otpValue, otpValueHandler] = useState('');

  useEffect(() => {
    otpHandler(otpValue);
  }, [otpHandler, otpValue]);

  const otpCongfig = {
    OTPLength: optLength,
    value: otpValue,
    onChange: otpValueHandler,
    autoFocus: true,
    inputClassName: 'ctm-otp',
    otpType: 'number',
    className: 'otp-wrapper',
  };

  return (
    <>
      <OTPInput {...otpCongfig} />
    </>
  );
};

export default OTPfield;
