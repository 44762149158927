import { Provider as ReduxProvider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from '../App';
import configureStore from './store';
import TagManager from 'react-gtm-module';

import '../client/styles/common.scss';
import 'react-phone-input-2/lib/style.css';

const { REACT_APP_GTM_ID: gtmId } = process.env;
const store = configureStore();
const tagManagerArgs = {
  gtmId,
};
TagManager.initialize(tagManagerArgs);

ReactDOM.hydrate(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);
