import React, { Fragment } from 'react';

// components
import Layout from '../layout';

// style
import { PawnaloPageStyle } from '../../styles/pawnalo.style';
import { ContainerStyled } from '../../styles/layout.style';
import { Link } from 'react-router-dom';
import ShortInfo from '../home/shortInfo';
import { Helmet } from 'react-helmet';

const PawnaloPage = () => {
  const gadgets = [
    {
      title: 'Smartphones',
      image:
        'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gadget-1.svg',
      slug: 'smartphones',
    },
    {
      title: 'Laptops',
      image:
        'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gadget-2.svg',
      slug: 'laptops',
    },
    {
      title: 'Cameras',
      image:
        'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gadget-3.svg',
      slug: 'cameras',
    },
    { 
      title: 'Tablets',
      image:
        'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gadget-4.svg',
      slug: 'tablets',
    },
    {
      title: 'Game Consoles',
      image:
        'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gadget-5.svg',
      slug: 'gaming-console',
    },
    {
      title: 'Soundbars and Speakers',
      image:
        'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gadget-6.svg',
      slug: 'soundbar-and-speakers',
    },
  ];
  return (
    <>
      <Layout>
        <Helmet>
          <title>
            Your Pinoy Online Pawnshop for Gadget - PawnHero - Best Trusted
            Gadget Sanglaan - Smartphone Laptop Camera - Pawn - Sangla - Loan -
            Pawnalo
          </title>
        </Helmet>
        <PawnaloPageStyle>
          <ContainerStyled>
            <div className='breadcrum'>
              <p>
                <Link to='/' id='pawnalo_home_link'>
                  Home
                </Link>{' '}
                • <span>Pawnalo Sa Gadget Appraisal</span>
              </p>
            </div>
          </ContainerStyled>

          <section className='banner'>
            <h1>
              <img
                className='promo-banner'
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo_logo.svg'
                alt='pawnalo'
              />
            </h1>
            <h2>Pawnalo ka sa gadget appraisal ng PawnHero Pawnshop!</h2>
          </section>

          <section className='gadget-we-accept'>
            <ContainerStyled>
              <h2>Gadgets we accept</h2>

              <div className='gadgets-wrapper'>
                <div>
                  {gadgets.map((e, i) => (
                    <Fragment key={i}>
                      <Link
                        className={`gadget `}
                        id={`pawnalo_gadget_${e.slug}`}
                        to={`/pawn/${e.slug}`}>
                        <div className='img-wrapper'>
                          <img alt={e.title} src={e.image} />
                        </div>
                        <p>{e.title}</p>
                      </Link>
                    </Fragment>
                  ))}
                </div>
              </div>

              <Link
                className='btn-pawn-now btn'
                id='pawnalo_pawn_link'
                to='/pawn'>
                Pawn now
              </Link>
            </ContainerStyled>
          </section>

          <section className='content'>
            <ContainerStyled>
              <p>
                We offer up to 20% higher appraisal rate or more with only 4.99%
                interest rate
                <span
                  style={{
                    color: '#ff7019',
                  }}>
                  *
                </span>{' '}
                vs. selected pawn shops. This means PawnHero customers get
                higher loan amounts and enjoy lower interest rates!
              </p>
              <div className='promo-content'>
                <div>
                  <div className='promo-detail-context'>
                    <div>
                      <p>Up to</p>
                      <p className='highlight'>20%</p>
                      <p className='note'>
                        higher appraisal <span>or more</span>
                      </p>
                    </div>
                    <div className='center-elem'>
                      <p>with</p>
                    </div>
                    <div>
                      <p>Only</p>
                      <p className='highlight'>4.99%</p>
                      <p className='note'>
                        lower interest rate<span className='mark'>*</span>
                      </p>
                    </div>
                  </div>
                  <p>vs. selected pawnshops</p>
                </div>
              </div>
              <div className='breakdown-sample-wrapper'>
                <p>Sample breakdown</p>

                <div className='breakdown-wrapper'>
                  <div className='breakdown'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-breakdown-1.svg'
                      alt='Apple iPhone 12 Pro Max 256GB'
                    />
                  </div>
                  <div className='breakdown'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-breakdown-2.svg'
                      alt='Pawnhero, Get as much as ₱24,840, 42% higher appraisal, Only 4.99% interest rate'
                    />
                  </div>
                  <div className='breakdown'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-breakdown-3.svg'
                      alt='Pawnshop X, Get as much as ₱17,433, 42% lower appraisal, 7-10% higher interest rate'
                    />
                  </div>
                </div>

                <p className='disclamer'>
                  <span className='green'>*</span>Based on the data gathered
                  during Q2 of 2021 <br />
                  <span className='green'>*</span>Applies on smartphones,
                  laptops, cameras, and other gadgets
                  <br />
                  <span className='orange'>*</span>4.99% interest rate or ₱500.00
                  interest, whichever is higher
                </p>
              </div>
            </ContainerStyled>
          </section>

          <section className='how-to'>
            <ContainerStyled>
              <h2>How to #PawnSafelyAtHome</h2>
              <ol>
                <li>
                  Click Pawn Now to pawn your valued item and create your pawn
                  ticket.
                </li>
                <li>
                  Fill out the required details of your online pawn ticket then
                  book your item pick-up schedule. Our certified in-house
                  courier (Pawntrooper) will be at your specified address to
                  pick up your collateral. Enjoy loan approval within 1 DAY only
                  after your collateral has been picked up and appraised!
                </li>
                <li>
                  Once you’ve accepted our final loan offer, you may opt to cash
                  out via bank transfer or any InstaPay and PESONet partner
                  banks and electronic money issuers. Fast and secure!
                </li>
              </ol>
              <p>
                Enjoy convenient online transaction even after you
                #PawnSafelyAtHome! You may pay redemption of pawned items online
                and submit your proof of payment through the PawnHero website.
              </p>
            </ContainerStyled>
          </section>
          <section className='reviews'>
            <ContainerStyled>
              <h2>What our customers are saying</h2>

              <div className='review-wrapper'>
                <div className='review-block'>
                  <img
                    className='rating-img '
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/rating-5.svg'
                    alt='5 star'
                  />
                  <p className='title'>Legit site and pawning service</p>
                  <p className='review'>
                    Pickup is convenient and the turnaround time for assessment
                    of item/s were more than satisfactory. The field agents that
                    pick up your items are trustworthy. I would highly recommend
                    this company.
                  </p>
                  <p className='item'>
                    - Pawned a Nikon D7200 Camera for ₱14,498.35
                  </p>
                </div>
                <div className='review-block'>
                  <img
                    className='rating-img '
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/rating-5.svg'
                    alt='5 star'
                  />
                  <p className='title'>Highly commended for their services</p>
                  <p className='review'>
                    1 day process only, my item got picked up after lunch and I
                    got the cash after my item was appraised the same day. High
                    appraisal & has the lowest interest rate compared to
                    traditional pawnshop. Thank you Pawnhero!
                  </p>
                  <p className='item'>
                    - Pawned a Sony A7 Mark III Camera for ₱30,865.04
                  </p>
                </div>
              </div>
            </ContainerStyled>
          </section>
          <ShortInfo />
        </PawnaloPageStyle>
      </Layout>
    </>
  );
};

export default PawnaloPage;
