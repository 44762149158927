import { isEmpty } from '@ramda/isempty/isEmpty';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makePaymentSlip } from '../../../actions/ticket';
import MyDropzone from '../../components/shared/dropzone';
import { EasyDatePicker } from '../../components/shared/easyDatePicker';
import PriceInput from '../../components/shared/price';
import SelectField from '../../components/shared/select';
import WhyPawnhero from '../../components/whyPawnhero';
import { ConfirmLoanPaymentStyle } from '../../styles/confirmLoanPayment.style';
import { ContainerStyled } from '../../styles/layout.style';
import Layout from '../layout';
// import DatePickerCTM from '../../components/shared/datePicker';

const ConfirmLoanPayment = ({ doMakePaymentSlip }) => {
  const [isError, isErrorHandler] = useState(false);
  const [isLoading, isLoadingHandler] = useState(false);
  const [isSuccess, isSuccessHandler] = useState(false);

  const [formData, formDataHandler] = useState({
    files: [],
    ticketNo: '',
    paymentMethod: '',
    fullName: '',
    paymentAmount: '',
    paymentDate: '',
    message: '',
  });

  const submit = () => {
    isLoadingHandler(true);
    isErrorHandler(false);
    isSuccessHandler(false);
    const {
      files,
      ticketNo: ticket_no,
      paymentMethod,
      fullName: customer_name,
      paymentAmount: amount,
      paymentDate: payment_date,
      message,
    } = formData;

    const _payload = {
      amount,
      bank: paymentMethod.value,
      customer_name,
      message,
      payment_date,
      payment_slip: files[0],
      ticket_no,
      title: 'Customer Upload',
    };

    doMakePaymentSlip(_payload)
      .then(({ data }) => {
        const { success } = data;

        if (success) {
          // reset form
          formDataHandler({
            files: [],
            ticketNo: '',
            paymentMethod: '',
            fullName: '',
            paymentAmount: '',
            paymentDate: '',
            message: '',
          });

          isSuccessHandler(true);
          isLoadingHandler(false);
        } else {
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        const {
          data: {
            message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          },
        } = err;

        isLoadingHandler(false);
        isErrorHandler(message);
      });
  };

  const isCtaButtonDisabled = () => {
    return (
      isEmpty(formData.files) ||
      !formData.ticketNo ||
      formData.ticketNo.length !== 6 ||
      !formData.paymentMethod ||
      !formData.fullName ||
      formData.fullName.length < 4 ||
      !formData.paymentAmount ||
      !formData.paymentDate ||
      isLoading
    );
  };

  return (
    <>
      <Layout>
        <>
          <ConfirmLoanPaymentStyle>
            <ContainerStyled>
              <div className='breadcrum'>
                <p>
                  <Link to='/'>Home</Link> •{' '}
                  <Link to='/guest'> Manage Pawn Ticket</Link> •{' '}
                  <span>Confirm Loan Repayment</span>
                </p>
              </div>
              <div className='content-wrapper'>
                <div className='content'>
                  <div className='content-header'>
                    <h2>Confirm Loan Repayment</h2>
                  </div>
                  <div className={`content-body ${isSuccess ? 'success' : ''}`}>
                    {!isSuccess ? (
                      <>
                        <p className='note'>
                          Please accomplish the form below to confirm your loan
                          payment. We will notify you via email and SMS once
                          your payment has been validated.
                        </p>

                        <div className='form-wrapper'>
                          <div className='form-row'>
                            <div className='field-wrapper'>
                              <div
                                className={`field ${
                                  formData.ticketNo ? 'with-value' : ''
                                }`}>
                                <input
                                  type='text'
                                  id='ticket_number'
                                  maxLength='6'
                                  value={formData.ticketNo}
                                  onChange={(e) => {
                                    formDataHandler({
                                      ...formData,
                                      ticketNo: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor='ticket_number'>
                                  Ticket number
                                </label>
                              </div>
                            </div>
                            <div className='field-wrapper'>
                              <div
                                className={`field ${
                                  formData.paymentMethod ? 'with-value' : ''
                                }`}>
                                <SelectField
                                  inputId='payment_method'
                                  className={`ctm-select `}
                                  classNamePrefix={'ctm'}
                                  placeholder={''}
                                  value={formData.paymentMethod}
                                  options={[
                                    {
                                      value: 'Cash Payment',
                                      label: 'Cash Payment',
                                    },
                                    { value: 'BancNet', label: 'BancNet' },
                                    { value: '7-Eleven', label: '7-Eleven' },
                                    { value: 'Coins', label: 'Coins' },
                                    { value: 'GCash', label: 'GCash' },
                                  ]}
                                  onChange={(value) => {
                                    formDataHandler({
                                      ...formData,
                                      paymentMethod: value,
                                    });
                                  }}
                                />
                                <label htmlFor='payment_method'>
                                  Payment method
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='field-wrapper'>
                              <div
                                className={`field ${
                                  formData.fullName ? 'with-value' : ''
                                }`}>
                                <input
                                  type='text'
                                  id='full_name'
                                  value={formData.fullName}
                                  onChange={(e) => {
                                    formDataHandler({
                                      ...formData,
                                      fullName: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor='full_name'>Full name</label>
                              </div>
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='field-wrapper'>
                              <div
                                className={`field ${
                                  formData.paymentAmount ? 'with-value' : ''
                                }`}>
                                <PriceInput
                                  id='payment_amount'
                                  disableAbbreviations={true}
                                  decimalsLimit={4}
                                  value={formData.paymentAmount}
                                  onValueChange={(value) => {
                                    formDataHandler({
                                      ...formData,
                                      paymentAmount: value,
                                    });
                                  }}
                                />
                                <label htmlFor='payment_amount'>
                                  Payment amount
                                </label>
                              </div>
                            </div>
                            <div className='field-wrapper'>
                              <div
                                className={`field ${
                                  formData.paymentDate ? 'with-value' : ''
                                }`}>
                                <EasyDatePicker
                                  id='payment_date'
                                  selectedDate={formData.paymentDate}
                                  onChange={(value) => {
                                    formDataHandler({
                                      ...formData,
                                      paymentDate: value,
                                    });
                                  }}
                                />
                                <label htmlFor='payment_date'>
                                  Payment date
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='field-wrapper'>
                              <MyDropzone
                                config={{
                                  multiple: false,
                                }}
                                files={formData.files}
                                onFileUpload={(data) => {
                                  formDataHandler({
                                    ...formData,
                                    files: [data],
                                  });
                                }}
                                withRemoveBtn={true}
                                onFileRemove={(data) => {
                                  const { file } = data;
                                  formDataHandler({
                                    ...formData,
                                    files: formData.files.filter(
                                      (f) => f !== file
                                    ),
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='field-wrapper'>
                              <div
                                className={`field text-area ${
                                  formData.message ? 'with-value' : ''
                                }`}>
                                <textarea
                                  // placeholder='Remarks (optional)'
                                  value={formData.message}
                                  onChange={(e) => {
                                    formDataHandler({
                                      ...formData,
                                      message: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor='message'>
                                  Remarks (optional)
                                </label>
                              </div>
                            </div>
                          </div>
                          {isError && <p className='error'>{isError}</p>}
                          <button
                            className={`btn ${
                              isCtaButtonDisabled() ? 'disabled' : ''
                            }`}
                            disabled={isCtaButtonDisabled()}
                            onClick={() => {
                              submit();
                            }}>
                            Submit confirmation
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
                            alt='checkmark-icon.svg'
                            style={{
                              width: '48px',
                              height: '48px',
                              display: 'block',
                              margin: 'auto',
                            }}
                          />
                          <p
                            style={{
                              fontSize: '18px',
                              display: 'block',
                              textAlign: 'center',
                              marginTop: '20px',
                              color: '#354F65',
                            }}>
                            Your loan repayment has been sent. <br />
                            We will notify you via email and SMS once your
                            payment has been validated.
                          </p>
                          <button
                            style={{
                              background: '#68C552',
                              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            }}>
                            <Link to='/'>Back to Home</Link>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <WhyPawnhero />
              </div>
            </ContainerStyled>
          </ConfirmLoanPaymentStyle>
        </>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakePaymentSlip: (payload) => dispatch(makePaymentSlip(payload)),
});

export default connect(null, mapDispatchToProps)(ConfirmLoanPayment);
