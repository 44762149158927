import React, { useState } from 'react';
import OTPfield from './otpInput';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';

const OtpForm = (props) => {
  const { doMakeVerifyOtp, doMakeTicket, doResetPawn, pawn, userDetails } =
    props;

  const history = useHistory();
  const optLength = 4;
  const [otp, otpHandler] = useState('');
  const [otpError, otpErrorHandler] = useState(false);
  const [error, errorHandler] = useState('');
  const [loading, loadingHandler] = useState(false);

  const verifyHandler = (otp, pawn) => {
    otpErrorHandler(false);
    loadingHandler(true);
    const _data = {
      otp,
      userDetail: userDetails,
    };

    doMakeVerifyOtp(_data)
      .then((data) => {
        loadingHandler(false);
        errorHandler('');
        if (!data.success) {
          otpErrorHandler(
            'You have entered a wrong 4-digit code. Please try again.'
          );
        } else {
          doMakeTicket({ ...pawn, otp })
            .then((data) => {
              const { ticket_no, g9 = false } = data;

              if (g9) {
                history.push(`/pawn/acceptance/${ticket_no}`);
              } else {
                history.push(`/pawn/thankyou/${ticket_no}`, {
                  prestine: true,
                  thankyouDateExpiry: moment().add(30, 'm').tz(timezone).unix(),
                });
              }

              loadingHandler(false);
              doResetPawn();
            })
            .catch((err) => {
              console.log('make tick err', err);
              // const { msg } = err.data;
              errorHandler(
                'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
              );
              loadingHandler(false);
            });
        }
      })
      .catch((err) => {
        console.log('err request', err);
        loadingHandler(false);
        otpErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };
  return (
    <>
      <p className='note'>Please enter the 4-digit code below.</p>
      <OTPfield otpHandler={otpHandler} optLength={optLength} />
      {otpError && (
        <p
          style={{
            fontSize: '8px',
            color: '#fff',
            fontStyle: 'italic',
            textAlign: 'center',
            margin: '20px 0 10px',
          }}>
          {otpError}
        </p>
      )}
      {error && <p className='error'>{error}</p>}
      <button
        className={`btn-otp-verify ${
          otp.length !== optLength || loading ? 'disable' : 'able'
        }`}
        disabled={otp.length !== optLength || loading}
        onClick={() => verifyHandler(otp, pawn)}>
        Verify OTP
      </button>
    </>
  );
};

export default OtpForm;
