import React, { useState, useEffect } from 'react';
import Layout from '../layout';
import WhyPawnhero from '../../components/whyPawnhero';
import Payment from '../../components/renewal/payment';

// styles
import { AcceptanceStyle } from '../../styles/acceptance.style';
import { ContainerStyled } from '../../styles/layout.style';

// utility
import { connect } from 'react-redux';
import {
  fetchRenewalApproved,
  fetchSummaryTicket,
} from '../../../actions/ticket';
import { useHistory, useParams } from 'react-router-dom';
import capitalize from 'lodash.capitalize';

const RenewalRepayment = ({ doFetchSummaryTicket, doFetchRenewalApproved }) => {
  const { ticketNum } = useParams();
  const history = useHistory();

  // states
  const [error, errorHandler] = useState(null);
  const [loading, loadingHandler] = useState(true);
  const [ticketOwner, ticketOwnerHandler] = useState(null);
  const [renewalData, renewalDataHandler] = useState(null);

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getTicketInfo();
    }
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    doFetchRenewalApproved({ ticketNum })
      .then((response) => {
        const { data, success } = response;

        if (success) {
          renewalDataHandler(data);
        }
      })
      .catch((err) => {
        console.log('doFetchRenewalApproved err', err);
      });
    // eslint-disable-next-line
  }, []);

  const getTicketInfo = async () => {
    try {
      loadingHandler(true);

      const {
        success,
        data: { statusRaw, user },
      } = await doFetchSummaryTicket({ ticketNum });

      if (success) {
        if (statusRaw === 'WA') {
          const { firstName } = user || { firstName: 'Guest' };
          ticketOwnerHandler(firstName);
          loadingHandler(false);
        } else {
          history.push('/guest');
        }
      } else {
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      }
      loadingHandler(false);
    } catch (err) {
      console.log('err get doFetchSummaryTicket', err);
      errorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
      loadingHandler(false);
    }
  };

  return (
    <>
      <Layout>
        <AcceptanceStyle className='window-height done-animate'>
          {error ? (
            <p
              style={{
                textAlign: 'center',
                color: 'red',
              }}>
              {error}
            </p>
          ) : (
            <>
              {loading ? (
                <>
                  <div
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      left: '0',
                      right: '0',
                      top: '0',
                      bottom: '0',
                      height: '32px',
                    }}>
                    <img
                      className='loader'
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                      alt='loading'
                      style={{
                        width: '32px',
                        height: '32px',
                        margin: 'auto',
                        display: 'flex',
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h1>Welcome back, {capitalize(ticketOwner)}!</h1>
                  <ContainerStyled className='container-wrapper'>
                    <div className='page-content-wrapper'>
                      <div
                        className='page-content'
                        style={{ overflow: 'visible' }}>
                        <Payment renewalData={renewalData} />
                      </div>
                      <WhyPawnhero />
                    </div>
                  </ContainerStyled>
                </>
              )}
            </>
          )}
        </AcceptanceStyle>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
  doFetchRenewalApproved: (payload) => dispatch(fetchRenewalApproved(payload)),
});
export default connect(null, mapDispatchToProps)(RenewalRepayment);
