export const UPDATE_SOCKET = 'UPDATE_SOCKET';
export const UPDATE_SOCKET_CHAT = 'UPDATE_SOCKET_CHAT';
export const UPDATE_SOCKET_CHAT_ROOM = 'UPDATE_SOCKET_CHAT_ROOM';
export const UPDATE_SOCKET_CHAT_ADD_COUNT = 'UPDATE_SOCKET_CHAT_ADD_COUNT';
export const UPDATE_SOCKET_CHAT_RESET_COUNT = 'UPDATE_SOCKET_CHAT_RESET_COUNT';

export const updateSocket = (socket) => {
  return {
    type: UPDATE_SOCKET,
    socket,
  };
};

export const updateSocketChat = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT,
    socket,
  };
};

export const updateSocketChatRoom = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT_ROOM,
    socket,
  };
};

export const updateSocketChatAddCount = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT_ADD_COUNT,
    socket,
  };
};

export const updateSocketChatResetCount = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT_RESET_COUNT,
    socket,
  };
};
