import styled from 'styled-components';
const { SlideUpKeyframes } = require('./layout.style');

export const SearchModalStyled = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  animation: ${SlideUpKeyframes} 500ms both ease;
  z-index: 999;

  .markHighlight {
    color: #8b959c;
    background: 0;
  }

  .modal-header {
    background: #327fbe;

    .modal-controller {
      padding: 10px 0;
      display: flex;
      position: relative;

      & > img {
        width: 12px;
        height: 20.5px;
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      .input-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 95%;
        position: relative;
        margin-left: 5%;
        background: #2167a0;
        border-radius: 5px;
        padding: 10px;

        img {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        input {
          width: 100%;
          border: 0;
          background: transparent;
          padding-left: 25px;
          outline: 0 !important;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;

          &::placeholder {
            color: #5a92c0;
          }
        }
      }
    }
  }

  .modal-body {
    max-height: 100vh;
    overflow-y: auto;

    .search-option-wrapper {
      a {
        position: relative;
        display: block;

        div.brand-icon-wrapper {
          position: absolute;
          background: #354f65;
          width: 32px;
          height: 32px;
          left: 15px;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 50%;
          text-align: center;
          font-size: 14px;
          line-height: 14px;
          justify-content: center;
          display: flex;
          color: #ffffff;

          p {
            align-self: center;
          }
        }

        div.brand-icon-label {
          p {
            padding: 17px 0;
            border-bottom: 1px solid #c7c7cc;
            width: 100%;
            margin-left: 56px;
            color: #354f65;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 75%;
          }

          img {
            position: absolute;
            width: 30px;
            height: 30px;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.isToggle ? 'block' : 'none')};
  }
`;
