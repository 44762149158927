import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const { ContainerStyled } = require('../../styles/layout.style');
const { ShortInfoStyled } = require('../../styles/home.style');

const ShortInfo = (props) => {
  return (
    <ShortInfoStyled>
      <ContainerStyled>
        <div>
          <div>
            <p>
              PawnHero, the first online pawnshop in the Philippines, is leading
              the modernization of the pawnbroking industry in the country.
            </p>
            <p>
              Through the use of advanced technology, PawnHero is able to
              provide instant cash loans to its customers in a hassle-free and
              discreet manner. PawnHero’s mission is to provide the most
              convenient and reliable financial service to all Filipinos in need
              of quick cash loans.
            </p>
          </div>
          <LazyLoadImage
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/model-girl-uniform-big.png'
            alt='ph girl'
          />
        </div>
      </ContainerStyled>
    </ShortInfoStyled>
  );
};

export default ShortInfo;
