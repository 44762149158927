import { INIT_TICKET, UPDATE_TICKET } from '../actions/ticket';
import update from 'immutability-helper';

const ticket = (state = {}, action) => {
  switch (action.type) {
    case INIT_TICKET:
        return action.ticket;
    case UPDATE_TICKET:
        return update(state, { $merge: action.ticket });
    default:
      return state;
  }
}


export default ticket;
