import React from 'react';
import { NotFoundPageStyled } from '../../styles/not-found.style';
import { Link } from 'react-router-dom';

const NotFoundPage = (props) => {
  return (
    <>
      <NotFoundPageStyled className='page-404' id='page_404'>
        <div>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/404.svg'
            className='page-404-image'
            alt=''
          />
          <div>
            <h1>Oopsie.</h1>
            <p>We are still looking for that missing page.</p>
            <div>
              <Link id='page_404_goHome' to='/'>
                Go to Home
              </Link>
              <Link id='page_404_goContact' to='/contact-us'>
                Contact us here
              </Link>
            </div>
          </div>
        </div>
        <div>
          <p>Or you can call us at</p>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnhero-tel.svg'
            alt='828-2274'
          />
        </div>
      </NotFoundPageStyled>
    </>
  );
};

export default NotFoundPage;
