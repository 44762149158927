import styled from 'styled-components';

export const ConfirmLoanPaymentStyle = styled.div`
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 20px 0;

  .content-wrapper {
    display: flex;

    .content {
      background: #fff;
      width: 66%;
      max-width: 750px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        width: 100%;
        max-width: unset;
      }

      .content-header {
        background: #327fbe;
        border-radius: 5px 5px 0px 0px;
        padding: 16px 0;

        h2 {
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          color: #ffffff;
        }
      }

      .content-body {
        padding: 30px 50px;

        p.note {
          font-style: italic;
          font-weight: normal;
          font-size: 14px;
          text-align: center;
          color: #8b959c;
        }

        .form-wrapper {
          margin-top: 30px;

          input {
            background: #ffffff;
            border: 1px solid #dadada;
            box-sizing: border-box;
            border-radius: 5px;
            width: 100%;
            display: block;
            margin: auto;
            font-size: 14px;
            padding: 5.5px 10px;
            color: #354f65;
            outline: none;
          }

          .form-row {
            width: 100%;
            display: flex;
            @media (max-width: 768px) {
              display: block;
            }
            .field-wrapper {
              display: flex;
              width: 100%;
              margin: 5px 10px;
              padding: 14px 0 11px;

              @media (max-width: 768px) {
                margin: 0;
              }

              textarea {
                width: 100%;
                height: 150px;
                border: 1px solid #e1e6e9;
                resize: none;
                border-radius: 5px;
                outline: none;
                padding: 10px;
                color: #354f65;
                font-size: 14px;
              }

              .field {
                display: flex;
                width: 100%;
                position: relative;

                label {
                  font-size: 14px;
                  font-weight: 400;
                  position: absolute;
                  bottom: 6.5px;
                  transition: 0.3s;
                  left: 10px;
                  color: #8b959c;
                }
                textarea + label {
                  bottom: 79%;
                }
                .ctm-select {
                  width: 100%;
                  & > div.ctm__control {
                    min-height: unset;
                    border: 1px solid #dadada !important;
                    &.ctm__control--is-focused {
                      border: 1px solid #327fbe !important;
                    }
                    & > div:first-child {
                      padding: 3px 10px;

                      & > div {
                        margin: unset;
                        font-size: 14px;
                        color: #354f65;
                        position: static;
                        top: unset;
                        transform: none;
                      }
                    }
                    & > div:last-child {
                      & > span {
                        display: none;
                      }

                      & > div {
                        padding: 3px;
                      }
                    }
                  }

                  &.no-val {
                    & > div {
                      & > div:first-child {
                        & > div:not(.ctm__option) {
                          color: #b2b1b1;
                        }
                      }
                    }
                  }
                }

                .shared-calendar {
                  padding: 0 5px;

                  input {
                    border: none;
                  }
                }

                &.with-value label,
                input:focus + label,
                textarea:focus + label,
                .ctm-select.focus + label,
                .shared-calendar.focus + label {
                  display: block;
                  bottom: 100%;
                  left: 0;
                  color: #327fbe;
                  font-size: 10px;
                }
              }
            }
          }
        }

        & > div > button,
        .form-wrapper > button {
          border: 0;
          background: #ff7019;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          font-size: 18px;
          color: #ffffff;
          display: block;
          margin: 30px auto 0;
          outline: none;

          & > a {
            color: #ffffff;
            font-size: 18px;
          }
          &:disabled,
          &.disabled {
            /* pointer-events: none; */
            color: #cd6a2e;
            background: #ff7019;

            & > a {
              color: #cd6a2e;
            }
          }
        }

        &.success {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;
