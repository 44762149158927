import styled from 'styled-components';

export const ModalLoaderStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background: #f9f9f685;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  @keyframes ldio-105p52voxmij {
    0% {
      transform: translate(12px, 80px) scale(0);
    }
    25% {
      transform: translate(12px, 80px) scale(0);
    }
    50% {
      transform: translate(12px, 80px) scale(1);
    }
    75% {
      transform: translate(80px, 80px) scale(1);
    }
    100% {
      transform: translate(148px, 80px) scale(1);
    }
  }
  @keyframes ldio-105p52voxmij-r {
    0% {
      transform: translate(148px, 80px) scale(1);
    }
    100% {
      transform: translate(148px, 80px) scale(0);
    }
  }
  @keyframes ldio-105p52voxmij-c {
    0% {
      background: #327fbe;
    }
    25% {
      background: #6bbd57;
    }
    50% {
      background: #327fbe;
    }
    75% {
      background: #6bbd57;
    }
    100% {
      background: #327fbe;
    }
  }
  .ldio-105p52voxmij div {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translate(80px, 80px) scale(1);
    background: #327fbe;
    animation: ldio-105p52voxmij 5s infinite cubic-bezier(0, 0.5, 0.5, 1);
  }
  .ldio-105p52voxmij div:nth-child(1) {
    background: #6bbd57;
    transform: translate(148px, 80px) scale(1);
    animation: ldio-105p52voxmij-r 1.25s infinite cubic-bezier(0, 0.5, 0.5, 1),
      ldio-105p52voxmij-c 5s infinite step-start;
  }
  .ldio-105p52voxmij div:nth-child(2) {
    animation-delay: -1.25s;
    background: #327fbe;
  }
  .ldio-105p52voxmij div:nth-child(3) {
    animation-delay: -2.5s;
    background: #6bbd57;
  }
  .ldio-105p52voxmij div:nth-child(4) {
    animation-delay: -3.75s;
    background: #327fbe;
  }
  .ldio-105p52voxmij div:nth-child(5) {
    animation-delay: -5s;
    background: #6bbd57;
  }
  .loadingio-spinner-ellipsis-12wcmryihj1e {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-105p52voxmij {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-105p52voxmij div {
    box-sizing: content-box;
  }
`;
