import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { Link, useParams } from 'react-router-dom';
import { fetchProductAttrEntities } from '../../../actions/product';
import { updatePawn } from '../../../actions/pawn';

import { StepStyled } from '../../styles/step.style';
import Checkbox from './checkbox';
import Radio from './radio';
import ReactModal from '../../components/shared/reactModal';

const Step = ({
  active,
  pawn,
  stepAttr,
  doFetchProductAttrEntities,
  doUpdatePawn,
}) => {
  const { categorySlug, brandSlug } = useParams();
  const { slideLeft = false, attrStep, attribute } = pawn;

  const { attribute_id, product_id } = stepAttr;

  // states
  const [entities, entitesHandler] = useState([]);
  const [toggle, toggleHandler] = useState(true);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      doFetchProductAttrEntities({ attribute_id, product_id })
        .then((data) => {
          entitesHandler(data);
        })
        .catch((err) => {
          console.log('doFetchProductAttrEntities err', err);
        });
    }

    return () => {
      cancel = true;
      toggleHandler(false);
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <StepStyled active={active} slideLeft={slideLeft}>
        <div className='pawn-activity-header'>
          {attrStep === 0 ? (
            <Link
              className='pawn-activity-header-nav'
              to={`/pawn/${categorySlug}/${brandSlug}`}
              onClick={() => {
                doUpdatePawn({ slideLeft: true });
              }}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='go back'
              />
              <p>Back to Models</p>
            </Link>
          ) : (
            <div
              className='ctm-link-attr-wrapper'
              onClick={() => {
                doUpdatePawn({ attrStep: attrStep - 1, slideLeft: true });
              }}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='go back'
              />
              <p>
                Back to{' '}
                {attribute[attrStep - 1].display_name.replace(
                  /\w\S*/g,
                  function (txt) {
                    return (
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                    );
                  }
                )}
              </p>
            </div>
          )}
        </div>
        <div className='pawn-activity-body flat-end'>
          {stepAttr.input_type === 'multiple_select' ? (
            <>
              <Checkbox entities={entities} code={stepAttr.code} />
            </>
          ) : (
            <>
              <Radio entities={entities} code={stepAttr.code} />
            </>
          )}
        </div>
        {stepAttr.input_type === 'multiple_select' && (
          <div className={`form-control-wrapper `}>
            <p
              onClick={() => {
                const _attrStep = attrStep + 1;
                doUpdatePawn({ attrStep: _attrStep });
              }}>
              Proceed
            </p>
          </div>
        )}
      </StepStyled>

      {stepAttr.display_name === 'item condition' && active && (
        <>
          <ReactModal
            toggle={toggle}
            toggleHandler={toggleHandler}
            withHeader={false}
            contentContainerStyle={{
              background: '#327FBE',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
              maxWidth: '554px',
              width: '100%',
              minHeight: '40vh',
              padding: '30px',
            }}>
            <>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/item-condition-modal-ico-2.svg'
                alt='item condition'
                style={{
                  width: '120px',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <p
                style={{
                  fontWeight: '600',
                  fontSize: '18px',
                  color: '#fff',
                  textAlign: 'center',
                  marginTop: '20px',
                }}>
                A friendly reminder from PawnHero
              </p>

              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '14px',
                  color: '#fff',
                  textAlign: 'center',
                  marginTop: '10px',
                }}>
                By clicking “I understand”, you are agreeing that you will
                describe your item accurately. If we have received your item in
                a state that is different from what you have previously
                described, it will be held and is subject for a return charge.
                <br />
                <br />
                Moreover, pawning of fake items is against the law, and is
                considered a criminal offense.
              </p>
              <button
                id='pawn_item_condition_understand_btn'
                style={{
                  cursor: 'pointer',
                  padding: '7px 20px',
                  lineHeight: '18px',
                  fontSize: '18px',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px',
                  borderRadius: '15px',
                  background: '#68C552',
                  border: '0',
                  color: '#fff',
                  display: 'block',
                  margin: '30px auto 0',
                  outline: 'none',
                }}
                onClick={() => toggleHandler(false)}>
                I understand
              </button>
            </>
          </ReactModal>
        </>
      )}
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
  doFetchProductAttrEntities: (payload) =>
    dispatch(fetchProductAttrEntities(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step);
