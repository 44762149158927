import styled from 'styled-components';

export const PawnaloPageStyle = styled.div`
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 20px 0 0;

  &.gold {
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #006b9a 0%,
      #035b82 72.4%,
      #154b79 100%
    );
  }

  section.banner {
    h1 {
      width: 100%;

      & > img {
        width: 100%;
        max-width: 415px;
        display: block;
        margin: auto;
      }
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      color: #ffb200;
    }
  }

  section.content {
    padding: 15px 0;

    p {
      font-weight: 600;
      font-size: 24px;
      color: #e4eef2;
      text-align: center;
      max-width: 750px;
      margin: 10px auto 0;
      display: block;
    }

    div.promo-content {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      & > div {
        display: flex;
        flex-flow: column;

        &:first-child {
          div.promo-detail-context {
            display: flex;
            & > div {
              display: flex;
              flex-flow: column;

              &.center-elem {
                padding: 0;
                display: flex;
                justify-content: center;
                padding: 0 50px;

                @media (max-width: 425px) {
                  padding: 0 20px;
                }

                p {
                  text-align: center;
                }
              }

              p {
                font-weight: 600;
                font-size: 18px;
                color: #fff;
                @media (max-width: 375px) {
                  font-size: 20px;
                }
              }

              p.highlight {
                font-weight: 600;
                font-size: 48px;
                line-height: 48px;
                background: -webkit-linear-gradient(
                  #ffe095,
                  #fede93,
                  #fbd88c,
                  #f5ce81,
                  #edbf70,
                  #e2ac5a,
                  #d5943f,
                  #c97f27,
                  #d38820,
                  #eca00d,
                  #ffb200
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }

              p.note {
                font-size: 14px;

                span {
                  display: block;
                  font-size: 12px;
                  font-weight: normal;
                }

                span.mark {
                  color: #ff7019;
                  display: inline-block;
                }
              }
            }
          }

          & > p {
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            color: #e4eef2;
            margin-top: 15px;
          }
        }
      }
    }

    .breakdown-sample-wrapper {
      & > p {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        font-style: italic;
        margin-top: 15px;
      }

      div.breakdown-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        & > div.breakdown {
          width: 165px;
          margin: 0 15px;
          &:nth-child(1) {
            img {
              margin-top: 15px;
            }

            @media (max-width: 768px) {
              width: 100%;

              img {
                width: 165px;
                display: block;
                margin: 0 auto;
              }
            }
          }

          @media (max-width: 375px) {
            width: 40%;
          }
          @media (max-width: 768px) {
            margin-top: 10px;
          }

          &.gold {
            & > div {
              margin-top: 15px;
              p {
                color: #ffffff;
                font-size: 14px;
                text-align: center;

                &.title {
                  font-weight: bold;
                }
              }
            }
          }
        }

        @media (max-width: 768px) {
          flex-wrap: wrap;
        }
      }

      p.disclamer {
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        span {
          &.green {
            color: #68c552;
          }

          &.orange {
            color: #ff7019;
          }
        }
      }

      div.terms-and-conditions {
        margin-top: 30px;

        & > p {
          font-weight: 600;
          font-size: 14px;
          color: #fff;
          text-align: center;

          span {
            color: #ff7934;
          }
        }

        div {
          margin: 10px auto 30px;
          max-width: 555px;
          display: block;

          & > p {
            font-size: 14px;
            color: #ffffff;
            text-align: justify;
          }
        }
      }
    }
  }

  section.gadget-we-accept {
    padding: 15px 0 15px;
    & > div {
      /* container */
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #fff;
        text-align: center;
      }

      div.gadgets-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 15px;

        & > div {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -15px;

          a.gadget {
            width: 165px;
            margin: 0 15px;
            cursor: pointer;

            div.img-wrapper {
              border-radius: 5px;
              overflow: hidden;
              width: 100%;
              border: 0 solid #68c552;
              background: #1c5979;

              img {
                width: 100%;
                font-size: 0;
                display: block;
                object-fit: cover;
                object-position: center;
              }
            }

            &.selected {
              div.img-wrapper {
                border: 2px solid #68c552;
              }
            }

            & > p {
              font-weight: normal;
              font-size: 14px;
              color: #fff;
              text-align: center;
            }
            @media (max-width: 425px) {
              width: 45%;
              margin: 5px 0;
            }
          }

          @media (max-width: 768px) {
            justify-content: center;
            margin: 0;
          }

          @media (max-width: 425px) {
            justify-content: space-between;
          }
        }
      }

      a.btn-pawn-now {
        background: #ff7019;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 15px;
        color: #fff;
        font-size: 18px;
        width: 150px;
        text-align: center;
        text-decoration: none;
        margin: 20px auto;
        display: block;
        border: 0;
      }
    }
  }

  section.how-to {
    padding: 15px 0 15px;
    & > div {
      /* container */

      h2 {
        font-weight: 600;
        font-size: 24px;
        color: #ffffff;
        text-align: center;
      }

      ol {
        padding: 0 15px;
        font-weight: normal;
        font-size: 14px;
        text-align: justify;
        color: #fff;
        margin-top: 15px;
      }

      p {
        margin-top: 15px;
        font-size: 14px;
        text-align: justify;
        color: #fff;
      }
    }
  }

  section.reviews {
    padding: 15px 0 15px;
    & > div {
      /* container */

      h2 {
        font-weight: 600;
        font-size: 24px;
        color: #ffffff;
        text-align: center;
      }

      div.review-wrapper {
        display: flex;
        margin: 15px -15px;
        justify-content: center;

        .review-block {
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          padding: 15px;
          width: 45%;
          max-width: 555px;
          margin: 0 15px;

          img.rating-img {
            max-width: 98px;
          }

          p {
            color: #354f65;
            font-size: 14px;
            margin-top: 10px;
          }

          .title {
            font-weight: 600;
          }

          .review {
            font-style: italic;
          }

          .item {
            margin-top: 10px;
            font-weight: 600;
          }
        }

        @media (max-width: 425px) {
          display: block;
          margin: 15px 0 0;

          .review-block {
            width: 100%;
            margin: 15px 0;
          }
        }
      }
    }
  }
`;
