import styled from 'styled-components';

export const CashoutPaymentStyled = styled.div`
  h2 {
    font-weight: 600;
    font-size: 32px;
    color: #327fbe;
  }

  span.bold {
    font-weight: 600;
    color: #354f65;
  }

  & > section:first-child {
    padding-top: 20px;

    h3 {
      color: #68c552;
      font-weight: 600;
      font-size: 24px;
      margin-top: 20px;
    }

    p {
      color: #8c969d;
      margin-top: 20px;
      font-size: 18px;
    }

    & > div {
      /* container */
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-flow: column-reverse;
      }

      @media (min-width: 769px) {
        & > div {
          width: 49%;
        }
      }

      & > div:first-child {
        padding: 20px 0;
        @media (max-width: 768px) {
          padding: 10px 0;
        }
      }

      & > div:last-child {
        font-size: 0;
        display: flex;
        justify-content: center;

        & > img {
          max-width: 258px;
        }
      }
    }
  }

  & > section:last-child {
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #354f65 0%,
      #253d52 100%
    );
    padding: 30px 0;

    @media (max-width: 768px) {
      padding: 20px 0;
    }

    & > div {
      /* container */
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-flow: column;
      }

      & > div {
        width: 49%;
        @media (max-width: 768px) {
          width: 100%;
          &:last-child {
            margin-top: 20px;

            @media (max-width: 425px) {
              p {
                span {
                  display: block;
                }

                br {
                  display: none;
                }
              }
            }
          }
        }

        h3 {
          color: #fff;
          font-weight: 600;
          font-size: 24px;
        }

        p {
          color: #fff;
          font-size: 18px;
          margin-top: 30px;

          span {
            font-weight: 600;
          }
        }
      }
    }
  }
`;
