import styled from 'styled-components';

export const TermsStyled = styled.div`
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 20px 0;

  .content-wrapper {
    display: flex;

    .content {
      background: #fff;
      width: 66%;
      max-width: 750px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 30px;
      text-align: justify;

      @media (max-width: 768px) {
        width: 100%;
        max-width: unset;
      }

      h2, h1 {
        color: #327fbe;
        font-weight: 600;
        font-size: 24px;
      }

      h3 {
        color: #354f65;
        font-weight: 600;
        font-size: 16px;
        margin-top: 20px;
      }

      p {
        color: #8c969d;
        font-weight: normal;
        font-size: 14px;
        margin-top: 20px;
      }

      h3 + p {
        margin-top: 0;
      }
    }
  }
`;
