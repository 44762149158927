import React, { useState, useRef } from 'react';
import OtpForm from './otpForm';
import Countdown, { zeroPad } from 'react-countdown-now';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { makeVerifyOtp, makeOtp } from '../../../actions/otp';
import { makeTicket } from '../../../actions/ticket';
import { resetPawn } from '../../../actions/pawn';
import StringMask from 'string-mask';

const { OtpModalStyled } = require('../../styles/otpModal.style');

const OtpModal = (props) => {
  const {
    closeModalHandler,
    pawn,
    doMakeVerifyOtp,
    doMakeOtp,
    doMakeTicket,
    doResetPawn,
  } = props;

  const mask = new StringMask('+## ### ### ####');

  const { userDetails } = pawn;
  const { mobile = null } = userDetails;
  const [resendDisable, resendDisableHandler] = useState(true);
  const counterRef = useRef(null);
  const renderer = ({ hours, minutes, seconds, completed }) => {
    return (
      <>
        ({minutes}:{zeroPad(seconds)})
      </>
    );
  };

  const onCompleteHandler = () => {
    resendDisableHandler(false);
  };

  const countdownConfig = {
    date: Date.now() + 60000,
    // date: Date.now() + 10000,
    zeroPadTime: 2,
    renderer: renderer,
    onComplete: onCompleteHandler,
  };

  const resendHandler = () => {
    const { start } = counterRef.current;
    start();
    resendDisableHandler(true);

    const { email, mobile } = userDetails;
    const _data = {
      email: email ? email : null,
      number: mobile ? mobile : null,
    };

    doMakeOtp(_data);
  };
  return (
    <OtpModalStyled>
      <div className='modal-wrapper'>
        <div className='modal-container'>
          <img
            className='modal-close-icon'
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/otp-modal-close-icon.svg'
            onClick={closeModalHandler}
            alt=''
          />
          <img
            className='modal-icon'
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/otp-vector.svg'
            alt=''
          />
          <p className='title'>Verify your mobile number</p>
          <p className='context'>
            For your security, we need to authenticate your mobile number before
            submitting your pawn request. In order to do that, we have sent you
            a one-time password (OTP) to your mobile number{' '}
            <span>{mask.apply(mobile.replace(/^09/g, '639'))}</span>
          </p>
          <OtpForm
            doMakeVerifyOtp={doMakeVerifyOtp}
            doMakeTicket={doMakeTicket}
            doResetPawn={doResetPawn}
            pawn={pawn}
            userDetails={userDetails}
          />
          <p className='resent-otp'>
            Didn’t receive the code?{' '}
            <span
              className={`${resendDisable && 'disable'}`}
              onClick={resendHandler}>
              Resend OTP{' '}
            </span>
            <span className={`${!resendDisable && 'disable'}`}>
              <Countdown {...countdownConfig} ref={counterRef} />
            </span>
          </p>
        </div>
      </div>
    </OtpModalStyled>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doMakeVerifyOtp: (data) => dispatch(makeVerifyOtp(data)),
  doMakeOtp: (data) => dispatch(makeOtp(data)),
  doMakeTicket: (data) => dispatch(makeTicket(data)),
  doResetPawn: (pawn) => dispatch(resetPawn(pawn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpModal);
