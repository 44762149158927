import React, { Fragment } from 'react';

import { menus } from '../../components/dashboard/layout';

// utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';
import { updateDashboard } from '../../../actions/dashboard';
import { NotificationListWrapperstyle } from '../../styles/notification.style';
import { Link } from 'react-router-dom';
import { isEmpty } from '@ramda/isempty/isEmpty';

// styles
const { ContentStyled } = require('../../styles/dashboard.style');

const Notification = ({ dashboard, notification }) => {
  const { activeMenu } = dashboard;

  const { notification: _noti = [] } = notification;

  return (
    <ContentStyled>
      <div className='content-header'>
        <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
        <h1>{menus[activeMenu] ? menus[activeMenu].text : ''}</h1>
      </div>
      <div className='content-wrapper'>
        <NotificationListWrapperstyle>
          <ul>
            {_noti &&
              !isEmpty(_noti) &&
              _noti.map((e, i) => (
                <Fragment key={i}>
                  <li className={e.read ? 'unread' : ''}>
                    <Link to={`/dashboard/notification/${e.id}`}>
                      <p className='title'>{e.title}</p>
                      <p className='content'>{e.message}</p>
                    </Link>
                  </li>
                </Fragment>
              ))}
          </ul>
        </NotificationListWrapperstyle>
      </div>
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard', 'notification']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateDashboard: (dashboard) => dispatch(updateDashboard(dashboard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
