import styled from 'styled-components';

export const WhyPawnheroSideStyled = styled.div`
  margin-left: auto;
  width: 30%;
  max-width: 360px;

  .advantage-block {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    .advantage-block-header {
      background: #112434;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 0;

      h2 {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
      }
    }

    .advantage-block-body {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 5px 5px;
      background: #ffffff;

      ul {
        list-style: none;
        padding: 15px;

        li {
          font-weight: 600;
          font-size: 14px;
          color: #354f65;
          position: relative;
          padding: 7.5px 0 7.5px 29px;

          img {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }

  .accreditation-block {
    margin-top: 14px;
    display: block;
    padding-top: 1px;

    .accreditation-wrapper {
      ul {
        list-style: none;
        li {
          &:nth-child(1) {
            p {
              font-style: italic;
              font-size: 12px;
              text-align: center;
              color: #ffffff;
              padding: 0 20px;
            }
          }
          &:nth-child(2) {
            img {
              width: 100%;
              max-width: 140px;
              margin: 15px auto;
              display: block;
            }
          }

          &:last-child {
            img {
              width: 100%;
              max-width: 154.79px;
              margin: 15px auto;
              display: block;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
