import React from 'react';

import Layout from '../layout';
import { Link } from 'react-router-dom';

import { ContainerStyled } from '../../styles/layout.style';
import { SectionStyled, BreadCrumsStyle } from '../../styles/section.style';

import { PromoLandingStyle } from '../../styles/promo.style';

import ShortInfo from '../home/shortInfo';

const PreHoliday = () => {
    
    return ( 
        <>
            <Layout >
                <SectionStyled className="full-section" bgColor="#8DC63F" bgImage="https://d1v5w8bodpeh4i.cloudfront.net/assets/pre-holiday-bg-img.svg">
                    <BreadCrumsStyle>
                        <div className='extended-breadcrumb preholiday-red'>
                            <ContainerStyled>
                                    <div className='breadcrum'>
                                        <p>
                                            <Link to='/' id='preholiday_home_link'>
                                            Home
                                            </Link>{' '}
                                            • <span class="active white">Pre-Holiday Giveaway</span>
                                        </p>
                                    </div>
                            </ContainerStyled> 
                        </div>
                    </BreadCrumsStyle>
                    <PromoLandingStyle >
                        
                        
                        <ContainerStyled>
                                <div className='promo-img-container'>
                                    <img
                                    className='promo-img'
                                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pre-holiday-logo.svg'
                                    alt='Cash Bonus'
                                    />
                                </div>
                               
                                <div className='promo-dark-background'>
                                    
                                    <div className='promo-terms-title'>
                                        <h3>Pawn and get a chance to win 2 movie tickets to the block screening of</h3>
                                        <h1>Black Panther: Wakanda Forever</h1>
                                    </div>

                                    <div className="button-container">
                                        <a 
                                        className="button orange"
                                        href='/pawn' 
                                        target='_blank' 
                                        rel='noopener noreferrer'>
                                            Pawn now
                                        </a>
                                    </div>

                                    <div className='promo-terms contain'>
                                        <p className='promo-terms-head bottom'>
                                        Raffle Promo Mechanics:
                                        </p>
                                        <div className='promo-terms-item'>
                                            <ul className='list-decimal pad-left'>
                                                <li >This raffle promo is open to all PawnHero Pawnshop Pawners pawning via website, app or walk-in from 1 October to 19 November 2022.</li>
                                                <li >All Pawners with successful loan releases within the promo period are automatically awarded 1 raffle entry (1 pawn ticket = 1 raffle entry); No registration needed to qualify. Qualified participants’ pawn ticket numbers which can be found on their respective pawn tickets will serve as their raffle ticket.</li>
                                                <li >Deadline of submission of raffle entries is on 19 November 2022.</li>
                                                <li >Raffle draw will be held on 21 November 2022, 3:00 PM at PawnHero Pawnshop located at 7F Avecshares Center, 38th Street, BGC, Taguig City.</li>
                                                <li >30 raffle winners will win 2 movie tickets each to the block screening of Black Panther: Wakanda Forever. Movie tickets are only valid for this special block screening on 30 November 2022 at 3:00 PM by PawnHero.</li>
                                                <li >The prizes are non-transferable, non-cumulative and cannot be exchanged for other products or services.</li>
                                                <li >Winners will be notified and contacted by our marketing team via SMS and email on 21 November 2022 through the winner’s registered mail and SMS number. Winners will also be announced on our official Facebook page and Instagram accounts on 21 November 2022.</li>
                                                <li >A customer can only win once.</li>
                                                <li >Prizes can be claimed at the PawnHero registration booth at Bonifacio High Street Cinema in BGC, Taguig City, in front of Cinema 1 on 30 November 2022. Claiming of tickets starts at 1:00 PM and ends at 2:30 PM.</li>
                                                <li >To claim the prize, winners must present 1 valid ID and the official notification message received via SMS or email upon claiming of movie tickets. </li>
                                                <li >All qualified entries will be drawn via electronic raffle draw (wheelofnames.com) with the virtual supervision of a DTI (Department of Trade and Industry) Representative on 21 November 2022.</li>
                                                <li >Winners should claim their prizes on the day of the block screening on 30 November 2022 from 1:00PM to 2:30PM or prizes will be forfeited in favor of PawnHero Pawnshop with prior approval of DTI.</li>
                                                <li >All employees of PawnHero Pawnshop and their relatives up to the second degree of consanguinity or affinity are disqualified from joining this promo.</li>
                                                <li >Per DTI Fair Trade Permit No. FTEB-152166 Series of 2022</li>
                                            </ul>   
                                        </div>
                                    </div>
                                    <div className='promo-terms contain'>
                                        <p className='promo-terms-head bottom'>
                                        Control Measures:
                                        </p>
                                        <div className='promo-terms-item'>
                                            <ul className='list-decimal pad-left'>
                                                <li >A DTI representative will be invited to be present during the actual drawing of the raffle winners.</li>
                                                <li >PawnHero Pawnshop will prepare the system-generated raffle list of qualified entries before the draw subject to the verification of DTI anytime.</li>
                                                <li >Winners will be drawn one at a time, using their unique electronic pawn ticket numbers.</li>
                                                <li >After the winner is drawn, it will be verified by representatives from PawnHero Pawnshop and DTI.</li>
                                                <li >The list of winners will be given to the DTI Representative after the draw.</li>
                                            </ul>   
                                        </div>
                                    </div>

                                    <div className='promo-terms contain'>
                                        <p className='promo-terms-head center bottom'>
                                        Items we accept as collateral:
                                        </p>
                                        <div className='promo-terms-item'>  
                                            <p className='collateral-items-item'>
                                                Designer Bags • Luxury Watches • Smartphones • Laptops
                                            </p>
                                            <p className='collateral-items-item'>
                                                Gaming Consoles • Cameras • Tablets • Televisions • Soundbars and Speakers
                                            </p>
                                        </div>
                                    </div>
                                    <div className='promo-terms contain'>
                                        <p className='promo-terms-head center bottom large '>
                                        How to pawn online (website and app)
                                        </p>
                                        <div className='promo-terms-item'>
                                            <ul className='list-decimal pad-left'>
                                                <li className='bottom'>
                                                    Create your pawn ticket through our website or mobile app. Click the “Pawn now” button to start. <br></br><br></br>
                                                    Reminder: Choose what best fits your item’s description for a more accurate system-generated initial appraised value offer. Final offer depends on your item’s condition, inclusions and more upon physical inspection.
                                                </li>
                                                <li className='bottom'>Once you’ve created your pawn ticket, your item will be in queue for next-day pick-up. Please keep your lines open on your scheduled pick-up date as our in-house courier may contact you for confirmation. Your item will be brought to our physical pawnshop for final appraisal.</li>
                                                <li className='bottom'>You will receive an SMS/email notification of our final loan offer on the same day your item is picked up. Once you accept our final loan offer, your loan will be released right away.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='promo-terms contain'>
                                        <div className='promo-terms-item top-space'>
                                            <p className='left'>
                                                Do you prefer the traditional way of pawning? You may visit our physical pawnshop at the 7th Floor, Avecshares Center, 38th St., Bonifacio Global City, Taguig City. We're open Monday to Saturday, 9:00AM to 7:00PM.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='promo-terms contain'>
                                        <div className='promo-terms-item top-space'>
                                            <p className='left'>
                                                Learn more about pawning <Link to='/pawning' className='learn-more-btn-pawning'> here</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="button-container">
                                        <a 
                                        className="button orange"
                                        href='/pawn' 
                                        target='_blank' 
                                        rel='noopener noreferrer'>
                                            Pawn now
                                        </a>
                                    </div>
                                    <div className="promo-details-container space-container">
                                        <div className='bsp-pioneer right'>
                                            <img alt="BSP" src="https://d1v5w8bodpeh4i.cloudfront.net/assets/bsp-white-label.svg" ></img>
                                        </div>
                                        <div className='bsp-pioneer left'>
                                            <img alt="Pioneer" src="https://d1v5w8bodpeh4i.cloudfront.net/assets/pioneer-white-label.svg" ></img>
                                        </div>
                                    </div>
                                </div>
                        </ContainerStyled>
                    </PromoLandingStyle>
                </SectionStyled>
                <ShortInfo />
            </Layout> 
        </>
    );
};

export default PreHoliday; 