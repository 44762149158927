import React, { Fragment } from 'react';

// components
import Layout from '../layout';

// style
import { PawnaloPageStyle } from '../../styles/pawnalo.style';
import { ContainerStyled } from '../../styles/layout.style';
import { Link } from 'react-router-dom';
import ShortInfo from '../home/shortInfo';
import { Helmet } from 'react-helmet';

const PawnaloGoldPage = () => {
  const gadgets = [
    {
      title: '14K Gold',
      image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-1.png',
      slug: 'jewelry/gold',
    },
    {
      title: '18K Gold',
      image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-2.png',
      slug: 'jewelry/gold',
    },
    {
      title: '21K Gold',
      image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-3.png',
      slug: 'jewelry/gold',
    },
    {
      title: '22K Gold',
      image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-4.png',
      slug: 'jewelry/gold',
    },
    {
      title: '24K Gold',
      image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-5.png',
      slug: 'jewelry/gold',
    },
    {
      title: 'Other Jewelry',
      image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-6.png',
      slug: 'jewelry',
    },
  ];
  return (
    <>
      <Layout>
        <Helmet>
          <title>
            Your Pinoy Online Pawnshop for Gold and Jewelry - PawnHero - Best
            Trusted Sanglaan ng Gold / Ginto / Alahas - Pawn - Sangla - Loan -
            Pawnalo
          </title>
        </Helmet>
        <PawnaloPageStyle className='gold'>
          <ContainerStyled>
            <div className='breadcrum'>
              <p>
                <Link to='/' id='pawnalo_home_link'>
                  Home
                </Link>{' '}
                • <span>Pawnalo Sa Gold Appraisal</span>
              </p>
            </div>
          </ContainerStyled>

          <section className='banner'>
            <h1>
              <img
                className='promo-banner'
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo_gold-fix-3.svg'
                alt='pawnalo gold'
              />
            </h1>
            <h2>
              Pawnalo ka sa gold and jewelry appraisal ng PawnHero Pawnshop!
            </h2>
          </section>

          <section className='gadget-we-accept'>
            <ContainerStyled>
              <h2>Get FREE initial appraisal</h2>

              <div className='gadgets-wrapper'>
                <div>
                  {gadgets.map((e, i) => (
                    <Fragment key={i}>
                      <Link
                        className={`gadget `}
                        id={`pawnalo_gadget_${e.slug}`}
                        to={`/pawn/${e.slug}`}>
                        <div className='img-wrapper'>
                          <img alt={e.title} src={e.image} />
                        </div>
                        <p>{e.title}</p>
                      </Link>
                    </Fragment>
                  ))}
                </div>
              </div>

              <Link
                className='btn-pawn-now btn'
                id='pawnalo_pawn_link'
                to='/pawn/jewelry'>
                Pawn now
              </Link>
            </ContainerStyled>
          </section>

          <section className='content'>
            <ContainerStyled>
              <p>
                Pawnalo ka dahil mas pinataas ang gold and jewelry appraisal ng
                PawnHero Pawnshop! We now offer up to 25% higher appraisal rate
                vs. previous PawnHero valuation! <br />
                <br />
                And for a limited time, we offer a fixed 3.5% interest rate,
                <br />
                one of the lowest in the market! This means PawnHero customers
                now get higher loan amounts and enjoy lower interest rates!
              </p>
              <div className='promo-content'>
                <div>
                  <div className='promo-detail-context'>
                    <div>
                      <p>Up to</p>
                      <p className='highlight'>25%</p>
                      <p className='note'>
                        higher appraisal
                        <span
                          style={{
                            fontSize: '8px',
                          }}>
                          than previous PawnHero gold valuation
                        </span>
                      </p>
                    </div>
                    <div className='center-elem'>
                      <p>with</p>
                    </div>
                    <div>
                      <p>Fixed</p>
                      <p className='highlight'>3.5%</p>
                      <p className='note'>
                        lower interest rate<span className='mark'>*</span>
                        <span
                          style={{
                            fontSize: '8px',
                          }}>
                          vs. selected pawnshops
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='breakdown-sample-wrapper'>
                <p>Sample breakdown</p>

                <div className='breakdown-wrapper'>
                  <div className='breakdown gold'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-sample-large.png'
                      alt='GOLD 24 KARAT'
                    />
                    <div>
                      <p className='title'>GOLD</p>
                      <p>24 KARAT</p>
                    </div>
                  </div>
                  <div className='breakdown'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-sample-2.svg'
                      alt='HIGHER APPRAISAL RATE NOW Get as much as ₱33,600 *Current PawnHero valuation vs BEFORE Only ₱26,880 *Previous PawnHero valuation'
                    />
                  </div>
                  <div className='breakdown'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo-gold-sample-3.svg'
                      alt='LOWER INTEREST RATE PAWNHERO 3.5% FIXED INTEREST RATE* vs PAWNSHOP X 3-5% INTEREST RATE'
                    />
                  </div>
                </div>

                <p className='disclamer'>
                  <span className='green'>*</span>Based on the data gathered
                  during Q2 of 2021 <br />
                </p>

                <div className='terms-and-conditions' id='terms'>
                  <p>
                    <span>*</span> Terms and Conditions
                  </p>
                  <div>
                    <p>
                      1. For the customer to be eligible for the promo, he/she
                      must apply for a loan ticket within the promo duration
                      June 21 to September 30, 2021, EXTENDED until December 31,
                      2021 (“Promo Period”).
                    </p>
                    <p>
                      2. This promo is valid for first-time and returning
                      PawnHero Pawnshop clients pawning GOLD or any JEWELRY
                      only.
                    </p>
                    <p>
                      3. Qualified transactions eligible for loan releases
                      within the Promo Period shall be entitled to a fixed 3.5%
                      interest rate on the first month and on each approved
                      succeeding loan renewal.
                    </p>
                    <p>
                      4. The regular term of “3.5% interest rate or PHP 500
                      interest, whichever is higher” shall not apply for GOLD
                      and JEWELRY collateral-based loans during the Promo
                      Period.
                    </p>
                    <p>
                      5. For multiple loan applications per client, ALL approved
                      gold and jewelry collateral-based loans during the Promo
                      Period will be eligible for the promo.
                    </p>
                    <p>
                      6. This promo may be availed via website at
                      www.pawnhero.ph or via walk-in at the PawnHero Pawnshop
                      office located at 7/F, Avecshares Center, BGC, Taguig City.
                    </p>
                    <p>
                      7. Per DTI Fair Trade Permit No. FTEB-121316 Series of
                      2021
                    </p>
                  </div>
                </div>
              </div>
            </ContainerStyled>
          </section>

          <section className='how-to'>
            <ContainerStyled>
              <h2>How to #PawnSafelyAtHome</h2>
              <ol>
                <li>
                  Click Pawn Now to pawn your valued item and create your pawn
                  ticket.
                </li>
                <li>
                  Fill out the required details of your online pawn ticket then
                  book your item pick-up schedule. Our certified in-house
                  courier (Pawntrooper) will be at your specified address to
                  pick up your collateral. Enjoy loan approval within 1 DAY only
                  after your collateral has been picked up and appraised!
                </li>
                <li>
                  Once you’ve accepted our final loan offer, you may opt to cash
                  out via bank transfer or any InstaPay and PESONet partner
                  banks and electronic money issuers. Fast and secure!
                </li>
              </ol>
              <p>
                Enjoy convenient online transaction even after you
                #PawnSafelyAtHome! You may pay redemption of pawned items online
                and submit your proof of payment through the PawnHero website.
              </p>
            </ContainerStyled>
          </section>
          <section className='reviews'>
            <ContainerStyled>
              <h2>What our customers are saying</h2>

              <div className='review-wrapper'>
                <div className='review-block'>
                  <img
                    className='rating-img '
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/rating-4.svg'
                    alt='4 star'
                  />
                  <p className='title'>Successful pawning transaction</p>
                  <p className='review'>
                    Customer service is top notch, paying off the loan and the
                    interest is so easy with their online payment scheme and
                    several options, my watch appraisal although it was barely
                    used seems to be just fine. They safely seal the item. All
                    in all it was a good, easy and hassle free transaction from
                    pawning to returning back the item.
                  </p>
                  <p className='item'>
                    - Pawned a Tudor Prince Oysterdate Luxury Watch for
                    ₱7,000.00
                  </p>
                </div>
                <div className='review-block'>
                  <img
                    className='rating-img '
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/rating-5.svg'
                    alt='5 star'
                  />
                  <p className='title'>Highly commended for their services</p>
                  <p className='review'>
                    1 day process only, my item got picked up after lunch and I
                    got the cash after my item was appraised the same day. High
                    appraisal & has the lowest interest rate compared to
                    traditional pawnshop. Thank you Pawnhero!
                  </p>
                  <p className='item'>
                    - Pawned a Sony A7 Mark III Camera for ₱30,865.04
                  </p>
                </div>
              </div>
            </ContainerStyled>
          </section>
          <ShortInfo />
        </PawnaloPageStyle>
      </Layout>
    </>
  );
};

export default PawnaloGoldPage;
