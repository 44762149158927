import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getBanks = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/coins/bank', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
