import { UPDATE_CATEGORY } from '../actions/category';
import update from 'immutability-helper';

const category = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CATEGORY:
      return update(state, { $merge: action.category });
    default:
      return state;
  }
};

export default category;
