import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from '@ramda/isempty/isEmpty';

import { pick } from 'ramda.pick/pick';

const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const { ActivityStyled } = require('../../styles/home.style');
const { ContainerStyled } = require('../../styles/layout.style');

const Activity = ({ release }) => {
  const _release =
    Array.isArray(release) && !isEmpty(release)
      ? release
          .map((e) => ({
            date: e.date,
            amount: formaterNumber(e.amount),
            itemName: e.items.map((e) => e.item_name).join('-'),
          }))
          .splice(0, 20)
      : [];

  const stringGenerator = (items = [], mode = 'desktop') => {
    let _string = '';
    if (mode === 'desktop') {
      _string = (
        <>
          <p>
            {items
              .map((item) => {
                return item.date + ' • P' + item.amount + ' ' + item.itemName;
              })
              .join(' | ')}
          </p>
        </>
      );
    } else {
      _string = (
        <>
          <p>
            {items.map((item, index) => {
              return (
                <Fragment key={index}>
                  <span> Latest Pawn Transactions </span>
                  {item.date + ' • P' + item.amount + ' ' + item.itemName}
                  <span className='spacer' />
                </Fragment>
              );
            })}
          </p>
        </>
      );
    }
    return _string;
  };
  return (
    <>
      {_release && !isEmpty(_release) && (
        <>
          <ActivityStyled>
            <ContainerStyled>
              <div className='desktop-marque'>
                <p className='title'>Latest Pawn Transactions</p>
                <div className='marque'>
                  <div className='marque-content-wrapper'>
                    {stringGenerator(_release, 'desktop')}
                  </div>
                </div>
              </div>

              <div className='mobile-marque'>
                <div className='marque'>
                  <div className='marque-content-wrapper'>
                    {stringGenerator(_release, 'mobile')}
                  </div>
                </div>
              </div>
            </ContainerStyled>
          </ActivityStyled>
        </>
      )}
    </>
  );
};

const mapStateToProps = pick(['release']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
