import { pick } from 'ramda.pick/pick';

//
import React from 'react';
import { connect } from 'react-redux';

import TicketDetails from '../../components/dashboard/ticketDetails';
import { menus } from '../../components/dashboard/layout';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
const {
  ContentStyled,
  DashboardNavHeader,
} = require('../../styles/dashboard.style');

const TicketViewPage = ({ dashboard }) => {
  const { activeMenu } = dashboard;
  const { ticketNum } = useParams();

  return (
    <ContentStyled>
      <DashboardNavHeader>
        <Link to='/dashboard'>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/header-nav-back-ico.svg'
            alt='back'
          />
        </Link>
        <div>
          <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
          <h1>Pawn Ticket {ticketNum}</h1>
        </div>
      </DashboardNavHeader>

      <TicketDetails withHeader={false} />
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard']);
export default connect(mapStateToProps, {})(TicketViewPage);
