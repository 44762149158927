import {
  getMyNotification,
  getMyUnreadCountNoti,
  getNotificationById,
} from '../utils/notification';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export const updateNotification = (notification) => {
  return {
    type: UPDATE_NOTIFICATION,
    notification,
  };
};

export const fetchMyNotification = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getMyNotification()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMyUnreadCountNoti = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getMyUnreadCountNoti()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchNotificationById = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getNotificationById(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
