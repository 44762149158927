import styled from 'styled-components';

// style
export const FooterStyled = styled.footer`
  background: #112b41;
  padding: 20px 0;

  @media (max-width: 768px) {
    ${(props) =>
      props.marginButtom !== false
        ? `margin-bottom:${props.marginButtom}px;`
        : ''}
  }

  ${(props) => (!props.showFooter ? 'display: none;' : '')}
  ${(props) =>
    props.isBottomFix
      ? `position : fixed; bottom : 0; left: 0; width: 100%; `
      : ''}
  div.footer-content {
    display: flex;

    div.links-wrapper {
      nav {
        ul {
          display: flex;
          list-style: none;

          li {
            margin-right: 10px;
            a {
              color: #fff;
              font-size: 10px;
              padding: 0;
            }
          }
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    div.social-wrapper {
      margin-left: auto;

      nav {
        ul {
          display: flex;
          list-style: none;

          li {
            margin-right: 10px;
            a {
              padding: 0;
              img {
                width: 24px;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        display: block;
        margin: auto;
      }
    }
  }

  div.footer-remarks {
    margin-top: 10px;

    img.footer-bsp {
      width: 100%;
      max-width: 200px;
      display: block;
      margin: auto;
    }

    p {
      display: block;
      text-align: center;
      font-size: 8px;
      color: #ffffff;
      margin-top: 10px;

      & > a {
        color: #ffffff;
        font-size: 8px;

        &.underline {
          text-decoration: underline !important;
        }
      }
    }
  }
`;
