import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import CollapseMenu from './collapseMenu';

// utilities
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import pick from 'ramda.pick';
import { connect } from 'react-redux';
import { updateDashboard } from '../../../actions/dashboard';

// styles
import { ContainerStyled } from '../../styles/layout.style';
import { HeaderStyled } from '../../styles/header.style';
import CookieNotice from '../cookieNotice';
import { isEmpty } from '@ramda/isempty/isEmpty';

const Header = ({
  headerRef,
  showHeader = true,
  dashboard,
  doUpdateDashboard,
  loadingHandler,
  user,
  page,
}) => {
  // props discription
  // showheader to control header display style --default true
  const location = useLocation();
  const { pathname } = location;
  const { acceptedCookied = false } = page;

  const staticPagesPaths = [
    { path: '/', exact: true },
    { path: '/items-we-accept', exact: true },
    { path: '/how-pawnhero-works', exact: true },
    { path: '/about-us', exact: true },
    { path: '/cashout-and-payments', exact: true },
    { path: '/faq', exact: true },
    { path: '/contact-us', exact: true },
    { path: '/press', exact: true },
    { path: '/service-advisory', exact: true },
    { path: '/terms-of-use', exact: true },
    { path: '/privacy-policy', exact: true },
    { path: '/login', exact: true },
    { path: '/guest', exact: true },
    { path: '/help' },
    { path: '/manage/confirm_loan_payment', exact: true },
    {
      path: ['/items-we-accept/:mode', '/items-we-accept/:mode/:slug'],
      exact: true,
    },
    { path: '/pawnalo-gadget-appraisal', exact: true },
    { path: '/app', exact: true },
    { path: '/pawning', exact: true },
    { path: '/unang-sangla', exact: true }
  ];

  const _isStaticPage = staticPagesPaths.find((pagePath) => {
    const _match = matchPath(pathname, pagePath);

    return _match ? true : false;
  });
  const isStaticPage = _isStaticPage ? true : false;

  const flexPagePaths = [
    {
      path: '/dashboard',
    },
  ];

  const _isFlexPage = flexPagePaths.find((pagePath) => {
    const _match = matchPath(pathname, pagePath);
    return _match ? true : false;
  });

  const isFlexPage = _isFlexPage ? true : false;

  const dashboardPagePaths = [
    {
      path: '/dashboard',
    },
  ];
  const _isDashboardPage = dashboardPagePaths.find((pagePath) => {
    const _match = matchPath(pathname, pagePath);
    return _match ? true : false;
  });

  const isDashboardPage = _isDashboardPage ? true : false;

  // states
  const [collapse, collapseHandler] = useState(false);

  return (
    <>
      <HeaderStyled
        ref={headerRef}
        isStaticPage={isStaticPage}
        showHeader={showHeader}>
        {!acceptedCookied && !isDashboardPage && <CookieNotice />}
        <div className='header-titles-wrapper'>
          <ContainerStyled className='container' isFlexPage={isFlexPage}>
            <div className={`header-titles ${isDashboardPage && user.mode === 'normal' ? 'dashboard' : ''}`}>
              
              <div className='brand-wrapper'>
                <Link to='/' id='header_home_link'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnhero-logo.svg'
                    alt='pawnhero.ph'
                    onLoad={() => {
                      loadingHandler(false);
                    }}
                  />
                </Link>
              </div>
              <div className='tel-wrapper'>
                <a href='tel:8828-2274' id='header_phone_link'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnhero-tel.svg'
                    alt='8828-2274'
                  />
                </a>
              </div>
              {isStaticPage && (
                <div
                  className={`menu-btn-wrapper static-menu ${
                    collapse ? 'open' : 'close'
                  }`}
                  onClick={() => collapseHandler(!collapse)}>
                  {collapse ? (
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/down-arrow-white-ico.svg'
                      alt='menu'
                    />
                  ) : (
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/menu-icon.svg'
                      alt='menu'
                    />
                  )}
                </div>
              )}
            </div>
          </ContainerStyled>
        </div>
        <div className='header-nav-wrapper'>
          <ContainerStyled className='container' isFlexPage={isFlexPage}>
            <Collapse isOpened={collapse}>
              <div className='nav-wrapper'>
                <div className='tel-wrapper'>
                  <a href='tel:8828-2274' id='header_phone_link'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnhero-tel.svg'
                      alt='8828-2274'
                    />
                  </a>
                </div>
                <nav>
                  <ul>
                    <li>
                      <Link
                        to='/app'
                        id='header_app_link'>
                        App <sup className="new-badge">New!</sup>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/items-we-accept'
                        id='header_items_we_accept_link'>
                        Items We Accept
                      </Link>
                    </li>
                    <li>
                      <CollapseMenu title='Frequently Asked Questions'>
                        <Link
                          to='/how-pawnhero-works'
                          id='header_how_pawnhero_works_link'>
                          How PawnHero Works
                        </Link>
                        <Link
                          to='/cashout-and-payments'
                          id='header_cashout_and_payments_link'>
                          Cashout and Payments
                        </Link>
                        <Link to='/faq' id='header_faq_link'>
                          FAQs
                        </Link>
                      </CollapseMenu>
                    </li>
                    <li>
                      <a href='https://blog.pawnhero.ph/' id='header_blog_link'>
                        Blog
                      </a>
                    </li>
                    <li>
                      <CollapseMenu title='About PawnHero'>
                        <Link to='/pawning' id='header_pawning_link'>
                          Pawning
                        </Link>
                        <Link to='/about-us' id='header_about_us_link'>
                          About Us
                        </Link>
                        <Link to='/service-advisory' id='header_service_advisory_link'>
                          Service Advisory
                        </Link>
                        <Link to='/contact-us' id='header_contact_us_link'>
                          Contact Us
                        </Link>
                      </CollapseMenu>
                    </li>
                    <li>
                      {user && !isEmpty(user) && user.user ? (
                        <>
                          <Link
                            to='/dashboard'
                            id='header_dashboard_link'
                            className='dashboard-action-button'>
                            {user.user && user.user.display_photo_thumb ? (
                              <img src={user.user.display_photo_thumb} alt='' />
                            ) : (
                              <img
                                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/front-profile-default-ico.svg'
                                alt=''
                              />
                            )}
                            My PawnHero Account
                          </Link>
                        </>
                      ) : (
                        <>
                          <CollapseMenu title='Manage Pawn Ticket'>
                            <Link
                              to='/guest'
                              id='header_how_pawnhero_works_link'>
                              Redeem My Item
                            </Link>
                            <Link
                              to='/guest'
                              id='header_cashout_and_payments_link'>
                              Renew My Loan
                            </Link>
                            <Link
                              to='/manage/confirm_loan_payment'
                              id='header_confirm_loan_payment_link'>
                              Confirm Loan Payment
                            </Link>
                          </CollapseMenu>
                          {/* <Link to='/login' id='header_login_link'>
                            Login/Signup
                          </Link> */}

                        </>
                      )}
                    </li>
                  </ul>
                </nav>
                          
                <Link
                  to='/pawn'
                  className='btn-pawn-now btn'
                  id='header_pawn_now_link'>
                  Pawn now
                </Link>
              </div>
            </Collapse>
          </ContainerStyled>
        </div>
      </HeaderStyled>
    </>
  );
};

const mapStateToProps = pick(['dashboard', 'user', 'page']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateDashboard: (dashboard) => dispatch(updateDashboard(dashboard)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
