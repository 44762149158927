import {
  getCategories,
  getCategory,
  getCategoryDetailBySlug,
} from '../utils/category';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

export const updateCategory = (category) => {
  return {
    type: UPDATE_CATEGORY,
    category,
  };
};

export const fetchCategories = () => () => {
  return new Promise((resolve, reject) => {
    getCategories()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCategory = (id) => () => {
  return new Promise((resolve, reject) => {
    getCategory(id)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCategoryDetailBySlug = (slug) => () => {
  return new Promise((resolve, reject) => {
    getCategoryDetailBySlug(slug)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
