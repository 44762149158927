import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// utility
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import {
  fetchRedeemDirectData,
  fetchRenewalApproved,
  fetchSummaryTicket,
} from '../../../actions/ticket';

// child component
import Layout from '../layout';
import ReactModal from '../../components/shared/reactModal';
import { matchPath } from 'react-router-dom/cjs/react-router-dom.min';

const { ContainerStyled } = require('../../styles/layout.style');
const { PawnThankyouStyledV2 } = require('../../styles/pawn-thankyou.style');

const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const DirectThankyou = (props) => {
  const {
    doFetchSummaryTicket,
    doFetchRenewalApproved,
    doFetchRedeemDirectData,
  } = props;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ticketNum = query.get('ticketno');

  const history = useHistory();
  const { pathname } = useLocation();

  const _isRenewal = matchPath(pathname, {
    path: '/pawn/renewal/*',
    exact: false,
  });

  const thankyouDateExpiry =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.thankyouDateExpiry
      ? props.location.state.thankyouDateExpiry
      : false;

  const now = moment().tz(timezone).unix();

  const [loading, loadingHandler] = useState(true);
  const [renewalData, renewalDataHandler] = useState({});
  const [redeemData, redeemDataHandler] = useState({});
  const [user, userHandler] = useState(null);
  const [modalToggle, modalToggleHandler] = useState(false);
  const [error, errorHandler] = useState(false);

  useEffect(() => {
    const _test = thankyouDateExpiry > now;

    if (!_test) {
      // expired
      history.push('/guest');
    } else {
      loadingHandler(true);
      doFetchSummaryTicket({ ticketNum })
        .then(({ data }) => {
          const { user } = data;
          userHandler(user);

          if (_isRenewal) {
            doFetchRenewalApproved({ ticketNum })
              .then((response) => {
                const { data, success } = response;

                if (success) {
                  renewalDataHandler(data);
                } else {
                  errorHandler(
                    'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                  );
                }
                loadingHandler(false);
              })
              .catch((err) => {
                console.log('doFetchRenewalApproved err', err);
                errorHandler(
                  'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                );
                loadingHandler(false);
              });
          } else {
            doFetchRedeemDirectData({ ticketNum })
              .then((response) => {
                const { data, success } = response;

                if (success) {
                  redeemDataHandler(data);
                } else {
                  errorHandler(
                    'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                  );
                }
                loadingHandler(false);
              })
              .catch((err) => {
                console.log('doFetchRedeemDirectData err', err);
                errorHandler(
                  'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                );
                loadingHandler(false);
              });
          }
        })
        .catch((err) => {
          console.log('err', err);
          loadingHandler(false);
        });
    }

    return () => {
      modalToggleHandler(false);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <PawnThankyouStyledV2>
          {loading ? (
            <>
              <div style={{ padding: '50px 0', width: '100%' }}>
                <img
                  className='loader'
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                  alt='loading'
                  style={{
                    width: '32px',
                    height: '32px',
                    margin: 'auto',
                    display: 'flex',
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {error ? (
                <>
                  <ContainerStyled>
                    <p
                      className='error'
                      style={{ textAlign: 'center', marginTop: '10px' }}>
                      {error}
                    </p>
                  </ContainerStyled>
                </>
              ) : (
                <section className='banner'>
                  <ContainerStyled>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou.svg'
                      alt='thank you'
                    />
                    <h2>Thank you, {user ? user.firstName : ''}!</h2>
                    <h3>We will now wait for your loan repayment.</h3>
                    <p className='highnotes'>
                      Your amount due is ₱{' '}
                      {formaterNumber(
                        _isRenewal
                          ? renewalData.amount_to_be_paid
                          : redeemData.amount
                      )}
                      .
                    </p>

                    <p className='notes'>
                      Once you have repaid your loan in cash at PawnHero office,
                      or have deposited the payment through one of{' '}
                      <span
                        onClick={() => modalToggleHandler(true)}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}>
                        our bank accounts
                      </span>
                      , you may proceed with validating your repayment{' '}
                      <Link to='/manage/confirm_loan_payment'>here</Link>.
                    </p>

                    <div className='cta-wrapper'>
                      <Link to='/' className='btn'>
                        Back to Home
                      </Link>
                    </div>
                  </ContainerStyled>
                </section>
              )}
            </>
          )}

          <ReactModal
            background={'rgba(0, 0, 0, 0.25)'}
            toggle={modalToggle}
            toggleHandler={modalToggleHandler}
            contentContainerStyle={{
              background: '#327FBE',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
              maxWidth: '554px',
              width: '100%',
              minHeight: '40vh',
              padding: '15px',
            }}>
            <>
              <div className='survey-modal-content'>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/modal-direct-succ.svg'
                  alt='Direct Payment'
                  className='logo'
                />
                <h3>PawnHero Direct Payment</h3>

                <div className='content-row' style={{ marginTop: '10px' }}>
                  <p style={{ fontStyle: 'italic', textAlign: 'center' }}>
                    You can either repay your loan in cash at PawnHero office,
                    or deposit the payment directly to our bank accounts below:
                    <br />
                    <br />
                    <strong>Pawnhero Pawnshop Philippines, Inc.</strong>
                    <br />
                    Security Bank - 00000 1682 7011
                    <br />
                    BDO – 0071 000 58845
                  </p>
                </div>

                <button
                  className='btn'
                  onClick={() => modalToggleHandler(false)}>
                  Got it
                </button>
              </div>
            </>
          </ReactModal>
        </PawnThankyouStyledV2>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
  doFetchRenewalApproved: (payload) => dispatch(fetchRenewalApproved(payload)),
  doFetchRedeemDirectData: (payload) =>
    dispatch(fetchRedeemDirectData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectThankyou);
