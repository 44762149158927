import update from 'immutability-helper';
import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER,
  UPDATE_USER_PROFILE,
} from '../actions/user';

export default function user(state = {}, action) {
  switch (action.type) {
    case LOGIN_USER:
      return update(state, { $merge: action.user });
    case LOGOUT_USER:
      return {};
    case UPDATE_USER:
      return update(state, { $merge: action.user });
    case UPDATE_USER_PROFILE: {
      const { user } = state;

      const _newUser = { ...user, ...action.user };

      return update(state, { $merge: { user: _newUser } });
    }
    default:
      return state;
  }
}
