import React from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updatePawn } from '../../../actions/pawn';

import { CheckboxStyled } from '../../styles/field-checkbox.style';
import { isNil } from '@ramda/isnil/isNil';

const Checkbox = ({ pawn, entities, code, doUpdatePawn }) => {
  const { formValues = {}, selectedEntities = {} } = pawn;

  const onChange = (e) => {
    const dom = e.target;
    const parent = dom.parentElement;
    const name = parent.getAttribute('name');
    const { value } = dom;

    if (dom.checked) {
      const _formValues = {
        ...formValues,
        [name]: [
          ...(!isNil(formValues[name]) ? formValues[name] : []),
          parseInt(value),
        ],
      };

      const _selectedEntities = entities.filter((e) =>
        _formValues[name].includes(e.attribute_entity_id)
      );

      doUpdatePawn({
        formValues: _formValues,
        selectedEntities: { ...selectedEntities, [code]: _selectedEntities },
      });
    } else {
      const _formValues = {
        ...formValues,
        [name]: formValues[name].filter((e) => e !== parseInt(value)),
      };

      const _selectedEntities = entities.filter((e) =>
        _formValues[name].includes(e.attribute_entity_id)
      );

      doUpdatePawn({
        formValues: _formValues,
        selectedEntities: { ...selectedEntities, [code]: _selectedEntities },
      });
    }
  };

  const dom = entities.map((entry, key) => {
    return (
      <CheckboxStyled key={key} name={code}>
        <input
          id={entry.attribute_entity_id}
          type='checkbox'
          value={entry.attribute_entity_id}
          name={code}
          onChange={onChange}
          checked={
            !isNil(formValues) &&
            !isNil(formValues[code]) &&
            formValues[code].includes(entry.attribute_entity_id)
              ? true
              : false
          }
        />
        <label htmlFor={entry.attribute_entity_id}>
          <div className='entry-icon-wrapper'>
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/selected-icon.svg'
              alt='seleted'
            />
          </div>
          <div className='entry-label-wrapper'>
            <p>{entry.attribute_entity_name}</p>
          </div>
        </label>
      </CheckboxStyled>
    );
  });

  return dom;
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
