import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';

// redux helpers
import pick from 'ramda.pick';
import { connect } from 'react-redux';

// utilities
import { useLocation, Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import { isNil } from '@ramda/isnil/isNil';
// import { Helmet } from 'react-helmet';
import ChatPlugin from '../../components/plugins/chat';

const { LayoutStyled } = require('../../styles/layout.style');

const Layout = ({
  children,
  pageModalActive = false,
  bannerRef = null,
  style,
  page,
}) => {
  // props discription
  // pageModalActive - if page modal is active set this to active to disable vertical scroll *default is false

  const { acceptedCookied = false } = page;

  const location = useLocation();
  const { pathname } = location;

  const homePath = [
    {
      path: '/',
      exact: true,
    },
  ];

  const _isHomePage = homePath.find((pagePath) => {
    const _match = matchPath(pathname, pagePath);

    return _match ? true : false;
  });
  const isHomePage = _isHomePage ? true : false;

  const headerRef = useRef(null);
  const [headerHeight, headerHeightHandler] = useState(80);

  const footerRef = useRef(null);
  const [footerHeight, footerHeightHandler] = useState(193);

  const [loading, loadingHandler] = useState(true);

  const onResize = () => {
    const { current: header } = headerRef;
    const { height } = (header && header.getBoundingClientRect()) || {};

    const { current: footer } = footerRef;
    const { height: footerH } =
      (footer && footer.getBoundingClientRect()) || {};

    headerHeightHandler(height);
    footerHeightHandler(footerH);
  };

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [loading]);

  // scrolling
  const [mobilePawnToggle, mobilePawnToggleHandler] = useState(false);
  const onSroll = () => {
    if (!isNil(bannerRef) && isHomePage) {
      const { current } = bannerRef;
      const { height: bannerHeight, top } = current.getBoundingClientRect();

      const { current: header } = headerRef;
      const { height } = header.getBoundingClientRect();

      const show = bannerHeight + top - height;

      if (show < 0) {
        mobilePawnToggleHandler(true);
      } else {
        mobilePawnToggleHandler(false);
      }
    }       
  };

  useEffect(() => {
    onSroll();
    window.addEventListener('scroll', onSroll);
    return () => {
      window.removeEventListener('scroll', onSroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // disabled body scrolling
    if (pageModalActive) {
      document.body.classList.add('modal-on');
    } else {
      document.body.classList.remove('modal-on');
    }
  }, [pageModalActive]);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      setTimeout(() => {
        onResize();
      }, 100);
    }

    return () => {
      cancel = true;
    };
  }, [acceptedCookied]);

  const dashboardPagePaths = [
    {
      path: '/dashboard',
    },
  ];
  const _isDashboardPage = dashboardPagePaths.find((pagePath) => {
    const _match = matchPath(pathname, pagePath);
    return _match ? true : false;
  });

  return (
    <>
      {/* <Helmet>
        <title>
        Online Pawnshop Philippines - 1 Day Loan Approval | PawnHero
        </title>
      </Helmet> */}
      <LayoutStyled
        headerHeight={headerHeight}
        footerHeight={footerHeight}
        style={style}>
        <Header headerRef={headerRef} loadingHandler={loadingHandler} />
        <main className='page'>{children}</main>

        {mobilePawnToggle && isHomePage && (
          <div className='mobile-wrapper-pawn-now floating-fixed'>
            <Link to='/pawn' className='btn'>
              Pawn now
            </Link>
          </div>
        )}
        {!_isDashboardPage && <ChatPlugin />}
        {!_isDashboardPage && <Footer marginButtom={isHomePage ? 69 : false} footerRef={footerRef} />}
      </LayoutStyled>
    </>
  );
};

const mapStateToProps = pick(['page']);
export default connect(mapStateToProps)(Layout);
