import React from 'react';

// components
import Layout from '../layout';
import WhyPawnhero from '../../components/whyPawnhero';

// style
import { ContainerStyled } from '../../styles/layout.style';

import { Link } from 'react-router-dom';
import { PolicyStyled } from '../../styles/policy.style';

const ReturnPolicy = () => {
  return (
    <>
      <Layout>
        <PolicyStyled>
          <ContainerStyled>
            <div className='breadcrum'>
              <p>
                <Link to='/'>Home</Link> • <span>Return Policy</span>
              </p>
            </div>
            <div className='content-wrapper'>
              <div className='content'>
                <section>
                  <h2>Return Policy</h2>
                  <p>
                    By booking your item for appraisal, you agree that if your
                    item has been rejected for the reason of aesthetic and
                    authenticity, your item will be returned to your address
                    within 7-10 business days (except Saturday and Sunday) from
                    the day of appraisal.
                    <br />
                    <br />
                    For follow-ups, you may reach us at 8828-2274,
                    customer@pawnhero.ph or via our official Facebook and
                    Instagram accounts.
                  </p>
                </section>
                <p>Last Updated: September 16, 2020.</p>
              </div>
              <WhyPawnhero />
            </div>
          </ContainerStyled>
        </PolicyStyled>
      </Layout>
    </>
  );
};

export default ReturnPolicy;
