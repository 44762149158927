import styled from 'styled-components';

export const SignUpStyled = styled.div`
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 85px 0;

  h1 {
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
  }

  p {
    color: #ffffff;
    text-align: center;
    font-size: 14px;
  }

  .fields-groups-wrapper {
    .fields-wrapper {
      max-width: 360px;
      display: block;
      margin: 30px auto 0;

      .field-group-flex {
        display: flex;
        justify-content: space-between;

        & > .field-group {
          width: 47%;
        }

        @media (max-width: 768px) {
          display: block;
          & > .field-group {
            width: 100%;
          }
        }
      }
      .field-group {
        padding: 14px 0 11px;
        margin-bottom: 5px;
        margin-top: 5px;
        position: relative;

        .field-label-wrapper {
          position: relative;

          label {
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            bottom: 6.5px;
            transition: 0.3s;
            left: 10px;
            color: #8b959c;
          }

          input:focus + label,
          .phone-input.focus + label,
          .ctm-date-picker.focus + label,
          .shared-calendar.focus + label,
          .ctm-select.focus + label,
          &.with-value label {
            display: block;
            bottom: 100%;
            left: 0;
            color: #fff;
            font-size: 12px;
          }

          & > p.disable-preview-field,
          & > input[type='text'],
          & > input[type='password'],
          .phone-input input {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #dadada;
            padding: 5px 10px;
            font-size: 14px;
            outline: none;
            color: #354f65;

            &:disabled {
              background: #dadada;
              color: #354f65;
            }

            &::placeholder {
              color: #dadada;
            }
          }

          &.is-error {
            input {
              border-color: #e2574c !important;
            }
          }

          p.disable-preview-field {
            background: #dadada;
            color: #354f65;
            text-align: left;
            cursor: no-drop;
          }
        }

        p.err-note {
          display: none;
          position: absolute;
          font-style: italic;
          font-size: 8px;
          line-height: 11px;
          color: #e2574c;
        }

        .field-label-wrapper.is-error + p.err-note {
          display: block;
        }

        /* bubble message */
        .bubble-message {
          background: #ffffff;
          border-radius: 5px;
          width: 228px;
          height: fit-content;
          position: absolute;
          right: -250px;
          top: 0;
          bottom: 0;
          margin: auto;
          padding: 20px 10px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #fff;
            position: absolute;
            left: -7px;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          ul {
            font-weight: 600;
            font-size: 14px;
            color: #e2574c;
            margin-left: 20px;
          }

          @media (max-width: 875px) {
            display: none;
          }
        }
      }
    }
  }

  div.bubble-message-general {
    display: none;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px 10px;

    @media (max-width: 875px) {
      display: block;
    }

    ul {
      font-weight: 600;
      font-size: 14px;
      color: #e2574c;
      margin-left: 20px;
    }
  }

  .message-wrapper-existing-user {
    background: #ffffff;
    border-radius: 5px;
    padding: 15px;

    p {
      font-weight: 600;
      font-size: 14px;
      color: #354f65;
      text-align: justify;
    }
  }
  div.action-wrapper {
    display: flex;
    justify-content: center;

    div {
      p.error-text {
        margin-top: 10px;
        color: red;
        font-size: 12px;
        text-align: left;
        font-style: italic;
        outline: none;
      }

      a {
        display: block;
        margin: auto;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        margin-top: 15px;
        text-align: center;

        &:last-child {
          margin-top: 85px;
        }
      }

      button {
        display: block;
        margin: auto;
        background: #ff7019;
        border: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        width: 120px;
        font-size: 18px;
        color: #ffffff;
        margin-top: 25px;
        outline: none;

        &:disabled {
          color: #fff;
          background: #979797;
        }
      }
    }
  }
`;
