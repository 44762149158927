import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getProvinces = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/provinces', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCities = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/cities', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getBarangays = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/barangays', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
export const getProvinceCities = (payload) => {
  const { province_id } = payload;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/cities/province/${province_id}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCityBarangays = (payload) => {
  const { city_id } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/barangays/${city_id}`, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUserLocation = (payload) => {
  // console.log('will fire verify api here', payload);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        '/api/user/address/store',
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
