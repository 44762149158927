import { INIT_ACCEPTANCE, UPDATE_ACCEPTANCE } from '../actions/acceptance';
import update from 'immutability-helper';

const acceptance = (state = {}, action) => {
  switch (action.type) {
    case INIT_ACCEPTANCE:
      return action.acceptance;
    case UPDATE_ACCEPTANCE:
      return update(state, { $merge: action.acceptance });
    default:
      return state;
  }
};

export default acceptance;
