import React, { useState } from 'react';

const Regulatory = () => {
    
    const [learnMore, learnMoreHandler] = useState(false);
    const [continueReading, continueReadingHandler] = useState(false);

    return (<>
        <div id="regulatory" className="section bg-gray" >
            <h2 className="section-title">Regulatory Issuances</h2>
            <h3 className="section-text section-subtitle">BSP Issuances</h3>
            <h3 className="section-text section-subtitle">Manual of Regulations for Non-Bank Financial Institutions, and Issuances of the Bangko Sentral ng Pilipinas and Other Government Agencies</h3>
            <p className="section-text">
                Manual of Regulations for Non-Bank Financial Institutions (“MORNBFI”)
                The MORNBFI is designed to be an authoritative codification of regulations governing non-bank financial institutions supervised by the Bangko Sentral ng Pilipinas (“BSP”) such as pawnshops.
            </p>
            <p className="section-text last-item">
                MORNBFI (<a href="https://www.bsp.gov.ph/Regulations/MORB/2018MORNBFI.pdf" target='_blank' rel='noopener noreferrer'>https://www.bsp.gov.ph/Regulations/MORB/2018MORNBFI.pdf</a>)
            </p>
            { continueReading &&
                <>
                    <h3 className="section-text section-subtitle">Excerpts:</h3>
                    <h3 className="section-text section-subtitle">302-P LOAN LIMIT</h3>
                    <p className="section-text">Pawnshops may grant such amount of loans as may be agreed upon between the parties. The amount of loan shall in no case be less than thirty percent (30%) of the appraised value of the security offered, unless the pawner manifests in writing that he is applying for a lesser amount. Pawnshops shall not under-appraise the security offered for the loan to circumvent the restriction prescribed by this Section.</p>
                    <p className="section-text last-item text-italic">(Circular Nos. 961 dated 02 June 2017 and 938 dated 23 December 2016)</p>
                    <h3 className="section-text section-subtitle">303-P INTEREST AND SURCHARGES</h3>
                    <p className="section-text">The rate of interest including surcharges on any loan or forbearance of money extended by a pawnshop shall be in accordance with market conditions. However, such interest rate should not be iniquitous, unconscionable, or contrary to morals, if not against the law as may be determined by the Court. These shall also be properly declared in accordance with the Truth in Lending Act.</p>
                    <p className="section-text">No pawnshop shall collect interest on loans in advance for a period longer than the original term agreed upon as indicated in the pawn ticket.</p>
                    <p className="section-text">In the absence of express contract as to such rate of interest, the rate of interest for the loan or forbearance of money or credit extended by a pawnshop shall be six percent (6%) per annum.</p>
                    <p className="section-text">Other charges. In addition to interest, pawnshops may impose a maximum service charge of five pesos (P5.00), but in no case to exceed one percent (1%) of the principal loan. Other charges may be imposed provided these are fair and reasonable and are properly disclosed.</p>
                    <p className="section-text last-item text-italic">(Circular Nos. 961 dated 02 June 2017 and 938 dated 23 December 2016)</p>
                    <h3 className="section-text section-subtitle">305-P PAST DUE ACCOUNTS; RENEWAL</h3>
                    <p className="section-text">A loan may be renewed for such amount and period as may be agreed upon between the pawnshop and the pawner, subject to the same conditions provided in this Part for new loans.</p>
                    <p className="section-text">No loan shall be renewed or its maturity date extended unless a new pawn ticket as defined in Sec. 101-P (Definition of terms) shall be issued indicating the new term of the loan agreed upon by the pawnshop and the pawner.</p>
                    <p className="section-text">Right of pawner to redeem pawn within ninety (90) days from maturity. A pawner who fails to pay or renew his loan with a pawnshop on the date it falls due shall have ninety (90) days from the date of maturity of the loan within which to redeem the pawn by paying the principal amount of the loan plus the amount of interest that shall have accrued thereon. The amount of interest due and payable after the maturity date of the loan shall be computed upon redemption based on the sum of the principal loan and interest earned as of the date of maturity.</p>
                    <p className="section-text">In case of a pawned article that is subject to quick obsolescence, i.e., electronic gadgets, the pawner and the pawnee may agree on a shorter redemption period but the same shall not be less than thirty (30) days.</p>
                    <p className="section-text">The following guidelines shall govern the redemption of pawns within ninety (90) days from maturity:</p>
                    <p className="section-text">a. On or before the expiration of the maturity date, a pawnshop shall notify a pawner in writing that the pawn shall be sold or otherwise disposed of in the event the pawner fails to redeem the pawn within the ninety (90)-day grace period, specifying in the same notification the date, hour and place where the sale shall take place.</p>
                    <p className="section-text">The notice shall be sent through the mode of notification agreed upon by the pawner and the pawnshop as indicated at the back of the pawn ticket at the time the loan was granted which may be through text/SMS message, electronic mail, fax or by mail to the residential address. If sent through text/SMS, the pawnshop shall obtain a report from the appropriate Telecommunications Company (TELCO) indicating that a text/SMS message was sent to the mobile phone number given by the pawner. The report of the TELCO shall be made available to Bangko Sentral upon request. In case no specific mode of notification is agreed upon and indicated at the back of the pawn ticket, the mode of notification shall be by ordinary mail. Pawnshops shall exert reasonable effort to notify the pawner and put on record if it is unable to do so. Pawnshops shall maintain proof of the notice to pawner.</p>
                    <p className="section-text">b. If upon the expiration of the ninety (90)-day grace period, the pawner fails to redeem his pawn, the pawnshop may sell or dispose of the pawn: Provided, however, That the pawnshop has published a notice of public auction of unredeemed articles held as security for loans in at least two (2) newspapers circulated in the city or municipality where the pawnshop has its place of business and such publication was done at least six (6) days prior to the date set for the public auction.</p>
                    <p className="section-text">The notice shall be in English, and in either Filipino or the local dialect and shall contain the following:</p>
                    <ul className="section-list list-decimal">
                        <li>Name and address of the pawnshop; and</li>
                        <li>Date, hour and place of the auction sale.</li>
                    </ul>
                    <p className="section-text">In remote areas where newspapers are neither published nor circulated, the publication requirement shall be complied with by posting notices at the city hall or municipal building of the city or municipality and in two (2) other conspicuous public places where the pawnshop has its place of business.</p>
                    <p className="section-text last-item text-italic">(Circular Nos. 974 dated 22 August 2017, 961 dated 02 June 2017, M-2017-003 dated 27 January 2017, and Circular No. 938 dated 23 December 2016)</p>
                    <h3 className="section-text section-subtitle">312-P REDEMPTION OF PAWNS</h3>
                    <p className="section-text">A pawnshop shall not release any pawn without first requiring the pawner to present and surrender the corresponding pawn ticket. If the pawn ticket was lost and could not be presented or surrendered, the pawnshop shall require the owner-pawner to execute and submit an affidavit of loss and shall ascertain the identity of the pawner, to ensure that the pawned item is released only to the owner-pawner.</p>
                    <p className="section-text">The pawnshop shall return the pawn in the same condition when they were first pawned by pawner, upon full settlement of the loan</p>
                    <p className="section-text last-item text-italic">(Circular No. 961 dated 02 June 2017, M-2017-003 dated 27 January 2017, and Circular No. 938 dated 23 December 2016)</p>
                    <h3 className="section-text section-subtitle">313-P PAWN TICKET</h3>
                    <p className="section-text">Pawnshops shall, at the time of the loan, deliver to each pawner a pawn ticket which shall contain the following:</p>
                    <ul className="section-list list-decimal">
                        <li>The business/registered name, address, telephone number, and tax identification number of the pawnshop. The business name indicated in the pawn ticket shall be in accordance with the provision of Sec. 105-P (Registered/Business Name);</li>
                        <li>Name and address of the pawner;</li>
                        <li>Date the loan was granted;</li>
                        <li>Amount of the principal loan and net proceeds;</li>
                        <li>Effective interest rate in percent, indicating if monthly or annually;</li>
                        <li>Service charge in amount;</li>
                        <li>Penalty interest in percent, if any;</li>
                        <li>Appraised value of pawn;</li>
                        <li>Maturity date;</li>
                        <li>Description of the pawn; and</li>
                        <li>Expiry date of the redemption period.</li>
                    </ul>
                    <p className="section-text">Aside from the memorandum book, or its equivalent, required under Section 11 of P.D. No. 114 and the pawn ticket, no other document or instrument shall be used/issued by a pawnshop for any loan granted by it to a pawner/borrower.</p>
                    <p className="section-text">A pawn ticket shall be issued for each pawned article received from the pawner. Stipulations in pawn ticket. The contents of the pawn ticket shall be in accordance to the requirements of Section 12 of P.D. 114, the standard format and terms and conditions of which are prescribed in Appendix P-4.</p>
                    <p className="section-text">Pawnshops may incorporate additional stipulations/information in the pawn ticket (Appendix P-4) without prior approval of the Bangko Sentral.</p>
                    <p className="section-text last-item text-italic">(Circular No. 656 dated 02 June 2009, as amended by Circular Nos. 961 dated 02 June 2017, M-2017-003 dated 27 January 2017, Circular Nos. 938 dated 23 December 2016 and 787 dated 20 February 2013)</p>
                    <h3 className="section-text section-subtitle">501-P ANTI-MONEY LAUNDERING REGULATIONS</h3>
                    <p className="section-text">Pawnshops, including their subsidiaries and affiliates, if any, shall comply with the provisions of Part Nine of Q-Regulations, R.A. No. 9160 (Anti-Money Laundering Act of 2001), as amended, and its Revised Implementing Rules and Regulations (RIRRs).</p>
                    <p className="section-text">Know your customer. Pawnshops who transact with any customer for the first time shall establish the true and full identity of the customer by requiring presentation of at least one (1) valid photo bearing identification document (ID) issued by an official authority or by other similarly reliable means.</p>
                    <p className="section-text">Every pawnshop shall maintain records containing all the information required under Section 11 of P.D. No. 114, and Section 9 (a) of R.A. 9160, as amended for each of their customers.</p>
                    <p className="section-text last-item text-italic">(Circular Nos. 961 dated 02 June 2017 and 938 dated 23 December 2016)</p>
                </>
            }
            { !continueReading &&
                <div className="button-container">
                    <button style={{width: 'auto'}} onClick={() => continueReadingHandler(true)} className="button green">Continue reading</button>
                </div>
            }
            <h3 className="section-text section-subtitle">AMLC Issuances</h3>
            <h3 className="section-text section-subtitle">2018 Implementing Rules and Regulations of Republic Act No. 9160, Otherwise Known as The Anti-Money Laundering Act of 2001, as Amended</h3>
            <h3 className="section-text section-subtitle">Excerpts:</h3>
            <h3 className="section-text section-subtitle">Required Identification Data from Natural Persons</h3>
            <p className="section-text  ">
                For customers who are natural persons, covered persons* shall gather the following identification information and ID before or during account opening or onboarding:
            </p>
            <h3 className="section-text section-subtitle">(a) Identification Information:</h3>
            <ul className="section-list list-decimal">
                <li>Full name;</li>
                <li>Date of birth;</li>
                <li>Place of birth;</li>
                <li>Sex;</li>
                <li>Citizenship or nationality;</li>
                <li>Address;</li>
                <li>Contact number or information, if any;</li>
                <li>Specimen signatures or biometric information;</li>
            </ul>
            { learnMore && 
                <>
                    <h3 className="section-text section-subtitle">Required Identification Data from Juridical Persons</h3>
                    <p className="section-text">For customers that are juridical persons, covered persons shall gather the following identification information and IDs before or during account opening or onboarding:</p>
                    <h3 className="section-text section-subtitle">(a) Identification Information:</h3>
                    <ul className="section-list list-decimal">
                        <li>Full name;</li>
                        <li>Name of authorized representative/transactor/signer;</li>
                        <li>Current office address;</li>
                        <li>Contact number or information, if any;</li>
                        <li>Nature of business; and</li>
                        <li>Specimen signatures or biometric information of the authorized representative/transactor/signer.</li>
                    </ul>
                    <p className="section-text text-italic">*For this purpose, covered persons refer to Pawnhero Pawnshop Philippines, Inc.</p>
                </>
            }
            
            { !learnMore &&
                <div className="button-container">
                    <button style={{width: 'auto'}} onClick={() => learnMoreHandler(true)} className="button green">Continue reading</button>
                </div>
            }
        </div>
    </>); 

};
    
export default Regulatory;