import React, { useState, useEffect } from 'react';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updatePawn } from '../../../actions/pawn';
import { fetchProductAttributeBySlug } from '../../../actions/product';
import { Link, useParams } from 'react-router-dom';

// component
import Step from '../fields/step';
import Submit from '../submit';
import OtpModal from '../otpModal';

// style
import { AttributeStyled } from '../../styles/attribute.style';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import MyContentLoader from '../contentLoader';

const Attribute = ({ pawn, doUpdatePawn, doFetchProductAttributeBySlug }) => {
  const { categorySlug, brandSlug } = useParams();

  const { attribute = [], attrStep } = pawn;
  const { productSlug } = useParams();

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [attrStep]);

  // states
  const [loading, loadingHandler] = useState(false);
  const [err, errHandler] = useState(null);
  const [otpToggle, otpToggleHandler] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      doUpdatePawn({ position: 3 });
      getData({ slug: productSlug });
    }
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const getData = async (slug) => {
    loadingHandler(true);
    const data = await doFetchProductAttributeBySlug(slug);
    if (isNil(data) || isEmpty(data)) {
      errHandler('Sorry No Attribute Available');
    } else {
      errHandler(null);
      doUpdatePawn({ attribute: data, attrStep: 0 });
    }
    loadingHandler(false);
  };

  return (
    <>
      <div className={`pawn-activity-content ${loading ? 'loading' : 'load'}`}>
        <AttributeStyled>
          {err ? (
            <>
              <div className='pawn-activity-header'>
                <Link
                  className='pawn-activity-header-nav'
                  to={`/pawn/${categorySlug}/${brandSlug}`}
                  onClick={() => {
                    doUpdatePawn({ slideLeft: true });
                  }}>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                    alt='go back'
                  />
                  <p>Back to Models</p>
                </Link>
              </div>
              <div className='pawn-activity-body flat-end'>
                <p className='no-item'>{err}</p>
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <MyContentLoader />
                </>
              ) : (
                <>
                  {attribute ? (
                    <>
                      {attribute.map((e, i) => {
                        return (
                          <Step
                            active={i === attrStep ? true : false}
                            key={i}
                            stepAttr={e}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>loading...</>
                  )}

                  {attribute && attribute.length === attrStep && (
                    <>
                      <Submit otpToggleHandler={otpToggleHandler} />
                    </>
                  )}
                  {otpToggle && (
                    <OtpModal
                      closeModalHandler={() => otpToggleHandler(false)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </AttributeStyled>
      </div>
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
  doFetchProductAttributeBySlug: (payload) =>
    dispatch(fetchProductAttributeBySlug(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attribute);
