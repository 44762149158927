import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const { ContainerStyled } = require('../../styles/layout.style');
const { ItemsWeAcceptStyled } = require('../../styles/home.style');

const ItemsWeAccept = (props) => {
  return (
    <ItemsWeAcceptStyled>
      <div className='swish first'>
        <img
          className='desktop'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-2-1.svg'
          alt=''
        />
        <img
          className='mobile'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-2-1-mobile.svg'
          alt=''
        />
      </div>
      <div className='swish last'>
        <img
          className='desktop'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-2-2.svg'
          alt=''
        />
        <img
          className='mobile'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-2-2-mobile.svg'
          alt=''
        />
      </div>
      <ContainerStyled>
        <h2>Items we accept</h2>
        <div className='items-wrapper'>
          <div className='items'>
            <div className='img-wrapper'>
              <LazyLoadImage
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/smartphones.png'
                alt='smartphones'
              />
            </div>
            <p>Your Smartphone</p>
          </div>
          <div className='items'>
            <div className='img-wrapper'>
              <LazyLoadImage
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/luxury-bags.png'
                alt='luxury bags'
              />
            </div>
            <p>Your Luxury Watch</p>
          </div>
          <div className='items'>
            <div className='img-wrapper'>
              <LazyLoadImage
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/designer-bags.png'
                alt='designer bags'
              />
            </div>
            <p>Your Designer Bag</p>
          </div>
          <div className='items'>
            <div className='img-wrapper'>
              <LazyLoadImage
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/jewelry.png'
                alt='jewelry'
              />
            </div>
            <p>Your Jewelry</p>
          </div>
        </div>

        <Link
          to='/items-we-accept'
          id='home_item_we_accept_link'
          className='link-see-more btn'>
          See more items we accept
        </Link>
      </ContainerStyled>
    </ItemsWeAcceptStyled>
  );
};

export default ItemsWeAccept;
