import React, { useState, useEffect } from 'react';
// styles
import { AcceptanceStyle } from '../../styles/acceptance.style';
import { ContainerStyled } from '../../styles/layout.style';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { fetchSummaryTicket } from '../../../actions/ticket';
import { useHistory, useParams } from 'react-router-dom';
import { resetLoan, updateLoan } from '../../../actions/loan';
import { getProductName } from '../../../utils/helper';
import capitalize from 'lodash.capitalize';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';

import loadable from '@loadable/component';
const Layout = loadable(() => import('../layout'));
const ProgressNav = loadable(() =>
  import('../../components/loanStep/progressNav')
);
const Summary = loadable(() => import('../../components/loanStep/summary'));
const WhyPawnhero = loadable(() => import('../../components/whyPawnhero'));
const Offer = loadable(() => import('../../components/loanStep/offer'));
const Review = loadable(() => import('../../components/loanStep/review'));

const FinalOffer = ({
  doFetchSummaryTicket,
  doUpdateLoan,
  doResetLoan,
  loan,
}) => {
  const acceptedStatus = ['OS', 'RJ', 'FR'];
  const { ticketNum } = useParams();
  const history = useHistory();
  const { position: currentPos = 0, data: loanData = {} } = loan;

  // states
  const [animationDone, animationDoneHandler] = useState(false);
  const [error, errorHandler] = useState(null);
  const [loading, loadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getTicketInfo();
    }
    return () => {
      cancel = true;
      doResetLoan();
    };
    // eslint-disable-next-line
  }, []);

  const getTicketInfo = async () => {
    try {
      loadingHandler(true);
      const {
        data,
        data: { statusRaw },
        success,
      } = await doFetchSummaryTicket({ ticketNum });

      if (success) {
        if (acceptedStatus.includes(statusRaw)) {
          doUpdateLoan({ ticketSummary: data });
          const { items } = data;
          const productName = getProductName(items[0].name);

          ticketDataHandler({ ...data, productName });
          loadingHandler(false);
        } else {
          history.push('/');
        }
      } else {
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        loadingHandler(false);
      }
    } catch (err) {
      errorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
      loadingHandler(false);
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPos]);

  return (
    <>
      <Layout>
        <AcceptanceStyle
          className={`window-height ${animationDone ? 'done-animate' : ''}`}>
          {error ? (
            <ContainerStyled>
              <p
                style={{
                  textAlign: 'center',
                  color: '#fff',
                  fontWeight: '600',
                  margin: '50px auto',
                }}>
                {error}
              </p>
            </ContainerStyled>
          ) : (
            <>
              {loading ? (
                <>
                  <div
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      left: '0',
                      right: '0',
                      top: '0',
                      bottom: '0',
                      height: '32px',
                    }}>
                    <img
                      className='loader'
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                      alt='loading'
                      style={{
                        width: '32px',
                        height: '32px',
                        margin: 'auto',
                        display: 'flex',
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {currentPos !== 0 && <ProgressNav mode={1} />}
                  {currentPos === 0 && (
                    <>
                      <h1>
                        {ticketData.statusRaw !== 'RJ'
                          ? 'Welcome back,'
                          : 'Our apologies'}{' '}
                        {!ticketData ||
                        isNil(ticketData) ||
                        !ticketData.user ||
                        isNil(ticketData.user)
                          ? ' '
                          : capitalize(ticketData.user.firstName)}
                        !
                      </h1>
                      <p>
                        {ticketData.statusRaw !== 'RJ' ? (
                          <>
                            Review our final loan offer/s for your{' '}
                            <span>
                              {!ticketData && isNil(ticketData)
                                ? ' '
                                : ticketData.productName}
                            </span>
                            .
                          </>
                        ) : (
                          <>
                            We are sorry, but we cannot accept your{' '}
                            <span>
                              {!ticketData && isNil(ticketData)
                                ? ' '
                                : ticketData.productName}
                            </span>{' '}
                            at the moment.
                          </>
                        )}
                      </p>
                    </>
                  )}
                  <ContainerStyled className='container-wrapper'>
                    <div className='page-content-wrapper'>
                      <div className='page-content'>
                        {currentPos === 0 && (
                          <>
                            <Offer
                              animationDoneHandler={animationDoneHandler}
                              mode={1}
                              noOffer={
                                ticketData.statusRaw === 'RJ' ||
                                ticketData.statusRaw === 'FR'
                              }
                              productName={ticketData.productName}
                            />
                          </>
                        )}

                        {currentPos === 1 && (
                          <>
                            <Review
                              animationDoneHandler={animationDoneHandler}
                              mode={1}
                            />
                          </>
                        )}
                      </div>
                      {!isEmpty(loanData) && currentPos !== 0 ? (
                        <Summary mode={1} />
                      ) : (
                        <WhyPawnhero />
                      )}
                    </div>
                  </ContainerStyled>
                </>
              )}
            </>
          )}
        </AcceptanceStyle>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['loan']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
  doUpdateLoan: (loan) => dispatch(updateLoan(loan)),
  doResetLoan: () => dispatch(resetLoan()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FinalOffer);
