import React, { useState } from 'react';
import Select from 'react-select';

const SelectField = (props) => {
  const [focus, focusHandler] = useState(false);

  const { isError = false } = props;
  return (
    <Select
      {...props}
      inputId={props.inputId}
      className={`${props.className} ${focus ? 'focus' : ''} ${
        isError ? 'is-error' : ''
      }`}
      onFocus={() => focusHandler(true)}
      onBlur={() => focusHandler(false)}
      openMenuOnFocus={true}
    />
  );
};

export default SelectField;
