import React, { useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from '../pages/home';
import NotFoundPage from '../pages/NotFound';
import PawnThankYou from '../pages/pawnThankyou';
import Login from '../pages/login';
import GuestLogin from '../pages/guestLogin';
import SignUp from '../pages/signup';
import ItemsWeAccept from '../pages/itemsWeAccept';
import ItemsDetailed from '../pages/itemsWeAccept/itemsDetailed';
import Acceptance from '../pages/acceptance';
import AcceptanceThankyou from '../pages/pawnThankyou/acceptanceThankyou';
import FinalOffer from '../pages/offer';
import Redeem from '../pages/redeem';
import PawnPage from '../pages/pawn';
import HowPawnheroWorks from '../pages/howPawnheroWorks';
import AboutUs from '../pages/aboutUs';
import CashoutPayments from '../pages/cashoutPayments';
import ContactUsPage from '../pages/contactUs';
import FaqPage from '../pages/faq';
import Press from '../pages/press';
import ServiceAdvisory from '../pages/serviceAdvisory';
import TermOfUse from '../pages/termOfUse';
import PrivacyPolicy from '../pages/privacyPolicy';
// import HelpCentre from '../pages/helpCentre';
// import HelpTopic from '../pages/helpCentre/topic';
// import HelpSearch from '../pages/helpCentre/search';
import RenewalPayment from '../pages/renewalPayment';
import PaynamicThankyou from '../pages/pawnThankyou/paynamicThankyou';
import RenewalThankyou from '../pages/pawnThankyou/renewalThankyou';
import RedeemFailed from '../pages/redeemFailed';
import RenewalPaynamicThankyou from '../pages/pawnThankyou/renewalPaynamicThankyou';
import RenewalFailed from '../pages/renewalFailed';
import FinalThankyou from '../pages/pawnThankyou/finalThankyou';
import ReturnPolicy from '../pages/returnPolicy';
import DirectThankyou from '../pages/pawnThankyou/directThankyou';
import ConfirmLoanPayment from '../pages/confirmLoanPayment';
import SbThankyou from '../pages/pawnThankyou/sbThankyou';
import CoinsThankyou from '../pages/pawnThankyou/coinsThankyou';
import PawnaloPage from '../pages/pawnalo';
import PawnaloGoldPage from '../pages/pawnalo/gold';
import PawnheroApp from '../pages/app';
import PawnheroPawning from '../pages/pawning';
// import UnangSangla from '../pages/unangSangla';
import CashBonus from '../pages/promo';
import PreHolidayPromo from '../pages/promo/preHoliday';

// custom route
import { DashboardRoutes } from './dashboadRoute';
import PrivateRoute from './private'; //will check if auth is still active before passing : user mode must be normal
import GuestTicketView from '../pages/guest/guestTicketView';
import Pdf from '../pages/pdf';
import PawnhHeroEchelon from '../pages/articles/pawnheroEchelon';
import PawnHeroMalaysia from '../pages/articles/pawnheroMalaysia';

import Forgot from '../pages/forgot';
import Reset from '../pages/reset';
import AppContext from './appContext';

// import UserActivatePage from '../pages/userActivate';

const AppRoutes = () => {
  const socketRef = useRef();

  return (
    <>
      <AppContext socketRef={socketRef} />
      <Switch>
        <Route exact path='/' component={Home} />

        {/* pawn thank you */}
        <Route
          exact
          path='/pawn/thankyou/:ticketNum'
          component={PawnThankYou}
        />
        <Route
          exact
          path='/pawn/acceptance/:ticketNum/thankyou'
          component={AcceptanceThankyou}
        />
        <Route
          exact
          path='/pawn/final/:ticketNum/thankyou'
          component={FinalThankyou}
        />
        <Route
          exact
          path='/pawn/renewal/:ticketNum/thankyou'
          component={RenewalThankyou} //renewal request thank you
        />

        <Route
          exact
          path={['/pawn/redeem/direct_success', '/pawn/renewal/direct_success']}
          component={DirectThankyou}
        />
        <Route
          exact
          path={['/pawn/redeem/sb_success', '/pawn/renewal/sb_success']}
          component={SbThankyou}
        />
        <Route
          exact
          path={['/pawn/redeem/coins_success', '/pawn/renewal/coins_success']}
          component={CoinsThankyou}
        />

        {/* paynamic success redirection */}
        <Route exact path='/pawn/redeem/success' component={PaynamicThankyou} />
        <Route exact path='/pawn/redeem/failed' component={RedeemFailed} />
        <Route
          exact
          path='/pawn/renewal/success'
          component={RenewalPaynamicThankyou}
        />
        <Route exact path='/pawn/renewal/failed' component={RenewalFailed} />

        {/* acceptance, final offer ,redeem, renewal payment */}
        <Route
          exact
          path='/pawn/acceptance/:ticketNum'
          component={Acceptance}
        />
        <Route exact path='/pawn/offer/:ticketNum/' component={FinalOffer} />
        <Route exact path='/pawn/redeem/:ticketNum/' component={Redeem} />
        <Route
          exact
          path='/pawn/renewal/payment/:ticketNum'
          component={RenewalPayment}
        />

        {/* the pawn page */}
        <Route
          exact
          path='/pawn/:categorySlug?/:brandSlug?/:productSlug?'
          component={PawnPage}
        />
        <Route path='/signup' component={SignUp} />
        <Route exact path='/guest' component={GuestLogin} />
        <Route path='/forgot' component={Forgot} />
        <Route path='/customer/reset/:token' component={Reset} />
        {/* <Route path='/customer/activation/:token' component={UserActivatePage} /> */}
        <Route path='/login' component={Login} />
        <Route exact path='/items-we-accept' component={ItemsWeAccept} />
        <Route
          exact
          path={['/items-we-accept/:key', '/items-we-accept/:key/:cat']}
          component={ItemsDetailed}
        />
        <Route
          path='/manage/confirm_loan_payment'
          component={ConfirmLoanPayment}
        />

        {/* static rotues */}
        <Route path='/how-pawnhero-works' component={HowPawnheroWorks} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/cashout-and-payments' component={CashoutPayments} />
        <Route path='/faq' component={FaqPage} />
        <Route path='/contact-us' component={ContactUsPage} />
        <Route path='/press' component={Press} />
        <Route path='/service-advisory' component={ServiceAdvisory} />
        <Route path='/terms-of-use' component={TermOfUse} />
        <Route path='/privacy-policy' component={PrivacyPolicy} />
        <Route path='/return-policy' component={ReturnPolicy} />
        {/* <Route exact path='/help' component={HelpCentre} />
        <Route exact path='/help/search' component={HelpSearch} />
        <Route path='/help/:slug' component={HelpTopic} /> */}
        <Route exact path='/help' >
          <Redirect to="/faq" />
        </Route>
        <Route path='/help/:slug' >
          <Redirect to="/faq" />
        </Route>
        <Route path='/pawnhero_echelon_2015' component={PawnhHeroEchelon} />
        <Route path='/pawnhero_in_malaysia' component={PawnHeroMalaysia} />
        <Route path='/pawnalo-gadget-appraisal' component={PawnaloPage} />
        <Route path='/app' component={PawnheroApp} />
        <Route path='/pawning' component={PawnheroPawning} />
        <Route
          path='/pawnalo-gold-jewelry-pawnshop-appraisal'
          component={PawnaloGoldPage}
        />
        <Route exact path='/unang-sangla' >
          <Redirect to="/" />
        </Route>
        <Route path='/cash-bonus-promo' component={CashBonus} />
        <Route path='/preholiday-giveaway' component={PreHolidayPromo} >
          <Redirect to="/" />
        </Route>

        {/* protected routes */}
        <Route path='/ticket/pdf/:ticketNum' component={Pdf} />

        {/* PRIVATE ROUTES */}
        <PrivateRoute
          path='/dashboard'
          component={(props) => (
            <DashboardRoutes {...props} socketRef={socketRef} />
          )}
        />

        {/* GUEST PAGES */}
        <Route
          exact
          path='/guest/ticket/:ticketNum'
          component={GuestTicketView}
        />

        {/* 404 page */}
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default AppRoutes;

// move to child routes approach
// TODO add sitemap routes -> contains all routes to generate comple sitemap
