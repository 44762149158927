import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getTime = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/time/now', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getHolidays = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/holidays', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getWorkingHours = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/time/work', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
