import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';
import { Collapse } from 'react-collapse';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchTicketTrail, fetchTicketView } from '../../../actions/ticket';
import { toTitleCase } from '../../../utils/helper';
import ElipseLoader from '../shared/elipseLoader';

const { TicketDetailStyled } = require('../../styles/dashboard.style');

const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const TicketDetails = (props) => {
  const { ticketNum } = useParams();
  const {
    dashboard,
    doFetchTicketView,
    doFetchTicketTrail,
    withHeader = true,
  } = props;

  // states
  const [collapse, collapseHandler] = useState(true);
  const [loading, loadingHandler] = useState(false);
  const [_loading, _loadingHandler] = useState(false);

  const [ticketView, ticketViewHandler] = useState(null);
  const [statusDisplay, statusDisplayHandler] = useState('');
  const [showCTA, showCTAHandler] = useState(false);
  const [err, errHandler] = useState(null);
  const [trails, trailsHandler] = useState([]);
  const [_lastActive, _lastActiveHandler] = useState(false);

  const showTicketDashboard =
    dashboard && !isEmpty(dashboard) ? dashboard.showTicketDashboard : true;

  // summary
  // RS
  // KW / KP
  // AI
  // LR / LX
  // MP
  // LL

  const statusSummary = [
    { label: 'Pawn Requested', status: ['RS'] },
    { label: 'Item Booked', status: ['KW', 'KP'] },
    { label: 'Item Appraised', status: ['AS'] },
    { label: 'Loan Released', status: ['LR'] },
    { label: 'Loan Repayment', status: ['MP'] },
    { label: 'Item Returned', status: ['LL'] },
  ];

  let _availStatus = [];
  statusSummary.forEach((e) => {
    const { status } = e;
    _availStatus = [..._availStatus, ...status];
  });

  const getData = async (slug) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data, success } = await doFetchTicketView({ ticketNum });

        if (success) {
          resolve(data);
        } else {
          reject(err);
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  const getTrail = () => {
    return new Promise((resolve, reject) => {
      doFetchTicketTrail(ticketNum)
        .then((response) => {
          const { data, success } = response;

          if (success) {
            resolve(data);
          } else {
            reject({ success });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      // get data
      loadingHandler(true);
      getData()
        .then((data) => {
          if (!cancel) {
            showCTAHandler(
              ['LR', 'LM', 'MP', 'WA', 'WI'].includes(data.status_raw)
            );
            if (data.status_raw === 'KW') {
              statusDisplayHandler('Walk-in Pending');
            } else if (data.status_raw === 'KP') {
              statusDisplayHandler('Pick-up Pending');
            } else {
              statusDisplayHandler(data.status);
            }
            ticketViewHandler(data);
            loadingHandler(false);
          }
        })
        .catch((err) => {
          if (!cancel) {
            errHandler(
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
            );
            loadingHandler(false);
          }
        });

      // trail
      _loadingHandler(true);
      trailsHandler([]);
      getTrail()
        .then((data) => {
          if (!cancel) {
            trailsHandler(data);
            _lastActiveHandler(
              data
                .filter((e) => _availStatus.includes(e.status_raw))
                .slice(-1)
                .pop()
            );
            _loadingHandler(false);
          }
        })
        .catch((err) => {
          if (!cancel) {
            errHandler(
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
            );
            _loadingHandler(false);
          }
        });
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <TicketDetailStyled>
      {loading || _loading ? (
        <>
          <div
            style={{
              width: '100%',
              padding: '50px 0',
            }}>
            <ElipseLoader />
          </div>
        </>
      ) : (
        <>
          {ticketView && (
            <>
              <div className='ticket-details-wrapper'>
                {withHeader && <h1>Pawn Ticket {ticketNum}</h1>}
                <div className='data-wrapper'>
                  <div className='data-header-wrapper'>
                    <p>{toTitleCase(statusDisplay)}</p>
                  </div>
                  <div className='data-content-wrapper'>
                    <div className='data-summary'>
                      <ul>
                        <li>
                          <p>
                            Pawner: <span>{ticketView.user.name}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Pawn item:{' '}
                            <span>
                              {ticketView.items.map((e) => e.name).join(',')}
                            </span>
                          </p>
                        </li>
                        {ticketView.loan && ticketView.loan.amount && (
                          <li>
                            <p>
                              Loan offer: <span>{ticketView.loan.amount}</span>
                            </p>
                          </li>
                        )}
                        <li>
                          <p>
                            Date loan granted:{' '}
                            <span>
                              {(ticketView.loan &&
                                ticketView.loan.start_date) ||
                                'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Maturity date:{' '}
                            <span>
                              {(ticketView.loan &&
                                ticketView.loan.maturity_date) ||
                                'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Expiry date of redemption :{' '}
                            <span>
                              {(ticketView.loan &&
                                ticketView.loan.default_date) ||
                                'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Effective interest rate:{' '}
                            <span>
                              {ticketView.loan && ticketView.loan.effective_ir
                                ? `${ticketView.loan.effective_ir}%`
                                : 'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Pick-up/Walk-in date:{' '}
                            <span>
                              {(ticketView.location &&
                                ticketView.location.preferred_date) ||
                                'None yet'}
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className='data-loan-detail'>
                      <ul>
                        <li>
                          <p>
                            Loan principal:{' '}
                            <span>
                              {ticketView.loan && ticketView.loan.loan_amount
                                ? formaterNumber(ticketView.loan.loan_amount)
                                : 'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Less interest:{' '}
                            <span>
                              {ticketView.loan &&
                              ticketView.loan.interest_amount
                                ? formaterNumber(
                                    ticketView.loan.interest_amount
                                  )
                                : 'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Less service charge:{' '}
                            <span>
                              {ticketView.loan && ticketView.loan.service_charge
                                ? formaterNumber(ticketView.loan.service_charge)
                                : 'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Less DST charge:{' '}
                            <span>
                              {ticketView.loan && ticketView.loan.dst_charge
                                ? formaterNumber(ticketView.loan.dst_charge)
                                : 'None yet'}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p style={{ color: '#68C552' }}>
                            Net proceed (you receive):{' '}
                            <span>
                              {ticketView.loan && ticketView.loan.net_proceeds
                                ? formaterNumber(ticketView.loan.net_proceeds)
                                : 'None yet'}
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className='data-timeline'>
                      <div
                        className='timeline-header'
                        onClick={() => collapseHandler(!collapse)}>
                        <img
                          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/timeline-ico.svg'
                          alt=''
                        />
                        <p>Transaction Timeline</p>
                        <img
                          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/top-arrow-dark-blue-ico.svg'
                          className={`${collapse ? 'open' : 'close'}`}
                          alt=''
                        />
                      </div>

                      <Collapse isOpened={collapse}>
                        <div className='timeline-wrapper'>
                          <table>
                            <tbody>
                              {!isEmpty(trails) &&
                                trails
                                  .reverse()
                                  .filter((e) => !isEmpty(e.status_description))
                                  .map((e, i) => (
                                    <Fragment key={i}>
                                      <tr>
                                        <td>{e.inserted_at}</td>
                                        <td>{e.status_description}</td>
                                      </tr>
                                    </Fragment>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  <div className='actions-wrapper'>
                    {showCTA && (
                      <>
                        <Link
                          to={`/pawn/redeem/${ticketNum}`}
                          className='item-redeem btn'>
                          Redeem your item
                        </Link>
                        <Link
                          to={`/pawn/redeem/${ticketNum}`}
                          className='item-renew btn'>
                          Renew your loan
                        </Link>
                      </>
                    )}

                    {ticketView.status_raw === 'ES' && (
                      <>
                        <Link
                          to={`/pawn/acceptance/${ticketNum}`}
                          className='item-acceptance btn'>
                          View our loan offers
                        </Link>
                      </>
                    )}
                    {ticketView.status_raw === 'OS' && (
                      <>
                        <Link
                          to={`/pawn/offer/${ticketNum}`}
                          className='item-offer btn'>
                          Accept loan offer
                        </Link>
                      </>
                    )}
                    {ticketView.status_raw === 'LX' && (
                      <>
                        <Link to={`/pawn`} className='item-pawn btn'>
                          Pawn another item
                        </Link>
                      </>
                    )}
                    {['KW', 'KP', 'LX'].includes(ticketView.status_raw) && (
                      <>
                        <Link to={`/guest`} className='item-guest btn'>
                          View another pawn ticket
                        </Link>
                      </>
                    )}
                  </div>

                  {/* <div className='data-footer'>
                    <Link to='/login'>Log in to your PawnHero account</Link>
                  </div> */}
                </div>
              </div>
              <div
                className={`summary-wrapper ${
                  showTicketDashboard ? 'mobile-show' : 'mobile-hide'
                }`}>
                <div className='summary-header'>
                  <Link to={`/ticket/pdf/${ticketNum}`} target='_blank'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/download-ico.svg'
                      alt=''
                    />
                    <p>Download PDF</p>
                  </Link>
                </div>
                <div className='summary-body'>
                  <ul>
                    {statusSummary.map((e, key) => {
                      const active = _lastActive
                        ? statusSummary.findIndex((e) =>
                            e.status.includes(_lastActive.status_raw)
                          )
                        : false;

                      return (
                        <Fragment key={key}>
                          <li
                            className={`${key === active ? 'active' : ''} ${
                              key > active ? 'in-active' : ''
                            }`}>
                            <span>{key + 1}</span>
                            <p>{e.label}</p>
                          </li>
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className='mobile-download-pdf'>
                <Link to={`/ticket/pdf/${ticketNum}`} target='_blank'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/download-ico.svg'
                    alt=''
                  />
                  <p>Download PDF</p>
                </Link>
              </div>
            </>
          )}

          {err && <p className='error'>{err}</p>}
        </>
      )}
    </TicketDetailStyled>
  );
};

const mapStateToProps = pick(['dashboard']);
const mapDispatchToProps = (dispatch) => ({
  doFetchTicketView: (payload) => dispatch(fetchTicketView(payload)),
  doFetchTicketTrail: (payload) => dispatch(fetchTicketTrail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
