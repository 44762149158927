import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { makeSignUp } from '../../../actions/auth';
import StringMask from 'string-mask';
import { makeEmailVerify } from '../../../actions/user';

// styles
import Layout from '../layout';
import { SignUpStyled } from '../../styles/signup.style';
import { ContainerStyled } from '../../styles/layout.style';

// components
import PhoneField from '../../components/shared/phone';
import ReactModal from '../../components/shared/reactModal';

// validators
const { validateEmail } = require('../../../utils/helper');

const SignUp = ({ doMakeSignUp, doMakeEmailVerify }) => {
  const [isOpen, isOpenHandler] = useState(false);
  const [_isOpen, _isOpenHandler] = useState(false);
  const [errFields, errFieldsHandler] = useState([]);
  const [err, errHandler] = useState(null);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [formValues, formValuesHandler] = useState({
    email: '',
    mobile: '',
    firstName: '',
    lastName: '',
  });

  const [_verifyUser, _verifyUserHandler] = useState(null);
  const [email, emailHandler] = useState(null);

  const isFormValid = () => {
    let _return = true;
    const _errFields = [];
    errFieldsHandler([]);
    errHandler(null);

    const { email, mobile, firstName, lastName } = formValues;

    const emailValid = validateEmail(email);

    if (isEmpty(email) || !emailValid) {
      _return = false;
      _errFields.push({
        key: 'email',
        message:
          'The email address you have entered is invalid. Please recheck the format.',
      });
    }

    if (isEmpty(mobile) || mobile.length !== 11) {
      _return = false;
      _errFields.push({
        key: 'mobile',
        message: `The mobile number you have entered is invalid. Please recheck the format.`,
      });
    }

    if (isEmpty(firstName) || firstName.length < 2) {
      _return = false;
      _errFields.push({
        key: 'firstName',
        message: `The first name you have entered is invalid. Please recheck the format.`,
      });
    }

    if (isEmpty(lastName) || lastName.length < 2) {
      _return = false;
      _errFields.push({
        key: 'lastName',
        message: `The last name you have entered is invalid. Please recheck the format.`,
      });
    }

    errFieldsHandler(_errFields);
    return _return;
  };

  const fireSignup = () => {
    isProcessingHandler(true);

    const isValid = isFormValid();

    if (isValid) {
      const {
        email,
        mobile,
        firstName: first_name,
        lastName: last_name,
      } = formValues;
      const payload = {
        user: {
          mobile,
          email,
          first_name,
          last_name,
        },
      };

      doMakeSignUp(payload)
        .then((response) => {
          const { message = [], success } = response;

          if (success) {
            if (_verifyUser && _verifyUser.status === 'U') {
              _isOpenHandler(true);
            } else {
              isOpenHandler(true);
            }
          } else {
            errHandler(message);
          }
        })
        .catch((err) => {
          console.log('err', err);
          errHandler({
            general:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          });
        });

      isProcessingHandler(false);
    } else {
      isProcessingHandler(false);
    }
  };

  useEffect(() => {
    let cancel = false;
    const timer = setTimeout(async () => {
      const _errFields = errFields.filter((e) => e.key !== 'email');
      errFieldsHandler(_errFields);
      if (!cancel) {
        if (email) {
          const emailValid = validateEmail(email);

          if (emailValid) {
            doMakeEmailVerify({ email })
              .then((response) => {
                const { success, data } = response;
                // Your email address has already been taken. Please use a new one.

                if (success) {
                  const { status, user } = data;

                  if (status === 'U') {
                    // user is not verified
                    _verifyUserHandler({ status });

                    const {
                      email,
                      mobile,
                      first_name: firstName,
                      last_name: lastName,
                    } = user;
                    const _user = {
                      email,
                      mobile,
                      firstName,
                      lastName,
                    };

                    formValuesHandler(_user);
                  } else {
                    // user have active account
                    _verifyUserHandler({ status });

                    errFieldsHandler([
                      ...new Set([
                        ...errFields,
                        {
                          key: 'email',
                          message:
                            'Your email address has already been taken. Please use a new one.',
                        },
                      ]),
                    ]);
                    formValuesHandler({
                      ...formValues,
                      mobile: '',
                      firstName: '',
                      lastName: '',
                    });
                  }
                } else {
                  // new user
                  _verifyUserHandler(null);
                  // reinit
                  formValuesHandler({
                    ...formValues,
                    mobile: '',
                    firstName: '',
                    lastName: '',
                  });
                }
              })
              .catch((err) => {
                console.log('err', err);
              });
          } else {
            //email not valid

            errFieldsHandler([
              ...new Set([
                ...errFields,
                {
                  key: 'email',
                  message:
                    'The email address you have entered is invalid. Please recheck the format.',
                },
              ]),
            ]);
          }
        } else {
          _verifyUserHandler(null);
        }
      }
    }, 300);

    return () => {
      cancel = true;
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [email]);

  return (
    <Layout>
      <SignUpStyled className='page'>
        <ContainerStyled>
          <h1>Sign up for a free PawnHero account</h1>
          {/* <p>Consectitur adipiscing elit</p> */}

          <div className='fields-groups-wrapper'>
            <form
              className='fields-wrapper'
              onSubmit={(e) => {
                e.preventDefault();
                fireSignup();
              }}>
              <div className='field-group'>
                <div
                  className={`field-label-wrapper ${
                    formValues.email == null || isEmpty(formValues.email)
                      ? 'no-value'
                      : 'with-value'
                  } ${
                    errFields.find((e) => e.key === 'email') ||
                    (err && err.email)
                      ? 'is-error'
                      : ''
                  } `}>
                  <input
                    id='signup_form_email'
                    type='text'
                    // placeholder='Email address'
                    value={formValues.email ? formValues.email : ''}
                    onChange={(e) => {
                      formValuesHandler({
                        ...formValues,
                        email: e.target.value,
                      });
                      emailHandler(e.target.value);
                    }}
                  />
                  <label htmlFor='signup_form_email'>Email address</label>
                </div>
                <p className='err-note error'>
                  {(errFields.length &&
                    errFields.find((e) => e.key === 'email') &&
                    errFields.find((e) => e.key === 'email').message) ||
                    ''}
                  {err && err.email && err.email.join(',')}
                </p>
              </div>

              {_verifyUser && _verifyUser.status === 'U' ? (
                <>
                  <div className='field-group'>
                    <div className='field-label-wrapper with-value'>
                      <p className='disable-preview-field'>
                        {StringMask.apply(
                          formValues.mobile.substring(0, 2) +
                            formValues.mobile.substring(
                              formValues.mobile.length - 4,
                              formValues.mobile.length
                            ),
                          '00$•$• $•$•$• 0000'
                        )}
                      </p>
                      <label>Mobile number</label>
                    </div>
                    <p className='err-note error'>
                      {(errFields.length &&
                        errFields.find((e) => e.key === 'mobile') &&
                        errFields.find((e) => e.key === 'mobile').message) ||
                        ''}
                      {err && err.mobile && err.mobile.join(',')}
                    </p>
                  </div>
                </>
              ) : (
                <div className='field-group'>
                  <div
                    className={`field-label-wrapper ${
                      formValues.mobile == null || isEmpty(formValues.mobile)
                        ? 'no-value'
                        : 'with-value'
                    } ${
                      errFields.find((e) => e.key === 'mobile') ||
                      (err && err.mobile)
                        ? 'is-error'
                        : ''
                    } `}>
                    <PhoneField
                      id='signup_form_mobile'
                      value={formValues.mobile ? formValues.mobile : ''}
                      onChange={(data) =>
                        formValuesHandler({
                          ...formValues,
                          mobile: data,
                        })
                      }
                      country='ph'
                      onlyCountries={['ph']}
                      masks={{ ph: '.... ... ....' }}
                      disableDropdown={true}
                      disableCountryCode={true}
                      placeholder=''
                      inputClass='input-phone'
                      containerClass={`phone-input ${
                        errFields.includes('mobile') ? 'invalid' : ''
                      }`}
                    />
                    <label htmlFor='signup_form_mobile'>Mobile number</label>
                  </div>
                  <p className='err-note error'>
                    {(errFields.length &&
                      errFields.find((e) => e.key === 'mobile') &&
                      errFields.find((e) => e.key === 'mobile').message) ||
                      ''}
                    {err && err.mobile && err.mobile.join(',')}
                  </p>
                </div>
              )}

              <div className='field-group-flex'>
                {_verifyUser && _verifyUser.status === 'U' ? (
                  <>
                    <div className='field-group'>
                      <div className='field-label-wrapper with-value'>
                        <p className='disable-preview-field'>
                          {formValues.firstName}
                        </p>
                        <label>First name</label>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='field-group'>
                    <div
                      className={`field-label-wrapper ${
                        formValues.firstName == null ||
                        isEmpty(formValues.firstName)
                          ? 'no-value'
                          : 'with-value'
                      } ${
                        errFields.find((e) => e.key === 'firstName') ||
                        (err && err.first_name)
                          ? 'is-error'
                          : ''
                      } `}>
                      <input
                        id='signup_form_fname'
                        type='text'
                        // placeholder='Email address'
                        value={formValues.firstName ? formValues.firstName : ''}
                        onChange={(e) => {
                          formValuesHandler({
                            ...formValues,
                            firstName: e.target.value,
                          });
                        }}
                      />
                      <label htmlFor='signup_form_fname'>First name</label>
                    </div>
                    <p className='err-note error'>
                      {(errFields.length &&
                        errFields.find((e) => e.key === 'firstName') &&
                        errFields.find((e) => e.key === 'firstName').message) ||
                        ''}
                      {err && err.first_name && err.first_name.join(',')}
                    </p>
                  </div>
                )}
                {_verifyUser && _verifyUser.status === 'U' ? (
                  <>
                    <div className='field-group'>
                      <div className='field-label-wrapper with-value'>
                        <p className='disable-preview-field'>
                          {StringMask.apply(
                            formValues.lastName,
                            `A${[...Array(formValues.lastName.length)]
                              .map((e, i) => '$•')
                              .join('')}`
                          )}
                        </p>
                        <label>Last name</label>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='field-group'>
                    <div
                      className={`field-label-wrapper ${
                        formValues.lastName == null ||
                        isEmpty(formValues.lastName)
                          ? 'no-value'
                          : 'with-value'
                      } ${
                        errFields.find((e) => e.key === 'lastName') ||
                        (err && err.last_name)
                          ? 'is-error'
                          : ''
                      } `}>
                      <input
                        id='signup_form_lname'
                        type='text'
                        // placeholder='Email address'
                        value={formValues.lastName ? formValues.lastName : ''}
                        onChange={(e) => {
                          formValuesHandler({
                            ...formValues,
                            lastName: e.target.value,
                          });
                        }}
                      />
                      <label htmlFor='signup_form_lname'>Last name</label>
                    </div>

                    <p className='err-note error'>
                      {(errFields.length &&
                        errFields.find((e) => e.key === 'lastName') &&
                        errFields.find((e) => e.key === 'lastName').message) ||
                        ''}
                      {err && err.last_name && err.last_name.join(',')}
                    </p>
                  </div>
                )}
              </div>
              {_verifyUser && _verifyUser.status === 'U' && (
                <>
                  <div className='message-wrapper-existing-user'>
                    <p>
                      We have found your email address in our records, but isn’t
                      verified yet.
                      <br />
                      <br />
                      Click on the Sign up button to continue.
                    </p>
                  </div>
                </>
              )}

              <div className='action-wrapper'>
                <div>
                  {err && err.general ? (
                    <p className='error-text'>{err.general}</p>
                  ) : (
                    ''
                  )}
                  <button
                    type='submit'
                    disabled={
                      (_verifyUser && _verifyUser.status === 'A') ||
                      isEmpty(formValues.email) ||
                      isEmpty(formValues.mobile) ||
                      isEmpty(formValues.firstName) ||
                      isEmpty(formValues.lastName) ||
                      isProcessing
                    }>
                    Sign up
                  </button>
                  <Link to={'/login'}>Log in to your PawnHero account</Link>

                  <Link to={'/guest'}>
                    Don’t have time? View your pawn ticket directly
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </ContainerStyled>
      </SignUpStyled>

      <ReactModal
        background={'rgba(0, 0, 0, 0.25)'}
        toggle={isOpen}
        toggleHandler={isOpenHandler}
        // withHeader={false}
        contentContainerStyle={{
          background: '#fff',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          maxWidth: '554px',
          width: '100%',
          minHeight: '40vh',
          padding: '15px',
          overflow: 'visible',
        }}>
        <>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/email-modal-ico.svg'
            alt=''
            style={{
              width: '48px',
              margin: 'auto',
              display: 'block',
            }}
          />

          <h4
            style={{
              fontWeight: '600',
              fontSize: '24px',
              color: '#327FBE',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Verify your email address
          </h4>

          <p
            style={{
              color: '#354F6',
              fontSize: '18px',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Before you can log in to your PawnHero account, please check your
            email inbox to verify your PawnHero account
          </p>

          <div
            style={{
              display: 'flex',
            }}>
            <Link
              to='/login'
              className='btn-common'
              style={{
                background: '#68C552',
                color: '#fff',
                textDecoration: 'none',
              }}>
              Back to Login
            </Link>
          </div>
        </>
      </ReactModal>

      <ReactModal
        background={'rgba(0, 0, 0, 0.25)'}
        toggle={_isOpen}
        toggleHandler={_isOpenHandler}
        // withHeader={false}
        contentContainerStyle={{
          background: '#fff',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          maxWidth: '554px',
          width: '100%',
          minHeight: '40vh',
          padding: '15px',
          overflow: 'visible',
        }}>
        <>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/email-modal-ico.svg'
            alt=''
            style={{
              width: '48px',
              margin: 'auto',
              display: 'block',
            }}
          />

          <h4
            style={{
              fontWeight: '600',
              fontSize: '24px',
              color: '#327FBE',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Your email address exists,
            <br /> but isn’t verified yet
          </h4>

          <p
            style={{
              color: '#354F6',
              fontSize: '18px',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Before you can log in to your PawnHero account, please check your
            email inbox to verify your PawnHero account
          </p>

          <div
            style={{
              display: 'flex',
            }}>
            <Link
              to='/login'
              className='btn-common'
              style={{
                background: '#68C552',
                color: '#fff',
                textDecoration: 'none',
              }}>
              Back to Login
            </Link>
          </div>
        </>
      </ReactModal>
    </Layout>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doMakeSignUp: (user) => dispatch(makeSignUp(user)),
  doMakeEmailVerify: (payload) => dispatch(makeEmailVerify(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
