import React, { Component } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
const { SearchMobileStyled } = require('../../styles/searchMobile.style');

class SearchMobile extends Component {
  render() {
    return (
      <SearchMobileStyled>
        <img
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-blue.svg'
          alt='search'
        />
        <p onClick={this.props.handleMobileSearchToggle}>
          Try searching directly for your item here{' '}
        </p>
      </SearchMobileStyled>
    );
  }
}

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMobile);
