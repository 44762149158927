import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

// utility
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { fetchSummaryTicket } from '../../../actions/ticket';

// child component
import Layout from '../layout';

const { ContainerStyled } = require('../../styles/layout.style');
const { PawnThankyouStyledV2 } = require('../../styles/pawn-thankyou.style');

const FinalThankyou = (props) => {
  const { doFetchSummaryTicket } = props;
  const history = useHistory();
  const { ticketNum } = useParams();

  const thankyouDateExpiry =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.thankyouDateExpiry
      ? props.location.state.thankyouDateExpiry
      : false;

  const now = moment().tz(timezone).unix();

  const [loading, loadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);
  const [user, userHandler] = useState(null);

  useEffect(() => {
    const _test = thankyouDateExpiry > now;

    if (!_test) {
      // expired
      history.push('/guest');
    } else {
      loadingHandler(true);
      doFetchSummaryTicket({ ticketNum })
        .then(({ data }) => {
          ticketDataHandler(data);
          const { user } = data;
          userHandler(user);
          loadingHandler(false);
        })
        .catch((err) => {
          loadingHandler(false);
          history.push('/guest');
        });
    }

    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <PawnThankyouStyledV2>
          {loading ? (
            <>
              <div style={{ padding: '50px 0', width: '100%' }}>
                <img
                  className='loader'
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                  alt='loading'
                  style={{
                    width: '32px',
                    height: '32px',
                    margin: 'auto',
                    display: 'flex',
                  }}
                />
              </div>
            </>
          ) : (
            <section className='banner'>
              <ContainerStyled>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou.svg'
                  alt='thank you'
                />
                <h2>Congratulations, {user ? user.firstName : ''}!</h2>
                <h3>We are now processing your loan application.</h3>
                <p className='highnotes'>
                  Your ticket number is {ticketData.ticketNo}.
                </p>

                <p className='notes'>
                  Please wait while we process the approval of your loan. For
                  any concerns, please do not hesitate to contact our customer
                  service representatives at 8828-2274 between 9:00 AM and 7:00
                  PM.
                </p>

                <div className='cta-wrapper'>
                  <Link to='/' className='btn'>
                    Back to Home
                  </Link>
                </div>
              </ContainerStyled>
            </section>
          )}
        </PawnThankyouStyledV2>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalThankyou);
