import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { makeReset } from '../../../actions/auth';
import Layout from '../layout';
import { Link, useLocation, useParams } from 'react-router-dom';
import { isEmpty } from '@ramda/isempty/isEmpty';
import ReactModal from '../../components/shared/reactModal';

// styles
const { LoginStyled } = require('../../styles/login.style');
const { ContainerStyled } = require('../../styles/layout.style');

const Reset = ({ doMakeReset }) => {
  const { search } = useLocation();
  const type = new URLSearchParams(search).get('type');

  const { token } = useParams();
  const [_isOpen, _isOpenHandler] = useState(false);
  const [formValues, formValuesHandler] = useState({
    password: '',
    confirm_password: '',
  });
  const [errFields, errFieldsHandler] = useState([]); //field error
  const [err, errHandler] = useState(null); //general error
  const [isProcessing, isProcessingHandler] = useState(false);

  const resetHandler = () => {
    isProcessingHandler(true);
    errFieldsHandler([]);
    errHandler(null);

    const _payload = {
      token,
      payload: { ...formValues, type },
    };

    doMakeReset(_payload)
      .then((response) => {
        const { success, message } = response;

        if (success) {
          _isOpenHandler(true);
        } else {
          if (typeof message === 'string') {
            errHandler(
              message ||
                'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
            );
          } else {
            const _message = Object.keys(message).map((e) => ({
              key: e,
              message: message[e],
            }));

            errFieldsHandler(_message);
          }
        }
        isProcessingHandler(false);
      })
      .catch((err) => {
        isProcessingHandler(false);
        errHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  return (
    <Layout>
      <LoginStyled>
        <ContainerStyled>
          <h1
            style={{
              maxWidth: '450px',
              textAlign: 'center',
              margin: 'auto',
            }}>
            Enter your new password
          </h1>

          <div className='fields-groups-wrapper'>
            <form
              className='fields-wrapper'
              onSubmit={(e) => {
                e.preventDefault();
                resetHandler();
              }}>
              <div className='field-group'>
                <div
                  className={`field-label-wrapper ${
                    formValues.password == null || isEmpty(formValues.password)
                      ? 'no-value'
                      : 'with-value'
                  } ${
                    errFields.find((e) => e.key === 'password_input')
                      ? 'is-error'
                      : ''
                  } `}>
                  <input
                    id='reset_form_password'
                    type='password'
                    autoComplete='false'
                    // placeholder='Email address'
                    value={formValues.password ? formValues.password : ''}
                    required
                    onChange={(e) => {
                      formValuesHandler({
                        ...formValues,
                        password: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor='reset_form_password'>New password</label>
                </div>

                {errFields.length &&
                errFields.find((e) => e.key === 'password_input') &&
                errFields.find((e) => e.key === 'password_input').message ? (
                  <div className='bubble-message'>
                    <ul>
                      {errFields
                        .find((e) => e.key === 'password_input')
                        .message.map((e, i) => (
                          <Fragment key={i}>
                            <li>{e}</li>
                          </Fragment>
                        ))}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className='field-group'>
                <div
                  className={`field-label-wrapper ${
                    formValues.confirm_password == null ||
                    isEmpty(formValues.confirm_password)
                      ? 'no-value'
                      : 'with-value'
                  } ${
                    errFields.find(
                      (e) => e.key === 'password_input_confirmation'
                    )
                      ? 'is-error'
                      : ''
                  } `}>
                  <input
                    id='reset_form_confirm_password'
                    type='password'
                    autoComplete='false'
                    // placeholder='Email address'
                    value={
                      formValues.confirm_password
                        ? formValues.confirm_password
                        : ''
                    }
                    required
                    onChange={(e) => {
                      formValuesHandler({
                        ...formValues,
                        confirm_password: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor='reset_form_confirm_password'>
                    Confirm new password
                  </label>
                </div>
                <p
                  className='err-note error'
                  dangerouslySetInnerHTML={{
                    __html:
                      errFields.length &&
                      errFields.find(
                        (e) => e.key === 'password_input_confirmation'
                      ) &&
                      errFields
                        .find((e) => e.key === 'password_input_confirmation')
                        .message.join('<br/>'),
                  }}
                />
              </div>

              <div className='action-wrapper'>
                <div>
                  {err ? (
                    <p
                      className='error-text'
                      dangerouslySetInnerHTML={{ __html: err }}
                    />
                  ) : (
                    ''
                  )}

                  <button
                    type='submit'
                    disabled={isProcessing}
                    style={{
                      width: 'auto',
                    }}>
                    Change Password
                  </button>
                  <Link to={'/login'}>Back to Login</Link>
                </div>
              </div>
            </form>
          </div>
        </ContainerStyled>
      </LoginStyled>

      <ReactModal
        background={'rgba(0, 0, 0, 0.25)'}
        toggle={_isOpen}
        toggleHandler={_isOpenHandler}
        // withHeader={false}
        contentContainerStyle={{
          background: '#fff',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          maxWidth: '554px',
          width: '100%',
          minHeight: '40vh',
          padding: '15px',
          overflow: 'visible',
        }}>
        <>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
            alt='checkmark-icon.svg'
            style={{
              width: '48px',
              height: '48px',
              display: 'block',
              margin: 'auto',
            }}
          />
          <h4
            style={{
              fontWeight: '600',
              fontSize: '24px',
              color: '#327FBE',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Congratulations
          </h4>

          <p
            style={{
              color: '#354F6',
              fontSize: '18px',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            You have successfully changed your password
          </p>

          <div
            style={{
              display: 'flex',
            }}>
            <Link
              to='/login'
              className='btn-common'
              style={{
                background: '#68C552',
                color: '#fff',
                textDecoration: 'none',
              }}>
              Back to Login
            </Link>
          </div>
        </>
      </ReactModal>
    </Layout>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doMakeReset: (payload) => dispatch(makeReset(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
