import { pick } from 'ramda.pick/pick';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updatePage } from '../../../actions/page';
import { CookieNoticeStyled } from '../../styles/cookieNotice.style';
import { withCookies } from 'react-cookie';
import moment from 'moment';
import { ContainerStyled } from '../../styles/layout.style';

const CookieNotice = (props) => {
  const { doUpdatePage, cookies } = props;

  const checkCookie = () => {
    const acceptedCookied = cookies.get('acceptedCookied') || false;
    if (acceptedCookied) {
      doUpdatePage({ acceptedCookied: true });
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      checkCookie();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CookieNoticeStyled>
        <ContainerStyled>
          <p>
            Dear PawnHero customers: This website uses cookies to ensure you get
            the best experience on our website.{' '}
            <Link to='/privacy-policy'>Read more</Link>
          </p>
          <button
            onClick={() => {
              cookies.set('acceptedCookied', 'user accept this', {
                path: '/',
                expires: moment().add(3, 'd').toDate(),
              });
              doUpdatePage({ acceptedCookied: true });
            }}>
            I understand
          </button>
        </ContainerStyled>
      </CookieNoticeStyled>
    </>
  );
};

const mapStateToProps = pick(['page']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePage: (page) => dispatch(updatePage(page)),
});

const withCookie = withCookies(CookieNotice);
export default connect(mapStateToProps, mapDispatchToProps)(withCookie);
