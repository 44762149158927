import { pick } from 'ramda.pick/pick';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeUserProfile, updateUserProfile } from '../../../actions/user';
import { handleSuccess } from '../../../utils/globals';
import uploadFileService from '../../../utils/uploadFileService';
import { AccountFormStyle } from '../../styles/dashboard.style';

const AccountForm = ({ user, doMakeUserProfile, doUpdateUserProfile }) => {
  const { user: _user } = user;

  const [isLoading, isLoadingHandler] = useState(false);
  const [file, fileHandler] = useState('');
  const fileRef = useRef();

  const uploadFiled = (file) => {
    return new Promise(async (resolve, reject) => {
      uploadFileService
        .uploadWithThumb(file, (progressEvent) => {
          console.log('progressEvent', progressEvent);
        })
        .then((response) => {
          resolve(handleSuccess(response));
        })
        .catch((error) => {
          console.log('error', error);
          resolve({ success: false, error });
        });
    });
  };

  useEffect(() => {
    let cancel = false;
    if (file && !cancel) {
      isLoadingHandler(true);
      uploadFiled(file)
        .then((response) => {
          const { success = false, data: _files } = response;

          if (success) {
            const { image, thumbnail } = _files;

            const _payload = {
              display_photo: image,
              display_photo_thumb: thumbnail,
            };

            doMakeUserProfile(_payload)
              .then((response) => {
                const { success = false } = response;

                if (success) {
                  doUpdateUserProfile(_payload);
                }
                if (!cancel) {
                  isLoadingHandler(false);
                }
              })
              .catch((error) => {
                if (!cancel) {
                  isLoadingHandler(false);
                }
              });
          } else {
            if (!cancel) {
              isLoadingHandler(false);
            }
          }
        })
        .catch((error) => {
          if (!cancel) {
            isLoadingHandler(false);
          }
        });
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, [file]);

  return (
    <>
      <AccountFormStyle>
        <div className='account-data-wrapper'>
          <div>Account Details</div>
          <div>
            <div className='avatar-wrapper'>
              <label htmlFor='profile-field'>
                {isLoading ? (
                  <p>loading...</p>
                ) : (
                  <>
                    {_user.display_photo ? (
                      <img src={_user.display_photo} alt='user-profile' />
                    ) : (
                      <img
                        className='default'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/front-profile-default-ico.svg'
                        alt=''
                      />
                    )}
                  </>
                )}
              </label>
              <input
                type='file'
                id='profile-field'
                accept='image/*'
                ref={fileRef}
                onChange={(e) => {
                  const _file = e.target.files[0];
                  fileHandler(_file);
                }}
              />
            </div>

            <>
              <div className='info-wrapper'>
                <div className='row-wrapper'>
                  <div>First name</div>
                  <div>{_user.first_name}</div>
                </div>
                <div className='row-wrapper'>
                  <div>Last name</div>
                  <div>{_user.last_name}</div>
                </div>
                <div className='row-wrapper'>
                  <div>Email address</div>
                  <div>{_user.email}</div>
                </div>
                <div className='row-wrapper'>
                  <div>Mobile number</div>
                  <div>{_user.mobile}</div>
                </div>
                <div className='row-wrapper'>
                  <div>Status</div>
                  <div>Verified</div>
                </div>
                <div className='row-note-wrapper'>
                  <p>
                    If you wish to change your personal details, you can contact
                    our customer service representatives at 8828-2274 between
                    9:00 AM and 7:00 PM.
                  </p>
                </div>
              </div>
            </>
          </div>
        </div>

        <div className='credential-link-wrapper'>
          <div>Password</div>
          <Link className='btn' to='/dashboard/account/credential'>
            Change
          </Link>
        </div>
      </AccountFormStyle>
    </>
  );
};

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({
  doMakeUserProfile: (payload) => dispatch(makeUserProfile(payload)),
  doUpdateUserProfile: (user) => dispatch(updateUserProfile(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
