import { INIT_REDEEM, UPDATE_REDEEM, RESET_REDEEM } from '../actions/redeem';
import update from 'immutability-helper';

const redeem = (state = {}, action) => {
  switch (action.type) {
    case INIT_REDEEM:
      return action.redeem;
    case UPDATE_REDEEM:
      return update(state, { $merge: action.redeem });
    case RESET_REDEEM:
      return {};
    default:
      return state;
  }
};

export default redeem;
