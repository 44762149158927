import React, { useEffect, useState } from 'react';
import { pick } from 'ramda.pick/pick';
import { connect } from 'react-redux';
import { fetchCategories } from '../../../actions/category';
import { updatePawn } from '../../../actions/pawn';
import { isEmpty } from '@ramda/isempty/isEmpty';

// component
import MyContentLoader from '../contentLoader';
import SearchBar from '../searchBar';
import SearchMobile from '../searchMobile';
import SearchMobileModal from '../searchMobileModal';

// style
import { CategoryStyled } from '../../styles/category.style';
import { Link } from 'react-router-dom';

const Category = ({ pawn, doFetchCategories, doUpdatePawn }) => {
  const { slideLeft = false } = pawn;

  // states
  const [categories, setCategoties] = useState([]);
  const [loading, loadingHandler] = useState(false);
  const [mobileToggle, mobileToggleHandler] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      doUpdatePawn({ position: 0, selectedEntities: null, formValues: {} });
      getData();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    loadingHandler(true);
    const data = await doFetchCategories();
    setCategoties(data);
    loadingHandler(false);
  };
  return (
    <>
      <div className={`pawn-activity-content ${loading ? 'loading' : 'load'}`}>
        <CategoryStyled slideLeft={slideLeft}>
          <div className='pawn-activity-header category'>
            <SearchBar />
            <SearchMobile handleMobileSearchToggle={mobileToggleHandler} />
          </div>
          <div className='pawn-activity-body'>
            {!isEmpty(categories) ? (
              <>
                {categories.map((cat, key) => {
                  return (
                    <Link
                      to={`/pawn/${cat.slug}`}
                      id={`pawn_category_${cat.slug}_link`}
                      key={key}
                      onClick={() => {
                        doUpdatePawn({ slideLeft: false });
                      }}>
                      <div className='category-item '>
                        <div className='category-icon-wrapper'>
                          <img src={cat.image} alt='' />
                        </div>
                        <div className='category-label-wrapper'>
                          <p>{cat.category_name}</p>
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-blue-ico.svg'
                            alt='>'
                          />
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-white-ico.svg'
                            alt='>'
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </>
            ) : (
              <>
                <MyContentLoader />
              </>
            )}
          </div>
        </CategoryStyled>
      </div>
      <SearchMobileModal
        mobileSearchToggle={mobileToggle}
        handleMobileSearchToggle={() => mobileToggleHandler(false)}
      />
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doFetchCategories: () => dispatch(fetchCategories()),
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
