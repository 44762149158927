import { getTime, getHolidays, getWorkingHours } from '../utils/time';

export const fetchTime = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTime()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchHolidays = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getHolidays()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchWorkingHours = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getWorkingHours()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
