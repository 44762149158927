import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HomeSearchBar from '../../components/homeSearch';

const { ContainerStyled } = require('../../styles/layout.style');
const { BannerStyled } = require('../../styles/home.style');
const Banner = (props) => {
  return (
    <BannerStyled>
      <ContainerStyled>
        <div>
          <h2>
            <p>For your cash needs,</p>
            <p>
              there's <span>Pawn</span>
              <span>Hero</span>
            </p>
          </h2>

          <div className='form-wrapper'>
            {/* <LazyLoadImage
              className='mobile-endorser'
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/model-formal-small.png'
              alt=''
            /> */}
            <div>
              <p>Get a free estimate for your item</p>
              <div>
                <label>I want to pawn my</label>
                <div>
                  <HomeSearchBar />
                </div>
              </div>
            </div>
          </div>

          <div className='value-preposition-wrapper'>
            <ul>
              <li>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/bangko-sentral-certified-blue.svg'
                  alt='bangko sentral supervised'
                />
              </li>
              <li>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pioneer-logo.svg'
                  alt='pioneer insured'
                />
              </li>
            </ul>
          </div>

        </div>

        <div>
          <p className='weight-600 text-green size-32'>We've moved to a <span className='text-blue'>new</span> location</p>
          <p className='weight-600 text-blue-dark size-18'>7th Floor, Avecshares Center, 38th Street, 1132 University Parkway North, Bonifacio Global City, Taguig City, Metro Manila</p>
          <LazyLoadImage
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/office-map.svg'
            alt='office map relocation'
          />
          <p className='text-blue-dark'>
            <Link to='/service-advisory' id='home_service_advisory_link' className='weight-600 text-blue text-underline'>
              Visit our Service Advisory page 
            </Link> to learn more.
          </p>
          {/* <LazyLoadImage
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/model-formal-big.png'
            alt=''
          /> */}
        </div>
      </ContainerStyled>
    </BannerStyled>
  );
};

export default Banner;
