import React from 'react';
import { WhyPawnheroSideStyled } from '../../styles/whyPawnHeroSide.style';

const WhyPawnhero = () => {
  return (
    <WhyPawnheroSideStyled>
      <div className='advantage-block'>
        <div className='advantage-block-header'>
          <h2>Why choose PawnHero?</h2>
        </div>

        <div className='advantage-block-body'>
          <ul>
            <li>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
                alt='checkmark-icon.svg'
              />
              Instant cash release
            </li>
            <li>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
                alt='checkmark-icon.svg'
              />
              1 day loan approval
            </li>
            <li>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
                alt='checkmark-icon.svg'
              />
              Pawn safely at home
            </li>
            <li>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
                alt='checkmark-icon.svg'
              />
              Free item insurance coverage
            </li>
          </ul>
        </div>
      </div>

      <div className='accreditation-block'>
        <div className='accreditation-wrapper'>
          <ul>
            <li>
              <p>
                *Enjoy 1 day loan approval upon item pick-up and instant cash
                release via InstaPay.
              </p>
            </li>
            <li>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/bangko-sentral-certified.svg'
                alt='Duly licensed by Bango Sentral ng Pilipinas'
              />
            </li>
            <li>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pioneer-insured.svg'
                alt='all pawned items are insured by pioneer insurance'
              />
            </li>
          </ul>
        </div>
      </div>
    </WhyPawnheroSideStyled>
  );
};

export default WhyPawnhero;
