import styled from 'styled-components';

export const PawningStyled = styled.div`

    display: flex;
    justify-content: space-between;
    margin-top: 30px; 
    @media (max-width: 1024px) {
        margin-top: 0; 
    }

    &  .fixed-breadcrum {
        position: fixed;
        top: 175px;
        @media (max-width: 1024px) {
            display: none;
        }
    }

    & .fixed-breadcrum-mob {
        display: none; 
    } 
    & .nav-container-mob {
        display: none;
    }
    & .nav-container-link {
        display: none;
    }
    @media (max-width: 959px) {
        & .nav-mobile {
            top: 104px;
            padding: 15px;
            width: 100%;
            position: fixed;    
            background: #354F65;
            left: 0;
            z-index: 9;
            @media (max-width: 677px) {
                top: 115px;
            }
            @media (max-width: 390px) {
                top: 132px;
            }
        }

        & .fixed-breadcrum-mob {
            display: block; 
        } 

        & .clearfix {
            &:after {
                clear: both;
                content: '';
                display: table;
            }
        }

        & .nav-container-mob {
            background: #35596F;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            display: block;
            padding: 15px;
            position: relative;
            width: 100%; 
            

            & .active-nav-mob {
                color: #68C552;
                font-weight: 600;
                text-align: center;

            }
        } 

        & .nav-menu-mob {
            width: 20px;
            position: absolute;
            right: 20px;
            top: 16px;

            &> img {
                transition: all 0.3s ease 0s;
            }
            &.active {
                &> img {
                    transform: rotate(180deg);
                }
            }
            & .arrow-container {
                width: 20px;
            }
        }

        & .nav-container-link {
            background: #35596F;
            border: none;
            border-radius: 5px;
            margin-top: 10px;
            padding: 15px; 
            display: block;
            &.hide {
                display: none;
            }

            & ul {
                list-style: none;
                > li {
                    padding: 6px 0;
                    text-align: center;
                    > a {
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    

    & .nav-container {
        position: relative;
        width: 30%; 
        @media (max-width: 1024px) {
            display: none;
        }
        & .left-content {
            background-color: #35596F;
            border-radius: 5px;
            margin-right: 15px;
            padding: 15px 30px;
            position: fixed;
            z-index: 1;
    
            @media (max-width: 1024px) {
                display: none;
            }
    
            ul {
                list-style: none;
                > li {
                    padding: 6px 0;
                    > a {
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
            }
    
        }
    }


    & .content {
        background-color: #FFFFFF;
        border-radius: 5px;
        width: 70%;
        z-index: 8;

        @media (max-width: 1024px) {
            width: 100%;
        }
        &.content-mob {
            @media (max-width: 959px) {
                margin-top: 75px;
            }
        }

        .top-banner {
            background-color: #CECECE;
            background-image: url('https://d1v5w8bodpeh4i.cloudfront.net/assets/home-background.png');
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: center;
            background-size: cover;
            height: auto;
            max-height: 200px;
            padding: 30px;
            position: relative;
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            @media (max-width: 520px) {
                padding: 15px 5px 0;
            }
            

            & .banner-title {
                width: 50%;
                align-items: center;
                display: flex;
                justify-content: center;
                
                & .title-text { 
                    font-size: 32px;
                    color: #327FBE;
                    @media (max-width: 520px) {
                        font-size: 18px;
                    }
                }

            }
            & .banner-image {
                width: 50%;
                align-self: baseline;
                justify-content: center;
                display: flex;
                @media (max-width: 520px) {
                    align-self: end;
                }
                
                & > img{
                    width: 200px;
                }
                @media (max-width: 520px) {
                    & > img{
                        width: 150px;
                    }
                }
            }

        }

        & .section {
            padding: 30px;
            @media (max-width: 420px) {
                padding: 15px;
            }
            &.bottom-zero {
                padding-bottom: 0;
            }

            &.bg-gray {
                background-color: #EFEFEF;
            }

            & .section-title {
                color: #327FBE;
                font-size: 24px;
                font-weight: 700;
            }   

            & .section-text {
                color: #354F65 !important;
                font-size: 14px;
                font-weight: 500;
                padding: 15px 0 0 0;
                text-align: justify;
                word-wrap: break-word;
                &.last-item {
                    padding: 15px 0 15px 0;
                }
                &.section-subtitle {
                    color: #354F65;
                    font-weight: 600;
                    text-align: left;
                    padding: 15px 0 15px 0;
                }
                &.text-italic {
                    font-style: italic;
                }
                & > a {
                    color: -webkit-link;
                }
            }

            & .section-list {
                color: #354F65 !important;
                font-size: 14px;
                font-weight: 500;
                padding-left: 30px;
                padding-bottom: 15px;
                & > li {
                    text-align: justify;
                }
                &.list-decimal {
                    list-style: decimal;
                }
                &.zero-bottom {
                    padding-bottom: 0;
                }
            }

            & .button-container {
                text-align: center;
                padding: 30px;
                & .button {
                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                    border-radius: 25px;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 400;
                    padding: 5px 25px;
                    text-align: center;
                    border: 0;
                    outline: none !important;

                    &.orange {
                        background: #ff7019;
                        color: #ffffff;
                    }
                    &.green {
                        background: #68C552;
                        color: #ffffff;
                    }
                }
            }
            
        }

        & .computation-wrapper {
            text-align: center;
            position: relative;
            & > img {
                width: 70%;
            }
            @media (max-width: 620px) {
                & > iframe {
                    width: 100%;
                }
            }
        }

        & .card-wrapper {
            & .card-wrapper-items {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                &.single {
                    justify-content: start;
                }
            }
            
            & .card {
                margin: 10px 5px;
                &.hidden {
                    visibility: hidden;
                    @media (max-width: 520px) {
                        display: none;
                    }
                }
                & .image-wrapper {
                    width: 135px;
                }
                & .card-title {
                    color: #354F65;
                    font-size: 12px;
                    text-align: center;
                }
                // @media (min-width: 926px) and (max-width: 960px) {
                //     &.last-item {
                //         display: none;
                //     }
                // }
                @media (min-width: 1165px) {
                    &.last-item {
                        display: none;
                    }
                }
                
            }
        }

        & .items-wrapper {
            margin: 30px 0;
            display: flex;
            justify-content: space-between;
            @media (max-width: 1024px) {
                display: inline-block;
            }

            & .column-wrapper{
                width: 48%; 
                @media (max-width: 1024px) {
                    width: 100%; 
                }
                & .faq-collapse {
                    margin-bottom: 15px;
                    &.close {
                        & .title {
                            border-radius: 5px;    
                        }
                    }
                    &.open {
                        & .title {
                            border-radius: 5px 5px 0 0;
                        }
                        & .content {
                            padding: 15px;
                        }
                    }
                    & .title {
                        padding: 15px 10px;
                    }

                    & .content {
                        width: 100%;

                    }
                }
                
            }
        }

        & .feedback-wrapper {
            & .feedback-container {
                background: #ffffff;
                box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                border: 0.5px solid #E1E1E1;
                border-radius: 5px;
                min-height: 330px;
                min-width: 270px;
                @media (max-width: 479px) {
                    min-width: 225px;
                    min-height: 440px;
                }
                padding: 15px;

                & .ratings {
                    display: flex;
                    & .star {
                        background: #DCDCE6;
                        padding: 5px;
                        margin-right: 2px;
                        &.green { background: #73CF11; }
                        &.dark-green { background: #00B67A; }
                    }
                    & .star > img {
                        width: 15px;
                    }
                }

                & .feedback-title {
                    color: #354F65;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 15px 0;
                }

                & .feedback-text {
                    color: #354F65;
                    font-size: 14px;
                    font-weight: 400;
                    font-style: italic;
                }

            }

            & .owl-stage {
                padding-left: 0 !important;
            }

            & .owl-nav {
                & .owl-prev {
                    position: absolute;
                    left: -25px;
                    bottom: 50%;
                    margin-bottom: -38px;                
                    width: 45px;
                }
                & .owl-next {
                    position: absolute;
                    right: -25px;
                    bottom: 50%;
                    margin-bottom: -38px;
                    width: 45px;
                }
            }
        }
    }


    

`;