import update from 'immutability-helper';
import { UPDATE_PAWN, SET_PAWN, RESET_PAWN } from '../actions/pawn';

const pawn = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PAWN:
      return update(state, { $merge: action.pawn });
    case SET_PAWN:
      return action.pawn;
    case RESET_PAWN:
      return {};
    default:
      return state;
  }
};

export default pawn;
