import React, { useState, useEffect, Fragment } from 'react';
import {
  TicketListStyled,
  TicketSearchNav,
} from '../../styles/dashboard.style';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchMyTickets } from '../../../actions/ticket';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { removeEmpty } from '../../../utils/helper';
import ElipseLoader from '../shared/elipseLoader';

const TicketList = ({ doFetchMyTickets }) => {
  // states

  const [pagination, paginationHandler] = useState({
    page_number: null,
    page_size: null,
    total_entries: 0,
    total_pages: null,
  });
  const [tickets, ticketsHandler] = useState([]);
  const [isLoading, isLoadingHandler] = useState(true);
  const [isLoadingMore, isLoadingMoreHandler] = useState(false);
  const [search, searchHandler] = useState('');

  const getData = () => {
    return new Promise((resolve, reject) => {
      doFetchMyTickets()
        .then((response) => {
          const {
            data,
            success,
            page_number,
            page_size,
            total_entries,
            total_pages,
          } = response;

          if (success) {
            resolve({
              data,
              pagination: {
                page_number,
                page_size,
                total_entries,
                total_pages,
              },
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const _getData = (param) => {
    isLoadingHandler(true); 

    doFetchMyTickets(removeEmpty(param))
      .then((response) => {
        const {
          data,
          success,
          page_number,
          page_size,
          total_entries,
          total_pages,
        } = response;

        if (success) {
          
          ticketsHandler(data);
          paginationHandler({
            page_number,
            page_size,
            total_entries,
            total_pages,
          });
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        isLoadingHandler(false);
      });
  };

  const _getMoreData = (param) => {
    isLoadingMoreHandler(true);

    doFetchMyTickets(removeEmpty(param))
      .then((response) => {
        const {
          data,
          success,
          page_number,
          page_size,
          total_entries,
          total_pages,
        } = response;

        if (success) {
          ticketsHandler([...tickets, ...data]);
          paginationHandler({
            page_number,
            page_size,
            total_entries,
            total_pages,
          });
        }
        isLoadingMoreHandler(false);
      })
      .catch((err) => {
        isLoadingMoreHandler(false);
      });
  };

  const _clearSearch = () => {
    searchHandler('')
    isLoadingHandler(true);
    _getData({search: ''})
  }

  useEffect(() => {
    let cancel = false;

    isLoadingHandler(true);
    getData()
      .then(({ data, pagination }) => {
        if (!cancel) {
          isLoadingHandler(false);
          ticketsHandler(data);
          paginationHandler(pagination);
        }
      })
      .catch((err) => {
        isLoadingHandler(false);
      });

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TicketSearchNav>
        <div>
          <p>{pagination.total_entries} pawn tickets { search ? 
            <>
              <a 
                href={() => false}
                onClick={_clearSearch}
                className="clear-search">
                Clear search results</a>
            </>
            : 
            <></>
            }
          </p>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              _getData({ search });
            }}>
            <input
              type='text'
              value={search}
              onChange={(e) => searchHandler(e.target.value)}
            />
            <button type='submit'>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon.svg'
                alt='search'
              />
            </button>
          </form>
        </div>
      </TicketSearchNav>
      <TicketListStyled>
        <div className='wrapper'>
          {isLoading ? (
            <>
              <ElipseLoader />
            </>
          ) : (
            <>
              {tickets.map((e, i) => (
                <Fragment key={i}>
                  <div className='item'>
                    <div>
                      <div>
                        <p>{e.ticket_no}</p>
                        <p className='tag-status'>{e.status}</p>
                      </div>
                      <div>
                        <div>
                          {!isEmpty(e.items) && (
                            <>
                              <p>{e.items[0].product.category}</p>
                              <p>{e.items[0].product.brand}</p>
                              <p>{e.items[0].product.name}</p>
                            </>
                          )}
                        </div>
                        <div>
                          <Link
                            to={`/dashboard/ticket/${e.ticket_no}`}
                            className='button'>
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))}
              {isLoadingMore && (
                <div
                  style={{
                    margin: 'auto',
                    width: '100%',
                    display: 'block',
                  }}>
                  <ElipseLoader />
                </div>
              )}

              {pagination && pagination.page_number < pagination.total_pages && (
                <div
                  className='load-more-wrapper'
                  onClick={() => {
                    _getMoreData({
                      page: pagination.page_number + 1,
                      search: search
                    });
                  }}>
                  <button className='button'>Load more pawn tickets</button>
                </div>
              )}
            </>
          )}
        </div>
      </TicketListStyled>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchMyTickets: (param) => dispatch(fetchMyTickets(param)),
});

export default connect(null, mapDispatchToProps)(TicketList);
