import React, { useState } from 'react';

const CollapseMenu = (props) => {
  const [isOpen, isOpenHandler] = useState(false);
  const { title } = props;

  return (
    <>
      <div className={`submenu-wrapper ${isOpen ? 'show' : ''}`}>
        <div
          className='title'
          onClick={() => {
            isOpenHandler(!isOpen);
          }}>
          <p>{title}</p>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/down-arrow-white-ico.svg'
            alt=''
          />
        </div>
        <div className='sub-menus'>{props.children}</div>
      </div>
    </>
  );
};

export default CollapseMenu;
