import React from 'react';

// style
import { SectionStyled } from '../../styles/section.style';
const { GoldOfferStyle } = require('../../styles/home.style');
const { ContainerStyled } = require('../../styles/layout.style');

const GoldOffer = (props) => {
  return (
    <>
        <SectionStyled className="full-section" bgColor="#000000" bgImage="https://d1v5w8bodpeh4i.cloudfront.net/assets/go-background.svg">
            <GoldOfferStyle>
                <div className='top-left-image'>
                    <img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/go-corner.svg" alt=""></img>
                </div>
                <ContainerStyled>
                    <div className='center-image'>
                        <img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/gold-offer2.svg" alt=""></img>
                    </div>
                    
                    <div className='detail'>
                        <h2>Higher appraisal rate for gold</h2>
                        <div className='unit'>
                            <small>As much as</small>    
                            <h1>₱ 3,000.00/gram*</h1>
                        </div>
                        <p className='note'>*Based on 24k gold</p>
                        <p className='note'>*3.5% interest for gold</p>
                        <p className='note'>*Appraisal rates vary per carat classification  </p>
                    </div>
                    
                    <div className="button-container">
                        <a 
                        className="button orange"
                        href='/pawn' 
                        target='_blank' 
                        rel='noopener noreferrer'>
                            Pawn now
                        </a>
                    </div>
                </ContainerStyled>
                <div className='bottom-right-image'>
                    <img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/go-corner.svg" alt=""></img>
                </div>
            </GoldOfferStyle>
        </SectionStyled>
    </>
  );
};

export default GoldOffer;
