import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchHolidays,
  fetchTime,
  fetchWorkingHours,
} from '../../../actions/time';

const ChatPlugin = ({ doFetchWorkingHours, doFetchTime, doFetchHolidays }) => {
  const getWorkingHours = async () => {
    const time = await doFetchTime();
    const { is_active, end_time, start_time } = await doFetchWorkingHours();
    const holidays = await doFetchHolidays();
    const today = moment(time).format('YYYY-MM-DD');

    const _isBetween = moment(time).isBetween(
      today + ' ' + start_time,
      today + ' ' + end_time
    );

    const isHoliday = holidays.find(
      (e) => e === moment(time).format('DD/MM/YYYY')
    );

    const todayDay = moment(time).day();

    if (isHoliday || todayDay === 6 || todayDay === 7) {
      //holiday or weekend
      updateDisplayChat(false);
    } else {
      if (is_active) {
        // may work
        if (_isBetween) {
          updateDisplayChat(true);
        } else {
          updateDisplayChat(false);
        }
      } else {
        //no work
        updateDisplayChat(false);
      }
    }
  };

  const updateDisplayChat = (activity) => {
    if (window) {
      var fbroot = document.getElementById('fb-root');

      if (fbroot) {
        if (activity) {
          fbroot.classList.add('show');
        } else {
          fbroot.classList.remove('show');
        }
      }
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getWorkingHours();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return <></>;
};

const mapDispatchToProps = (dispatch) => ({
  doFetchWorkingHours: () => dispatch(fetchWorkingHours()),
  doFetchTime: () => dispatch(fetchTime()),
  doFetchHolidays: () => dispatch(fetchHolidays()),
});
export default connect(null, mapDispatchToProps)(ChatPlugin);
