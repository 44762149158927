import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

// utility
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { fetchSummaryTicket, makeTicketSurvey } from '../../../actions/ticket';
import { isEmpty } from '@ramda/isempty/isEmpty';

// child component
import Layout from '../layout';
import ReactModal from '../../components/shared/reactModal';
import SelectField from '../../components/shared/select';

const { ContainerStyled } = require('../../styles/layout.style');
const { PawnThankyouStyledV2 } = require('../../styles/pawn-thankyou.style');

const PawnThankYou = (props) => {
  const { doFetchSummaryTicket, doMakeSummarySurvey } = props;
  const history = useHistory();
  const { ticketNum } = useParams();

  const thankyouDateExpiry =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.thankyouDateExpiry
      ? props.location.state.thankyouDateExpiry
      : false;

  const now = moment().tz(timezone).unix();

  const [loading, loadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);
  const [user, userHandler] = useState(null);
  const [surveyToggle, surveyToggleHandler] = useState(true);
  const [surveyValue, surveyValueHandler] = useState('');
  const [comment, commentHandler] = useState('');

  const surveyOptions = [
    {
      label: 'Facebook',
      value: 'facebook',
    },
    {
      label: 'Google Search',
      value: 'google_search',
    },
    {
      label: 'Instagram',
      value: 'instagram',
    },
    {
      label: 'Billboards',
      value: 'billboards',
    },
    {
      label: 'Radio Ads',
      value: 'radio_ads',
    },
    {
      label: 'Friends',
      value: 'friends',
    },
    {
      label: 'Family',
      value: 'family',
    },
    {
      label: 'Workmates',
      value: 'workmates',
    },
    {
      label: 'Flyers',
      value: 'flyers',
    },
    {
      label: 'Bus Ads',
      value: 'bus_ads',
    },
    {
      label: 'Lamp Post Banners',
      value: 'lamp_post_banners',
    },
    {
      label: 'Condominium Ads',
      value: 'condominium_ads',
    },
    {
      label: 'Corporate Ads',
      value: 'corporate_ads',
    },
    {
      label: 'Hospital Ads',
      value: 'hospital_ads',
    },
  ];

  useEffect(() => {
    const _test = thankyouDateExpiry > now;

    if (!_test) {
      // expired
      history.push('/guest');
    } else {
      loadingHandler(true);
      doFetchSummaryTicket({ ticketNum })
        .then(({ data }) => {
          ticketDataHandler(data);
          const { user } = data;
          userHandler(user);
          loadingHandler(false);
        })
        .catch((err) => {
          console.log('err', err);
          loadingHandler(false);
        });
    }

    return () => {
      surveyToggleHandler(false);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <PawnThankyouStyledV2>
          {loading ? (
            <>
              <div style={{ padding: '50px 0', width: '100%' }}>
                <img
                  className='loader'
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                  alt='loading'
                  style={{
                    width: '32px',
                    height: '32px',
                    margin: 'auto',
                    display: 'flex',
                  }}
                />
              </div>
            </>
          ) : (
            <section className='banner'>
              <ContainerStyled>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou.svg'
                  alt='thank you'
                />
                <h2>Thank you, {user ? user.firstName : ''}!</h2>
                <h3>We are now processing your loan application.</h3>
                <p className='highnotes'>
                  Your ticket number is <span>{ticketData.ticketNo}</span>.
                </p>

                <p className='notes'>
                  Keep your lines open, as our customer service representative
                  will contact you between 9:00 AM and 7:00 PM to discuss the
                  initial loan offer.
                </p>

                <div className='cta-wrapper'>
                  <Link to='/' className='btn'>
                    Back to Home
                  </Link>
                </div>
              </ContainerStyled>
            </section>
          )}

          <ReactModal
            background={'rgba(0, 0, 0, 0.25)'}
            toggle={surveyToggle}
            toggleHandler={surveyToggleHandler}
            contentContainerStyle={{
              background: '#327FBE',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
              maxWidth: '554px',
              width: '100%',
              minHeight: '40vh',
              padding: '15px',
              overflow: 'visible',
            }}>
            <>
              <div className='survey-modal-content'>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/survey-thanks-ico.svg'
                  alt='thank you'
                  className='logo'
                />
                <h3>Some quick questions</h3>

                <div className='content-row'>
                  <p>How did you find out about PawnHero?</p>
                  <SelectField
                    value={surveyValue}
                    className={`ctm-select`}
                    classNamePrefix={'ctm'}
                    options={surveyOptions}
                    placeholder={'Select your answer'}
                    onChange={(val) => {
                      surveyValueHandler(val);
                    }}
                  />
                </div>
                <div className='content-row'>
                  <p>
                    Do you have comments or suggestions to improve our service?
                    Share them with us here. (optional)
                  </p>
                  <textarea
                    placeholder='Write your comment here'
                    value={comment}
                    onChange={(e) => commentHandler(e.target.value)}
                  />
                </div>

                <button
                  className='btn'
                  disabled={isEmpty(surveyValue)}
                  onClick={() => {
                    const _payload = {
                      checkboxValue: [surveyValue.value],
                      textValue: comment,
                      ticket_no: ticketNum,
                    };

                    doMakeSummarySurvey(_payload).then((data) => {
                      surveyToggleHandler(false);
                    });
                  }}>
                  Submit
                </button>
              </div>
            </>
          </ReactModal>
        </PawnThankyouStyledV2>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
  doMakeSummarySurvey: (survey) => dispatch(makeTicketSurvey(survey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PawnThankYou);
