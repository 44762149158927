import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const postTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    const {
      productData: { product_id },
      attribute: attr,
      selectedEntities,
      userDetails,
      otp,
    } = payload;

    const ticketData = {
      otp,
      user_details: {
        ...userDetails,
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
      }, //change fname => first_name, lname => last_name
      product_id,
      form_values: attr
        .map((att) => {
          const _selectedValues = selectedEntities[att.code];
          const _tmp = {
            attribute_id: att.attribute_id,
            attribute_code: att.code,
            selected: _selectedValues,
          };
          return _tmp;
        })
        .filter((e) => e.selected),
    };

    try {
      const response = await axios.post('/api/ticket/make', ticketData, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getSummaryTicket = (payload) => {
  const { ticketNum } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/ticket/summary/${ticketNum}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getAcceptanceOffer = (payload) => {
  const { ticketNum } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/ticket/acceptance/${ticketNum}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postAcceptInitialOffer = (payload) => {
  const {
    data: {
      offer: { raw: offerValue },
      booking,
      cashout,
    },
    ticketNum,
  } = payload;

  // remove filler data
  delete cashout.cashOutDetails.bankSelected;
  delete cashout.cashOutDetails.bankMethod;

  const _data = {
    offer: offerValue,
    booking,
    cashout,
    ticket_no: ticketNum,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/ticket/acceptance/accept`,
        _data,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postTicketSurvey = (payload) => {
  const {
    checkboxValue: checkbox_value,
    textValue: comment_value,
    ticket_no,
  } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/ticket/make/survey`,
        {
          checkbox_value,
          comment_value,
          ticket_no,
        },
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getFinalOffer = ({ ticketNum }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/ticket/offer/${ticketNum}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postFinalOffer = (payload) => {
  const {
    ticketNum: ticket_no,
    data: {
      offer: { raw },
    },
  } = payload;

  const reqPayload = {
    ticket_no,
    offer: raw,
  };

  // ticket/finalOffer/accept
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/ticket/finalOffer/accept`,
        reqPayload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRedeemOffer = (payload) => {
  const { ticketNum } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/ticket/redeem/${ticketNum}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRenewalRequest = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/ticket/make/renewal`,
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRenewalApproved = (payload) => {
  const { ticketNum } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/ticket/renewal/approved/${ticketNum}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketPdf = (payload) => {
  const { ticketNum } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/ticket/pdf/${ticketNum}`, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketView = (payload) => {
  const { ticketNum } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/ticket/view/${ticketNum}`, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketRelease = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/ticket/release`, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postPaymentSlip = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/payment/slip`, payload, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketTrail = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/ticket/trail/${payload}`, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRedeemDirectData = (payload) => {
  const { ticketNum } = payload;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api//ticket/redeem/directData/${ticketNum}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getMyTickets = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const _urlparas = payload
        ? `?${Object.keys(payload)
            .map((k) => {
              return (
                encodeURIComponent(k) + '=' + encodeURIComponent(payload[k])
              );
            })
            .join('&')}`
        : '';

      const response = await axios.get(
        `/api/ticket/myTickets${_urlparas}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
