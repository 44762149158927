import styled from 'styled-components';

export const NotificationListWrapperstyle = styled.div`
  background: pink;
  width: 100%;
  max-width: 410px;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  ul {
    list-style: none;

    & > li {
      position: relative;
      padding: 15px 30px 15px 15px;
      p.title {
        color: #327fbe;
        font-weight: 600;
        font-size: 17px;
      }

      p.content {
        font-weight: 600;
        color: #354f65;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.unread {
        p {
          color: #8b959c;
          &.content {
            font-weight: normal;
          }
        }
      }

      &:not(:last-child)::before {
        position: absolute;
        content: '';
        height: 1px;
        width: 95%;
        background: #8b959c;
        bottom: 0;
        right: 0;
      }

      &::after {
        position: absolute;
        content: url('https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/assets/right-arrow-gray-ico.svg');
        height: 13px;
        width: 8px;
        bottom: 0;
        top: 0;
        right: 15px;
        margin: auto;
      }
    }
  }
`;

export const NotificationFormWrapperstyle = styled.div`
  width: 100%;
  max-width: 400px;
  margin-top: 20px;

  div.header-wrapper {
    background: #327fbe;
    border-radius: 5px 5px 0px 0px;
    padding: 15px;

    p {
      color: #ffffff;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
  }

  div.body-wrapper {
    border-radius: 0 0 5px 5px;
    padding: 15px;
    background: #fff;

    div.body-header-wrapper {
      display: flex;
      justify-content: space-between;

      p.title {
        color: #327fbe;
        font-size: 17px;
        font-weight: 600;
      }

      p.time-stamp {
        color: #8b959c;
        font-size: 15px;
      }
    }
    & > p {
      font-size: 15px;
      margin-top: 10px;
    }
  }
`;
