import React, { useState } from 'react';
import { CollapseStyled } from '../../styles/faq.style';

const Collapse = (props) => {
  const { children, title, className, toggle: active = false } = props;

  const [toggle, toggleHandler] = useState(active);
  return (
    <>
      <CollapseStyled className={`${className} ${toggle ? 'open' : 'close'} `}>
        <div
          className='title'
          onClick={() => {
            toggleHandler(!toggle);
          }}>
          <p>{title}</p>
          <img
            className='active'
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/down-arrow-white-ico.svg'
            alt='open'
          />
          <img
            className='inactive'
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/down-arrow-blue-ico.svg'
            alt='open'
          />
        </div>
        <div className='content'>{children}</div>
      </CollapseStyled>
    </>
  );
};

export default Collapse;
