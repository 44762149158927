import React, { useState } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { makeLogin } from '../../../actions/auth';
import Layout from '../layout';
import { Link, useHistory } from 'react-router-dom';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';

const { validateEmail } = require('../../../utils/helper');

// styles
const { LoginStyled } = require('../../styles/login.style');
const { ContainerStyled } = require('../../styles/layout.style');

const Login = ({ doMakeLogin }) => {
  const history = useHistory();
  const [email, emailHandler] = useState(null);
  const [password, passwordHandler] = useState(null);
  const [errFields, errFieldsHandler] = useState([]);
  const [err, errHandler] = useState(null);
  const [isProcessing, isProcessingHandler] = useState(false);

  const credentialValidator = ({ email, password }) => {
    let _return = true;

    // validations
    if (isNil(email) || isEmpty(email)) {
      _return = false;

      errFieldsHandler([
        ...errFields,
        { key: 'email', message: 'Email is required' },
      ]);
    } else {
      //emal has value
      const emailValid = validateEmail(email);
      if (!emailValid) {
        _return = false;
        errFieldsHandler([
          ...errFields,
          { key: 'email', message: 'Email is invalid' },
        ]);
      }
    }

    // password is required
    if (isNil(password) || isEmpty(password)) {
      _return = false;

      errFieldsHandler([
        ...errFields,
        { key: 'password', message: 'Please enter your password' },
      ]);
    }

    return _return;
  };

  const loginHandler = () => {
    isProcessingHandler(true);
    errHandler('');

    const isValid = credentialValidator({ email, password });

    if (isValid) {
      doMakeLogin({ email, password })
        .then(() => {
          isProcessingHandler(false);
          history.push('/dashboard');
        })
        .catch((err) => {
          isProcessingHandler(false);

          const {
            data: { message },
          } = err;

          if (message) {
            const _err = Object.keys(message).map((key) => ({
              key,
              message: message[key],
            }));

            errFieldsHandler(_err);
          }
        });
    }
  };

  return (
    <Layout>
      <LoginStyled>
        <ContainerStyled>
          <h1>Log in to your PawnHero account</h1>
          {/* <p>Consectitur adipiscing elit</p> */}
          <div className='fields-groups-wrapper'>
            <form
              className='fields-wrapper'
              onSubmit={(e) => {
                e.preventDefault();
                loginHandler();
              }}>
              <div className='field-group'>
                <div
                  className={`field-label-wrapper ${
                    email == null || isEmpty(email) ? 'no-value' : 'with-value'
                  } ${
                    errFields.find((e) => e.key === 'email') ? 'is-error' : ''
                  } `}>
                  <input
                    id='login_form_email'
                    type='text'
                    // placeholder='Email address'
                    value={email ? email : ''}
                    onChange={(e) => {
                      emailHandler(e.target.value);
                    }}
                  />
                  <label htmlFor='login_form_email'>
                    Email address
                  </label>
                </div>
                <p className='err-note error'>
                  {(errFields.length &&
                    errFields.find((e) => e.key === 'email') &&
                    errFields.find((e) => e.key === 'email').message) ||
                    `Please enter your valid email address`}
                </p>
              </div>
              <div className='field-group'>
                <div
                  className={`field-label-wrapper ${
                    password == null || isEmpty(password)
                      ? 'no-value'
                      : 'with-value'
                  } 
                  ${
                    errFields.find((e) => e.key === 'password')
                      ? 'is-error'
                      : ''
                  }
                  `}>
                  <input
                    id='login_form_password'
                    type='password'
                    value={password ? password : ''}
                    autoComplete='false'
                    onChange={(e) => {
                      passwordHandler(e.target.value);
                    }}
                  />
                  <label htmlFor='login_form_password'>Password</label>
                </div>
                <p className='err-note error'>
                  {(errFields.length &&
                    errFields.find((e) => e.key === 'password') &&
                    errFields.find((e) => e.key === 'password').message) ||
                    `Please enter your password`}
                </p>
              </div>

              <div className='action-wrapper'>
                <div>
                  {err ? (
                    <p
                      className='error-text'
                      dangerouslySetInnerHTML={{ __html: err }}
                    />
                  ) : (
                    ''
                  )}
                  <Link
                    to={'/forgot'}
                    style={{
                      marginTop: 0,
                    }}>
                    Forgot password?
                  </Link>
                  <button type='submit' disabled={isProcessing}>
                    Login
                  </button>
                  <Link to={'/signup'}>
                    Sign up for a free PawnHero account
                  </Link>
                  <Link to={'/guest'}>
                    Don’t have time? View your pawn ticket directly
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </ContainerStyled>
      </LoginStyled>
    </Layout>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doMakeLogin: (user) => dispatch(makeLogin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
