import styled from 'styled-components';

export const NotFoundPageStyled = styled.div`
  background: radial-gradient(50% 50% at 50% 50%, #354f65 0%, #253d52 100%);
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;

  & > div:first-child {
    margin: auto;
    display: flex;

    @media (max-width: 768px) {
      flex-flow: column;
      align-items: center;
      text-align: center;
    }

    & > div {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;

      h1 {
        color: #ffffff;
        font-weight: 600;
        font-size: 32px;

        @media (max-width: 768px) {
          text-align: center;
        }
      }

      p {
        font-size: 14px;
        color: #8c969d;
      }

      & > div {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        a {
          text-decoration: none;
        }

        & > a:first-child {
          background: #ff7019;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-weight: 600;
          color: #ffffff;
          border-radius: 15px;
          text-align: center;
          padding: 7px 20px;
          font-size: 18px;
          line-height: 18px;
          margin-right: 30px;
        }

        & > a:last-child {
          color: #ffffff;
          font-size: 18px;
        }

        @media (max-width: 768px) {
          flex-flow: column;
          align-items: center;
          margin-top: 25px;

          & > a {
            margin: 15px auto 0 !important;
          }
        }
      }
    }
  }

  & > div:last-child {
    margin: 50px auto;

    p {
      font-size: 14px;
      text-align: center;
      color: #ffffff;
    }

    img {
      width: 150px;
      margin-top: 10px;
    }
  }
`;
