import React from 'react';

import Layout from '../layout';
import { Link } from 'react-router-dom';

import { ContainerStyled } from '../../styles/layout.style';
import { SectionGradient, BreadCrumsStyle } from '../../styles/section.style';

import { PromoLandingStyle } from '../../styles/promo.style';

import ShortInfo from '../home/shortInfo';

const Promo = () => {
    
    return ( 
        <>
            <Layout >
                <SectionGradient className="full-section" bgColor="#8DC63F" bgGradient="linear-gradient(142.08deg, #8DC63F 25.44%, #006838 112.52%)">
                    <BreadCrumsStyle>
                        <div className='extended-breadcrumb cash-bonus-green'>
                            <ContainerStyled>
                                    <div className='breadcrum'>
                                        <p>
                                            <Link to='/' id='preholiday_home_link'>
                                            Home
                                            </Link>{' '}
                                            • <span class="active green">Cash Bonus Promo</span>
                                        </p>
                                    </div>
                            </ContainerStyled> 
                        </div>
                    </BreadCrumsStyle>
                    <PromoLandingStyle >
                        <ContainerStyled>
                                <div className='promo-img-container'>
                                    <img
                                    className='promo-img'
                                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/cash-bonus-background-img.svg'
                                    alt='Cash Bonus'
                                    />
                                </div>
                                <div className='promo-headline'>
                                    <h1 className='promo-headline-title'>
                                        Enjoy cash bonus of up to ₱10,000 sa bawat sangla mo sa PawnHero!
                                    </h1>
                                    <div className='promo-headline-description'>
                                        <h5>
                                            Get up to ₱10,000 cash bonus to be added to your loan proceeds every time you pawn with us from 15 September to 31 December 2022. This is open to new and existing PawnHero Pawners, online man o via walk-in! Terms and conditions apply.
                                        </h5>
                                    </div>
                                </div>
                                <div className='promo-computation'>
                                    <div className='promo-computation-title'>
                                        <h3>Sample Computation</h3>
                                    </div>
                                    <div className='promo-computation-box-container'>
                                        <img
                                        className='promo-img-comparison'
                                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/cash-bonus-comparison.svg'
                                        alt='Cash Bonus Comparison'
                                        />
                                    </div>  
                                    <div className='promo-computation-disclaimer'>
                                        <p>*Subject to appraisal</p>
                                    </div>
                                </div>
                                <div className='promo-terms'>
                                    <p className='promo-terms-head left'>
                                    Charges applied for designer bags, luxury watches, gadgets and electronics:
                                    </p>
                                    <div className='promo-terms-item'>
                                        <ul className='list-decimal'>
                                            <li>Interest of 4.99% or ₱ 500.00 whichever is higher</li>
                                            <li>Service charge of 1% of principal loan but shall not exceed ₱ 5.00</li>
                                            <li>Documentary stamp tax of ₱ 40.00 for loan amount up to ₱ 5,000.00, additional ₱ 20.00 on each ₱ 5,000.00 or fractional part in excess of ₱ 5,000.00</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <a 
                                    className="button orange"
                                    href='/pawn' 
                                    target='_blank' 
                                    rel='noopener noreferrer'>
                                        Pawn now
                                    </a>
                                </div>
                                <div className='promo-terms'>
                                    <p className='promo-terms-head center bottom'>
                                    Terms and Conditions:
                                    </p>
                                    <div className='promo-terms-item'>
                                        <ul className='list-decimal'>
                                            <li className='italic'>For the customer to be eligible for the promo, he/she must create a pawn ticket within the promo duration 15 September to 31 December 2022.</li>
                                            <li className='italic'>This promo is valid for both new and existing PawnHero Pawnshop pawners.</li>
                                            <li className='italic'>This promo is available online via website, app or via walk-in at PawnHero Pawnshop’s physical pawnshop located at 7th Floor, Avecshares Center, 38th Street, Bonifacio Global City, Taguig City.</li>
                                            <li className='italic'>This promo is applicable when pawning luxury bags, watches, gadgets and electronics with individual loan value amount of at least PHP17,000.00 and up to a maximum of PHP500,000 per pawn ticket. Pawner cannot accumulate his/her multiple pawn tickets in order to reach the minimum loan value required for the promo.</li>
                                            <li className='italic'>Qualified PawnHero Pawnshop pawners with successful loan release from September 15 to December 31 2022 can enjoy additional 2% cash bonus of their pawn ticket’s total loan value, up to a maximum of PHP10,000 cash bonus, to be added to their loan proceeds.</li>
                                            <li className='italic'>This promo is automatically applied to eligible pawn tickets. No promo code needed.</li>
                                            <li className='italic'>Promo is not applicable to pawn items such as gold and jewelry.</li>
                                            <li className='italic'>Promo is non-transferable, non-cumulative and cannot be exchanged for other products or services.</li>
                                            <li className='italic'>The promo can be applied only once per specific pawn item. In case the pawner redeems and repawns the same exact item which previously qualified for the promo, then, the pawner is not anymore entitled to the promo to be applied to the same exact item.</li>
                                            <li className='italic'>Pawn ticket approved for loan release by PawnHero Pawnshop with applied promotion is final. Once cancelled by the customer, his/her item will no longer be eligible for the promo should the pawner decide to pawn the same exact item in the future.</li>
                                            <li className='italic'>PawnHero reserves the right to disqualify a previously approved transaction that was cancelled or charged back within the promo period. Should the pawner receive the cash bonus but was later on deemed unqualified for not meeting the requirements of the promo due to disputes arising from incorrect, invalid, fraudulent or unauthorized transactions, the cost of the incentive shall be charged by PawnHero to the pawner’s account and can be resolved during loan renewal or redemption.</li>
                                            <li className='italic'>DTI Fair Trade Permit No. FTEB-150097 Series of 2022</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='promo-terms'>
                                    <p className='promo-terms-head center bottom'>
                                    Items we accept as collateral:
                                    </p>
                                    <div className='promo-terms-item'>  
                                        <p className='collateral-items-item'>
                                            Designer Bags • Luxury Watches • Smartphones • Laptops
                                        </p>
                                        <p className='collateral-items-item'>
                                            Gaming Consoles • Cameras • Tablets • Televisions • Soundbars and Speakers
                                        </p>
                                    </div>
                                </div>
                                <div className='promo-terms full-width'>
                                    <p className='promo-terms-head center bottom large '>
                                    How to pawn online (website and app)
                                    </p>
                                    <div className='promo-terms-item'>
                                        <ul className='list-decimal'>
                                            <li className='bottom'>
                                                Create your pawn ticket through our website or mobile app. Click the “Pawn now” button to start. <br></br><br></br>
                                                Reminder: Choose what best fits your item’s description for a more accurate system-generated initial appraised value offer. Final offer depends on your item’s condition, inclusions and more upon physical inspection.
                                            </li>
                                            <li className='bottom'>Once you’ve created your pawn ticket, your item will be in queue for next-day pick-up. Please keep your lines open on your scheduled pick-up date as our in-house courier may contact you for confirmation. Your item will be brought to our physical pawnshop for final appraisal.</li>
                                            <li className='bottom'>You will receive an SMS/email notification of our final loan offer on the same day your item is picked up. Once you accept our final loan offer, your loan will be released right away.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='promo-terms full-width'>
                                    <div className='promo-terms-item top-space'>
                                        <p className='left'>
                                            Do you prefer the traditional way of pawning? You may visit our physical pawnshop at the 7th Floor, Avecshares Center, 38th St., Bonifacio Global City, Taguig City. We're open Monday to Saturday, 9:00AM to 7:00PM.
                                        </p>
                                    </div>
                                </div>
                                <div className='promo-terms full-width'>
                                    <div className='promo-terms-item top-space'>
                                        <p className='left'>
                                            Learn more about pawning <Link to='/pawning' className='learn-more-btn-pawning'> here</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="promo-details-container space-container">
                                    <div className='bsp-pioneer right'>
                                        <img alt="BSP" src="https://d1v5w8bodpeh4i.cloudfront.net/assets/bsp-white-label.svg" ></img>
                                    </div>
                                    <div className='bsp-pioneer left'>
                                        <img alt="PIONEER" src="https://d1v5w8bodpeh4i.cloudfront.net/assets/pioneer-white-label.svg" ></img>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <a 
                                    className="button orange"
                                    href='/pawn' 
                                    target='_blank' 
                                    rel='noopener noreferrer'>
                                        Pawn now
                                    </a>
                                </div>
                        </ContainerStyled>
                    </PromoLandingStyle>
                </SectionGradient>
                <ShortInfo />
            </Layout> 
        </>
    );
};

export default Promo; 