import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import SelectField from '../shared/select';
import DateBookingPickerCTM from '../shared/dateBookingPicker';
import DatePickerCTM from '../shared/datePicker';

// styles
import { BookingStyled } from '../../styles/acceptance.style';

//utility
import { isEmpty } from '@ramda/isempty/isEmpty';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateLoan } from '../../../actions/loan';
import {
  fetchProvinces,
  fetchCities,
  fetchBarangays,
} from '../../../actions/location';
import { fetchTime } from '../../../actions/time';
import moment from 'moment';

const Booking = ({
  doUpdateLoan,
  doFetchProvinces,
  doFetchCities,
  doFetchBarangays,
  doFetchTime,
  loan,
  user = false,
  animationDoneHandler,
  address,
}) => {
  const { slideLeft = false } = loan;
  const { booking } = loan.data;

  // states
  const [mode, modehandler] = useState(
    booking && booking.mode ? booking.mode : 'pickup'
  );

  const [isBulky, isBulkyHandler] = useState(false);
  const [currentDate, currentDateHandler] = useState(new Date());
  const [selectedDate, selectedDateHandler] = useState(
    booking && booking.date ? moment(booking.date).toDate() : null
  );
  const [addressOption, addressOptionHandler] = useState([]);
  const [_selectedAddress, _selectedAddressHandler] = useState({});
  const [selectedAddress, selectedAddressHandler] = useState(
    booking && booking.selectedAddress && !isEmpty(booking.selectedAddress)
      ? booking.selectedAddress
      : {}
  );
  const [provinceOptions, provinceOptionsHandler] = useState([]);
  const [cityOptions, cityOptionsHandler] = useState([]);
  const [barangayOptions, barangayOptionsHandler] = useState([]);
  const [valid, validHandler] = useState(false);

  // method
  const prev = () => {
    doUpdateLoan({
      position: 0,
      slideLeft: true,
    });
  };

  const next = (mode, selectedAddress, date) => {
    
    const _booking = {
      selectedAddress,
      mode,
      date: moment(date).format('YYYY-MM-DD 00:00:00'),
    };

    doUpdateLoan({
      position: 2,
      slideLeft: false,
      data: { ...loan.data, booking: _booking },
    });
  };

  // onload
  useEffect(() => {
    // reset redux data content
    const { data } = loan;
    doUpdateLoan({ data: { offer: data.offer } });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(user) && user) {
      // user is login
      const addressOptions = [ 
        {
          value: 'new_address',
          label: 'Enter new address',
        },
        ...address
          .filter((e) => e.type === 'P')
          .map((e) => ({
            value: e.id,
            label: [
              e.address,
              e.barangay_name,
              e.city_name,
              e.province_name,
            ].join(','),
          })),
      ];
      addressOptionHandler(addressOptions);
      // selectedAddressHandler(address[0]);
    }
    // user is not active
    // load option values
    doFetchProvinces().then((data) => {
      const { provinces } = data;

      const _options = provinces.map((province) => {
        return {
          ...province,
          label: province.name,
          value: province.province_id,
        };
      });

      provinceOptionsHandler(_options);
    });

    doFetchCities().then((data) => {
      const { cities } = data;
      const _options = cities.map((city) => {
        return {
          ...city,
          label: city.name,
          value: city.city_id,
        };
      });
      cityOptionsHandler(_options);
    });

    doFetchBarangays().then((data) => {
      const { barangays } = data;
      const _options = barangays.map((barangay) => {
        return {
          ...barangay,
          label: barangay.name,
          value: barangay.barangay_id,
        };
      });

      barangayOptionsHandler(_options);
    });

    //eslint-disable-next-line
  }, []);

  // get server current time
  useEffect(() => {
    doFetchTime().then((time) => {
      const _time = moment(time).toDate();
      currentDateHandler(_time);
    });

    // animation
    animationDoneHandler(false);
    setTimeout(() => {
      animationDoneHandler(true);
    }, 300);
  }, [animationDoneHandler, doFetchTime]);

  useEffect(() => {
    let valid = false;
    
    // eslint-disable-next-line
    doFetchTime().then((time) => {
      const itemDetails = loan.ticketSummary.items[0];
      // const isBulky = itemDetails.isBulky;
      if(itemDetails.isBulky) {
        modehandler('walkin');
        isBulkyHandler(true);
      }
    });

    if (mode === 'pickup') {
      const keys = ['province', 'city', 'barangay', 'street'];

      const test = keys.filter((key) => {
        if (key === 'street') {
          const _test = selectedAddress[key] && selectedAddress[key].length > 3;
          return _test ? false : true;
        } else {
          const _test = selectedAddress[key];
          return _test ? false : true;
        }
      });

      valid = isEmpty(test) && selectedDate ? true : false;
    }

    if (mode === 'walkin') {
      valid = selectedDate ? true : false;
    }
    validHandler(valid);
  }, [selectedAddress, selectedDate, mode, doFetchTime, loan.ticketSummary.items]);

  useEffect(() => {

    if (!isEmpty(user) && user) {
      const _selectedAdd = address.find((e) => e.id === _selectedAddress.value);
      if (_selectedAdd) {
        const {
          province_id,
          province_name,
          city_id,
          city_name,
          barangay_id,
          barangay_name,
          address,
        } = _selectedAdd;

        selectedAddressHandler({
          street: address,
          province: {
            value: province_id,
            label: province_name,
          },
          city: {
            value: city_id,
            label: city_name,
          },
          barangay: {
            value: barangay_id,
            label: barangay_name,
          },
        });
      }
    } 
    // eslint-disable-next-line
  }, [_selectedAddress]);
  

  return (
    <BookingStyled slideLeft={slideLeft}>
      <h2>Schedule your item for booking</h2>

      <div className='booking-item-wrapper'>
        <div className='booking-form-wrapper'>
          <div className='form-header'>
            <div onClick={prev}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='previous'
              />
              <p>Back to Loan Offers</p>
            </div>
          </div>
          <div className='form-body'>
            <div className='pickup-option-wrapper'>
            {!isBulky && (
                <button 
                 onClick={() => {
                   modehandler('pickup');
                   if (mode !== 'pickup') {
                     selectedDateHandler(null);
                   }
                 }}
                 className={`${mode === 'pickup' ? 'selected' : ''}`}>
                 <img
                   src='https://d1v5w8bodpeh4i.cloudfront.net/assets/delivery-ico.svg'
                   alt='pickup'
                 />
                 Pick-up
               </button>
            )}

              <button
                onClick={() => {
                  modehandler('walkin');
                  if (mode !== 'walkin') {
                    selectedDateHandler(null);
                  }
                }}
                className={`${mode === 'walkin' ? 'selected' : ''} ${isBulky === true ? 'bulky' : ''}`}>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/delivery-man-ico.svg'
                  alt='pickup'
                />
                Walk-in
              </button>
            </div>

            {/* form here */}
            {mode === 'pickup' && (
              <>
                <p className='note'>
                  #PawnSafelyAtHome by letting our certified in-house courier
                  (Pawntrooper) pick-up your collateral from your specified
                  address, on your preferred date and time. Safe and convenient!
                </p>
                <div className='fields-groups-wrapper'>
                  <h4>Book your item for pick-up</h4>
                  <div className='fields-wrapper'>
                    {!isEmpty(user) && user && (
                      <div className='field-group'>
                        <div className={`field-label-wrapper`}>
                          <Select
                            className='ctm-select'
                            classNamePrefix={'ctm'}
                            options={addressOption}
                            value={_selectedAddress}
                            placeholder='Your entered pickup address'
                            onChange={(value) => _selectedAddressHandler(value)}
                          />
                        </div>
                      </div>
                    )}
                    <div className={`field-group `}>
                      <div
                        className={`field-label-wrapper ${
                          selectedAddress.street == null ||
                          isEmpty(selectedAddress.street)
                            ? 'no-value'
                            : 'with-value'
                        }`}>
                        <input
                          type='text'
                          name='street'
                          id='pawn_acceptance_booking_address'
                          value={
                            selectedAddress.street ? selectedAddress.street : ''
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            selectedAddressHandler({
                              ...selectedAddress,
                              street: value,
                            });
                          }}
                          disabled={
                            !isEmpty(user) &&
                            _selectedAddress.value !== 'new_address'
                          }
                          autoComplete='false'
                        />
                        <label htmlFor='pawn_acceptance_booking_address'>
                          Street address
                        </label>
                      </div>
                    </div>
                    <div className='multi-field'>
                      <>
                        <div className={`field-group `}>
                          <div
                            className={`field-label-wrapper ${
                              selectedAddress.province == null ||
                              isEmpty(selectedAddress.province)
                                ? 'no-value'
                                : 'with-value'
                            }`}>
                            <SelectField
                              inputId='pawn_acceptance_booking_province'
                              className={`ctm-select ${
                                selectedAddress.province ? 'val' : 'no-val'
                              }`}
                              classNamePrefix={'ctm'}
                              options={provinceOptions}
                              placeholder={''}
                              value={
                                selectedAddress.province
                                  ? selectedAddress.province
                                  : ''
                              }
                              onChange={(value) => {
                                selectedAddressHandler({
                                  ...selectedAddress,
                                  province: value,
                                  city: '',
                                  barangay: '',
                                });
                                selectedDateHandler(null);
                              }}
                              isLoading={isEmpty(provinceOptions)}
                              isDisabled={
                                !isEmpty(user) &&
                                _selectedAddress.value !== 'new_address'
                              }
                            />
                            <label htmlFor='pawn_acceptance_booking_province'>
                              Province
                            </label>
                          </div>
                          {selectedAddress.province &&
                            selectedAddress.province.remarks && (
                              <p className='remarks'>
                                {selectedAddress.province.remarks}
                              </p>
                            )}
                        </div>
                        <div className={`field-group`}>
                          <div
                            className={`field-label-wrapper ${
                              selectedAddress.city == null ||
                              isEmpty(selectedAddress.city)
                                ? 'no-value'
                                : 'with-value'
                            }`}>
                            <SelectField
                              inputId='pawn_acceptance_booking_city'
                              className={`ctm-select ${
                                selectedAddress.city ? 'val' : 'no-val'
                              }`}
                              classNamePrefix={'ctm'}
                              options={cityOptions.filter(
                                (option) =>
                                  option.province_id ===
                                  (!selectedAddress.province
                                    ? false
                                    : selectedAddress.province.value)
                              )}
                              placeholder={''}
                              value={
                                selectedAddress.city ? selectedAddress.city : ''
                              }
                              onChange={(value) => {
                                selectedAddressHandler({
                                  ...selectedAddress,
                                  city: value,
                                  barangay: '',
                                });
                              }}
                              isDisabled={
                                (selectedAddress.province &&
                                !isEmpty(selectedAddress.province)
                                  ? false
                                  : true) ||
                                (!isEmpty(user) &&
                                  _selectedAddress.value !== 'new_address')
                              }
                              isLoading={isEmpty(cityOptions)}
                            />
                            <label htmlFor='pawn_acceptance_booking_city'>
                              City
                            </label>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className='multi-field'>
                      <>
                        <div className={`field-group`}>
                          <div
                            className={`field-label-wrapper ${
                              selectedAddress.barangay == null ||
                              isEmpty(selectedAddress.barangay)
                                ? 'no-value'
                                : 'with-value'
                            }`}>
                            <SelectField
                              inputId='pawn_acceptance_booking_barangay'
                              className={`ctm-select ${
                                selectedAddress.barangay ? 'val' : 'no-val'
                              }`}
                              classNamePrefix={'ctm'}
                              options={barangayOptions.filter(
                                (option) =>
                                  option.city_id ===
                                  (!selectedAddress.city
                                    ? false
                                    : selectedAddress.city.value)
                              )}
                              placeholder={''}
                              value={
                                selectedAddress.barangay
                                  ? selectedAddress.barangay
                                  : ''
                              }
                              onChange={(value) => {
                                selectedAddressHandler({
                                  ...selectedAddress,
                                  barangay: value,
                                });
                              }}
                              isDisabled={
                                (selectedAddress.city &&
                                !isEmpty(selectedAddress.city)
                                  ? false
                                  : true) ||
                                (!isEmpty(user) &&
                                  _selectedAddress.value !== 'new_address')
                              }
                              isLoading={isEmpty(barangayOptions)}
                            />
                            <label htmlFor='pawn_acceptance_booking_barangay'>
                              Barangay
                            </label>
                          </div>
                        </div>
                      </>

                      <div className={`field-group `}>
                        <div
                          className={`field-label-wrapper ${
                            selectedDate == null || isEmpty(selectedDate)
                              ? 'no-value'
                              : 'with-value'
                          }`}>
                          <DateBookingPickerCTM
                            id='pawn_acceptance_booking_pickup_date'
                            selectedDate={selectedDate}
                            onChange={(date) => selectedDateHandler(date)}
                            currentDate={currentDate}
                            minDate={currentDate}
                            placeholder=''
                            disabled={selectedAddress.province ? false : true}
                            conditons={{
                              max:
                                (selectedAddress &&
                                  selectedAddress.province &&
                                  selectedAddress.province.max_days) ||
                                3,
                              cutoff_time:
                                (selectedAddress &&
                                  selectedAddress.province &&
                                  selectedAddress.province.cutoff_time) ||
                                null,
                              available_days:
                                (selectedAddress &&
                                  selectedAddress.province &&
                                  selectedAddress.province.available_days) ||
                                null,
                            }}
                          />
                          <label htmlFor='pawn_acceptance_booking_pickup_date'>
                            Pick-up date
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      className={`submit ${valid ? '' : 'disabled'}`}
                      disabled={valid ? false : true}
                      onClick={() => next(mode, selectedAddress, selectedDate)}>
                      Go to Cash Out
                    </button>
                  </div>
                </div>
              </>
            )}

            {mode === 'walkin' && (
              <>
                <p className='note'>
                  Drop by our office to enjoy cash on-the-spot service! Book
                  your walk-in transactions from{' '}
                  <span>
                    9:00 AM to 7:00 PM (6:30 PM cut-off), Mondays to Saturdays
                  </span>{' '}
                  at PawnHero Pawnshop located at 7/F, Avecshares Center, 
                  Bonifacio Global City, Taguig City. Fast and easy!
                </p>
                <div className='fields-groups-wrapper'>
                  <h4>Schedule your item for walk-in</h4>
                  <div className='fields-wrapper'>
                    <div className={`field-group `}>
                      <div
                        className={`field-label-wrapper ${
                          selectedDate == null || isEmpty(selectedDate)
                            ? 'no-value'
                            : 'with-value'
                        }`}>
                        <DatePickerCTM
                          id='pawn_acceptance_booking_walkin_date'
                          selectedDate={selectedDate}
                          onChange={(date) => selectedDateHandler(date)}
                          minDate={currentDate}
                          maxDate={3}
                          placeholder=''
                          currentDate={currentDate}
                          noTimeLimit={true}
                        />
                        <label htmlFor='pawn_acceptance_booking_walkin_date'>
                          Walk-in date
                        </label>
                      </div>
                    </div>
                    <button
                      className={`submit ${valid ? '' : 'disabled'}`}
                      disabled={valid ? false : true}
                      onClick={() => next(mode, undefined, selectedDate)}>
                      Go to Cash Out
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </BookingStyled>
  );
};

const mapStateToProps = pick(['loan', 'user', 'address']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateLoan: (loan) => dispatch(updateLoan(loan)),
  doFetchProvinces: () => dispatch(fetchProvinces()),
  doFetchCities: () => dispatch(fetchCities()),
  doFetchBarangays: () => dispatch(fetchBarangays()),
  doFetchTime: () => dispatch(fetchTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
