import React, { useEffect, useState } from 'react';

// child component
import Layout from '../layout';

// utility
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { makePaynamicParamsRenewal } from '../../../actions/payment';

// style
import { ContainerStyled } from '../../styles/layout.style';
import { RedeemFailedStyle } from '../../styles/redeemFailed.style';
import capitalize from 'lodash.capitalize';

const RenewalFailed = ({ doMakePaynamicParams }) => {
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const request_id = params.get('requestid');
  const response_id = params.get('responseid');

  // states
  const [isLoading, isLoadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);
  const [customer, customerHandler] = useState({ first_name: 'Guest' });
  const [isError, isErrorHandler] = useState(false);

  useEffect(() => {
    isLoadingHandler(true);
    isErrorHandler(false);

    if (request_id && response_id) {
      const _payload = {
        request_id,
        response_id,
      };

      doMakePaynamicParams(_payload)
        .then(({ data }) => {
          const { data: ticketData } = data;
          ticketDataHandler(ticketData);
          customerHandler(ticketData.user);
          isLoadingHandler(false);
        })
        .catch((err) => {
          isLoadingHandler(false);
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        });
    } else {
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <RedeemFailedStyle className='page' style={{ position: 'relative' }}>
        <ContainerStyled style={{ overflow: 'unset' }}>
          {isError ? (
            <p className='error'>{isError}</p>
          ) : (
            <>
              {isLoading ? (
                <>
                  <div
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      left: '0',
                      right: '0',
                      top: '0',
                      bottom: '0',
                      height: '32px',
                    }}>
                    <img
                      className='loader'
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                      alt='loading'
                      style={{
                        width: '32px',
                        height: '32px',
                        margin: 'auto',
                        display: 'flex',
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <section className='banner'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/transaction-lost.svg'
                      alt=''
                    />

                    <h1>Our apologies, {capitalize(customer.first_name)}.</h1>
                    <p>
                      Your payment for Ticket Number{' '}
                      {ticketData ? ticketData.ticket_no : ''} did not go
                      through.
                    </p>
                  </section>
                  <section className='cta-content'>
                    <p>
                      If you need assistance with this issue, please do not
                      hesitate to contact our Customer Service Support through
                      our PawnHero Customer Hotline at{' '}
                      <a href='tel:8828-2274'>8828-2274</a>, or send us a
                      message here.
                    </p>

                    <p>
                      Our customer service representatives are available from
                      Monday to Friday, at 9:00 AM to 7:00 PM.
                    </p>

                    <Link
                      to={`/pawn/redeem/${
                        ticketData ? ticketData.ticket_no : ''
                      }`}
                      className='btn'>
                      Go back to Redeem / Renew page
                    </Link>
                    <Link to='/contact-us' className='cancel-link'>
                      Contact us here
                    </Link>
                  </section>
                </>
              )}
            </>
          )}
        </ContainerStyled>
      </RedeemFailedStyle>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakePaynamicParams: (payload) =>
    dispatch(makePaynamicParamsRenewal(payload)),
});

export default connect(null, mapDispatchToProps)(RenewalFailed);
