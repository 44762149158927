import React from 'react';
import { Link } from 'react-scroll'

const NavMenu = () => {

    return (
        <>
            <ul>
                <li><Link to="about_pawnhero" spy={true} smooth={true} offset={-160} duration={250}>About PawnHero</Link></li>
                <li><Link to="items_we_accept" spy={true} smooth={true} offset={-160} duration={250} >Items We Accept</Link></li>
                <li><Link to="interest_rates" spy={true} smooth={true} offset={-160} duration={250} >Interest Rates and Other Charges</Link></li>
                <li><Link to="new_loan_term_policy" id="new-loan-term-policy" spy={true} smooth={true} offset={-160} duration={250} >New Loan Term Policy</Link></li>
                <li><Link to="new_renewal_policy" id="newrenewalpolicy" spy={true} smooth={true} offset={-160} duration={250} >New Renewal Policy</Link></li>
                <li><Link to="how_to_pawn" spy={true} smooth={true} offset={-160} duration={250} >How To Pawn</Link></li>
                <li><Link to="feedback" spy={true} smooth={true} offset={-160} duration={250} >Feedback</Link></li>
                <li><Link to="requirements" spy={true} smooth={true} offset={-160} duration={250} >Requirements</Link></li>
                <li><Link to="faqs" spy={true} smooth={true} offset={-160} duration={250} >FAQs</Link></li>
                <li><Link to="terms" spy={true} smooth={true} offset={-160} duration={250} >Terms and Conditions</Link></li>
                <li><Link to="regulatory" spy={true} smooth={true} offset={-160} duration={250} >Regulatory Issuances</Link></li>
            </ul>
        </>
    );

};

export default NavMenu;