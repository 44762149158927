import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTicketPdf } from '../../../actions/ticket';
import ModalLoader from '../../components/loader/modalLoader';
import PdfComponent from '../../components/pdf';

const Pdf = ({ doFetchTicketPdf }) => {
  const { ticketNum } = useParams();

  // states
  const [isLoading, isLoadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);

  const getData = async () => {
    isLoadingHandler(true);

    try {
      const { data, success } = await doFetchTicketPdf({ ticketNum });

      if (success) {
        ticketDataHandler(data);
      } else {
        ticketDataHandler(false);
      }
      isLoadingHandler(false);
    } catch (err) {
      console.log('pdf error', err);
      ticketDataHandler(false);
      isLoadingHandler(false);
    }
  };
  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <ModalLoader />
        </>
      ) : (
        <>
          {ticketData === false ? (
            <>
              <p>error generating pdf</p>
            </>
          ) : (
            <PdfComponent data={ticketData} />
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchTicketPdf: (payload) => dispatch(fetchTicketPdf(payload)),
});
export default connect(null, mapDispatchToProps)(Pdf);
