import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const LayoutStyled = styled.div`
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  
  a.btn,
  button {
    cursor: pointer;
    padding: 7px 20px;
    line-height: 18px;
    font-size: 18px;
  }
  a {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none !important;
  }

  img {
    width: 100%;
    background: transparent;
  }

  main.page {
    padding-top: '0px';
    ${(props) =>
      props.headerHeight
        ? ` padding-top:  ${props.headerHeight}px`
        : ' padding-top:  80px'};

    & > div {
      min-height: ${(props) =>
        `calc( 100vh - ${props.headerHeight}px - ${props.footerHeight}px ) `};
    }
  }

  .d-none {
    display: none;
  }

  .iwa-title {
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }

  .floating-summary {
    top: ${(props) => (props.headerHeight ? `${props.headerHeight}px` : '0')};
    ${(props) => `height: calc( 100% - ${props.headerHeight}px )`};
    /* background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(5px); */
  }

  /* container with min height of window computing the header height */
  .window-height {
    ${(props) =>
      props.headerHeight && props.footerHeight
        ? `min-height : calc( 100vh - ${props.headerHeight}px - ${props.footerHeight}px);`
        : ''}
  }

  .mobile-wrapper-pawn-now {
    background: pink;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #2167a0;
    padding: 15px;
    display: none;
    justify-content: center;

    @media (max-width: 768px) {
      display: flex;
    }

    a {
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      width: 205px;
      color: #ffffff;
      background: #ff7019;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) x;
      border-radius: 25px;
      display: block;
    }
  }

  .error {
    color: #e2574c;
    font-style: italic;
  }

  .breadcrum {
    margin-bottom: 20px;

    p {
      font-weight: normal;
      font-size: 14px;
      color: #fff;

      a {
        color: #fff;
      }

      span {
        color: #68c552;
      }
    }
  }
`;

export const ContainerStyled = styled.div`
  ${(props) => (props.isFlexPage ? '' : `max-width: 1170px;`)}
  width: 100%;
  overflow-x: hidden;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 992px) {
    ${(props) => (props.isFlexPage ? '' : `max-width: 970px;`)}
  }

  @media (max-width: 460px) {
    padding: 0 10px;
  }
`;

export const LinkStyled = styled(Link)`
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none !important;

  &:hover {
    color: #ff7019;
  }
`;

export const SlideInKeyframes = keyframes`
  0%,
  25% {
    transform: translateZ(-500px) translateX(200%);
  }
  75% {
    transform: translateZ(-500px);
  }
  100% {
    transform: translateZ(0) translateX(0);
  }
`;
export const SlideInLeftKeyframes = keyframes`
  0%,
  25% {
    transform: translateX(-200%) translateZ(-500px);
  }
  75% {
    transform: translateZ(-500px);
  }
  100% {
    transform: translateX(0); 
  }
`;

export const SlideOutKeyframes = keyframes`
  0% {
  }
  25% {
    transform: translateZ(-500px);
  }
  75% {
    transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    transform: translateZ(-500px) translateX(-200%);
  }
`;

export const FadeInKeyframes = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const SlideUpKeyframes = keyframes`
  0%{
    z-index: 1;
    top: 100%;
  }
  100%{
    top: 0;
  }
`;

export const Marque = keyframes`
  0% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(-100%);
  }
`;

export const MarqueSmall = keyframes`
  0% {
    transform: translateX(3%);
  }

  100% {
    transform: translateX(-100%);
  }
`;
