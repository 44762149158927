import React, { useEffect, useState } from 'react';

//utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';

// child
import { menus } from '../../components/dashboard/layout';
import FormAddress from '../../components/dashboard/formAddress';
import { Link, useParams } from 'react-router-dom';
import ElipseLoader from '../../components/shared/elipseLoader';

// styles
const {
  ContentStyled,
  AddressPageStyle,
  DashboardNavHeader,
} = require('../../styles/dashboard.style');

const AddressFormPage = (props) => {
  const { dashboard, address, mode } = props;
  const { activeMenu } = dashboard;

  const { id } = useParams();

  const [_selectedAddress, _selectedAddressHandler] = useState(null);
  const [isLoading, isLoadingHandler] = useState(true);
  useEffect(() => {
    let cancel = false;

    if (!cancel && address) {
      const _address =
        mode !== 'new'
          ? address.find((e) => `${e.id}` === `${id}`)
          : { type: 'P' };
      _selectedAddressHandler(_address);
      isLoadingHandler(false);
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, [address]);

  return (
    <ContentStyled>
      {isLoading || !_selectedAddress ? (
        <ElipseLoader />
      ) : (
        <>
          <DashboardNavHeader>
            <Link to='/dashboard/address'>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/header-nav-back-ico.svg'
                alt='back'
              />
            </Link>
            <div>
              <img
                src={menus[activeMenu] ? menus[activeMenu].image : ''}
                alt=''
              />
              <h1>
                {_selectedAddress && _selectedAddress.type === 'P'
                  ? 'Pick-up Address'
                  : 'Permanent Address'}
              </h1>
            </div>
          </DashboardNavHeader>
          <AddressPageStyle>
            {_selectedAddress && (
              <FormAddress data={_selectedAddress} mode={mode} />
            )}
          </AddressPageStyle>
        </>
      )}
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard', 'user', 'address']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddressFormPage);
