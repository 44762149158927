import styled from 'styled-components';

export const FaqStyled = styled.div`
  section:first-child {
    padding: 30px 0;

    & > div {
      /* container */

      h2, h1 {
        font-weight: 600;
        font-size: 32px;
        text-align: center;
        color: #327fbe;
      }

      h2 + p, h1 + p {
        font-size: 18px;
        text-align: center;
        color: #8c969d;
      }

      div.items-wrapper {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px;
        justify-content: space-between;

        & > div.column-wrapper {
          width: 50%;
          margin: 0 -10px;

          & > div {
            margin: 10px 0;
          }
        }

        @media (max-width: 768px) {
          display: block;

          & > div.column-wrapper {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }

  section:last-child {
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #354f65 0%,
      #253d52 100%
    );
    padding: 30px 0;

    & > div {
      /* container */

      h2 {
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        color: #ffffff;
      }

      div.videos-wrapper {
        display: flex;
        justify-content: space-around;
        flex-flow: wrap;

        div.video-wrapper {
          width: 360px;
          height: 200px;
          background: gray;
          margin-top: 20px;

          @media (max-width: 425px) {
            width: 100%;
          }

          iframe {
            width: 100%;
            height: 100%;
            border: 0;
          }
        }

        @media (max-width: 768px) {
          display: block;

          div.video-wrapper {
            display: block;
            margin: 20px auto 0;
          }
        }
      }

      p {
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        margin-top: 20px;
        span {
          color: #68c552;
        }
      }
    }
  }
`;

export const CollapseStyled = styled.div`
  div.title {
    background: #112434;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    outline: none;

    p {
      color: #ffffff;
      font-weight: 600;
    }

    img {
      width: 8px;
      margin-left: auto;
      transition: 0.3s;
      outline: none;
    }

    img.active {
      display: block;
      transform: rotate(180deg);
    }
    img.inactive {
      display: none;
    }
  }

  div.content {
    padding: 5px 10px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: height 300s;

    p {
      color: #8c969d;
      font-size: 14px;
      span {
        color: #354f65;
        font-weight: 600;
      }
    }
  }

  &.close {
    div.content {
      height: 0;
      padding: 0;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    div.title {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      p {
        color: #354f65;
      }
      img.active {
        display: none;
      }
      img.inactive {
        display: block;
      }
    }
  }
`;
