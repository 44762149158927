import React, { useEffect, useState } from 'react';

//utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';

// child

import { menus } from '../../components/dashboard/layout';
import ElipseLoader from '../../components/shared/elipseLoader';
import { useHistory } from 'react-router';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';

// styles
const {
  ContentStyled,
  AddressPageStyle,
  FormAddressStyled,
} = require('../../styles/dashboard.style');

const AddressPage = (props) => {
  const history = useHistory();
  const { dashboard, address = null } = props;
  const { activeMenu } = dashboard;

  const [isLoading, isLoadingHandler] = useState(true);
  const [pickupAddress, pickupAddressHandler] = useState(null);
  const [customerAddress, customerAddressHandler] = useState(null);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (address && !isEmpty(address)) {
        const _pickupAddress = address.find((e) => e.type === 'P');
        pickupAddressHandler(_pickupAddress);

        const _customerAddress = address.find((e) => e.type === 'C');
        customerAddressHandler(_customerAddress);
      }
      isLoadingHandler(false);
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, [address]);

  return (
    <ContentStyled>
      <div className='content-header'>
        <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
        <h1>{menus[activeMenu] ? menus[activeMenu].text : ''}</h1>
      </div>

      {isLoading || isNil(address) ? (
        <>
          <ElipseLoader />
        </>
      ) : (
        <>
          <AddressPageStyle>
            {pickupAddress ? (
              <>
                <FormAddressStyled>
                  <div className='preview-box'>
                    <div>
                      <p>Pick-up Address</p>
                    </div>
                    <div>
                      <div>
                        <p>{pickupAddress.address}</p>
                        <p>{pickupAddress.barangay_name}</p>
                        <p>{pickupAddress.city_name}</p>
                      </div>
                      <div>
                        <button
                          onClick={() =>
                            history.push(
                              `/dashboard/address/${pickupAddress.id}`
                            )
                          }>
                          Edit address
                        </button>
                      </div>
                    </div>
                  </div>
                </FormAddressStyled>
              </>
            ) : (
              <>
                <FormAddressStyled>
                  <div className='preview-box'>
                    <div>
                      <p>Pick-up Address</p>
                    </div>
                    <div>
                      <div>
                        <p>None yet</p>
                      </div>
                      <div>
                        <button
                          onClick={() =>
                            history.push(`/dashboard/address/new`)
                          }>
                          Edit address
                        </button>
                      </div>
                    </div>
                  </div>
                </FormAddressStyled>
              </>
            )}

            {customerAddress ? (
              <>
                <FormAddressStyled>
                  <div className='preview-box'>
                    <div>
                      <p>Permanent Address</p>
                    </div>
                    <div>
                      <div>
                        <p>{customerAddress.address}</p>
                        <p>{customerAddress.barangay_name}</p>
                        <p>{customerAddress.city_name}</p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </FormAddressStyled>
              </>
            ) : (
              <>
                <FormAddressStyled>
                  <div className='preview-box'>
                    <div>
                      <p>Permanent Address</p>
                    </div>
                    <div>
                      <div>
                        <p>None yet</p>
                      </div>
                      <div>{/* <button>Add address</button> */}</div>
                    </div>
                  </div>
                </FormAddressStyled>
              </>
            )}
          </AddressPageStyle>
        </>
      )}
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard', 'user', 'address']);

export default connect(mapStateToProps, {})(AddressPage);
