import React from 'react';
import SharedCarousel from '../../components/shared/owlCarousel';

const AppPreviewCarousel = () => {
  
  const options = {
    loop: false,
    nav: true,
    navText: [
        "<img height='72px' src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow.svg' alt='prev' />",
        "<img height='72px' src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow.svg' alt='next' />" 
    ],
    dots: true,
    dotsEach: true,
    startPosition: 0, //index
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
        stagePadding: 50
      },
      320: {
        items: 1,
        stagePadding: 50
      },
      410: {
        items: 1,
        stagePadding: 70
      },
      480 : {
        items: 1,
        stagePadding: 100
      },
      560 : { 
        items: 2,
        stagePadding: 10
      },
      640 : { 
        items: 2,
        stagePadding: 40
      },
      720 : {
        items: 2,
        stagePadding: 50
      },
      820 : {
        items: 3,
        stagePadding: 10
      },
      900: {
        items: 3,
        stagePadding: 40
      },
      1020: {
        items: 3,
        stagePadding: 50
      },
      1100: {
        items: 4,
        stagePadding: 10
      },
      1200: {
        items: 4,
        stagePadding: 40
      },
      1300: {
        items: 4,
        stagePadding: 50
      },
      1400: {
        items: 5,
        stagePadding: 10
      },
      1500: {
        items: 5,
        stagePadding: 40
      },
    },
  };
  
  return (
    <>
        <div className="carousel-container">
            <h2> App Preview </h2>
            
            <div>
                <SharedCarousel options={options}>
                <div className="item-1" key={1}>
                  <div className='img-wrapper'>
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-preview-1.jpg' alt='' />
                  </div>
                </div>
                <div className="item-2" key={2}>
                  <div className='img-wrapper'>
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-preview-2.jpg' alt='' />
                  </div>
                </div>
                <div className="item-3" key={3}>
                  <div className='img-wrapper'>
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-preview-3.jpg' alt='' />
                  </div>
                </div>
                <div className="item-4" key={4}>
                  <div className='img-wrapper'>
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-preview-4.jpg' alt='' />
                  </div>
                </div>
                <div className="item-5" key={5}>
                  <div className='img-wrapper'>
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-preview-5.jpg' alt='' />
                  </div>
                </div>
                <div className="item-6" key={6}>
                  <div className='img-wrapper'>
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-preview-6.jpg' alt='' />
                  </div>
                </div>
                <div className="item-7" key={7}>
                  <div className='img-wrapper'>
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-preview-7.jpg' alt='' />
                  </div>
                </div>
                </SharedCarousel>
            </div>
            
        </div>
    </>
  );

};

export default AppPreviewCarousel;
