import React, { useState } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { makeLoginGuest } from '../../../actions/auth';
import Layout from '../layout';
// import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { useHistory } from 'react-router-dom';

// styles
const { GuestLoginStyled } = require('../../styles/guestLogin.style');
const { ContainerStyled } = require('../../styles/layout.style');

const GuestLogin = (props) => {
  const { doMakeLogin } = props;

  const history = useHistory();
  const [email, emailHandler] = useState('');
  const [ticket, ticketHandler] = useState('');
  const [err, errHandler] = useState(null);

  const formSubmit = () => {
    if (ticket && email) {
      const _payload = {
        ticket,
        email,
      };

      doMakeLogin(_payload)
        .then((data) => {
          history.push(`/guest/ticket/${ticket}`);
        })
        .catch((err) => {
          errHandler(
            'Please enter your valid pawn ticket and/or email address'
          );
        });
    } else {
      errHandler('Please fill-up ticket and email fields');
    }
  };

  return (
    <Layout>
      <GuestLoginStyled className='page'>
        <ContainerStyled>
          <h1>Manage your pawn ticket now</h1>
          <p>Access your pawn ticket by providing the details below</p>
          <div className='form-wrapper'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formSubmit();
              }}>
              <div className='form-row'>
                <div className='field-wrapper'>
                  <div className={`field ${ticket ? 'with-value' : ''}`}>
                    <input
                      type='text'
                      id='ticket'
                      value={ticket}
                      onChange={(e) => ticketHandler(e.target.value)}
                    />
                    <label htmlFor='ticket'>Pawn ticket number</label>
                  </div>
                </div>
              </div>
              <div className='form-row'>
                <div className='field-wrapper'>
                  <div className={`field ${email ? 'with-value' : ''}`}>
                    <input
                      type='text'
                      id='email'
                      value={email}
                      onChange={(e) => emailHandler(e.target.value)}
                    />
                    <label htmlFor='email'>Email address</label>
                  </div>
                </div>
              </div>
              <div className='action-wrapper'>
                <div>
                  {err ? (
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '10px',
                        textAlign: 'center',
                      }}>
                      {err}
                    </p>
                  ) : (
                    ''
                  )}
                  <button
                    type='submit'
                    disabled={isEmpty(email) || isEmpty(ticket)}>
                    View now
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/* <Link to={'/login'}>Log in to your PawnHero account</Link> */}
        </ContainerStyled>
      </GuestLoginStyled>
    </Layout>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doMakeLogin: (user) => dispatch(makeLoginGuest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestLogin);
