import {
  UPDATE_SOCKET,
  UPDATE_SOCKET_CHAT,
  UPDATE_SOCKET_CHAT_ADD_COUNT,
  UPDATE_SOCKET_CHAT_RESET_COUNT,
  UPDATE_SOCKET_CHAT_ROOM,
} from '../actions/socket';
import update from 'immutability-helper';

const socket = (state = { chats: [], count: 0 }, action) => {
  switch (action.type) {
    case UPDATE_SOCKET:
      return update(state, { $merge: action.socket });
    case UPDATE_SOCKET_CHAT_ROOM: {
      const { chats } = state;
      const { messages = [], init = false } = action.socket;
      const _chats = init ? [...messages] : [...messages, ...chats];
      return update(state, { $merge: { chats: _chats } });
    }
    case UPDATE_SOCKET_CHAT:
      return update(state, { chats: { $push: [action.socket.chats] } });
    case UPDATE_SOCKET_CHAT_ADD_COUNT: {
      const _count = state.count + 1;
      return update(state, { $merge: { count: _count } });
    }
    case UPDATE_SOCKET_CHAT_RESET_COUNT:
      return update(state, { $merge: { count: 0 } });
    default:
      return state;
  }
};

export default socket;
