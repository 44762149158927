import { getIwaData } from '../utils/iwa';

export const UPDATE_IWA = 'UPDATE_IWA';
export const updateIwa = (iwa) => {
  return {
    type: UPDATE_IWA,
    iwa,
  };
};

export const fetchIwaData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getIwaData(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
