import styled from 'styled-components';

export const ItemsWeAcceptStyled = styled.div`
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 30px 0;

  h1 {
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }

  h1 + p {
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-top: 10px;
  }

  section {
    & > div {
      max-width: 810px;
      padding: 0 30px;
      margin: auto;
    }
  }
  section.banner {
    padding: 30px 0 15px;

    img {
      width: 100%;
      max-width: 625px;
      margin: auto;
      display: block;
    }

    h1 {
      margin-top: 20px;
    }
  }
  
  .search-wrapper {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    @media (max-width: 768px) {
      display: block;
    }

    .search-bar {
      display: flex;
      background: #ffffff;
      margin-right: 10px;
      width: 100%;
      max-width: 375px;
      align-items: center;
      border-radius: 5px;
      padding: 5px 10px;
      position: relative;

      @media (max-width: 768px) {
        margin: 0;
        max-width: unset;
      }

      img {
        width: 20px;
        height: 20px;
        margin: auto 5px;
      }

      input {
        border: 0;
        outline: none;
        width: 100%;
        font-size: 14px;
        color: #354f65;

        &::placeholder {
          color: #dadada;
        }
      }

      .option-wrapper {
        display: none;
        position: absolute;
        width: 100%;
        margin: auto -10px;
        top: 90%;
        background: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 1px solid #dadada;

        .option {
          padding: 5px 10px;
          color: #354f65;
        }

        & > .option:not(:last-child) {
          border-bottom: 1px solid #dadada;
        }
      }

      .option-wrapper.close {
        display: none;
      }

      .option-wrapper.open {
        display: block;
      }
    }

    button {
      outline: none;
      border: 0;
      font-weight: normal;
      font-size: 18px;
      line-height: 18px;
      color: #ffffff;
      background: #ff7019;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      padding: 7px 20px;

      @media (max-width: 768px) {
        margin: 10px auto 10px;
        display: block;
      }
    }
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 0 -15px;

    @media (max-width: 768px) {
      justify-content: space-between;
      margin: 0;
      padding: 0 15px;
    }
    .card {
      width: 21%;
      margin: 7.5px 15px 15px;
      padding: 0;

      @media (max-width: 768px) {
        width: 50%;
        margin: 7.5px -15px 15px;
      }

      .image-wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        width: 100%;
        height: 165px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        font-size: 14px;
        text-align: center;
        font-weight: normal;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
`;

export const ItemsDetailedStyled = styled.div`
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding-bottom: 30px;

  .breadcrum {
    padding: 20px 0;
    margin-bottom: 0;
  }

  .banner {
    height: fit-content;
    max-height: 360px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      display: block;
    }

    @media (max-width: 768px) {
      img {
        object-fit: cover;
        object-position: center;
        height: 200px;
      }
    }
  }

  .page-content {
    margin-top: 20px;
    display: flex;

    @media (max-width: 768px) {
      display: block;
    }

    .page-content-left {
      width: 60%;

      @media (max-width: 768px) {
        width: 100%;
      }

      .info-wrapper {
        h1 {
          color: #fff;
          font-weight: 600;
          font-size: 24px;
        }

        p,
        & > div {
          text-align: justify;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          margin-top: 10px;
        }

        ul {
          list-style-position: inside;
        }

        a {
          text-decoration: underline !important;
          color: #fff;
        }
      }
      .sub-info-wrapper {
        padding: 20px 0;

        h2 {
          color: #fff;
          font-weight: 600;
          font-size: 24px;
        }

        .tags-wrapper {
          margin: 0 -10px;
          display: flex;
          flex-wrap: wrap;

          a {
            background: #327fbe;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 5px 10px;
            font-size: 14px;
            color: #fff;
            font-weight: 400;
            margin: 10px 10px 0;
          }
        }
      }

      .cta-wrapper {
        margin-top: 30px;

        h2 {
          color: #fff;
          font-weight: 600;
          font-size: 24px;
        }

        p {
          text-align: justify;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          margin-top: 10px;
        }

        a {
          font-size: 18px;
          text-align: center;
          color: #ffffff;
          font-weight: 400;
          background: #ff7019;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          display: block;
          width: 100%;
          margin-top: 20px;

          @media (max-width: 768px) {
            margin: 20px auto 0;
          }
        }

        .iwa-unlike-other {
          display: flex;
          margin: 20px -10px 0;

          & > div {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 10px;

            & > p {
              text-align: center;
              &:first-child {
                color: #fff;
                font-weight: bold;
                font-size: 16px;
              }

              &:last-child {
                margin-top: 10px;
                color: #fff;
                font-size: 14px;
              }
            }
          }

          @media (max-width: 768px) {
            display: block;

            & > div {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .page-content-right {
    }
  }
`;
