import { postOtp, postOtpVerify, getIsEnableOtp } from '../utils/otp';

export const makeOtp = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postOtp(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeVerifyOtp = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postOtpVerify(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const isEnableOtp = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getIsEnableOtp(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
