import { INIT_OFFER, UPDATE_OFFER } from '../actions/offer';
import update from 'immutability-helper';

const offer = (state = {}, action) => {
  switch (action.type) {
    case INIT_OFFER:
      return action.offer;
    case UPDATE_OFFER:
      return update(state, { $merge: action.offer });
    default:
      return state;
  }
};

export default offer;
