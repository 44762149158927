import React, { Fragment } from 'react';
import { WhyPawnModalStyled } from '../../styles/home.style';
import { Link } from 'react-router-dom';

const WhyPawnModal = (props) => {
  const { whyPawnData, toggle, showKey, close } = props;

  const ItemGenerator = () => {
    return (
      <>
        {whyPawnData.map((item, index) => {
          return (
            <Fragment key={index}>
              <div className={index === showKey ? 'show' : 'hide'}>
                <div>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/close-mark-white-ico.svg'
                    alt='close'
                    className='btn-close'
                    onClick={() => close()}
                  />
                  <img src={item.img} alt={item.title} />
                  <p>{item.text}</p>
                  <Link
                    to='/pawn'
                    id={`home_whyPawn_modal_pawn_link_${item.title.toLowerCase()}`}>
                    Pawn now
                  </Link>
                  <button
                    onClick={() => close()}
                    id={`home_whyPawn_modal_close_btn_${item.title.toLowerCase()}`}>
                    Close
                  </button>
                </div>
              </div>
            </Fragment>
          );
        })}
      </>
    );
  };
  return (
    <WhyPawnModalStyled toggle={toggle} showKey={showKey}>
      {ItemGenerator()}
    </WhyPawnModalStyled>
  );
};

export default WhyPawnModal;
