import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { HashLink } from 'react-router-hash-link';

export const FAQ_data = [
  {
    title: 'How do I apply for a PawnHero loan?',
    content: () => (
      <Fragment>
        <p>
          1. Go to <Link to='/pawn'>wwww.pawnhero.ph/pawn</Link> or simply click
          here to pawn your valued item and create your pawn ticket.
          <br />
          <br />
          2. Fill out the required details of your online pawn ticket then book
          your item pick-up schedule. Our certified in-house courier
          (Pawntrooper) will be at your specified address to pick up your
          collateral. Enjoy loan approval within 1 DAY only after your
          collateral has been picked up and appraised!
          <br />
          <br />
          3. Once you’ve accepted our final loan offer, you may opt to cash out
          via bank transfer or any InstaPay and PESONet partner banks and
          electronic money issuers. Fast and secure!
          <br />
        </p>
      </Fragment>
    ),
  },
  {
    title: 'What are your interest rates and other charges?',
    content: () => (
      <Fragment>
        <p>
          PawnHero Pawnshop offers flexible loan terms and interest packages
          depending on the item category used as collateral for a loan.
          <br />
          <br />
          Charges applied for gold and jewelry:
          <br />
          <br />
          1. Interest of 3.5% for gold and jewelry or ₱ 500.00 whichever is
          higher
          <br />
          2. Service charge of 1% of principal loan but shall not exceed ₱ 5.00
          <br />
          3. Documentary stamp tax of ₱ 40.00 for loan amount up to ₱ 5,000.00,
          additional ₱ 20.00 on each ₱ 5,000.00 or fractional part in excess of
          ₱ 5,000.00
          <br />
          <br />
          Charges applied for designer bags, luxury watches, gadgets and other
          item categories:
          <br />
          <br />
          1. Interest of 4.99% or ₱ 500.00 whichever is higher
          <br />
          2. Service charge of 1% of principal loan but shall not exceed ₱ 5.00
          <br />
          3. Documentary stamp tax of ₱ 40.00 for loan amount up to ₱ 5,000.00,
          additional ₱ 20.00 on each ₱ 5,000.00 or fractional part in excess of
          ₱ 5,000.00
          <br />
          <br />
          Please see sample computation{' '}
          <a
            href='https://d1v5w8bodpeh4i.cloudfront.net/assets/sample-computation.pdf'
            target='_blank'
            rel='noopener noreferrer'
            className='underline'>
            here
          </a>
        </p>
      </Fragment>
    ),
    contentFaq: () => (
      <Fragment>
        <p>
          PawnHero Pawnshop offers flexible loan terms and interest packages
          depending on the item category used as collateral for a loan.
          <br />
          <br />
          Charges applied for gold and jewelry:
          <br />
          <br />
          1. Interest of 3.5% for gold and jewelry or ₱ 500.00 whichever is
          higher
          <br />
          2. Service charge of 1% of principal loan but shall not exceed ₱ 5.00
          <br />
          3. Documentary stamp tax of ₱ 40.00 for loan amount up to ₱ 5,000.00,
          additional ₱ 20.00 on each ₱ 5,000.00 or fractional part in excess of
          ₱ 5,000.00
          <br />
          <br />
          Charges applied for designer bags, luxury watches, gadgets and other
          item categories:
          <br />
          <br />
          1. Interest of 4.99% or ₱ 500.00 whichever is higher
          <br />
          2. Service charge of 1% of principal loan but shall not exceed ₱ 5.00
          <br />
          3. Documentary stamp tax of ₱ 40.00 for loan amount up to ₱ 5,000.00,
          additional ₱ 20.00 on each ₱ 5,000.00 or fractional part in excess of
          ₱ 5,000.00
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/computation-gold-and-jewelry.svg'
            alt='Sample Computation: Gold and Jewelry'
            style={{
              marginTop: '15px',
              width: '100%',
            }}
          />
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/computation-bags-watches-gadgets-smartphones-others.svg'
            alt='Sample Computation: Bags, Watches, Gadgets, Smartphones, and Others'
            style={{
              marginTop: '30px',
              width: '100%',
            }}
          />
        </p>
      </Fragment>
    ),
  },
  {
    title: 'What items can I pawn?',
    content: () => (
      <Fragment>
        <p>
          PawnHero accepts a wide range of items as collateral. For a full list
          of what we accept, visit the{' '}
          <Link to='/items-we-accept' id='home_faq_items_we_accept_link'>
            Items We Accept
          </Link>{' '}
          Page.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'How will I get my money?',
    content: () => (
      <Fragment>
        <p>
          Once you have accepted our final loan offer, you will receive your
          money in a few hours through online bank transfer via InstaPay.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'How will you get my item?',
    content: () => (
      <Fragment>
        <p>
          Once you have selected your item pick-up schedule after creating your
          pawn ticket online, our certified in-house courier (Pawntrooper) will
          pick up your collateral from your specified address, on your preferred
          date and time. Safe and convenient!
        </p>
      </Fragment>
    ),
  },
  {
    title: 'Are my items safe and insured?',
    content: () => (
      <Fragment>
        <p>
          All PawnHero customers enjoy <span>free insurance coverage</span>{' '}
          provided by our partner Pioneer Insurance Corporation. All items
          entrusted to us by you are fully covered by insurance. The PawnHero
          warehouse is 100% safe and secure with 24/7 protection of our security
          team and advanced security protocols such as CCTVs and emergency alarm
          systems.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'What are the requirements to redeem my item?',
    content: () => (
      <Fragment>
        <p>
          Make sure to <span>fully settle your loan balance</span> and don't
          forget to bring with you the <span>original copy </span>
          of your PawnHero ticket and two (2) valid IDs when redeeming your
          item/s. These are strictly required for us to be able to smoothly
          process your loan redemption.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'How do I redeem my loan?',
    content: () => (
      <Fragment>
        <p>
          Redeem your loan on or before the maturity date to avoid
          inconvenience. You may pay via these payment methods:
          <br />
          <br />
          <span>Over-the-Counter</span>
          <br />
          <span>Cash Payment</span>
          <br />
          <span>Online Bank Payment</span>
          <br />
          <span>7-Eleven (7-CONNECT Service)</span>
          <br />
          <span>Coins.ph</span>
          <br />
          <br />
          <span className='note'>
            * Transaction and service fees may apply.
          </span>
        </p>
      </Fragment>
    ),
  },
  {
    title: 'How do I renew my loan?',
    content: () => (
      <Fragment>
        <p>
          Not ready to redeem your loan? You can opt for a loan renewal! All you
          have to do is pay your interest amount due in full amount to extend
          your loan for another month. Payment methods for loan renewal are same
          with payment methods for Loan Redemption.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'Can I extend my loan?',
    content: () => (
      <Fragment>
        <p>
          Yes! If you are not ready to redeem your item yet and pay the full
          loan amount, you can request for a loan renewal. Just login to your
          PawnHero account to apply for an extension of your loan or call one of
          our customer care specialists to help you.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'What is the difference between Loan Estimate and Loan Appraisal?',
    content: () => (
      <Fragment>
        <p>
          Loan Estimate - A rough calculation and/or guess of the value of the
          item you want to pawn. The <strong>final loan appraisal/value</strong>{' '}
          can only be determined upon personal and thorough inspection of the
          item.
          <br />
          <br />
          Loan Appraisal - This is the loan offer you’re entitled to get when
          you pawn a certain item with PawnHero.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'Does PawnHero allow loan renewal?',
    content: () => (
      <Fragment>
        <p>With the exception of electronic gadgets, pawners are allowed to apply for loan renewal but are subject to approval.
          <br />
          <br />
          Moreover, effective Feb. 15, 2022, PawnHero Pawnshop Philippines, Inc. shall allow a limit of up to three (3) renewals for all 
          new loans secured by personal property other than electronic gadgets. Rest assured that renewed loans under 
          this three-renewal limit shall comply with the applicable laws and regulations. PPPI shall issue a new pawn 
          ticket indicating the new term of the loan agreed upon by PPPI and pawner relating to the renewal.
          <br />
          <br />
          This three-renewal limit policy shall not apply to existing loans made prior to Feb. 15, 2022. 
          <br />
          <br />
          Loans made prior to Feb. 15, 2022 under the electronic gadgets category shall remain unavailable for loan renewal applications.
          <br />
          <br />
          For the purpose of counting the number of renewals, 1 month loan renewal is equal to 1 loan renewal application.
          <br />
          <br />
          For further details, you may refer to the policy on this link: Click <HashLink to='/pawning#newrenewalpolicy'>
          here
          </HashLink>
        </p>
      </Fragment>
    ),
  },
  {
    title: 'What loan terms do you offer?',
    content: () => (
      <Fragment>
        <p>Effective March 15 2022, PawnHero Pawnshop Philippines, Inc. shall allow pawners up to 3 months loan duration subject to approval. 
          Rest assured that our new policy on loan terms shall comply with the applicable laws and regulations.
          <br />
          <br />
          This three-month maximum loan duration limit policy shall not apply to existing loans made prior to March 15 2022.
          <br />
          <br />
          Maximum of 1-month loan term still applies for electronic gadgets.
          <br />
          <br />
          For further details, you may refer to the policy on this link: Click <HashLink to='/pawning#new-loan-term-policy'>
          here
          </HashLink>
        </p>
      </Fragment>
    ),
  },
];