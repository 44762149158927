import styled from 'styled-components';

export const PressStyled = styled.div`
  section {
    padding: 30px 0;
  }

  & > section:nth-child(odd) {
    background: #fff;
  }
  & > section:nth-child(even) {
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #354f65 0%,
      #253d52 100%
    );
  }

  h1 {
    color: #327fbe;
    font-weight: 600;
    text-align: center;
    font-size: 32px;
  }

  h2 {
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 32px;
  }

  .card-wrapper {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px;
    width: 49%;
    margin: 5px 0;

    @media (max-width: 768px) {
      display: block;
      width: 100%;
    }

    h3 {
      color: #354f65;
      font-weight: 600;
      font-size: 18px;
    }

    p {
      margin-top: 5px;
      color: #8c969d;
      font-size: 14px;
      font-weight: normal;

      a {
        font-weight: normal;
        font-size: 14px;
        color: #327fbe;
        text-decoration: underline !important;
      }
    }
  }

  .btn {
    background: #68c552;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    font-size: 18px;
    line-height: 18px;
    display: table;
    padding: 7px 15px;
    margin: 15px auto 0;
    color: #ffffff;
    border: 0;
    outline: none;
  }
`;
