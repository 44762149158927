import update from 'immutability-helper';
import { UPDATE_IWA } from '../actions/iwa';

const iwa = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_IWA:
      return update(state, { $merge: action.iwa });
    default:
      return state;
  }
};

export default iwa;
