import { isEmpty } from '@ramda/isempty/isEmpty';
import moment from 'moment';
import { pick } from 'ramda.pick/pick';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { fetchConvoMessages } from '../../../actions/chat';
import {
  updateSocketChatResetCount,
  updateSocketChatRoom,
} from '../../../actions/socket';
import { handleSuccess } from '../../../utils/globals';
import { removeEmpty } from '../../../utils/helper';
import uploadFileService from '../../../utils/uploadFileService';
import { ChatboxStyle } from '../../styles/dashboard.style';
import ElipseLoader from '../shared/elipseLoader';

const ChatBox = ({
  socketRef,
  user,
  socket,
  doFetchConvoMessages,
  doUpdateSocketChatRoom,
  doUpdateSocketChatResetCount,
}) => {
  const {
    user: { user_id, name },
  } = user;
  const { chats = [] } = socket;

  const emailRegpattern =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  const _emailRegpattern =
    /(http(s))[//(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

  const [isLoading, isLoadingHandler] = useState(true);
  const [message, messageHandler] = useState('');
  const [file, fileHandler] = useState('');
  const [willScrolltoBot, willScrolltoBotHandler] = useState(true);
  const [isSending, isSendingHandler] = useState(false);
  const [isGettingPrevMsg, isGettingPrevMsgHandler] = useState(false);
  const comboBoxRef = useRef();
  const fileInputRef = useRef();
  const formRef = useRef();

  const uploadFiled = (file) => {
    return new Promise(async (resolve, reject) => {
      uploadFileService
        .chatUpload(file, (progressEvent) => {
          console.log('progressEvent', progressEvent);
        })
        .then((response) => {
          resolve(handleSuccess(response));
        })
        .catch((error) => {
          console.log('error', error);
          resolve({ success: false, error });
        });
    });
  };

  const sendMessage = async () => {
    isSendingHandler(true);
    let content = {
      message: message,
      sender: { user_id, name },
      file: '',
      thumbnail: '',
    };

    if (message || file) {
      if (file) {
        const {
          success = false,
          data: attachement = { image: '', thumbnail: '' },
        } = await uploadFiled(file);

        if (success) {
          content = {
            ...content,
            file: attachement.image,
            thumbnail: attachement.thumbnail,
          };
        }
      }

      const context = {
        room: user_id,
        content: content,
      };

      willScrolltoBotHandler(true);
      socketRef.current.emit('private_message', context);

      setTimeout(() => {
        console.log('fire reset here');
        messageHandler('');
        fileHandler('');
        isSendingHandler(false);
      }, 500);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (comboBoxRef.current) {
        if (willScrolltoBot) {
          const lastChild =
            comboBoxRef.current.children[
              comboBoxRef.current.children.length - 1
            ];

          if (lastChild) {
            lastChild.scrollIntoView({
              behavior: 'smooth',
            });
          }

          doUpdateSocketChatResetCount();
          socketRef.current.emit('read_message', {
            user_id,
          });
        } else {
          const firstChild = comboBoxRef.current.children[0];

          if (firstChild) {
            firstChild.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }
      }
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, [chats]);

  const getRoomMessage = (last_msg_id = '') => {
    return new Promise((resolve) => {
      const payload = removeEmpty({ last_msg_id });
      doFetchConvoMessages({ user: user_id, payload })
        .then((response) => {
          const { data = [], success } = response;
          if (success) {
            resolve(data.reverse().map((e) => ({ ...e, message: e.content })));
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log('error', error);
          resolve([]);
        });
    });
  };

  const getPrevRoomMessage = () => {
    isGettingPrevMsgHandler(true);
    getRoomMessage(chats[0].message_id).then((messages) => {
      doUpdateSocketChatRoom({ messages, init: false });
      isGettingPrevMsgHandler(false);
    });
  };

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      willScrolltoBotHandler(true);
      getRoomMessage().then((messages) => {
        if (!cancel) {
          doUpdateSocketChatRoom({ messages, init: true });
          isLoadingHandler(false);
        }
      });
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ChatboxStyle>
        <div className='convo-box' ref={comboBoxRef}>
          <div className='load-more-wrapper'>
            {isGettingPrevMsg ? (
              <ElipseLoader />
            ) : (
              <button
                onClick={() => {
                  willScrolltoBotHandler(false);
                  getPrevRoomMessage();
                }}
                disabled={isGettingPrevMsg}>
                Load more
              </button>
            )}
          </div>
          {isLoading && (
            <>
              <ElipseLoader />
            </>
          )}
          {chats.map((e, i) => {
            const _isSender = e.sender_id === user_id; //client

            const withEmailMessage = emailRegpattern.test(e.message);
            const withHttps = _emailRegpattern.test(e.message);

            const _message = withEmailMessage
              ? e.message.replace(
                  emailRegpattern,
                  withHttps
                    ? '<a href="$&" target="_blank">$&</a>'
                    : '<a href="https://$&" target="_blank">$&</a>'
                )
              : e.message;

            return (
              <Fragment key={i}>
                <div className={`${_isSender ? 'sender' : 'receiver'} bubles`}>
                  <div className='chat-context'>
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: _message,
                        }}
                      />
                    </>
                    {e.file && (
                      <>
                        <img src={e.thumbnail || e.file} alt='file priveiew' />
                      </>
                    )}
                  </div>
                  <div className='chat-detail'>
                    <p>
                      {_isSender ? (
                        <>
                          {moment(e.inserted_at).format(
                            'hh:mm:ss A | DD MMM YYYY'
                          )}
                        </>
                      ) : (
                        <>
                          {moment(e.inserted_at).format(
                            'hh:mm:ss A | DD MMM YYYY'
                          )}
                          - Pawnhero
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>

        <div className='form-wrapper'>
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              sendMessage();
            }}>
            <div className='attachment-wrapper'>
              <label htmlFor='file'>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/attachment-ico.svg'
                  alt='attachement'
                />
              </label>
              <input
                type='file'
                id='file'
                ref={fileInputRef}
                onChange={(e) => {
                  const _file = e.target.files[0];
                  console.log('_file', _file);
                  fileHandler(_file);
                }}
              />
            </div>
            <div className='input-wrapper'>
              {file && !isEmpty(file) && (
                <>
                  <div className='preview-container'>
                    <button onClick={() => fileHandler('')}>Remove</button>
                    <img src={URL.createObjectURL(file)} alt='preview' />
                  </div>
                </>
              )}
              <div className='input-box'>
                <textarea
                  value={message}
                  onChange={(e) => messageHandler(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                  disabled={isSending}
                />
                <button type='submit'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/send-message-ico.svg'
                    alt='send'
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      </ChatboxStyle>
    </>
  );
};

const mapStateToProps = pick(['user', 'socket']);
const mapDispatchToProps = (dispatch) => ({
  doFetchConvoMessages: (payload) => dispatch(fetchConvoMessages(payload)),
  doUpdateSocketChatRoom: (socket) => dispatch(updateSocketChatRoom(socket)),
  doUpdateSocketChatResetCount: () => dispatch(updateSocketChatResetCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
