import React from 'react';

// components
import Layout from '../layout';
import WhyPawnhero from '../../components/whyPawnhero';

// style
import { ContainerStyled } from '../../styles/layout.style';

import { Link } from 'react-router-dom';
import { PolicyStyled } from '../../styles/policy.style';

const PawnHeroMalaysia = () => {
  return (
    <>
      <Layout>
        <PolicyStyled>
          <ContainerStyled>
            <div className='breadcrum'>
              <p>
                <Link to='/'>Home</Link> •{' '}
                <span>PawnHero flies to Malaysia for #Maybankfintech 2015</span>
              </p>
            </div>
            <div className='content-wrapper'>
              <div className='content'>
                <section>
                  <h1 className='no-display'>PawnHero goes to Malaysia</h1>
                  <h2>PawnHero flies to Malaysia for #Maybankfintech 2015</h2>

                  <p>
                    PawnHero will be joining 19 other regional startups at the
                    Alpha Startups pre-accelerator idea validation programme in
                    partnership with Maybank and 1337 Ventures. Running from
                    June 13 to 15, the programme will involve startups being
                    guided in validating their ideas to customers and improving
                    their product development and go-to-market strategies.{' '}
                  </p>

                  <p>
                    A final demo day will be held on June 16, where startups
                    will be pitching in front of Maybank executives and venture
                    capitalists. Top startups will be given an opportunity to
                    work closely with the Maybank Fintech team, complete with an
                    onboarding and piloting phase that will take about 3 months.{' '}
                  </p>

                  <p>
                    “To have PawnHero as one of Maybank’s Top 20 startups in
                    Southeast Asia is another recognition of our hard work,"
                    explained David Margendorff, co-founder of PawnHero. "We're
                    very happy for this opportunity."
                  </p>

                  <p>
                    PawnHero has been lauded as one of the fintech startups that
                    are disrupting traditional pawnshop industries in the
                    Philippines. Solving the problem of expensive credit and
                    interest rates, PawnHero provides an easy, fair, and
                    convenient way of overcoming short-term cash needs.
                  </p>
                </section>
              </div>
              <WhyPawnhero />
            </div>
          </ContainerStyled>
        </PolicyStyled>
      </Layout>
    </>
  );
};

export default PawnHeroMalaysia;
