import React from 'react';
import { Link } from 'react-router-dom';
import { HomeServiceAdvisoryStyled } from '../../styles/home.style';
const { ContainerStyled } = require('../../styles/layout.style');

const ServiceAdvisory = (props) => {
  return (
    <>
      <HomeServiceAdvisoryStyled>
        <ContainerStyled>
          <div className='service-adivsory'>
            <h2 className='title'>FIRST TIME TO LEARN ABOUT PAWNHERO?</h2>
            <p className='text-highligts'>
            All the exciting things you need to know when pawning with PawnHero Pawnshop are on one page.
            </p>
            <Link
              to='/pawning'
              id='home_pawning_link'
              className='btn'>
              Learn More
            </Link>
          </div>
          <div>
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/model-combo-uniform.png'
              alt='models'
            />
          </div>
        </ContainerStyled>
      </HomeServiceAdvisoryStyled>
    </>
  );
};

export default ServiceAdvisory;
