import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useRouteMatch } from 'react-router-dom';

const { ContainerStyled } = require('../../styles/layout.style');
const { FooterStyled } = require('../../styles/footer.style');

const Footer = (props) => {
  const { footerRef, showFooter, isBottomFix, marginButtom = false } = props;
  const flexPages = ['/dashboard'];
  const isFlexPage = useRouteMatch(flexPages) ? true : false;

  const _showFooter = showFooter !== undefined ? showFooter : true;

  return (
    <FooterStyled
      ref={footerRef}
      showFooter={_showFooter}
      isBottomFix={isBottomFix}
      marginButtom={marginButtom}>
      <ContainerStyled isFlexPage={isFlexPage}>
        <div className='footer-content'>
          <div className='links-wrapper'>
            <nav>
              <ul>
                <li>
                  <Link to='/pawn' id='footer_pawn_now_link'>
                    Pawn
                  </Link>
                </li>
                <li>
                  <Link
                    to='/how-pawnhero-works'
                    id='footer_how_pawnhero_works_link'>
                    How PawnHero Works
                  </Link>
                </li>
                <li>
                  <Link to='/about-us' id='footer_about_us_link'>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to='/cashout-and-payments'
                    id='footer_cashout_and_payments_link'>
                    Cashout & Payments
                  </Link>
                </li>
                <li>
                  <Link to='/contact-us' id='footer_contact_us_link'>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <a
                    href='https://luxein.com/?utm_source=pawnhero-site&utm_medium=footer&utm_campaign=pawnhero'
                    id='footer_luxein_link'>
                    Luxe In
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to='/items-we-accept' id='footer_items_we_accept'>
                    Items We Accept
                  </Link>
                </li>
                <li>
                  <Link to='/terms-of-use' id='footer_terms_of_use_link'>
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <Link to='/privacy-policy' id='footer_privact_policy_link'>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <a
                    href='https://s3-us-west-2.amazonaws.com/pawnhero.ph/bsp_posters.pdf'
                    id='footer_bsp_poster_link'
                    target='_blank'
                    rel='noopener noreferrer'>
                    BSP Posters
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='social-wrapper'>
            <nav>
              <ul>
                <li>
                  <a
                    href='https://www.facebook.com/PawnHero.ph'
                    id='footer_facebook_link'>
                    <LazyLoadImage
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/facebook.svg'
                      alt='facebook'
                    />{' '}
                  </a>
                </li>
                <li>
                  <a
                    href='https://twitter.com/PawnHeroPH'
                    id='footer_twiiter_link'>
                    <LazyLoadImage
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/twitter.svg'
                      alt='twitter'
                    />{' '}
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/company/pawnhero'
                    id='footer_linkedin_link'>
                    <LazyLoadImage
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/linkedin.svg'
                      alt='linkedin'
                    />{' '}
                  </a>
                </li>
                <li>
                  <a
                    href='https://instagram.com/pawnheroph/'
                    id='footer_instagram_link'>
                    <LazyLoadImage
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/instagram.svg'
                      alt='instagram'
                    />{' '}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='footer-remarks'>
          <LazyLoadImage
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/footer-bsp-ico.svg'
            alt='duly licensed by bangko sentral ng pilipinas'
            className='footer-bsp'
          />

          <p className='copy-remarks'>
            &#x000A9; 2022 PawnHero Pawnshop Philippines, Inc. | Version{' '}
            {process.env.REACT_APP_VERSION} | <a href='/sitemap.xml'>Sitemap</a>
          </p>
          <p>
            PawnHero Pawnshop offers different interest packages depending on
            the item category used as collateral for a loan. Charges applied:
            Interest of 3.5% for gold and jewelry, and 4.99% for other types of
            items or ₱ 500.00 whichever is higher; Service charge of 1% of
            principal loan but shall not exceed ₱ 5.00; Documentary stamp tax of
            ₱ 40.00 for loan amount up to ₱ 5,000.00, additional ₱ 20.00 on each
            ₱ 5,000.00 or fractional part in excess of ₱ 5,000.00. Please see
            sample computation{' '}
            <a
              href='https://d1v5w8bodpeh4i.cloudfront.net/assets/sample-computation.pdf'
              target='_blank'
              rel='noopener noreferrer'
              className='underline'>
              here
            </a>
            .
            <br />
            <br />
            PawnHero Pawnshop Philippines Inc. is regulated by the Bangko
            Sentral ng Pilipinas with contact number (02) 8708-7807 and email
            address consumeraffairs@bsp.gov.ph.
          </p>
        </div>
      </ContainerStyled>
    </FooterStyled>
  );
};

export default Footer;
