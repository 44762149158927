import React from 'react';

//utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';

// child
import { menus } from '../../components/dashboard/layout';
import TicketList from '../../components/dashboard/ticketList';

// styles
import { ContentStyled } from '../../styles/dashboard.style';

const TicketPage = ({ dashboard }) => {
  const { activeMenu } = dashboard;

  return (
    <ContentStyled>
      <div className='content-header'>
        <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
        <h1>{menus[activeMenu] ? menus[activeMenu].text : ''}</h1>
      </div>
      <div className='content-wrapper'>
        <TicketList />
      </div>
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard']);

export default connect(mapStateToProps, {})(TicketPage);
