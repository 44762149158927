import React, { useState, useEffect } from 'react';

// child component
import Layout from '../layout';
import Category from '../../components/categories';
import Brand from '../../components/brand';
import Product from '../../components/product';
import Attribute from '../../components/attribute';
import PawnNavigation from '../../components/pawnNavigation';
import WhyPawnhero from '../../components/whyPawnhero';
import PawnSummary from './pawnSummary';

// utility
import { Route, Switch, useParams } from 'react-router-dom';
import { pick } from 'ramda.pick/pick';
import { connect } from 'react-redux';
import { fetchCategoryDetailBySlug } from '../../../actions/category';
import { updatePawn } from '../../../actions/pawn';
import { fetchBrandDetailBySlug } from '../../../actions/brand';
import { fetchProductDetailBySlug } from '../../../actions/product';

// style
const { ContainerStyled } = require('../../styles/layout.style');
const { PawnStyled } = require('../../styles/pawn.style');

const PawnPage = ({
  pawn,
  doFetchCategoryDetailBySlug,
  doUpdatePawn,
  doFetchBrandDetailBySlug,
  doFetchProductDetailBySlug,
}) => {
  const { position = false, attribute, attrStep } = pawn;
  const { categorySlug, brandSlug, productSlug } = useParams();

  // states
  const [modalIsOn, modalIsOnHandler] = useState(false); //for otp modal

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (categorySlug) {
        doFetchCategoryDetailBySlug({ slug: categorySlug }).then(
          ({ categoryData }) => {
            doUpdatePawn({ categoryData });
          }
        );
      }
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, [categorySlug]);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (brandSlug) {
        doFetchBrandDetailBySlug({ slug: brandSlug }).then((brandData) => {
          doUpdatePawn({ brandData });
        });
      } else {
        doUpdatePawn({ brandData: null });
      }
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, [brandSlug]);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (productSlug) {
        doFetchProductDetailBySlug({ slug: productSlug }).then(
          (productData) => {
            doUpdatePawn({ productData });
          }
        );
      } else {
        doUpdatePawn({ productData: null });
      }
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, [productSlug]);

  return (
    <Layout pageModalActive={modalIsOn}>
      <PawnStyled className='pawn-page-container window-height'>
        <ContainerStyled>
          {position !== false && (
            <>
              {position !== 0 && <PawnNavigation />}
              {position === 0 && (
                <>
                  <div className='page-title-wrapper show'>
                    <h1>Get a free instant appraisal</h1>
                    <p className='pawn-title-sub'>
                      To start, select the item you wish to pawn
                    </p>
                  </div>
                </>
              )}
            </>
          )}

          <div className='pawn-content-wrapper'>
            <div className='pawn-activity-wrapper'>
              {position !== false && (
                <>
                  {position === 1 && <h2>Select the brand</h2>}
                  {position === 2 && <h2>Select the model</h2>}
                  {position === 3 && attribute && (
                    <>
                      {attribute[attrStep] && (
                        <h2>Select the {attribute[attrStep].display_name}</h2>
                      )}
                    </>
                  )}
                  {position === 4 && <h2>One last step</h2>}
                </>
              )}

              <div>
                {/* content left */}
                <>
                  <Switch>
                    <Route
                      exact
                      path='/pawn'
                      render={() => (
                        <>
                          <Category />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/pawn/:categorySlug?'
                      render={() => (
                        <>
                          <Brand />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/pawn/:categorySlug?/:brandSlug?'
                      render={() => (
                        <>
                          <Product />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/pawn/:categorySlug?/:brandSlug?/:productSlug?'
                      render={() => (
                        <>
                          <Attribute modalIsOnHandler={modalIsOnHandler} />
                        </>
                      )}
                    />
                  </Switch>
                </>
              </div>
            </div>
            {/* content right */}
            {position !== false && (
              <>
                {position === 0 && <WhyPawnhero />}
                {position !== 0 && <PawnSummary />}
              </>
            )}
          </div>
        </ContainerStyled>
      </PawnStyled>
    </Layout>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
  doFetchCategoryDetailBySlug: (slug) =>
    dispatch(fetchCategoryDetailBySlug(slug)),
  doFetchBrandDetailBySlug: (slug) => dispatch(fetchBrandDetailBySlug(slug)),
  doFetchProductDetailBySlug: (slug) =>
    dispatch(fetchProductDetailBySlug(slug)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PawnPage);
