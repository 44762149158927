import React from 'react';
import { ModalLoaderStyle } from '../../styles/modalLoader.style';

const ModalLoader = (props) => {
  return (
    <>
      <ModalLoaderStyle>
        <div class='loadingio-spinner-ellipsis-12wcmryihj1e'>
          <div class='ldio-105p52voxmij'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </ModalLoaderStyle>
    </>
  );
};

export default ModalLoader;
