import styled from 'styled-components';

export const DashboardStyled = styled.div`
  position: relative;
  display: flex;
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  min-height: 70vh;

  div.content-wrapper {
    padding: 0 !important;
  }

  div.child-wrapper {
    flex: 1;
    padding: 0 30px;
  }
`;

export const SnippetStyled = styled.div`
  min-width: 320px;
  width: 50%;
  margin: 20px -10px 0;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    min-width: unset;
    margin: 20px 0;
  }

  &.extended {
    width: 100%;
    max-width: 850px;
  }

  div.snippet-header {
    background: #327fbe;
    border-radius: 5px 5px 0px 0px;
    padding: 10px 0;
    display: flex;
    justify-content: center;

    img {
      width: 24px;
      margin-right: 5px;
    }
    p {
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
    }
  }

  div.snipper-loader {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background: #ffffff;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  div.snippet-body {
    background: #ffffff;

    & > div {
      /* container */
      padding: 0;
      padding-left: 15px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        background: #327fbe;

        tr {
          th {
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            font-weight: 600;
            padding: 15px 0;
          }
        }
      }

      tbody {
        tr {
          font-size: 14px;

          & > td:first-child {
            color: #327fbe;
            width: 35%;
          }

          td {
            border-bottom: 1px solid #c7c7cc;
            padding: 5px 0;
            color: #354f65;

            &.action-column {
              button,
              a {
                background: #68c552;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                border: 0;
                text-align: center;
                font-size: 18px;
                color: #ffffff;
                padding: 3px 20px;
                outline: none;
                width: 100px;
                display: block;
                margin: auto;
              }
            }

            &.arrow-list {
              display: flex;
              justify-content: space-between;
              align-items: center;

              img {
                width: 8px;
              }
            }

            &:last-child {
              padding-right: 15px;
            }
          }
        }
      }
    }

    form {
      // background: pink;
      width: 100%;

      div.form-group {
        margin-top: 5px;

        label {
          display: block;
          color: #327fbe;
          font-size: 10px;
        }

        input,
        p {
          margin-top: 3px;
          display: block;
          width: 100%;
          border: 1px solid #dadada;
          border-radius: 5px;
          font-size: 14px;
          padding: 5px;
          color: #8b959c;
        }

        .input-phone {
          .react-phone-number-input__country {
            display: none;
          }
        }
      }
    }
  }

  div.snippet-action {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background: #ffffff;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    a,
    button {
      background: #68c552;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      padding: 5px 20px;
      font-size: 18px;
      color: #ffffff;
      outline: none;
      border: 0;
    }
  }
`;

export const ContentStyled = styled.div`
  padding: 30px 0;

  div.content-header {
    position: relative;
    padding-left: 45px;

    img {
      width: 35px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    h1 {
      font-weight: 600;
      font-size: 24px;
      color: #ffffff;
    }
  }

  div.content-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5px;
    padding: 0 10px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;

export const TicketDetailStyled = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-flow: column;
  }

  div.ticket-details-wrapper {
    width: 100%;
    max-width: 70%;
    padding-right: 15px;

    h1 {
      font-weight: 600;
      font-size: 24px;
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
    }

    .data-wrapper {
      border-radius: 5px;
      overflow-y: hidden;
      overflow-x: hidden;
      background: #ffffff;

      .data-header-wrapper {
        background: #327fbe;
        border-radius: 5px 5px 0px 0px;
        padding: 16px 15px;
        display: flex;
        justify-content: center;

        img {
          max-width: 20px;
          margin-right: 5px;
        }

        p {
          font-size: 18px;
          color: #ffffff;
          font-weight: 600;
        }
      }

      .data-content-wrapper {
        .data-summary,
        .data-loan-detail {
          padding: 15px;

          ul {
            list-style: none;

            li {
              width: 50%;
              display: inline-block;

              p {
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                span {
                  font-weight: 600;
                }
              }

              @media (max-width: 768px) {
                width: 100%;
                display: block;
                padding: 5px 0;
              }
            }
          }
        }

        .data-summary {
          background: #2675aa;
        }
        .data-loan-detail {
          background: #112434;
        }

        .data-timeline {
          background: #fff;

          .timeline-header {
            padding: 15px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            img {
              width: 25px;
              transition: 0.3s;
              outline: none;

              &:last-child {
                width: 15px;
              }

              &.close {
                transform: rotate(180deg);
              }
            }

            p {
              color: #354f65;
              font-size: 18px;
              font-weight: 600;
              margin: auto 5px;
            }
          }

          .timeline-wrapper {
            padding-left: 15px;
            border-bottom: 1px solid #8b959c;
            border-top: 1px solid #8b959c;

            table {
              width: 100%;
              border-collapse: collapse;

              tbody {
                tr {
                  display: flex;

                  &:not(:last-child) {
                    border-bottom: 1px solid #8b959c;
                  }

                  td {
                    padding: 5px 0;
                    width: 100%;
                  }

                  td:first-child {
                    font-size: 14px;
                    color: #354f65;
                    width: 40%;
                  }

                  td:last-child {
                    color: #8b959c;
                    font-size: 14px;
                  }

                  @media (max-width: 768px) {
                    td {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .actions-wrapper {
        display: flex;
        justify-content: center;
        padding: 15px 0;

        .item-redeem {
          background: #ff7019;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          font-size: 18px;
          color: #ffffff;
          margin: auto 20px;
        }

        .item-renew {
          background: #354f65;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          font-size: 18px;
          color: #fff;
          margin: auto 20px;
        }

        .item-acceptance,
        .item-offer,
        .item-pawn {
          background: #ff7019;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          font-size: 18px;
          color: #fff;
          margin: auto 20px;
        }

        .item-guest {
          background: #68c552;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          font-size: 18px;
          color: #fff;
          margin: auto 20px;
        }
        p {
          color: #327fbe;
          font-size: 14px;
        }

        @media (max-width: 500px) {
          display: block;

          & > .item-redeem,
          & > .item-renew,
          & > .item-acceptance,
          & > .item-offer,
          & > .item-pawn,
          & > .item-guest {
            max-width: 260px;
            display: block;
            margin: 10px auto 0;
            text-align: center;
            width: fit-content;
          }
        }
      }

      .data-footer {
        padding: 15px 0;
        display: flex;
        justify-content: center;

        a {
          font-size: 14px;
          color: #327fbe;
          font-weight: 600;
        }
      }
    }

    @media (max-width: 768px) {
      max-width: unset;
      padding-right: 0;
    }
  }

  div.summary-wrapper {
    margin-left: auto;
    background: #112b41;
    width: 100%;
    max-width: 30%;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
    align-self: baseline;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    div.summary-header {
      background: #327fbe;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 15px;

      a {
        display: flex;
        justify-content: center;

        img {
          max-width: 20px;
          margin-right: 5px;
        }

        p {
          font-size: 18px;
          color: #ffffff;
        }
      }
    }

    div.summary-body {
      ul {
        padding: 15px;
        list-style: none;

        li {
          &:not(:first-child) {
            margin-top: 10px;
          }

          display: flex;
          justify-content: left;
          align-items: center;

          span {
            margin-right: 15px;
            background: #fff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #354f65;
            font-size: 18px;
            font-weight: 600;
          }

          p {
            color: #ffffff;
            font-size: 18px;
          }

          &.active {
            p {
              color: #68c552;
            }

            span {
              background: #68c552;
              color: #ffffff;
            }
          }

          &.in-active {
            p {
              color: #354f65;
            }

            span {
              color: #112b41;
              background: #354f65;
            }
          }

          @media (max-width: 768px) {
            & > p,
            & > span {
              font-size: 16px;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      /* display: none; */

      order: -1;
      max-width: unset;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 0;

      &.mobile-hide {
        display: none;
      }

      &.mobile-show {
        display: block;
      }
    }
  }

  .mobile-download-pdf {
    display: none;
    margin-top: 20px;
    @media (max-width: 768px) {
      display: block;
    }

    a {
      display: flex;
      justify-content: center;

      img {
        max-width: 20px;
        margin-right: 5px;
      }

      p {
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
`;

export const FormAddressStyled = styled.div`
  & > div.preview-box {
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 100%;
    flex-direction: column;
    display: flex;

    & > div {
      &:first-child {
        p {
          font-weight: 600;
          font-size: 24px;
          color: #327fbe;
        }
      }

      &:last-child {
        display: flex;
        flex: 1;

        & > div {
          width: 50%;

          &:first-child {
          }

          &:last-child {
            display: flex;

            button {
              border: 0;
              background: #68c552;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 15px;
              font-size: 18px;
              color: #ffffff;
              text-align: center;
              margin-left: auto;
              height: fit-content;
              align-self: flex-end;
            }
          }
        }
      }
    }
  }

  & > div.form-box {
    & > div {
      &:first-child {
        border-radius: 5px 5px 0px 0px;
        background: #327fbe;
        padding: 10px;
        position: relative;

        & > div {
          display: flex;
          width: fit-content;
          cursor: pointer;
          position: absolute;
          left: 10;
          top: 0;
          bottom: 0;
          margin: auto;
          height: fit-content;

          img {
            width: 12px;
            margin-right: 10px;
          }

          p {
            font-size: 18px;
            color: #ffffff;
            text-align: center;
          }
        }

        & > p {
          color: #ffffff;
          text-align: center;
          font-weight: 600;
          font-size: 18px;
        }
      }

      &:last-child {
        border-radius: 0px 0px 5px 5px;
        background: #ffffff;
        padding: 10px;

        .fields-groups-wrapper {
          .fields-wrapper {
            display: block;

            .field-group {
              padding: 14px 0 11px;
              margin-bottom: 5px;
              margin-top: 5px;

              .field-label-wrapper {
                position: relative;

                label {
                  font-size: 14px;
                  font-weight: 400;
                  position: absolute;
                  bottom: 6.5px;
                  transition: 0.3s;
                  left: 10px;
                  color: #8b959c;
                }

                input {
                  width: 100%;
                }

                input:focus + label,
                .phone-input.focus + label,
                .ctm-date-picker.focus + label,
                .shared-calendar.focus + label,
                .ctm-select.focus + label,
                &.with-value label {
                  display: block;
                  bottom: 100%;
                  left: 0;
                  color: #327fbe;
                  font-size: 10px;
                }

                & > input[type='text'],
                & > input[type='password'] {
                  width: 100%;
                  border-radius: 5px;
                  border: 1px solid #dadada;
                  padding: 5px 10px;
                  font-size: 14px;
                  outline: none;
                  color: #354f65;

                  &:disabled {
                    background: #ebebeb;
                    color: #b2b1b1;
                  }

                  &::placeholder {
                    color: #dadada;
                  }
                }

                &.is-error {
                  input {
                    border-color: #e2574c !important;
                  }
                }
              }

              p.err-note {
                display: none;
                position: absolute;
                font-style: italic;
                font-size: 8px;
                line-height: 11px;
                color: #e2574c;
              }

              .field-label-wrapper.is-error + p.err-note {
                display: block;
              }
            }
          }
        }
      }

      button {
        border: 0;
        background: #68c552;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        margin: auto;
        display: block;

        &:disabled {
          background: #68c552;
          color: #67b252;
        }
      }
    }
  }
`;

export const TicketListStyled = styled.div`
  width: 100%;

  div.wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 10px -10px;

    & > div.item {
      width: 50%;
      padding: 10px;

      & > div {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 15px;

        & > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > p:first-child {
            color: #327fbe;
            font-weight: 600;
            font-size: 24px;
          }

          p.tag-status {
            font-size: 12px;
            color: #354f65;
          }
        }

        & > div:last-child {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          & > div {
            &:first-child {
              p {
                color: #354f65;
                font-size: 14px;
              }
            }

            &:last-child {
              a {
                background: #68c552;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                font-size: 18px;
                color: #ffffff;
                border: 0;
                padding: 5px 30px;
                display: block;
              }
            }
          }
        }
      }
    }

    div.load-more-wrapper {
      display: flex;
      justify-content: center;
      flex: 1;
      margin-top: 10px;

      button {
        background: #68c552;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        border: 0;
        outline: none;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
`;

export const AccountFormStyle = styled.div`
  width: 100%;
  max-width: 408px;
  margin-top: 15px;

  & > div.account-data-wrapper {
    width: 100%;

    & > div:first-child {
      background: #327fbe;
      border-radius: 5px 5px 0px 0px;
      padding: 15px 0;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      font-weight: 600;
    }

    & > div:last-child {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 5px 5px;

      div.avatar-wrapper {
        padding: 15px;
        display: flex;
        justify-content: center;

        & > label {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.default {
              transform: scale(1.3);
            }
          }
        }

        & > input {
          display: none;
        }
      }

      div.info-wrapper {
        border-top: 1px #ebebeb solid;
        padding: 0 10px;

        & > div.row-wrapper {
          &:not(:last-child) {
            border-bottom: 1px #ebebeb solid;
          }
          display: flex;
          padding: 10px 0;

          & > div:first-child {
            width: 40%;
            color: #327fbe;
            font-weight: 600;
            font-size: 14px;
          }

          & > div:last-child {
            flex: 1;
            color: #354f65;
            font-size: 14px;
          }
        }

        & > div.row-note-wrapper {
          padding: 10px 20px;
          p {
            font-size: 14px;
            text-align: center;
            color: #354f65;
          }
        }
      }

      .fields-groups-wrapper {
        .fields-wrapper {
          max-width: 360px;
          display: block;
          margin: 30px auto 0;

          .field-group {
            padding: 14px 0 11px;
            margin-bottom: 5px;
            margin-top: 5px;

            .field-label-wrapper {
              position: relative;

              label {
                font-size: 14px;
                font-weight: 400;
                position: absolute;
                bottom: 6.5px;
                transition: 0.3s;
                left: 10px;
                color: #8b959c;
              }

              input {
                width: 100%;
              }

              input:focus + label,
              .phone-input.focus + label,
              .ctm-date-picker.focus + label,
              .shared-calendar.focus + label,
              .ctm-select.focus + label,
              &.with-value label {
                display: block;
                bottom: 100%;
                left: 0;
                color: #327fbe;
                font-size: 10px;
              }

              & > input[type='text'],
              & > input[type='password'] {
                width: 100%;
                border-radius: 5px;
                border: 1px solid #dadada;
                padding: 5px 10px;
                font-size: 14px;
                outline: none;
                color: #354f65;

                &:disabled {
                  background: #ebebeb;
                  color: #b2b1b1;
                }

                &::placeholder {
                  color: #dadada;
                }
              }

              &.is-error {
                input {
                  border-color: #e2574c !important;
                }
              }
            }

            p.err-note {
              display: none;
              position: absolute;
              font-style: italic;
              font-size: 8px;
              line-height: 11px;
              color: #e2574c;
            }

            .field-label-wrapper.is-error + p.err-note {
              display: block;
            }
          }
        }
      }

      div.cta-wrapper {
        padding: 15px 0;
        display: flex;
        justify-content: center;

        button {
          border: 0;
          background: #68c552;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          font-size: 18px;
          color: #ffffff;
          padding: 10px 15px;
          display: block;
          width: 194px;
        }
      }
    }
  }

  & > div.credential-link-wrapper {
    margin-top: 30px;
    padding: 20px 10px;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      width: 40%;
      color: #327fbe;
      font-weight: 600;
      font-size: 18px;
    }

    & > button,
    & > a {
      background: #68c552;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      border: 0;
      font-size: 18px;
      color: #ffffff;
    }
  }
`;

export const AddressPageStyle = styled.div`
  display: flex;
  width: 100%;
  margin: 20px -10px;

  @media (max-width: 768px) {
    display: block;
  }

  & > div {
    width: 50%;
    padding: 0 10px;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const ChatboxStyle = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;

  div.load-more-wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    button {
      border: 0;
      background-color: #327fbe;
      border-radius: 15px;
      color: #fff !important;
      font-size: 18px !important;
      line-height: 18px !important;
      padding: 7px 20px !important;
    }
  }

  div.convo-box {
    padding: 10px;
    height: 400px;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    & > div.bubles {
      display: inline-block;

      margin-bottom: 10px;

      &.sender {
        margin-left: auto;

        .chat-context {
          margin-left: auto;
          background: radial-gradient(
            50% 48.09% at 50% 48.09%,
            #354f65 0%,
            #253d52 100%
          );
          p {
            color: #fff;
            a {
              text-decoration: underline !important;
              color: inherit;
            }
          }
        }

        .chat-detail {
          p {
            text-align: right;
          }
        }
      }

      &.receiver {
        margin-right: auto;
        .chat-context {
          background: #e5e6ea;

          a {
            text-decoration: underline !important;
            color: inherit;
          }
        }

        .chat-detail {
          p {
            text-align: left;
          }
        }
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }

      .chat-context {
        padding: 10px;
        border-radius: 17.5px;
        width: fit-content;
        max-width: 30vw;
        white-space: pre-line;
      }

      .chat-detail {
        p {
          font-weight: 500;
          color: #858e99;
          font-size: 11px;
        }
      }
    }
  }

  .form-wrapper {
    padding: 10px;
    background: #e5e6ea;

    form {
      display: flex;
      align-items: center;

      div.attachment-wrapper {
        input {
          display: none;
        }
      }

      div.input-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-left: 10px;

        & > div {
          display: flex;
        }

        div.preview-container {
          position: relative;
          width: fit-content;

          button {
            position: absolute;
            font-size: 12px;
            border: 0;
            padding: 5px;
            top: 0;
            right: 0;
            z-index: 999;
          }

          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }
        }

        div.input-box {
          border: 1px solid #c8c7cc;
          border-radius: 100px;
          padding: 5px 10px;
          background: #fff;
          height: 35px;

          input[type='text'],
          textarea {
            resize: none;
            width: 100%;
            outline: none;
            border: 0;
            background: #fff;
            font-size: 14px;
            height: 100%;
          }

          button {
            border: 0;
            background: 0;
            width: fit-content;
            padding: 0;

            img {
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
  }
`;

export const TicketSearchNav = styled.div`
  justify-content: space-between;
  background: #354f65;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-top: 10px;

  & a.clear-search {
     padding-left: 25px;
     color: #FFFFFF;
     text-decoration: underline !important;
     cursor: pointer;
  }

  & > div {
    & > p {
      font-weight: 600;
      font-size: 14px;
      color: #fff;
    }

    & > form {
      display: flex;
      align-items: center;

      & > input {
        background: #ffffff;
        border: 1px solid #dadada;
        border-radius: 5px;
        padding: 5px;
        font-size: 14px;
        margin-right: 10px;

        &::placeholder {
          color: #dadada;
        }
      }

      & > button {
        background: #68c552;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 0;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          width: 10px;
        }
      }
    }
  }
`;

export const DashboardNavHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > a {
    display: flex;
    img {
      width: 16px;
    }
  }

  & > div {
    margin-left: 15px;
    display: flex;
    align-items: center;

    & > img {
      width: 24px;
      margin-right: 10px;
    }

    & > h1 {
      font-weight: 600;
      font-size: 24px;
      color: #ffffff;
    }
  }
`;
