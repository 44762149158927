import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// child component
import Layout from '../layout';

// utility
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import pick from 'ramda.pick';
import { fetchSummaryTicket } from '../../../actions/ticket';
import isNil from '@ramda/isnil';

const { ContainerStyled } = require('../../styles/layout.style');
const { PawnThankyouStyled } = require('../../styles/pawn-thankyou.style');

const AcceptanceThankyou = (props) => {
  const { ticketNum } = props.match.params;
  const { doFetchSummaryTicket } = props;

  // state
  const [ticketData, ticketDataHandler] = useState(null);

  const thankyouDateExpiry =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.thankyouDateExpiry
      ? props.location.state.thankyouDateExpiry
      : false;

  const now = moment().tz(timezone).unix();

  const history = useHistory();

  useEffect(() => {
    const _test = thankyouDateExpiry > now;
    if (!_test) {
      // expired
      history.push('/guest');
    } else {
      doFetchSummaryTicket({ ticketNum })
        .then(({ data }) => {
          ticketDataHandler(data);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <PawnThankyouStyled className='page'>
        <section>
          <ContainerStyled>
            <div>
              <h1>
                Congratulations,{' '}
                {!ticketData && isNil(ticketData)
                  ? ' '
                  : ticketData.user.firstName.charAt(0).toUpperCase() +
                    ticketData.user.firstName.slice(1)}
                !
              </h1>
              <p>Your item booking has been confirmed.</p>

              <p>
                Your ticket number is <span>{ticketNum}</span>
              </p>

              <div className='link-wrappers'>
                <Link to='/guest'>View my ticket</Link>
                <Link to='/'>Back to Home</Link>
              </div>
            </div>
            <div>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/model-girl-solo.png'
                alt='model endorser'
              />
            </div>
          </ContainerStyled>
        </section>
        <section>
          <ContainerStyled>
            <div className='steps-wrapper'>
              <div className='step'>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-1.png'
                  alt=''
                />
                <div>
                  <h2>What happens next?</h2>
                  <ul>
                    <li>
                      <img
                        className='step-icon'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-1-1.svg'
                        alt=''
                      />
                      <p>
                        For pick-up transactions:
                        <br />
                        <br />
                        1. Expect a call or SMS from our logistics team on the
                        day of pick-up.
                        <br />
                        <br />
                        2. Prepare your pawn item with its complete declared
                        inclusions before our Pawntrooper arrives at your
                        pick-up place.
                        <br />
                        <br />
                        3. Do not pack your pawn item as our Pawntrooper will
                        spot-check your item before receiving.
                        <br />
                        <br />
                        4. Ensure that you have your two (2) valid government
                        IDs available to be presented to our Pawntrooper upon
                        item pick-up.
                      </p>
                    </li>
                    <li>
                      <img
                        className='step-icon'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-1-2.svg'
                        alt=''
                      />
                      <p>
                        For walk-in transactions:
                        <br />
                        <br />
                        1. PawnHero office is located at{' '}
                        <strong>
                          7/F, Avecshares Center, Bonifacio Global City, Taguig City, and is open from Monday
                          to Saturday, 9:00 AM to 5:00 PM (4pm cut-off for item
                          appraisal).
                        </strong>
                        <br />
                        <br />
                        2. Prepare your pawn item with its complete declared
                        inclusions before arriving at PawnHero office.
                        <br />
                        <br />
                        3. Do not pack your pawn item as our appraisers will
                        check your item before receiving.
                        <br />
                        <br />
                        4. Ensure that you have your two (2) valid government
                        IDs available to be presented to the building concierge
                        and to our customer service representative.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='step'>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-2-new.png'
                  alt=''
                />
                <div>
                  <h2>How and when do I get the money?</h2>
                  <ul>
                    <li>
                      <img
                        className='step-icon'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-1-1.svg'
                        alt=''
                      />
                      <p>
                        For pick-up transactions:
                        <br />
                        <br />
                        Our Pawntroppers are expected to be back in our office
                        on or before 4:00 PM. They will turn the item over to
                        our operations team for appraisal and checking
                        (authenticity/aesthetic).
                        <br />
                        <br />
                        Once the item passed the inspection, we shall then
                        release the loan. If cashout method selected is bank
                        deposit with InstaPay option, Coins.ph, GCash or
                        Paymaya, the{' '}
                        <strong>
                          loan will be released instantly upon approval
                        </strong>
                        . If bank deposit with PESONet option has been selected,
                        <strong>
                          the loan will be released next banking day on or
                          before 8:00 PM
                        </strong>
                        .
                      </p>
                    </li>
                    <li>
                      <img
                        className='step-icon'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-1-2.svg'
                        alt=''
                      />
                      <p>
                        For walk-in transactions:
                        <br />
                        <br />
                        Upon completion of our mandatory forms, our customer
                        service representative will accept your item and turn it
                        over to our operations team for appraisal and checking
                        (authenticity/aesthetic).
                        <br />
                        <br />
                        Once the item passed the inspection, we shall then
                        release the loan.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='step'>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-3.png'
                  alt=''
                />
                <div>
                  <h2>How do I prepare my item for pick-up?</h2>
                  <ul>
                    <li>
                      <img
                        className='step-icon'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou-step-3-1.svg'
                        alt=''
                      />
                      <p>
                        Before either our Pawntrooper arrives at your place or
                        going to PawnHero office depending on your selected item
                        booking, please prepare your pawn item with its complete
                        declared inclusions.
                        <br />
                        <br />
                        Lastly, do not pack your pawn item as either our
                        Pawntrooper or one of our operations team will check
                        your item before receiving.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ContainerStyled>
        </section>
      </PawnThankyouStyled>
    </Layout>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceThankyou);
