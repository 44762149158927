import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getMyNotification = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/notification', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getMyUnreadCountNoti = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/notification/unread', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getNotificationById = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/notification/id/${payload}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
