import styled from 'styled-components';
const { FadeInKeyframes } = require('./layout.style');

export const PawnStyled = styled.div`
  /* animation: ${FadeInKeyframes} 500ms both ease; */
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 30px 0;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 768px) {
    padding: 20px 0;
  }

  div.page-title-wrapper {
    display: none;

    &.show {
      display: block;
    }

    p.pawn-title-sub {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
    }

    h1 {
      color: #ffffff;
      font-weight: 600;
      font-size: 32px;
      text-align: center;
    }
  }

  .pawn-content-wrapper {
    display: flex;
    margin-top: 30px;
    border-radius: 5px 5px 0 0;

    .pawn-activity-wrapper {
      overflow: hidden;
      width: 66%;
      max-width: 750px;
      min-height: 500px;

      h2 {
        font-weight: 600;
        font-size: 24px;
        color: #ffffff;
        text-align: center;
        margin-bottom: 10px;
      }

      & > div {
        border-radius: 5px;
      }

      div {
        .pawn-activity-content {
          border-radius: 5px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          overflow-x: hidden;
          overflow-y: hidden;

          &.loading {
            background: #ffffff;
          }

          .pawn-activity-header {
            background: #327fbe;
            padding: 16px 10px;
            border-radius: 5px 5px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.category {
              padding: 13.5px 10px;
            }
            .pawn-activity-header-nav,
            .ctm-link-attr-wrapper {
              position: relative;
              display: inline-block;
              cursor: pointer;

              img {
                width: 12px;
                height: 20.5px;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }

            .pawn-activity-header-nav > p,
            .ctm-link-attr-wrapper > p {
              font-weight: 400;
              font-size: 18px;
              color: #ffffff;
              border-radius: 5px 5px 0 0;
              padding-left: 25px;
              white-space: nowrap;
            }

            .search-filter-wrapper {
              display: flex;
              align-items: center;
              width: 50%;
              justify-content: flex-end;

              & > img {
                width: 20px;
              }

              .search-input-wrapper {
                display: flex;
                align-self: center;
                align-items: center;
                background: #2167a0;
                border-radius: 5px;
                padding: 5px 10px;
                width: 100%;
                margin: -3px 0;

                @media (max-width: 768px) {
                  display: none;
                }

                &.hide {
                  display: none;
                }

                & > img {
                  width: 20px;
                }

                & > input {
                  background: transparent;
                  border: 0;
                  font-size: 14px;
                  outline: none;
                  color: #fff;
                  padding-left: 5px;
                  width: 100%;

                  &::placeholder {
                    color: #5a92c0;
                  }
                }
              }
            }
          }

          .pawn-activity-body {
            background: #ffffff;
            height: 500px;
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;
            border-radius: 0 0 5px 5px;

            &.flat-end {
              border-radius: 0;
            }
            p.no-item {
              padding: 20px;
              text-align: center;
              font-size: 20px;
              font-weight: 600;
              color: #68c552;
            }
          }

          .form-control-wrapper {
            background: #ebebeb;
            padding: 12px 10px;
            display: flex;
            justify-content: flex-end;

            &.disabled {
              p {
                cursor: not-allowed;
                pointer-events: none;
                color: #67b252;
              }
            }
            p {
              cursor: pointer;
              background: #68c552;
              color: #ffffff;
              font-weight: 400;
              font-size: 18px;
              line-height: 18px;
              text-align: center;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 15px;
              padding: 7px 20px;
            }
          }
        }

        .filter-search-modal-wrapper {
          background: #fff;
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 99;

          @media (max-width: 768px) {
            &.show {
              display: block;
              padding-top: 59px;
            }
          }

          .modal-header-wrapper {
            padding: 10px 15px;
            background: #327fbe;
            display: flex;
            position: fixed;
            width: 100%;
            left: 0;
            top: 0;
            & > img {
              width: 12px;
              margin-right: 25px;
            }

            & > div.search-wrapper {
              display: flex;
              justify-content: flex-end;
              width: 95%;
              position: relative;
              background: #2167a0;
              border-radius: 5px;
              padding: 10px;

              & > img {
                width: 20px;
                height: 20px;
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                margin: auto;
              }
              & > input {
                width: 100%;
                border: 0;
                background: transparent;
                padding-left: 25px;
                outline: 0 !important;
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;

                &::placeholder {
                  color: #5a92c0;
                }
              }
            }
          }

          .modal-body-wrapper {
            padding: 5px 15px;
            overflow-y: auto;
            position: static;
            height: 100%;
            a {
              display: flex;

              div.list-item {
                display: flex;
                width: 100%;

                div.list-icon-wrapper {
                  display: flex;
                  align-items: center;

                  p {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    background: #354f65;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ffffff;
                    font-size: 14px;
                  }
                }

                div.list-label-wrapper {
                  display: flex;
                  border-bottom: 1px solid #c7c7cc;
                  width: 100%;
                  justify-content: space-between;
                  padding: 10px;

                  p {
                    color: #354f65;
                    font-size: 18px;
                  }
                  img {
                    width: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .pawn-content-wrapper {
      flex-wrap: wrap;

      .pawn-activity-wrapper {
        width: 100%;
        max-width: auto;
      }
    }
  }
`;

export const PawnSummaryStyled = styled.div`
  margin-left: auto;
  width: 30%;
  max-width: 360px;
  transition: 0.3s ease-in-out;

  @media (max-width: 768px) {
    max-height: ${(props) => (props.showMobile ? 'unset' : '0')};
    overflow: hidden;
    width: 100%;
    max-width: unset;
    position: fixed;
    left: 0;
  }

  .pawn-summary-wrapper {
    .header {
      background: #327fbe;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 10px;

      @media (max-width: 768px) {
        border-radius: 0px;
      }

      p {
        color: #ffffff;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        text-align: center;
      }
    }
    .content {
      background: #112b41;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 5px 5px;

      nav {
        ul {
          list-style: none;
          padding: 10px;

          li {
            padding: 5px;
            align-items: center;
            display: flex;
            pointer-events: none;

            a {
              display: flex;
              overflow-x: hidden;
              overflow-y: hidden;
              align-items: center;
            }

            p.order-marker {
              background: #17466c;
              color: #112b41;
              width: 32px;
              height: 32px;
              min-width: 32px;
              min-height: 32px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              font-size: 18px;
            }

            div.elipse-dynamic {
              flex: 1;
              min-width: 0;
              p.step-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            p.step-title {
              margin-left: 10px;
              color: #ffffff;
              font-weight: 600;
              font-size: 18px;
              color: #354f65;

              & > span {
                text-decoration: underline;
              }
            }

            img {
              width: 18px;
              margin-left: 5px;
              display: none;
            }

            &.done {
              pointer-events: auto;
              p.order-marker {
                background: #ffffff;
                color: #354f65;
              }

              p.step-title {
                color: #ffffff;
              }

              img {
                display: block;
              }
            }

            &.current {
              pointer-events: auto;
              p.order-marker {
                background: #68c552;
                color: #ffffff;
              }

              p.step-title {
                color: #68c552;
              }
            }
          }
        }
      }
    }
  }
`;
