import React, { useEffect, useState } from 'react';
import { pick } from 'ramda.pick/pick';
import { connect } from 'react-redux';
import { updatePawn } from '../../../actions/pawn';
import { fetchBrandWithCategory } from '../../../actions/brand';
import { Link, useParams } from 'react-router-dom';
import { BrandStyle } from '../../styles/brand.style';
import MyContentLoader from '../contentLoader';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';

const Brand = ({ pawn, doUpdatePawn, doFetchBrandWithCategory }) => {
  const { slideLeft = false } = pawn;
  const { categorySlug } = useParams();

  // state
  const [loading, loadingHandler] = useState(false);
  const [brands, brandsHandler] = useState(null);
  const [filterKey, filterKeyHandler] = useState(null);
  const [filterToggle, filterToggleHandler] = useState(false);
  const [filterBrands, filterBrandsHandler] = useState(null);

  const _displayBrands = filterKey ? filterBrands : brands;

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      doUpdatePawn({ position: 1, selectedEntities: null, formValues: {} });
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    loadingHandler(true);
    const data = await doFetchBrandWithCategory({ slug: categorySlug });
    brandsHandler(data);
    loadingHandler(false);
  };

  useEffect(() => {
    if (filterKey) {
      const _brands = brands.filter((brand) => {
        const _test = new RegExp(filterKey, 'i').test(brand.brand_name);
        return _test ? _test : false;
      });

      filterBrandsHandler(_brands);
    }
  }, [brands, filterKey]);

  return (
    <>
      <div className={`pawn-activity-content ${loading ? 'loading' : 'load'}`}>
        <BrandStyle slideLeft={slideLeft}>
          <div className='pawn-activity-header'>
            <Link
              className='pawn-activity-header-nav'
              to={'/pawn'}
              id={`pawn_${categorySlug}_back_link`}
              onClick={() => {
                doUpdatePawn({ slideLeft: true });
              }}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='go back'
              />
              <p>Back to Categories</p>
            </Link>
            <div className='search-filter-wrapper'>
              {!filterToggle && (
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon.svg'
                  alt=''
                  onClick={() => filterToggleHandler(true)}
                />
              )}

              <div
                className={`search-input-wrapper ${
                  filterToggle ? 'show' : 'hide'
                } `}>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-gray.svg'
                  alt=''
                />

                {filterToggle && (
                  <input
                    type='text'
                    onChange={(e) => filterKeyHandler(e.target.value)}
                    onBlur={() => {
                      filterToggleHandler(filterKey ? true : false);
                    }}
                    placeholder='Try searching directly for brand'
                    autoFocus
                  />
                )}
              </div>
            </div>
          </div>
          <div className='pawn-activity-body'>
            {!isNil(_displayBrands) ? (
              !isEmpty(_displayBrands) ? (
                _displayBrands.map((brand, key) => {
                  return (
                    <Link
                      to={`/pawn/${brand.category_slug}/${brand.brand_slug}`}
                      id={`pawn_${brand.category_slug}_${brand.brand_slug}_link`}
                      key={key}
                      onClick={() => {
                        doUpdatePawn({ slideLeft: false });
                      }}>
                      <div className='brand-item '>
                        <div className='brand-icon-wrapper'>
                          <p>
                            {brand.brand_name
                              .replace(/[^a-zA-Z- ]/g, '')
                              .match(/\b\w/g)
                              .join('')
                              .substring(0, 2)
                              .toUpperCase()}
                          </p>
                        </div>
                        <div className='brand-label-wrapper'>
                          <p>{brand.brand_name}</p>
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-blue-ico.svg'
                            alt='>'
                          />
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-white-ico.svg'
                            alt='>'
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <>
                  <p className='no-item'>Sorry No Product Available</p>
                </>
              )
            ) : (
              <MyContentLoader />
            )}
          </div>
        </BrandStyle>
      </div>

      <div
        className={`filter-search-modal-wrapper ${
          filterToggle ? 'show' : 'hide'
        }`}>
        <div className='modal-header-wrapper'>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
            alt='go back'
            onClick={() => filterToggleHandler(false)}
          />
          <div className='search-wrapper'>
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-blue.svg'
              alt='search-icon'
            />
            {filterToggle && (
              <input
                type='text'
                onChange={(e) => filterKeyHandler(e.target.value)}
                placeholder='Try searching directly for brand'
                autoFocus
              />
            )}
          </div>
        </div>
        <div className='modal-body-wrapper'>
          {!isNil(_displayBrands) ? (
            <>
              {!isEmpty(_displayBrands) ? (
                _displayBrands.map((brand, key) => {
                  return (
                    <Link
                      to={`/pawn/${brand.category_slug}/${brand.brand_slug}`}
                      id={`pawn_${brand.category_slug}_${brand.brand_slug}_filter_link`}
                      key={key}
                      onClick={() => {
                        doUpdatePawn({ slideLeft: false });
                      }}>
                      <div className='list-item'>
                        <div className='list-icon-wrapper'>
                          <p>
                            {brand.brand_name
                              .replace(/[^a-zA-Z- ]/g, '')
                              .match(/\b\w/g)
                              .join('')
                              .substring(0, 2)
                              .toUpperCase()}
                          </p>
                        </div>
                        <div className='list-label-wrapper'>
                          <p>{brand.brand_name}</p>
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-blue-ico.svg'
                            alt='>'
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <>
                  <p className='no-item'>Sorry No Product Available</p>
                </>
              )}
            </>
          ) : (
            <MyContentLoader />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
  doFetchBrandWithCategory: (slug) => dispatch(fetchBrandWithCategory(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Brand);
