import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// utility
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import {
  fetchRedeemDirectData,
  fetchRenewalApproved,
  fetchSummaryTicket,
} from '../../../actions/ticket';

// child component
import Layout from '../layout';
import { matchPath } from 'react-router-dom/cjs/react-router-dom.min';

const { ContainerStyled } = require('../../styles/layout.style');
const { PawnThankyouStyledV2 } = require('../../styles/pawn-thankyou.style');

const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const SbThankyou = (props) => {
  const {
    doFetchSummaryTicket,
    doFetchRenewalApproved,
    doFetchRedeemDirectData,
  } = props;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ticketNum = query.get('ticketno');

  const history = useHistory();
  const { pathname } = useLocation();

  const _isRenewal = matchPath(pathname, {
    path: '/pawn/renewal/*',
    exact: false,
  });

  const thankyouDateExpiry =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.thankyouDateExpiry
      ? props.location.state.thankyouDateExpiry
      : false;

  const now = moment().tz(timezone).unix();

  const [loading, loadingHandler] = useState(true);
  const [renewalData, renewalDataHandler] = useState(null);
  const [user, userHandler] = useState(null);
  const [redeemData, redeemDataHandler] = useState({});
  const [error, errorHandler] = useState(false);

  useEffect(() => {
    const _test = thankyouDateExpiry > now;

    if (!_test) {
      // expired
      history.push('/guest');
    } else {
      loadingHandler(true);
      doFetchSummaryTicket({ ticketNum })
        .then(({ data }) => {
          const { user } = data;
          userHandler(user);

          if (_isRenewal) {
            doFetchRenewalApproved({ ticketNum })
              .then((response) => {
                const { data, success } = response;

                if (success) {
                  renewalDataHandler(data);
                }
                loadingHandler(false);
              })
              .catch((err) => {
                console.log('doFetchRenewalApproved err', err);
                loadingHandler(false);
              });
          } else {
            doFetchRedeemDirectData({ ticketNum })
              .then((response) => {
                const { data, success } = response;

                if (success) {
                  redeemDataHandler(data);
                } else {
                  errorHandler(
                    'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                  );
                }
                loadingHandler(false);
              })
              .catch((err) => {
                console.log('doFetchRedeemDirectData err', err);
                errorHandler(
                  'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                );
                loadingHandler(false);
              });
          }
        })
        .catch((err) => {
          console.log('err', err);
          loadingHandler(false);
        });
    }

    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <PawnThankyouStyledV2>
          {loading ? (
            <>
              <div style={{ padding: '50px 0', width: '100%' }}>
                <img
                  className='loader'
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                  alt='loading'
                  style={{
                    width: '32px',
                    height: '32px',
                    margin: 'auto',
                    display: 'flex',
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {error ? (
                <>
                  <ContainerStyled>
                    <p
                      className='error'
                      style={{ textAlign: 'center', marginTop: '10px' }}>
                      {error}
                    </p>
                  </ContainerStyled>
                </>
              ) : (
                <section className='banner'>
                  <ContainerStyled>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou.svg'
                      alt='thank you'
                    />
                    <h2>Thank you, {user ? user.firstName : ''}!</h2>
                    <h3>We will now wait for your loan repayment.</h3>
                    <p className='highnotes'>
                      Your reference number is{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        {ticketNum}
                      </span>
                      <br />
                      Your amount due is ₱{' '}
                      {formaterNumber(
                        _isRenewal
                          ? renewalData.amount_to_be_paid
                          : redeemData.amount
                      )}
                      , payable until{' '}
                      {moment().add(1, 'd').format('DD MMM YYYY')}
                    </p>

                    <p
                      className='notes'
                      style={{ textAlign: 'justify', marginTop: '30px' }}>
                      <strong>
                        Security Bank Payment (Over-the-Counter) Instructions
                      </strong>
                      <br />
                      <br />
                      1. Proceed at any Security Bank branch and settle loan/s
                      via bills payment
                      <br />
                      <br />
                      2. Fill up payment slip with corresponding details
                      (Merchant Name, Reference Number, Payee, Amount, Payment
                      Mode)
                      <br />
                      <br />
                      3. Once payment is successful, kindly attached a copy of
                      the universal transaction slip at "insert link of confirm
                      payment"
                      <br />
                      <br />
                      You will receive a notification the moment your payment
                      has been validated and we return your item.
                      <br />
                      <br />
                      <strong>
                        Security Bank Payment (Online Payment) Instructions
                      </strong>
                      <br />
                      <br />
                      1. Log in to Security Bank Online.
                      <br />
                      2. Browse the “Payments & Transfers” tab and select “Pay
                      Bills”.
                      <br />
                      3. Client indicates the payment details(Biller, Reference
                      Number, Amount, Source Account and Payment Schedule), and
                      clicks on the Submit button.
                      <br />
                      4. Once payment is successful, kindly attached a copy of
                      the universal transaction slip at "insert link of confirm
                      payment"
                      <br />
                      <br />
                      You will receive a notification the moment your payment
                      has been validated and we return your item.
                    </p>

                    <div className='cta-wrapper'>
                      <Link to='/' className='btn'>
                        Back to Home
                      </Link>
                    </div>
                  </ContainerStyled>
                </section>
              )}
            </>
          )}
        </PawnThankyouStyledV2>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
  doFetchRenewalApproved: (payload) => dispatch(fetchRenewalApproved(payload)),
  doFetchRedeemDirectData: (payload) =>
    dispatch(fetchRedeemDirectData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SbThankyou);
