import styled from 'styled-components';

export const SectionStyled = styled.section`
    background-color: ${props => props.bgColor ? props.bgColor : '#FFFFFF' };    
    background-image: ${props => props.bgImage ? 'url('+props.bgImage+')' : 'none' };
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
    height: auto;
    min-height: 500px;
    padding: 25px 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    &.full-section {
        height: 100%;
        align-items: center;    
        display: flex;
        justify-content: center;
    }   
    &.no-padd {
        padding: 0;
    }

`;  

export const SectionRadialStyled = styled.section`
    background-color: #FFF2D3;
    background: radial-gradient(50% 48.09% at 50% 48.09%, #FFFFFF 0%, #FBEBC6 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: auto;
    min-height: 500px;
    padding: 0 0 25px 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    &.full-section {
        height: 100%;
        align-items: center;    
        display: flex;
        justify-content: center;
    }   
`;

export const SectionDefault = styled.section`
    background-color: ${props => props.bgColor ? props.bgColor : '#FFFFFF'};
    background: ${props => props.bgColor ? props.bgColor : '#FFFFFF'};
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: auto;
    min-height: 500px;
    overflow: hidden;
    position: relative;
    width: 100%;
    &.full-section {
        height: 100%;
        align-items: center;    
        display: flex;
        justify-content: center;
    }   
`;

export const SectionGradient = styled(SectionDefault)`
    background: ${props => props.bgGradient ? props.bgGradient : '#FFFFFF'};
`;

export const BreadCrumsStyle = styled.div`
    & .extended-breadcrumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        &.preholiday-red {
            background: #9C0517;
        }
        &.cash-bonus-green {
            background: #426B22;
        }

        & .breadcrum{
            margin: 0;
            padding: 10px 0;
            & .active.white {
                color: #FFFFFF; 
            }
            & .active.green {
                color: #C3FF95;
            }
        }
    }
`;