import styled from 'styled-components';

export const CheckboxStyled = styled.div`
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #6bbd57;

    .entry-label-wrapper {
      p {
        color: #fff !important;
      }
    }
  }

  input {
    display: none;
  }

  label {
    display: block;
    padding-left: 10px;
    position: relative;
    cursor: pointer !important;

    .entry-icon-wrapper {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      justify-content: center;
      display: flex;
      border: 2px solid #354f65;

      img {
        display: none;
        align-self: center;
        width: 100%;
      }
    }

    .entry-label-wrapper {
      padding: 17px 0;
      border-bottom: 1px solid #c7c7cc;
      margin-left: 40px;

      p {
        font-weight: 400;
        font-size: 18px;
        color: #354f65;
        padding-left: 10px;
      }
    }
  }

  input:checked + label {
    .entry-icon-wrapper {
      background: transparent;
      border: 0;

      img {
        display: block;
      }
    }
  }
`;
