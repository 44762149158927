import React from 'react';

// components
import Layout from '../layout';
import WhyPawnhero from '../../components/whyPawnhero';

// style
import { ContainerStyled } from '../../styles/layout.style';
import { TermsStyled } from '../../styles/terms.style';
import { Link } from 'react-router-dom';

const TermOfUse = () => {
  return (
    <>
      <Layout>
        <TermsStyled>
          <ContainerStyled>
            <div className='breadcrum'>
              <p>
                <Link to='/'>Home</Link> • <span>Terms of Use</span>
              </p>
            </div>
            <div className='content-wrapper'>
              <div className='content'>
                <section>
                  <h1>Terms of Use</h1>
                  <p>
                    Welcome to the PawnHero website. Please read these terms and
                    conditions carefully. The following Terms of Use govern your
                    use and access of the Platform (defined below) and the use
                    of the Services. By accessing the Platform and/or using the
                    Services, you agree to be bound by these Terms of Use. If
                    you do not agree to these Terms of Use, do not access and/or
                    use this Platform or the Services.
                  </p>
                  <p>
                    Access to and use of password protected and/or secure areas
                    of the Platform and/or use of the Services are restricted to
                    Customers with accounts only. You may not obtain or attempt
                    to obtain unauthorised access to such parts of this Platform
                    and/or Services, or to any other protected information,
                    through any means not intentionally made available by us for
                    your specific use. If you are below 18 years old: you must
                    obtain consent from your parent(s) or legal guardian(s),
                    their acceptance of these Terms of Use and their agreement
                    to take responsibility for: (i) your actions; (ii) any
                    charges associated with your use of any of the Services or
                    purchase of Products; and (iii) your acceptance and
                    compliance with these Terms of Use.
                  </p>
                  <p>
                    If you do not have consent from your parent(s) or legal
                    guardian(s), you must stop using/accessing this Platform and
                    using the Services.
                  </p>
                </section>
                <section>
                  <h3>1. Definitions & Interpretation</h3>
                  <p>
                    Unless otherwise defined, the definitions and provisions in
                    respect of interpretation set out in Schedule 1 will apply
                    to these Terms of Use.
                  </p>
                </section>
                <section>
                  <h3>2. General use of Services and/or access of Platform</h3>
                  <p>
                    2.1 Guidelines to the use of Platform and/or Services: You
                    agree to comply with any and all the guidelines, notices,
                    operating rules and policies and instructions pertaining to
                    the use of the Services and/or access to the Platform, as
                    well as any amendments to the aforementioned, issued by us,
                    from time to time. We reserve the right to revise these
                    guidelines, notices, operating rules and policies and
                    instructions at any time and you are deemed to be aware of
                    and bound by any changes to the foregoing upon their
                    publication on the Platform.
                  </p>
                  <p>
                    2.2 Restricted activities: You agree and undertake NOT to:
                  </p>
                  <p>
                    (a) impersonate any person or entity or to falsely state or
                    otherwise misrepresent your affiliation with any person or
                    entity;
                    <br />
                    (b) use the Platform or Services for illegal purposes;{' '}
                    <br />
                    (c) attempt to gain unauthorized access to or otherwise
                    interfere or disrupt other computer systems or networks
                    connected to the Platform or Services; <br />
                    (d) post, promote or transmit through the Platform or
                    Services any Prohibited Materials; <br />
                    (e) interfere with another’s utilization and enjoyment of
                    the Platform or Services; <br />
                    (f) use or upload, in any way, any software or material that
                    contains, or which you have reason to suspect that contains,
                    viruses, damaging components, malicious code or harmful
                    components which may impair or corrupt the Platform’s data
                    or damage or interfere with the operation of another
                    Customer’s computer or mobile device or the Platform or
                    Services; and
                    <br />
                    (g) use the Platform or Services other than in conformance
                    with the acceptable use policies of any connected computer
                    networks, any applicable Internet standards and any other
                    applicable laws.
                  </p>
                  <p>
                    2.3 Availability of Platform and Services: We may, from time
                    to time and without giving any reason or prior notice,
                    upgrade, modify, suspend or discontinue the provision of or
                    remove, whether in whole or in part, the Platform or any
                    Services and shall not be liable if any such upgrade,
                    modification, suspension or removal prevents you from
                    accessing the Platform or any part of the Services.
                  </p>
                  <p>
                    2.4 Right, but not obligation, to monitor content: We
                    reserve the right, but shall not be obliged to:
                  </p>
                  <p>
                    (a) monitor, screen or otherwise control any activity,
                    content or material on the Platform and/or through the
                    Services. We may in our sole and absolute discretion,
                    investigate any violation of the terms and conditions
                    contained herein and may take any action it deems
                    appropriate; (b) prevent or restrict access of any Customer
                    to the Platform and/or the Services; (c) report any activity
                    it suspects to be in violation of any applicable law,
                    statute or regulation to the appropriate authorities and to
                    co-operate with such authorities; and/or (d) to request any
                    information and data from you in connection with your use of
                    the Services and/or access of the Platform at any time and
                    to exercise our right under this paragraph if you refuse to
                    divulge such information and/or data or if you provide or if
                    we have reasonable grounds to suspect that you have provided
                    inaccurate, misleading or fraudulent information and/or
                    data.
                  </p>
                  <p>
                    2.5 Privacy Policy: Your use of the Services and/or access
                    to the Platform is also subject to the Privacy Policy as set
                    out here.
                  </p>
                  <p>
                    2.6 Terms & Conditions of Sale: Purchases of any Product
                    would be subject to the Terms & Conditions of Sale.
                  </p>
                  <p>
                    2.7 Terms & Conditions of Pawn Loan: Pawn Loans would be
                    subject to the Terms & Conditions reflected on the pawn
                    ticket.
                  </p>
                  <p>
                    2.8 Additional terms: In addition to these Terms of Use, the
                    use of specific aspects of the Materials and Services, more
                    comprehensive or updated versions of the Materials offered
                    by us or our designated sub-contractors, may be subject to
                    additional terms and conditions, which will apply in full
                    force and effect.
                  </p>
                </section>
                <section>
                  <h3>3. Use of Services</h3>
                  <p>
                    3.1 Application of this Clause: In addition to all other
                    terms and conditions of these Terms of Use, the provisions
                    in this Clause 3 are the additional specific terms and
                    conditions governing your use of the Services.
                  </p>
                  <p>
                    3.2 Restrictions: Use of the Services is limited to
                    authorised Customers that are of legal age and who have the
                    legal capacity to enter into and form contracts under any
                    applicable law. Customers who have breached or are in breach
                    of the terms and conditions contained herein and Customers
                    who have been permanently or temporarily suspended from use
                    of any of the Services may not use the Services even if they
                    satisfy the requirements of this Clause 3.2.
                  </p>
                  <p>3.3 General terms of use: You agree:</p>
                  <p>
                    (a) to access and/or use the Services only for lawful
                    purposes and in a lawful manner at all times and further
                    agree to conduct any activity relating to the Services in
                    good faith; and <br />
                    (b) to ensure that any information or data you post or cause
                    to appear on the Platform in connection with the Services is
                    accurate and agree to take sole responsibility for such
                    information and data.
                  </p>
                  <p>
                    3.4 Product description: While we endeavour to provide an
                    accurate description of the Products, we do not warrant that
                    such description is accurate, current or free from error.
                  </p>
                  <p>
                    3.5 Prices of Products: All Listing Prices may be subject to
                    taxes, unless otherwise stated. We reserve the right to
                    amend the Listing Prices at any time without giving any
                    reason or prior notice.
                  </p>
                </section>
                <section>
                  <h3>4. Customers with PawnHero accounts</h3>
                  <p>
                    4.1 Username/Password: Certain Services that may be made
                    available on the Platform may require creation of an account
                    with us or for you to provide Personal Data. If you request
                    to create an account with us, a Username and Password may
                    either be: (i) determined and issued to you by us; or (ii)
                    provided by you and accepted by us in our sole and absolute
                    discretion in connection with the use of the Services and/or
                    access to the relevant Platform. We may at any time in our
                    sole and absolute discretion, request that you update your
                    Personal Data or forthwith invalidate the Username and/or
                    Password without giving any reason or prior notice and shall
                    not be liable or responsible for any Losses suffered by or
                    caused by you or arising out of or in connection with or by
                    reason of such request or invalidation. You hereby agree to
                    change your Password from time to time and to keep the
                    Username and Password confidential and shall be responsible
                    for the security of your account and liable for any
                    disclosure or use (whether such use is authorised or not) of
                    the Username and/or Password. You should notify us
                    immediately if you have knowledge that or have reason for
                    suspecting that the confidentiality of the Username and/or
                    Password has been compromised or if there has been any
                    unauthorised use of the Username and/or Password or if your
                    Personal Data requires updating.
                  </p>
                  <p>
                    4.2 Purported use/access: You agree and acknowledge that any
                    use of the Services and/or any access to the Platform and
                    any information, data or communications referable to your
                    Username and Password shall be deemed to be, as the case may
                    be:
                  </p>
                  <p>
                    (a) access to the relevant Platform and/or use of the
                    Services by you; or <br />
                    (b) information, data or communications posted, transmitted
                    and validly issued by you. You agree to be bound by any
                    access of the Platform and/or use of any Services (whether
                    such access or use are authorised by you or not) and you
                    agree that we shall be entitled (but not obliged) to act
                    upon, rely on or hold you solely responsible and liable in
                    respect thereof as if the same were carried out or
                    transmitted by you. You further agree and acknowledge that
                    you shall be bound by and agree to fully indemnify us
                    against any and all Losses attributable to any use of any
                    Services and/or or access to the Platform referable to your
                    Username and Password.
                  </p>
                </section>
                <section>
                  <h3>5. Intellectual property</h3>
                  <p>
                    5.1 Ownership: The Intellectual Property in and to the
                    Platform and the Materials are owned, licensed to or
                    controlled by us, our licensors or our service providers. We
                    reserve the right to enforce its Intellectual Property to
                    the fullest extent of the law.
                  </p>
                  <p>
                    5.2 Restricted use: No part or parts of the Platform, or any
                    Materials may be reproduced, reverse engineered, decompiled,
                    disassembled, separated, altered, distributed, republished,
                    displayed, broadcast, hyperlinked, mirrored, framed,
                    transferred or transmitted in any manner or by any means or
                    stored in an information retrieval system or installed on
                    any servers, system or equipment without our prior written
                    permission or that of the relevant copyright owners. Subject
                    to Clause 5.3, permission will only be granted to you to
                    download, print or use the Materials for personal and
                    non-commercial uses, provided that you do not modify the
                    Materials and that we or the relevant copyright owners
                    retain all copyright and other proprietary notices contained
                    in the Materials.
                  </p>
                  <p>
                    5.3 Trademarks: The Trademarks are registered and
                    unregistered trademarks of us or third parties. Nothing on
                    the Platform and in these Terms of Use shall be construed as
                    granting, by implication, estoppel, or otherwise, any
                    license or right to use (including as a meta tag or as a
                    “hot” link to any other website) any Trademarks displayed on
                    the Services, without our written permission or any other
                    applicable trademark owner.
                  </p>
                </section>
                <section>
                  <h3>6. Our limitation of responsibility and liability</h3>
                  <p>
                    6.1 No representations or warranties: The Services, the
                    Platform and the Materials are provided on an “as is” and
                    “as available” basis. All data and/or information contained
                    in the Platform, the Services or the Materials are provided
                    for informational purposes only. No representations or
                    warranties of any kind, implied, express or statutory,
                    including the warranties of non-infringement of third party
                    rights, title, merchantability, satisfactory quality or
                    fitness for a particular purpose, are given in conjunction
                    with the Platform, the Services or the Materials. Without
                    prejudice to the generality of the foregoing, we do not
                    warrant:
                  </p>
                  <p>
                    (a) the accuracy, timeliness, adequacy, commercial value or
                    completeness of all data and/or information contained in the
                    Platform, the Services or the Materials; <br />
                    (b) that the Platform, the Services or that any of the
                    Materials will be provided uninterrupted, secure or free
                    from errors or omissions, or that any identified defect will
                    be corrected;
                    <br />
                    (c) that the Platform, the Services or the Materials are
                    free from any computer virus or other malicious, destructive
                    or corrupting code, agent, program or macros; and <br />
                    (d) the security of any information transmitted by you or to
                    you through the Platform or the Services, and you accept the
                    risk that any information transmitted or received through
                    the Services or the Platform may be accessed by unauthorised
                    third parties and/or disclosed by us or our officers,
                    employees or agents to third parties purporting to be you or
                    purporting to act under your authority. Transmissions over
                    the Internet and electronic mail may be subject to
                    interruption, transmission blackout, delayed transmission
                    due to internet traffic or incorrect data transmission due
                    to the public nature of the Internet.
                  </p>
                  <p>
                    6.2 Exclusion of liability: PawnHero Indemnitees shall not
                    be liable to you for any Losses whatsoever or howsoever
                    caused (regardless of the form of action) arising directly
                    or indirectly in connection with:
                  </p>
                  <p>
                    (a) any access, use and/or inability to use the Platform or
                    the Services; <br />
                    (b) reliance on any data or information made available
                    through the Platform and/or through the Services. You should
                    not act on such data or information without first
                    independently verifying its contents; <br />
                    (c) any system, server or connection failure, error,
                    omission, interruption, delay in transmission, computer
                    virus or other malicious, destructive or corrupting code,
                    agent program or macros; and <br />
                    (d) any use of or access to any other website or webpage
                    linked to the Platform, even if we or our officers or agents
                    or employees may have been advised of, or otherwise might
                    have anticipated, the possibility of the same.
                  </p>
                  <p>
                    6.3 At your own risk: Any risk of misunderstanding, error,
                    damage, expense or Losses resulting from the use of the
                    Platform is entirely at your own risk and we shall not be
                    liable therefor.
                  </p>
                </section>
                <section>
                  <h3>7. Hyperlinks, and alerts</h3>
                  <p>
                    7.1 Hyperlinks: For your convenience, we may include
                    hyperlinks to other websites or content on the Internet that
                    are owned or operated by third parties. Such linked websites
                    or content are not under our control and we are not liable
                    for any errors, omissions, delays, defamation, libel,
                    slander, falsehood, obscenity, pornography, profanity,
                    inaccuracy or any other objectionable material contained in
                    the contents, or the consequences of accessing, any linked
                    website. Any hyperlinks to any other websites or content are
                    not an endorsement or verification of such websites or
                    content and you agree that your access to or use of such
                    linked websites or content is entirely at your own risk.
                  </p>
                  <p>
                    7.2 Promotions: We may attach banners, java applets and/or
                    such other materials to the Platform for the purposes of
                    promoting our or our Third Party Vendors’ products and/or
                    services. For the avoidance of doubt, you shall not be
                    entitled to receive any payment, fee and/or commission in
                    respect of any such promotional materials.
                  </p>
                </section>
                <section>
                  <h3>8. Your submissions and information</h3>
                  <p>
                    8.1 Submissions by you: You grant us a non-exclusive license
                    to use the materials or information that you submit to the
                    Platform and/or provide to us, including but not limited to,
                    questions, reviews, comments, and suggestions (collectively,
                    “Submissions”). When you post comments or reviews to the
                    Platform, you also grant us the right to use the name that
                    you submit or your Username in connection with such review,
                    comment, or other content. You shall not use a false e-mail
                    address, pretend to be someone other than yourself or
                    otherwise mislead us or third parties as to the origin of
                    any Submissions. We may, but shall not be obligated to,
                    publish, remove or edit your Submissions.
                  </p>
                  <p>
                    8.2 Consent to receive e-mails: You give your full, free,
                    and unequivocal consent and authority to the collection,
                    processing and use by us of any information provided by you
                    (including Personal Data) for the purposes of sending
                    informational and promotional e-mails and any and all
                    communications, notices, updates and other information to
                    you,. Your agreement to the provisions of this Clause 8.2
                    shall constitute your consent for the purpose of the
                    provisions of any spam control laws (whether in Philippines
                    or elsewhere). You may subsequently opt out of receiving
                    promotional e-mails by clicking on the appropriate hyperlink
                    in any promotional e-mail.
                  </p>
                  <p>
                    8.3 PawnHero may, from time to time, be required by
                    government agencies to disclose certain information in
                    connection with any audit or investigation. You understand
                    that we are not required to contest any demand made by an
                    (government) authority for such information.
                  </p>
                  <p>
                    Privacy Policy: You acknowledge that you have read and agree
                    to the Privacy Policy set out here. and consent to our
                    collection, use and disclosure of your Personal Data for the
                    purposes as set out in the Privacy Policy.
                  </p>
                </section>
                <section>
                  <h3>9. Termination</h3>
                  <p>
                    9.1 Termination by us: In our sole and absolute discretion,
                    we may with immediate effect, upon giving you notice,
                    terminate your use of the Platform and/or Services and/or
                    disable your Username and Password. We may bar access to the
                    Platform and/or Services (or any part thereof) for any
                    reason whatsoever, including a breach of any of these Terms
                    of Use or where if we believe that you have violated or
                    acted inconsistently with any terms or conditions set out
                    herein, or if in our opinion or the opinion of any
                    regulatory authority, it is not suitable to continue
                    providing the services relating to the Platform.
                  </p>
                  <p>
                    9.2 Termination by you: You may terminate these Terms of Use
                    by giving seven days’ notice in writing to us.
                  </p>
                </section>
                <section>
                  <h3>10. Notices</h3>
                  <p>
                    10.1 Notices from us: All notices or other communications
                    given to you if:
                  </p>
                  <p>
                    (a) communicated through any print or electronic media as we
                    may select will be deemed to be notified to you on the date
                    of publication or broadcast; or
                    <br />
                    (b) sent by post or left at your last known address will be
                    deemed to be received by you on the day following such
                    posting or on the day when it was so left.
                  </p>
                  <p>
                    10.2 Notices from you: You may only give notice to us in
                    writing sent to our designated address or e-mail address,
                    and we shall be deemed to have received such notice only
                    upon receipt. While we endeavour to respond promptly to
                    notices from you, we cannot guarantee that we will always
                    respond with consistent speed.
                  </p>
                  <p>
                    10.3 Other modes: Notwithstanding Clauses 10.1 and 10.2, we
                    may from time to time designate other acceptable modes of
                    giving notices (including but not limited to e-mail or other
                    forms of electronic communication) and the time or event by
                    which such notice shall be deemed given.
                  </p>
                </section>
                <section>
                  <h3>11. General</h3>
                  <p>
                    11.1 Cumulative rights and remedies: Unless otherwise
                    provided under these Terms of Use, the provisions of these
                    Terms of Use and our rights and remedies under these Terms
                    of Use are cumulative and are without prejudice and in
                    addition to any rights or remedies we may have in law or in
                    equity, and no exercise by us of any one right or remedy
                    under these Terms of Use, or at law or in equity, shall
                    (save to the extent, if any, provided expressly in these
                    Terms of Use or at law or in equity) operate so as to hinder
                    or prevent our exercise of any other such right or remedy as
                    at law or in equity.
                  </p>

                  <p>
                    11.2 No waiver: Our failure to enforce these Terms of Use
                    shall not constitute a waiver of these terms, and such
                    failure shall not affect the right later to enforce these
                    Terms of Use. We would still be entitled to use our rights
                    and remedies in any other situation where you breach these
                    Terms of Use.
                  </p>

                  <p>
                    11.3 Severability: If at any time any provision of these
                    Terms of Use shall be or shall become illegal, invalid or
                    unenforceable in any respect, the legality, validity and
                    enforceability of the remaining provisions of this Agreement
                    shall not be affected or impaired thereby, and shall
                    continue in force as if such illegal, invalid or
                    unenforceable provision was severed from these Terms of Use.
                  </p>

                  <p>
                    11.4 Rights of third parties: A person or entity who is not
                    a party to these Terms of Use shall have no right under any
                    legislation in any jurisdiction to enforce any term of these
                    Terms of Use, regardless of whether such person or entity
                    has been identified by name, as a member of a class or as
                    answering a particular description. For the avoidance of
                    doubt, nothing in this Clause shall affect the rights of any
                    permitted assignee or transferee of these Terms of Use.
                  </p>

                  <p>
                    11.5 Governing law: Use of the Platform and/or the Services
                    and these Terms of Use shall be governed by and construed in
                    accordance with Philippines law and you hereby submit to the
                    exclusive jurisdiction of the Philippine Courts. Except as
                    provided in the preceding sentence, any dispute,
                    controversy, or claim arising out of or relating to this
                    terms and conditions, or the breach, termination or
                    invalidity thereof shall be settled through arbitration
                    under Republic Act 9285 or the Alternative Dispute
                    Resolution Act of 2004.
                  </p>

                  <p>
                    11.6 Injunctive relief: We may seek immediate injunctive
                    relief if we make a good faith determination that a breach
                    or non-performance is such that a temporary restraining
                    order or other immediate injunctive relief is the only
                    appropriate or adequate remedy.
                  </p>

                  <p>
                    11.7 Amendments: We may by notice through the Platform or by
                    such other method of notification as we may designate (which
                    may include notification by way of e-mail), vary the terms
                    and conditions of these Terms of Use, such variation to take
                    effect on the date we specify through the above means. If
                    you use the Platform or the Services after such date, you
                    are deemed to have accepted such variation. If you do not
                    accept the variation, you must stop access or using the
                    Platform and the Services and terminate these Terms of Use.
                    Our right to vary these Terms of Use in the manner aforesaid
                    will be exercised with may be exercised without the consent
                    of any person or entity who is not a party to these Terms of
                    Use.
                  </p>

                  <p>
                    11.8 Correction of errors: Any typographical, clerical or
                    other error or omission in any acceptance, invoice or other
                    document on our part shall be subject to correction without
                    any liability on our part.
                  </p>

                  <p>
                    11.9 Currency: Money references under these Terms of Use
                    shall be in Philippines Peso.
                  </p>

                  <p>
                    11.10 Language: In the event that these Terms of Use is
                    executed or translated in any language other than English
                    (“Foreign Language Version”), the English language version
                    of these Terms of Use shall govern and shall take precedence
                    over the Foreign Language Version.
                  </p>

                  <p>
                    11.11 Entire agreement: These Terms of Use shall constitute
                    the entire agreement between you and us relating to the
                    subject matter hereof and supersedes and replaces in full
                    all prior understandings, communications and agreements with
                    respect to the subject matter hereof.
                  </p>

                  <p>
                    11.12 Binding and conclusive: You acknowledge and agree that
                    any records (including records of any telephone
                    conversations relating to the Services, if any) maintained
                    by us or our service providers relating to or in connection
                    with the Platform and Services shall be binding and
                    conclusive on you for all purposes whatsoever and shall be
                    conclusive evidence of any information and/or data
                    transmitted between us and you. You hereby agree that all
                    such records are admissible in evidence and that you shall
                    not challenge or dispute the admissibility, reliability,
                    accuracy or the authenticity of such records merely on the
                    basis that such records are in electronic form or are the
                    output of a computer system, and you hereby waive any of
                    your rights, if any, to so object.
                  </p>

                  <p>
                    11.13 Sub-contracting and delegation: We reserve the right
                    to delegate or sub-contract the performance of any of our
                    functions in connection with the Platform and/or Services
                    and reserve the right to use any service providers,
                    subcontractors and/or agents on such terms as we deem
                    appropriate.
                  </p>

                  <p>
                    11.14 Assignment: You may not assign your rights under these
                    Terms of Use without our prior written consent. We may
                    assign our rights under these Terms of Use to any third
                    party.
                  </p>

                  <p>
                    11.15 Force Majeure: We shall not be liable for
                    non-performance, error, interruption or delay in the
                    performance of its obligations under these Terms of Use (or
                    any part thereof) or for any inaccuracy, unreliability or
                    unsuitability of the Platform's and/or Services’ contents if
                    this is due, in whole or in part, directly or indirectly to
                    an event or failure which is beyond our reasonable control.
                  </p>
                </section>
                <section>
                  <h3>Schedule 1</h3>
                  <p>Definitions and Interpretation</p>
                </section>
                <section>
                  <h3>1. Definitions.</h3>
                  <p>
                    Unless the context otherwise requires, the following
                    expressions shall have the following meanings in these Terms
                    of Use:
                  </p>
                  <p>
                    1.1 “Customer” has the same meaning as in the Terms &
                    Conditions of Sale and Terms & Conditions of Pawn Loan.
                  </p>

                  <p>
                    1.2 “Intellectual Property” means all copyright, patents,
                    utility innovations, trademarks and service marks,
                    geographical indications, domain names, layout design
                    rights, registered designs, design rights, database rights,
                    trade or business names, rights protecting trade secrets and
                    confidential information, rights protecting goodwill and
                    reputation, and all other similar or corresponding
                    proprietary rights and all applications for the same,
                    whether presently existing or created in the future,
                    anywhere in the world, whether registered or not, and all
                    benefits, privileges, rights to sue, recover damages and
                    obtain relief or other remedies for any past, current or
                    future infringement, misappropriation or violation of any of
                    the foregoing rights.
                  </p>

                  <p>
                    1.3 “PawnHero Indemnitees” means PawnHero and all of its
                    respective officers, employees, directors, agents,
                    contractors and assigns.
                  </p>

                  <p>
                    1.4 “PawnHero”, “we”, “our” and “us” refer to PawnHero
                    Pawnshop Philippines Inc., with business address at 
                    7th Floor, Avecshares Center, 1132 University Parkway North, 
                    Bonifacio Global City, Taguig City 1634, Philippines.
                  </p>

                  <p>
                    1.5 “Listing Price” means the price of Products listed for
                    sale to Customers, as stated on the Platform.
                  </p>

                  <p>
                    1.6 “Losses” means all penalties, losses, settlement sums,
                    costs (including legal fees and expenses on a
                    solicitor-client basis), charges, expenses, actions,
                    proceedings, claims, demands and other liabilities, whether
                    foreseeable or not.
                  </p>

                  <p>
                    1.7 “Materials” means, collectively, all web pages on the
                    Platform, including the information, images, links, sounds,
                    graphics, video, software, applications and other materials
                    displayed or made available on the Platform and the
                    functionalities or services provided on the Platform.
                  </p>

                  <p>
                    1.8 “Order” means your order for Products sent through the
                    Platform in accordance with the Terms & Conditions of Sale.
                  </p>

                  <p>
                    1.9 “Password” refers to the valid password that a Customer
                    who has an account with PawnHero may use in conjunction with
                    the Username to access the relevant Platform and/or
                    Services.
                  </p>

                  <p>
                    1.10 “Personal Data” means data, whether true or not, that
                    can be used to identify, contact or locate you. Personal
                    Data can include your name, e-mail address, billing address,
                    shipping address, phone number and credit card information.
                    “Personal Data” shall be deemed to include any data that you
                    have provided to us when placing an Order, regardless of
                    whether you have an account with us together with non-public
                    information that may be associated with foregoing.
                  </p>

                  <p>
                    1.11 “Platform” means (a) both the web and mobile versions
                    of the website operated and/or owned by PawnHero which is
                    presently located at the following URLs: www.PawnHero.ph and
                    www.luxein.com/; and (b) the mobile applications made
                    available from time to time by PawnHero, including the iOS
                    and Android versions.
                  </p>

                  <p>
                    1.12 “Privacy Policy” means the privacy policy set out here.
                  </p>

                  <p>
                    1.13 “Product” means a product (including any installment of
                    the product or any parts thereof) available for sale to
                    Customers on the Platform.
                  </p>

                  <p>
                    1.14 “Prohibited Material” means any information, graphics,
                    photographs, data and/or any other material that:
                  </p>
                  <p>
                    (a) contains any computer virus or other invasive or
                    damaging code, program or macro;
                    <br />
                    (b) infringes any third-party Intellectual Property or any
                    other proprietary rights;
                    <br />
                    (c) is defamatory, libellous or threatening;
                    <br />
                    (d) is obscene, pornographic, indecent, counterfeited,
                    fraudulent, stolen, harmful or otherwise illegal under the
                    applicable law; and/or
                    <br />
                    (e) is or may be construed as offensive and/or otherwise
                    objectionable, in our sole opinion.
                  </p>
                  <p>
                    1.15 “Services” means services, information and functions
                    made available by us at the Platform.
                  </p>

                  <p>
                    1.16 “Submission” is as defined in Clause 8.1 of these Terms
                    of Use.
                  </p>

                  <p>
                    1.17 “Terms & Conditions of Pawn Loan” means the terms and
                    conditions governing a Customer’s pawn loan transaction with
                    PawnHero and are set out on the pawn ticket.
                  </p>

                  <p>
                    1.18 “Terms & Conditions of Sale” means the terms and
                    conditions governing a Customer’s purchase of the Products
                    and are set out here.
                  </p>

                  <p>
                    1.19 “Terms of Use” means the recitals, Clauses 1 to 12 and
                    any Schedules to these terms and conditions.
                  </p>

                  <p>
                    1.20 “Trademarks” means the trademarks, service marks, trade
                    names and logos used and displayed on the Platform,
                    including the PawnHero trademark.
                  </p>

                  <p>
                    1.21 “Username” refers to the unique login identification
                    name or code which identifies a Customer who has an account
                    with PawnHero.
                  </p>

                  <p>
                    1.22 “you” and “your” refer to the individuals over the age
                    of 18 or otherwise under the supervision of a parent or
                    legal guardian.
                  </p>

                  <p>
                    1.23 "Anonymous Information" means information that is not
                    associated with or linked to your Personal Data; Anonymous
                    Information does not permit the identification of individual
                    persons.
                  </p>
                </section>
                <section>
                  <h3>2. Interpretation:</h3>
                  <p>
                    Any reference in these Terms of Use to any provision of a
                    statute shall be construed as a reference to that provision
                    as amended, re-enacted or extended at the relevant time. In
                    the Agreement, whenever the words “include”, “includes” or
                    “including” are used, they will be deemed to be followed by
                    the words “without limitation”. Unless expressly indicated
                    otherwise, all references to a number of days mean calendar
                    days, and the words “month” or “monthly” as well as all
                    references to a number of months means calendar months.
                    Clause headings are inserted for convenience only and shall
                    not affect the interpretation of these Terms of Use. In the
                    event of a conflict or inconsistency between any two or more
                    provisions under these Terms of Use, whether such provisions
                    are contained in the same or different documents, such
                    conflict or inconsistency shall be resolved in favour of
                    PawnHero and the provision which is more favourable to
                    PawnHero shall prevail.
                  </p>
                </section>
                <p>Last Updated: October 5, 2016.</p>
              </div>
              <WhyPawnhero />
            </div>
          </ContainerStyled>
        </TermsStyled>
      </Layout>
    </>
  );
};

export default TermOfUse;
