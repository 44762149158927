import styled from 'styled-components';

export const SideNavStyled = styled.div`
  overflow-x: hidden;
  transition: 0.5s;

  @media (min-width: 769px) {
    max-width: 300px;
    width: 30%;

    &.close {
      width: 0;
    }
  }

  @media (max-width: 768px) {
    max-width: unset;
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);

    &.close {
      height: 0;
    }
  }

  div.sidenav-wrapper {
    background: #354f65;
    height: 100%;

    @media (max-width: 768px) {
      height: fit-content;
      padding-bottom: 20px;
    }
  }

  .user-details {
    padding: 40px 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
    }

    div.photo-container {
      div.user-profile-wrapper {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 50%;
        overflow: hidden;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    div.user-data-container {
      padding: 0 10px;
      h1 {
        color: #ffffff;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        white-space: nowrap;
      }

      p {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        font-style: italic;
        white-space: nowrap;
      }

      a {
        font-size: 12px;
        text-decoration-line: underline !important;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  nav {
    margin: 45px auto 0;

    ul {
      list-style: none;

      li {
        padding: 5px 20px;
        cursor: pointer;

        &.active {
          background: #68c552;
        }

        div {
          padding: 10px 0 10px 30px;
          display: flex;
          flex-wrap: nowrap;

          img {
            width: 22px;
          }

          p,
          a {
            padding-left: 10px;
            flex: 1;
            color: #ffffff;
            font-size: 14px;
            display: block;
            width: 100%;
            white-space: nowrap;
            display: flex;
            justify-content: space-between;

            &:hover {
              color: #ffffff;
            }
          }

          span {
            background: #e2574c;
            width: 18px;
            height: 18px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
`;
