import React, { useEffect, useState } from 'react';

// utilities
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateRedeem } from '../../../actions/redeem';
import momentTZ from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import { fetchTime } from '../../../actions/time';
import moment from 'moment';

// styles
import { LoanOfferStyled } from '../../styles/acceptance.style';
import SharedModal from '../shared/modal';
import { fetchRedeemOffer, makeRenewalRequest } from '../../../actions/ticket';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { off } from '../../../server/config/bookshelf';

const Offer = ({
  redeem,
  animationDoneHandler,
  doUpdateRedeem,
  doFetchRedeemOffer,
  doMakeRenewalRequest,
  callback = false,
  doFetchTime,
}) => {
  const { slideLeft = false } = redeem;
 
  // params
  const { ticketNum } = useParams();
  const history = useHistory();

  // states
  const [promo, promoHandler] = useState(false);
  const [isOpen, isOpenHandler] = useState(false);
  const [offers, offersHandler] = useState(false);
  const [error, errorHandler] = useState(null);
  const [renewalSelected, renewalSelectedHandler] = useState(null);
  const [isLoading, isLoadingHandler] = useState(false);
  const [currentDate, currentDateHandler] = useState(new Date());
  const [maturityDate, maturityDateHandler] = useState(false);
  const [isDefaultToday, isDefaultTodayHandler] = useState(false);


  // methods
  const offerSelect = (offer) => {
    doUpdateRedeem({
      position: 1,
      data: { ...redeem.data, offer },
      slideLeft: false,
    });
  };

  //load events
  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getCurrentDate();
      getRedeemOffer();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  const getCurrentDate = async () => {
    try {
      const currentDate = await doFetchTime();
      currentDateHandler(currentDate);
    } catch (err) {
      currentDateHandler(new Date());
    }
  };

  const getRedeemOffer = async () => {
    animationDoneHandler(false);
    try {
      //fire async get redeem offer
      const { data, success = false } = await doFetchRedeemOffer({ ticketNum });

      if (success) {
        const { offer, promo } = data;
        
        const [redeem] = offer;
        maturityDateHandler(redeem.dueDate)

        let _isDefaultToday = redeem.defaultDate
                        ? moment(currentDate).isAfter(
                            moment(redeem.defaultDate).subtract(-1, 'd'),
                            'day'
                          )
                        : false;
        isDefaultTodayHandler(_isDefaultToday);
        offersHandler(offer);
        promoHandler(promo);
        if (callback) { 
          callback(data);
        }
      } else {
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      }
    } catch (err) {
      errorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
    setTimeout(() => {
      animationDoneHandler(true);
    }, 300);
  };

  const makeRenewalReq = (renewalSelected) => {
    isLoadingHandler(true);
    const { raw: data } = renewalSelected;
    const payload = {
      ticket_no: ticketNum,
      data,
    };

    doMakeRenewalRequest(payload)
      .then(({ data }) => {
        const { success } = data;

        if (success) {
          isOpenHandler(false);
          isLoadingHandler(false);
          history.push(`/pawn/renewal/${ticketNum}/thankyou`, {
            thankyouDateExpiry: momentTZ().add(30, 'm').tz(timezone).unix(),
          });
        } else {
          isLoadingHandler(false);
          isOpenHandler(false);
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        console.log('doMakeRenewalRequest err', err);
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  return (
    <LoanOfferStyled slideLeft={slideLeft}>
      <div className='option-wrapper'>
        <div className='option-header'>
          <p>Pawn Ticket {ticketNum}</p>
        </div>
        <div className='option-list-wrapper'>
          <ul>
            {error ? (
              <>
                <p style={{ textAlign: 'center' }}>{error}</p>
              </>
            ) : (
              <>
                {offers ? (
                  <>
                    
                    {offers.map((offer, i) => {
                      const _isExpired = offer.defaultDate
                        ? moment(currentDate).isAfter(
                            moment(offer.defaultDate).subtract(7, 'd'),
                            'day'
                          )
                        : false;
                          
                      
                      return (
                        <li key={i}>
                          <div className='list-content'>
                            <div>
                              <div>
                                {offer.mode === 'renewal' ? (
                                  <p className='title'>You pay</p>
                                ) : (
                                  <>
                                    {_isExpired ? (
                                      <>
                                        <p className='expired-flag'>
                                          LAST DAY OF REDEMPTION:{' '}
                                          {moment(offer.defaultDate).format(
                                            'D MMM YYYY'
                                          )}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p className='title'>You pay</p>
                                      </>
                                    )}
                                  </>
                                )}

                                <p className='primary-note'>
                                  {offer.amountDue}
                                </p>
                                <p className='note'>
                                  <span>
                                    {offer.mode === 'redeem'
                                      ? 'Loan Amount'
                                      : 'Renewal Amount'}
                                    :{' '}
                                    {offer.mode === 'redeem'
                                      ? offer.netAmount
                                      : offer.renewalAmount}
                                    {offer.mode === 'renewal' && (
                                      <>
                                        {' • '}
                                        Interest Rate:{' '}
                                        {offer.interestRate || ''}
                                      </>
                                    )}
                                  </span>
                                  <span>
                                    {offer.mode === 'redeem'
                                      ? 'Total Interest'
                                      : 'Liquidating Damage'}
                                    :{' '}
                                    {offer.mode === 'redeem'
                                      ? offer.totalInterest
                                      : offer.liquidatingInterest}
                                    {offer.mode === 'redeem' && (
                                      <>
                                        {' • '}
                                        Interest Rate:{' '}
                                        {offer.interestRate || ''}
                                      </>
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div>
                              {offer.mode === 'redeem' ? (
                                
                                <>
                                  
                                  <div>
                                    <>
                                      <p className='title'>Maturity date</p>
                                      <p className='primary-note'>
                                        {offer.dueDate}
                                      </p>

                                      {_isExpired ? (
                                        <>
                                          <p className='note'>
                                            Pay on or before maturity date to
                                            avoid foreclosure of your item
                                          </p>
                                        </>
                                      ) : (
                                        <p className='note'>
                                          Pay on or before maturity date to
                                          avoid penalties
                                        </p>
                                      )}
                                    </>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <p className='title'>New loan duration</p>
                                    <p className='primary-note'>
                                      {offer.extensionDuration}
                                    </p>
                                    <p className='note'>
                                      New maturity date:
                                      <span>{offer.extensionDueDate}</span>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              {offer.mode === 'redeem' ? (
                                <button
                                  className='primary'
                                  onClick={() => offerSelect(offer)}>
                                  Pay loan now
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    renewalSelectedHandler(offer);
                                    isOpenHandler(true);
                                  }}>
                                  Request renewal
                                </button>
                              )}
                            </div>
                          </div>
                          {offer.mode === 'redeem' ? (
                            <>
                                <p className='disclaimer-redeem'>
                                  Please repay your loan within 24 hours after pressing the "Pay loan now" button
                                </p>
                            </>
                            ) : (
                            <></>
                            )}
                        </li>
                        
                        
                        
                        
                      );
                      
                      
                    })}
                    {/* gold promo */}
                    {promo === 'fixed_interest' && (
                      <>
                        <li className='promo-li'>
                          <div className='pawnhero-gold-promo'>
                            <div>
                              <img
                                className='promo-banner'
                                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo_gold-fix-3.svg'
                                alt='pawnalo gold'
                              />
                            </div>
                            <div>
                              <p className='title'>PAWNALO DEAL:</p>
                              <p>
                                Get a higher appraisal up to 25% vs previous
                                PawnHero valuation!
                              </p>
                              <br />
                              <p className='title'>LIMITED OFFER:</p>
                              <p>
                                Enjoy a fixed 3.5% interest rate when you pawn
                                gold and jewelry!
                              </p>
                              <br />
                              <p className='note'>
                                <Link to='/pawnalo-gold-jewelry-pawnshop-appraisal'>
                                  T&Cs apply.
                                </Link>{' '}
                                DTI Fair Trade Permit No. FTEB-121316 Series of
                                2021
                              </p>
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                    <div className='disclamer-wrapper'>
                      <p className='title'>Loan Repayment</p>
                      <ul>
                        <li>
                          {isDefaultToday ? (
                            <>
                              <p className='note'>
                                Please repay your loan <span className='important'>TODAY BEFORE 11:59 PM</span> before you can redeem your item.
                              </p>
                            </>
                          ) : (
                            <>
                            <p className='note'>
                              Please repay your loan <span className='important'>ON OR BEFORE {maturityDate}</span> before you can redeem your item.
                            </p>
                            </>
                          )}
                          
                        </li>
                        <li>
                          <p>
                            You may pay via the following repayment methods:
                            direct payment, Security Bank, 7-Eleven, Coins.ph,
                            GCash, Dragonpay, or E-banking
                          </p>
                        </li>
                      </ul>
                      <p className='title'>Loan Renewal </p>
                      <ul>
                        <li>
                          <p>
                            Total renewal amount is equal to the renewal amount
                            plus the liquidating damages
                          </p>
                        </li>
                        <li>
                          <p>
                            The interest rate on loan renewals may vary
                            depending on the interest rate of your original loan
                            amount due to the increased depreciation of your
                            item
                          </p>
                        </li>
                        <li>
                          <p>
                            All renewal requests are subject to PawnHero’s
                            approval
                          </p>
                        </li>
                        <li>
                          <p>
                            Once your renewal request has been approved, you
                            have to pay the total renewal amount within 24 hours
                          </p>
                        </li>
                      </ul>
                      <p className='disclamer-note'>
                        For any concerns about loan repayments or renewals,
                        please do not hesitate to contact our customer service
                        representatives at 8828-2274 between 9:00 AM and 7:00
                        PM.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <li className='fetching-offer'>
                      <p>Please wait while we calculate your payment terms</p>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
      <SharedModal
        isOpen={isOpen}
        background={'rgba(0, 0, 0, 0.25)'}
        handleClose={() => {
          isOpenHandler(false);
        }}>
        <img
          className='logo'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/renew-req-ico.svg'
          alt=''
        />
        <p className='title'>Confirm your loan renewal request</p>
        <p className='note'>
          Please expect an email or SMS that your renewal request has been
          approved.
        </p>
        <button
          disabled={isLoading}
          onClick={() => {
            makeRenewalReq(renewalSelected);
          }}>
          Request for loan renewal
        </button>
      </SharedModal>
    </LoanOfferStyled>
  );
};

const mapStateToProps = pick(['redeem']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateRedeem: (redeem) => dispatch(updateRedeem(redeem)),
  doFetchRedeemOffer: (payload) => dispatch(fetchRedeemOffer(payload)),
  doMakeRenewalRequest: (payload) => dispatch(makeRenewalRequest(payload)),
  doFetchTime: () => dispatch(fetchTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
