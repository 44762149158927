import React from 'react';
import { LoanSummaryStyled } from '../../styles/acceptance.style';

//utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import isNil from '@ramda/isnil';
import { updateLoan } from '../../../actions/loan';

const Summary = ({
  loan,
  doUpdateLoan,
  mode = 0, // 0 -> acceptance : 1 -> offer
}) => {
  const { position, data = {} } = loan;
  const { offer = null, booking = null, cashout = null } = data;

  const lists = [
    {
      label: 'Select Offer',
      hasValue: offer ? true : false,
      displayLabel: 'You selected',
      displayLabelVal:
        !isNil(offer) && offer !== undefined ? offer.loanAmount : '',
      mode: [0, 1],
    },
    {
      label: 'Select the item booking type',
      hasValue: booking ? true : false,
      displayLabel: 'You selected',
      displayLabelVal:
        !isNil(booking) && booking !== undefined
          ? booking.mode === 'walkin'
            ? 'Walk-in'
            : 'Pickup'
          : '',
      mode: [0],
    },
    {
      label: 'Select the cash out method',
      hasValue: cashout ? true : false,
      displayLabel: 'You selected',
      displayLabelVal:
        !isNil(cashout) && cashout !== undefined ? cashout.label : '',
      mode: [0],
    },
    {
      label: 'Review loan offer',
      hasValue: false,
      displayLabel: '',
      mode: [0, 1],
    },
  ].filter((e) => e.mode.includes(mode));

  const positionHandler = (pos) => {
    doUpdateLoan({ position: pos, showLoanSummary: false });
  };

  return (
    <LoanSummaryStyled
      MobileDisplay={loan.showLoanSummary}
      className='floating-summary'>
      <div className='loan-summary-wrapper'>
        <div className='summary-header'>
          <h3>Loan Summary</h3>
        </div>
        <div className='summary-body'>
          <nav>
            <ul>
              {lists.map((list, i) => {
                return (
                  <li
                    key={i}
                    className={`${list.hasValue ? 'with-value' : ''} ${
                      i === position ? 'current' : ''
                    }`}
                    onClick={() => positionHandler(i)}>
                    <span>{i + 1}</span>
                    {list.hasValue ? (
                      <>
                        <p>
                          {list.displayLabel}{' '}
                          <span>{list.displayLabelVal}</span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>{list.label}</p>
                      </>
                    )}
                    {list.hasValue && (
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pen-edit-circle-ico.svg'
                        alt='edit'
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </LoanSummaryStyled>
  );
};

const mapStateToProps = pick(['loan']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateLoan: (acceptance) => dispatch(updateLoan(acceptance)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
