import { config } from './axios.config';
import { handleSuccess, handleError } from './globals';
const axios = require('axios');

export const getConvoMessages = ({ user, payload }) => {
  const _urlparams = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/chat/room/${user}${_urlparams}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getUnreadCount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/chat/message/myUnread/count`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
