export const advisoryData = [
  {
    title: `Service Advisory - 15 June 2023, 12:00 NN`,
    receiver: ``,
    content: `
    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/psa-pawnhero-fraud-facebook.png' alt='' styles="width: '100%'"/>
    <p>
      Please be informed that PawnHero DOES NOT operate any Telegram Group. For your security, do not join any Telegram Group claiming to be affiliated with PawnHero.
      <br><br>
      The Telegram group with join link: https://t.me/+dTvVIIY29_dkNWY is not an official PawnHero Telegram group and is not managed by PawnHero.
      Kindly help us report this Telegram Group so as to avoid other people from being misinformed.
      <br><br>
      <b>BEWARE AND BE CAUTIOUS.</b>
      <br><br>
      Here are the only official PawnHero Pages and Accounts: <br>
      Website: https://pawnhero.ph/<br>
      Facebook: https://www.facebook.com/PawnHero.ph/<br>
      Instagram: https://www.instagram.com/pawnheroph//<br>
      LinkedIn: https://www.linkedin.com/company/pawnhero/<br>
      Youtube: https://www.youtube.com/@PawnHeroChannel/<br><br>
      For concerns and inquiries, below are the only official PawnHero Customer Hotlines:<br><br>
      Globe: 0917 872 2274 (Calls only)<br>
      Smart: 0999 222 2274 (Calls only)<br>
      Landline: 8828-CASH (2274)<br>
      Email: customer@pawnhero.ph<br>
      PawnHero Mobile app chat support
    <br/><br/>
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory - 26 May 2022, 12:00 NN`,
    receiver: ``,
    content: `
    <p>
      As we continue to work on enhancing your pawning experience, please be advised that our official customer service hotlines will undergo scheduled maintenance from May 28 to June 1 and hence will be unavailable during this time.
      <br/><br/>
      Unavailable hotlines from May 28 to June 1, 2022:
      <br/>
      Main Hotline: 8828-2274(CASH)
      <br/>
      Globe: 0917 872 2274
      <br/>
      Smart: 0999 222 2274
      <br/><br/>
      During this time, you may continue reaching us via email at customer@pawnhero.ph and Facebook Messenger at m.me/PawnHero.ph.
      <br/><br/>
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory - 11 May 2022, 12:00 NN`,
    receiver: ``,
    content: `
    <p>
      Please be advised that PawnHero Pawnshop will be pausing all walk-in transactions from May 21 to June 1, 2022 in preparation for our office relocation. PawnHero Pawnshop will be relocating from the 7/F W Global Center, 30th St. cor. 9th Ave., Bonifacio Global City, Taguig City to 7/F Avecshares Center, 1132 University Parkway North, Bonifacio Global City, Taguig City effective June 1, 2022.
    <br/><br/>
      During this time, we are encouraging our dear Pawners to utilize our online pawning services via website or the PawnHero mobile app. All our online services such as online pawning, loan renewal and redemption remain business as usual. Regular walk-in services will resume on June 2, 2022 at our new location.
    <br/><br/>
      For questions and inquiries, call our hotline at 8828-2274 or email us at customer@pawnhero.ph.
    </p>
    `,
    sender: `Thank you for understanding.`,
  },
  {
    title: `Service Advisory - 11 May 2022, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    Please be advised that starting 1 June 2022, PawnHero Pawnshop Philippines, Inc. will be relocating from the 7th Floor W Global Center, 30th St. cor. 9th Ave., Bonifacio Global City, Taguig City to its new office located at 7th Floor, Avecshares Center, 1132 University Parkway North, Bonifacio Global City, Taguig City. 
    <br/><br/>
    Our business operations from 9:00AM to 7:00PM, Monday to Saturday (no holidays and Sundays) and contact information remain the same. For questions and inquiries, call our hotline at 8828-2274 or via email at customer@pawnhero.ph.
    <br/><br/>
    Please be reminded of the dress code policy as strictly implemented by the Avecshares Center building administration where our physical store is located:
    <br/><br/>
    1. No wearing of slippers, shorts, and sleeveless shirts
    <br/>
    2. No wearing of any kinds of caps/hats
    <br/><br/>
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory –  11 March 2022, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    Please be advised that there will be a scheduled system maintenance activity on 14 March 2022, Monday, from 8:00 PM until 12:00 AM. During this time, all PawnHero Pawnshop services such as online pawning, loan renewal and loan redemption will be unavailable. 
    <br/><br/>
    For your convenience, kindly schedule your online transactions accordingly. Should you need assistance, talk to our loan officers during business hours (9:00 AM to 7:00 PM, Monday to Saturday) through these channels:
    <br/>
    <br/>
    Main hotline: 8828 - 2274<br/>
    Globe hotline: 0917 872 2274 (Calls only)<br/>
    Smart hotline: 0999 222 2274 (Calls only)<br/>
    Facebook Messenger: m.me/PawnHero.ph
    <br/><br/>
    
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory –  23 February 2022, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p><strong>New Three-Month Maximum Loan Term Policy</strong>
    <br/><br/>
    Effective March 15 2022, PawnHero Pawnshop Philippines, Inc. shall allow pawners up to 3 months initial loan term subject to approval. Rest assured that our new policy on loan terms shall comply with the applicable laws and regulations.
    <br/><br/>
    This three-month maximum loan duration limit policy shall not apply to existing loans made prior to March 15 2022.
    <br/>
    <br/>
    Maximum of 1-month loan term still applies for electronic gadgets.
    <br/><br/>
    For further details, you may refer to the policy on this link: <br/>
    <a href='https://pawnhero.ph/pawning#new-loan-term-policy'>https://pawnhero.ph/pawning#new-loan-term-policy</a>
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory –  26 January 2022, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p><strong>Three-Renewal Limit Policy</strong>
    <br/><br/>
    Effective Feb. 15, 2022, PawnHero Pawnshop Philippines, Inc. shall allow a limit of up to three (3) renewals for all new loans secured by personal property other than electronic gadgets. Rest assured that renewed loans under this three-renewal limit shall comply with the applicable laws and regulations. PPPI shall issue a new pawn ticket indicating the new term of the loan agreed upon by PPPI and pawner relating to the renewal.
    <br/><br/>
    This three-renewal limit policy shall not apply to existing loans made prior to Feb. 15, 2022. 
    <br/>
    <br/>
    Loans made prior to Feb. 15, 2022 under the electronic gadgets category shall remain unavailable for loan renewal applications.
    <br/><br/>
    For the purpose of counting the number of renewals, 1 month loan renewal is equal to 1 loan renewal application.
    <br/><br/>
    For further details, you may refer to the policy on this link: <br/>
    <a href='https://pawnhero.ph/pawning#newrenewalpolicy'>https://pawnhero.ph/pawning#newrenewalpolicy</a>
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory –  09 January 2022, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    Please be advised that our doors at PawnHero Pawnshop remain open to all our dear Pawners in need of instant cash.  
    <br/><br/>
    For your protection, you will be asked to present your vaccination card upon entry to the PawnHero Lounge for any walk-in transactions.
    <br/><br/>
    Pawners who will not be able to present their vaccination cards will still be allowed to pawn via walk-in but only at the 7th floor lobby area. 
    <br/><br/>
    Thank you and stay safe, Pawners!
    <br/><br/>
    For your utmost safety, opt to #PawnSafelyAtHome through our app available on Google Play Store and Apple App Store. Item pick-up and return are FREE.
    <br/><br/><br/>
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory –  05 November 2021, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    Please be advised that there will be a scheduled system maintenance activity on 7 November 2021, Sunday, starting at 8:00 AM until 11:59 PM. During the system maintenance, all PawnHero Pawnshop services such as pawning, loan renewal and loan redemption will be unavailable. 
    <br/><br/>
    Due to this, all loan maturities and expirations falling on November 7 will automatically be moved to the next business day (November 8, Monday). Loan renewal and redemption requests must be submitted on or before November 8 (except on November 7, Sunday) to avoid accrued interests and penalties.
    <br/><br/>
    For your convenience, kindly schedule your transactions accordingly. You may talk to any of our loan officers during business hours (9:00 AM - 7:00 PM, Monday - Saturday) through these channels:
    <br/>
    <br/>
    Main hotline: 8828 - 2274<br/>
    Globe hotline: 0917 872 2274 (Calls only)<br/>
    Smart hotline: 0999 222 2274 (Calls only)<br/>
    Facebook Messenger: m.me/PawnHero.ph
    <br/><br/><br/>
    
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory –  15 September 2021, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the announcement of the national government placing NCR under GCQ with a curfew of 10PM-4AM effective September 16, please be informed that we will resume our regular operations schedule starting September 16 until further notice:
    <br/><br/>
    9AM to 7PM - Monday to Saturday
    <br/><br/>
    Delivery cut-off notice:<br/>
    5PM cut-off for deliveries within Metro Manila<br/>
    <br/><br/>
    *Item pick-up and return within Metro Manila and serviceable areas in Rizal and Cavite are available.<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory –  8 September 2021, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the announcement of the national government extending MECQ in NCR starting September 8, please be informed that we are retaining our adjusted business hours until further notice.
    <br/><br/>
    8AM to 5PM - Monday to Saturday
    <br/><br/>
    Cut-off notice:<br/>
    4PM cut-off for walk-in customers<br/>
    3PM cut-off for item pick-up and return within Metro Manila<br/>
    <br/><br/>
    *Item pick-up and return within Metro Manila and serviceable areas in Rizal and Cavite are available.<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 1 September 2021, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the announcement of the national government extending MECQ in NCR until September 7, please be informed that we are retaining our adjusted business hours for the duration of this quarantine period.
    <br/><br/>
    8AM to 5PM - Monday to Saturday
    <br/><br/>
    Cut-off notice:<br/>
    4PM cut-off for walk-in customers<br/>
    3PM cut-off for item pick-up and return within Metro Manila<br/>
    <br/><br/>
    *Item pick-up and return within Metro Manila and serviceable areas in Rizal and Cavite are available.<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 21 August 2021, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the announcement of the national government placing NCR under MECQ starting August 21 to 31, please be informed of our adjusted business hours for the duration of this quarantine period.
    <br/><br/>
    8AM to 5PM - Monday to Saturday
    <br/><br/>
    Cut-off notice:<br/>
    4PM cut-off for walk-in customers<br/>
    3PM cut-off for item pick-up and return within Metro Manila<br/>
    <br/><br/>
    *Item pick-up and return within Metro Manila and serviceable areas in Rizal and Cavite are available.<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 4 August 2021, 12:00 NN`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the announcement of the national government placing NCR+ under ECQ starting August 6 to August 20, please be informed of our adjusted business hours starting August 6 until further notice:
    <br/><br/>
    8AM to 5PM - Monday to Saturday
    <br/><br/>
    Cut-off notice:<br/>
    4PM cut-off for walk-in customers<br/>
    3PM cut-off for item pick-up and return within Metro Manila<br/>
    <br/><br/>
    *Item pick-up and return within Cavite and Rizal areas are suspended during the ECQ period.<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 25 July 2021, 8:00 AM`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the recent announcement of MMDA of the new unified curfew hours of 10:00PM - 4:00AM in Metro Manila starting July 25, through Resolution 21-13 Series of 2021, please be informed of our adjusted operating schedule starting July 26, Monday:
    <br/><br/>
    9AM to 6PM - Monday to Saturday
    <br/><br/>
    Cut-off notice:<br/>
    5:00PM cut-off for walk-in customers<br/>
    4:00PM cut-off for item pick-up and return within Metro Manila<br/>
    3:30PM cut-off for item pick-up and return for serviceable areas outside Metro Manila<br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 15 May 2021, 8:00 AM`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the recent announcement placing Metro Manila under General Community Quarantine starting May 15 until May 31, 2021, the PawnHero Pawnshop operations schedule will be:
    <br/><br/>
    9AM to 7PM - Monday to Saturday
    <br/><br/>
    Cut-off notice:<br/>
    6PM cut-off for walk-in customers<br/>
    5:30PM cut-off for item pick-up and return within Metro Manila<br/>
    4:30PM cut-off for item pick-up and return for serviceable areas outside Metro Manila<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 5 May 2021, 9:45 AM`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    If you have received an SMS today, May 5, 2021 at 8:45AM stating that your loan is due today, kindly disregard the SMS. Upon investigation, this was caused by a system glitch that triggered the incorrect SMS send-out. You may refer to your pawn ticket for the correct due date. We would like to sincerely apologize for the inconvenience this may have caused you.  For further clarifications, you may message our loan support team on our official Facebook page. Thank you.
    <br/><br/>
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 1 May 2021, 8:00 AM`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <p>
    In line with the recent announcement of MMDA of the new unified curfew hours of 10PM - 4AM in Metro Manila starting May 1, through Resolution 21-09 Series of 2021, please be informed of our adjusted operating schedule starting May 3, Monday.
    <br/><br/>
    9AM to 7PM - Monday to Saturday
    <br/><br/>
    Cut-off notice:<br/>
    6PM cut-off for walk-in customers<br/>
    5:30PM cut-off for item pick-up and return within Metro Manila<br/>
    4:30PM cut-off for item pick-up and return for serviceable areas outside Metro Manila<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 27 April 2021, 12:01 PM`,
    receiver: `Dear PawnHero customers,`,
    subTitle: `Holiday Operations and System Maintenance`,
    content: `<img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/sysmaintenance-cal-1.png' alt='' styles="width: '100%'"/>
    <p>
    In observance of Labor Day, please be advised that the PawnHero operations will be closed on May 1, 2021. Incidentally, please be advised that there will be a scheduled system enhancement activity on April 30, 2021, 10:00PM until May 2, 2021 11:59PM. During this time, PawnHero customers will not be able to use the services marked with x. <br/><br/>
    All loan maturities and expirations falling on May 1 and 2 will automatically be moved on the next business day (May 3, 2021). Loan renewal and redemption requests must be done on the next business day (May 3, 2021) to avoid accrued interest and penalties. <br/><br/>
    Pick-up schedule booked on April 30 will be accommodated on May 3. Pick-up schedule booked on May 3 will be accommodated on May 4, Tuesday. For the item return schedule, service will resume on May 3, Monday. <br/><br/>
    For your convenience, kindly schedule your transactions accordingly. You may talk to any of our loan support team during business hours through these channels: <br/><br/>
    
    Main hotline: 8828-CASH (2274) <br/>
    Globe hotline: 0917 872 CASH (Calls only) <br/>
    Smart hotline: 0999 222 CASH (Calls only) <br/>
    Message us on Facebook: Official PawnHero FB Page <br/><br/>
    
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    </p>
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 12 April 2021, 10:00 AM`,
    receiver: `Dear PawnHero customers,`,
    content: `In line with the announcement of the national government placing Metro Manila and other cities under MECQ until April 30, 2021, please be informed of the new PawnHero Pawnshop adjusted operations schedule starting April 13, 2021 until further notice. <br/><br/>
    8AM to 5PM - Monday to Sunday<br/><br/>
    4:30 PM Cut-off for Walk-in Customers<br/><br/>
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 5 April 2021, 5:00 PM`,
    receiver: `Dear PawnHero customers,`,
    content: `
    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/system-maintenance-2.png' alt='' styles="width: '100%'"/>
    In observance of Araw ng Kagitingan, please be advised that the PawnHero operations will be closed on April 9, 2021. Incidentally, please be advised that there will be a scheduled system enhancement activity on April 9, 2021, 12:00AM until April 11, 2021 11:59PM. During this time, PawnHero customers will not be able to use the services marked with x. <br/><br/>
    All loan maturities and expirations falling on these dates will automatically be moved on the next business day (April 12, 2021). Loan renewal and redemption requests must be done on the next business day (April 12, 2021) to avoid accrued interest and penalties.<br/><br/>
    Pick-up schedule booked on April 8 will be accommodated on April 12. Pick-up schedule booked on April 12 will be accommodated on April 13, Tuesday. For the item return schedule, service will resume on April 12, Monday.<br/><br/>
    For your convenience, kindly schedule your transactions accordingly. You may talk to any of our loan support team during business hours through these channels:<br/><br/>
    Main hotline: 8828-CASH (2274)<br/>
    Globe hotline: 0917 872 CASH (Calls only)<br/>
    Smart hotline: 0999 222 CASH (Calls only)<br/>
    Message us on Facebook: Official PawnHero FB Page<br/><br/>

    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 4 April 2021, 5:00 PM`,
    receiver: `Dear PawnHero customers,`,
    content: `In line with the IATF resolution No. 106-A placing Metro Manila under Enhanced Community Quarantine from March 29 - April 4, 2021, the PawnHero Pawnshop operations schedule will be: <br/><br/>
    8AM to 4PM - April 5 - 8, 2021<br/>
    3:30 PM Cut-off for Walk-in Customers<br/><br/>
    
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    `,
    sender: `Your Pawnhero Family`,
  },
  {
    title: `Service Advisory – 28 March 2021, 5:00 PM`,
    receiver: `Dear PawnHero customers,`,
    content: `
    In line with the IATF resolution No. 106-A placing Metro Manila under Enhanced Community Quarantine from March 29 - April 4, 2021, the PawnHero Pawnshop operations schedule will be: <br/><br/>
    8AM to 4PM - March 29 - 31, 2021<br/>
    3:30 PM Cut-off for Walk-in Customers<br/><br/>
    New operations schedule is effective starting tomorrow March 29 until March 31, 2021. PawnHero Pawnshop office will be closed on April 1-4 in observance of Holy Week.<br/><br/>
    PawnHero Pawnshop Philippines Inc. is regulated by the Bangko Sentral ng Pilipinas with contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph
    `,
    sender: `Your Pawnhero Family`,
  },
];
