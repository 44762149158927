import {
  postPaymentString,
  postPaynamicParams,
  postPaynamicParamsRenewal,
  postRedeem,
  postRenewalPayment,
  postRenewalPaymentString,
} from '../utils/payment';

export const makePaymentString = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postPaymentString(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRenewalPaymentString = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRenewalPaymentString(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRedeem = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRedeem(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRenewalPayment = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRenewalPayment(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makePaynamicParams = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postPaynamicParams(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makePaynamicParamsRenewal = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postPaynamicParamsRenewal(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
