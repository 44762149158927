import isNil from '@ramda/isnil';
import { isEmpty } from '@ramda/isempty/isEmpty';

const moment = require('moment-business-days');
const passwordValidator = require('password-validator');

export const progressbar = (data) => {
  const { totalNumberStep, steps, defaultStep, formValues } = data;

  let doneStep = 0;
  steps.slice(0, defaultStep).forEach((element) => {
    if (!isNil(element.slug)) {
      ++doneStep;
    }
  });

  if (!isNil(formValues)) {
    doneStep += Object.values(formValues).length;
  }

  return (doneStep / totalNumberStep) * 100;
};

export const pawnDataHelper = (state, action) => {
  const defaultStep = [
    { name: 'Categories', slug: null },
    { name: 'Brand', slug: null },
    { name: 'Model', slug: null },
  ];

  let changemarker = null;

  let _steps = action.pawn.steps;

  _steps = _steps.map((step, key) => {
    if (
      !isNil(state.steps[key]) &&
      !isNil(state.steps[key].slug) &&
      step.slug !== state.steps[key].slug
    ) {
      changemarker = key;
      if (changemarker < defaultStep.length) {
        return defaultStep[key];
      } else {
        return step;
      }
    }
    return step;
  });

  if (!isNil(changemarker) && changemarker < defaultStep.length - 1) {
    _steps = _steps.slice(0, defaultStep.length);
    action.pawn.steps = _steps;
    action.pawn.formValues = null; // remove form values
    action.pawn.attr = null; //reset att fieldss
  }

  return action;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var _re = /pawnhero.ph$/;

  return (
    re.test(String(email).toLowerCase()) &&
    !_re.test(String(email).toLowerCase())
  );
};

const validator = new passwordValidator();
validator
  .is()
  .min(6)
  .has()
  .digits()
  .has()
  .symbols()
  .has()
  .lowercase()
  .has()
  .uppercase()
  .has()
  .not()
  .spaces();

export const validatorPassword = (password) => {
  let _return = true;
  let _error = [];

  if (isNil(password) || isEmpty(password)) {
    _return = false;
    _error.push('is required');
  } else {
    // passport has value
    const passwordValid = validator.validate(password, { list: true });

    if (passwordValid.length) {
      _return = false;

      passwordValid.forEach((err) => {
        switch (err) {
          case 'min':
            _error.push('minimum is 6');
            break;
          case 'spaces':
            _error.push(`should not contains ${err} `);
            break;
          default:
            _error.push(`should contains ${err} `);
        }
      });
    }
  }

  return {
    valid: _return,
    error: _return ? '' : 'Password ' + _error.join(', '),
  };
};

const { REACT_APP_WORKINGDAYS: workingDays = '1-2-3-4-5-6' } = process.env;
// moment bussiness setup
moment.updateLocale('us', {
  workingWeekdays: workingDays.split('-').map((e) => parseInt(e)), // monday to saturday
});
export const momentBusiness = moment;

export const getProductName = (name) => {
  const _name = name.split(' - ');
  return `${_name[_name.length - 2] || ''} ${_name[_name.length - 1]}`;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && !isEmpty(v))
  );
};
