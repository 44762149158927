import styled from 'styled-components';
const { SlideInKeyframes, SlideInLeftKeyframes } = require('./layout.style');

export const SubmitStyled = styled.div`
  animation: ${(props) =>
      props.slideLeft ? SlideInLeftKeyframes : SlideInKeyframes}
    300ms both ease;

  .pawn-activity-body {
    padding: 33px;
    height: auto !important;

    & > p {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      display: block;
      max-width: 400px;
      color: #354f65;
      margin: auto;
    }

    .pawn-sumit-field-wrapper {
      margin: 0 auto 0;
      padding-top: 20px;
      padding-bottom: 22px;
      max-width: 555px;
      width: 100%;

      .field-group {
        padding: 14px 0 11px;
        margin-bottom: 5px;
        margin-top: 5px;

        .field-label-wrapper {
          position: relative;

          label {
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            bottom: 6.5px;
            transition: 0.3s;
            left: 10px;
            color: #8b959c;
          }

          input:focus + label,
          .phone-input.focus + label,
          &.with-value label {
            display: block;
            bottom: 100%;
            left: 0;
            color: #327fbe;
            font-size: 10px;
          }

          .field-content-preview {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5.5px 10px;
            border: 1px solid #dadada;
            border-radius: 5px;
            background: #ebebeb;

            & > p {
              color: #354f65;
              font-size: 14px;
            }

            & > img {
              width: 18px;
              height: 18px;
              cursor: pointer;
            }
          }
        }

        p.err-note {
          display: none;
          position: absolute;
        }

        .field-label-wrapper.invalid + p.err-note,
        .field-label-wrapper.invalid + p.err-note,
        .field-label-wrapper.invalid + p.err-note {
          display: block;
          font-style: italic;
          font-size: 8px;
          line-height: 11px;
          color: #e2574c;
        }

        input[type='text'],
        .input-phone input {
          background: #ffffff;
          border: 1px solid #dadada;
          box-sizing: border-box;
          border-radius: 5px;
          width: 100%;
          display: block;
          margin: auto;
          font-size: 14px;
          padding: 5.5px 10px;
          color: #354f65;
          outline: none;
        }

        input[type='text'].invalid,
        .input-phone.invalid input {
          border: 1px solid #e2574c;
        }

        input::placeholder {
          color: #dadada;
        }

        input:disabled {
          background: #ebebeb;
          border: 1px solid #dadada;
          color: #8b959c;
        }

        .input-phone {
          width: 100%;
          border: 1px solid #dadada;
        }

        &.name-group {
          display: flex;
          max-width: 555px;

          display: flex;
          align-items: flex-end;
          & > div {
            width: 48.64864864864865%;
            max-width: 270px;
          }

          & > div:last-child {
            margin-left: auto;
          }
        }

        &.submit-group {
          margin-top: 20px;

          button {
            font-size: 18px;
            line-height: 18px;
            display: block;
            margin: auto;
            font-weight: 400;
            border: 0;
            background: #ff7019;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
            outline: none;
            color: #ffffff;
          }

          &.disable {
            button {
              pointer-events: none;
              background: #979797;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 15px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
`;
