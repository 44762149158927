import styled from 'styled-components';

export const ContactUsPageStyled = styled.div`
  section:first-child {
    padding: 30px 0;

    & > div {
      /* container */
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-flow: column;
      }
      & > div {
        width: 49%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      & > div:first-child {
        h2, h1 {
          font-weight: 600;
          font-size: 32px;
          color: #327fbe;
        }

        p {
          color: #8c969d;
          font-size: 18px;
          margin-top: 20px;

          span {
            color: #327fbe;
            font-weight: 600;
          }
        }

        h4 {
          font-size: 18px;
          color: #354f65;
          font-weight: 600;
          margin-top: 30px;
        }

        div.img-wrapper {
          background: gray;
          padding: 10px;
          max-width: 360px;
          margin-top: 20px;
          font-size: 0;

          @media (max-width: 768px) {
            display: none;
          }

          img {
            width: 100%;
          }
        }
      }

      & > div:last-child {
        font-size: 0;
        @media (max-width: 768px) {
          margin-top: 20px;
        }

        & > iframe {
          width: 100%;
          border: 0;
          height: 100%;
          min-height: 220px;
        }
      }
    }
  }

  section:last-child {
    background: #f2f2f2;
    padding: 30px 0;

    h3 {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #354f65;
    }

    div.form-wrapper {
      margin: 0 -10px;
      padding-top: 20px;

      @media (max-width: 768px) {
        margin: 0;
      }
      /* display: flex;
      justify-content: space-between;
      margin-top: 40px;

      @media (max-width: 768px) {
        flex-flow: column;
      } */

      /* & > div {
        width: 49%;

        &:first-child {
          margin: -14px 0;
        }

        @media (max-width: 768px) {
          width: 100%;
          margin-top: 10px;
        }
      } */

      input {
        background: #ffffff;
        border: 1px solid #dadada;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        display: block;
        margin: auto;
        font-size: 14px;
        padding: 5.5px 10px;
        color: #354f65;
        outline: none;
      }

      textarea {
        width: 100%;
        height: 100%;
        resize: none;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        padding: 10px;
        outline: none;
        font-size: 14px;
        color: #354f65;
      }

      .phone-input {
        width: 100%;
      }

      .form-row {
        width: 100%;
        display: flex;

        @media (max-width: 768px) {
          display: block;
        }
      }

      .field-wrapper {
        display: flex;
        width: 100%;
        margin: 5px 10px;
        padding: 14px 0 11px;

        @media (max-width: 768px) {
          margin: 0;
        }

        textarea {
          width: 100%;
          height: 150px;
          border: 1px solid #e1e6e9;
          resize: none;
          border-radius: 5px;
          outline: none;
          padding: 10px;
        }

        .field {
          display: flex;
          width: 100%;
          position: relative;

          label {
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            bottom: 6.5px;
            transition: 0.3s;
            left: 10px;
            color: #8b959c;
          }

          textarea:not(:focus) + label {
            bottom: 80%;
          }

          .ctm-select {
            width: 100%;
            & > div.ctm__control {
              min-height: unset;
              border: 1px solid #dadada !important;
              &.ctm__control--is-focused {
                border: 1px solid #327fbe !important;
              }
              & > div:first-child {
                padding: 3px 10px;

                & > div {
                  margin: unset;
                  font-size: 14px;
                  color: #354f65;
                  position: static;
                  top: unset;
                  transform: none;
                }
              }
              & > div:last-child {
                & > span {
                  display: none;
                }

                & > div {
                  padding: 3px;
                }
              }
            }

            &.no-val {
              & > div {
                & > div:first-child {
                  & > div:not(.ctm__option) {
                    color: #b2b1b1;
                  }
                }
              }
            }
          }

          .shared-calendar {
            padding: 0 5px;

            input {
              border: none;
            }
          }

          &.with-value label,
          input:focus + label,
          .phone-input.focus + label,
          .ctm-select.focus + label,
          .shared-calendar.focus + label,
          textarea:focus + label {
            display: block;
            bottom: 100% !important;
            left: 0;
            color: #327fbe;
            font-size: 10px;
          }
        }
      }
    }
    button {
      color: #ffffff;
      background: #ff7019;
      border: 1px solid #cb5812;
      border-radius: 15px;
      text-align: center;
      border: 0;
      margin: 20px auto 0;
      display: block;
      outline: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:disabled {
        /* pointer-events: none; */
        color: #cd6a2e;
      }
    }
  }
`;
