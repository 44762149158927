import {
  getSearch,
  getSearchBrandCategory,
  getSearchItem,
} from '../utils/search';

export const fetchSearch = (key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSearch(key)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSearchBrandCategory = (key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSearchBrandCategory(key)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSearchItem = (key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSearchItem(key)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
