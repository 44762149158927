import React from 'react';
import SharedCarousel from '../../components/shared/owlCarousel';

const { WhyPawnStyled } = require('../../styles/home.style');

const WhyPawnCarousel = (props) => {
  const { whyPawnData, whyPawnToggleKeyHandler, whyPawnToggleHandler } = props;
  const options = {
    center: true,
    loop: true,
    dots: true,
    dotsEach: true,
    startPosition: 2, //index
    responsive: {
      0: {
        items: 1,
      },
      375: {
        items: 1,
        stagePadding: 70,
      },
      425: {
        items: 1,
        stagePadding: 80,
      },
      550: {
        items: 1,
        stagePadding: 120,
      },
      500: {
        items: 1,
        stagePadding: 100,
      },
      700: {
        items: 1,
        stagePadding: 200,
      },
      769: {
        items: 3,
        stagePadding: 100,
      },
      1440: {
        stagePadding: 200,
      },
    },
  };

  const ItemGenerator = () => {
    return (
      <>
        {whyPawnData.map((item, index) => {
          return (
            <div
              onClick={() => {
                whyPawnToggleKeyHandler(index);
                whyPawnToggleHandler(true);
              }}
              key={index}
              id={`home_why_pawn_${item.id}`}>
              <div className='img-wrapper'>
                <img src={item.img} alt={item.title} />
              </div>
              <p>{item.title}</p>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <WhyPawnStyled>
      <div className='swish'>
        <img
          className='desktop'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish.svg'
          alt=''
        />
        <img
          className='mobile'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-mobile.svg'
          alt=''
        />
      </div>
      <div>
        <h2> Why pawn with PawnHero? </h2>
        <div>
          <SharedCarousel options={options}>{ItemGenerator()}</SharedCarousel>
        </div>
      </div>
    </WhyPawnStyled>
  );
};

export default WhyPawnCarousel;
