import React from 'react';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <Component />
    </>
  );
};

export default PrivateRoute;
