import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { PhoneInputStyled } from '../../styles/shared.style';

const PhoneField = (props) => {
  const { containerClass } = props;
  const [focus, focusHandler] = useState(false);

  return (
    <PhoneInputStyled
      className={`${containerClass ? containerClass : ''} ${
        focus ? 'focus' : ''
      }`}>
      <PhoneInput
        {...props}
        inputProps={{
          id: props.id,
        }}
        onFocus={() => focusHandler(true)}
        onBlur={() => focusHandler(false)}
      />
    </PhoneInputStyled>
  );
};

export default PhoneField;
