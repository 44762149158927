import React from 'react';
import Layout from '../layout';
import { ContactUsPageStyled } from '../../styles/contact-us.style';
import { ContainerStyled } from '../../styles/layout.style';
import { useState } from 'react';
import PhoneField from '../../components/shared/phone';
import SelectField from '../../components/shared/select';
import { isEmpty } from '@ramda/isempty/isEmpty';
import MyDropzone from '../../components/shared/dropzone';
import inquiryService from '../../../utils/inquiryService';

const ContactUsPage = () => {
  const [formData, formDataHandler] = useState({
    fullname: '',
    mobileNumber: '',
    email: '',
    subject: '',
    message: '',
    files: [],
  });

  const [isError, isErrorHandler] = useState(false);
  const [isLoading, isLoadingHandler] = useState(false);
  const [isSuccess, isSuccessHandler] = useState(false); //null initial

  const fireSubmit = async () => {
    isErrorHandler(false);
    isLoadingHandler(true);
    const {
      files,
      fullname,
      mobileNumber,
      email,
      subject: { value: subjectValue },
      message,
    } = formData;
    let payload = new FormData();

    files.forEach((element) => {
      payload.append('attachments[]', element);
    });

    payload.set('email', email);
    payload.set('name', fullname);
    payload.set('mobile', mobileNumber);
    payload.set('message', message);
    payload.set('subject', subjectValue);

    try {
      const {
        data: { success },
      } = await inquiryService.post(payload);

      if (success) {
        formDataHandler({
          fullname: '',
          mobileNumber: '',
          email: '',
          subject: '',
          message: '',
          files: [],
        });
        isSuccessHandler(true);
        isLoadingHandler(false);
      } else {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        isLoadingHandler(false);
      }
    } catch (err) {
      console.log('err', err);
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
      isLoadingHandler(false);
    }
  };

  return (
    <>
      <Layout>
        <ContactUsPageStyled>
          <section>
            <ContainerStyled>
              <div>
                <h1>Contact us</h1>
                <p>
                  We’d love to assist you with your PawnHero loan journey. You
                  may talk to any of our loan support team during business
                  hours.
                  <br />
                  <br />
                  Main hotline: <span>8828-CASH (2274)</span>
                  <br />
                  <br />
                  Smart hotlines: <br/>
                  <span>0999 222 2274</span>
                  <br />
                  <span>0908 890 5893</span>
                  <br />
                  <span>0999 228 4159</span>
                  <br /><br />
                  Globe hotlines:
                  <br />
                  <span>0917 872 2274</span>
                  <br />
                  <span>0917 111 5893</span>
                  <br />
                  <span>0917 630 1718</span>
                  <br />
                  <span>0917 630 1793</span>
                  <br /><br />
                  Message us on Facebook: <span>PawnHero Pawnshop</span>
                </p>

                <h4>PawnHero Pawnshop Philippines, Inc.</h4>
                <p>
                  7th Floor, Avecshares Center 
                  <br /> 38th Street, 1132 University Parkway North
                  <br /> Bonifacio Global City
                  <br /> Taguig City, Metro Manila
                </p>
              </div>
              <div>
                <iframe
                  title='contact us'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.839248525108!2d121.04968071494265!3d14.551183589833922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397ca2b04c7f4ff%3A0xf6de3d37c2eb7518!2sPawnHero%20Pawnshop%20Philippines!5e0!3m2!1sen!2sph!4v1582179659406!5m2!1sen!2sph'></iframe>
              </div>
            </ContainerStyled>
          </section>

          <section>
            <ContainerStyled
              style={{
                overflow: 'visible',
              }}>
              {!isSuccess ? (
                <>
                  <h3>
                    We’re here to help! Fill out the form below to receive a
                    call from us about your collateral loan application.
                  </h3>
                  <div className='form-wrapper'>
                    <div className='form-row'>
                      <div className='field-wrapper'>
                        <div
                          className={`field ${
                            formData.fullname ? 'with-value' : ''
                          }`}>
                          <input
                            type='text'
                            id='full_name'
                            value={formData.fullname}
                            onChange={(e) => {
                              formDataHandler({
                                ...formData,
                                fullname: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor='full_name'>Full name</label>
                        </div>
                      </div>
                      <div className='field-wrapper'>
                        <div
                          className={`field ${
                            formData.mobileNumber ? 'with-value' : ''
                          }`}>
                          <PhoneField
                            id='mobile_number'
                            value={formData.mobileNumber}
                            onChange={(data) => {
                              formDataHandler({
                                ...formData,
                                mobileNumber: data,
                              });
                            }}
                            country='ph'
                            onlyCountries={['ph']}
                            masks={{ ph: '.... ... ....' }}
                            disableDropdown={true}
                            disableCountryCode={true}
                            placeholder=''
                            inputClass='input-phone'
                            containerClass={`phone-input `}
                          />
                          <label htmlFor='mobile_number'>Mobile number</label>
                        </div>
                      </div>
                    </div>
                    <div className='form-row'></div>
                    <div className='form-row'>
                      <div className='field-wrapper'>
                        <div
                          className={`field ${
                            formData.email ? 'with-value' : ''
                          }`}>
                          <input
                            type='text'
                            id='email'
                            value={formData.email}
                            onChange={(e) => {
                              formDataHandler({
                                ...formData,
                                email: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor='email'>Email address</label>
                        </div>
                      </div>

                      <div className='field-wrapper'>
                        <div
                          className={`field ${
                            formData.subject ? 'with-value' : ''
                          }`}>
                          <SelectField
                            inputId='subject'
                            className={`ctm-select `}
                            classNamePrefix={'ctm'}
                            placeholder={''}
                            value={formData.subject}
                            options={[
                              {
                                value: `General Inquiry`,
                                label: `General Inquiry`,
                              },
                              {
                                value: `Item/s not on list`,
                                label: `Item/s not on list`,
                              },
                              { value: `Advertising`, label: `Advertising` },
                              { value: `Partnerships`, label: `Partnerships` },
                              {
                                value: `Authentication Service`,
                                label: `Authentication Service`,
                              },
                              { value: `Other`, label: `Other` },
                            ]}
                            onChange={(value) => {
                              formDataHandler({
                                ...formData,
                                subject: value,
                              });
                            }}
                          />
                          <label htmlFor='subject'>Subject of concern</label>
                        </div>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='field-wrapper'>
                        <div
                          className={`field text-area ${
                            formData.message ? 'with-value' : ''
                          }`}>
                          <textarea
                            id='message'
                            value={formData.message}
                            onChange={(e) => {
                              formDataHandler({
                                ...formData,
                                message: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor='message'>Message</label>
                        </div>
                      </div>
                      <div className='field-wrapper'>
                        <MyDropzone
                          config={{
                            multiple: false,
                          }}
                          files={formData.files}
                          onFileUpload={(data) => {
                            formDataHandler({
                              ...formData,
                              files: [...formData.files, data],
                            });
                          }}
                          withRemoveBtn={true}
                          onFileRemove={(data) => {
                            const { index } = data;
                            formDataHandler({
                              ...formData,
                              files: formData.files.filter(
                                (f, i) => i !== index
                              ),
                            });
                          }}
                          s3Upload={false}
                        />
                      </div>
                    </div>
                  </div>
                  {isError && <p className='error'>{isError}</p>}
                  <button
                    disabled={
                      isEmpty(formData.fullname) ||
                      isEmpty(formData.mobileNumber) ||
                      isEmpty(formData.email) ||
                      isEmpty(formData.subject) ||
                      isEmpty(formData.message) ||
                      isLoading
                    }
                    onClick={() => {
                      fireSubmit();
                    }}>
                    Submit message
                  </button>
                </>
              ) : (
                <>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
                    alt='checkmark-icon.svg'
                    style={{
                      width: '48px',
                      height: '48px',
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                  <p
                    style={{
                      fontSize: '24px',
                      display: 'block',
                      textAlign: 'center',
                      marginTop: '20px',
                    }}>
                    Your message has been sent!
                  </p>
                  <button
                    style={{
                      background: '#68C552',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                    onClick={(e) => {
                      isSuccessHandler(false);
                    }}>
                    Send another message
                  </button>
                </>
              )}
            </ContainerStyled>
          </section>
        </ContactUsPageStyled>
      </Layout>
    </>
  );
};

export default ContactUsPage;
