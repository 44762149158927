import React from 'react';
import { menus } from '../dashboard/layout';

// utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { makeLogOut } from '../../../actions/auth';
import { logout } from '../../../actions/user';
import { updateDashboard } from '../../../actions/dashboard';
import BubleCount from './bubleCount';

// styles
import { SideNavStyled } from '../../styles/dashboard-sidenav.style';

const SideNav = (props) => {
  const {
    dashboard,
    user: { user = {} },
    doMakeLogOut,
    doLogout,
    doUpdateDashboard,
  } = props;
  const { showDashboard, activeMenu } = dashboard;
  const history = useHistory();

  const logut = () => {
    doMakeLogOut().then((data) => {
      history.push('/login');
      doLogout();
    });
  };

  return (
    <>
      <SideNavStyled className={`${showDashboard ? 'open' : 'close'}`}>
        <div className='sidenav-wrapper'>
          <div className='user-details'>
            <div className='photo-container'>
              <div className='user-profile-wrapper'>
                {user.display_photo ? (
                  <img src={user.display_photo} alt='user-profile' />
                ) : (
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/front-profile-default-ico.svg'
                    alt=''
                  />
                )}
              </div>
            </div>

            <div className='user-data-container'>
              <h1>{user && user.first_name + " " + user.last_name}</h1>
              {/* <p>
                Pawner since {moment(user.inserted_at).format('D MMMM YYYY')}
              </p> */}
              <Link to='/dashboard/account'>Manage your PawnHero account</Link>
            </div>
          </div>
          <nav>
            <ul>
              {menus
                .filter((e) => e.isNaveMenu)
                .map((menu, key) => {
                  return (
                    <li
                      key={key}
                      className={`${activeMenu === key ? 'active' : ''}`}>
                      <div>
                        <img src={menu.image} alt='' />
                        {menu.url ? (
                          <>
                            <Link
                              to={menu.url}
                              onClick={() => {
                                if (window) {
                                  const { innerWidth: width } = window;
                                  if (width <= 768) {
                                    doUpdateDashboard({
                                      showDashboard: false,
                                    });
                                  }
                                }
                              }}>
                              {menu.text}

                              {menu.count && (
                                <>
                                  <BubleCount type={menu.count} />
                                </>
                              )}
                            </Link>
                          </>
                        ) : (
                          <p>{menu.text}</p>
                        )}
                      </div>
                    </li>
                  );
                })}
              <li>
                <div onClick={logut}>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/logout-ico.svg'
                    alt=''
                  />
                  <p>Logout</p>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </SideNavStyled>
    </>
  );
};
const mapStateToProps = pick(['dashboard', 'user']);
const mapDispatchToProps = (dispatch) => ({
  doMakeLogOut: () => dispatch(makeLogOut()),
  doLogout: () => dispatch(logout()), //clean the reducer
  doUpdateDashboard: (dashboard) => dispatch(updateDashboard(dashboard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
