import styled from 'styled-components';
const { SlideInKeyframes, SlideInLeftKeyframes } = require('./layout.style');

export const CategoryStyled = styled.div`
  background: #327fbe;
  animation: ${(props) =>
      props.slideLeft ? SlideInLeftKeyframes : SlideInKeyframes}
    300ms both ease;
  border-radius: 5px;

  .category-item {
    padding-left: 10px;
    position: relative;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background: #6bbd57;

      .category-label-wrapper {
        p {
          color: #fff !important;
        }

        & > img:last-child {
          display: block;
        }
      }
    }

    .category-icon-wrapper {
      width: 32px;
      height: 32px;
      background: #f1f1f2;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .category-label-wrapper {
      padding: 17px 0;
      border-bottom: 1px solid #c7c7cc;
      margin-left: 40px;

      p {
        font-weight: 400;
        font-size: 18px;
        color: #354f65;
        padding-left: 10px;
      }

      img {
        width: 8px;
        height: 13px;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      & > img:last-child {
        display: none;
      }
    }
  }
`;
