import React from 'react';
import Layout from '../layout';
import { ContainerStyled } from '../../styles/layout.style';
import { CashoutPaymentStyled } from '../../styles/cashoutpayment.style';
const CashoutPayments = (props) => {
  return (
    <>
      <Layout>
        <CashoutPaymentStyled>
          <section>
            <ContainerStyled>
              <div>
                <h1 className='d-none'>Cashout & Payments</h1>
                <h2>All you need to know about your PawnHero Pawnshop loan</h2>
                <h3>Pawn and get your money instantly</h3>
                <p>
                  Receive your money in an instant and without the hassle when
                  you apply for a collateral loan. Aside from receiving your
                  money in cash form at the PawnHero Pawnshop office, you may
                  also opt to cash out{' '}
                  <span className='bold'>
                    via bank transfer or any InstaPay and PESONet partner banks
                    and electronic money issuers
                  </span>
                  . It’s fast and secure!
                </p>
              </div>
              <div>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/model-girl-solo-bang-big.png'
                  alt=''
                />
              </div>
            </ContainerStyled>
          </section>
          <section>
            <ContainerStyled>
              <div>
                <h3>How to pay your loan </h3>
                <p>
                  <span>1. Loan Redemption</span>
                  <br />
                  Redeem your loan on or before the maturity date to avoid
                  inconvenience. You may pay via these payment methods:
                  <br />
                  <br />
                  <span>
                    • Over-the-Counter Cash Payment
                    <br />
                    • Security Bank Online
                    <br />
                    • Online Bank Payment
                    <br />
                    • 7-Eleven (7-CONNECT)
                    <br />• Coins.ph
                  </span>
                  <br />
                  <br />
                  <span>2. Loan Renewal</span>
                  <br />
                  Not ready to redeem your loan? You may opt for a loan renewal!
                  All you have to do is pay your interest amount due in full
                  amount to extend your loan for another month. Payment methods
                  for loan renewal are the same with payment methods for loan
                  redemption.
                </p>
              </div>
              <div>
                <h3>Appraisal value and interest rates</h3>
                <p>
                  Your final appraisal value may be higher or lower than the
                  initial appraisal value given depending on the condition of
                  your item which will be determined during the physical
                  inspection process. Please make sure that the description of
                  your item matches its actual and current condition for a
                  smoother transaction and quicker process of your loan
                  application.
                  <br />
                  <br />
                  PawnHero Pawnshop interest rates start as low as 3.5% every
                  month. However, your interest rate may vary due to the
                  following factors: item condition, age, and category. Items in
                  the electronics and gadgets categories may hold higher
                  interest rates since value depreciates faster for these items.
                </p>
                <br />
                <h3>Enjoy these services for FREE! </h3>
                <p>
                  Pick-up and return delivery of loan collateral within Metro
                  Manila: <span>FREE</span>
                  <br />
                  Insurance coverage while in transit: <span>FREE</span>
                  <br />
                  Insurance coverage while in storage: <span>FREE</span>
                  <br />
                  Storage fee: <span>FREE</span>
                </p>
              </div>
            </ContainerStyled>
          </section>
        </CashoutPaymentStyled>
      </Layout>
    </>
  );
};

export default CashoutPayments;
