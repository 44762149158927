import React, { useEffect } from 'react';
import Layout from '../../pages/layout';
import SideNav from './sideNav';

// utils
import pick from 'ramda.pick';
import { connect } from 'react-redux';
import { updateDashboard } from '../../../actions/dashboard';
import { matchPath, useLocation } from 'react-router';

// styles
// import { ContainerStyled } from '../../styles/layout.style';
import { DashboardStyled } from '../../styles/dashboard.style';

export const menus = [
  {
    text: 'My Pawn Tickets',
    image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/tickets-ico.svg',
    url: '/dashboard',
    exact: true,
    isNaveMenu: true,
  },
  {
    text: 'My Address Book',
    image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/my-address-ico.svg',
    url: '/dashboard/address/',
    isNaveMenu: true,
  },
  {
    text: 'Confirm Loan Payment',
    image:
      'https://d1v5w8bodpeh4i.cloudfront.net/assets/confirm-loan-payment-menu-ico.svg',
    url: '/dashboard/confirm-loan-payment',
    isNaveMenu: true,
  },
  {
    text: 'Notifications',
    image:
      'https://d1v5w8bodpeh4i.cloudfront.net/assets/notification-menu-ico.svg',
    url: '/dashboard/notification',
    isNaveMenu: true,
    count: 'notification',
  },
  // {
  //   text: 'Chat Support',
  //   image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/chat-menu-ico.svg',
  //   url: '/dashboard/chat',
  //   isNaveMenu: true,
  //   count: 'socket',
  // },
  {
    text: 'Back to Home',
    image: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/home-menu-ico.svg',
    url: '../',
    isNaveMenu: true,
  },
  {
    text: 'Manage PawnHero Account',
    image:
      'https://d1v5w8bodpeh4i.cloudfront.net/assets/front-user-manage-menu-ico.svg',
    url: '/dashboard/account',
    isNaveMenu: false,
    exact: true,
  },
  {
    text: 'Change Password',
    image:
      'https://d1v5w8bodpeh4i.cloudfront.net/assets/front-user-manage-menu-ico.svg',
    url: '/dashboard/account/credential',
    isNaveMenu: false,
  },
];

const DashboardLayout = (props) => {
  const location = useLocation();

  const { doUpdateDashboard, children, user } = props;

  useEffect(() => {
    const { innerWidth } = window;
    doUpdateDashboard({ showDashboard: innerWidth > 768 });
  }, [doUpdateDashboard]);

  useEffect(() => {
    const { pathname } = location;
    const _currentMenu = menus.findIndex(({ url, exact = false }) => {
      const _match = matchPath(pathname, { path: url, exact });
      return _match ? true : false;
    });

    doUpdateDashboard({ activeMenu: _currentMenu < 0 ? 0 : _currentMenu });
    // eslint-disable-next-line
  }, [location]);

  return (
    <Layout>
      <DashboardStyled>
        {user.mode === 'normal' && <SideNav />}
        <div className={`child-wrapper`}>{children}</div>
      </DashboardStyled>
    </Layout>
  );
};

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateDashboard: (dashboard) => dispatch(updateDashboard(dashboard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
