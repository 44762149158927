import React from 'react';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { Link } from 'react-router-dom';
import isEmpty from '@ramda/isempty';
import isNil from '@ramda/isnil';

// styles
import { PawnSummaryStyled } from '../../styles/pawn.style';
import { Fragment } from 'react';
import { updatePawn } from '../../../actions/pawn';

const PawnSummary = ({ pawn, doUpdatePawn }) => {
  const {
    categoryData,
    brandData,
    productData,
    selectedEntities,
    position,
    showSummary,
    attribute = [],
  } = pawn;

  let selectedHasValue = false;

  if (selectedEntities && !isEmpty(selectedEntities)) {
    const selectedEntitiesKeys = Object.keys(selectedEntities);
    if (selectedEntitiesKeys.length === attribute.length) {
      let cleanSelectedEntities = selectedEntities;

      Object.keys(cleanSelectedEntities).forEach(
        (key) =>
          (isEmpty(cleanSelectedEntities[key]) ||
            isNil(cleanSelectedEntities[key])) &&
          delete cleanSelectedEntities[key]
      );

      if (Object.keys(cleanSelectedEntities).length === attribute.length) {
        selectedHasValue = true;
      } else {
        selectedHasValue = false;
      }
    }
  }
  const steps = [
    {
      title: 'You selected category',
      display: `You selected`,
      displayValue: `${categoryData ? categoryData.category_name : ''}`,
      hasValue: categoryData ? true : false,
      link: `/pawn`,
    },
    {
      title: 'Select the brand',
      display: `You selected`,
      displayValue: `${brandData ? brandData.brand_name : ''}`,
      hasValue: brandData ? true : false,
      link: `/pawn/${categoryData ? categoryData.slug : ''}`,
    },
    {
      title: 'Select the model',
      display: `You selected`,
      displayValue: `${productData ? productData.product_name : ''}`,
      hasValue: productData ? true : false,
      link: `/pawn/${categoryData ? categoryData.slug : ''}/${
        brandData ? brandData.slug : ''
      }`,
    },
    {
      title: 'Select the attributes',
      display: `You selected attributes`,
      displayValue: '',
      hasValue: selectedHasValue || position === 4,
      link: `/pawn/${categoryData ? categoryData.slug : ''}/${
        brandData ? brandData.slug : ''
      }/${productData ? productData.slug : ''}`,
      onClick: () => {
        doUpdatePawn({ position: 3, attrStep: 0 });
      },
    },
    {
      title: 'Submit pawn ticket',
      link: '#',
    },
  ];

  return (
    <PawnSummaryStyled className='floating-summary' showMobile={showSummary}>
      <div className='pawn-summary-wrapper'>
        <div className='header'>
          <p>Pawn Summary</p>
        </div>
        <div className='content'>
          <nav>
            <ul>
              {steps.map((e, i) => {
                return (
                  <Fragment key={i}>
                    <li
                      className={`${e.hasValue ? 'done' : ''} ${
                        position === i ? 'current' : ''
                      }`}>
                      <Link
                        to={e.link}
                        onClick={() => {
                          doUpdatePawn({ showSummary: false });
                          if (e.onClick) {
                            e.onClick();
                          }
                        }}>
                        <p className='order-marker'>{i + 1}</p>
                        <div className='elipse-dynamic'>
                          <p className='step-title'>
                            {/* {e.hasValue ? e.display : e.title} */}
                            {e.hasValue ? (
                              <>
                                {e.display} <span>{e.displayValue}</span>
                              </>
                            ) : (
                              <>{e.title}</>
                            )}
                          </p>
                        </div>
                        <img
                          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pen-edit-circle-ico.svg'
                          alt='edit'
                        />
                      </Link>
                    </li>
                  </Fragment>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </PawnSummaryStyled>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PawnSummary);
