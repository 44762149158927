import styled from 'styled-components';

export const SearchStyled = styled.div`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#2167a0'};
  border-radius: 5px;
  width: 100%;
  div.searchable-dropdown {
    position: relative;
    padding: 5px 10px;
    width: 100%;

    & > img {
      width: 20px;
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    img.loader {
      right: 10px;
      left: unset;
    }

    input {
      width: 100%;
      display: block;
      background: transparent;
      border: 0;
      outline: 0 !important;
      padding-left: 40px;
      font-size: 14px;
      color: ${(props) =>
        props.foregroundColor ? props.foregroundColor : '#fff'};

      &::placeholder {
        color: #5a92c0;
      }
    }

    & > div {
      display: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      position: absolute;
      width: 100%;
      left: 0;
      top: 115%;
      z-index: 9;
      background: #ffffff;
    }

    input:focus + div,
    input:active + div,
    input + div:hover {
      display: block;
    }

    .search-option-wrapper {
      width: inherit;
      background: #ffffff;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;

      a {
        position: relative;
        display: block;
        text-decoration: none;

        div.brand-icon-wrapper {
          background: #354f65;
          width: 32px;
          height: 32px;
          left: 15px;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 50%;
          text-align: center;
          font-size: 14px;
          line-height: 14px;
          justify-content: center;
          display: flex;
          color: #ffffff;
          position: absolute;

          p {
            align-self: center;
          }
        }

        div.brand-icon-label {
          p {
            padding: 17px 0;
            border-bottom: 1px solid #c7c7cc;
            width: 100%;
            margin-left: 56px;
            color: #354f65;
          }

          img {
            position: absolute;
            width: 30px;
            height: 30px;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HomeSearchStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 70%;
    position: relative;
    padding: 5px 10px;
    border: 1px solid #dadada;
    border-radius: 5px;
    display: flex;
    max-width: 327px;

    & > img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      width: 12px;
    }

    input {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      outline: none !important;
      border: 0;
      color: #354f65;
      text-overflow: ellipsis;
      padding-right: 20px;

      &::placeholder {
        color: #dadada;
      }
    }

    input + div {
      height: 240px;
      overflow-y: scroll;
      display: none;
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      z-index: 99;

      & div.option-wrapper {
        border-top: 1px solid #dadada;
        cursor: pointer;
        & > div {
          padding-left: 10px;
          transition: background-color 0.3s;

          &:hover {
            background: #6bbd57;
          }
          & > div {
            text-align: center;
            border-bottom: 1px solid #dadada;
            display: flex;
            align-items: center;
            padding: 5px 0;

            &:hover {
              & p {
                color: #fff !important;
              }
            }

            div.opt-image-wrapper {
              width: 100%;
              max-width: 32px;
              max-height: 32px;
              overflow-x: hidden;
              overflow-y: hidden;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
              }
            }

            p {
              font-size: 18px;
              color: #354f65;
              padding-left: 10px;
              text-align: left;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }

      @media (max-width: 1024px) {
        height: 150px !important;
      }
    }

    input.toggle + div {
      display: block;
    }

    @media (max-width: 1199px) {
      width: 100%;
      max-width: none;
      margin-right: 10px;
    }
  }

  & > button {
    background: #ff7019;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    max-width: 150px;
    width: 100%;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    border: 0;
    color: #ffffff;
    outline: none !important;

    @media (max-width: 768px) {
      margin: 20px auto 0;
      display: block;
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
`;
