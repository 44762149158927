import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// child component
import Layout from '../layout';

// utility
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import { fetchSummaryTicket } from '../../../actions/ticket';
import { useParams } from 'react-router-dom';

const { ContainerStyled } = require('../../styles/layout.style');
const { PawnThankyouStyledV2 } = require('../../styles/pawn-thankyou.style');

const RenewalThankyou = (props) => {
  const { ticketNum } = useParams();
  const { doFetchSummaryTicket } = props;

  // state
  const [loading, loadingHandler] = useState(true);
  // const [ticketData, ticketDataHandler] = useState(null);
  const [user, userHandler] = useState({ firstName: 'Guest' });

  const thankyouDateExpiry =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.thankyouDateExpiry
      ? props.location.state.thankyouDateExpiry
      : false;

  const now = moment().tz(timezone).unix();

  const history = useHistory();

  useEffect(() => {
    const _test = thankyouDateExpiry > now;
    if (!_test) {
      history.push('/guest');
    } else {
      loadingHandler(true);
      doFetchSummaryTicket({ ticketNum })
        .then(({ data }) => {
          // ticketDataHandler(data);
          if (data.user) {
            userHandler(data.user);
          }
          loadingHandler(false);
        })
        .catch((err) => {
          console.log('err', err);
          loadingHandler(false);
        });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <PawnThankyouStyledV2>
        <section className='banner'>
          <ContainerStyled>
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/renewal-request-thankyou.svg'
              alt='thank you'
            />
            {loading ? (
              <>
                <p>loading...</p>
              </>
            ) : (
              <>
                <h2>Thank you, {user.firstName}!</h2>
                <h3>We are now processing your loan renewal request.</h3>

                <p className='notes' style={{ marginTop: '20px' }}>
                  If you need assistance, please do not hesitate to contact our
                  Customer Service Support through our PawnHero Customer Hotline
                  at 8828-2274, or send us a message here.
                </p>
                <p className='notes'>
                  Our customer service representatives are available from Monday
                  to Friday, at 9:00 AM to 7:00 PM.
                </p>

                <div
                  className='cta-wrapper'
                  style={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Link to='/' className='btn'>
                    Back to Home
                  </Link>
                  <Link to='/contact-us' style={{ marginTop: '10px' }}>
                    Contact us here
                  </Link>
                </div>
              </>
            )}
          </ContainerStyled>
        </section>
      </PawnThankyouStyledV2>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
});

export default connect(null, mapDispatchToProps)(RenewalThankyou);
