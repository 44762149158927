import {
  getProvinces,
  getCities,
  getBarangays,
  getProvinceCities,
  getCityBarangays,
  postUserLocation,
} from '../utils/location';

export const fetchProvinces = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProvinces()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCities = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCities()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchBarangays = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getBarangays()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProvinceCities = (province) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProvinceCities(province)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCityBarangays = (city) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCityBarangays(city)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUserLocation = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUserLocation(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
