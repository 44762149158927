import React, { useState } from 'react';

import arial from './fonts/Arial.ttf';
import arialBold from './fonts/Arial-bold.ttf';

import logo from './image/logo.png';
import tel from './image/tel.png';
import info1 from './image/1.png';
import info2 from './image/2.png';
import info3 from './image/3.png';
import info4 from './image/4.png';
import checkbox from './image/check-box.png';
import box from './image/box.png';
import sign from './image/sign.png';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from '@react-pdf/renderer';

import { useParams } from 'react-router-dom';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import ModalLoader from '../loader/modalLoader';
import numeral from 'numeral';

Font.register({
  family: 'Arial',
  fonts: [
    {
      src: arial,
    },
    {
      src: arialBold,
      fontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 40,
    fontSize: '8pt',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  bold: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: '8pt',
  },
  normal: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    fontSize: '8pt',
  },
  small: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    fontSize: '6pt',
  },
  row: {
    backgroundColor: '#EEEEEE',
    borderBottom: '1pt',
    borderColor: '#AEAEAE',
    flexDirection: 'row',
    display: 'flex',
  },
  rowTitle: {
    display: 'flex',
    backgroundColor: '#252525',
    flexDirection: 'row',
    padding: '3px',
    alignItems: 'center',
  },
  rowHeader: {
    backgroundColor: '#fff',
    width: '12px',
    height: '12px',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cell: { flexGrow: 1, flexBasis: 0, display: 'flex', padding: '2px' },
  cellHighlight: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    padding: '2px',
    backgroundColor: '#fff',
  },
});

const MiniCheckMark = () => {
  return (
    <Image
      style={{
        width: '5px',
        height: '5px',
        display: 'flex',
        alignSelf: 'center',
        marginRight: '3px',
      }}
      src={checkbox}
    />
  );
};

const MiniBox = () => {
  return (
    <Image
      style={{
        width: '5px',
        height: '5px',
        marginRight: '3px',
        display: 'flex',
        alignSelf: 'center',
      }}
      src={box}
    />
  );
};

const PdfComponent = ({ data }) => {
  const {
    customer = {},
    loan_details = {},
    loan_terms = {},
    status,
    location,
  } = data;
  const { ticketNum } = useParams();

  const finalCopy = ['LR', 'LM', 'LX', 'LL', 'LZ', 'WI', 'WA', 'MP'];
  const isFinalCopy = finalCopy.includes(status);

  const customerAddress = [
    customer.address,
    customer.barangay,
    customer.city,
    customer.province,
  ]
    .filter((e) => !isNil(e) && !isEmpty(e) && typeof e !== 'undefined')
    .join(', ');

  const loanDetailAddress = [
    location.address,
    location.barangay,
    location.city,
    location.province,
  ]
    .filter((e) => !isNil(e) && !isEmpty(e) && typeof e !== 'undefined')
    .join(', ');

  const itemName = loan_details.item[0].item_name || '';
  const [loading, loadingHandler] = useState(true);

  return (
    <>
      {loading && (
        <>
          <ModalLoader />
        </>
      )}
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <Document
          title={ticketNum}
          onRender={() => {
            loadingHandler(false);
          }}>
          <Page size='A4' style={styles.page}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 'auto',
                flexWrap: 'nowrap',
              }}>
              <View
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexBasis: 0,
                }}>
                <Image
                  style={{
                    width: '100px',
                    height: '20px',
                  }}
                  src={`https://bwipjs-api.metafloor.com/?bcid=code39&text=${ticketNum}`}
                />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginTop: '5px',
                    flexBasis: 0,
                  }}>
                  <View
                    style={{
                      width: '40px',
                      backgroundColor: '#ecf0f5',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '3px 0',
                    }}>
                    {isFinalCopy ? (
                      <>
                        <Text style={{ ...styles.bold, textAlign: 'center' }}>
                          Final
                        </Text>
                        <Text style={{ ...styles.bold, textAlign: 'center' }}>
                          Copy
                        </Text>
                      </>
                    ) : (
                      <Text style={{ ...styles.bold, textAlign: 'center' }}>
                        Original Copy
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      padding: '5px',
                    }}>
                    <Text>Pawn ticket number</Text>
                    <Text style={{ ...styles.bold, fontSize: '16pt' }}>
                      {ticketNum}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '0 20px',
                }}>
                <View style={{ textAlign: 'center' }}>
                  <Text style={{ ...styles.bold, fontSize: '9pt' }}>
                    PawnHero Pawnshop Philippines, Inc. ("PawnHero")
                  </Text>
                  <Text style={styles.normal}>
                    7th Floor, Avecshares Center, 1132 University Parkway North, 
                    Bonifacio Global City, Taguig City 1634
                  </Text>
                </View>
                <View
                  style={{
                    display: 'table',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                  }}>
                  <Text style={styles.bold}> Business Hours: </Text>
                  <Text style={styles.normal}>
                    Monday to Saturday, 9:00AM - 7:00PM
                  </Text>
                </View>
                <View
                  style={{
                    display: 'table',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    flexGrow: 1,
                    justifyContent: 'center',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    <Text style={styles.bold}>Phone:</Text>
                    <Text style={styles.normal}>02 8828 2274 </Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={styles.bold}>• TIN: </Text>
                    <Text style={styles.normal}> 008 861 350 000</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexBasis: 0,
                  alignItems: 'flex-end',
                }}>
                <Text style={styles.bold}>SN#:{data.sn || ''}</Text>
                <Image style={{ width: '80%' }} src={logo} />
                <Image style={{ width: '70%', marginTop: '5px' }} src={tel} />
              </View>
            </View>
            <View style={{ marginTop: '5px' }}>
              {/* pawnher Details */}

              <View style={styles.rowTitle}>
                <View style={styles.rowHeader}>
                  <Text style={styles.bold}>1</Text>
                </View>
                <Text
                  style={{ ...styles.bold, color: '#fff', marginLeft: '10px' }}>
                  Pawner Details
                </Text>
              </View>
              {/* row-content */}
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Last name</Text>
                </View>
                <View style={styles.cellHighlight}>
                  <Text>{customer.last_name || ''}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.bold}>First name</Text>
                </View>
                <View style={styles.cellHighlight}>
                  <Text>{customer.first_name || ''}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Middle name</Text>
                </View>
                <View style={styles.cellHighlight}>
                  <Text>{customer.middle_name || ''}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Permanent address</Text>
                </View>
                <View style={{ ...styles.cellHighlight, flexGrow: 5.2 }}>
                  <Text>{customerAddress}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Mobile number</Text>
                </View>
                <View style={styles.cellHighlight}>
                  <Text>{customer.mobile || ''}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Email address</Text>
                </View>
                <View style={styles.cellHighlight}>
                  <Text>{customer.email || ''}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: '5px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <View style={{ ...styles.cell, padding: 0, paddingRight: '2px' }}>
                {/* Loan terms */}
                <View style={styles.rowTitle}>
                  <View style={styles.rowHeader}>
                    <Text style={styles.bold}>2</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.bold,
                      color: '#fff',
                      marginLeft: '10px',
                    }}>
                    Loan terms
                  </Text>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Date loan granted*</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{loan_terms.start_date || ''}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Maturity date*</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{loan_terms.maturity_date}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Cashout method</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{loan_terms.payment_method || ''}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Expiry date of redemption*</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{loan_terms.expired_date || ''}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Effective interest rate</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{loan_terms.effective_ir}% per month</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Pawn article pickup date</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{loan_terms.preferred_date || ''}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>
                      Pawn article's place of pickup/delivery
                    </Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{loanDetailAddress}</Text>
                  </View>
                </View>
              </View>
              <View style={{ ...styles.cell, padding: 0, paddingLeft: '2px' }}>
                {/* Loan details (in Php */}
                <View style={styles.rowTitle}>
                  <View style={styles.rowHeader}>
                    <Text style={styles.bold}>3</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.bold,
                      color: '#fff',
                      marginLeft: '10px',
                    }}>
                    Loan details (in Php)
                  </Text>
                </View>

                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>
                      Description of pawn articles
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={{ ...styles.bold, textAlign: 'right' }}>
                      Appraised value*
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cellHighlight}>
                    <Text>{itemName}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Loan principal </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={{ textAlign: 'right' }}>
                      {numeral(loan_details.loan_amount || 0).format('0,0.00')}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Interest</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={{ textAlign: 'right' }}>
                      {`(${numeral(loan_details.interest_amount || 0).format(
                        '0,0.00'
                      )})`}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Cash bonus promo</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={{ textAlign: 'right' }}>
                      {`${numeral(loan_details.promo_discount || 0).format(
                        '0,0.00'
                      )}`}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Service charge</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={{ textAlign: 'right' }}>
                      {`(${numeral(loan_details.service_charge || 0).format(
                        '0,0.00'
                      )})`}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ ...styles.cell, flexGrow: 2 }}>
                    <Text style={styles.bold}>
                      Documentary stamp tax charge
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={{ textAlign: 'right' }}>
                      {`(${numeral(loan_details.dst_charge || 0).format(
                        '0,0.00'
                      )})`}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Net proceeds (you receive)</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={{ textAlign: 'right' }}>
                      {numeral(loan_details.net_proceeds || 0).format('0,0.00')}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cellHighlight}>
                    <Text>
                      * Subject to actual appraisal, final loan details to be
                      reflected in the system generated final copy of pawn
                      ticket.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {/* info section */}
            <View
              style={{
                backgroundColor: '#eee',
                marginTop: '5px',
                display: 'flex',
                flexDirection: 'row',
                padding: '5px 0',
              }}>
              <View
                style={{
                  flexGrow: 1,
                  flexBasis: 0,
                  padding: '3px',
                }}>
                <Text style={styles.bold}>What's next</Text>
              </View>
              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '3px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}>
                <Image
                  style={{ width: '15px', marginRight: '5px', height: '15px' }}
                  src={info1}
                />
                <Text
                  style={{
                    fontSize: '5pt',
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: 0,
                  }}>
                  You receive a text message/email once your pawn article(s) are
                  delivered to our Appraisal Center. Pawn articles are usually
                  appraised the same day.
                </Text>
              </View>
              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '3px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}>
                <Image
                  style={{ width: '15px', marginRight: '5px', height: '15px' }}
                  src={info2}
                />
                <Text
                  style={{
                    fontSize: '5pt',
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: 0,
                  }}>
                  You will receive another notification once your loan has been
                  processed and the money is transferred.
                </Text>
              </View>
              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '3px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}>
                <Image
                  style={{ width: '15px', marginRight: '5px', height: '15px' }}
                  src={info3}
                />
                <View
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: 0,
                  }}>
                  <Text style={{ fontSize: '5pt' }}>Your loan is due on</Text>
                  <Text style={{ ...styles.bold, fontSize: '5pt' }}>
                    07 Jul 2016.
                  </Text>
                  <Text style={{ fontSize: '5pt' }}>
                    Expect reminder via SMS and email. You can redeem your pawn
                    article(s) any time on or before the loan maturity date
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}>
                <Image
                  style={{ width: '15px', marginRight: '5px', height: '15px' }}
                  src={info4}
                />
                <View
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: 0,
                  }}>
                  <Text style={{ fontSize: '5pt' }}>
                    Need more time? You can request for a loan extension by
                    visiting
                  </Text>
                  <Text style={{ ...styles.bold, fontSize: '5pt' }}>
                    www.pawnhero.ph/pawn/redeem/{ticketNum}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '0 5px',
                }}>
                <View>
                  <Text
                    style={{
                      ...styles.bold,
                      fontSize: '7pt',
                      textAlign: 'center',
                    }}>
                    Got questions?
                  </Text>
                  <Text
                    style={{
                      ...styles.bold,
                      fontSize: '6pt',
                      textAlign: 'center',
                    }}>
                    Visit www.pawnhero.ph/contact-us
                  </Text>
                </View>
              </View>
            </View>

            {/* Id row */}
            {!isFinalCopy && (
              <>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginTop: '5px',
                  }}>
                  <View>
                    <Text style={styles.bold}>ID Presented</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                    }}>
                    <Image
                      style={{
                        width: '5px',
                        marginRight: '5px',
                        height: '5px',
                        alignSelf: 'center',
                      }}
                      src={box}
                    />
                    <Text>SSS</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                    }}>
                    <Image
                      style={{
                        width: '5px',
                        marginRight: '5px',
                        height: '5px',
                        alignSelf: 'center',
                      }}
                      src={box}
                    />
                    <Text>TIN</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                    }}>
                    <Image
                      style={{
                        width: '5px',
                        marginRight: '5px',
                        height: '5px',
                        alignSelf: 'center',
                      }}
                      src={box}
                    />
                    <Text>Driver's License</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                    }}>
                    <Image
                      style={{
                        width: '5px',
                        marginRight: '5px',
                        height: '5px',
                        alignSelf: 'center',
                      }}
                      src={box}
                    />
                    <Text>Passport</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                    }}>
                    <Image
                      style={{
                        width: '5px',
                        marginRight: '5px',
                        height: '5px',
                        alignSelf: 'center',
                      }}
                      src={box}
                    />
                    <Text>NBI Barangay</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                    }}>
                    <Image
                      style={{
                        width: '5px',
                        marginRight: '5px',
                        height: '5px',
                        alignSelf: 'center',
                      }}
                      src={box}
                    />
                    <Text>Barangay</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                    }}>
                    <Image
                      style={{
                        width: '5px',
                        marginRight: '5px',
                        height: '5px',
                        alignSelf: 'center',
                      }}
                      src={box}
                    />
                    <Text>Others (please specify) ___________________</Text>
                  </View>
                </View>

                {/* signature */}
                <View style={{ marginTop: '5px' }}>
                  <Text style={{ fontSize: '7pt', textAlign: 'center' }}>
                    By signing below, I pledge to PawnHero, as security for the
                    loan, the pawn articles described above subject to the Terms
                    and Conditions below
                  </Text>

                  <View
                    style={{
                      marginTop: '5px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    <View
                      style={{
                        textAlign: 'center',
                        flexGrow: 1,
                        flexBasis: 0,
                      }}>
                      {/* pawnher signature */}
                      <Image
                        style={{
                          width: '10px',
                          height: '10px',
                          alignSelf: 'left',
                          marginBottom: '0',
                          marginLeft: '30px',
                        }}
                        src={sign}
                      />
                      <Text>_____________________________________________</Text>
                      <Text style={{ ...styles.bold, marginTop: '5px' }}>
                        Signature or thumbmark of Pawner
                      </Text>
                    </View>

                    <View
                      style={{
                        textAlign: 'center',
                        flexGrow: 1,
                        flexBasis: 0,
                      }}>
                      {/* pawnher agent signature */}
                      <Text style={{ marginTop: '10px' }}>
                        _____________________________________________
                      </Text>
                      <Text style={{ ...styles.bold, marginTop: '5px' }}>
                        Signature of PawnHero's authorized representative
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            )}
            {/* Term and condition */}
            <View style={{ borderTop: '1pt solid #000', marginTop: '5px' }}>
              <Text
                style={{
                  textAlign: 'center',
                  marginTop: '5px',
                  marginBottom: '5px',
                  ...styles.bold,
                }}>
                Terms and conditions of this pawn ticket
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'justify',
                }}>
                <View
                  style={{
                    flexGrow: 1,
                    flexBasis: 0,
                    paddingRight: '5px',
                  }}>
                  <Text
                    style={{
                      fontSize: '6.5pt',
                      display: 'table',
                      flexWrap: 'wrap',
                    }}>
                    1. The pawner hereby accepts PawnHero’s appraisal as proper.
                    2. The pawner agrees on the interest rates imposed in this
                    contract of pledge. In case of dispute, the regular courts
                    of law have the vested power to determine the reasonableness
                    and legality of interest rates. PawnHero hereby agrees not
                    to collect advance interest for a period of more than one
                    (1) year. 3. The service charge is equivalent to one percent
                    (1%) of the principal loan, but shall not exceed five pesos
                    (P5.00). No other charges shall be collected. 4. This loan
                    is renewable for such amount and period as may be agreed
                    upon between PawnHero and the pawner subject to the same
                    requirements for a new loan. 5. Upon maturity of this loan,
                    as indicated on the face of this pawn ticket, the pawner has
                    ninety ({loan_terms.expiration_days}) days from maturity
                    date within which to redeem the pawn by paying the principal
                    loan plus the interest that shall have accrued thereon. 6.
                    The amount of interest due and payable after the maturity
                    date of the loan up to the redemption period shall be
                    computed upon redemption at the rate of provided above based
                    on the sum of the principal loan and interest earned as of
                    the date of maturity. Any additional penalty and/or interest
                    shall also be computed in the same manner. 7. PawnHero shall
                    notify the pawner of any change in its business address/
                    location through: (1) publication in English and in Filipino
                    or in the local dialect in two (2) daily newspapers of
                    general circulation in the city or municipality where the
                    pawnshop is closing business; and (2) posting in English and
                    Filipino or in the local dialect for one (1) month after
                    date of publication in a conspicuous place in the premises
                    to be vacated and to be transferred to. 8. The pawner and
                    PawnHero agree that the notice of auction sale shall be
                    delivered via
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                      }}>
                      <MiniCheckMark />
                      <Text style={{ fontSize: '6.5pt' }}>e-mail, </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                      }}>
                      <MiniCheckMark />
                      <Text style={{ fontSize: '6.5pt' }}>SMS, </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                      }}>
                      <MiniBox />
                      <Text style={{ fontSize: '6.5pt' }}>fax or </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                      }}>
                      <MiniBox />
                      <Text style={{ fontSize: '6.5pt' }}>
                        registered mail or courier at ________________.
                      </Text>
                    </View>
                  </View>
                  <Text style={{ fontSize: '6.5pt' }}>
                    (check box of agreed mode and indicate the email address;
                    mobile phone no.; fax no; or complete residential address,
                    as may be appropriate, in the space provided). In case no
                    mode of notification is agreed upon, the default shall be
                    via ordinary mail. PawnHero shall have the right to sell or
                    dispose of the pawn in public auction if the pawner fails to
                    redeem the pawn within the ninety (
                    {loan_terms.expiration_days}) day grace period. PawnHero
                    shall send the reminder on or before the expiration of the
                    ninety ({loan_terms.expiration_days}) day grace period. 9.
                    The pawner shall advise PawnHero of any change of
                    address/mobile phone number/e-mail address/fax number. 10.
                    This ticket shall be surrendered at maturity date upon
                    payment of the loan. In case of loss or destruction of this
                    ticket, the pawner hereby undertakes to personally present
                    an affidavit to PawnHero before the redemption period
                    expires. PawnHero has two (2) days to decide whether to
                    accept: (1) the affidavit in place of the original pawn
                    ticket; or (2) to issue a substitute pawn ticket, thereby
                    cancelling the original. 11. In case of pre-payment of this
                    loan by pawner, the interest collected in advance shall
                    accrue in full to PawnHero.
                  </Text>
                </View>

                <View
                  style={{
                    flexGrow: 1,
                    flexBasis: 0,
                    paddingLeft: '5px',
                    textAlign: 'justify',
                  }}>
                  <Text style={{ fontSize: '6.5pt' }}>
                    12.The pawner shall not be entitled to the excess of the
                    public auction sale price over the amount of principal,
                    interest and service fee; neither shall PawnHero be entitled
                    to recover the deficiency from the pawner. 13. The pawner
                    declares under the penalty of the Anti-Fencing Law that he
                    is the owner of the property subject of this contract. 14.
                    PawnHero shall exercise reasonable care and caution that an
                    ordinary prudent person would as to his own property over
                    the thing pawned in accordance with Republic Act No. 386
                    (Civil Code of the Philippines), as amended. Accordingly,
                    PawnHero shall insure all pawned items, except those which
                    are kept inside a fireproof vault, in accordance with the
                    pertinent regulations of the Bangko Sentral ng Pilipinas.
                    Claims for restitution by pawners in case of loss,
                    destruction or defect of the pawn due to robbery, fire and
                    other fortuitous event, with or without the fault or
                    negligence of PawnHero, its owner, managing partner,
                    directors and officers are cognizable by the regular courts.
                    15. The pawnee shall not be liable for the loss or damage of
                    the article pawned due to fortuitous events or force
                    majeure. When the loss is due to the fault and/or negligence
                    of the pawnee, the amount of its liability, if any, shall be
                    limited to the appraised value appearing on the face hereof.
                    16. The pawner shall not assign, sell or in any other way
                    alienate the pawn securing this loan without prior written
                    consent of the pawnshop. If the pawnshop agrees, the terms
                    and conditions of this contract remain enforceable. 17.
                    PawnHero reserves the right to have the pawned item checked
                    by professional for authenticity after the loan has been
                    released.
                  </Text>
                  <View>
                    <View
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}>
                      <Text style={{ ...styles.bold, fontSize: '6.5pt' }}>
                        Stipulations:
                      </Text>
                      <Text style={{ fontSize: '6.5pt' }}>
                        1. Venue of all judicial and administrative cases or
                        proceedings and
                      </Text>
                    </View>
                    <Text style={{ fontSize: '6.5pt' }}>
                      other legal incidents arising out of or in connection with
                      this contract shall solely and exclusively be brought
                      before appropriate courts, departments, offices or
                      agencies of the government situated in Taguig. 2. The
                      authorized representative must present valid
                      identification papers. 3. Upon expiration of the
                      redemption period, PawnHero has the right to open the
                      sealed pawn for purposes of public auction. 4. For
                      purposes of computing the amount of interest for pledge
                      loans paid after maturity date, a fraction of the original
                      term of the loan agreed upon as indicated at the face of
                      the pawn ticket shall be considered as one full term. 5.
                      In case this loan is not paid on maturity date, the pawner
                      hereby agrees to pay in addition to accrued interest, two
                      percent (2%) per month of the principal, as liquidated
                      damages. For purposes of computing the amount of
                      liquidated damages, a fraction of the original term of the
                      loan agreed upon as indicated at the face of the pawn
                      ticket shall be considered as one full term.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {/* acknowledge */}
            <View
              style={{
                backgroundColor: '#EEEEEE',
                padding: '5px',
                marginTop: '10px',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
              }}>
              <View
                style={{
                  flexGrow: 1,
                  flexBasis: 0,
                  display: 'flex',
                  padding: '5px',
                }}>
                <Text style={{ fontSize: '6pt' }}>
                  When you redeem your pawn article(s), sign on the right side
                  of this section, and return this copy to the authorized
                  PawnHero representative
                </Text>
              </View>
              <View
                style={{
                  flexGrow: 1,
                  flexBasis: 0,
                  display: 'flex',
                  padding: '5px',
                }}>
                <Text style={{ fontSize: '6pt' }}>
                  I have received the article(s) in the same condition when
                  pawned and redeemed.
                </Text>
              </View>
              <View
                style={{
                  flexGrow: 1,
                  flexBasis: 0,
                  display: 'flex',
                  padding: '5px',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}>
                <Image
                  style={{
                    width: '10px',
                    height: '10px',
                    alignSelf: 'left',
                    marginBottom: '0',
                  }}
                  src={sign}
                />
                <Text>_____________________________________</Text>
                <Text
                  style={{
                    fontSize: '8pt',
                    ...styles.bold,
                  }}>
                  Signature or thumbmark of Pawner/Date
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default PdfComponent;
