import React, { useState } from 'react';

const TermsAndConditions = () => {
    
    const [learnMore, learnMoreHandler] = useState(false);

    return (
        <>
            <div id="terms" className="section" >
                <h2 className="section-title">Terms and Conditions</h2>
                <p className='section-text'>
                    <ul className="section-list list-decimal">
                        <li>Pawner hereby accepts PawnHero’s appraisal as proper. </li>
                        <li>Pawner agrees on the interest rates imposed in this contract of pledge. In case of dispute, the regular courts of law have the vested power to determine the reasonableness and legality of interest rates. PawnHero hereby agrees not to collect advance interest for a period of more than one (1) year. </li>
                        <li>Service charge is equivalent to one percent (1%) of the principal loan, but shall not exceed five pesos (P5.00). No other charges shall be collected.</li>
                        <li>This loan is renewable for such amount and period as may be agreed upon between PawnHero and the pawner subject to the same requirements for a new loan.</li>
                        <li>
                            Upon maturity of this loan, as indicated on the face of this pawn ticket, the pawner has thirty (30) days for gadgets as pawn items or ninety (90) days for other pawn items from maturity date within which to redeem the pawn by paying the principal loan plus the interest that shall have accrued thereon.
                        </li>
                        { learnMore && 
                            <>
                                <li>
                                    The amount of interest due and payable after the maturity date of the loan up to the redemption period shall be computed upon redemption at the rate of provided above based on the sum of the principal loan and interest earned as of the date of maturity. Any additional penalty and/or interest shall also be computed in the same manner. 
                                </li>
                                <li>
                                    (2) posting in English and Filipino or in the local dialect for one (1) month after date of PawnHero shall notify the pawner of any change in its business address/location through: (1) publication in English and in Filipino or in the local dialect in two (2) daily newspapers of general circulation in the city or municipality where the pawnshop is closing business; and publication in a conspicuous place in the premises to be vacated and to be transferred to. 
                                </li>
                                <li>
                                    The pawner and PawnHero agree that the notice of auction sale shall be delivered via e-mail, SMS, fax or registered mail or courier at __ (check box of agreed mode and indicate the email address; mobile phone no.; fax no; or complete residential address, as may be appropriate, in the space provided). In case no mode of notification is agreed upon, the default shall be via ordinary mail. PawnHero shall have the right to sell or dispose of the pawn in public auction if the pawner fails to redeem the pawn item within the thirty (30) day grace period for gadgets and ninety (90) day grace period for other pawn items. PawnHero shall send the reminder on or before the expiration of the thirty (30) day grace period for gadgets and ninety (90) day grace period for other items. 
                                </li>
                                <li>
                                    The pawner shall advise PawnHero of any change of address/mobile phone number/e-mail address/fax number.
                                </li>
                                <li>
                                This ticket shall be surrendered at maturity date upon payment of the loan. In case of
                                loss or destruction of this ticket, the pawner hereby undertakes to personally present an
                                affidavit to PawnHero before the redemption period expires. PawnHero has two (2) days
                                to decide whether to accept: (1) the affidavit in place of the original pawn ticket; or (2) to
                                issue a substitute pawn ticket, thereby cancelling the original. 
                                </li>
                                <li>
                                    In case of pre-payment of this loan by pawner, the interest collected in advance shall accrue in full to PawnHero.
                                </li>
                                <li>
                                    The pawner shall not be entitled to the excess of the public auction sale price over
                                    the amount of principal, interest and service fee; neither shall PawnHero be entitled to
                                    recover the deficiency from the pawner.
                                </li>
                                <li>
                                    The pawner declares under the penalty of the Anti-Fencing Law that he is the owner of the property subject of this contract. 
                                </li>
                                <li>
                                    PawnHero shall exercise reasonable care and caution that an ordinary prudent person
                                    would as to his own property over the thing pawned in accordance with Republic Act
                                    No. 386 (Civil Code of the Philippines), as amended. Accordingly, PawnHero shall insure
                                    all pawned items, except those which are kept inside a fireproof vault, in accordance
                                    with the pertinent regulations of the Bangko Sentral ng Pilipinas. Claims for restitution by
                                    pawners in case of loss, destruction or defect of the pawn due to robbery, fire and other
                                    fortuitous event, with or without the fault or negligence of PawnHero, its owner, managing
                                    partner, directors and officers are cognizable by the regular courts. 
                                </li>
                                <li>
                                    The pawnee shall not be liable for the loss or damage of the article pawned due to fortuitous events or force majeure. When the loss is due to the fault and/or negligence of the pawnee, the amount of its liability, if any, shall be limited to the appraised value appearing on the face hereof.
                                </li>
                                <li>
                                    The pawner shall not assign, sell or in any other way alienate the pawn securing this
                                    loan without prior written consent of the pawnshop. If the pawnshop agrees, the terms
                                    and conditions of this contract remain enforceable. 
                                </li>
                                <li>PawnHero reserves the right to have the pawned item checked by professional for authenticity after the loan has been released.</li>
                            </>
                        }
                    </ul>
                </p>
                { learnMore &&
                    <>
                        <h3 className="section-text section-subtitle">Stipulations:</h3>
                        <ul className="section-list list-decimal">
                            <li>
                                Venue of all judicial and administrative cases or proceedings and other legal incidents arising out of or in connection with this contract shall solely and exclusively be brought before appropriate courts, departments, offices or agencies of the government situated in Taguig. 
                            </li>
                            <li>
                                The authorized representative must present valid identification papers. 
                            </li>
                            <li>
                                Upon expiration of the redemption period, PawnHero has the right to open the sealed pawn for purposes of public auction.
                            </li>
                            <li>
                                For purposes of computing the amount of interest for pledge loans paid after maturity date, a fraction of the original term of the loan agreed upon as indicated at the face of the pawn ticket shall be considered as one full term.
                            </li>
                            <li>
                                In case this loan is not paid on maturity date, the pawner hereby agrees to pay in addition to accrued interest, two percent (2%) per month of the principal, as liquidated damages. For purposes of computing the amount of liquidated damages, a fraction of the original term of the loan agreed upon as indicated at the face of the pawn ticket shall be considered as one full term.
                            </li>
                        </ul>
                    </>
                }   
                { !learnMore &&
                    <div className="button-container">
                        <button style={{width: 'auto'}} onClick={() => learnMoreHandler(true)} className="button green">Continue reading</button>
                    </div>
                }
            </div>
        </>
    );

};

export default TermsAndConditions;  