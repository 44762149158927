import styled from 'styled-components';
import { Marque, MarqueSmall } from './layout.style';
const { FadeInKeyframes } = require('./layout.style');

// style
export const HomeStyled = styled.div`
  animation: ${FadeInKeyframes} 500ms both ease;

  /* swish style */
  div.swish {
    & > img {
      &.desktop {
        display: block;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &.mobile {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }
    }
  }
`;

export const SectionStyled = styled.section``;

export const BannerStyled = styled.div`
  // background: url('https://d1v5w8bodpeh4i.cloudfront.net/assets/home-background.png')
  //   0% 0% / 100% 100% fixed;
  background: #DFF1EF;
  display: flex;
  // padding-top: 30px;
  padding: 30px 0;

  @media (max-width: 992px) {
    width: 100%;
  }

  & > div {
    /* contianer */
    display: flex;
    overflow: visible;

    @media (max-width: 992px) {
      flex-flow: column;
      width: 100%;
      justify-content: center;
    }

    & > div {
      width: 50%;
      @media (max-width: 992px) {
        width: 100%;
      }

      &:first-child {
        flex-flow: column;
        justify-content: center;
        display: flex;

        & > h1, h2 {
          color: #354f65;
          font-weight: 400;
          text-align: center;
          margin-top: 30px;

          & > p:first-child {
            font-size: 32px;

            @media (max-width: 1080px) {
              font-size: 30px;
            }

            @media (max-width: 768px) {
              font-size: 28px;
            }

            @media (max-width: 470px) {
              font-size: 20px;
            }
          }

          & > p:last-child {
            font-size: 60px;

            @media (max-width: 1080px) {
              font-size: 55px;
            }

            @media (max-width: 768px) {
              font-size: 50px;
            }

            @media (max-width: 470px) {
              font-size: 33px;
            }

            & > span {
              color: #327fbe;
            }

            & > span:last-child {
              color: #6dbe54;
            }
          }
        }

        & > div.form-wrapper {
          & > img {
            display: none;
            @media (max-width: 992px) {
              margin: 30px auto 0;
              display: block;
              z-index: 1;
              position: relative;
              max-width: 312px;
            }
          }
          & > div {
            background: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            margin-top: 30px;
            padding: 30px;

            @media (max-width: 992px) {
              position: relative;
              z-index: 2;
              margin-top: 0;
            }

            & > p {
              font-weight: 400;
              font-size: 24px;
              color: #354f65;
              text-align: center;

              @media (max-width: 470px) {
                font-size: 16px;
              }
            }

            & > div {
              margin-top: 15px;

              @media (max-width: 470px) {
                margin-top: 0;
              }

              label {
                font-size: 10px;
                color: #354f65;
                font-weight: 400;
                text-align: center;
              }
            }
          }
        }

        & > div.value-preposition-wrapper {
          width: 100%;
          padding: 20px;

          ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: center;

            li {
              align-self: center;
              width: 33.33%;

              img {
                width: 100%;
                display: block;
                margin: auto;
              }

              &:nth-child(1) > img {
                max-width: 112px;
              }

              &:nth-child(2) > img {
                max-width: 155px;
              }
            }
          }

          @media (max-width: 470px) {
            padding: 15px;
          }
        }
      }

      &:last-child {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        // padding-left: 10px;

        @media (max-width: 992px) {
          // display: none;
          display: block;
          text-align: center;
          margin: 25px 0;
        }

        & .text-green { color: #68C552; }
        & .size-32 { font-size: 32px; }
        & .size-18 { font-size: 18px; }
        & .text-blue { color: #327FBE; }
        & .text-blue-dark { color: #354F65; }
        & .text-red { color: #E2574C;}
        & .weight-600 { font-weight: 600; }
        & .text-underline { text-decoration: underline !important; }
        & > img {
          width: 100%;
          align-self: center;
          // max-width: 555px;
          max-width:360px;
          margin: 25px 0; 
        }
      }
    }
  }
`;

export const ActivityStyled = styled.div`
  background: #354f65;
  padding: 5px 0;

  & > div {
    div.desktop-marque {
      display: flex;
      @media (max-width: 768px) {
        display: none;
      }
      p.title {
        font-size: 24px;
        color: #fff;
        white-space: nowrap;
        padding-right: 20px;
      }

      div.marque {
        overflow-y: hidden;
        overflow-x: hidden;

        div.marque-content-wrapper {
          display: inline-block;
          animation: ${Marque} 200s linear infinite;

          p {
            font-style: italic;
            font-size: 24px;
            white-space: nowrap;
            color: #fff;
            @media (max-width: 425px) {
              font-size: 18px;
              line-height: 18px;
            }
          }
        }
      }
    }

    div.mobile-marque {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }

      div.marque {
        overflow-y: hidden;
        overflow-x: hidden;

        div.marque-content-wrapper {
          display: inline-block;
          animation: ${MarqueSmall} 200s linear infinite;

          & > p span:first-child {
            margin-right: 50px;
          }

          p {
            font-style: italic;
            font-size: 18px;
            white-space: nowrap;
            color: #fff;
            @media (max-width: 425px) {
              font-size: 18px;
              line-height: 18px;
            }
            span {
              font-style: normal;
            }

            & > span.spacer:not(:last-of-type) {
              margin-right: 100vw;
            }
          }
        }
      }
    }
  }
`;

export const WhyPawnStyled = styled.div`
  width: 100%;
  background: radial-gradient(
    50% 47.83% at 50% 47.83%,
    #6bb3ef 0%,
    #2269a2 100%
  );
  padding: 100px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 425px) {
    padding: 40px 0;
  }

  div.swish {
    position: absolute;
    top: -1px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 90%;
    pointer-events: none;

    @media (max-width: 425px) {
      height: 70%;
    }

    & > img {
      width: auto;
      height: 100%;
    }
  }

  & > div {
    position: relative;

    & > h2 {
      font-weight: 600;
      font-size: 32px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;

      @media (max-width: 425px) {
        font-size: 25px;
        margin-bottom: 15px;
      }
    }

    & > div {
      div.owl-carousel {
        div.owl-stage {
          div.owl-item {
            & > div {
              display: flex;
              flex-flow: column;
              align-items: center;
              height: 350px;
              justify-content: center;

              div.img-wrapper {
                width: 80%;
                border-radius: 50%;
                overflow-x: hidden;
                overflow-y: hidden;
                transition: 0.3s;
                max-width: 200px;

                @media (max-width: 1024px) {
                  max-width: 150px;
                }

                img {
                  width: 100%;
                }
              }
              p {
                color: #ffffff;
                font-size: 24px;
                font-weight: 600;
                margin-top: 10px;
              }
            }

            &.center {
              & > div {
                div.img-wrapper {
                  max-width: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const HowPawnStyled = styled.div`
  background: #f9f9f9;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 100px 0;
  position: relative;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 425px) {
    padding: 40px 0;
  }

  & > div.swish {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;

    img {
      width: auto;
      height: 100%;
      pointer-events: none;

      &.desktop {
        display: block;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &.mobile {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }
    }
  }
  & > div {
    /* container */
    width: 100%;
    position: relative;

    & > h2 {
      font-weight: 600;
      font-size: 32px;
      color: #327fbe;
      text-align: center;

      @media (max-width: 425px) {
        font-size: 20px;
      }
    }

    & > p {
      color: #8c969d;
      font-size: 18px;
      text-align: center;

      @media (max-width: 425px) {
        font-size: 12px;
      }
    }

    & > div {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        display: block;
        margin-top: 10px;
      }

      & > div {
        width: 50%;

        @media (max-width: 768px) {
          display: block;
          width: 100%;
        }

        &.video-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;

          iframe {
            width: 100%;
            max-width: 430px;
            min-height: 250px;
          }

          @media (max-width: 768px) {
            padding: 0;
          }

          @media (max-width: 425px) {
            display: block;
            margin: auto;

            iframe {
              width: 100%;
              height: 200px;
            }
          }
        }

        &.steps-wrapper {
          padding: 0 10px;

          @media (max-width: 768px) {
            margin-top: 30px;
          }

          ul {
            li {
              display: flex;
              padding: 10px 0;
              align-items: center;

              @media (max-width: 425px) {
                padding: 5px 0;
              }

              & > div.step-icon-wrapper {
                min-width: 72px;

                & > img {
                  width: 100%;
                }

                @media (max-width: 425px) {
                  min-width: 50px;
                  display: flex;
                  align-items: center;
                }
              }

              & > div.text-wrapper {
                display: flex;
                padding: 0 20px;
                align-items: center;

                @media (max-width: 320px) {
                  padding: 0;
                  padding-left: 5px;
                }

                p {
                  color: #8c969d;
                  font-size: 16px;
                  font-weight: 400;

                  @media (max-width: 320px) {
                    font-size: 12px;
                  }

                  span {
                    color: #354f65;
                    display: inline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ItemsWeAcceptStyled = styled.div`
  background: radial-gradient(
    50% 47.83% at 50% 47.83%,
    #6bb3ef 0%,
    #2269a2 100%
  );
  display: flex;
  align-items: center;
  width: 100%;
  padding: 100px 0;
  position: relative;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 425px) {
    padding: 40px 0;
  }

  div.swish {
    position: absolute;

    &.first {
      height: 60%;
      width: auto;
      bottom: 0;
      left: 0;

      @media (max-width: 768px) {
        height: 50%;
        bottom: 10%;
      }
    }

    &.last {
      top: 0;
      right: 0;
      height: 90%;
      width: auto;

      img {
        width: 100%;
      }
      @media (max-width: 768px) {
        height: 40%;
      }
    }

    & > img {
      width: auto;
      height: 100%;
    }
  }

  & > div {
    /* container */
    width: 100%;
    position: relative;

    & > h2 {
      font-weight: 600;
      font-size: 32px;
      text-align: center;
      color: #fff;
    }

    div.items-wrapper {
      display: flex;
      margin-top: 30px;
      flex-wrap: wrap;

      @media (max-width: 880px) {
        max-width: 450px;
        margin: auto;
      }

      & div.items {
        width: 25%;

        div.img-wrapper {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          margin: auto;

          img {
            width: 80%;
          }

          @media (max-width: 480px) {
            width: 150px;
            height: 150px;
          }

          @media (max-width: 375px) {
            width: 125px;
            height: 125px;
          }
        }

        p {
          font-size: 18px;
          color: #fff;
          text-align: center;
          margin-top: 10px;
        }

        @media (max-width: 880px) {
          width: 50%;
          margin-top: 20px;
        }
      }
    }

    a.link-see-more {
      text-decoration: none;
      text-align: center;
      font-size: 18px;
      line-height: 18px;
      color: #ffffff;
      background: #68c552;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      margin: 40px auto 5px;
      display: block;
      width: 280px;
    }
  }
`;

export const FaqStyled = styled.div`
  background: #f9f9f9;
  padding: 100px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 425px) {
    padding: 40px 0;
  }

  div.swish {
    position: absolute;

    &.first {
      height: 100%;
      width: auto;
      bottom: 0;
      left: 0;

      @media (max-width: 768px) {
        top: 0;
        bottom: auto;
        height: 50%;
      }
    }

    &.last {
      bottom: 0;
      right: 0;
      width: auto;
      height: 90%;

      @media (max-width: 768px) {
        height: 40%;
      }
    }

    & > img {
      width: auto;
      height: 100%;
    }
  }

  & > div {
    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #327fbe;
    }

    & > div {
      /* carousel */
      margin-top: 20px;

      div.owl-carousel {
        div.owl-stage {
          display: flex;
          div.owl-item {
            display: flex;
            justify-content: center;

            & > div {
              padding: 10px 20px 20px !important;
            }

            &.center > div {
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }

            & > div.read-more {
              border-radius: 5px;
              width: 100%;
              border-radius: 5px;
              padding: 15px;
              margin-top: 60px;
              background: #354f65;
              display: flex;
              justify-content: center;
              align-items: center;

              & > a {
                display: flex;
                flex-flow: column;
                align-items: center;

                img {
                  width: 48px;
                }

                p {
                  font-size: 24px;
                  line-height: 33px;
                  text-align: center;
                  color: #ffffff;
                  margin-top: 10px;
                }
              }
            }
            & > div {
              width: 100%;
              background: #ffffff;
              margin-top: 60px;
              padding: 10px;
              border-radius: 5px;

              & > img {
                width: 72px;
                height: 72px;
                margin: -51px auto 0;
                display: block;
              }

              & > p:nth-of-type(1) {
                color: #327fbe;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                margin-top: 20px;
              }

              & > p:nth-of-type(2) {
                color: #354f65;
                font-size: 14px;
                line-height: 19px;
                margin-top: 20px;
                text-align: justify;

                a {
                  color: #327fbe;
                  font-weight: 600;
                }

                span {
                  font-weight: 600;
                }

                strong {
                  font-weight: 700;
                }

                span.note {
                  font-weight: 400;
                  font-style: italic;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ShortInfoStyled = styled.div`
  background: #354f65;
  padding: 100px 0 0;
  position: relative;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 425px) {
    padding: 40px 0;
  }

  & > div {
    /* container */
    display: flex;
    justify-content: center;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 931px;

      & > div {
        padding: 5px;
        p:first-child {
          font-weight: 600;
          font-size: 32px;
          line-height: 44px;
          color: #ffffff;

          @media (max-width: 425px) {
            line-height: 22px;
            font-size: 18px;
          }
        }

        p:last-child {
          color: #ffffff;
          font-size: 18px;
          margin-top: 30px;
          @media (max-width: 425px) {
            line-height: 20px;
            font-size: 16px;
          }
        }

        @media (max-width: 768px) {
          text-align: center;
          text-align-last: center;
        }
      }

      & > img {
        width: 346px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
`;

export const DiscountStyled = styled.div`
  background: url('https://d1v5w8bodpeh4i.cloudfront.net/assets/home-background-2.png')
    0% 0% / 100% 100% fixed;
  padding-top: 30px;

  @media (max-width: 768px) {
    padding: 20px 0;
  }

  & > div {
    /* container */
    display: flex;
    font-size: 0;
    justify-content: center;

    & > div {
      display: flex;

      &:first-child {
        max-width: 300px;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &:last-child {
        display: flex;
        padding-left: 50px;
        align-items: center;

        @media (max-width: 768px) {
          padding: 0;
        }

        div {
          button {
            border: 0;
            background: #ff7019;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
            color: #fff;
            display: block;
            margin: 20px auto 0;
            font-size: 18px;
          }

          p {
            margin-top: 60px;
            font-size: 14px;
            line-height: 25px;
            font-style: italic;
            color: #354f65;
            @media (min-width: 769px) {
              white-space: nowrap;
            }

            span {
              color: #e2574c;
            }
          }
        }
      }
    }
  }
`;

export const WhyPawnModalStyled = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: ${(props) => (props.toggle ? 'block' : 'none')};
  top: 0;
  left: 0;
  z-index: 9999;

  & > div {
    display: none;
    align-items: center;
    justify-content: center;
    height: inherit;

    & > div {
      /* container */
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;
      padding: 20px;
      position: relative;

      & > img.btn-close {
        position: absolute;
        opacity: 0.5;
        width: 15px;
        top: 0;
        right: 0;
      }

      & > img:not(.btn-close) {
        width: 150px;
        height: 150px;
        overflow-x: hidden;
        overflow-y: hidden;
        border-radius: 50%;
      }

      & > p {
        margin-top: 20px;
        font-size: 16px;
        color: #fff;
        text-align: center;
      }

      & > a {
        border-radius: 20px;
        border: 0;
        background-color: #ff7019;
        padding: 6px 48px;
        font-weight: 400;
        color: #fff;
        font-size: 16px;
        text-decoration: none;
        margin-top: 15px;
        cursor: pointer;
      }

      & > button {
        color: #fff;
        font-size: 16px;
        background: none;
        outline: none;
        margin-top: 15px;
        border: 0;
        cursor: pointer;
      }
    }
    &.show {
      display: flex;
    }
  }
`;

export const DiscountModalStyled = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  & > div {
    max-width: 620px;
    position: relative;
    padding: 20px 0;

    & > img {
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    h3 {
      font-weight: 600;
      font-size: 32px;
      text-align: center;
      color: #327fbe;
    }

    p {
      font-size: 14px;
      line-height: 19px;
      text-align: justify;
      margin-top: 10px;
    }

    & > p:first-child {
      margin-top: 20px;
    }

    a {
      background: #ff7019;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      width: 150px;
      padding: 10px 0;
      text-align: center;
      display: block;
      margin: 20px auto;
    }
  }
`;

export const HomeServiceAdvisoryStyled = styled.div`
  background: linear-gradient(180deg, #354f65 0%, #182f42 100%),
    linear-gradient(180deg, #327fbe 0%, #2569a2 100%);

   & .service-adivsory {
      justify-content: center;
      & .title {
        font-size: 48px;
      }
      & .text-highligts {
        font-size: 24px !important;
        text-align: center;
      }
   } 
  & > div {
    /* container */
    padding: 40px 0 0;
    display: flex;

    @media (max-width: 768px) {
      padding: 40px 10px 30px;
    }

    & > div {
      display: flex;

      h2 {
        font-family: 'Bebas Neue';
        font-size: 72px;
        color: #d6f0f5;
        text-align: center;
      }
    }

    & > div:first-child {
      width: 60%;
      align-items: center;
      flex-flow: column;
      padding-bottom: 20px;

      h2 + p {
        font-weight: 600;
        font-size: 18px;
        color: #d6f0f5;
      }

      p.text-highligts {
        font-weight: 600;
        font-size: 32px;
        color: #fff;
        margin-top: 10px;
      }

      p.notes {
        font-style: italic;
        font-size: 14px;
        color: #fff;
      }

      a {
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        color: #ffffff;
        background: #68c552;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 15px;
        margin: 40px auto 5px;
        display: block;
        width: 138px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    & > div:last-child {
      width: 40%;
      justify-content: center;

      img {
        width: 100%;
        max-width: 332px;
        align-self: flex-end;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const GcqAdvisoryStyled = styled.div`
  background: linear-gradient(180deg, #327fbe 0%, #2569a2 100%),
    linear-gradient(180deg, #354f65 0%, #182f42 100%);

  & > div {
    /* container */
    display: flex;
    padding: 35px 0;

    @media (max-width: 768px) {
      display: block;
      padding: 0 10px;
    }

    & > div {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
        padding: 20px 0;
      }

      &:first-child {
        h2 {
          font-family: 'Bebas Neue';
          font-size: 48px;
          color: #fff;
          text-align: center;
          width: 100%;
        }

        .board {
          background: #fff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          padding: 25px;
          width: 100%;
          max-width: 480px;
          margin: auto;

          p {
            text-align: center;
            font-size: 16px;
            color: #354f65;
          }

          p.highlight {
            color: #327fbe;
            font-weight: 600;
          }

          p.normal {
            margin-top: 10px;
          }

          p.note {
            font-style: italic;
            margin-top: 10px;
          }
        }
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        p {
          color: #ffffff;
          font-size: 16px;
          text-align: center;
        }

        p.title {
          font-weight: 600;
          font-size: 24px;
        }

        p.note {
          font-style: italic;
          margin-bottom: 30px;
        }

        a.btn {
          background: #ff7019;
          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          border-radius: 15px;
          color: #fff;
          font-size: 18px;
          text-align: center;
          text-decoration: none;
          margin: 10px 0;
        }

        a.link-advisory {
          color: #ffffff;
          margin: 15px 0;
          font-size: 18px;
        }
      }
    }
  }
`;

export const PawnaloPromoStyle = styled.div`
  height: 100%;
  padding: 40px 0;
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );

  &.gold {
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #006b9a 0%,
      #035b82 72.4%,
      #154b79 100%
    );
  }

  & > div {
    /* container */

    div.content-wrapper {
      display: flex;
      justify-content: center;

      & > div.context-wrapper {
        a.promo-banner-link {
          display: block;
          width: fit-content;
          margin: auto;
        }
        img.promo-banner {
          width: 100%;
          max-width: 410px;
          margin: auto;
          display: block;
        }

        div.wrapper {
          margin-top: 20px;

          div.promo-detail-wrapper {
            div.promo-content {
              display: flex;
              justify-content: center;
              & > div {
                display: flex;
                flex-flow: column;

                &:first-child {
                  div.promo-detail-context {
                    & > div {
                      display: flex;
                      flex-flow: column;

                      &.center-elem {
                        padding: 10px 0;

                        p {
                          text-align: center;
                        }

                        @media (min-width: 769px) {
                          padding: 0;
                          display: flex;
                          justify-content: center;
                          padding: 0 20px;

                          &.gold {
                            padding-left: 0;
                            padding-right: 30px;
                          }
                        }
                      }

                      p {
                        font-weight: 600;
                        font-size: 18px;
                        color: #fff;
                        @media (max-width: 375px) {
                          font-size: 20px;
                        }
                      }

                      p.highlight {
                        font-weight: 600;
                        font-size: 48px;
                        line-height: 48px;
                        background: -webkit-linear-gradient(
                          #ffe095,
                          #fede93,
                          #fbd88c,
                          #f5ce81,
                          #edbf70,
                          #e2ac5a,
                          #d5943f,
                          #c97f27,
                          #d38820,
                          #eca00d,
                          #ffb200
                        );
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }

                      p.note {
                        font-size: 14px;

                        span {
                          display: block;
                          font-size: 12px;
                          font-weight: normal;
                        }

                        span.mark {
                          color: #ff7019;
                          display: inline-block;
                        }

                        @media (max-width: 425px) {
                          font-size: 17px;
                        }
                        @media (max-width: 375px) {
                          font-size: 14px;
                        }
                      }
                    }

                    @media (min-width: 769px) {
                      display: flex;
                    }
                  }

                  & > p {
                    font-weight: 600;
                    font-size: 14px;
                    text-align: center;
                    color: #e4eef2;
                    margin-top: 15px;
                  }
                }

                &:last-child {
                  margin: 0 -10px;
                  max-width: 230px;
                  width: 100%;
                  /* mobile iphone */
                  @media (min-width: 769px) {
                    display: none;
                  }
                }
              }
            }

            div.promo-content-cta-wrapper {
              margin-top: 40px;

              div.disclamer-wrapper {
                div.promo-mechanics {
                  max-width: 394px;
                  display: block;
                  margin: auto;
                }

                p {
                  font-weight: 600;
                  font-size: 10px;
                  color: #fff;
                  text-align: center;
                  font-style: italic;

                  span {
                    &.marker-green {
                      color: #68c552;
                    }
                    &.marker-orange {
                      color: #ff7019;
                    }
                  }
                }
              }
              div.cta-wrapper {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                a {
                  background: #68c552;
                  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
                  border-radius: 15px;
                  padding: 5px 20px;
                  color: #ffffff;
                  display: inline-block;
                  margin: auto;
                }
              }

              div.dti-wrapper {
                width: 241px;
                margin: 10px auto 0;

                p {
                  display: block;
                  font-size: 8px;
                  color: #fff;
                  text-align: center;
                }
              }

              div.content-footer {
                display: flex;
                margin-top: 40px;
                justify-content: center;

                & > img {
                  padding: 0 20px;
                }

                img.bsg-img {
                  max-width: 240px;
                  width: 100%;
                }

                img.pioneer-img {
                  max-width: 155px;
                  width: 100%;
                }
              }
            }
          }

          div.add-image {
            /* desktop iphone */
            display: none;

            img.iphone-img {
              width: 100%;
              max-width: 200px;
            }

            img.gold-img {
              width: 100%;
              max-width: 200px;
              margin-top: 20px;
            }

            @media (min-width: 769px) {
              display: block;
            }
          }

          @media (min-width: 769px) {
            display: flex;
            justify-content: center;
            div.promo-detail-wrapper {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
`;

export const PromoCarouselStyle = styled.div`
  position: relative;

  .control {
    position: absolute;
    width: 72px;
    height: 72px;
    margin: auto;
    background: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    & > img {
      width: 72px;
      height: 72px;
    }

    &.next {
      right: 0;
      top: 0;
      bottom: 0;
    }

    &.prev {
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  .owl-carousel {
    position: relative;
    .owl-stage-outer {
      .owl-stage {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
      }
    }

    .owl-dots {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }
`;

export const UnangSanglaStyle = styled.div`

  width: 100%;
  background-color: #FFF2D3;
  background: radial-gradient(50% 48.09% at 50% 48.09%, #FFFFFF 0%, #FBEBC6 100%);
  height: 100%;
  padding: 150px 0 100px 0;

  .unang-sangla-wrapper {
    // background-color: #FFF2D3;
    // background: radial-gradient(50% 48.09% at 50% 48.09%, #FFFFFF 0%, #FBEBC6 100%);
    // height: 100%;
    // min-height: 550px;

    & .unang-sangla-promo-container {
      text-align: center;
      & .promo-image-wrapper {
          > img { 
              height: auto; 
              margin: 0 auto;
              max-width: 600px;
              width: 100%; 
          }
      }
      > p {
          color: #354F65;
      }

      & .promo-detail {
        color: #3A4E63;
        margin-top: 30px;
        > h1 {
          font-size: 48px;
        }
      }

      & .promo-period {
        > p {
          color: #3A4E63;
          &.duration {
            font-weight: 600;
          }
          &.permit {
            font-size: 12px;
          }
        }
      }

      & .promo-partners {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 30px 0;
        & > div {
          margin: 0 15px;
          max-width: 240px !important;
        }

        & .left-item {
          position: absolute;
          left: 5%;
          bottom: 0;
        }
        & .right-item {
          position: absolute;
          right: 5%;
          bottom: 0;
        }

        @media (max-width: 992px) {
          & .right-item {
            display: none;
          }
          & .left-item {
            display: none;
          }
        }
        @media (max-width: 1091px) {
          & .right-item {
            right: 0;
          }
          & .left-item {
            left: 0;
          }
        }
      }
    }

    .button-container {
      text-align: center;
      padding: 30px;
      & .button {
          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          border-radius: 25px;
          width: 100%;
          font-size: 18px;
          font-weight: 400;
          padding: 5px 25px;
          text-align: center;
          border: 0;
          outline: none !important;

          &.orange {
              background: #ff7019;
              color: #ffffff;
          }
          &.green {
              background: #68C552;
              color: #ffffff;
          }
      }
    }

  }
  .top-img {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .bottom-img {
    transform: rotate(180deg);
    position: absolute;
    width: 100%;
    bottom: 0;
  }
`;

export const GoldOfferStyle = styled.div`
  @media (max-width: 768px) {
    margin: 50px 0;
  }
  .top-left-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8;
  }

  .detail {
    margin: 15px 0;
    color: #FFF;
    text-align: center;
    & .unit {
      color: #000;
      background: linear-gradient(90deg, #FFA101 12.22%, #FBC900 107.27%), #C4C4C4;
      border-radius: 50px;
      display: inline-block;
      padding: 15px 50px;
      margin: 30px 0;
      @media (max-width: 380px) {
        display: block;
        padding: 0 10px;
      }
      @media (max-width: 460px) {
        padding: 10px 25px;
      }
      & > h1 {
        font-size: 34px;
      }
    }
  }
  

  .button-container {
    text-align: center;
    padding: 30px;
    & .button {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 25px;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        padding: 5px 25px;
        text-align: center;
        border: 0;
        outline: none !important;

        &.orange {
            background: #ff7019;
            color: #ffffff;
        }
        &.green {
            background: #68C552;
            color: #ffffff;
        }
    }
  }

  .bottom-right-image {
    bottom: 0;
    position: absolute;
    right: 0;
    transform: rotate(180deg);
    z-index: 8;
  }
`;