import React from 'react';
import Layout from '../layout';

// styles
import { ContainerStyled } from '../../styles/layout.style';
import { HowToPawnStyled } from '../../styles/how-to-pawn.style';
import { Link } from 'react-router-dom';

const HowPawnheroWorks = (props) => {
  return (
    <>
      <Layout>
        <HowToPawnStyled>
          <section>
            <ContainerStyled>
              <div>
                <h1>How to easily apply for a loan at PawnHero Pawnshop</h1>
                <p>
                  It’s fast and easy to gain <span>instant cash</span> by
                  pawning with PawnHero Pawnshop. Just follow these{' '}
                  <span>EASY steps</span> and meet your financial needs
                  instantly!
                </p>

                <div className='steps-wrapper'>
                  <ul>
                    <li>
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/how-step1.svg'
                        alt='1'
                      />
                      <p>
                        Go to <Link to='/pawn'>www.pawnhero.ph/pawn</Link> or
                        simply <Link to='/pawn'>click here</Link> to pawn your
                        valued item and create your pawn ticket.
                      </p>
                    </li>
                    <li>
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/how-step2.svg'
                        alt='2'
                      />
                      <p>
                        Fill out the required details of your online pawn ticket
                        then book your item pick-up schedule. Our certified{' '}
                        <span>in-house courier (Pawntrooper)</span> will be at
                        your specified address to pick up your collateral. Enjoy
                        loan approval within <span>1 DAY</span> only after your
                        collateral has been picked up and appraised!
                      </p>
                    </li>
                    <li>
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/how-step3.svg'
                        alt='3'
                      />
                      <p>
                        Once you’ve accepted our final loan offer, you may opt
                        to cash out via{' '}
                        <span>
                          bank transfer or any InstaPay and PESONet partner
                          banks and electronic money issuers
                        </span>
                        . Fast and secure!
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/kuyawithate-action-1.png'
                  alt='model'
                />
              </div>
            </ContainerStyled>
          </section>
          <section>
            <ContainerStyled>
              <div>
                <iframe
                  title='how pawnhero work'
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/P4NQr2ocP9Y'
                  frameBorder='0'
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen={true}
                />
              </div>
              <div>
                <p>Keep your finances secure</p>
                <p>
                  Pawn your assets and enjoy our <strong>1 DAY ONLY</strong>{' '}
                  collateral loan approval<span>*</span> with no loan amount
                  limit. We provide FREE insurance coverage for all your
                  collateral in partnership with Pioneer Insurance Corporation,
                  so you don’t need to worry!
                  <br />
                  <br /> The PawnHero Pawnshop warehouse, where your collateral
                  is securely kept and stored, is 100% safe and secure with 24/7
                  security personnel and advanced security protocols such as
                  CCTVs and emergency alarm systems.
                  <br />
                  <br />
                  <i>
                    <span>*</span>All loan applications will be approved within
                    the day after your item has been picked up.
                  </i>
                </p>
                <Link to='/pawn' className='btn'>
                  Pawn now
                </Link>
              </div>
            </ContainerStyled>
          </section>
        </HowToPawnStyled>
      </Layout>
    </>
  );
};

export default HowPawnheroWorks;
