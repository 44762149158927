import React, { useState, useEffect } from 'react';
import { pick } from 'ramda.pick/pick';
import { connect } from 'react-redux';
import { fetchProductwithBrand } from '../../../actions/product';
import { updatePawn } from '../../../actions/pawn';
import { Link, useParams } from 'react-router-dom';
import { ProductStyled } from '../../styles/product.style';
import { isNil } from '@ramda/isnil/isNil';
import MyContentLoader from '../contentLoader';

const Product = ({ pawn, dofetchProductwithBrand, doUpdatePawn }) => {
  const { slideLeft = false } = pawn;
  const { categorySlug, brandSlug } = useParams();

  // states
  const [products, productshandler] = useState(null);
  const [loading, loadingHandler] = useState(false);
  const [filterProducts, filterProductsHandler] = useState(null);
  const [filterKey, filterKeyHandler] = useState(null);
  const [filterToggle, filterToggleHandler] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      doUpdatePawn({ position: 2, selectedEntities: {}, formValues: {} });
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    loadingHandler(true);
    const data = await dofetchProductwithBrand({
      slug: brandSlug,
      category: categorySlug,
    });
    productshandler(data);
    loadingHandler(false);
  };

  const toDisplayName = (name) => {
    let _name = name.replace(/[^a-zA-Z0-9- ]/g, '').match(/\b\w/g);

    _name = !isNil(_name) ? _name.join('').substring(0, 2).toUpperCase() : '';

    return _name;
  };

  const _displayProducts = filterKey ? filterProducts : products;

  useEffect(() => {
    if (filterKey) {
      const _product = products.filter((product) => {
        const _test = new RegExp(filterKey, 'i').test(product.product_name);
        return _test ? _test : false;
      });

      filterProductsHandler(_product);
    }
  }, [products, filterKey]);

  return (
    <>
      <div className={`pawn-activity-content ${loading ? 'loading' : 'load'}`}>
        <ProductStyled slideLeft={slideLeft}>
          <div className='pawn-activity-header'>
            <Link
              className='pawn-activity-header-nav'
              to={`/pawn/${categorySlug}`}
              id={`pawn_${categorySlug}_${brandSlug}_back_link`}
              onClick={() => {
                doUpdatePawn({ slideLeft: true });
              }}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='go back'
              />
              <p>Back to Brands</p>
            </Link>
            <div className='search-filter-wrapper'>
              {!filterToggle && (
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon.svg'
                  alt=''
                  onClick={() => filterToggleHandler(true)}
                />
              )}

              <div
                className={`search-input-wrapper ${
                  filterToggle ? 'show' : 'hide'
                } `}>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-gray.svg'
                  alt=''
                />

                {filterToggle && (
                  <input
                    type='text'
                    onChange={(e) => filterKeyHandler(e.target.value)}
                    onBlur={() => {
                      filterToggleHandler(filterKey ? true : false);
                    }}
                    placeholder='Try searching directly for model'
                    autoFocus
                  />
                )}
              </div>
            </div>
          </div>
          <div className='pawn-activity-body'>
            {!isNil(_displayProducts) ? (
              _displayProducts.length ? (
                _displayProducts.map((product, key) => {
                  return (
                    <Link
                      to={`/pawn/${product.category_slug}/${product.brand_slug}/${product.product_slug}`}
                      id={`pawn_${product.category_slug}_${product.brand_slug}_${product.product_slug}_link`}
                      key={key}
                      onClick={() => {
                        doUpdatePawn({ slideLeft: false });
                      }}>
                      <div className='product-item '>
                        <div className='product-icon-wrapper'>
                          <p>{toDisplayName(product.product_name)}</p>
                        </div>
                        <div className='product-label-wrapper'>
                          <p>{product.product_name}</p>
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-blue-ico.svg'
                            alt='>'
                          />
                          <img
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-white-ico.svg'
                            alt='>'
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <p className='no-item'>Sorry No Product Available</p>
              )
            ) : (
              <MyContentLoader />
            )}
          </div>
        </ProductStyled>
      </div>

      <div
        className={`filter-search-modal-wrapper ${
          filterToggle ? 'show' : 'hide'
        }`}>
        <div className='modal-header-wrapper'>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
            alt='go back'
            onClick={() => filterToggleHandler(false)}
          />
          <div className='search-wrapper'>
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-blue.svg'
              alt='search-icon'
            />
            {filterToggle && (
              <input
                type='text'
                onChange={(e) => filterKeyHandler(e.target.value)}
                placeholder='Try searching directly for model'
                autoFocus
              />
            )}
          </div>
        </div>
        <div className='modal-body-wrapper'>
          {!isNil(_displayProducts) ? (
            _displayProducts.map((product, key) => {
              return (
                <Link
                  to={`/pawn/${product.category_slug}/${product.brand_slug}/${product.product_slug}`}
                  id={`pawn_${product.category_slug}_${product.brand_slug}_${product.product_slug}_filter_link`}
                  key={key}
                  onClick={() => {
                    doUpdatePawn({ slideLeft: false });
                  }}>
                  <div className='list-item'>
                    <div className='list-icon-wrapper'>
                      <p>{toDisplayName(product.product_name)}</p>
                    </div>
                    <div className='list-label-wrapper'>
                      <p>{product.product_name}</p>
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-blue-ico.svg'
                        alt='>'
                      />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <MyContentLoader />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  dofetchProductwithBrand: (slug) => dispatch(fetchProductwithBrand(slug)),
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
