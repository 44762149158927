import styled from 'styled-components';

export const PawnOnlineStyle = styled.div`
  margin-left: auto;
  width: 30%;
  max-width: 360px;

  .advantage-block {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    .advantage-block-header {
      background: #112434;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 0;

      h2 {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
      }
    }

    .advantage-block-body {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 5px 5px;
      background: #ffffff;
      padding: 15px;

      p {
        color: #354f65;
        font-size: 14px;
      }

      a {
        background: #ff7019;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 15px;
        display: table;
        margin: 15px auto 0;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }

  .accreditation-block {
    margin-top: 14px;
    display: block;
    padding-top: 1px;

    .accreditation-wrapper {
      ul {
        list-style: none;
        li {
          &:nth-child(1) {
            p {
              font-style: italic;
              font-size: 12px;
              text-align: center;
              color: #ffffff;
              padding: 0 20px;
            }
          }
          &:nth-child(2) {
            img {
              width: 100%;
              max-width: 140px;
              margin: 15px auto;
              display: block;
            }
          }

          &:last-child {
            img {
              width: 100%;
              max-width: 154.79px;
              margin: 15px auto;
              display: block;
            }
          }
        }
      }
    }
  }

  .cash-announcement {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 30px;

    .header {
      background: #112434;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 0;

      h2 {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
      }
    }

    .body {
      padding: 15px;
      background: #327fbe;
      border-radius: 0px 0px 5px 5px;

      .announcement-block {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 15px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        p {
          color: #354f65;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }

  .customer-review {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 30px;

    .header {
      background: #112434;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 0;

      h2 {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
      }
    }

    .body {
      padding: 15px;
      background: #6bbd57;
      border-radius: 0px 0px 5px 5px;

      .review-block {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 15px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        img.rating-img {
          max-width: 98px;
        }

        p {
          color: #354f65;
          font-size: 14px;
          margin-top: 10px;
        }

        .title {
          font-weight: 600;
        }

        .review {
          font-style: italic;
        }

        .item {
          margin-top: 10px;
          font-weight: 600;
        }
      }
    }
  }

  &:not(.mobile-show) {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &.mobile-show {
    @media (max-width: 768px) {
      display: block;
      width: 100%;
      max-width: unset;
      margin-top: 30px;
    }
  }
`;
