export const INIT_ADDRESS = 'INIT_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_SINGLE = 'UPDATE_ADDRESS_SINGLE';

export function updateAddress(address) {
  return {
    type: UPDATE_ADDRESS,
    address,
  };
}

export function updateAddressSingle(address) {
  return {
    type: UPDATE_ADDRESS_SINGLE,
    address,
  };
}
