import React, { useState } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { makeForgot } from '../../../actions/auth';
import Layout from '../layout';
import { Link } from 'react-router-dom';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { validateEmail } from '../../../utils/helper';
import ReactModal from '../../components/shared/reactModal';

// styles
const { LoginStyled } = require('../../styles/login.style');
const { ContainerStyled } = require('../../styles/layout.style');

const Forgot = ({ doMakeForgot }) => {
  const [email, emailHandler] = useState(null);
  const [errFields, errFieldsHandler] = useState([]);
  const [err, errHandler] = useState(null);
  const [message, messageHandler] = useState(null);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [_isOpen, _isOpenHandler] = useState(false);
  const [toggle, toggleHandler] = useState(false);

  const forgotHandler = () => {
    messageHandler(null);
    errHandler(null);
    isProcessingHandler(true);
    doMakeForgot({ email })
      .then((response) => {
        errFieldsHandler([]);
        const { success, message = false } = response;

        if (success) {
          _isOpenHandler(true);
        } else {
          const {
            data: { status = null },
          } = response;

          if (status === 'U') {
            toggleHandler(true);
          } else {
            if (message) {
              errFieldsHandler([
                {
                  key: 'email',
                  message,
                },
              ]);
            } else {
              errHandler(
                message ||
                  'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
              );
            }
          }
        }
        isProcessingHandler(false);
      })
      .catch((err) => {
        errFieldsHandler([]);
        isProcessingHandler(false);
        errHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  return (
    <Layout>
      <LoginStyled>
        <ContainerStyled>
          <h1
            style={{
              maxWidth: '450px',
              textAlign: 'center',
              margin: 'auto',
            }}>
            Enter the email address associated with your PawnHero account
          </h1>

          <div className='fields-groups-wrapper'>
            <form
              className='fields-wrapper'
              onSubmit={(e) => {
                e.preventDefault();
                forgotHandler();
              }}>
              <div className='field-group'>
                <div
                  className={`field-label-wrapper ${
                    email == null || isEmpty(email) ? 'no-value' : 'with-value'
                  } ${
                    errFields.find((e) => e.key === 'email') ? 'is-error' : ''
                  } `}>
                  <input
                    id='forgot_form_email'
                    type='text'
                    // placeholder='Email address'
                    value={email ? email : ''}
                    required
                    onChange={(e) => {
                      emailHandler(e.target.value);
                    }}
                  />
                  <label htmlFor='forgot_form_email'>Email address</label>
                </div>
                <p className='err-note error'>
                  {(errFields.length &&
                    errFields.find((e) => e.key === 'email') &&
                    errFields.find((e) => e.key === 'email').message) ||
                    `Please enter your valid email address`}
                </p>
              </div>

              <div className='action-wrapper'>
                <div>
                  {err ? (
                    <p
                      className='error-text'
                      dangerouslySetInnerHTML={{ __html: err }}
                    />
                  ) : (
                    ''
                  )}
                  {message ? (
                    <p dangerouslySetInnerHTML={{ __html: message }} />
                  ) : (
                    ''
                  )}

                  <button
                    type='submit'
                    disabled={isProcessing || !validateEmail(email)}
                    style={{
                      width: 'auto',
                    }}>
                    Reset Password
                  </button>
                  <Link to={'/login'}>Back to Login</Link>
                </div>
              </div>
            </form>
          </div>
        </ContainerStyled>
      </LoginStyled>

      {/* positive modal */}
      <ReactModal
        background={'rgba(0, 0, 0, 0.25)'}
        toggle={_isOpen}
        toggleHandler={_isOpenHandler}
        // withHeader={false}
        contentContainerStyle={{
          background: '#fff',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          maxWidth: '554px',
          width: '100%',
          minHeight: '40vh',
          padding: '15px',
          overflow: 'visible',
        }}>
        <>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
            alt='checkmark-icon.svg'
            style={{
              width: '48px',
              height: '48px',
              display: 'block',
              margin: 'auto',
            }}
          />
          <h4
            style={{
              fontWeight: '600',
              fontSize: '24px',
              color: '#327FBE',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Check your inbox
          </h4>

          <p
            style={{
              color: '#354F6',
              fontSize: '18px',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            We have sent password recovery instructions to your email address
          </p>

          <div
            style={{
              display: 'flex',
            }}>
            <Link
              to='/login'
              className='btn-common'
              style={{
                background: '#68C552',
                color: '#fff',
                textDecoration: 'none',
              }}>
              Back to Login
            </Link>
          </div>
        </>
      </ReactModal>

      {/* negative modal */}
      <ReactModal
        background={'rgba(0, 0, 0, 0.25)'}
        toggle={toggle}
        toggleHandler={toggleHandler}
        // withHeader={false}
        contentContainerStyle={{
          background: '#fff',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          maxWidth: '554px',
          width: '100%',
          minHeight: '40vh',
          padding: '15px',
          overflow: 'visible',
        }}>
        <>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/email-modal-ico.svg'
            alt='email-icon.svg'
            style={{
              width: '48px',
              height: '48px',
              display: 'block',
              margin: 'auto',
            }}
          />
          <h4
            style={{
              fontWeight: '600',
              fontSize: '24px',
              color: '#327FBE',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Your email address exists,
            <br />
            but isn’t verified yet
          </h4>

          <p
            style={{
              color: '#354F6',
              fontSize: '18px',
              textAlign: 'center',
              marginTop: '15px',
            }}>
            Before you can log in to your PawnHero account, please go to Sign Up
            page first and enter your email address in order to create your
            password
          </p>

          <div
            style={{
              display: 'flex',
            }}>
            <Link
              to='/signup'
              className='btn-common'
              style={{
                background: '#68C552',
                color: '#fff',
                textDecoration: 'none',
              }}>
              Go to Sign Up page
            </Link>
          </div>
        </>
      </ReactModal>
    </Layout>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doMakeForgot: (payload) => dispatch(makeForgot(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
