import {
  getProductwithBrand,
  getProductBySlug,
  getProductById,
  getProductDetailBySlug,
  getProductAttributeBySlug,
  getProductAttrEntities,
} from '../utils/product';

export const fetchProductwithBrand = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProductwithBrand(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProductBySlug = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProductBySlug(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProductById = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProductById(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProductDetailBySlug = (slug) => () => {
  return new Promise((resolve, reject) => {
    getProductDetailBySlug(slug)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProductAttributeBySlug = (payload) => () => {
  return new Promise((resolve, reject) => {
    getProductAttributeBySlug(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProductAttrEntities = (payload) => () => {
  return new Promise((resolve, reject) => {
    getProductAttrEntities(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
