import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/category', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCategory = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { id } = payload;

    try {
      const response = await axios.get('/api/category/' + id, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCategoryDetailBySlug = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { slug } = payload;

    try {
      const response = await axios.get('/api/category/detail/' + slug, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
