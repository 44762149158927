export const testimonials = [
  {
    title: 'Fast and friendly employees',
    comment: 'Fast and friendly employees, very nice place, looks safe.',
    rating: 5,
    item: 'Pawned an Oakley Sunglasses',
    price: '₱2,789.20',
  },
  {
    title: 'Successful pawning transaction',
    comment:
      'Customer service is top notch, paying off the loan and the interest is so easy with their online payment scheme and several options, my watch appraisal although it was barely used seems to be just fine. They safely seal the item. All in all it was a good, easy and hassle free transaction from pawning to returning back the item.',
    rating: 4,
    item: 'Pawned a Tudor Prince Oysterdate Luxury Watch',
    price: '₱70,000.00',
  },
  {
    title: 'Personnel are prompt and courteous',
    comment:
      'Personnel are prompt and courteous. Ensure the safety and health protocols. Nice and clean office. Very competitive rates and flexible appraisal value. Good job👍👍👍',
    rating: 5,
    item: 'Pawned an Omega Constellation Luxury Watch',
    price: '₱24,421.40',
  },
  {
    title: 'Very smooth transaction',
    comment: `Very smooth transaction. The staff are very accommodating. I was a little hesitant at first since this was my first with pawnhero but I made the right choice. Thank you Pawnhero, you're a lifesaver!`,
    rating: 5,
    item: 'Pawned an Omega Seamaster Luxury Watch',
    price: '₱39,075.37',
  },
  {
    title: 'Great courier service',
    comment: `Great courier service. Trustworthy!`,
    rating: 5,
    item: 'Pawned a Givenchy Pandora Designer Bag',
    price: '₱5,451.00',
  },
  {
    title: 'Very nice office',
    comment: `The staff are very friendly and their process is very smooth. I was a bit nervous on my way but the staff made me feel comfortable and confident. Keep it up!`,
    rating: 5,
    item: 'Pawned a TechnoMarine Cruise Luxury Watch',
    price: '₱6,000.19',
  },
  {
    title: 'Legit site and pawning service',
    comment: `Pickup is convenient and the turnaround time for assessment of item/s were more than satisfactory. The field agents that pick up your items are trustworthy. I would highly recommend this company.`,
    rating: 5,
    item: 'Pawned a Nikon D7200 Camera',
    price: '₱14,498.35',
  },
  {
    title: 'Highly commended for their services',
    comment: `1 day process only, my item got picked up after lunch and I got the cash after my item was appraised the same day. High appraisal & has the lowest interest rate compared to traditional pawnshop. Thank you Pawnhero!`,
    rating: 5,
    item: 'Pawned a Sony A7 Mark III Camera',
    price: '₱30,865.04',
  },
  {
    title: 'I cant say more',
    comment: `Got my item picked up again, I checked with the customer service why appraisal got low and she walked me through and explained the reason. Satisfied with the service as always and the courier was so mabait (kind).`,
    rating: 5,
    item: 'Pawned a Louis Vuitton Babylone Designer Bag',
    price: '₱14,000.00',
  },
  {
    title: 'First time to transact with them & they…',
    comment: `first time to transact with them & they very responsive to my inquiry..thanks`,
    rating: 5,
    item: 'Pawned a Chanel Le boy Flap Designer Bag',
    price: '₱75,000.00',
  },
];
