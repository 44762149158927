import React from 'react';

const { ContainerStyled } = require('../../styles/layout.style');
const { HowPawnStyled } = require('../../styles/home.style');
const HowPawnCarousel = (props) => {
  return (
    <HowPawnStyled>
      <div className='swish'>
        <img
          className='desktop'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-1.svg'
          alt=''
        />
        <img
          className='mobile'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-1-mobile.svg'
          alt=''
        />
      </div>
      <ContainerStyled>
        <h2>How to pawn your item </h2>
        <p>Need cash immediately? Pawn now in just 3 easy steps!</p>
        <div>
          <div className='video-wrapper'>
            <iframe
              title='how to pawn'
              src='https://www.youtube.com/embed/o2VS5RMA5W8'
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen={true}
            />
          </div>
          <div className='steps-wrapper'>
            <ul>
              <li>
                <div className='step-icon-wrapper'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/how-step1.svg'
                    alt='1'
                  />
                </div>
                <div className='text-wrapper'>
                  <p>
                    Click <span>Pawn Now</span> to pawn your valued item and
                    create your pawn ticket.
                  </p>
                </div>
              </li>
              <li>
                <div className='step-icon-wrapper'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/how-step2.svg'
                    alt='2'
                  />
                </div>
                <div className='text-wrapper'>
                  <p>
                    Fill out the required details of your online pawn ticket
                    then book your item pick-up schedule. Our certified{' '}
                    <span>in-house courier (Pawntrooper)</span> will be at your
                    specified address to pick up your collateral. Enjoy loan
                    approval within <span>1 DAY only</span> after your
                    collateral has been picked up and appraised!
                  </p>
                </div>
              </li>
              <li>
                <div className='step-icon-wrapper'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/how-step3.svg'
                    alt='3'
                  />
                </div>
                <div className='text-wrapper'>
                  <p>
                    Once you’ve accepted our final loan offer, you may opt to
                    cash out{' '}
                    <span>
                      via bank transfer or any InstaPay and PESONet partner
                      banks and electronic money issuers
                    </span>
                    . Fast and secure!
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ContainerStyled>
    </HowPawnStyled>
  );
};

export default HowPawnCarousel;
