import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import {
  fetchProvinces,
  fetchProvinceCities,
  fetchCityBarangays,
  makeUserLocation,
} from '../../../actions/location';
import SelectField from '../shared/select';
import { updateAddressSingle } from '../../../actions/address';
import { useHistory } from 'react-router';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';

const { FormAddressStyled } = require('../../styles/dashboard.style');

const FormAddress = ({
  data,
  mode,
  doFetchProvinces,
  doFetchProvinceCities,
  doFetchCityBarangays,
  doMakeUserLocation,
  doUpdateAddressSingle,
}) => {
  const history = useHistory();

  const [isLoading, isLoadingHandler] = useState(true);
  const [provinceOptions, provinceOptionsHandler] = useState([]);
  const [cityOptions, cityOptionsHandler] = useState([]);
  const [barangayOptions, barangayOptionsHandler] = useState([]);
  const [formValue, formValueHandler] = useState({
    address: '',
    province: {
      label: '',
      value: '',
    },
    city: {
      label: '',
      value: '',
    },
    barangay: {
      label: '',
      value: '',
    },
  });

  const getProvinceOptions = () => {
    return new Promise((resolve, reject) => {
      doFetchProvinces()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject([]);
        });
    });
  };

  const getCityOptions = (provinceID) => {
    return new Promise((resolve, reject) => {
      doFetchProvinceCities({ province_id: provinceID })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject([]);
        });
    });
  };

  const getBarangayOptions = (cityId) => {
    return new Promise((resolve, reject) => {
      doFetchCityBarangays({ city_id: cityId })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject([]);
        });
    });
  };

  useEffect(() => {
    let cancel = false;

    if (data) {
      getProvinceOptions().then((data) => {
        const { provinces } = data;

        const _options = provinces.map((province) => {
          return {
            label: province.name,
            value: province.province_id,
          };
        });

        if (!cancel) {
          provinceOptionsHandler(_options);
        }
      });

      if (mode !== 'new') {
        getCityOptions(data.province_id).then((data) => {
          const { cities } = data;
          const _options = cities.map((city) => {
            return {
              label: city.name,
              value: city.city_id,
            };
          });

          if (!cancel) {
            cityOptionsHandler(_options);
          }
        });

        getBarangayOptions(data.city_id).then((data) => {
          const { barangays } = data;

          const _options = barangays.map((barangay) => {
            return {
              label: barangay.name,
              value: barangay.barangay_id,
            };
          });

          if (!cancel) {
            barangayOptionsHandler(_options);
          }
        });
      }

      if (!cancel) {
        isLoadingHandler(true);
        const {
          address,
          province_id,
          province_name,
          city_name,
          city_id,
          barangay_id,
          barangay_name,
        } = data;

        const _formValue = {
          address: address,
          province: {
            label: province_name,
            value: province_id,
          },
          city: {
            label: city_name,
            value: city_id,
          },
          barangay: {
            label: barangay_name,
            value: barangay_id,
          },
        };

        formValueHandler(_formValue);
        isLoadingHandler(false);
      }
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);

  const updateCityOptions = (id) => {
    cityOptionsHandler([]);
    getCityOptions(id).then((data) => {
      const { cities } = data;
      const _options = cities.map((city) => {
        return {
          label: city.name,
          value: city.city_id,
        };
      });

      cityOptionsHandler(_options);
    });
  };

  const updateBarangayOptions = (id) => {
    barangayOptionsHandler([]);
    getBarangayOptions(id).then((data) => {
      const { barangays } = data;

      const _options = barangays.map((barangay) => {
        return {
          label: barangay.name,
          value: barangay.barangay_id,
        };
      });

      barangayOptionsHandler(_options);
    });
  };

  const fireSubmit = () => {
    const { type } = data;
    const { address, province, city, barangay } = formValue;

    const _payload = {
      type,
      address,
      barangay: barangay.value,
      city: city.value,
      province: province.value,
      zipcode: '',
      landmark: '',
    };

    isLoadingHandler(true);

    doMakeUserLocation(_payload)
      .then((response) => {
        const { success } = response;
        if (success) {
          doUpdateAddressSingle({ ...formValue, type });
          history.push('/dashboard/address');
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
      });
  };
  return (
    <>
      <FormAddressStyled>
        <>
          <div className='form-box'>
            <div>
              <p>
                Edit{' '}
                {data.type === 'P' ? 'Pick-up Address' : 'Permanent Address'}
              </p>
            </div>
            <div>
              <>
                <div className='fields-groups-wrapper'>
                  <div className='fields-wrapper'>
                    <div className='field-group'>
                      <div
                        className={`field-label-wrapper ${
                          formValue.address ? 'with-value' : ''
                        }`}>
                        <input
                          id={`street-address`}
                          type='text'
                          value={formValue.address || ''}
                          onChange={(e) => {
                            formValueHandler({
                              ...formValue,
                              address: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor={`street-address`}>Street address</label>
                      </div>
                      <p className='err-note error'></p>
                    </div>
                    <div className='field-group'>
                      <div
                        className={`field-label-wrapper ${
                          formValue.province ? 'with-value' : ''
                        }`}>
                        <SelectField
                          inputId={`province`}
                          className={`ctm-select`}
                          classNamePrefix={'ctm'}
                          options={provinceOptions}
                          placeholder={''}
                          value={formValue.province}
                          isLoading={isEmpty(provinceOptions)}
                          onChange={(value) => {
                            formValueHandler({
                              ...formValue,
                              province: value,
                              city: null,
                              barangay: null,
                            });
                            updateCityOptions(value.value);
                          }}
                        />
                        <label htmlFor={`province`}>Province</label>
                      </div>
                      <p className='err-note error'></p>
                    </div>
                    <div className='field-group'>
                      <div
                        className={`field-label-wrapper ${
                          formValue.city ? 'with-value' : ''
                        }`}>
                        <SelectField
                          inputId={`city`}
                          className={`ctm-select`}
                          classNamePrefix={'ctm'}
                          options={cityOptions}
                          placeholder={''}
                          value={formValue.city}
                          isLoading={isEmpty(cityOptions)}
                          isDisabled={
                            !formValue.province || !formValue.province.value
                          }
                          onChange={(value) => {
                            formValueHandler({
                              ...formValue,
                              city: value,
                              barangay: null,
                            });
                            updateBarangayOptions(value.value);
                          }}
                        />
                        <label htmlFor={`city`}>City / Municipality</label>
                      </div>
                      <p className='err-note error'></p>
                    </div>
                    <div className='field-group'>
                      <div
                        className={`field-label-wrapper  ${
                          formValue.barangay ? 'with-value' : ''
                        }`}>
                        <SelectField
                          inputId={`barangay`}
                          className={`ctm-select`}
                          classNamePrefix={'ctm'}
                          options={barangayOptions}
                          isLoading={isEmpty(barangayOptions)}
                          isDisabled={!formValue.city || !formValue.city.value}
                          placeholder={''}
                          value={formValue.barangay}
                          onChange={(value) => {
                            formValueHandler({ ...formValue, barangay: value });
                          }}
                        />
                        <label htmlFor={`barangay`}>Barangay</label>
                      </div>
                      <p className='err-note error'></p>
                    </div>
                  </div>
                </div>
              </>
              <button
                onClick={() => fireSubmit()}
                disabled={
                  isNil(formValue.province) ||
                  isNil(formValue.city) ||
                  isNil(formValue.barangay) ||
                  isEmpty(formValue.address) ||
                  isLoading
                }>
                Save changes
              </button>
            </div>
          </div>
        </>
      </FormAddressStyled>
    </>
  );
};

const mapStateToProps = pick(['address']);
const mapDispatchToProps = (dispatch) => ({
  doFetchProvinces: () => dispatch(fetchProvinces()),
  doFetchProvinceCities: (province) => dispatch(fetchProvinceCities(province)),
  doFetchCityBarangays: (city) => dispatch(fetchCityBarangays(city)),
  doMakeUserLocation: (payload) => dispatch(makeUserLocation(payload)),
  doUpdateAddressSingle: (payload) => dispatch(updateAddressSingle(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormAddress);
