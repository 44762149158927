import styled from 'styled-components';
const { SlideInKeyframes, SlideInLeftKeyframes } = require('./layout.style');

export const AcceptanceStyle = styled.div`
  position: relative;
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 30px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 32px;
    color: #fff;
    text-align: center;
  }

  h1 + p {
    font-size: 14px;
    color: #fff;
    text-align: center;

    span {
      color: #68c552;
    }
  }

  .page-content-wrapper {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;

    .page-content {
      width: 67%;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &.done-animate {
    .page-content {
      overflow: unset;
    }
    .container-wrapper {
      overflow: visible;
    }
  }
`;

export const LoanOfferStyled = styled.div`
  .option-wrapper {
    animation: ${(props) =>
        props.slideLeft ? SlideInLeftKeyframes : SlideInKeyframes}
      300ms both ease;

    .option-header {
      background: #327fbe;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 0;

      p {
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        text-align: center;
      }
    }

    .option-list-wrapper {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 5px 5px;
      overflow-x: hidden;

      ul {
        width: 100%;
        list-style: none;

        li {
          display: block;
          padding: 0 10px;
          border: 1px solid #ebebeb;

          &.fetching-offer {
            display: flex;
            justify-content: center;
            p {
              text-align: center;
              font-size: 18px;
              color: #354f65;
              padding: 10px;
            }
          }

          > p.disclaimer-redeem {
            background: #354F65;
            border-radius: 5px;
            color: #FFFFFF;
            font-size: 12px;
            font-style: italic;
            margin: 0 0 10px 0;
            padding: 5px;
            text-align: center;
            width: 100%;
          }

          @media (max-width: 768px) {
            position: relative;
          }
          & > div {
            padding: 20px 0;
          }
          div.list-number {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 768px) {
              position: absolute;
              top: 10px;
              left: 10px;
              width: auto;
            }

            span {
              background: #354f65;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              font-size: 14px;
            }
          }

          &.unang-sangla-li {
            background: linear-gradient(142.08deg, #8DC63F 25.44%, #006838 112.52%);
            border: unset;
            padding: 0;

            & .unang-sangla-promo {
              margin: 0;
              padding: 0;

              & .promo-container {
                display: flex;
                flex-direction: row;
                @media (max-width: 768px) {
                  flex-direction: column;
                }
                > div {
                  color: ##354F65;
                  text-align: center;
                  padding: 15px;
                  align-self: center; 
                  @media (max-width: 768px) {
                    width: 100% !important;
                  }
                  & .note{
                    font-size: 11px;
                    .note-link {
                      color: #FFFFFF; 
                      font-weight: 700;
                    }
                  }
                }
                > div.left { 
                  color: #354F65; 
                  width: 50%; 
                }
                > div.right { 
                  width: 50%; 
                  text-align: left;
                }
                & .promo-detail-wrapper {
                  > h2 {
                    color: #FFFFFF;
                  }
                  > p {
                    color: #FFF;
                    margin: 10px 0;
                  }
                }
                & .promo-image-wrapper {
                  & p.note {
                    font-size: 10px;
                  } 
                }
              }
              
            }
          }

          &.promo-li {
            background: radial-gradient(
              50% 48.09% at 50% 48.09%,
              #006b9a 0%,
              #035b82 72.4%,
              #154b79 100%
            );
            border: unset;

            div.pawnhero-gold-promo {
              display: flex;
              width: 100%;

              & > div {
                &:first-child {
                  width: 40%;
                }

                &:last-child {
                  width: 60%;
                  p {
                    color: #fff;
                    font-size: 14px;

                    &.title {
                      font-weight: bold;
                    }

                    &.note {
                      font-size: 10px;

                      span,
                      a {
                        text-decoration: underline !important;
                        color: #fff;
                      }
                    }
                  }
                }
              }

              @media (max-width: 758px) {
                display: block;

                & > div {
                  width: 100% !important;
                }
              }
            }
          }

          div.list-content-disclamer {
            width: 100%;
            p {
              width: 100%;
              font-style: italic;
              font-weight: normal;
              font-size: 14px;
              text-align: center;
              color: #354f65;
            }
          }

          div.list-content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-flow: row;
            position: relative;

            & > div {
              p.title {
                color: #327fbe;
                font-size: 12px;
                @media (max-width: 425px) {
                  font-size: 10px;
                }
              }
              p.title-promo {
                background: #327FBE;
                -webkit-border-radius: 3px;
                  -moz-border-radius: 3px;
                    border-radius: 3px;
                color: #FFFFFF;
                display: inline-block;
                font-size: 12px;
                padding: 5px;
                @media (max-width: 425px) {
                  font-size: 10px;
                }
              }

              p.expired-flag {
                background: #e2574c;
                font-weight: 600;
                font-size: 8px;
                color: #ffffff;
                display: inline-block;
                padding: 5px;
                border-radius: 3px;
                text-transform: uppercase;
              }

              p.primary-note {
                color: #354f65;
                font-size: 18px;
                font-weight: 600;
                @media (max-width: 425px) {
                  font-size: 16px;
                }
              }

              p.note {
                color: #354f65;
                font-size: 12px;
                font-weight: 600;

                span {
                  display: block;

                  @media (max-width: 425px) {
                    font-size: 10px;
                    display: block;
                  }
                }

                i {
                  font-style: italic;
                  font-size: 10px;
                }
              }

              button {
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                background: #354f65;
                color: #fff;
                font-size: 18px;
                text-align: center;
                border: 0;
                max-width: 180px;
                width: 100%;
                padding: 7px 10px;
                margin: auto;
                display: block;

                &.primary {
                  background: #68c552;
                  color: #ffffff;
                  outline: none;
                }
              }
            }

            & > div:first-child {
              width: 40%;
            }

            &.no-offer > div:first-child {
              width: 70%;
            }
            & > div:nth-child(2) {
              width: 30%;
              text-align: right;
            }

            & > div:last-child {
              width: 30%;
              align-items: center;
              display: flex;
              padding: 10px;

              a {
                background: #ff7019;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                color: #fff;
                text-align: center;
                margin: auto;
              }
            }

            @media (max-width: 1000px) {
              display: block;

              & > div {
                text-align: center !important;
                padding: 10px 0;
                width: 100% !important;
              }
            }
          }

          &.offer-none {
            margin: 10px 0;
            padding: 0 10px;
            border-bottom: 1px solid #8c969d !important;

            & > div.list-number {
              justify-content: left;

              img {
                width: 40px;
              }
            }

            & > div.list-content {
              & > div.content {
                width: 70%;
                padding-right: 10px;
                @media (max-width: 768px) {
                  width: 100%;
                  padding-right: 0;
                }
              }

              & > div.action {
                flex: 1;

                @media (max-width: 768px) {
                  display: block;
                  width: 100%;
                  margin: 10px auto 0;
                  max-width: 200px;
                }
              }

              & > div {
                & > a {
                  outline: none;
                  background: #ff7019;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  border-radius: 15px;
                  font-size: 18px;
                  line-height: 18px;
                  color: #ffffff;
                  display: flex;
                  height: fit-content;
                  align-items: center;
                  justify-content: center;
                  padding: 10px;
                  align-self: center;
                  text-align: center;
                }
              }

              &::after {
                display: none;
              }

              @media (max-width: 768px) {
                display: block;
              }
            }
          }
        }
      }

      div.disclamer-wrapper {
        padding: 15px 10px;

        p {
          font-size: 14px;
          color: #354f65;

          &.title {
            font-weight: 600;
          }

          &.disclamer-note {
            font-size: 14px;
          }
          
        }

        ul {
          font-size: 14px;
          color: #354f65;
          list-style: none;
          margin-bottom: 20px;
          padding-left: 10px;

          li {
            padding: 0;
            display: flex;
            border: 0;

            &::before {
              content: '•';
              font-size: 14px;
              margin-right: 5px;
            }
            p {
              display: inline;
              font-style: italic;
            }
            p.note span.important {
              color: #E2574C;
              font-weight: 600;
              font-style: italic;
            }
          }
        }
      }
    }
  }
`;

export const BookingStyled = styled.div`
  h2 {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }

  .booking-item-wrapper {
    animation: ${(props) =>
        props.slideLeft ? SlideInLeftKeyframes : SlideInKeyframes}
      300ms both ease;
    @media (max-width: 768px) {
      width: 100%;
    }

    .form-header {
      margin-top: 30px;
      background: #327fbe;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 10px;

      & > div {
        display: flex;
        cursor: pointer;

        img {
          width: 12px;
          margin-right: 10px;
        }

        p {
          font-size: 18px;
          color: #fff;
        }
      }
    }

    .form-body {
      padding: 30px 50px;
      border-radius: 0 0 5px 5px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 20px;

      @media (max-width: 768px) {
        padding: 20px;
      }

      .pickup-option-wrapper {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        margin: auto -5px;

        button {
          background: #ebebeb;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          width: 50%;
          margin: 0 5px;
          padding: 10px 0;
          color: #354f65;
          font-size: 18px;
          border: 0;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;

          &.selected {
            background: #354f65;
            color: #ffffff;
          }
          &.bulky {
            margin: auto;
          }

          & > img {
            width: 25px;
            margin-right: 10px;
          }
        }
      }

      p.note {
        font-size: 10px;
        text-align: center;
        color: #8b959c;
        margin-top: 30px;

        span {
          color: #354f65;
          font-weight: 600;
        }
        span.important {
          color: #E2574C;
          font-weight: 600;
          font-style: italic;
        }
      }

      .fields-groups-wrapper {
        h4 {
          margin-top: 30px;
          color: #354f65;
          font-size: 18px;
          text-align: center;
          font-weight: normal;
        }

        .fields-wrapper {
          margin-top: 30px;

          .field-group {
            padding: 14px 0 11px;
            margin-bottom: 5px;
            margin-top: 5px;

            .remarks {
              font-size: 7px;
              color: #354f65;
              font-style: italic;
              margin-top: 5px;
            }

            .field-label-wrapper {
              position: relative;

              label {
                font-size: 14px;
                font-weight: 400;
                position: absolute;
                bottom: 6.5px;
                transition: 0.3s;
                left: 10px;
                color: #8b959c;
              }

              input:focus + label,
              .phone-input.focus + label,
              .ctm-date-picker.focus + label,
              .shared-calendar.focus + label,
              .ctm-select.focus + label,
              &.with-value label {
                display: block;
                bottom: 100%;
                left: 0;
                color: #327fbe;
                font-size: 10px;
              }

              /* ctm-select */
              .ctm-select {
                & > div.ctm__control {
                  min-height: unset;
                  border: 1px solid #dadada !important;
                  &.ctm__control--is-focused {
                    border: 1px solid #327fbe !important;
                  }
                  & > div:first-child {
                    padding: 3px 10px;

                    & > div {
                      margin: unset;
                      font-size: 14px;
                      color: #354f65;
                      position: static;
                      top: unset;
                      transform: none;
                    }
                  }
                  & > div:last-child {
                    & > span {
                      display: none;
                    }

                    & > div {
                      padding: 3px;
                    }
                  }
                }

                &.no-val {
                  & > div {
                    & > div:first-child {
                      & > div:not(.ctm__option) {
                        color: #b2b1b1;
                      }
                    }
                  }
                }
              }

              & > input[type='text'] {
                width: 100%;
                border-radius: 5px;
                border: 1px solid #dadada;
                padding: 5px 10px;
                font-size: 14px;
                outline: none;
                color: #354f65;

                &:disabled {
                  background: #ebebeb;
                  color: #b2b1b1;
                }

                &::placeholder {
                  color: #dadada;
                }
              }

              & > .ctm--is-disabled {
                .ctm__control--is-disabled {
                  background: #ebebeb;
                }
              }
            }
          }

          & > button {
            text-align: center;
            display: block;
            margin: 30px auto 0;
            background: #68c552;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
            border: 0;
            font-size: 18px;
            color: #fff;
            outline: none;

            &.disabled,
            &:disabled {
              /* pointer-events: none; */
              background: #68c552;
              color: #67b252;
            }
          }

          .multi-field {
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            margin: auto -5px;

            .field-group {
              width: 100%;
              margin: 5px;
            }

            @media (max-width: 768px) {
              display: block;
              margin: auto;

              & > .field-group {
                margin: 10px 0 10px !important;
              }
            }
          }
        }
      }
    }
  }
`;
export const CashOutStyled = styled.div`
  p.errMsg {
    font-size: 12px;
    color: #e2574c;
  }

  .cashout-content-wrapper {
    animation: ${(props) =>
        props.slideLeft ? SlideInLeftKeyframes : SlideInKeyframes}
      300ms both ease;

    @media (max-width: 768px) {
      width: 100%;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      color: #fff;
      text-align: center;
    }

    .cashout-form-wrapper {
      .form-header {
        margin-top: 30px;
        background: #327fbe;
        border-radius: 5px 5px 0px 0px;
        padding: 16px 10px;

        & > div {
          display: flex;
          cursor: pointer;

          img {
            width: 12px;
            margin-right: 10px;
          }

          p {
            font-size: 18px;
            color: #fff;
          }
        }
      }

      .form-body {
        padding: 30px 50px;
        border-radius: 0 0 5px 5px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;

        @media (max-width: 768px) {
          padding: 20px;
        }

        @media (max-width: 425px) {
          /* padding: 10px; */
        }

        .cashout-option-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 -10px;

          &.walkin {
            justify-content: center;

            button {
              height: fit-content;

              &.selected {
                background: none !important;
                box-shadow: none !important;

                img {
                  width: 50px;
                  height: unset;
                }
                p {
                  display: none;
                }
              }
            }
          }

          &.pickup {
            justify-content: left;
          }
          button {
            border: 0;
            width: 29%;
            text-align: center;
            background: #ebebeb;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            font-size: 18px;
            color: #354f65;
            outline: none;
            margin: 10px;
            padding: 15px 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: 950px) {
              width: 45%;
            }

            @media (max-width: 768px) {
              width: 30%;
            }

            @media (max-width: 664px) {
              padding: 5px 0;
              height: 50px;
              width: 45%;
            }

            @media (max-width: 425px) {
              width: 43%;
              font-size: 16px;
            }

            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }

            &.selected {
              background: #354f65;
              color: #fff;
            }
          }

          @media (max-width: 425px) {
            justify-content: space-around;
          }

          @media (max-width: 370px) {
            justify-content: center;
          }
        }

        .disclamer-message {
          font-style: italic;
          font-weight: normal;
          font-size: 10px;
          letter-spacing: -0.41px;
          margin-top: 15px;
          color: #354f65;
          text-align: center;
        }

      .note-wrapper {
          p.note {
            color: #354f65;
            font-size: 18px;
            text-align: center;
            margin-top: 30px;
          }
          p.note-description {
            color: #8b959c;
            font-size: 14px;
            text-align: center;
            font-style: italic;
          }

          p.note-description-extra {
            color: #8b959c;
            font-size: 14px;
            text-align: center;
            margin-top: 20px;
            font-style: italic;

            span {
              color: #354f65;
              font-weight: 600;
              font-size: 14px;
              display: block;
            }
          }
        }

        .btn-next {
          color: #ffffff;
          text-align: center;
          font-size: 18px;
          border: 0;
          background: #68c552;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          margin: 20px auto 0;
          display: block;
          outline: none;

          &:disabled {
            /* pointer-events: none; */
            background: #68c552;
            color: #67b252;
          }

          &.orange:disabled {
            color: #be5515;
          }
        }
      }
    }
  }

  &.payment .btn-next {
    background: #ff7019 !important;
  }

  &.payment
    .cashout-content-wrapper
    .cashout-form-wrapper
    .form-body
    .cashout-option-wrapper {
    margin: 0 -15px;
    justify-content: space-around;

    @media (max-width: 425px) {
      margin: 0 -10px;
      justify-content: space-between;
    }
  }
`;

export const ReviewStyled = styled.div`
  .review-content-wrapper {
    animation: ${(props) =>
        props.slideLeft ? SlideInLeftKeyframes : SlideInKeyframes}
      300ms both ease;

    @media (max-width: 768px) {
      width: 100%;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      color: #fff;
      text-align: center;
    }

    .review-form-wrapper {
      .form-header {
        margin-top: 30px;
        background: #327fbe;
        border-radius: 5px 5px 0px 0px;
        padding: 16px 10px;

        & > div {
          display: flex;
          cursor: pointer;

          img {
            width: 12px;
            margin-right: 10px;
          }

          p {
            font-size: 18px;
            color: #fff;
          }
        }
      }
      .form-body {
        border-radius: 0 0 5px 5px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
        padding-bottom: 1px;

        ul.summary-list {
          list-style: none;
          border-bottom: 1px solid #ebebeb;

          li {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            border-bottom: 1px solid #ebebeb;

            @media (max-width: 768px) {
              padding: 0 10px;
            }

            div.list-content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              @media (max-width: 768px) {
                flex-flow: column;
                padding: 10px 0;
                text-align: center;
              }
              .content {
                display: flex;
                width: 65%;
                flex-flow: column;

                .title {
                  color: #327fbe;
                  font-size: 10px;
                }
                .highlight {
                  color: #354f65;
                  font-size: 14px;
                  font-weight: 600;
                }
                .note {
                  font-size: 10px;
                  color: #354f65;

                  span {
                    display: block;
                  }
                }
              }

              .cta {
                display: flex;
                flex-grow: 1;
                justify-content: center;

                @media (max-width: 768px) {
                  margin-top: 20px;
                }

                button {
                  border: 0;
                  background: #354f65;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  border-radius: 15px;
                  color: #fff;
                  font-size: 18px;
                  padding: 7px 5px;
                  outline: none;

                  &.redeem-btn {
                    width: 260px;
                  }

                  &.loan-btn {
                    width: 200px;
                  }
                }
              }
            }
          }
        }

        .aggrement-wrapper {
          padding: 20px;
          @media (max-width: 768px) {
            padding: 20px 10px;
          }

          & > div {
            display: flex;

            .checkbox-wrapper {
              margin-right: 10px;
              margin-top: 3px;

              label {
                width: 18px;
                height: 18px;
                display: block;
                border: 2px solid #8c969d;
                border-radius: 3px;
                outline-style: none;
                cursor: pointer;
                img {
                  display: none;
                }
              }
              input {
                display: none;
                &:checked + label {
                  border: none;
                  img {
                    display: block;
                  }
                }
              }
            }

            p {
              color: #8c969d;
              font-size: 14px;

              a {
                font-weight: bold;
                text-decoration: underline !important;
                color: #327fbe;
              }
            }
          }
        }

        button.btn-submit {
          display: block;
          margin: 20px auto 30px;
          background: #ff7019;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          border: 0;
          outline: none;
          width: 280px;
          text-align: center;
          font-size: 18px;
          color: #fff;

          &:disabled {
            color: #be5515;
          }
        }
      }
    }
  }
`;

export const LoanSummaryStyled = styled.div`
  width: 30%;

  @media (max-width: 768px) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    ${(props) => (props.MobileDisplay ? 'display : block;' : '')}
  }

  .loan-summary-wrapper {
    .summary-header {
      background: #327fbe;
      border-radius: 5px 5px 0px 0px;
      padding: 16px 0;
      @media (max-width: 768px) {
        border-radius: 0;
      }
      h3 {
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        text-align: center;
        color: #fff;
      }
    }

    .summary-body {
      padding: 15px;
      background: #112b41;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 5px 5px;

      @media (max-width: 768px) {
        border-radius: 0;
      }
      nav {
        ul {
          list-style: none;

          li {
            display: flex;
            align-items: center;
            padding: 5px 0;
            pointer-events: none;
            cursor: pointer;

            & > span {
              width: 32px;
              min-width: 32px;
              height: 32px;
              min-height: 32px;
              background: #17466c;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #112b41;
              font-size: 18px;
              margin-right: 10px;
            }

            p {
              color: #354f65;
              font-size: 14px;
              font-weight: 600;

              & > span {
                text-decoration: underline;
              }
            }

            img {
              width: 18px;
              margin-left: 5px;
            }

            &.with-value {
              pointer-events: auto;
              & > span {
                background: #fff;
                color: #354f65;
              }
              p {
                color: #fff;
              }
            }
            &.current {
              pointer-events: auto;

              p {
                color: #68c552;
              }

              span {
                background: #68c552;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
`;

export const AcceptanceNavStyled = styled.div`
  /* margin-bottom: 30px; */

  div.mobile-nav {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
    }

    p {
      color: #ffffff;
      font-size: 18px;

      span {
        color: #68c552;
      }
    }
  }

  div.destop-nav {
    @media (max-width: 768px) {
      display: none;
    }

    nav {
      ul {
        display: flex;
        list-style: none;
        justify-content: space-evenly;
        li {
          list-style: none;
          cursor: pointer;

          p {
            font-size: 18px;
            color: #fff;

            &.disabled {
              color: #48667e;
              pointer-events: none;
            }

            &.current {
              color: #68c552;
            }
          }
        }
      }
    }
  }

  div.progress-bar {
    margin-top: 15px;
    height: 7px;
    width: 100%;
    background: #112434;
    border-radius: 7px;
    div.progress-percent {
      transition: width 0.3s;
      height: inherit;
      width: ${(props) => props.percent + '%'};
      background: #68c552;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 7px;
    }
  }
`;
