import React, { useRef } from 'react';
import SharedCarousel from '../../components/shared/owlCarousel';
import { PromoCarouselStyle } from '../../styles/home.style';

import PawnApp from './pawnApp';
import GoldOffer from './goldOffer';

const PromoCarousel = () => {
  const corouselRef = useRef();
  const options = {
    center: true,
    loop: true,
    dots: false,
    dotsEach: false,
    items: 1,
    ref: corouselRef,
    autoplay: false,
    autoplayTimeout: 7000,
  };

  return (
    <>
      <PromoCarouselStyle>
        { /* Hide next and previous button */ }
         <button
          className='control prev'
          onClick={() => {
            corouselRef.current.prev(800);
          }}>
          <img
            className='promo-banner'
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/courosel-left.svg'
            alt='prev'
          />
        </button>
        <button
          className='control next'
          onClick={() => {
            corouselRef.current.next(800);
          }}>
          <img
            className='promo-banner'
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/courosel-right.svg'
            alt='next'
          />
        </button> 
        <SharedCarousel options={options}>
          <PawnApp />
          <GoldOffer />
        </SharedCarousel>
      </PromoCarouselStyle>
    </>
  );    
};

export default PromoCarousel;
