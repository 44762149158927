export const UPDATE_LOAN = 'UPDATE_LOAN';
export const RESET_LOAN = 'RESET_LOAN';

export const updateLoan = (loan) => {
  return {
    type: UPDATE_LOAN,
    loan,
  };
};

export const resetLoan = () => {
  return {
    type: RESET_LOAN,
  };
};
