import React, { useState, useEffect } from 'react';
import Layout from '../layout';
import ProgressNav from '../../components/loanStep/progressNav';
import Summary from '../../components/loanStep/summary';
import WhyPawnhero from '../../components/whyPawnhero';
import Offer from '../../components/loanStep/offer';
import Booking from '../../components/loanStep/booking';
import CashOut from '../../components/loanStep/cashout';
import Review from '../../components/loanStep/review';

// styles
import { AcceptanceStyle } from '../../styles/acceptance.style';
import { ContainerStyled } from '../../styles/layout.style';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { fetchSummaryTicket } from '../../../actions/ticket';
import { useHistory, useParams } from 'react-router-dom';
import { resetLoan, updateLoan } from '../../../actions/loan';
import { getProductName } from '../../../utils/helper';
import capitalize from 'lodash.capitalize';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';

const Acceptance = ({
  doFetchSummaryTicket,
  doUpdateLoan,
  doResetLoan,
  loan,
  user,
}) => {
  const { ticketNum } = useParams();
  const history = useHistory();
  const { position: currentPos = 0, data: loanData = {} } = loan;

  // states
  const [animationDone, animationDoneHandler] = useState(false);
  const [error, errorHandler] = useState(null);
  const [loading, loadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getTicketInfo();
    }
    return () => {
      cancel = true;
      doResetLoan();
    };
    // eslint-disable-next-line
  }, []);

  const getTicketInfo = async () => {
    try {
      loadingHandler(true);
      const {
        data,
        data: { statusRaw, user: ticketUser },
        success,
      } = await doFetchSummaryTicket({ ticketNum });

      if (success) {
        if (statusRaw !== 'ES') {
          history.push('/guest');
        } else {
          if (
            user &&
            !isEmpty(user) &&
            user.user.user_id !== ticketUser.userId
          ) {
            history.push('/');
          } else {
            doUpdateLoan({ ticketSummary: data });
            const { items } = data;
            const productName = getProductName(items[0].name);

            ticketDataHandler({ ...data, productName });
            loadingHandler(false);
          }
        }
      } else {
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        loadingHandler(false);
      }
    } catch (err) {
      errorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
      loadingHandler(false);
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPos]);

  return (
    <>
      <Layout style={{ overflow: 'hidden' }}>
        <AcceptanceStyle
          className={`window-height ${animationDone ? 'done-animate' : ''}`}>
          {error ? (
            <ContainerStyled>
              <p
                style={{
                  textAlign: 'center',
                  color: '#fff',
                  fontWeight: '600',
                  margin: '50px auto',
                }}>
                {error}
              </p>
            </ContainerStyled>
          ) : (
            <>
              {loading ? (
                <>
                  <div
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      left: '0',
                      right: '0',
                      top: '0',
                      bottom: '0',
                      height: '32px',
                    }}>
                    <img
                      className='loader'
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                      alt='loading'
                      style={{
                        width: '32px',
                        height: '32px',
                        margin: 'auto',
                        display: 'flex',
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {currentPos !== 0 && <ProgressNav />}
                  {currentPos === 0 && (
                    <>
                      <h1>
                        Congratulations,{' '}
                        {!ticketData && isNil(ticketData)
                          ? ' '
                          : capitalize(ticketData.user.firstName)}
                        !
                      </h1>
                      <p>
                        Review our initial loan offer/s for your{' '}
                        <span>
                          {!ticketData && isNil(ticketData)
                            ? ' '
                            : ticketData.productName}
                        </span>
                      </p>
                    </>
                  )}
                  <ContainerStyled className='container-wrapper'>
                    <div className='page-content-wrapper'>
                      <div className='page-content'>
                        {currentPos === 0 && (
                          <>
                            <Offer
                              animationDoneHandler={animationDoneHandler}
                              mode={0}
                            />
                          </>
                        )}
                        {currentPos === 1 && (
                          <>
                            <Booking
                              animationDoneHandler={animationDoneHandler}
                            />
                          </>
                        )}

                        {currentPos === 2 && (
                          <>
                            <CashOut
                              animationDoneHandler={animationDoneHandler}
                            />
                          </>
                        )}

                        {currentPos === 3 && (
                          <>
                            <Review
                              animationDoneHandler={animationDoneHandler}
                              mode={0}
                            />
                          </>
                        )}
                      </div>
                      {!isEmpty(loanData) && currentPos !== 0 ? (
                        <Summary />
                      ) : (
                        <WhyPawnhero />
                      )}
                    </div>
                  </ContainerStyled>
                </>
              )}
            </>
          )}
        </AcceptanceStyle>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['loan', 'user']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
  doUpdateLoan: (loan) => dispatch(updateLoan(loan)),
  doResetLoan: () => dispatch(resetLoan()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Acceptance);
