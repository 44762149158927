import React, { useEffect, useRef, useState } from 'react';
import { fetchSearch } from '../../../actions/search';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import isEmpty from '@ramda/isempty';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { updatePawn } from '../../../actions/pawn';

const { SearchModalStyled } = require('../../styles/searchModal.style');
const { ContainerStyled } = require('../../styles/layout.style');

const SearchMobileModal = ({
  mobileSearchToggle,
  handleMobileSearchToggle,
  doUpdatePawn,
  doPawnSearch,
}) => {
  const inputRef = useRef(null);
  const [searchKey, searchKeyHandler] = useState('');
  const [catalogs, catalogsHandler] = useState(null);

  useEffect(() => {
    if (mobileSearchToggle) {
      inputRef.current.focus();
    }
  }, [mobileSearchToggle]);

  useEffect(() => {
    let cancel = false;

    const timer = setTimeout(async () => {
      if (!cancel && !isEmpty(searchKey.trim())) {
        doSerch(searchKey);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      cancel = true;
    };

    // eslint-disable-next-line
  }, [searchKey]);

  const doSerch = async (key) => {
    const data = await doPawnSearch({ key });
    catalogsHandler(data);
  };
  return (
    <>
      <SearchModalStyled isToggle={mobileSearchToggle}>
        <div className='modal-header'>
          <ContainerStyled>
            <div className='modal-controller'>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                onClick={handleMobileSearchToggle}
                alt='righ-arrow-icon'
              />
              <div className='input-wrapper'>
                {isEmpty(searchKey) ? (
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-blue.svg'
                    alt='search-icon'
                  />
                ) : (
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon.svg'
                    alt='search-icon'
                  />
                )}
                <input
                  ref={inputRef}
                  type='text'
                  onChange={(e) => {
                    searchKeyHandler(e.target.value);
                  }}
                  placeholder='Try searching directly for your item here'
                />
              </div>
            </div>
          </ContainerStyled>
        </div>
        <div className='modal-body'>
          <ContainerStyled>
            <div className='search-option-wrapper'>
              {catalogs !== null &&
                catalogs.map((cat, key) => {
                  return (
                    <Link
                      key={key}
                      to={`/pawn/${cat.cc_slug}/${cat.cb_slug}/${cat.cp_slug}`}
                      onClick={() => {
                        doUpdatePawn({ slideLeft: false });
                      }}>
                      <div className='brand-icon-wrapper'>
                        <p>
                          {cat.brand_name
                            .replace(/[^a-zA-Z- ]/g, '')
                            .match(/\b\w/g)
                            .join('')
                            .substring(0, 2)
                            .toUpperCase()}
                        </p>
                      </div>
                      <div className='brand-icon-label'>
                        <p>
                          <Highlighter
                            highlightClassName={'markHighlight'}
                            searchWords={[searchKey]}
                            textToHighlight={`${cat.brand_name} ${cat.product_name} `}
                          />
                        </p>
                        <img
                          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-gray.svg'
                          alt='search-icon'
                        />
                      </div>
                    </Link>
                  );
                })}
            </div>
          </ContainerStyled>
        </div>
      </SearchModalStyled>
    </>
  );
};

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({
  doPawnSearch: (key) => dispatch(fetchSearch(key)),
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMobileModal);
