export const INIT_DASHBOARD = 'INIT_DASHBOARD';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';

export function initDashboard() {
  return {
    type: INIT_DASHBOARD,
  };
}

export function updateDashboard(dashboard) {
  return {
    type: UPDATE_DASHBOARD,
    dashboard,
  };
}


