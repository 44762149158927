import { isEmpty } from '@ramda/isempty/isEmpty';
import { pick } from 'ramda.pick/pick';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { io } from 'socket.io-client';
import { updateAddress } from '../../actions/address';
import { fetchUnreadCount } from '../../actions/chat';
import {
  fetchMyNotification,
  fetchMyUnreadCountNoti,
  updateNotification,
} from '../../actions/notification';
import {
  updateSocket,
  updateSocketChat,
  updateSocketChatAddCount,
} from '../../actions/socket';
import { fetchMyAddress } from '../../actions/user';

const { REACT_APP_FRONT_URL: _url, REACT_APP_MODE: _mode } = process.env;
const ENDPOINT = _mode === 'development' ? `http://localhost:3000` : `${_url}`;
// const ENDPOINT =
//   _mode === 'development' ? `https://front.pawnhero.ph` : `${_url}`;

const _socket = io(ENDPOINT, { autoConnect: false });

const AppContext = ({
  doUpdateSocket,
  doUpdateSocketChat,
  doFetchMyAddress,
  doUpdateAddress,
  doFetchMyNotification,
  doFetchMyUnreadCountNoti,
  doUpdateNotification,
  doFetchUnreadCount,
  doUpdateSocketChatAddCount,
  user,
  socketRef,
}) => {
  const location = useLocation();

  useEffect(() => {
    socketRef.current = _socket;
    socketRef.current.on('connect', () => {
      setTimeout(() => {
        doUpdateSocket({ isConnected: true });
      }, 1000);
    });

    return () => socketRef.current.disconnect();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if user is online

    if (user && !isEmpty(user) && user.user) {
      const {
        user: { user_id, name },
      } = user;
      socketRef.current.auth = { userId: user_id, type: 'client', name };
      socketRef.current.connect();

      socketRef.current.on('private_message', (content) => {
        doUpdateSocketChat({
          chats: {
            ...content,
            sender: content.sender.name,
            sender_id: content.sender.user_id,
          },
        });

        if (content.sender.user_id !== user.user.user_id) {
          doUpdateSocketChatAddCount();
        }
      });

      // on user login preload user reducer's
      doFetchMyAddress()
        .then((response) => {
          const { data = [], success = false } = response;
          if (success) {
            doUpdateAddress(data);
          }
        })
        .catch((err) => {
          doUpdateAddress([]);
        });

      doFetchMyNotification()
        .then((response) => {
          const { data: notification = null, success = false } = response;

          if (success) {
            doUpdateNotification({ notification });
          } else {
            doUpdateNotification({ notification });
          }
        })
        .catch((err) => {});

      doFetchMyUnreadCountNoti()
        .then((response) => {
          const { success } = response;

          if (success) {
            const {
              data: { count = 0 },
            } = response;
            doUpdateNotification({ count });
          } else {
            doUpdateNotification({ count: 0 });
          }
        })
        .catch((err) => {});

      doFetchUnreadCount().then((response) => {
        const { success } = response;

        if (success) {
          const {
            data: { count = 0 },
          } = response;
          doUpdateSocket({ count });
        } else {
          doUpdateSocket({ count: 0 });
        }
      });
    }

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  return <></>;
};

const mapStateToProps = pick(['user', 'notification', 'socket']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateSocket: (socket) => dispatch(updateSocket(socket)),
  doUpdateSocketChat: (socket) => dispatch(updateSocketChat(socket)),
  doFetchMyAddress: () => dispatch(fetchMyAddress()),
  doUpdateAddress: (address) => dispatch(updateAddress(address)),
  doFetchMyNotification: () => dispatch(fetchMyNotification()),
  doFetchMyUnreadCountNoti: () => dispatch(fetchMyUnreadCountNoti()),
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
  doFetchUnreadCount: () => dispatch(fetchUnreadCount()),
  doUpdateSocketChatAddCount: () => dispatch(updateSocketChatAddCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContext);
