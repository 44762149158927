import { uploadConfig } from './axios.config';

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();
    formData.append('image', file);

    return uploadConfig(onUploadProgress).post('/api/image/upload', formData);
  }

  chatUpload(file, onUploadProgress) {
    let formData = new FormData();
    formData.append('image_params', file);

    return uploadConfig(onUploadProgress).post('/api/chat/upload', formData);
  }

  uploadWithThumb(file, onUploadProgress) {
    let formData = new FormData();
    formData.append('image_params', file);

    return uploadConfig(onUploadProgress).post(
      '/api/image/upload_with_thumb',
      formData
    );
  }
}

export default new UploadFilesService();
