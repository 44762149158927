import styled from 'styled-components';

export const OtpModalStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  div.modal-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;

    div.modal-container {
      width: 95%;
      max-width: 554px;
      background: #327fbe;
      border-radius: 5px;
      padding: 30px;
      position: relative;

      > img.modal-close-icon {
        width: 24px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }

      > img.modal-icon {
        width: 120px;
        margin: auto;
        display: block;
      }

      p.title {
        font-size: 18px;
        text-align: center;
        color: #ffffff;
      }

      p.context {
        margin: 20px 0;
        font-size: 14px;
        text-align: center;
        color: #ffffff;

        span {
          color: #68c552;
        }
      }

      p.note {
        font-size: 14px;
        text-align: center;
        color: #ffffff;
      }

      .otp-wrapper {
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
        .ctm-otp {
          background: #016cae;
          border: 1px solid #016cae;
          border-radius: 5px;
          font-size: 20px;
          color: #ffffff;
        }
      }

      p.error {
        text-align: center;
        color: red;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      button.btn-otp-verify {
        background: #68c552;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        width: 150px;
        display: block;
        margin: auto;
        border: 0;
        padding: 5px;
        font-size: 18px;
        color: #ffffff;
        outline: none;

        &.disable,
        &:disabled {
          color: #3e9d29;
          /* pointer-events: none; */
        }
      }

      p.resent-otp {
        font-style: italic;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        margin-top: 30px;

        & > span:first-child {
          color: #fff;
          text-decoration: underline;
          cursor: pointer;

          &.disable {
            color: #354f65;
            pointer-events: none;
            text-decoration: none;
            cursor: auto;
          }
        }

        & > span:last-child {
          display: inline-block;

          &.disable {
            display: none;
          }
        }
      }
    }
  }
`;
