import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getBrandWithCategory = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { slug } = payload;

    try {
      const response = await axios.get('/api/brand/category/' + slug, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getBrandDetailBySlug = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { slug } = payload;

    try {
      const response = await axios.get('/api/brand/detail/' + slug, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
