import React from 'react';

//utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';
import { updateDashboard } from '../../../actions/dashboard';

// child
import ChatBox from '../../components/dashboard/chat';
import { menus } from '../../components/dashboard/layout';

// styles
const { ContentStyled } = require('../../styles/dashboard.style');

const ChatBoxPage = (props) => {
  const {
    socketRef,
    socket: { isConnected = false },
    dashboard,
  } = props;

  const { activeMenu } = dashboard;

  return (
    <ContentStyled>
      <div className='content-header'>
        <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
        <h1>{menus[activeMenu] ? menus[activeMenu].text : ''}</h1>
      </div>
      <div className='content-wrapper'>
        {isConnected ? (
          <ChatBox socketRef={socketRef} />
        ) : (
          <>
            <p>connecting</p>
          </>
        )}
      </div>
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard', 'user', 'socket']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateDashboard: (dashboard) => dispatch(updateDashboard(dashboard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBoxPage);
