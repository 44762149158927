import styled from 'styled-components';

export const CookieNoticeStyled = styled.div`
  background: #354f65;
  padding: 5px 0;

  & > div {
    /* container */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    font-size: 10px;
    color: #fff;
    text-align: center;

    a {
      color: #fff;
      text-decoration: underline !important;
    }
  }

  button {
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-weight: 600;
    font-size: 8px;
    line-height: 8px;
    background: transparent;
    color: #fff;
    padding: 2px 10px;
    margin-left: 20px;
    white-space: nowrap;
  }
`;
