import styled from 'styled-components';

export const HeaderStyled = styled.header`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: ${(props) => (props.showHeader ? 'block' : 'none')};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .container {
    /* container */
    overflow-y: visible !important;
    overflow-x: visible !important;
  }

  div.header-titles-wrapper {
    background: #327fbe;
    padding: 10px 0;

    div.header-titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0;
      position: relative;

      // &.dashboard {
      //   padding-left: 50px;
      // }

      @media (max-width: 959px) {
        justify-content: center;
      }

      .dashboard-menu {
        display: flex;
        align-items: center;
        margin-right: 15px;
        position: absolute;
        left: 0;

        img {
          transform: scaleX(-1);
        }

        @media (max-width: 959px) {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }

      div.brand-wrapper {
        max-width: 240px;
        a {
          padding: 0;
          & > img {
            width: 100%;
          }
        }
      }

      div.tel-wrapper {
        max-width: 200px;

        & > a {
          display: block;
          padding: 0;
        }
        & > img {
          width: 100%;
        }
        @media (max-width: 959px) {
          display: none;
        }
      }

      div.menu-btn-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        height: 12px;
        display: none;
        transition: transform 0.3s;

        &.static-menu.close {
          height: 18px;
        }

        &.open {
          transform: rotate(180deg);
        }

        @media (max-width: 959px) {
          display: block;
        }
      }

      div.side-wrapper-menu {
        display: none;

        @media (max-width: 959px) {
          display: block;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  div.header-nav-wrapper {
    ${(props) => (props.isStaticPage ? 'display : block;' : 'display : none;')}
    background: #2167a0;

    & > div.container {
      @media (max-width: 959px) {
        padding: 0;
      }
    }

    @media (min-width: 960px) {
      .ReactCollapse--collapse {
        height: auto !important;
        width: 100% !important;
        overflow-y: visible !important;
        overflow-x: visible !important;
      }
    }

    div.nav-wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: 959px) {
        display: block;
        padding: 20px 0 10px;
      }

      .tel-wrapper {
        display: none;
        max-width: 200px;
        margin: auto;
        @media (max-width: 959px) {
          display: block;
        }
      }

      nav {
        width: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 959px) {
          display: block;
          width: 100%;
          max-width: unset;
        }

        ul {
          display: flex;
          list-style: none;
          width: 100%;
          height: 100%;
          margin: 0 -10px;

          @media (max-width: 959px) {
            display: block;
            margin: auto;
          }

          & > li:last-child {
            margin-left: auto;
            @media (max-width: 959px) {
              margin: auto;
              & > div {
                margin: 0 -10px !important;
              }
            }
          }

          li {
            display: flex;
            position: relative;
            height: 100%;
            align-items: center;
            margin: 0 10px;

            @media (max-width: 959px) {
              display: block;
            }
            @media (max-width: 959px) {
              & #header_app_link {
                margin-left: 40px;
              }
            }
            a {
              font-size: 14px;
              line-height: 14px;
              color: #ffffff;
              text-decoration: none;
              cursor: pointer;
              height: 100%;
              display: flex;
              align-items: center;
              padding: 0%;

              @media (max-width: 959px) {
                display: block;
                text-align: center;
                padding: 10px 0;
              }
              

              & .new-badge {
                font-size: 8px;
                margin-top: -20px;
                background: #E2574C;
                margin-left: 5px;
                padding: 1px 5px;
                border-radius: 10px;
              }
            }

            a.dashboard-action-button {
              & > img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin: 0 5px;
              }
            }

            & > div.submenu-wrapper {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              & > div.title {
                display: flex;
                justify-content: center;
                font-size: 14px;
                line-height: 14px;
                color: #ffffff;
                cursor: pointer;
                height: 100%;
                width: 100%;
                align-items: center;

                @media (max-width: 959px) {
                  padding: 10px 0;
                }

                & > img {
                  width: 10px;
                  margin-left: 5px;
                  align-self: center;
                  transition: 0.3s;
                }
              }

              & > div.sub-menus {
                display: none;
                position: absolute;
                background: #134a77;
                width: 100%;
                top: 70%;
                left: 0;
                padding: 5px 0;

                @media (max-width: 959px) {
                  position: static;
                  padding: 0;
                }

                & > a {
                  display: block;
                  width: 100%;
                  padding: 5px 10px;
                  text-align: left;
                  @media (max-width: 959px) {
                    padding: 10px 0;
                    text-align: center;
                  }

                  &:hover {
                    background: #2167a0;
                  }
                }
              }

              /* desktop hover */
              @media (min-width: 960px) {
                min-width: 130px;
                &:hover > div.title {
                  img {
                    transform: rotate(180deg);
                  }
                }

                &:hover > div.sub-menus {
                  display: block;
                }
              }

              /* mobile */
              @media (max-width: 959px) {
                margin: 0 -20px;

                &.show {
                  display: block;
                  & > div.sub-menus {
                    display: block;
                  }

                  img {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }

      a.btn-pawn-now {
        background: #ff7019;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        color: #fff;
        font-size: 18px;
        width: 150px;
        text-align: center;
        text-decoration: none;
        margin: 10px 0;

        @media (max-width: 959px) {
          display: none;
        }
      }
    }
  }
`;
