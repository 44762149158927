import React, { useState, useEffect } from 'react';
import { BookingStyled } from '../../styles/acceptance.style';
import Select from 'react-select';
import SelectField from '../shared/select';
import DatePickerCTM from '../shared/datePicker';

//utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateRedeem } from '../../../actions/redeem';
import { fetchTime } from '../../../actions/time';
import moment from 'moment';
import { isEmpty } from '@ramda/isempty/isEmpty';
import {
  fetchProvinces,
  fetchCities,
  fetchBarangays,
} from '../../../actions/location';

const Booking = (props) => {
  const {
    doUpdateRedeem,
    redeem,
    doFetchTime,
    animationDoneHandler,
    user = false,
    doFetchProvinces,
    doFetchCities,
    doFetchBarangays,
  } = props;

  const { slideLeft = false } = redeem;
  const { booking } = redeem.data;

  const [mode, modehandler] = useState(
    booking && booking.mode ? booking.mode : 'delivery'
  );
  const [selectedDate, selectedDateHandler] = useState(
    booking && booking.date ? moment(booking.date).toDate() : null
  );
  const [addressOption, addressOptionHandler] = useState([]);
  const [selectedAddress, selectedAddressHandler] = useState(
    booking && booking.selectedAddress && !isEmpty(booking.selectedAddress)
      ? booking.selectedAddress
      : {}
  );
  const [currentDate, currentDateHandler] = useState(new Date());
  const [provinceOptions, provinceOptionsHandler] = useState([]);
  const [cityOptions, cityOptionsHandler] = useState([]);
  const [barangayOptions, barangayOptionsHandler] = useState([]);
  const [valid, validHandler] = useState(false);
  const [maturityDate, maturityDateHandler] = useState(false);
  const [isDefaultToday, isDefaultTodayHandler] = useState(false);

  const prev = () => {
    doUpdateRedeem({
      ...redeem,
      position: 0,
    });
  };

  const next = (mode, selectedAddress, date) => {
    const _booking = {
      selectedAddress,
      mode,
      date:
        mode === 'pickup' ? moment(date).format('YYYY-MM-DD 00:00:00') : null,
    };

    doUpdateRedeem({
      ...redeem,
      position: 2,
      data: { ...redeem.data, booking: _booking },
    });
  };

  useEffect(() => {
    if (!isEmpty(user) && user) {
      // user is login
      const address = [];
      addressOptionHandler(address);
      // selectedAddressHandler(address[0]);
    } else {
      // user is not active
      // load option values
      doFetchProvinces().then((data) => {
        const { provinces } = data;

        const _options = provinces.map((province) => {
          return {
            ...province,
            label: province.name,
            value: province.province_id,
          };
        });

        provinceOptionsHandler(_options);
      });

      doFetchCities().then((data) => {
        const { cities } = data;
        const _options = cities.map((city) => {
          return {
            ...city,
            label: city.name,
            value: city.city_id,
          };
        });
        cityOptionsHandler(_options);
      });

      doFetchBarangays().then((data) => {
        const { barangays } = data;
        const _options = barangays.map((barangay) => {
          return {
            ...barangay,
            label: barangay.name,
            value: barangay.barangay_id,
          };
        });

        barangayOptionsHandler(_options);
      });
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let valid = false;

    if (mode === 'delivery') {
      const keys = ['province', 'city', 'barangay', 'street'];

      const test = keys.filter((key) => {
        if (key === 'street') {
          const _test = selectedAddress[key] && selectedAddress[key].length > 3;
          return _test ? false : true;
        } else {
          const _test = selectedAddress[key];
          return _test ? false : true;
        }
      });

      valid = isEmpty(test) ? true : false;
    }

    if (mode === 'pickup') {
      valid = selectedDate ? true : false;
    }

    validHandler(valid);
  }, [mode, selectedAddress, selectedDate]);

  useEffect(() => {
    doFetchTime().then((time) => {
      const _time = moment(time).toDate();
      currentDateHandler(_time);
    });

    // animation
    animationDoneHandler(false);
    setTimeout(() => {
      animationDoneHandler(true);
    }, 300);
  }, [animationDoneHandler, doFetchTime]);

  // onload
  useEffect(() => {
    // reset redux data content
    const { data } = redeem;
    doUpdateRedeem({ data: { offer: data.offer } });
    maturityDateHandler(data.offer.dueDate)
    let _isDefaultToday = data.offer.defaultDate
                        ? moment(currentDate).isAfter(
                            moment(data.offer.defaultDate).subtract(-1, 'd'),
                            'day'
                          )
                        : false;
      isDefaultTodayHandler(_isDefaultToday);
    //eslint-disable-next-line
  }, []);

  return (
    <BookingStyled slideLeft={slideLeft}>
      <h2>Schedule your item for return</h2>
      <div className='booking-item-wrapper'>
        <div className='booking-form-wrapper'>
          <div className='form-header'>
            <div onClick={prev}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='previous'
              />
              <p>Back to Loan Repayment</p>
            </div>
          </div>
          <div className='form-body'>
            <div className='pickup-option-wrapper'>
              <button
                onClick={() => modehandler('delivery')}
                className={`${mode === 'delivery' ? 'selected' : ''}`}>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/delivery-ico.svg'
                  alt='delivery'
                />
                Delivery
              </button>
              <button
                onClick={() => modehandler('pickup')}
                className={`${mode === 'pickup' ? 'selected' : ''}`}>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/delivery-man-ico.svg'
                  alt='pickup'
                />
                Pick-up
              </button>
            </div>

            {mode === 'delivery' && (
              <>
                <p className='note'>
                  Please repay your loan 
                  {isDefaultToday ? (
                    <>
                      <span className='important'>TODAY BEFORE 11:59 PM</span>
                    </>
                  ) : (
                    <>
                      <span className='important'>ON OR BEFORE {maturityDate}</span>
                    </>
                  )}
                  before you can redeem your item. When your
                  payment has been validated (payment validation can take up to
                  1-2 business days depending on your mode of payment), item
                  delivery will take place and can take up to 3-5 business days.
                </p>
                <div className='fields-groups-wrapper'>
                  <h4>Book your item for delivery</h4>
                  <div className='fields-wrapper'>
                    {!isEmpty(user) && user && (
                      <div className='field-group'>
                        <Select
                          className='ctm-select'
                          options={addressOption}
                          value={selectedAddress}
                          onChange={(value) => selectedAddressHandler(value)}
                        />
                      </div>
                    )}
                    <div className={`field-group `}>
                      <div
                        className={`field-label-wrapper ${
                          selectedAddress.street == null ||
                          isEmpty(selectedAddress.street)
                            ? 'no-value'
                            : 'with-value'
                        }`}>
                        <input
                          type='text'
                          name='street'
                          id='pawn_redeem_form_address'
                          value={
                            selectedAddress.street ? selectedAddress.street : ''
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            selectedAddressHandler({
                              ...selectedAddress,
                              street: value,
                            });
                          }}
                          disabled={!isEmpty(user) && user}
                          autoComplete='false'
                        />
                        <label htmlFor='pawn_redeem_form_address'>
                          Street address
                        </label>
                      </div>
                    </div>
                    <div className='multi-field'>
                      {!isEmpty(user) && user ? (
                        <>
                          <div className={`field-group`}>
                            <input
                              type='text'
                              name='province'
                              value={
                                selectedAddress.province
                                  ? selectedAddress.province
                                  : ''
                              }
                              disabled
                            />
                          </div>
                          <div className={`field-group`}>
                            <input
                              type='text'
                              name='city'
                              value={
                                selectedAddress.city ? selectedAddress.city : ''
                              }
                              disabled
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={`field-group `}>
                            <div
                              className={`field-label-wrapper ${
                                selectedAddress.province == null ||
                                isEmpty(selectedAddress.province)
                                  ? 'no-value'
                                  : 'with-value'
                              }`}>
                              <SelectField
                                inputId='pawn_redeem_form_province'
                                className={`ctm-select ${
                                  selectedAddress.province ? 'val' : 'no-val'
                                }`}
                                classNamePrefix={'ctm'}
                                options={provinceOptions}
                                placeholder={''}
                                value={
                                  selectedAddress.province
                                    ? selectedAddress.province
                                    : ''
                                }
                                onChange={(value) => {
                                  selectedAddressHandler({
                                    ...selectedAddress,
                                    province: value,
                                    city: '',
                                    barangay: '',
                                  });
                                }}
                                isLoading={isEmpty(provinceOptions)}
                              />
                              <label htmlFor='pawn_redeem_form_province'>
                                Province
                              </label>
                            </div>
                          </div>
                          <div className={`field-group`}>
                            <div
                              className={`field-label-wrapper ${
                                selectedAddress.city == null ||
                                isEmpty(selectedAddress.city)
                                  ? 'no-value'
                                  : 'with-value'
                              }`}>
                              <SelectField
                                inputId='pawn_redeem_form_city'
                                className={`ctm-select ${
                                  selectedAddress.city ? 'val' : 'no-val'
                                }`}
                                classNamePrefix={'ctm'}
                                options={cityOptions.filter(
                                  (option) =>
                                    option.province_id ===
                                    (!selectedAddress.province
                                      ? false
                                      : selectedAddress.province.value)
                                )}
                                placeholder={''}
                                value={
                                  selectedAddress.city
                                    ? selectedAddress.city
                                    : ''
                                }
                                onChange={(value) => {
                                  selectedAddressHandler({
                                    ...selectedAddress,
                                    city: value,
                                    barangay: '',
                                  });
                                }}
                                isDisabled={
                                  selectedAddress.province &&
                                  !isEmpty(selectedAddress.province)
                                    ? false
                                    : true
                                }
                                isLoading={isEmpty(cityOptions)}
                              />
                              <label htmlFor='pawn_redeem_form_city'>
                                City
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className='multi-field'>
                      {!isEmpty(user) && user ? (
                        <div className={`field-group`}>
                          <input
                            type='text'
                            name='barangay'
                            value={
                              selectedAddress.barangay
                                ? selectedAddress.barangay
                                : ''
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        <>
                          <div className={`field-group`}>
                            <div
                              className={`field-label-wrapper ${
                                selectedAddress.barangay == null ||
                                isEmpty(selectedAddress.barangay)
                                  ? 'no-value'
                                  : 'with-value'
                              }`}>
                              <SelectField
                                inputId='pawn_redeem_form_barangay'
                                className={`ctm-select ${
                                  selectedAddress.barangay ? 'val' : 'no-val'
                                }`}
                                classNamePrefix={'ctm'}
                                options={barangayOptions.filter(
                                  (option) =>
                                    option.city_id ===
                                    (!selectedAddress.city
                                      ? false
                                      : selectedAddress.city.value)
                                )}
                                placeholder={''}
                                value={
                                  selectedAddress.barangay
                                    ? selectedAddress.barangay
                                    : ''
                                }
                                onChange={(value) => {
                                  selectedAddressHandler({
                                    ...selectedAddress,
                                    barangay: value,
                                  });
                                }}
                                isDisabled={
                                  selectedAddress.city &&
                                  !isEmpty(selectedAddress.city)
                                    ? false
                                    : true
                                }
                                isLoading={isEmpty(barangayOptions)}
                              />
                              <label htmlFor='pawn_redeem_form_barangay'>
                                Barangay
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <button
                      className={`submit ${valid ? '' : 'disabled'}`}
                      disabled={valid ? false : true}
                      onClick={() => next(mode, selectedAddress, selectedDate)}>
                      Go to Repayment Method
                    </button>
                  </div>
                </div>
              </>
            )}

            {mode === 'pickup' && (
              <>
                <p className='note'>
                  Please repay your loan 
                  {isDefaultToday ? (
                    <>
                      <span className='important'>TODAY BEFORE 11:59 PM</span>
                    </>
                  ) : (
                    <>
                      <span className='important'>ON OR BEFORE {maturityDate}</span>
                    </>
                  )} before you can redeem your item. Don't forget
                  to bring with you the original copy of your pawn ticket and
                  two (2) valid IDs when redeeming your item/s. These are
                  strictly required for us to be able to smoothly process your
                  loan redemption.
                </p>
                <div className='fields-groups-wrapper'>
                  <h4>Schedule your item pick-up</h4>
                  <div className='fields-wrapper'>
                    <div className={`field-group `}>
                      <div
                        className={`field-label-wrapper ${
                          selectedDate == null || isEmpty(selectedDate)
                            ? 'no-value'
                            : 'with-value'
                        }`}>
                        <DatePickerCTM
                          id='pawn_redeem_booking_pickup_date'
                          selectedDate={selectedDate}
                          onChange={(date) => selectedDateHandler(date)}
                          minDate={currentDate}
                          maxDate={3}
                          placeholder=''
                          currentDate={currentDate}
                        />
                        <label htmlFor='pawn_redeem_booking_pickup_date'>
                          Pick-up date
                        </label>
                      </div>
                    </div>
                    <button
                      className={`submit ${valid ? '' : 'disabled'}`}
                      disabled={valid ? false : true}
                      onClick={() => next(mode, undefined, selectedDate)}>
                      Go to Repayment Method
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </BookingStyled>
  );
};

const mapStateToProps = pick(['redeem']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateRedeem: (redeem) => dispatch(updateRedeem(redeem)),
  doFetchTime: () => dispatch(fetchTime()),
  doFetchProvinces: () => dispatch(fetchProvinces()),
  doFetchCities: () => dispatch(fetchCities()),
  doFetchBarangays: () => dispatch(fetchBarangays()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
