import React from 'react';
import { Link } from 'react-scroll'

const NavMenuMob = (props) => {

    const setMenu = (menu) => {
        props.onClick(menu)
    }

    return (
        <>
            <ul>
                <li><Link onClick={() => setMenu('About PawnHero')} to="about_pawnhero" spy={true} smooth={true} offset={-220} duration={250}>About PawnHero</Link></li>
                <li><Link onClick={() => setMenu('Items We Accept')} to="items_we_accept" spy={true} smooth={true} offset={-220} duration={250} >Items We Accept</Link></li>
                <li><Link onClick={() => setMenu('Interest Rates and Other Charges')} to="interest_rates" spy={true} smooth={true} offset={-220} duration={250} >Interest Rates and Other Charges</Link></li>
                <li><Link onClick={() => setMenu('New Loan Term Policy')} to="new_loan_term_policy" py={true} smooth={true} offset={-235} duration={250} >New Loan Term Policy</Link></li>
                <li><Link onClick={() => setMenu('New Renewal Policy')} to="new_renewal_policy" id="newrenewalpolicy" spy={true} smooth={true} offset={-220} duration={200} >New Renewal Policy</Link></li>
                <li><Link onClick={() => setMenu('How To Pawn')} to="how_to_pawn" spy={true} smooth={true} offset={-220} duration={250} >How To Pawn</Link></li>
                <li><Link onClick={() => setMenu('Feedback')} to="feedbacks" spy={true} smooth={true} offset={-220} duration={250} >Feedbacks</Link></li>
                <li><Link onClick={() => setMenu('Requirements')} to="requirements" spy={true} smooth={true} offset={-220} duration={250} >Requirements</Link></li>
                <li><Link onClick={() => setMenu('FAQs')} to="faqs" spy={true} smooth={true} offset={-220} duration={250} >FAQs</Link></li>
                <li><Link onClick={() => setMenu('Terms and Conditions')} to="terms" spy={true} smooth={true} offset={-220} duration={250} >Terms and Conditions</Link></li>
                <li><Link onClick={() => setMenu('Regulatory Issuances')} to="regulatory" spy={true} smooth={true} offset={-220} duration={250} >Regulatory Issuances</Link></li>
            </ul>
        </>
    );

};

export default NavMenuMob;