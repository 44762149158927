import React from 'react';
import SharedCarousel from '../../components/shared/owlCarousel';

const FeedBacks = () => {

    const options = {
        loop: false,
        nav: true,
        navText: [
            "<img height='72px' src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow.svg' alt='prev' />",
            "<img height='72px' src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow.svg' alt='next' />" 
        ],
        dots: false,
        dotsEach: true,
        startPosition: 0, //index
        lazyLoad: true,
        margin:15,
        responsive: {
          0: {
            items: 1,
            stagePadding: 50
          },
          320: {
            items: 1,
            stagePadding: 50
          },
          410: {
            items: 1,
            stagePadding: 50
          },
          480 : {
            items: 1,
            stagePadding: 20
          },
          560 : { 
            items: 1,
            stagePadding: 50
          },
          640 : { 
            items: 2,
            stagePadding: 40
          },
          720 : {
            items: 2,
            stagePadding: 50
          },
          820 : {
            items: 2,
            stagePadding: 40
          },
          1020: {
            items: 2,
            stagePadding: 50
          },
          1200: {
            items: 2,
            stagePadding: 40
          }
        },
      };

    return (
        <>
            <div className="feedback-wrapper">
                <SharedCarousel options={options}>
                    <div className="item-1" key={1}>
                        <div className="feedback-container">
                            <div className="ratings" >
                                <div className="star green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                            </div>
                            <p className="feedback-title">Successful pawning transaction</p>
                            <p className="feedback-text">Customer service is topnotch, paying of the loan and the interest is so easy with their online payment scheme and several options, my watch appraisal although it was barely used seems to be just fine. They safely seal the item. all in all it was good, easy and hassle free transaction from pawning to Returning back the item.</p>
                            <p className="feedback-title">- Pawned a Tudor Prince Oysterdate Luxury Watch for ₱70,000.00</p>
                        </div>
                    </div>
                    <div className="item-2" key={2}>
                        <div className="feedback-container">
                            <div className="ratings" >
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                            </div>
                            <p className="feedback-title">Highly commended for their services</p>
                            <p className="feedback-text">1 day process only, my item got picked up after lunch and I got the cash after my item was appraised the same day. High appraisal & has the lowest interest rate compared to traditional pawn shop. Thank you Pawnhero!</p>
                            <p className="feedback-title">- Pawned a Sony A7 Mark III Camera for ₱30,865.04</p>
                        </div>
                    </div>
                    <div className="item-3" key={3}>
                        <div className="feedback-container">
                            <div className="ratings" >
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                                <div className="star dark-green"><img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/star.svg" alt="rate" /></div>
                            </div>
                            <p className="feedback-title">Legit site and pawning service</p>
                            <p className="feedback-text">Pickup is convenient and the turnaround time for assessment of item/s were more than satisfactory. The field agents that pick up your items are trustworthy. I would highly recommend this company.</p>
                            <p className="feedback-title">- Pawned a Nikon D7200 Camera for ₱14,498.35</p>
                        </div>
                    </div>
                </SharedCarousel>
            </div>
        </>
    );
};

export default FeedBacks;