import {
  postTicket,
  getSummaryTicket,
  getAcceptanceOffer,
  postAcceptInitialOffer,
  postTicketSurvey,
  getFinalOffer,
  postFinalOffer,
  getRedeemOffer,
  postRenewalRequest,
  getRenewalApproved,
  getTicketPdf,
  getTicketView,
  getTicketRelease,
  postPaymentSlip,
  getTicketTrail,
  getRedeemDirectData,
  getMyTickets,
} from '../utils/ticket';

export const INIT_TICKET = 'INIT_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';

export const makeTicket = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postTicket(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSummaryTicket = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSummaryTicket(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAcceptanceOffer = (data) => (dispatch) => {
  // initial offer
  return new Promise((resolve, reject) => {
    getAcceptanceOffer(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAcceptInitialOffer = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAcceptInitialOffer(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeTicketSurvey = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postTicketSurvey(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchFinalOffer = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getFinalOffer(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAcceptFinalOffer = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postFinalOffer(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRedeemOffer = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRedeemOffer(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRenewalRequest = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRenewalRequest(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRenewalApproved = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRenewalApproved(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketPdf = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketPdf(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketView = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketView(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketRelease = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketRelease(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makePaymentSlip = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postPaymentSlip(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketTrail = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketTrail(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRedeemDirectData = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRedeemDirectData(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMyTickets = (data) => (dispatch) => {
  console.log('firing here');
  return new Promise((resolve, reject) => {
    getMyTickets(data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
