import styled from 'styled-components';

export const AboutUsStyled = styled.div`
  h3, h1 {
    font-weight: 600;
    font-size: 32px;
    color: #ffffff;
    text-align: justify;
  }

  p {
    font-size: 18px;
    color: #ffffff;
    text-align: justify;
  }

  h3 + p, h1 + p {
    margin-top: 20px;
  }

  p > span.underline {
    text-decoration: underline;
    font-weight: 600;
  }

  img.mobile-images {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .text-content-wrapper {
    /* desktop */
    @media (min-width: 769px) {
      height: inherit;
      display: flex;

      & > div.half-wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
        box-shadow: 25px 0px 25px rgba(0, 0, 0, 0.3);

        & > div.context-wrapper {
          width: 100%;
          max-width: 420px;
          padding-right: 15px;
        }
      }

      &.left > div.half-wrapper {
        margin-left: auto;
        box-shadow: -25px 0px 25px rgba(0, 0, 0, 0.3);
      }
    }

    /* mobile */
    @media (max-width: 768px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .section-wrapper {
    position: relative;

    & > img {
      position: absolute;
      width: 50%;
      height: 100%;
      left: 50%;
      object-fit: cover;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &.left > img {
      left: 0;
    }
  }
  & > section:first-child {
    /* @media (min-width: 769px) {
      & > div.section-wrapper {
        background-image: url('https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-1.png');
        background-size: 50% 100%;
        background-position-x: right;
        background-repeat: no-repeat;
      }
    } */
  }

  & > section:nth-child(2) {
    @media (min-width: 769px) {
      & > div.section-wrapper {
        /* background-image: url('https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-2.png');
        background-size: 50% 100%;
        background-position-x: left;
        background-repeat: no-repeat; */
      }

      div.half-wrapper {
        padding: 50px 0;
      }
    }
  }

  & > section:nth-child(3) {
    @media (min-width: 769px) {
      & > div.section-wrapper {
        /* background-image: url('https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-3.png');
        background-size: 50% 100%;
        background-position-x: right;
        background-repeat: no-repeat; */
      }

      div.half-wrapper {
        padding: 50px 0;
      }
    }
  }

  & > section:nth-child(odd) {
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #354f65 0%,
      #253d52 100%
    );
  }

  & > section:nth-child(even) {
    background: #327fbe;
  }

  section#mission {
    background: #fff;
    padding: 70px 0;

    div.content-wrapper {
      display: flex;

      @media (max-width: 768px) {
        display: block;
      }

      & > div {
        width: 100%;
        padding: 10px;

        @media (max-width: 768px) {
          margin-top: 10px;
        }
        img {
          width: 100px;
          height: 100px;
          margin: auto;
          display: block;
        }

        h4 {
          color: #327fbe;
          font-weight: 600;
          font-size: 24px;
          text-align: center;
          margin-top: 15px;
        }

        p {
          color: #8c969d;
          font-size: 18px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  section#featured_in {
    background: #f2f2f2;
    padding: 50px 0;

    h3 {
      color: #327fbe;
      font-weight: 600;
      font-size: 24px;
      text-align: center;
    }

    & > div {
      /* container */
      & > div {
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;

        .list-container {
          margin-top: 10px;
          max-width: 600px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;

          @media (max-width: 425px) {
            justify-content: center;
          }

          & > img {
            display: flex;
            margin: 10px;

            &#fea_abs {
              width: 43px;
            }

            &#fea_gma {
              width: 72px;
            }

            &#fea_rappler {
              width: 43px;
            }

            &#fea_inquirer {
              width: 130px;
            }

            &#fea_forbes {
              width: 72px;
            }

            &#fea_bloomberg {
              width: 92px;
            }

            &#fea_wsj {
              width: 49px;
            }

            &#fea_entrepreneur {
              width: 94px;
            }

            &#fea_techcrunch {
              width: 127px;
            }

            &#fea_inc {
              width: 81px;
            }

            &#fea_techniasia {
              width: 99px;
            }

            &#award_top {
              width: 110px;
              @media (max-width: 425px) and (min-width: 321px) {
                margin: 10px 20px;
              }
            }

            &#award_startup {
              width: 113px;
              @media (max-width: 425px) and (min-width: 321px) {
                margin: 10px 20px;
              }
            }

            &#award_osaka {
              width: 83px;
              @media (max-width: 425px) and (min-width: 321px) {
                margin: 10px 20px;
              }
            }

            &#award_asia {
              width: 136px;
              @media (max-width: 425px) and (min-width: 321px) {
                margin: 10px 20px;
              }
            }
          }
        }

        /* awards */
        &:last-child {
          margin-top: 20px;
          .list-container {
            @media (max-width: 768px) {
              justify-content: space-around;
            }
          }
        }
      }
    }
  }

  section#media {
    background: #fff;
    padding: 30px 0;

    h3 {
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      color: #327fbe;
    }

    div.press-list-wrapper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      div.press {
        margin-top: 20px;
        width: 20%;
        min-width: 255px;
        a {
          @media (max-width: 560px) {
            width: 100%;
          }

          & > div.press-img {
            height: 160px;
            width: 100%;
            background: #828282;
          }

          & > div.press-data {
            h4 {
              font-weight: 600;
              font-size: 18px;
              color: #327fbe;
              margin-top: 15px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            p {
              font-size: 14px;
              color: #8c969d;
            }
          }
        }
      }
    }
  }
`;
