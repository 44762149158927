const hash = process.env.REACT_APP_TOKEN;
const axios = require('axios');

const config = {
  headers: {
    Authorization: `${hash}`,
    'Content-Type': 'application/json',
  },
};

const uploadConfig = (onUploadProgress) => {
  const token = process.env.REACT_APP_CMS_TOKEN; //cms token
  const apiUrl = process.env.REACT_APP_API_URL; //cms-api url
  return axios.create({
    baseURL: apiUrl,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: onUploadProgress,
  });
};

const FormConfig = () => {
  const token = process.env.REACT_APP_CMS_TOKEN; //cms token
  const apiUrl = process.env.REACT_APP_API_URL; //cms-api url
  return axios.create({
    baseURL: apiUrl,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

exports.config = config;
exports.uploadConfig = uploadConfig;
exports.FormConfig = FormConfig;
