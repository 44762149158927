import styled from 'styled-components';

export const SearchMobileStyled = styled.div`
  display: none;
  background: #2167a0;
  border-radius: 5px;
  padding: 10px 15px;
  position: relative;
  width: 100%;

  img {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 15px;
  }

  p {
    color: #5a92c0;
    font-size: 14px;
    font-weight: 400;
    padding-left: 30px;
    font-size: 14px;

    @media (max-width: 425px) {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
`;
