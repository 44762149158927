import { getBrandWithCategory, getBrandDetailBySlug } from '../utils/brand';

export const fetchBrandWithCategory = (slug) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getBrandWithCategory(slug)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchBrandDetailBySlug = (slug) => () => {
  return new Promise((resolve, reject) => {
    getBrandDetailBySlug(slug)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
