import React, { useState, useEffect } from 'react';
import {
  BankDeposit,
  CoinsDeposit,
  GCashDeposit,
  PayMayaDeposit,
} from '../cashOutMethods';

//utility
import { isEmpty } from '@ramda/isempty/isEmpty';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateLoan } from '../../../actions/loan';

// styles
import { CashOutStyled } from '../../styles/acceptance.style';

export const CashOut = ({ doUpdateLoan, loan, animationDoneHandler }) => {
  const {
    data: {
      cashout: cashoutVal = {},
      booking: { mode = '' },
    },
    data,
    slideLeft = false,
  } = loan;

  const { cashOutDetails: cashOutDetailVal = {} } = cashoutVal;
  const [cashOutSelect, cashOutSelectHandler] = useState(
    mode === 'walkin' ? 'cash' : ''
  );
  const [cashOutDetails, cashOutDetailsHandler] = useState(cashOutDetailVal);

  const [errFields, errFieldsHandler] = useState([]);

  const doNext = (cashout, cashOutDetails) => {
    const { text: cashoutLabel = '' } = cashOptions.find(
      (option) => option.value === cashout
    );

    doUpdateLoan({
      position: 3,
      data: {
        ...loan.data,
        cashout: { type: cashout, label: cashoutLabel, cashOutDetails },
      },
      slideLeft: false,
    });
  };

  const formValid = (cashout, cashOutDetails) => {
    const noValidation = ['cash', 'cash_card'];

    if (!noValidation.includes(cashout)) {
      if (cashout === 'bank_deposit') {
        if (!cashOutDetails.selectedOption) {
          return true;
        }
        if (!cashOutDetails.account_no) {
          return true;
        }
        if (!cashOutDetails.bankSelected) {
          return true;
        }
      } else {
        const { account_no = '' } = cashOutDetails;

        if (account_no && !isEmpty(account_no)) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };
  const next = (cashout, cashOutDetails) => {
    const noValidation = ['cash', 'cash_card'];
    errFieldsHandler([]); //clear err fields

    if (!noValidation.includes(cashout)) {
      // validation
      if (cashout === 'bank_deposit') {
        if (
          cashOutDetails.selectedOption &&
          cashOutDetails.account_no &&
          cashOutDetails.bankSelected
        ) {
          if (cashOutDetails.account_no.length < 4) {
            errFieldsHandler(['accountNum']);
          } else {
            doNext(cashout, cashOutDetails);
          }
        } else {
          const _errFields = [];
          if (!cashOutDetails.selectedOption) {
            _errFields.push('selectedOption');
          }
          if (!cashOutDetails.account_no) {
            _errFields.push('accountNum');
          }
          if (!cashOutDetails.bankSelected) {
            _errFields.push('bank');
          }
          errFieldsHandler(_errFields);
        }
      } else {
        const { account_no = '' } = cashOutDetails;
        const mobileReg = new RegExp('^09');
        const validate = account_no.length === 11;

        if (account_no && !isEmpty(account_no)) {
          if (validate && mobileReg.test(account_no)) {
            doNext(cashout, cashOutDetails);
          } else {
            errFieldsHandler(['accountNum']);
          }
        } else {
          errFieldsHandler(['accountNum']);
        }
      }
    } else {
      doNext(cashout, cashOutDetails);
    }
  };

  useEffect(() => {
    // animation
    animationDoneHandler(false);
    setTimeout(() => {
      animationDoneHandler(true);
    }, 500);
  }, [animationDoneHandler]);

  useEffect(() => {
    doUpdateLoan({ data: { offer: data.offer, booking: data.booking } });
    // eslint-disable-next-line
  }, []);

  const cashOptions = [
    {
      text: 'Cash',
      value: 'cash',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/cash-ico.svg',
      note: 'You will receive your loan in cash upon loan approval',
      walkin: true,
      pickup: false,
    },
    {
      text: 'Cash Card',
      value: 'cash_card',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/cash-card-ico.svg',
      note: 'Receive your loan in an instant using our free PayMaya cash card.',
      walkin: false,
      pickup: false,
    },
    {
      text: 'Bank Deposit',
      value: 'bank_deposit',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/bank-ico.svg',
      note: '',
      fields: (
        <BankDeposit
          onChange={(data) => cashOutDetailsHandler(data)}
          user={loan.ticketSummary.user}
          values={cashOutDetailVal}
          errFields={errFields}
        />
      ),
      walkin: false,
      pickup: true,
      disclamer:
        '* Please note that selected fees and processing time are subject to change without prior notice depending on the selected electronic fund transfer service.',
    },
    {
      text: 'Coins.ph',
      value: 'coins',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/coins-ico.svg',
      note:
        'Receive your loan in an instant using your Coins.ph e-wallet account.',
      fields: (
        <CoinsDeposit
          onChange={(data) => cashOutDetailsHandler(data)}
          values={cashOutDetailVal}
          errFields={errFields}
        />
      ),
      walkin: false,
      pickup: true,
      disclamer: `* You may only use any mobile number connected to your Coins.ph account. The mobile number should be owned by you, the original pawner.`,
    },
    {
      text: 'GCash',
      value: 'gcash',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/gcash-ico.svg',
      note:
        'Receive your loan in an instant using your GCash e-wallet account.',
      fields: (
        <GCashDeposit
          onChange={(data) => cashOutDetailsHandler(data)}
          values={cashOutDetailVal}
          errFields={errFields}
        />
      ),
      walkin: false,
      pickup: true,
      disclamer: `* You may only use any mobile number connected to your GCash account. The mobile number should be owned by you, the original pawner.`,
    },
    {
      text: 'PayMaya',
      value: 'paymaya',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/paymaya-ico.svg',
      note:
        'Receive your loan in an instant using your PayMaya e-wallet account.',
      fields: (
        <PayMayaDeposit
          onChange={(data) => cashOutDetailsHandler(data)}
          values={cashOutDetailVal}
          errFields={errFields}
        />
      ),
      walkin: false,
      pickup: true,
      disclamer: `* You may only use any mobile number connected to your PayMaya account. The mobile number should be owned by you, the original pawner.`,
    },
  ];

  const prev = () => {
    doUpdateLoan({
      position: 1,
      slideLeft: true,
    });
  };

  return (
    <CashOutStyled slideLeft={slideLeft}>
      <div className='cashout-content-wrapper'>
        <h2>
          {cashOptions.filter((option) => {
            return option[mode];
          }).length > 1
            ? `How do you want to receive your loan?`
            : `Confirm your cash out method`}
        </h2>
        <div className='cashout-form-wrapper'>
          <div className='form-header'>
            <div onClick={prev}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='previous'
              />
              <p>Back to Booking</p>
            </div>
          </div>
          <div className='form-body'>
            <div className={`cashout-option-wrapper ${mode}`}>
              {cashOptions
                .filter((option) => {
                  return option[mode];
                })
                .map((option, key) => {
                  return (
                    <button
                      key={key}
                      className={`${
                        cashOutSelect === option.value ? 'selected' : ''
                      }`}
                      onClick={() => {
                        errFieldsHandler([]);
                        cashOutSelectHandler(option.value);
                      }}>
                      <img src={option.img} alt={option.text} />
                      <p>{option.text}</p>
                    </button>
                  );
                })}
            </div>
            <div className='note-wrapper'>
              <p className='note'>
                {cashOptions.find((option) => option.value === cashOutSelect)
                  ? cashOptions.find((option) => option.value === cashOutSelect)
                      .text
                  : ''}
              </p>
              <p className='note-description'>
                {cashOptions.find((option) => option.value === cashOutSelect)
                  ? cashOptions.find((option) => option.value === cashOutSelect)
                      .note
                  : ''}
              </p>
              {cashOptions.find((option) => option.value === cashOutSelect)
                ? cashOptions.find((option) => option.value === cashOutSelect)
                    .fields
                : ''}
            </div>
            {cashOptions.find((option) => option.value === cashOutSelect) &&
              cashOptions.find((option) => option.value === cashOutSelect)
                .disclamer && (
                <p className='disclamer-message'>
                  {
                    cashOptions.find((option) => option.value === cashOutSelect)
                      .disclamer
                  }
                </p>
              )}

            <button
              className='btn-next'
              disabled={formValid(cashOutSelect, cashOutDetails)}
              onClick={() => next(cashOutSelect, cashOutDetails)}>
              Go to Loan Review
            </button>
          </div>
        </div>
      </div>
    </CashOutStyled>
  );
};

const mapStateToProps = pick(['loan']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateLoan: (loan) => dispatch(updateLoan(loan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashOut);
