import { getBanks } from '../utils/coins';

export const fetchBanks = () => () => {
  return new Promise((resolve, reject) => {
    getBanks()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
