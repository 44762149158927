import styled from 'styled-components';

export const RedeemFailedStyle = styled.div`
  background: radial-gradient(50% 50% at 50% 50%, #354f65 0%, #253d52 100%);
  padding: 60px 0;

  section.banner {
    img {
      width: 100%;
      max-width: 240px;
      display: block;
      margin: auto;
    }

    h1 {
      font-size: 32px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
    }

    h1 + p {
      font-size: 20px;
      color: #ffffff;
      text-align: center;
    }
  }

  section.cta-content {
    margin-top: 25px;
    max-width: 750px;
    margin: auto;

    p {
      margin-top: 25px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;

      a {
        color: #ffffff;
        text-decoration: underline !important;
      }
    }

    a.btn {
      background: #ff7019;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      display: table;
      margin: 20px auto;
      font-weight: 600;
      color: #ffffff;
    }

    a.cancel-link {
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      display: table;
      margin: auto;
    }
  }
`;
