import styled from 'styled-components';

export const AppStyled = styled.div`
    


    .tl-background { 
        left: 0;
        position: absolute;
        top: 0;
    }
    .br-background { 
        bottom: -5px;
        position: absolute;
        right: 0;
    }
    .tl-background > img { width: 500px; } 
    .br-background > img { width: 300px; } 
    .right-mobile-money { display: none; }
    .home-top .right-mobile-money { display: none !important;}
    @media (max-width: 771px) {

        & .tl-background { left: -250px; }
        & .br-background { right: -260px; }
        
        .app-content {
            & .right-mobile-money { display: inline-block !important; }
            & .left-content { 
                display: none;
            }
        }

    }

    .app-content {
        position: relative;
        justify-content: center;
        align-items: center;
        min-height: 500px;
        display: flex;
        z-index: 9;
        & .left-content {
            & > img {
                width: 300px;
                z-index: 999;
            }
        }
        & .right-content {
            text-align: center;
            color: #FFFFFF; 
            & .title { 
                font-size: 72px;
                font-weight: 600;
            }
            & .sub-title { 
                font-size: 24px;
                font-weight: 600;
            }
            & .middle {
                display: inline-block;
                margin: 30px 0;
            }
            ul { list-style: none outside none; margin:0; padding: 0; }
            li { 
                float: left; 
                margin: 0 30px; 
                @media (max-width: 480px) {
                    margin: 0 10px; 
                }
            }
            & .home-bot {
                display: flex;
                justify-content: center;
            }
            & .home-bot > ul > li {
                margin: 0 5px; 
            }
            & .home-bot > a {
                float: left;
            }
            & .bottom > a > img { 
                margin: 0 10px;
                width: 150px; 
                @media (max-width: 480px) {
                    width: 120px;
                }
            }
            @media (max-width: 480px) {
                & .home-top .title {
                    font-size: 48px;
                }
                & .home-middle { margin: 15px 0; }
                & .home-middle ul li img {
                    width: 60px !important;
                }
                & .home-bot a > img{
                    width: 120px !important;
                }
                & .bottom > a:last-child > img {
                    width: 35px !important;
                }
                & .home-bot li > img{
                    width: 35px !important;
                }
            }
        }
        div.cta-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            a {
              background: #68c552;
              box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
              border-radius: 15px;
              padding: 5px 20px;
              color: #ffffff;
              display: inline-block;
              margin: auto;
            }
        }
    }

    .carousel-container {
        text-align: center;
        & > h2 {
            color: #354F65;
            font-weight: 600;
            margin: 25px 0;
        }

        & > div {
            div.owl-carousel {
                div.owl-stage {
                    div.owl-item {
                        & > div {
                            display: flex;
                            flex-flow: column;
                            align-items: center;
                            justify-content: center;
      
                            div.img-wrapper {
                            -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 25px;
                            width: 262px;
                            overflow-x: hidden;
                            overflow-y: hidden;
                            transition: 0.3s;
                                img {
                                    width: 100%;
                                }
                            }
                            p {
                                color: #ffffff;
                                font-size: 24px;
                                font-weight: 600;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                & .owl-dots {
                    & > button { 
                        background: #B2B1B1; 
                        display: none;
                        @media (max-width: 480px) {
                            display: block;
                        }
                    }
                    & > button.active { background: #327FBE; }
                }

                & .owl-nav {
                    & .owl-prev { 
                        position: absolute;
                        left: 5px;
                        bottom: 50%;
                        margin-bottom: -36px;
                    }
                    & .owl-next {
                        position: absolute;
                        right: 5px;
                        bottom: 50%;
                        margin-bottom: -36px;
                    }
                }
            }
        }
    }

    .context-container {
        p { text-align: center }
        & .context-title {
            color: #327FBE;
            font-size: 32px;
            font-weight: 700;
            margin: 15px 0 30px 0;
        }
        & .context-subtext {
            color: #354F65;
            font-size: 24px;
            font-weight: 500;
            margin: 25px 0;
            padding: 0 105px;
            @media (max-width: 480px) {
                padding: 0 10px;
            }
        }

        & .btn-lm {
            background: #68C552;
            color: #FFF;
            margin-bottom: 30px;
        }

        & .learn-more-text {
            & > p {
                text-align: left;
                @media (max-width: 771px) {
                    text-align: justify;
                }
            }
            & .p-title {
                font-weight: 600;
                color: #327FBE;
                margin-bottom: 15px;
            }
            & .p-body {
                font-weight: 500;
                color: #354F65;
                margin-bottom: 15px;
            }
            & .p-600 { font-weight: 600; }
        }

        & .download-container {
            text-align: center;
            & > a { 
                margin: 10px 15px;
                @media (max-width: 480px) {
                    margin: 10px;
                }
            }

            & .bottom-as { 
                width: 250px; 
                @media (max-width: 690px) {
                    width: 200px;
                }
                @media (max-width: 481px) {
                    width: 120px;
                }
            }
            & .bottom-gp {
                width: 250px; 
                @media (max-width: 690px) {
                    width: 200px;
                }
                @media (max-width: 481px) {
                    width: 120px;
                }
            }
            & .bottom-qr {
                width: 72px;
                @media (max-width: 690px) {
                    width: 60px;
                }
                @media (max-width: 481px) {
                    width: 35px;
                }
            }

        }
    }

    
`;
