import styled from 'styled-components';

export const RadioStyled = styled.div`
  transition: background-color 0.3s;
  cursor: pointer !important;

  &:hover,
  &:focus {
    background: #6bbd57;

    .entry-label-wrapper {
      p {
        color: #fff !important;
      }
      & > img:last-child {
        display: block;
      }
    }
  }

  input {
    display: none;
  }

  label {
    display: block;
    padding-left: 10px;
    position: relative;
    cursor: pointer !important;

    .entry-icon-wrapper {
      width: 32px;
      height: 32px;
      background: #354f65;
      border-radius: 50%;
      position: absolute;
      top: 15px;
      /* bottom: 0; */
      margin: auto;
      justify-content: center;
      display: flex;

      p {
        align-self: center;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .entry-label-wrapper {
      padding: 17px 0;
      border-bottom: 1px solid #c7c7cc;
      margin-left: 40px;

      p {
        font-weight: 400;
        font-size: 18px;
        color: #354f65;
        padding-left: 10px;
      }

      p.note {
        color: #8b959c;
        font-size: 14px;
        margin-top: 10px;
        padding-right: 30px;
      }

      img {
        width: 8px;
        height: 13px;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      & > img:last-child {
        display: none;
      }
    }
  }

  input:checked + label {
    .entry-icon-wrapper {
      background: #68c552;
    }
  }
`;
