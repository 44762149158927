import React from 'react';
import { ContainerStyled } from '../../styles/layout.style';
import { AcceptanceNavStyled } from '../../styles/acceptance.style';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateRedeem } from '../../../actions/redeem';

const ProgressNav = (props) => {
  const { doUpdateRedeem, redeem } = props;

  const menus = [
    {
      text: 'Loan Repayment',
      redeemField: 'offer',
    },
    {
      text: 'Item Return',
      redeemField: 'booking',
    },
    {
      text: 'Repayment Method',
      redeemField: 'cashout',
    },
  ];

  const positionHandler = (pos) => {
    doUpdateRedeem({ position: pos });
  };

  const currentPos = redeem && redeem.position ? redeem.position : 0;
  const percent =
    menus.length - 1 === currentPos ? 99 : (currentPos / menus.length) * 100;

  return (
    <AcceptanceNavStyled percent={percent}>
      <ContainerStyled>
        <div className='destop-nav'>
          <nav>
            <ul>
              {menus.map((menu, i) => {
                const hasData = redeem.data[menu.redeemField] ? true : false;
                return (
                  <li key={i}>
                    <p
                      className={`${currentPos === i ? 'current' : ''} ${
                        !hasData && currentPos !== i ? 'disabled' : 'enable'
                      }`}
                      onClick={() => positionHandler(i)}>
                      {menu.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
        <div className='mobile-nav'>
          <p>
            Step {currentPos} of {menus.length} :{' '}
            <span>{menus[currentPos].text}</span>
          </p>
        </div>
        <div className='progress-bar'>
          <div className='progress-percent'></div>
        </div>
      </ContainerStyled>
    </AcceptanceNavStyled>
  );
};

const mapStateToProps = pick(['redeem']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateRedeem: (redeem) => dispatch(updateRedeem(redeem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNav);
