import { isEmpty } from '@ramda/isempty/isEmpty';
import React, { useState } from 'react';
import { BankDepositStyled } from '../../styles/cashOutMethods.style';
import SelectField from '../shared/select';

export const EBanking = ({ onChange }) => {
  const options = [
    { label: 'BancNet', value: 'BN' },
    { label: 'BDO Online Bills Payment', value: 'BDOOBP' },
    { label: 'BDO Over-the-Counter', value: 'BDOOTC' },
    { label: 'PNB Online Bills Payment', value: 'PNBOBP' },
    { label: 'PNB Over-the-Counter', value: 'PNBOTC' },
    { label: 'UCPB Online Bills Payment', value: 'UCPBOBP' },
    { label: 'UCPB Over-the-Counter', value: 'UCPBOTC' },
    // { label: 'UnionBank Online Bills Payment', value: 'UBPOBP' },
  ];

  const [channel, channelHandler] = useState('');

  return (
    <>
      <BankDepositStyled>
        <div className='fields-wrapper'>
          <div className={`field-group `}>
            <div
              className={`field-label-wrapper ${
                channel == null || isEmpty(channel) ? 'no-value' : 'with-value'
              }`}>
              <SelectField
                inputId='pawn_redeem_payment_channels'
                options={options}
                className='ctm-select'
                classNamePrefix='ctm'
                value={channel}
                onChange={(value) => {
                  channelHandler(value);
                  onChange({ channel: value });
                }}
                placeholder=''
              />
              <label htmlFor='pawn_redeem_payment_channels'>
                Payment Channels
              </label>
            </div>
          </div>
        </div>
      </BankDepositStyled>
    </>
  );
};
