import React, { useEffect, useState } from 'react';
import Layout from '../layout';
import { ContainerStyled } from '../../styles/layout.style';
import { ItemsDetailedStyled } from '../../styles/itemsWeAccept.style';
import PawnOnline from '../../components/pawnOnline';

// utility
import { connect } from 'react-redux';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { pick } from 'ramda.pick/pick';
import { fetchIwaData, updateIwa } from '../../../actions/iwa';
import { Helmet } from 'react-helmet';

const ItemsDetailed = (props) => {
  const {
    doFetchIwaData,
    doUpdateIwa,
    iwa,
    iwa: { mode, slug: _slug, cat: _cat, success = true },
  } = props;

  const [isError, isErrorHandler] = useState(!success);
  const [isLoading, isLoadingHandler] = useState(false);
  const [getCatName, setCatName] = useState(0);

  const { key: slug, cat = false } = useParams();

  const location = useLocation();

  const getData = () => {
    isErrorHandler(false);
    isLoadingHandler(true);

    const payload = { slug, cat };
    const _payload = Object.fromEntries(
      Object.entries(payload).filter(([_, v]) => v != null)
    );

    doFetchIwaData(_payload)
      .then((data) => {
        const { success } = data;
        if (data && success) {
          doUpdateIwa({ ...data, slug, cat });
        } else {
          doUpdateIwa({
            success: false,
            slug,
            cat,
          });
          isErrorHandler(true);
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        isErrorHandler(true);
        isLoadingHandler(false);
      });
  };

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    let cancel = false;
    

    if (!cancel) {
      if (cat !== _cat || slug !== _slug) {
        getData();
      }
    }
    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, [location]);


  useEffect(() => {
    if(iwa.cat === false) {
      setCatName('');
    } else {
      var categoryName = iwa.cat;
      setCatName(capitalizeFirst(categoryName.replace("-"," ")));
    }
    
  }, [iwa.cat])

  return (
    <Layout>
      <Helmet>
        <title>
          {
             (mode === 'category'
            ? iwa.categoryData && iwa.categoryData.title
            : capitalizeFirst(iwa.brandData.brand_name) + ' ' + getCatName +' - Items We Accept | PawnHero') 
          } 
          {/* {(mode === 'category'
            ? iwa.categoryData && iwa.categoryData.title
            : iwa.brandData && iwa.brandData.title) ||
            'Online Pawn Loans: 1 Day Loan Approval! | PawnHero'} */}
        </title>
      </Helmet>
      <ItemsDetailedStyled className='page'>
        {isError ? (
          <>
            <ContainerStyled>
              <p className='error' style={{ marginTop: '20px' }}>
                Something went wrong. Please refresh the page, or contact
                PawnHero IT support.
              </p>
            </ContainerStyled>
          </>
        ) : (
          <>
            <div className='breadcrum'>
              <ContainerStyled>
                <p>
                  <Link to='/' id='iwa_home_link'>
                    Home
                  </Link>{' '}
                  •{' '}
                  <Link to='/items-we-accept' id='iwa_detail_iwa_link'>
                    {' '}
                    Items We Accept
                  </Link>{' '}
                  •{' '}
                  <span>
                    {(iwa.categoryData && iwa.categoryData.category_name) ||
                      (iwa.brandData && iwa.brandData.brand_name)}
                  </span>
                </p>
              </ContainerStyled>
            </div>

            {!isEmpty(iwa) && mode === 'category' && (
              <>
                <div className='banner'>
                  <img
                    src={
                      mode === 'category'
                        ? iwa.categoryData && iwa.categoryData.banner_image
                        : iwa.brandData && iwa.brandData.banner_image
                    }
                    alt=''
                  />
                </div>
              </>
            )}

            <>
              <ContainerStyled>
                <div className='page-content'>
                  <div className='page-content-left'>
                    {isLoading ? (
                      <>
                        <div
                          style={{
                            margin: 'auto',
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            height: '32px',
                          }}>
                          <img
                            className='loader'
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                            alt='loading'
                            style={{
                              width: '32px',
                              height: '32px',
                              margin: 'auto',
                              display: 'flex',
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='info-wrapper'>
                          <>
                            <h1 className='d-none'>
                              {mode === 'category'
                                ? iwa.categoryData &&
                                  iwa.categoryData.category_name
                                : iwa.brandData && iwa.brandData.brand_name + ((getCatName) ? ' ' + getCatName : '') }
                            </h1>

                            <span className='iwa-title'>
                              {mode === 'category'
                                ? iwa.categoryData &&
                                  iwa.categoryData.category_name
                                : iwa.brandData && iwa.brandData.brand_name}
                            </span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  mode === 'category'
                                    ? !isEmpty(iwa) &&
                                      iwa.categoryData &&
                                      iwa.categoryData.description
                                    : !isEmpty(iwa) &&
                                      iwa.brandData &&
                                      iwa.brandData.description,
                              }}
                            />

                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  mode === 'category'
                                    ? !isEmpty(iwa) &&
                                      iwa.categoryData &&
                                      iwa.categoryData.why_pawn_your_item
                                    : !isEmpty(iwa) &&
                                      iwa.brandData &&
                                      iwa.brandData.why_pawn_your_item,
                              }}
                            />
                          </>
                        </div>

                        {mode === 'category' &&
                          iwa.brands &&
                          !isEmpty(iwa.brands) && (
                            <>
                              <div className='sub-info-wrapper'>
                                <h2>Brands Accepted</h2>

                                <div className='tags-wrapper'>
                                  <>
                                    {iwa.brands.map((brand, i) => {
                                      return (
                                        <Link
                                          key={i}
                                          to={`/items-we-accept/${brand.slug}/${brand.categorySlug}`}
                                          id={`items_we_accept_brand_${brand.slug}`}>
                                          {brand.brand_name}
                                        </Link>
                                      );
                                    })}
                                  </>
                                </div>
                              </div>
                            </>
                          )}

                        {mode === 'brand' &&
                          iwa.products &&
                          !isEmpty(iwa.products) && (
                            <>
                              <div className='sub-info-wrapper'>
                                <h2>Specific Models Accepted</h2>

                                <div className='tags-wrapper'>
                                  <>
                                    {iwa.products.map((product, i) => {
                                      return (
                                        <Link
                                          key={i}
                                          to={`/pawn/${product.cc_slug}/${iwa.brandData.slug}/${product.slug}`}
                                          id={`items_we_accept_product_${product.slug}`}>
                                          {product.product_name}
                                        </Link>
                                      );
                                    })}
                                  </>
                                </div>
                              </div>
                            </>
                          )}

                        <div className='cta-wrapper'>
                          <h2>Pawning made safer and more convenient.</h2>
                          <p>
                            Unlike traditional pawnshops, you can now get cash
                            within just a few clicks! PawnHero makes it safer
                            and more convenient to pawn your stuff as you never
                            leave the comfort of your home. All your items are
                            fully insured and are carefully stored in our
                            high-security warehouse.
                          </p>

                          {mode === 'brand' && (
                            <div className='iwa-unlike-other'>
                              <div>
                                <p>Free appraisal in minutes</p>
                                <p>
                                  Submit photos and details of your item to get
                                  a free appraisal, then schedule a free pickup.
                                </p>
                              </div>
                              <div>
                                <p>Same day cash</p>
                                <p>
                                  Receive your cash the same day we appraise
                                  your item through your bank account or other
                                  payout options.
                                </p>
                              </div>
                              <div>
                                <p>Get your item back</p>
                                <p>
                                  Repay your loan and we return your item, fully
                                  insured and without any shipping cost!
                                </p>
                              </div>
                            </div>
                          )}

                          <Link
                            to={`${
                              mode === 'category' ? `/pawn/${slug}` : `/pawn/`
                            }`}
                            id={`iwa_pawn_btn`}
                            className='btn'>
                            Pawn now
                          </Link>
                        </div>
                      </>
                    )}
                  </div>

                  <PawnOnline />
                </div>
              </ContainerStyled>
            </>
          </>
        )}
      </ItemsDetailedStyled>
    </Layout>
  );
};

const mapStateToProps = pick(['iwa']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateIwa: (payload) => dispatch(updateIwa(payload)),
  doFetchIwaData: (payload) => dispatch(fetchIwaData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetailed);
