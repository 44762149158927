import {
  getUserByEmail,
  getCurrentUser,
  getCurrentGuestUser,
  postEmailVerify,
  getMyAddress,
  postUserProfile,
} from '../utils/user';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

const secret = process.env.REACT_APP_USER_SECRET;
const encryptor = require('simple-encryptor')({ key: `${secret}` });

export function login(user) {
  return {
    type: LOGIN_USER,
    user,
  };
}

export function logout() {
  return {
    type: LOGOUT_USER,
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function updateUserProfile(user) {
  return {
    type: UPDATE_USER_PROFILE,
    user,
  };
}

export const fetchUserByEmail = (key) => () => {
  return new Promise((resolve, reject) => {
    getUserByEmail(key)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCurrentUser = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const _currenUser = await getCurrentUser();
      const _decUser = encryptor.decrypt(_currenUser);
      resolve(_decUser);
    } catch (err) {
      reject(err);
    }
  });
};

export const fetchCurrentGuestUser = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const _currenUser = await getCurrentGuestUser();
      const _decUser = encryptor.decrypt(_currenUser);
      resolve(_decUser);
    } catch (err) {
      reject(err);
    }
  });
};

export const makeEmailVerify = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postEmailVerify(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMyAddress = (key) => () => {
  return new Promise((resolve, reject) => {
    getMyAddress(key)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUserProfile = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUserProfile(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
