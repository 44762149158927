import React, { useEffect, useState } from 'react';
import { pick } from 'ramda.pick/pick';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchCurrentGuestUser, login } from '../../../actions/user';

// components
import Layout from '../../components/dashboard/layout';
import { ContentStyled } from '../../styles/dashboard.style';
import TicketDetails from '../../components/dashboard/ticketDetails';
import { ContainerStyled } from '../../styles/layout.style';

const GuestTicketView = ({ user, doLogin, doFetchCurrentUser }) => {
  const history = useHistory();
  const { ticketNum } = useParams();
  const [isLoading, isLoadingHandler] = useState(true);

  useEffect(() => {
    isLoadingHandler(true);

    doFetchCurrentUser()
      .then((data) => {
        console.log('data', data);
        const { user, mode, ticket } = data;
        doLogin({ ...user, mode, ticket });
        isLoadingHandler(false);

        if (ticketNum !== ticket) {
          history.push(`/guest/ticket/${ticketNum}`);
        }
      })
      .catch((err) => {
        console.log('catch err', err);
      });

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <ContentStyled>
          {isLoading ? (
            <>
              <div
                style={{
                  margin: 'auto',
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  top: '0',
                  bottom: '0',
                  height: '32px',
                }}>
                <img
                  className='loader'
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                  alt='loading'
                  style={{
                    width: '32px',
                    height: '32px',
                    margin: 'auto',
                    display: 'flex',
                  }}
                />
              </div>
            </>
          ) : (
            <ContainerStyled>
              <TicketDetails ticketNumber={ticketNum} />
            </ContainerStyled>
          )}
        </ContentStyled>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({
  doLogin: (user) => dispatch(login(user)), //update user reducer
  doFetchCurrentUser: () => dispatch(fetchCurrentGuestUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestTicketView);
