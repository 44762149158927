import React, { useState, useRef } from 'react';
import Layout from '../layout';
import { HomeStyled } from '../../styles/home.style';

// import Discount from './discount';
import Activity from './activity';
import Banner from './banner';
import WhyPawnCarousel from './whyPawn';
import HowPawnCarousel from './howPawn';
import ItemsWeAccept from './itemsWeAccept';
import Faq from './faq';
import ShortInfo from './shortInfo';
import WhyPawnModal from './whyPawnModal';
import DiscountModal from './discountModal';
import ServiceAdvisory from './serviceAdvisory';
import PromoCarousel from './promoCarousel';

const Home = () => {
  const bannerRef = useRef(null);

  const [whyPawnToggle, whyPawnToggleHandler] = useState(false);
  const [whyPawnToggleKey, whyPawnToggleKeyHandler] = useState(0);
  const [discountToggle, discountToggleHandler] = useState(false);

  const whyPawnData = [
    {
      title: 'Automobile',
      text: 'If owning a car is on your list, consider it achieved now! Build equity for your dream car today with PawnHero. Receive your cash immediately when you pawn with us.',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/automobile.png',
      id: 'automobile',
    },
    {
      title: 'Travel',
      text: 'If owning a car is on your list, consider it achieved now! Build equity for your dream car today with PawnHero. Receive your cash immediately when you pawn with us.',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/travel.png',
      id: 'travel',
    },
    {
      title: 'Business',
      text: 'Get your initial capital through PawnHero and start a more secure financial future. Be an entrepreneur and start your own business today! Receive your cash immediately when you pawn with us.',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/business.png',
      id: 'business',
    },
    {
      title: 'Education',
      text: 'Education is the passport to a bright and secure future. Cover all education expenses conveniently and receive your cash immediately when you pawn with us.',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/education.png',
      id: 'education',
    },
    {
      title: 'Health',
      text: 'Maintaining good health can be heavy in the pocket and when emergency situations arise, PawnHero can back you up. Receive your cash immediately when you pawn with us.',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/health.png',
      id: 'health',
    },
  ];

  return (
    <Layout
      bannerRef={bannerRef}
      pageModalActive={whyPawnToggle || discountToggle}>
      <HomeStyled>
        <section ref={bannerRef}>
          <Banner />
          <Activity />
        </section>
        <section>
          <PromoCarousel />
        </section>
        <section>
          <ItemsWeAccept />
        </section>
        <section>
          <ServiceAdvisory />
        </section>
        <section>
          <HowPawnCarousel />
        </section>
        <section>
          <WhyPawnCarousel
            whyPawnData={whyPawnData}
            whyPawnToggleHandler={whyPawnToggleHandler}
            whyPawnToggleKeyHandler={whyPawnToggleKeyHandler}
          />
        </section>
        <section>
          <Faq /> 
        </section>
        <section>
          <ShortInfo />
        </section>
        <WhyPawnModal
          whyPawnData={whyPawnData}
          toggle={whyPawnToggle}
          showKey={whyPawnToggleKey}
          close={() => whyPawnToggleHandler(false)}
        />
        <DiscountModal
          toggle={discountToggle}
          close={() => {
            discountToggleHandler(false);
          }}
        />
      </HomeStyled>
    </Layout>
  );
};

export default Home;
