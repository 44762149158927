import React, { useState, useEffect } from 'react';

// styles
import {
  BankDepositStyled,
  AccountDepositStyle,
} from '../../styles/cashOutMethods.style';

// utility
import { fetchBanks } from '../../../actions/coins';
import { useDispatch } from 'react-redux';
import { isEmpty } from '@ramda/isempty/isEmpty';
import isNil from '@ramda/isnil';
import PhoneField from '../shared/phone';
import SelectField from '../shared/select';

export const BankDeposit = (props) => {
  const { onChange, user, values = {}, errFields = [] } = props;

  const {
    bankSelected: selectedVal = null,
    bankMethod = null,
    account_no: accountNumVal = '',
  } = values;

  const dispatch = useDispatch();

  const [selectedOption, selectedOptionHandler] = useState(selectedVal);
  const [bankSelect, bankSelectHandler] = useState(bankMethod); //bank method insta or peso...
  const [accountNum, accountNumHandler] = useState(accountNumVal);
  const [banks, banksHandler] = useState([]);

  // get banks
  useEffect(() => {
    let isCancelled = false;
    dispatch(fetchBanks())
      .then((data) => {
        const _data = data.map((datum) => {
          const { bankName, key } = datum;
          return { ...datum, label: bankName, value: key };
        });
        if (!isCancelled) {
          banksHandler(_data);
        }
      })
      .catch((err) => {
        console.log('err', err);
        if (!isCancelled) {
          banksHandler([]);
        }
      });

    return () => {
      isCancelled = true;
    };
  }, [dispatch]);

  useEffect(() => {
    const _bank = isNil(selectedOption) ? null : selectedOption[bankSelect];
    onChange({
      selectedOption: _bank,
      bankSelected: selectedOption,
      bankMethod: bankSelect,
      account_no: accountNum,
      account_name: user.name,
    });
    // eslint-disable-next-line
  }, [bankSelect, accountNum, selectedOption]);

  return (
    <BankDepositStyled>
      <div className='fields-wrapper'>
        <div className='field-group'>
          <div
            className={`field-label-wrapper ${
              user.name == null || isEmpty(user.name)
                ? 'no-value'
                : 'with-value'
            }`}>
            <input type='text' disabled value={user.name} />
            <label>Account holder name</label>
          </div>
        </div>
        <div className='multi-field'>
          <div className={`field-group `}>
            <div
              className={`field-label-wrapper ${
                selectedOption == null || isEmpty(selectedOption)
                  ? 'no-value'
                  : 'with-value'
              } ${errFields.includes('bank') ? 'is-error' : ''}`}>
              <SelectField
                inputId='pawn_cashout_method_issuing_bank'
                isError={errFields.includes('bank')}
                options={banks}
                className='ctm-select'
                classNamePrefix='ctm'
                value={selectedOption}
                onChange={(value) => {
                  bankSelectHandler(null);
                  selectedOptionHandler(value);
                }}
                isDisabled={isEmpty(banks) ? true : false}
                isLoading={isEmpty(banks)}
                placeholder=''
              />
              <label htmlFor='pawn_cashout_method_issuing_bank'>
                Issuing bank
              </label>
            </div>
            <p className='err-note error'>Please select issuing bank</p>
          </div>
          <div className={`field-group`}>
            <div
              className={`field-label-wrapper ${
                accountNum == null || isEmpty(accountNum)
                  ? 'no-value'
                  : 'with-value'
              }  ${errFields.includes('accountNum') ? 'is-error' : ''}`}>
              <input
                id='pawn_cashout_account_num'
                type='number'
                value={accountNum}
                min={0}
                onKeyDown={(e) => {
                  const re = /^\d+$/;
                  const test = re.test(e.key);
                  const hotKeys = ['Shift', 'Meta', 'Escape', 'Backspace'];
                  if (
                    test ||
                    hotKeys.includes(e.key) ||
                    e.key === 'v' ||
                    e.key === 'V'
                  ) {
                    return true;
                  } else {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  accountNumHandler(e.target.value);
                }}
              />
              <label htmlFor='pawn_cashout_account_num'>Account number</label>
            </div>
            <p className='err-note error'>
              Please enter your valid account number
            </p>
          </div>
        </div>
      </div>
      {!isNil(selectedOption) && (
        <p className='bank-option-note'>Select bank deposit method</p>
      )}
      <div
        className={`multi-field ${
          !isNil(selectedOption) && errFields.includes('selectedOption')
            ? 'is-error'
            : ''
        }`}>
        {!isNil(selectedOption) && (
          <>
            {!isNil(selectedOption.instaPay) && selectedOption.instaPay && (
              <div className='option'>
                <div>
                  <label>
                    <input
                      type='radio'
                      name='bankMethod'
                      value='instaPay'
                      checked={bankSelect === 'instaPay'}
                      onChange={() => bankSelectHandler('instaPay')}
                    />
                    <span className='checkmark' />
                    <img
                      className='instapay-logo'
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/instapay-ico.svg'
                      alt='instaPay'
                    />
                    <span className='note'>Fee: ₱ 10.00</span>
                  </label>
                </div>
                <p className='option-note'>
                  Get your money within 15 minutes after loan approval.
                </p>
              </div>
            )}

            {!isNil(selectedOption.pesoPay) && selectedOption.pesoPay && (
              <>
                <div className='option'>
                  <div>
                    <label>
                      <input
                        type='radio'
                        name='bankMethod'
                        value='pesoPay'
                        checked={bankSelect === 'pesoPay'}
                        onChange={() => bankSelectHandler('pesoPay')}
                      />
                      <span className='checkmark' />
                      <img
                        className='pesonet-logo'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pesonet-ico.svg'
                        alt='instaPay'
                      />
                      <span className='note'>Fee: ₱ 5.00</span>
                    </label>
                  </div>
                  <p className='option-note'>
                    Get your money the next day after loan approval.
                  </p>
                </div>
              </>
            )}

            {!isNil(selectedOption.bankOption) && selectedOption.bankOption && (
              <>
                <div className='option'>
                  <div>
                    <label>
                      <input
                        type='radio'
                        name='bankMethod'
                        value='bankOption'
                        checked={bankSelect === 'bankOption'}
                        onChange={() => bankSelectHandler('bankOption')}
                      />
                      <span className='checkmark' />
                      <img
                        className='banktransfer-logo'
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/banktransfer-ico.svg'
                        alt='instaPay'
                      />
                      <span className='note'>Fee: ₱ 20.00</span>
                    </label>
                  </div>
                  <p className='option-note'>
                    Get your money the next day after loan approval.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {!isNil(selectedOption) && errFields.includes('selectedOption') && (
        <>
          <p className='error'>Please select bank deposit method</p>
        </>
      )}
    </BankDepositStyled>
  );
};

export const CoinsDeposit = (props) => {
  const { onChange, values = {}, errFields = [] } = props;
  const { account_no: accountNumVal = '' } = values;

  // state
  const [accountNum, accountNumHandler] = useState(accountNumVal);

  useEffect(() => {
    onChange({ account_no: accountNum });
    // eslint-disable-next-line
  }, [accountNum]);

  return (
    <AccountDepositStyle>
      <div className={`field-group`}>
        <div
          className={`field-label-wrapper ${
            accountNum == null || isEmpty(accountNum)
              ? 'no-value'
              : 'with-value'
          } ${errFields.includes('accountNum') ? 'is-error' : ''}`}>
          <PhoneField
            id='pawn_cashout_coins'
            value={accountNum}
            onChange={(data) => accountNumHandler(data)}
            country='ph'
            onlyCountries={['ph']}
            masks={{ ph: '.... ... ....' }}
            disableDropdown={true}
            disableCountryCode={true}
            placeholder=''
            inputClass='input-phone'
            containerClass={`phone-input `}
          />
          <label htmlFor='pawn_cashout_coins'>Coins.ph mobile number</label>
        </div>
        <p className='err-note error'>
          Please enter your valid Coins.ph mobile number
        </p>
      </div>
    </AccountDepositStyle>
  );
};

export const GCashDeposit = (props) => {
  const { onChange, values = {}, errFields = [] } = props;
  const { account_no: accountNumVal = '' } = values;

  // state
  const [accountNum, accountNumHandler] = useState(accountNumVal);

  useEffect(() => {
    onChange({ account_no: accountNum });
    // eslint-disable-next-line
  }, [accountNum]);
  return (
    <AccountDepositStyle>
      <div className={`field-group`}>
        <div
          className={`field-label-wrapper ${
            accountNum == null || isEmpty(accountNum)
              ? 'no-value'
              : 'with-value'
          } ${errFields.includes('accountNum') ? 'is-error' : ''}`}>
          <PhoneField
            id='pawn_cashout_gcash'
            value={accountNum}
            onChange={(data) => accountNumHandler(data)}
            country='ph'
            onlyCountries={['ph']}
            masks={{ ph: '.... ... ....' }}
            disableDropdown={true}
            disableCountryCode={true}
            placeholder=''
            inputClass='input-phone'
            containerClass={`phone-input `}
          />
          <label htmlFor='pawn_cashout_gcash'>GCash mobile number</label>
        </div>
        <p className='err-note error'>
          Please enter your valid GCash mobile number
        </p>
      </div>
    </AccountDepositStyle>
  );
};

export const PayMayaDeposit = (props) => {
  const { onChange, values = {}, errFields = [] } = props;
  const { account_no: accountNumVal = '' } = values;

  // state
  const [accountNum, accountNumHandler] = useState(accountNumVal);

  useEffect(() => {
    onChange({ account_no: accountNum });
    // eslint-disable-next-line
  }, [accountNum]);

  return (
    <AccountDepositStyle>
      <div className={`field-group`}>
        <div
          className={`field-label-wrapper ${
            accountNum == null || isEmpty(accountNum)
              ? 'no-value'
              : 'with-value'
          } ${errFields.includes('accountNum') ? 'is-error' : ''}`}>
          <PhoneField
            id='pawn_cashout_paymaya'
            value={accountNum}
            onChange={(data) => accountNumHandler(data)}
            country='ph'
            onlyCountries={['ph']}
            masks={{ ph: '.... ... ....' }}
            disableDropdown={true}
            disableCountryCode={true}
            placeholder=''
            inputClass='input-phone'
            containerClass={`phone-input `}
          />
          <label htmlFor='pawn_cashout_paymaya'>PayMaya mobile number</label>
        </div>
        <p className='err-note error'>
          Please enter your valid PayMaya mobile number
        </p>
      </div>
    </AccountDepositStyle>
  );
};
