import React from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { isNil } from '@ramda/isnil/isNil';

const { PawnNavStyled } = require('../../styles/pawn-navigation.style');

const PawnNavigation = ({ pawn }) => {
  const { position } = pawn;

  const steps = [
    {
      label: 'Category',
      code: 'category',
      hasValue: position > 0 ? true : false,
    },
    {
      label: 'Brand',
      code: 'brand',
      hasValue: position > 1 ? true : false,
    },
    {
      label: 'Model',
      code: 'model',
      hasValue: position > 2 ? true : false,
    },
    {
      label: 'Attributes',
      code: 'attr',
      hasValue: position > 3 ? true : false,
    },
    {
      label: 'Submit',
      code: 'submit',
    },
  ];

  const menuL = steps.length;
  const menuD = steps.filter((step) => step.hasValue).length;
  const _percent = menuL - 1 === menuD ? 99 : (menuD / menuL) * 100;

  return (
    <PawnNavStyled percent={_percent}>
      <>
        <div className='menu-wrapper'>
          <nav>
            <ul>
              {!isNil(pawn) ? (
                <>
                  {steps.map((step, i) => {
                    return (
                      <li
                        key={i}
                        className={`${step.hasValue ? 'done' : ''} ${
                          i === pawn.position ? 'current' : ''
                        }`}>
                        {step.label}
                      </li>
                    );
                  })}
                </>
              ) : (
                <>loading</>
              )}
            </ul>
          </nav>
        </div>
        <div className='menu-wrapper-mobile'>
          <p>
            Step {pawn.position + 1} of {steps.length}
            {' : '}
            <span>
              {' '}
              {steps[pawn.position] ? steps[pawn.position].label : ''}
            </span>
          </p>
        </div>
        <div className='process-bar-wrapper'>
          <div className='process-status'></div>
        </div>
      </>
    </PawnNavStyled>
  );
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PawnNavigation);
