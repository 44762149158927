import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;


export const postOtp = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { email, number } = payload;

    try {
      const response = await axios.post('/api/otp/make', { email, number },config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
}

export const postOtpVerify = (payload) => {
  // console.log('will fire verify api here', payload);
  return new Promise( async (resolve, reject) => {
    try {
      const response = await axios.post('/api/otp/verify', payload,config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
}

export const getIsEnableOtp = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('/api/otp/is_enable', config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};