import React, { useState, useEffect } from 'react';
import Layout from '../layout';
import ProgressNav from '../../components/redeem/progressNav';
import Summary from '../../components/redeem/summary';
import WhyPawnhero from '../../components/whyPawnhero';
import Offer from '../../components/redeem/offer';
import Booking from '../../components/redeem/booking';
import Payment from '../../components/redeem/payment';
// import Review from '../../components/redeem/review';

// styles
import { AcceptanceStyle } from '../../styles/acceptance.style';
import { ContainerStyled } from '../../styles/layout.style';

// utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { fetchSummaryTicket } from '../../../actions/ticket';
import { useHistory, useParams } from 'react-router-dom';
import { resetRedeem, updateRedeem } from '../../../actions/redeem';
import { getProductName } from '../../../utils/helper';
import capitalize from 'lodash.capitalize';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';

const Redeem = ({
  doFetchSummaryTicket,
  doResetRedeem,
  doUpdateRedeem,
  redeem,
}) => {
  const { ticketNum } = useParams();
  const history = useHistory();
  const { position: currentPos = 0, data: loanData = {} } = redeem;

  // states
  const [animationDone, animationDoneHandler] = useState(false);
  const [error, errorHandler] = useState(null);
  const [loading, loadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);
  const [ticketOwner, ticketOwnerHandler] = useState(null);
  const [offers, offersHandler] = useState({ renewals: [] });

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getTicketInfo();
    }
    return () => {
      cancel = true;
      doResetRedeem();
    };
    // eslint-disable-next-line
  }, []);

  const getTicketInfo = async () => {
    try {
      loadingHandler(true);
      const {
        data,
        data: { statusRaw, user },
        success,
      } = await doFetchSummaryTicket({ ticketNum });

      if (success) {
        if (
          statusRaw !== 'LR' &&
          statusRaw !== 'MP' &&
          statusRaw !== 'LM' &&
          statusRaw !== 'WA' &&
          statusRaw !== 'WI'
        ) {
          history.push('/pawn');
        } else {
          doUpdateRedeem({ ticketSummary: data });
          const { items } = data;
          const productName = getProductName(items[0].name);

          ticketDataHandler({ ...data, productName });
          const { firstName } = user || { firstName: 'Guest' };
          ticketOwnerHandler(firstName);
          loadingHandler(false);
        }
      } else {
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        loadingHandler(false);
      }
    } catch (err) {
      console.log('err get doFetchSummaryTicket', err);
      errorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
      loadingHandler(false);
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPos]);

  return (
    <>
      <Layout style={{ overflow: 'hidden' }}>
        <AcceptanceStyle
          className={`window-height ${animationDone ? 'done-animate' : ''}`}>
          {error ? (
            <p
              style={{
                textAlign: 'center',
                color: 'red',
              }}>
              {error}
            </p>
          ) : (
            <>
              {loading ? (
                <>
                  <div
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      left: '0',
                      right: '0',
                      top: '0',
                      bottom: '0',
                      height: '32px',
                    }}>
                    <img
                      className='loader'
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                      alt='loading'
                      style={{
                        width: '32px',
                        height: '32px',
                        margin: 'auto',
                        display: 'flex',
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {currentPos !== 0 && <ProgressNav />}
                  {currentPos === 0 && (
                    <>
                      <h1>Welcome back, {capitalize(ticketOwner)}!</h1>
                      <p>
                        You can now redeem your
                        <span>
                          {!ticketData && isNil(ticketData)
                            ? ' '
                            : ' ' + ticketData.productName}
                        </span>
                        {!isEmpty(offers.renewals)
                          ? ', or request for a loan renewal.'
                          : '.'}
                      </p>
                    </>
                  )}
                  <ContainerStyled className='container-wrapper'>
                    <div className='page-content-wrapper'>
                      <div className='page-content'>
                        {currentPos === 0 && (
                          <>
                            <Offer
                              animationDoneHandler={animationDoneHandler}
                              callback={offersHandler}
                            />
                          </>
                        )}
                        {currentPos === 1 && (
                          <>
                            <Booking
                              animationDoneHandler={animationDoneHandler}
                            />
                          </>
                        )}
                        {currentPos === 2 && (
                          <Payment
                            animationDoneHandler={animationDoneHandler}
                          />
                        )}
                        {/* {currentPos === 3 && (
                          <Review animationDoneHandler={animationDoneHandler} />
                        )} */}
                      </div>
                      {!isEmpty(loanData) && currentPos !== 0 ? (
                        <Summary />
                      ) : (
                        <WhyPawnhero />
                      )}
                    </div>
                  </ContainerStyled>
                </>
              )}
            </>
          )}
        </AcceptanceStyle>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['redeem']);
const mapDispatchToProps = (dispatch) => ({
  doFetchSummaryTicket: (ticket) => dispatch(fetchSummaryTicket(ticket)),
  doResetRedeem: () => dispatch(resetRedeem()),
  doUpdateRedeem: (redeem) => dispatch(updateRedeem(redeem)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Redeem);
