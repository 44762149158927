import styled from 'styled-components';

export const PhoneInputStyled = styled.div`
  .invalid {
    border: 1px solid #e2574c;
    border-radius: 5px;
  }

  .flag-dropdown {
    display: none;
  }

  .input-phone,
  input {
    padding: 5.5px 10px !important;
    height: unset !important;
    line-height: normal !important;
    color: #354f65;
  }
`;

export const DatePickerStyled = styled.div`
  width: 100%;
  background: pink;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;

  &.disabled {
    background: #ebebeb;
  }
  & > img {
    width: 12px;
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      input {
        width: 100%;
        outline: none;
        border: 0;
        color: #354f65;
        font-size: 14px;

        &::placeholder {
          color: #dadada;
          font-size: 14px;
        }
      }
    }
  }

  .ctm-date-picker {
    button {
      padding: 0;
      outline: none;
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background: #fff;
      }

      .react-datepicker__day--disabled {
        background: #ebebeb;
        border-radius: 5px;
      }
    }
  }
`;

export const SharedModalStyled = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  ${(props) => (props.background ? `background: ${props.background};` : '')}
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  ${(props) => (props.isOpen ? 'display : flex;' : 'display : none;')}
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  & .modal-content-wrapper {
    background: #327fbe;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    max-width: 554px;
    padding: 40px 20px;
    position: relative;

    .modal-close {
      width: 25px;
      height: 25px;
      top: 10px;
      right: 10px;
      position: absolute;
      cursor: pointer;
    }

    .logo {
      width: 112px;
      display: block;
      margin: auto;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      margin-top: 20px;
      text-align: center;
    }

    .note {
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
    }

    button {
      border: 0;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      background: #ff7019;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      padding: 5px 10px;
      display: block;
      margin: 20px auto 0;
    }
  }
`;

export const SharedCarouselStyled = styled.div`
  div.carousel-container {
    img {
      pointer-events: none;
    }

    ul.caraousel-dots-container {
      li {
        button {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
`;

export const OwlCarouselStyled = styled.div`
  .owl-carousel {
    .owl-stage {
      padding-bottom: 20px;
    }

    .owl-dots {
      display: flex;
      justify-content: center;

      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        margin: 0 5px;
        outline: none;

        &.active {
          background: #68c552;
        }
      }
    }

    &.aqua {
      .owl-dots {
        button {
          background: #b2b1b1;
          &.active {
            background: #327fbe;
          }
        }
      }
    }
  }
`;

export const MyDropZoneStyle = styled.div`
  width: 100%;

  .preview-wrapper {
    border: 1px dashed #e1e6e9;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    height: 150px;
    display: flex;
    background: #fafafa;
  }

  .uploader {
    background: #fafafa;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    .note-wrapper {
      display: flex;
      align-items: center;

      img {
        width: 40px;
      }

      p {
        margin-left: 20px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #354f65;
      }
    }

    &.with-files {
      img {
        width: 20px;
      }
      .note-wrapper {
        flex-flow: column;
        margin-top: 10px;
      }

      p {
        margin-left: 0;
        text-align: center;
        padding: 0 10px;
      }
    }
  }

  .thumbnail-wrapper,
  .uploader.with-files {
    width: 100px;
    height: 100px;
    background: #fff;
    border: 1px dashed #e1e6e9;
    margin: 10px;
  }

  .thumbnail-wrapper {
    position: relative;

    .remove-btn {
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .image-wrapper {
      width: 100%;
      height: 100%;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: none;
        object-position: center;
      }
    }
  }
`;
