export const UPDATE_PAWN = 'UPDATE_PAWN';
export const SET_PAWN = 'SET_PAWN';
export const GET_PAWN = 'GET_PAWN';
export const RESET_PAWN = 'RESET_PAWN';

export const initialState = {
  position: 0,
  prod_id: null,
  attr: null,
  formValues: null,
  categoryData: null,
  brandData: null,
  productData: null,
};

export function updatePawn(pawn) {
  return {
    type: UPDATE_PAWN,
    pawn,
  };
}

export function setPawn(pawn) {
  return {
    type: SET_PAWN,
    pawn,
  };
}

export function resetPawn() {
  return {
    type: RESET_PAWN,
  };
}
