import styled from 'styled-components';

export const BankDepositStyled = styled.div`
  & > p.error {
    font-style: italic;
    font-size: 8px;
    line-height: 11px;
    color: #e2574c;
  }
  p.bank-option-note {
    color: #354f65;
    font-size: 14px;
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 20px;
  }

  .fields-wrapper {
    margin-top: 30px;

    .field-group {
      padding: 14px 0 11px;
      margin-bottom: 5px;
      margin-top: 5px;

      .field-label-wrapper {
        position: relative;

        label {
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          bottom: 6.5px;
          transition: 0.3s;
          left: 10px;
          color: #8b959c;
        }

        input:focus + label,
        .phone-input.focus + label,
        .ctm-date-picker.focus + label,
        .shared-calendar.focus + label,
        .ctm-select.focus + label,
        &.with-value label {
          display: block;
          bottom: 100%;
          left: 0;
          color: #327fbe;
          font-size: 10px;
        }

        &.is-error {
          input {
            border-color: #e2574c !important;
          }
        }

        /* ctm-select */
        .ctm-select {
          &.is-error {
            .ctm__control {
              border-color: #e2574c !important;
            }
          }
          & > div.ctm__control {
            min-height: unset;
            border: 1px solid #dadada !important;
            &.ctm__control--is-focused {
              border: 1px solid #327fbe !important;
            }
            & > div:first-child {
              padding: 3px 10px;

              & > div {
                margin: unset;
                font-size: 14px;
                color: #354f65;
                position: static;
                top: unset;
                transform: none;
              }
            }
            & > div:last-child {
              & > span {
                display: none;
              }

              & > div {
                padding: 3px;
              }
            }
          }

          &.no-val {
            & > div {
              & > div:first-child {
                & > div:not(.ctm__option) {
                  color: #b2b1b1;
                }
              }
            }
          }
        }

        & > input[type='text'],
        & > input[type='number'] {
          width: 100%;
          border-radius: 5px;
          border: 1px solid #dadada;
          padding: 5px 10px;
          font-size: 14px;
          outline: none;
          color: #354f65;

          &:disabled {
            background: #dadada;
            color: #8b959c;
          }

          &::placeholder {
            color: #dadada;
          }
        }

        & > .ctm--is-disabled {
          .ctm__control--is-disabled {
            background: #ebebeb;
          }
        }
      }

      p.err-note {
        display: none;
        position: absolute;
        font-style: italic;
        font-size: 8px;
        line-height: 11px;
        color: #e2574c;
      }

      .field-label-wrapper.is-error + p.err-note {
        display: block;
      }
    }

    .multi-field {
      display: flex;
      flex-flow: row;
      align-items: flex-end;
      margin: auto -5px;

      .field-group {
        width: 100%;
        margin: 5px;
      }

      @media (max-width: 768px) {
        display: block;
        margin: auto;

        & > .field-group {
          margin: 10px 0 10px !important;
        }
      }
    }
  }

  /* Select bank deposit method */
  & > .multi-field {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.is-error {
      div.option > div {
        border: 1px solid #e2574c !important;
      }
    }
    @media (max-width: 768px) {
      display: block;

      div.option {
        width: 100%;
      }
    }
  }

  div.option {
    width: 50%;
    margin: 10px -5px 0;

    & > p.option-note {
      font-size: 10px;
      color: #354f65;
      margin-top: 5px;
      margin-left: 10px;
      font-style: italic;
    }

    div {
      /* width: 45%; */
      border: 1px solid #dadada;
      border-radius: 5px;
      padding: 15px 10px;
      display: flex;

      @media (max-width: 425px) {
        width: 100%;
      }

      & > label {
        display: flex;
        align-items: center;
        width: 100%;

        input {
          display: none;
        }

        .checkmark {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid #354f65;
          display: block;
          margin-right: 10px;
        }

        img.instapay-logo,
        img.pesonet-logo {
          max-width: 60px;
        }

        img.banktransfer-logo {
          max-width: 98px;
        }

        span.note {
          margin-left: auto;
          color: #354f65;
          font-size: 14px;
          font-weight: 600;
        }

        input:checked + .checkmark {
          display: flex;
          justify-content: center;
          align-items: center;

          &:after {
            content: '';
            width: 15px;
            height: 15px;
            background: #68c552;
            border-radius: 50%;
          }
        }
      }
    }
  }
`;

export const AccountDepositStyle = styled.div`
  margin-top: 20px;

  .field-group {
    padding: 14px 0 11px;
    margin-bottom: 5px;
    margin-top: 5px;

    .field-label-wrapper {
      position: relative;

      label {
        font-size: 14px;
        font-weight: 400;
        position: absolute;
        bottom: 6.5px;
        transition: 0.3s;
        left: 10px;
        color: #8b959c;
      }

      input:focus + label,
      .phone-input.focus + label,
      .ctm-date-picker.focus + label,
      .shared-calendar.focus + label,
      .ctm-select.focus + label,
      &.with-value label {
        display: block;
        bottom: 100%;
        left: 0;
        color: #327fbe;
        font-size: 10px;
      }

      input {
        border: 1px solid #dadada;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        color: #8b959c;
        font-size: 14px;
        outline: none;

        &:disabled {
          background: #ebebeb;
        }

        &::placeholder {
          color: #dadada;
        }
      }

      &.is-error {
        input {
          border-color: #e2574c !important;
        }
      }
    }

    p.err-note {
      display: none;
      position: absolute;
      font-style: italic;
      font-size: 8px;
      line-height: 11px;
      color: #e2574c;
    }

    .field-label-wrapper.is-error + p.err-note {
      display: block;
    }
  }
`;
