import { combineReducers } from 'redux';
import user from './user';
import pawn from './pawn';
import dashboard from './dashboard';
import ticket from './ticket';
import address from './adress';
import acceptance from './acceptance';
import redeem from './redeem';
import offer from './offer';
import category from './category';
import loan from './loan';
import release from './release';
import iwa from './iwa';
import page from './page';
import socket from './socket';
import notification from './notification';

export default combineReducers({
  user,
  pawn,
  dashboard,
  ticket,
  address,
  acceptance, //initial offer
  offer, //final offer
  redeem,
  category, //ssr state -> iwa category
  loan,
  release, //ssr state -> release ticket
  iwa, //ssr state -> iwa pages
  page, //page settings
  socket,
  notification,
});
