export const coverageData = [
  {
    title: 'Philippine Loan Sharks Tested by New Rivals',
    content: `Financial services fueled by soaring smartphone use are starting to challenge the dominance of illegal lenders and pawnshops as the country’s dominant small lenders.`,
    link:
      'https://www.wsj.com/articles/philippine-loan-sharks-tested-by-new-rivals-1439926259',
  },
  {
    title: 'Pressure Creates Diamonds: 7 Startups Heating Up The Tech Industry',
    content: `When you hear the word “startup,” the first vision that comes to mind is often of a scrappy budding business revolving around a single product or idea.`,
    link:
      'https://www.forbes.com/sites/steveolenski/2015/09/01/pressure-creates-diamonds-7-startups-heating-up-the-tech-industry/',
  },
  {
    title:
      'PawnHero, LoanSolutions partner to provide lending options to Filipinos',
    content: `In the Philippines, applying for a loan is tedious work; most of the time, Filipinos need to take multiple trips to different banks just to check what type of loans each bank offers.`,
    link:
      'https://e27.co/pawnhero-and-loansolutions-partner-and-provide-lending-options-to-filipinos-20150507/',
  },
  {
    title:
      'PH startups PawnHero, LoanSolutions tie up to enhance lending options',
    content: `Philippine startups PawnHero and LoanSolutions teamed up recently, seeking to reinvent lending and other financial practices.`,
    link:
      'https://www.dealstreetasia.com/stories/ph-startups-pawnhero-loansolutions-tie-up-to-enhance-lending-options-6216/',
  },
  {
    title: 'PawnHero modernises the pawning industry',
    content: `Philippine-based online pawnshop, PawnHero.ph, is out to transform one of the world's oldest businesses with a modern alternative.`,
    link:
      'http://www.omnichannelasia.com/articles/pawn-hero-modernises-one-world%E2%80%99s-oldest-businesses',
  },
  {
    title: 'Online pawnshop in the PH goes live',
    content: `Online pawnshop in the PH goes live PawnHero, an online service that aims to address the short-term financial needs of Pinoys has just been launched It leverages on turning millions of smartphones into instant pawnshops.`,
    link:
      'http://www.interaksyon.com/infotech/online-pawnshop-in-the-ph-goes-live',
  },
  {
    title: 'The Big Idea with David Margendorff',
    content: `PawnHero Chairman and Co-founder David Margendorff shares the big idea behind PawnHero.ph, the first online pawnshop in Southeast Asia. Get to know the many ways online pawning can help more Filipinos get financial inclusion.`,
    link: 'http://www.regents.ac.uk/news/the-big-idea-with-david-margendorff',
  },
  {
    title: 'PawnHero targets more Pinoy smartphone users',
    content: `Southeast Asia's first online pawnshop targets over 27 million smartphone users in the Philippines with its mobile-friendly and easy to use online pawning and selling website.`,
    link:
      'http://newsbytes.ph/2015/03/02/pawnhero-targets-more-pinoy-smartphone-users-2/',
  },
  {
    title: `PawnHero's online pawnshop goes live in the Philippines`,
    content: `PawnHero's online pawnshop goes live in the Philippines Pinoys can now get a short-term loan by pawning pre-loved items online. Unlike traditional pawnshops, PawnHero offers lower interest rates and accepts a wider variety of items.`,
    link: 'https://www.rappler.com/technology/pawnhero-philippines-launch',
  },
  {
    title: `24 startups in Asia that caught our eye`,
    content: `PawnHero.ph made it in Techinasia.com's list of Asian tech startups. It is listed alongside other forward-thinking Asian startups from Japan, Singapore, Taiwan, and more.`,
    link: 'https://www.techinasia.com/asian-startup-list-1-march-2015',
  },
  {
    title: `Online pawn debuts in the Philippines`,
    content: `Pioneer online pawnshop, PawnHero.ph, has come to the rescue of Filipinos with urgent cash needs. It seeks to solve the problem of expensive credit for base-of-the-pyramid consumers.`,
    link: 'https://www.retailnews.asia/online-pawn-debuts-in-philippines/',
  },
  {
    title: `Pioneer online pawnshop, to the rescue`,
    content: `Southeast Asia's first online pawnshop recently launched to start solving the problem of expensive credit for base-of-the-pyramid customers. It offers the lowest interest rates and accept a wider range of items.`,
    link: 'https://www.techinasia.com/pawnhero-philippines-online-pawnshop',
  },
  {
    title: `PawnHero is ready 2GO`,
    content: `PawnHero.ph, the first online pawnshop in Southeast Asia, in partnership with 2GO, begins a pawning revolution in the Philippines through pioneering online pawning and selling website.`,
    link:
      'http://www.geekymobilegadgets.com/hbt.php?rewrite=news/pawnhero-is-ready-2go',
  },
  {
    title: `The 5 PH tech startups at your service`,
    content: `PawnHero.ph, Philippines' first online pawnshop, secured "executive edge" in disrupting the Philippine pawning industry as one of the emerging firms to offer online solutions to the Pinoy market.`,
    link:
      'https://www.rappler.com/business/executive-edge-five-ph-tech-startups',
  },
  {
    title: `Online pawnshop launches in PH as 1st in SEA`,
    content: `In a country where pawnshops are almost everywhere, a new player rises--PawnHero. PawnHero.ph introduces a new service that no other pawnshop in the country, let alone Southeast Asia has--online pawning.`,
    link:
      'http://cebutechblogger.com/2015/03/pawnheroph-first-online-pawnshop-in-southeastasia',
  },
  {
    title: `Filipino team launched 1st online pawnshop in SEA`,
    content: `PawnHero.ph, the first online pawnshop in Southeast Asia, recently launched in the Philippines to solve the problem of expensive credit for base-of-the-pyramid consumers in the country. `,
    link:
      'https://www.kalibrr.com/advice/2015/02/why-manila-is-the-best-place-to-be-an-expat',
  },
  {
    title: `Online pawning: the next big thing`,
    content: `The first online pawnshop in Southeast Asia turned over 27 million smartphones in the Philippines into a mobile pawnshop. This targets over 1 million pawners who visit a pawnshop everyday.`,
    link:
      'http://www.geekymobilegadgets.com/hbt.php?rewrite=news/online-pawning-the-next-big-thing',
  },
  {
    title: `The next big thing is online pawning`,
    content: `PawnHero.ph recently launched in the Philippines and became the first online pawnshop in Southeast Asia. The new site offers a cheaper and more convenient solution to urgent cash needs.`,
    link:
      'http://www.ilonggotechblog.com/2015/03/online-pawning-next-big-thing.html',
  },
  {
    title: `24/7 pawnshop now online`,
    content: `Newly launched online pawnshop, PawnHero.ph, will revolutionize the way you turn your items to cash through online pawning and selling. This first of its kind service offers the lowest interest rates in the country.`,
    link:
      'http://geekymobilegadgets.com/news/online-pawning-pawnhero-ph-247-pawnshop',
  },
];
