import {
  INIT_ADDRESS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_SINGLE,
} from '../actions/address';
import update from 'immutability-helper';

const address = (state = null, action) => {
  switch (action.type) {
    case INIT_ADDRESS:
      return action.address;
    case UPDATE_ADDRESS:
      return update([], { $merge: action.address });
    case UPDATE_ADDRESS_SINGLE: {
      const { address } = action;

      const _address = state.map((e) => {
        if (e.type === address.type) {
          const { address: __address, barangay, city, province } = address;

          return {
            ...e,
            address: __address,
            barangay_id: barangay.value,
            barangay_name: barangay.label,
            city_id: city.value,
            city_name: city.label,
            province_id: province.value,
            province_name: province.label,
          };
        } else {
          return e;
        }
      });

      return update(state, { $merge: _address });
    }
    default:
      return state;
  }
};

export default address;
