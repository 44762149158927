export const INIT_REDEEM = 'INIT_REDEEM';
export const UPDATE_REDEEM = 'UPDATE_REDEEM';
export const RESET_REDEEM = 'RESET_REDEEM';

export const updateRedeem = (redeem) => {
  return {
    type: UPDATE_REDEEM,
    redeem,
  };
};

export const resetRedeem = () => {
  return {
    type: RESET_REDEEM,
  };
};
