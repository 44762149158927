import { handleSuccess, handleError } from './globals';

const axios = require('axios');
const config = require('./axios.config').config;
const jwt = require('jsonwebtoken');

export const postLogin = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { email, password } = payload;

    try {
      const response = await axios.post(
        '/api/auth/login',
        { email, password },
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postSignUp = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/auth/signup', payload, config)
      .then((response) => {
        resolve(handleSuccess(response));
      })
      .catch((err) => {
        reject(handleError(err));
      });
  });
};

export const isAuth = ({ userToken: token }) => {
  if (token) {
    const user = jwt.decode(token);
    return user;
  } else {
    return false;
  }
};

export const postGuestLogin = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('/api/auth/guest', payload, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postLogout = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('/api/auth/logout', {}, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postForgot = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('/api/auth/forgot', payload, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postReset = (_payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token, payload } = _payload;
      const response = await axios.post(
        `/api/auth/reset/${token}`,
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postActivate = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/auth/customer/activate`,
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postChangeCredentials = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/auth/update/credentials`,
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
