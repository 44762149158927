import React, { useEffect, useState } from 'react';

import { menus } from '../../components/dashboard/layout';

// utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';
import { useParams } from 'react-router';
import { fetchNotificationById } from '../../../actions/notification';
import { NotificationFormWrapperstyle } from '../../styles/notification.style';
import { Link } from 'react-router-dom';
import ElipseLoader from '../../components/shared/elipseLoader';

// styles
const {
  ContentStyled,
  DashboardNavHeader,
} = require('../../styles/dashboard.style');

const NotificationView = ({ dashboard, doFetchNotificationById }) => {
  const { activeMenu } = dashboard;

  const { noti_id } = useParams();

  const [notiData, notiDataHandler] = useState(null);
  const [isLoading, isLoadingHandler] = useState(true);

  const getData = () => {
    return new Promise((resolve, reject) => {
      doFetchNotificationById(noti_id)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            resolve(data);
          } else {
            reject({ success: false });
          }
        })
        .catch((err) => {
          reject({ error: err, success: false });
        });
    });
  };

  useEffect(() => {
    let cancel = false;

    getData()
      .then((data) => {
        console.log('data', data);
        if (!cancel) {
          isLoadingHandler(false);
          notiDataHandler(data);
        }
      })
      .catch((err) => {
        if (!cancel) {
          isLoadingHandler(false);
        }
      });

    return () => (cancel = true);

    // eslint-disable-next-line
  }, []);

  return (
    <ContentStyled>
      <DashboardNavHeader>
        <Link to='/dashboard/notification'>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/header-nav-back-ico.svg'
            alt='back'
          />
        </Link>
        <div>
          <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
          <h1>{menus[activeMenu] ? menus[activeMenu].text : ''}</h1>
        </div>
      </DashboardNavHeader>
      <div className='content-wrapper'>
        <NotificationFormWrapperstyle>
          {isLoading ? (
            <>
              <ElipseLoader />
            </>
          ) : (
            <>
              {notiData && (
                <>
                  <div className='header-wrapper'>
                    <p>Pawn Ticket {notiData.title}</p>
                  </div>
                  <div className='body-wrapper'>
                    <div className='body-header-wrapper'>
                      <p className='title'>{notiData.title}</p>
                      <p className='time-stamp'>{notiData.inserted_at}</p>
                    </div>
                    <p>{notiData.message}</p>
                  </div>
                </>
              )}
            </>
          )}
        </NotificationFormWrapperstyle>
      </div>
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard']);
const mapDispatchToProps = (dispatch) => ({
  doFetchNotificationById: (payload) =>
    dispatch(fetchNotificationById(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationView);
