import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerStyled } from '../../styles/shared.style';

//utility
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchHolidays } from '../../../actions/time';
const momentBD = require('moment-business-days');

const DateBookingPickerCTM = (props) => {
  const {
    selectedDate,
    onChange,
    minDate,
    placeholder,
    currentDate = new Date(),
    doFetchHolidays,
    id,
    noTimeLimit = false,
    disabled = false,
    conditons,
  } = props;

  const { max, cutoff_time, available_days } = conditons;

  if (available_days) {
    const _available_days = available_days
      .split(',')
      .map((e) => parseInt(e))
      .sort();

    momentBD.updateLocale('us', {
      workingWeekdays: _available_days,
    });
  } else {
    momentBD.updateLocale('us', {
      workingWeekdays: [1, 2, 3, 4, 5, 6], //default is monday to friday
    });
  }

  // states
  const [focus, focusHandler] = useState(false);
  const [holidays, holidaysHandler] = useState([]);
  const isWorkingDay = (date) => {
    return momentBD(date).isBusinessDay(); //disable NOT WORKING DAYS
  };

  const timeValidator = moment(
    moment(currentDate).format('YYYY-MM-DD') + ' ' + cutoff_time
  ); //time setting

  const isTimeValid = moment(minDate).isBefore(timeValidator);

  const _minDate =
    momentBD(minDate).isBusinessDay() && (noTimeLimit ? true : isTimeValid)
      ? minDate
      : momentBD().nextBusinessDay().toDate();

  const _maxDate = momentBD(_minDate).businessAdd(max).toDate();

  useEffect(() => {
    doFetchHolidays().then((data) => {
      holidaysHandler(data);
    });
    // eslint-disable-next-line
  }, []);

  // add holiday
  momentBD.updateLocale('us', {
    holidays: holidays,
    holidayFormat: 'MM/DD/YYYY',
  });

  return (
    <DatePickerStyled
      className={`shared-calendar ${focus ? 'focus' : ''} ${
        disabled ? 'disabled' : ''
      }`}>
      <DatePicker
        id={id}
        calendarClassName='ctm-date-picker'
        filterDate={isWorkingDay}
        selected={selectedDate}
        onChange={onChange}
        minDate={_minDate}
        maxDate={_maxDate}
        placeholderText={placeholder}
        onFocus={() => focusHandler(true)}
        onBlur={() => focusHandler(false)}
        disabled={disabled}
      />
      <img
        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/datepicker-ico.svg'
        alt='datepicker'
      />
    </DatePickerStyled>
  );
};

// todo add holidays
const mapDispatchToProps = (dispatch) => ({
  doFetchHolidays: () => dispatch(fetchHolidays()),
});

export default connect(null, mapDispatchToProps)(DateBookingPickerCTM);
