import React from 'react';

import { Route, Switch } from 'react-router-dom';
// import Dashboard from './dashboard';
import TicketPage from '../pages/dashboard/ticket';
import TicketViewPage from '../pages/dashboard/ticketView';

// import NotFoundPage from '../pages/NotFound';
import AccountPage from '../pages/dashboard/account';
import AddressPage from '../pages/dashboard/address';
import ChatBoxPage from '../pages/dashboard/chat';
import Layout from '../components/dashboard/layout';
import Notification from '../pages/dashboard/notification';
import ConfirmLoanPaymentFormfig from '../pages/dashboard/confirmLoanPayment';
import AddressFormPage from '../pages/dashboard/addressForm';
import NotificationView from '../pages/dashboard/notificationView';
import AccountCredential from '../pages/dashboard/accountCredential';

export const DashboardRoutes = ({ socketRef }) => {
  return (
    <>
      <Layout>
        <Switch>
          <Route exact path='/dashboard' component={TicketPage} />
          <Route
            exact
            path='/dashboard/ticket/:ticketNum'
            component={TicketViewPage}
          />
          <Route
            exact
            path='/dashboard/notification'
            component={Notification}
          />
          <Route
            exact
            path='/dashboard/notification/:noti_id'
            component={NotificationView}
          />
          <Route
            exact
            path='/dashboard/confirm-loan-payment'
            component={ConfirmLoanPaymentFormfig}
          />
          <Route
            exact
            path='/dashboard/chat'
            component={(props) => (
              <ChatBoxPage {...props} socketRef={socketRef} />
            )}
          />
          <Route exact path='/dashboard/address' component={AddressPage} />
          <Route
            exact
            path='/dashboard/address/new'
            render={(props) => <AddressFormPage {...props} mode={'new'} />}
          />
          <Route
            exact
            path='/dashboard/address/:id'
            component={AddressFormPage}
          />
          <Route exact path='/dashboard/account' component={AccountPage} />
          <Route
            exact
            path='/dashboard/account/credential'
            component={AccountCredential}
          />

          {/* <Route exact path='/dashboard/ticket' component={TicketPage} />        
          <Route
            exact
            path='/dashboard/account/edit'
            component={(props) => <AccountPage {...props} mode={'edit'} />}
          />
       
          <Route component={NotFoundPage} /> */}
        </Switch>
      </Layout>
    </>
  );
};
