import React, { useEffect, useState } from 'react';
import Loadable from '@loadable/component';
import { OwlCarouselStyled } from '../../styles/shared.style';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const SharedCarousel = (props) => {
  const { children, options, theme = '' } = props;
  const [isWindow, isWindowHandler] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (window) {
        isWindowHandler(true);
      } else {
        isWindowHandler(false);
      }
    }
  }, []);

  return (
    <OwlCarouselStyled>
      {isWindow ? (
        <OwlCarousel {...options} className={`owl-carousel ${theme}`}>
          {children}
        </OwlCarousel>
      ) : (
        <>{children}</>
      )}
    </OwlCarouselStyled>
  );
};

export default SharedCarousel;
