import React, { useEffect, useState } from 'react';

//utils
import { pick } from 'ramda.pick/pick';
import { connect } from 'react-redux';
import { makeOtp, isEnableOtp } from '../../../actions/otp';
import { updatePawn } from '../../../actions/pawn';
import { fetchUserByEmail } from '../../../actions/user';
import { validateEmail } from '../../../utils/helper';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import moment from 'moment-timezone';
import { timezone } from '../../../server/config/moment';

// components
import PhoneField from '../shared/phone';
import { makeTicket } from '../../../actions/ticket';
import { useHistory } from 'react-router-dom';
import publicIp from 'public-ip';
import StringMask from 'string-mask';

const { SubmitStyled } = require('../../styles/pawn-submit.style');

const Submit = ({
  pawn,
  doUpdatePawn,
  doFetchUserByEmail,
  doIsEnableOtp,
  doMakeOtp,
  doMakeTicket,
  otpToggleHandler,
  user,
}) => {
  const history = useHistory();
  const { attribute } = pawn;
  const lastAttr = attribute
    ? attribute[attribute.length - 1].display_name
    : '';

  const getIp = async () => {
    try {
      const myPubID = await publicIp.v4();
      return String(myPubID);
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    doUpdatePawn({ position: 4 });

    //eslint-disable-next-line
  }, []);

  const { REACT_APP_OFFICE_IP: office_url = '' } =
    process.env;

  // state
  const [isProcessing, isProcessingHandler] = useState(false);
  const [toggleField, toggleFieldHandler] = useState(false);
  const [isNewUser, isNewUserHandler] = useState(true);
  const [email, emailHandler] = useState(null);
  const [mobile, mobileHandler] = useState(null);
  const [firstName, firstNameHandler] = useState(null);
  const [lastName, lastNameHandler] = useState(null);
  const [errFields, errFieldsHandler] = useState([]);
  const [errMsg, errMsgHandler] = useState(false);
  const [editMode, editModeHandler] = useState(false);

  useEffect(() => {
    let cancel = false;
    const timer = setTimeout(async () => {
      if (!cancel) {
        if (email) {
          const emailValid = validateEmail(email);
          if (emailValid) {
            const _errFields = errFields.filter((e) => e !== 'email');
            errFieldsHandler(_errFields);
            toggleFieldHandler(true);
            doFetchUserByEmail({ key: email })
              .then((data) => {
                if (data) {
                  //with existing user
                  isNewUserHandler(false);
                  const { first_name = '', last_name, mobile } = data;
                  mobileHandler(mobile.length >= 11 ? mobile : '0' + mobile);
                  firstNameHandler(first_name);
                  lastNameHandler(last_name);
                  editModeHandler(false);
                } else {
                  // new user
                  isNewUserHandler(true);
                  mobileHandler(null);
                  firstNameHandler(null);
                  lastNameHandler(null);
                }
              })
              .catch((err) => {
                console.log('doFetchUserByEmail err', err);
                mobileHandler(null);
                firstNameHandler(null);
                lastNameHandler(null);
              });
          } else {
            //email not valid
            errFieldsHandler([...new Set([...errFields, 'email'])]);
          }
        } else {
          toggleFieldHandler(false);
          mobileHandler(null);
          firstNameHandler(null);
          lastNameHandler(null);
        }
      }
    }, 300);

    return () => {
      cancel = true;
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    const mobileReg = new RegExp('^09');

    if (!isNil(mobile)) {
      if (!mobileReg.test(mobile)) {
        errFieldsHandler([...new Set([...errFields, 'mobile'])]);
      } else if (mobile.length !== 11) {
        errFieldsHandler([...new Set([...errFields, 'mobile'])]);
      } else {
        const _errFields = errFields.filter((e) => e !== 'mobile');
        errFieldsHandler(_errFields);
      }
    }

    // eslint-disable-next-line
  }, [mobile]);

  const doSubmit = async () => {
    isProcessingHandler(true);
    const _userDetails = {
      email,
      mobile,
      firstName,
      lastName,
    };

    const _data = {
      email,
      number: mobile,
    };
    doUpdatePawn({ userDetails: _userDetails });

    const _office_urls = office_url.split('/').map((e) => String(e));
    const myIp = await getIp();
    const isOffice_url = _office_urls.includes(myIp);

    const isOtpEnable = await doIsEnableOtp(_data);
    
    if (isOtpEnable.value === '1' && !isOffice_url) {
      doMakeOtp(_data);
      otpToggleHandler(true);
      isProcessingHandler(false);
    } else {
      errMsgHandler(false);
      const _pawn = { ...pawn, userDetails: _userDetails };
      doMakeTicket(_pawn)
        .then((data) => {
          isProcessingHandler(false);
          const { ticket_no, g9 = false } = data;

          if (g9) {
            history.push(`/pawn/acceptance/${ticket_no}`);
          } else {
            history.push(`/pawn/thankyou/${ticket_no}`, {
              prestine: true,
              thankyouDateExpiry: moment().add(30, 'm').tz(timezone).unix(),
            });
          }
        })
        .catch((err) => {
          errMsgHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
          isProcessingHandler(false);
        });
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      const { user: _user } = user;
      console.log('user is active', _user);
      if (_user && !isEmpty(_user)) {
        const { email } = _user;
        emailHandler(email);
      }
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, []);

  return (
    <SubmitStyled>
      <div className='pawn-activity-header'>
        <div
          className='ctm-link-attr-wrapper'
          onClick={() => {
            doUpdatePawn({
              position: 3,
              attrStep: attribute.length - 1,
              slideLeft: true,
            });
          }}>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
            alt='go back'
          />
          <p>
            Back to{' '}
            {lastAttr.replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })}
          </p>
        </div>
      </div>
      <div className='pawn-activity-body'>
        <p>
          Kindly provide your contact information below to receive our initial
          loan offer.
        </p>

        <div className='pawn-sumit-field-wrapper'>
          <div className='field-group'>
            <div
              className={`field-label-wrapper ${
                email == null || isEmpty(email) ? 'no-value' : 'with-value'
              } ${errFields.includes('email') ? 'invalid' : ''}`}>
              <input
                id='pawn_form_email'
                type='text'
                // placeholder='Email address'
                value={email ? email : ''}
                onChange={(e) => {
                  emailHandler(e.target.value);
                }}
                className={`${errFields.includes('email') ? 'invalid' : ''}`}
              />
              <label htmlFor='pawn_form_email'>Email address</label>
            </div>
            <p className='err-note error'>
              Please enter your valid email address
            </p>
          </div>
          {toggleField && (
            <>
              <div className='field-group'>
                <div
                  className={`field-label-wrapper ${
                    mobile == null || isEmpty(mobile)
                      ? 'no-value'
                      : 'with-value'
                  } ${errFields.includes('mobile') ? 'invalid' : ''}`}>
                  {!isNewUser && mobile && !editMode ? (
                    <>
                      <div className='field-content-preview'>
                        <p>
                          {StringMask.apply(
                            mobile.substring(0, 2) +
                              mobile.substring(
                                mobile.length - 4,
                                mobile.length
                              ),
                            '00$•$• $•$•$• 0000'
                          )}
                        </p>
                        <img
                          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/field-edit-ico.svg'
                          alt=''
                          onClick={() => editModeHandler(true)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <PhoneField
                        id='pawn_form_mobile'
                        value={mobile ? mobile : ''}
                        onChange={(data) => mobileHandler(data)}
                        country='ph'
                        onlyCountries={['ph']}
                        masks={{ ph: '.... ... ....' }}
                        disableDropdown={true}
                        disableCountryCode={true}
                        // countryCodeEditable={false}
                        placeholder=''
                        // disabled={!isNewUser}
                        inputClass='input-phone'
                        containerClass={`phone-input ${
                          errFields.includes('mobile') ? 'invalid' : ''
                        }`}
                      />
                    </>
                  )}

                  <label htmlFor='pawn_form_mobile'>Mobile number</label>
                </div>
                <p className='err-note error'>
                  Please enter your valid mobile number
                </p>
              </div>
              <div className='field-group name-group'>
                <div
                  className={`field-label-wrapper ${
                    firstName == null || isEmpty(firstName)
                      ? 'no-value'
                      : 'with-value'
                  } ${errFields.includes('firstName') ? 'invalid' : ''}`}>
                  <input
                    type='text'
                    name='first_name'
                    id='pawn_form_first_name'
                    value={firstName ? firstName : ''}
                    onChange={(e) => {
                      firstNameHandler(e.target.value);
                    }}
                    disabled={!isNewUser}
                  />
                  <label htmlFor='pawn_form_first_name'>First name</label>
                </div>
                <div
                  className={`field-label-wrapper ${
                    lastName == null || isEmpty(lastName)
                      ? 'no-value'
                      : 'with-value'
                  } ${errFields.includes('lastName') ? 'invalid' : ''}`}>
                  <input
                    type='text'
                    name='last_name'
                    id='pawn_form_last_name'
                    value={lastName ? lastName : ''}
                    onChange={(e) => {
                      lastNameHandler(e.target.value);
                    }}
                    disabled={!isNewUser}
                  />
                  <label htmlFor='pawn_form_last_name'>Last name</label>
                </div>
              </div>
              {errMsg && <p className='error'>{errMsg}</p>}
            </>
          )}
          <div
            className={`field-group submit-group ${
              errFields.length ||
              isEmpty(mobile) ||
              isNil(mobile) ||
              isEmpty(firstName) ||
              isNil(firstName) ||
              firstName.length < 2 ||
              isEmpty(lastName) ||
              isNil(lastName) ||
              lastName.length < 2 ||
              isProcessing
                ? 'disable'
                : 'able'
            }`}>
            <button
              onClick={() => {
                doSubmit();
              }}>
              Get a free estimate
            </button>
          </div>
        </div>
      </div>
    </SubmitStyled>
  );
};

const mapStateToProps = pick(['pawn', 'user']);
const mapDispatchToProps = (dispatch) => ({
  doFetchUserByEmail: (key) => dispatch(fetchUserByEmail(key)),
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
  doIsEnableOtp: (data) => dispatch(isEnableOtp(data)),
  doMakeOtp: (data) => dispatch(makeOtp(data)),
  doMakeTicket: (data) => dispatch(makeTicket(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Submit);
