import styled from 'styled-components';

export const PawnNavStyled = styled.div`
  /* height: auto;
  overflow-y: hidden;

  ${(props) => (props.show ? `height: auto;` : `height: 0;`)} */
  .menu-wrapper {
    max-width: 900px;
    display: block;
    margin: auto;

    @media (max-width: 768px) {
      display: none;
    }

    nav {
      ul {
        list-style: none;
        display: flex;
        justify-content: space-around;

        li {
          display: flex;
          color: #48667e;
          font-size: 18px;

          &.done {
            color: #ffffff;
          }

          &.current {
            color: #68c552;
          }
        }
      }
    }
  }

  .menu-wrapper-mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }

    p {
      font-size: 18px;
      color: #fff;
      text-align: center;
      span {
        color: #68c552;
        font-weight: 600;
      }
    }
  }

  .process-bar-wrapper {
    margin-top: 15px;
    background: #112434;
    border-radius: 7px;
    height: 7px;
    width: 100%;
    display: block;
    position: relative;

    .process-status {
      background: #68c552;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 7px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 7px;
      width: ${(props) => (props.percent ? props.percent + '%' : '0%')};
      transition: width 0.3s;
    }
  }
`;
