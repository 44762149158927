import React, { useState } from 'react';

//utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';

// child
import { menus } from '../../components/dashboard/layout';
import { Link, useHistory } from 'react-router-dom';
import { makeChangeCredentials, makeLogOut } from '../../../actions/auth';
import { logout } from '../../../actions/user';

// styles
const {
  ContentStyled,
  DashboardNavHeader,
  FormAddressStyled,
} = require('../../styles/dashboard.style');

const AccountCredential = ({
  dashboard,
  doMakeChangeCredentials,
  doMakeLogOut,
  doLogout,
}) => {
  const { activeMenu } = dashboard;

  const history = useHistory();
  const [formValue, formValueHandler] = useState({});
  const [isProcessing, isProcessingHandler] = useState(false);
  const [err, errHandler] = useState(null);

  const _logut = () => {
    doMakeLogOut().then((data) => {
      history.push('/login');
      doLogout();
    });
  };

  const onSubmit = () => {
    isProcessingHandler(true);
    doMakeChangeCredentials(formValue)
      .then((response) => {
        const {
          success,
          message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
        } = response;

        isProcessingHandler(false);

        if (success) {
          _logut();
        } else {
          errHandler(message);
        }
      })
      .catch((err) => {
        console.log('err', err);
        isProcessingHandler(false);
        errHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };
  return (
    <ContentStyled>
      <DashboardNavHeader>
        <Link to='/dashboard/account'>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/header-nav-back-ico.svg'
            alt='back'
          />
        </Link>
        <div>
          <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
          <h1>{menus[activeMenu] ? menus[activeMenu].text : ''}</h1>
        </div>
      </DashboardNavHeader>

      <div
        style={{
          width: '100%',
          maxWidth: '408px',
        }}>
        <FormAddressStyled>
          <>
            <div className='form-box'>
              <div>
                <p>Change your password</p>
              </div>
              <div>
                <>
                  <div className='fields-groups-wrapper'>
                    <div className='fields-wrapper'>
                      <div className='field-group'>
                        <div
                          className={`field-label-wrapper ${
                            formValue.current_password ? 'with-value' : ''
                          }`}>
                          <input
                            id={`form_current_password`}
                            type='password'
                            value={formValue.current_password || ''}
                            onChange={(e) => {
                              formValueHandler({
                                ...formValue,
                                current_password: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor={`form_current_password`}>
                            Old password
                          </label>
                        </div>
                        <p className='err-note error'></p>
                      </div>
                      <div className='field-group'>
                        <div
                          className={`field-label-wrapper ${
                            formValue.new_password ? 'with-value' : ''
                          }`}>
                          <input
                            id={`form_new_password`}
                            type='password'
                            value={formValue.new_password || ''}
                            onChange={(e) => {
                              formValueHandler({
                                ...formValue,
                                new_password: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor={`form_new_password`}>
                            New password
                          </label>
                        </div>
                        <p className='err-note error'></p>
                      </div>
                      <div className='field-group'>
                        <div
                          className={`field-label-wrapper ${
                            formValue.confirm_password ? 'with-value' : ''
                          }`}>
                          <input
                            id={`form_confirm_password`}
                            type='password'
                            value={formValue.confirm_password || ''}
                            onChange={(e) => {
                              formValueHandler({
                                ...formValue,
                                confirm_password: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor={`form_confirm_password`}>
                            Confirm new password
                          </label>
                        </div>
                        <p className='err-note error'></p>
                      </div>
                    </div>
                  </div>
                </>
                {err && (
                  <p className='error' style={{ fontSize: '12px' }}>
                    {err}
                  </p>
                )}
                <button
                  disabled={
                    !formValue.confirm_password ||
                    !formValue.new_password ||
                    !formValue.current_password ||
                    isProcessing
                  }
                  onClick={() => {
                    onSubmit();
                  }}>
                  Save changes
                </button>
              </div>
            </div>
          </>
        </FormAddressStyled>
      </div>
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard']);
const mapDispatchToProps = (dispatch) => ({
  doMakeChangeCredentials: (paylaod) =>
    dispatch(makeChangeCredentials(paylaod)),
  doMakeLogOut: () => dispatch(makeLogOut()),
  doLogout: () => dispatch(logout()), //clean the reducer
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCredential);
