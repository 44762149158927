export const pressData = [
  {
    title: "PawnHero's Statement Regarding HeroToken",
    content: `Our company, PawnHero Pawnshop Philippines, Inc. (PPPI) has been made aware by the Bangko Sentral ng Pilipinas (BSP) of reports coming from concerned consumers regarding an association between our organization and the cryptocurrency known as Hero Token.`,
    link:
      'https://s3-us-west-2.amazonaws.com/pawnhero.ph/Press+Release_Hero-Token.pdf',
  },
  {
    title: 'PawnHero Snags Judges’ Choice, Winning Echelon 2015',
    content: `In a regional competition for most promising startup in Asia, fintech startup PawnHero emerged as the champion, being the first Philippine startup to take the prestigious award home for the country.`,
    localLink: '/pawnhero_echelon_2015',
  },
  {
    title: 'PawnHero flies to Malaysia for #Maybankfintech 2015',
    content: `PawnHero will be joining 19 other regional startups at the Alpha Startups pre-accelerator idea validation programme in partnership with Maybank and 1337 Ventures. Running from June 13 to 15, the programme will involve startups being guided in validating their ideas to customers and improving their product development and go-to-market strategies.`,
    localLink: '/pawnhero_in_malaysia',
  },
  {
    title: 'PawnHero wins Echelon Asia Philippines Qualifier',
    content: `The country's first online pawnshop, PawnHero.ph, was awarded as Echelon Asia Summit 2015's top startup from the Philippines and will be part of the biggest tech conference to be held in Singapore.`,
    link:
      'https://blog.pawnhero.ph/pawnhero-ph-wins-echelon-asia-philippines-qualifier/',
  },
  {
    title: 'PawnHero is Ready 2GO',
    content: `PawnHero.ph, the first online pawnshop in Southeast Asia, in partnership with 2GO, begins a pawning revolution in the Philippines. With PawnHero.ph, anyone can submit a pawn request anytime and anywhere.`,
    link:
      'https://s3-ap-southeast-1.amazonaws.com/pawnhero-downloadables/press-release/PawnHero-is-Ready-2GO.zip',
  },
  {
    title: 'PawnHero.ph -- 24/7 Pawnshop Now Live!',
    content: `Newly launched online pawnshop, PawnHero.ph, will revolutionize the way Filipinos turn their second-hand items to cash. Through online pawning and selling, even personal items such as luxury bags, designer shoes, Apple products, and more can be a collateral for a loan.`,
    link:
      'https://s3-ap-southeast-1.amazonaws.com/pawnhero-downloadables/press-release/24-7-Pawnshop.zip',
  },
  {
    title: 'Online Pawning -- the Next Big Thing',
    content: `The first online pawnshop in Southeast Asia turned over 20 million smartphones in the Philippines into a pawnshop upon launch. By providing an online platform for Filipinos to submit a pawn request, anyone who wishes to pawn an item can easily do so in a few clicks.`,
    link:
      'https://s3-ap-southeast-1.amazonaws.com/pawnhero-downloadables/press-release/Next-Big-Thing-Online-Pawn.zip',
  },
];
