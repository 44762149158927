import React from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateLoan } from '../../../actions/loan';

import { ContainerStyled } from '../../styles/layout.style';
import { AcceptanceNavStyled } from '../../styles/acceptance.style';

const ProgressNav = ({
  doUpdateLoan,
  loan,
  mode = 0, //0 -> acceptance ,  1 -> offer
}) => {
  const {
    data: { offer = null, booking = null, cashout = null },
  } = loan;

  const menus = [
    {
      text: 'Loan Offers',
      acceptanceField: 'offer',
      hasData: offer ? true : false,
      mode: [0, 1],
    },
    {
      text: 'Item Booking',
      acceptanceField: 'booking',
      hasData: booking ? true : false,
      mode: [0],
    },
    {
      text: 'Cash out',
      acceptanceField: 'cashout',
      hasData: cashout ? true : false,
      mode: [0],
    },
    {
      text: 'Review',
      mode: [0, 1],
    },
  ].filter((e) => e.mode.includes(mode));

  const positionHandler = (pos) => {
    doUpdateLoan({ position: pos });
  };

  const { position: currentPos = 0 } = loan;

  const menuL = menus.length;
  const menuData = menus.filter((menu) => menu.hasData).length;
  const _percent = menuL - 1 === menuData ? 99 : (menuData / menuL) * 100;

  return (
    <AcceptanceNavStyled percent={_percent}>
      <ContainerStyled>
        <div className='destop-nav'>
          <nav>
            <ul>
              {menus.map((menu, i) => {
                const { hasData } = menu;
                return (
                  <li key={i}>
                    <p
                      className={`${currentPos === i ? 'current' : ''} ${
                        !hasData ? 'disabled' : 'enable'
                      }`}
                      onClick={() => {
                        positionHandler(i);
                      }}>
                      {menu.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
        <div className='mobile-nav'>
          <p>
            Step {currentPos + 1} of {menus.length} :{' '}
            <span>{menus[currentPos].text}</span>
          </p>
        </div>
        <div className='progress-bar'>
          <div className='progress-percent'></div>
        </div>
      </ContainerStyled>
    </AcceptanceNavStyled>
  );
};

const mapStateToProps = pick(['loan']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateLoan: (loan) => dispatch(updateLoan(loan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNav);
