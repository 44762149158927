import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getIwaData = (payload) => {
  const _urlparams = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/iwa${_urlparams}`, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
