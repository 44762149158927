import React, { useState } from 'react';
import pick from 'ramda.pick';
import { fetchSearch, fetchSearchBrandCategory } from '../../../actions/search';
import { connect } from 'react-redux';
import isEmpty from '@ramda/isempty';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';

const { SearchStyled } = require('../../styles/search.style');

const SearchBar = (props) => {
  const {
    doPawnSearch,
    backgroundColor,
    foregroundColor,
    placeHolder,
    categoryAndBrandOnly,
    doFetchSearchBrandCategory,
    optionLink,
  } = props;
  const [catalogs, catalogsHandler] = useState(null);
  const [searchKey, searchKeyHandler] = useState('');
  const [loading, loadingHandler] = useState(false);
  let timeout = null;

  const handleChange = (e) => {
    const key = e.target.value;
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (!isEmpty(key.trim()) && key.trim().length > 2) {
        searchKeyHandler(key);
        doSearch(key);
      } else {
        catalogsHandler(null);
      }
    }, 500);
  };

  const doSearch = (key) => {
    loadingHandler(true);
    if (categoryAndBrandOnly) {
      doFetchSearchBrandCategory({ key })
        .then((data) => {
          catalogsHandler(data);
        })
        .then(() => {
          loadingHandler(false);
        });
    } else {
      doPawnSearch({ key })
        .then((data) => {
          catalogsHandler(data);
        })
        .then(() => {
          loadingHandler(false);
        });
    }
  };

  return (
    <>
      <SearchStyled
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}>
        <div className='searchable-dropdown'>
          {isEmpty(searchKey) ? (
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-blue.svg'
              alt='search icon'
            />
          ) : (
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon.svg'
              alt='search icon'
            />
          )}

          {loading && (
            <img
              className='loader'
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
              alt='loading'
            />
          )}
          <input
            type='text'
            onChange={handleChange}
            placeholder={
              placeHolder
                ? `${placeHolder}`
                : 'Try searching directly for your item here'
            }
          />
          {catalogs !== null && (
            <div className='search-option-wrapper'>
              {catalogs !== null &&
                (categoryAndBrandOnly
                  ? catalogs.map((cat, key) => {
                      return (
                        <Link
                          key={key}
                          to={`${optionLink}/${cat.type}/${cat.slug}`}>
                          <div className='brand-icon-wrapper'>
                            <p>
                              {(cat.type === 'brand'
                                ? cat.brand_name
                                : cat.category_name
                              )
                                .replace(/[^a-zA-Z- ]/g, '')
                                .match(/\b\w/g)
                                .join('')
                                .substring(0, 2)
                                .toUpperCase()}
                            </p>
                          </div>
                          <div className='brand-icon-label'>
                            <p>
                              <Highlighter
                                highlightClassName={'markHighlight'}
                                searchWords={[searchKey]}
                                textToHighlight={`${
                                  cat.type === 'brand'
                                    ? cat.brand_name
                                    : cat.category_name
                                }`}
                              />
                            </p>
                            <img
                              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-gray.svg'
                              alt='search icon'
                            />
                          </div>
                        </Link>
                      );
                    })
                  : catalogs.map((cat, key) => {
                      return (
                        <Link
                          key={key}
                          to={`/pawn/${cat.cc_slug}/${cat.cb_slug}/${cat.cp_slug}`}>
                          <div className='brand-icon-wrapper'>
                            <p>
                              {cat.brand_name
                                .replace(/[^a-zA-Z- ]/g, '')
                                .match(/\b\w/g)
                                .join('')
                                .substring(0, 2)
                                .toUpperCase()}
                            </p>
                          </div>
                          <div className='brand-icon-label'>
                            <p>
                              <Highlighter
                                highlightClassName={'markHighlight'}
                                searchWords={[searchKey]}
                                textToHighlight={`${cat.brand_name} ${cat.product_name} `}
                              />
                            </p>
                            <img
                              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-gray.svg'
                              alt='search icon'
                            />
                          </div>
                        </Link>
                      );
                    }))}
            </div>
          )}
        </div>
      </SearchStyled>
    </>
  );
};

const mapStateToProps = pick(['']);
const mapDispatchToProps = (dispatch) => ({
  doPawnSearch: (key) => dispatch(fetchSearch(key)),
  doFetchSearchBrandCategory: (key) => dispatch(fetchSearchBrandCategory(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
