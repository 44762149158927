import React from 'react';
import { LoanSummaryStyled } from '../../styles/acceptance.style';

//utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import isNil from '@ramda/isnil';
import { updateRedeem } from '../../../actions/redeem';

const Summary = (props) => {
  const { redeem, doUpdateRedeem } = props;
  const { position } = redeem;
  const { offer, booking, cashout } = redeem.data;

  const lists = [
    {
      label: 'Select Redeem Option',
      hasValue: offer ? true : false,
      displayLabel: 'You selected',
      displayLabelVal: 'Loan Repayment ',
    },
    {
      label: 'Select the item return type',
      hasValue: booking ? true : false,
      displayLabel: 'You selected',
      displayLabelVal:
        !isNil(booking) && booking !== undefined
          ? booking.mode === 'delivery'
            ? 'Delivery'
            : 'Pick-up'
          : '',
    },
    {
      label: 'Select the repayment method',
      hasValue: cashout ? true : false,
      displayLabel: 'You selected',
      displayLabelVal:
        !isNil(cashout) && cashout !== undefined ? cashout.label : '',
    },
    // { label: 'Review loan repayment', hasValue: false, displayLabel: '' },
  ];

  const positionHandler = (pos) => {
    doUpdateRedeem({ position: pos });
  };

  return (
    <LoanSummaryStyled MobileDisplay={redeem.showRedeemSummary}>
      <div className='loan-summary-wrapper'>
        <div className='summary-header'>
          <h3>Repayment Summary</h3>
        </div>
        <div className='summary-body'>
          <nav>
            <ul>
              {lists.map((list, i) => {
                return (
                  <li
                    key={i}
                    className={`${list.hasValue ? 'with-value' : ''} ${
                      i === position ? 'current' : ''
                    }`}
                    onClick={() => positionHandler(i)}>
                    <span>{i + 1}</span>
                    {list.hasValue ? (
                      <>
                        <p>
                          {list.displayLabel}{' '}
                          <span>{list.displayLabelVal}</span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>{list.label}</p>
                      </>
                    )}
                    {list.hasValue && (
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pen-edit-circle-ico.svg'
                        alt='edit'
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </LoanSummaryStyled>
  );
};

const mapStateToProps = pick(['redeem']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateRedeem: (redeem) => dispatch(updateRedeem(redeem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
