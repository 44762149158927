import React, { useEffect, useState } from 'react';

//utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import momentTZ from 'moment-timezone';
import { timezone } from '../../../server/config/moment';
import { updateLoan } from '../../../actions/loan';

// styles
import { ReviewStyled } from '../../styles/acceptance.style';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  makeAcceptFinalOffer,
  makeAcceptInitialOffer,
} from '../../../actions/ticket';

const moment = require('moment');

const Review = ({
  loan,
  doUpdateLoan,
  doMakeAcceptInitialOffer,
  doMakeAcceptFinalOffer,
  animationDoneHandler,
  mode = 0, // 0 -> acceptance : 1 -> offer
}) => {
  const { ticketNum } = useParams();
  const history = useHistory();

  const { data = {}, slideLeft = false } = loan;
  const { offer = null, booking = null, cashout = null } = data;

  // states
  const [agreement, agreementHandler] = useState(false);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [isError, isErrorHandler] = useState(false);

  const prev = () => {
    if (mode === 0) {
      doUpdateLoan({
        position: 2,
        slideLeft: true,
      });
    } else {
      doUpdateLoan({
        position: 0,
        slideLeft: true,
      });
    }
  };

  const submit = () => {
    isProcessingHandler(true);
    isErrorHandler(false);
    if (mode === 0) {
      doMakeAcceptInitialOffer({ ...loan, ticketNum })
        .then((data) => {
          const { success } = data;
          isProcessingHandler(false);
          if (success) {
            history.push(`/pawn/acceptance/${ticketNum}/thankyou`, {
              thankyouDateExpiry: momentTZ().add(30, 'm').tz(timezone).unix(),
            });
          } else {
            isErrorHandler(true);
          }
        })
        .catch(({ data: { msg, success } }) => {
          console.log('error on domake acceptFinal Offer', msg, success);
          isProcessingHandler(false);
          isErrorHandler(true);
        });
    } else {
      doMakeAcceptFinalOffer({ ...loan, ticketNum })
        .then(({ data }) => {
          const { success } = data;
          isProcessingHandler(false);
          if (success) {
            history.push(`/pawn/final/${ticketNum}/thankyou`, {
              thankyouDateExpiry: momentTZ().add(30, 'm').tz(timezone).unix(),
            });
          } else {
            isErrorHandler(true);
          }
        })
        .catch(({ data: { msg, success } }) => {
          console.log('error on domake acceptFinal Offer', msg, success);
          isProcessingHandler(false);
          isErrorHandler(true);
        });
    }
  };

  useEffect(() => {
    // animation
    animationDoneHandler(false);
    setTimeout(() => {
      animationDoneHandler(true);
    }, 300);
  }, [animationDoneHandler]);

  const cashOutLabels = {
    cash: 'You will receive your loan in cash upon loan approval',
  };

  return (
    <ReviewStyled slideLeft={slideLeft}>
      <div className='review-content-wrapper'>
        <h2>Review your loan details</h2>
        <div className='review-form-wrapper'>
          <div className='form-header'>
            <div onClick={prev}>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                alt='previous'
              />
              <p>Back to {mode === 0 ? 'Cash Out' : 'Loan Offers'}</p>
            </div>
          </div>
          <div className='form-body'>
            <ul className='summary-list'>
              <li>
                <div className='list-content'>
                  <div className='content'>
                    <p className='title'>Loan Offer</p>
                    <p className='highlight'>
                      {offer.loanAmount} / {offer.term}
                    </p>
                    <p className='note'>
                      Total interest: {offer.totalInterest} /{' '}
                      {offer.interestRate}%
                    </p>
                  </div>
                  <div className='cta'>
                    <button
                      className='loan-btn'
                      onClick={() =>
                        doUpdateLoan({
                          position: 0,
                        })
                      }>
                      <p className='btn-txt-desktop'>Change loan offer</p>
                    </button>
                  </div>
                </div>
              </li>

              {mode === 0 && (
                <>
                  <li>
                    <div className='list-content'>
                      <div className='content'>
                        <p className='title'>Item booking</p>
                        <p className='highlight'>
                          {booking.mode === 'pickup'
                            ? `${
                                booking.selectedAddress.label
                                  ? booking.selectedAddress.label
                                  : booking.selectedAddress.street
                              } ${
                                booking.selectedAddress.barangay.label 
                                ? booking.selectedAddress.barangay.label 
                                : booking.selectedAddress.barangay.name
                              }, ${
                                booking.selectedAddress.city.label 
                                ? booking.selectedAddress.city.label 
                                : booking.selectedAddress.city.name
                              }`
                            : 'Walk-in at PawnHero'}
                        </p>
                        <p className='note'>
                          {moment(booking.date).format('D MMMM YYYY, dddd')}
                        </p>
                      </div>
                      <div className='cta'>
                        <button
                          className='loan-btn'
                          onClick={() =>
                            doUpdateLoan({
                              position: 1,
                            })
                          }>
                          <p className='btn-txt-desktop'>Change item booking</p>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className='list-content'>
                      <div className='content'>
                        <p className='title'>Cash out method</p>
                        <p className='highlight'>{cashout.label}</p>
                        {cashout.type === 'bank_deposit' &&
                          cashout.cashOutDetails && (
                            <p className='note'>
                              {cashout.cashOutDetails.bankSelected &&
                                cashout.cashOutDetails.bankSelected.label + ` `}
                              / Ending in{'   '}
                              {cashout.cashOutDetails.account_no.substring(
                                cashout.cashOutDetails.account_no.length - 4,
                                cashout.cashOutDetails.account_no.length
                              )}
                            </p>
                          )}

                        {cashout.type !== 'bank_deposit' &&
                          cashout.cashOutDetails && (
                            <p className='note'>
                              <span>{cashout.cashOutDetails.account_no}</span>
                            </p>
                          )}

                        {cashOutLabels[cashout.type] && (
                          <p className='note'>{cashOutLabels[cashout.type]}</p>
                        )}
                      </div>
                      <div className='cta'>
                        <button
                          className='loan-btn'
                          onClick={() =>
                            doUpdateLoan({
                              position: 2,
                            })
                          }>
                          <p className='btn-txt-desktop'>Change cash out</p>
                        </button>
                      </div>
                    </div>
                  </li>
                </>
              )}
            </ul>
            {mode === 0 && (
              <div className='aggrement-wrapper'>
                <div>
                  <div className='checkbox-wrapper'>
                    <input
                      id='agreement'
                      type='checkbox'
                      name='agreement'
                      checked={agreement}
                      onChange={() => agreementHandler(!agreement)}
                    />
                    <label htmlFor='agreement'>
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-ctm-green.svg'
                        alt=''
                      />
                    </label>
                  </div>
                  <p
                    onClick={() => agreementHandler(!agreement)}
                    style={{ cursor: 'pointer' }}>
                    By booking your item for appraisal, you agree to{' '}
                    <Link to='/privacy-policy' target='_blank'>
                      Privacy Policy
                    </Link>
                    ,{' '}
                    <Link to='/terms-of-use' target='_blank'>
                      Terms of Use
                    </Link>
                    , and{' '}
                    <Link to='/return-policy' target='_blank'>
                      Return Policy on Rejected Items
                    </Link>
                    .
                  </p>
                </div>
              </div>
            )}
            {isError && (
              <p
                className='error'
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                }}>
                Something went wrong. Please refresh the page, or contact
                PawnHero IT support.
              </p>
            )}
            {mode === 0 && (
              <button
                className='btn-submit'
                onClick={submit}
                disabled={!agreement || isProcessing}>
                {isProcessing ? 'Loading...' : 'Book my item for appraisal'}
              </button>
            )}

            {mode === 1 && (
              <button
                className='btn-submit'
                onClick={submit}
                disabled={isProcessing}>
                {isProcessing ? 'Loading...' : 'Accept offer'}
              </button>
            )}
          </div>
        </div>
      </div>
    </ReviewStyled>
  );
};

const mapStateToProps = pick(['loan']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateLoan: (loan) => dispatch(updateLoan(loan)),
  doMakeAcceptInitialOffer: (acceptance) =>
    dispatch(makeAcceptInitialOffer(acceptance)),
  doMakeAcceptFinalOffer: (offer) => dispatch(makeAcceptFinalOffer(offer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
