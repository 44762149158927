import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getSearch = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { key } = payload;

    try {
      const response = await axios.get('/api/search/' + key, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getSearchBrandCategory = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { key } = payload;

    try {
      const response = await axios.get(
        '/api/search/categoryBrand/' + key,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getSearchItem = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { key } = payload;

    try {
      const response = await axios.get('/api/search/items/' + key, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
