import styled from 'styled-components';

export const PawnThankyouStyled = styled.div`
  & > section:nth-child(1) {
    background: radial-gradient(50% 50% at 50% 50%, #354f65 0%, #253d52 100%);

    & > div {
      //container
      display: flex;
      max-width: 810px;

      & > div:nth-child(1) {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 832px) {
          width: 100%;
          text-align: center;
          padding: 30px 0;
        }

        h1 {
          font-size: 32px;
          color: #ffffff;
          font-weight: 600;
        }

        & > p:nth-of-type(1) {
          font-size: 20px;
          color: #ffffff;
        }

        & > p:nth-of-type(2) {
          margin-top: 30px;
          font-size: 18px;
          color: #ffffff;

          span {
            color: #fff;
            text-decoration: underline;
          }
        }

        & > p:nth-of-type(3) {
          font-size: 14px;
          color: #ffffff;
          margin-top: 5px;
        }

        a {
          color: #fff;
          text-decoration: none;
          font-size: 14px;
          margin-top: 30px;
        }

        .link-wrappers {
          display: flex;

          @media (max-width: 768px) {
            justify-content: center;
            margin-top: 20px;
          }

          & > a {
            margin-right: 50px;
            @media (max-width: 768px) {
              margin: auto;
            }
          }

          & > a:last-child {
            color: #ffffff;
          }
        }
      }

      & > div:last-child {
        width: 30%;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 832px) {
          display: none;
        }

        & > img {
          max-width: 187px;
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  & > section:nth-child(2) {
    background: #ebebeb;

    & > div {
      //container

      & > div.steps-wrapper {
        display: flex;
        margin: 30px 0;

        @media (max-width: 832px) {
          display: block;
        }

        div.step:first-child {
          margin: 0;
        }

        div.step {
          width: 32.33%;
          margin-left: 1.5%;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          overflow: hidden;
          padding-bottom: 30px;
          height: fit-content;

          @media (max-width: 984px) {
            height: 700px;
          }

          @media (max-width: 900px) {
            height: 750px;
          }

          @media (max-width: 832px) {
            height: auto;
            padding-bottom: 30px;
            width: 100%;
            margin: 15px 0 0;
          }

          & > img {
            width: 100%;
          }

          & > div {
            padding: 0 20px;

            h2 {
              text-align: center;
              font-weight: 600;
              font-size: 24px;
              color: #327fbe;
              margin: auto;
            }

            ul {
              list-style: none;

              li {
                margin-top: 15px;
                display: flex;

                img {
                  width: 32px;
                  align-self: flex-start;
                }

                p {
                  font-size: 14px;
                  text-align: justify;
                  color: #8c969d;
                  padding-left: 15px;

                  strong {
                    color: #354f65;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  div.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    & > div.modal-content {
      background: #327fbe;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 20px;
      max-width: 555px;
      width: 100%;

      & > div.modal-body {
        position: relative;

        & > img.modal-close-icon {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }

        & > img.modal-img {
          display: block;
          margin: auto;
          max-width: 110px;
          width: 100%;
        }

        & > p {
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          color: #ffffff;
          margin-top: 20px;
        }

        & > div.form-wrapper {
          div.form-group {
            margin-top: 20px;

            button.btn-submit {
              background: #68c552;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 15px;
              border: 0;
              width: 150px;
              text-align: center;
              padding: 10px 0;
              font-size: 18px;
              color: #fff;
              margin: auto;
              display: block;

              &.disabled {
                color: #3e9d29;
                pointer-events: none;
              }
            }

            div.option-wrapper {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              div.option {
                width: 50%;
                min-width: 200px;
                display: flex;
                align-items: center;
                margin-top: 3px;

                @media (max-width: 425px) {
                  min-width: 150px;
                }

                input {
                  display: flex;
                  align-self: center;
                }

                label {
                  font-size: 12px;
                  padding-left: 5px;
                  color: #fff;
                }
              }
            }

            p {
              font-size: 14px;
              color: #ffffff;
            }

            textarea {
              margin-top: 10px;
              width: 100%;
              border-radius: 5px;
              border: 0;
              resize: none;
              height: 150px;
              font-size: 16px;
              padding: 5px;
            }
          }
        }
      }
    }
  }
`;

export const PawnThankyouStyledV2 = styled.div`
  background: radial-gradient(50% 50% at 50% 50%, #354f65 0%, #253d52 100%);

  .banner {
    padding: 50px 0;

    img {
      max-width: 165px;
      width: 100%;
      display: block;
      margin: auto;
    }

    h2 {
      font-weight: 600;
      font-size: 32px;
      color: #fff;
      text-align: center;
      margin-top: 10px;
    }

    h3 {
      font-weight: normal;
      font-size: 20px;
      color: #fff;
      text-align: center;
      margin-top: 10px;
    }

    p.highnotes {
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-top: 30px;
      span {
        text-decoration: underline;
      }
    }

    p.notes {
      font-size: 14px;
      text-align: center;
      color: #fff;
      margin: 10px auto 0;
      width: 100%;
      max-width: 640px;

      a {
        font-size: 14px;
        color: #fff;
        text-decoration: underline !important;
      }
    }

    .cta-wrapper {
      display: flex;
      justify-content: center;

      a {
        color: #fff;
        font-size: 14px;
      }
      a.btn {
        background: #ff7019;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        border: 0;
        color: #fff;
        font-size: 14px;
        display: inline-block;
        margin: 30px auto 0;
      }
    }
  }
`;
