import React, { useState } from 'react';

const AppContext = () => {
    const [isHidden, isHiddenHandler] = useState(true);

    return (
        <>
            <div className="context-container">
                <p>
                    <img
                        style={{
                            width: '150px',
                            marginTop: '30px'
                        }}
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-icon.svg'
                        alt='Appication brand icon'
                    />
                </p>
                <p className="context-title">PawnHero is your one-stop pawnshop app for all your transactions from pawning, renewal, redemption, and in-app messaging to all our friendly loan support team! </p>
                <p className="context-subtext"> Lalong pinadali at pinabilis ang pagsangla mo sa PawnHero Pawnshop dahil nandito na ang official PawnHero mobile app.</p>
                { isHidden ? (
                    <>
                        <div className="learn-more">
                            <button onClick={() => isHiddenHandler(false)} className="btn-lm btn-common">Learn more</button>
                        </div>
                    </>
                ) : (
                    <>
                    <div className="learn-more-text">
                        <p className="p-title">PawnHero</p>
                        <p className="p-body">PawnHero is the first online pawnshop in the Philippines and is leading the modernization of the
                            pawnbroking industry in the country since 2015. PawnHero’s mission is to provide the most convenient and reliable
                            financial service to all Filipinos in need of quick cash loans
                            through the use of advanced technology.</p>
                        <p className="p-body">PawnHero is the pawnshop that goes to your homes which eliminates the need for people to
                            go out into the streets in search of ways to turn their valuables into cash. A safer option for you, our
                            pawners, especially during this time of pandemic.</p>
                        <p className="p-body">With PawnHero, you can experience the complete pawnshop services even from the comfort of
                            your home. All you have to do is pawn conveniently online through our official mobile app.</p>
                        <p className="p-title">How much can I loan?</p>
                        <p className="p-body">PawnHero lets you borrow money based on your item’s appraisal value. Loan amounts can vary
                            from Php 1500 and can go as high as millions.</p>
                        <p className="p-title">How fast will I get my money?</p>
                        <p className="p-body">For walk-in transactions scheduled in-app, you’ll receive your loan on hand, real-time.
                            For online pick-up booked via app, you can get your loan approved in as fast as 1 day with real-time loan
                            disbursement via InstaPay.</p>
                        <p className="p-title">What do I need to pawn?</p>
                        <p className="p-body">All you need are 2 valid primary IDs and your choice of accepted collateral.</p>
                        <p className="p-title">How simple is the process?</p>
                        <p className="p-body">Download the PawnHero App</p>
                        <p className="p-body">Fill out your pawn application form</p>
                        <p className="p-body">Receive our initial loan offer</p>
                        <p className="p-body">Book pick-up or walk-in schedule</p>
                        <p className="p-body">Get your item physically appraised</p>
                        <p className="p-body">Accept our final offer</p>
                        <p className="p-body">Receive your cash via InstaPay or PESONet </p>
                        <p className="p-title">Items We Accept</p>
                        <p className="p-title">Get FREE initial appraisal In-app:</p>
                        <ul style={{margin: '0 0 15px 25px', color: '#354F65'}}>
                            <li>Gold and Jewelry</li>
                            <li>Designer Bags</li>
                            <li>Luxury Watches</li>
                            <li>Laptops</li>
                            <li>Cameras</li>
                            <li>Smartphones</li>
                            <li>Gaming Consoles</li>
                            <li>Tablets</li>
                            <li>Televisions</li>
                            <li>Sunglasses</li>
                            <li>Soundbars and Speakers</li>
                            <li>Designer Belts</li>
                            <li>Designer Shoes</li>
                        </ul>
                        <p className="p-title"> App Features: </p>
                        <p className="p-title"> #PawnSafelyAtHome</p>
                        <p className="p-title">Pawn</p>
                        <p className="p-body">Instantly transact anywhere within Metro Manila via the PawnHero app.</p>
                        <p className="p-title">Renew</p>
                        <p className="p-body">Conveniently apply for pawn renewal anywhere in the world via PawnHero app to extend your
                            loan.</p>
                        <p className="p-title">Redeem</p>
                        <p className="p-body">Repay your loan via PawnHero app anywhere and receive your pawned asset at your doorstep*
                        </p>
                        <p className="p-body">*Home or office delivery is supported within Metro Manila and serviceable nearby cities.
                        </p>
                        <p className="p-title">Chat Support</p>
                        <p className="p-body">Enjoy in-app messaging to all our friendly loan officers to assist you smoothly from
                            pawning to redemption inquiries. #MagkanoSaPawnHero? You can message us to ask.</p>
                        <p className="p-title">My Pawn Tickets</p>
                        <p className="p-body">Manage your pawn tickets in one easy to use dashboard where you can accept loan offesr or
                            track next payment.</p>
                        <p className="p-title">Confirm Loan Payment</p>
                        <p className="p-body">Confirm your payment is made by submitting your proof via in-app.</p>
                        <p className="p-title">Push Notifications</p>
                        <p className="p-body">Receive real-time push notifications to track your pawn tickets and get early reminders of
                            your due date.</p>
                        
                        <p className="p-600 p-body">---</p>
                        <p className="p-600 p-body">All pawned items are insured by Pioneer Insurance.</p>
                        <p className="p-600 p-body">PawnHero Pawnshop Philippines, Inc. is regulated by the Bangko Sentral ng Pilipinas with
                            contact number (02) 8708-7807 and email address consumeraffairs@bsp.gov.ph.</p>
                    </div>
                    </>
                )}
                
                
                
                <div className="download-container">
                    <a 
                    href='https://apps.apple.com/us/app/pawnhero/id1580876430' 
                    target='_blank' 
                    rel='noopener noreferrer'>
                        <img
                            className="bottom-as"
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-download-ios.svg'
                            alt='Download on the App Store'
                        />
                    </a>
                    <a 
                    href='https://play.google.com/store/apps/details?id=com.pawnhero.mobileapp' 
                    target='_blank' 
                    rel='noopener noreferrer'>
                        <img
                            className="bottom-gp"
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-download-android.svg'
                            alt='Get it on Google Play'
                        />
                    </a>
                    <img
                        className="bottom-qr"
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-pawnhero-qr-code.png'
                        alt='Pawnhero QR'
                    />
                </div>
            </div>
        </>
    );
};

export default AppContext;