import React, { Fragment, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { isEmpty } from '@ramda/isempty/isEmpty';
import uploadFileService from '../../../utils/uploadFileService';
import { MyDropZoneStyle } from '../../styles/shared.style';

const MyDropzone = ({
  config,
  onFileUpload,
  files,
  withRemoveBtn = false,
  onFileRemove = false,
  s3Upload = true,
}) => {
  // states
  const [isUploading, isUploadingHandler] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const _file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (s3Upload) {
        upload(_file);
      } else {
        const fileToUpload = _file.filter((file) => file instanceof File);
        onFileUpload(fileToUpload[0]);
      }
    },
  });

  const upload = (files) => {
    isUploadingHandler(true);
    const fileToUpload = files.filter((file) => file instanceof File);

    if (!isEmpty(fileToUpload)) {
      uploadFileService
        .upload(fileToUpload[0], (progressEvent) => {
          console.log('progressEvent*****', progressEvent);
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          const _percentCompleted =
            percentCompleted > 95 ? 95 : percentCompleted;

          console.log('_percentCompleted', _percentCompleted);
        })
        .then(({ data: dataResponse }) => {
          console.log('uploadService dataResponse', dataResponse);
          const { url } = dataResponse;

          clean(fileToUpload); //remove from cache
          //upload api is only for single file
          //TODO update it with multi file
          onFileUpload(url);
          isUploadingHandler(false);
        })
        .catch((err) => {
          console.log('uploadService err', err);
        });
    }
  };

  const clean = (files) => {
    if (files) {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  };

  return (
    <>
      <MyDropZoneStyle>
        {isUploading ? (
          <p>uploading...</p>
        ) : (
          <>
            <div
              className={`preview-wrapper ${
                !isEmpty(files) ? 'with-files' : 'no-files'
              }`}>
              {files &&
                files.map((file, i) => {
                  const prev = file && file.preview ? file.preview : file;

                  return (
                    <Fragment key={i}>
                      <div className='thumbnail-wrapper'>
                        {withRemoveBtn && (
                          <img
                            className='remove-btn'
                            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/delete-ico.svg'
                            alt=''
                            onClick={() => {
                              console.log('fire remove');
                              if (onFileRemove !== false) {
                                onFileRemove({ file, index: i });
                              }
                            }}
                          />
                        )}

                        <div className='image-wrapper'>
                          <img src={prev} alt='' />
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              <div
                {...getRootProps({
                  className: `uploader ${!isEmpty(files) ? 'with-files' : ''}`,
                })}>
                <input {...getInputProps({ ...config })} />
                <div className='note-wrapper'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/upload-ico.svg'
                    alt=''
                  />
                  <p>Upload photo </p>
                </div>
              </div>
            </div>
          </>
        )}
      </MyDropZoneStyle>
    </>
  );
};

// makeUpload
const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(MyDropzone);
