import styled from 'styled-components';

export const HowToPawnStyled = styled.div`
  section:nth-child(1) {
    & > div {
      /* container */
      display: flex;
      align-items: flex-end;
      padding-top: 20px;

      @media (max-width: 768px) {
        flex-flow: column-reverse;
      }

      & > div {
        width: 50%;
        font-size: 0;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      & > div:first-child {
        padding: 40px 0;

        @media (max-width: 768px) {
          padding: 20px 0;
        }
        h1 {
          font-size: 32px;
          line-height: 40px;
          color: #327fbe;
          font-weight: 600;
        }

        h1 + p {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #8c969d;
          margin-top: 10px;

          span {
            color: #68c552;
          }
        }

        div.steps-wrapper {
          margin-top: 25px;

          ul {
            list-style: none;
            li {
              display: flex;
              align-items: center;
              padding: 5px 0;

              img {
                width: 72px;
              }

              p {
                color: #8c969d;
                font-size: 14px;
                margin-left: 20px;
                word-break: break-word;

                span {
                  color: #000;
                  font-weight: 600;
                }

                a {
                  color: #327fbe;
                  text-decoration: underline;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      & > div:last-child {
        img {
          max-width: 450px;
          margin-left: auto;
          display: block;

          @media (max-width: 768px) {
            margin: auto;
          }
        }
      }
    }
  }

  section:nth-child(2) {
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #354f65 0%,
      #253d52 100%
    );
    padding: 40px 0;

    & > div {
      /* container */
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: block;
      }

      & > div {
        width: 49%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      & > div:first-child {
        font-size: 0;
        iframe {
          min-height: 280px;
        }
      }

      & > div:last-child {
        display: flex;
        flex-flow: column;
        justify-content: center;

        & > p:first-of-type {
          font-weight: 600;
          font-size: 32px;
          color: #ffffff;
        }

        & > p:last-of-type {
          font-size: 14px;
          color: #ffffff;
          margin-top: 20px;

          span.underline {
            text-decoration: underline;
            font-weight: 600;
          }

          strong {
            font-weight: 600;
          }

          span {
            color: #68c552;
          }

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

        & > a {
          font-size: 18px;
          line-height: 18px;
          background: #ff7019;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          color: #ffffff;
          display: block;
          width: 150px;
          text-align: center;
          margin: 20px 0 5px;

          @media (max-width: 768px) {
            margin: 20px auto 5px;
            width: 100%;
            max-width: 345px;
          }

          @media (max-width: 425px) {
            max-width: unset;
          }
        }
      }
    }
  }
`;
