import React, { Fragment } from 'react';
import Layout from '../layout';
import { AboutUsStyled } from '../../styles/about-us.style';
import { ContainerStyled } from '../../styles/layout.style';

const AboutUs = (props) => {
  const pressLink = [
    {
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/press-bm.png',
      title: 'Revolutionizing The Way You Get A Loan',
      link: `https://businessmirror.com.ph/2017/06/03/revolutionizing-the-way-you-get-a-loan/`,
      content:
        'In an age where transactions can be done online, there is a copious amount of equivalent mobile app for just about anything...',
    },
    {
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/press-lifestyle.png',
      title: 'Decluttering Your Closet for Cash in the ‘New Normal',
      link: `https://lifestyle.inquirer.net/369992/decluttering-your-closet-for-cash-in-the-new-normal/`,
      content:
        'Now that we are living in the “new normal”, there are traditional transactions and services that can now be safely and securely done online, ...',
    },
    {
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/press-gma.png',
      title: 'PawnHero.ph, First Online Pawnshop in Southeast Asia',
      link: `https://www.gmanetwork.com/news/money/content/508250/pawnhero-ph-first-online-pawnshop-in-southeast-asia/story/`,
      content: `The first online pawnshop in Southeast Asia, PawnHero.ph opened in the Philippines last March. Even if they're up running for a few months...`,
    },
    {
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/press-forbes.png',
      title: 'The Filipino Startup Disrupting The Pawn Shop Industry',
      link: `https://www.forbes.com/sites/chynes/2016/11/28/the-filipino-startup-disrupting-the-pawn-shop-industry/`,
      content:
        'When David Margendorff arrived in the Philippines several years ago, the country’s financial system piqued his interest almost immediately. While...',
    },
  ];
  return (
    <>
      <Layout>
      <h1 className='d-none'>About Us</h1>
        <AboutUsStyled>
          <section>
            <img
              className='mobile-images'
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-1-mobile.png'
              alt=''
            />

            <div className='section-wrapper'>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-1.png'
                alt=''
              />
              <ContainerStyled className='text-content-wrapper '>
                <div className='half-wrapper'>
                  <div className='context-wrapper'>
                    <h3>About PawnHero</h3>
                  
                    <p>
                      PawnHero is the first online pawnshop in the Philippines
                      and is leading the modernization of the pawnbroking
                      industry in the country. PawnHero’s mission is to provide
                      the most convenient and reliable financial service to all
                      Filipinos in need of quick cash loans.
                      <br />
                      <br />
                      Through the use of advanced technology, PawnHero is able
                      to provide{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        instant cash
                      </span>{' '}
                      loans to its customers in a hassle-free and discreet
                      manner. PawnHero is the pawnshop that goes to your homes
                      which eliminates the need for people to go out into the
                      streets in search of ways to turn their valuables into
                      cash.
                    </p>
                  </div>
                </div>
              </ContainerStyled>
            </div>
          </section>
          <section>
            <img
              className='mobile-images'
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-2-mobile.png'
              alt=''
            />
            <div className='section-wrapper left'>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-2.png'
                alt=''
              />
              <ContainerStyled className='text-content-wrapper left '>
                <div className='half-wrapper'>
                  <div className='context-wrapper'>
                    <p>
                      Unlike traditional pawn shops, PawnHero isn’t limited to
                      just pawning jewelry but instead also accepts designer
                      bags, gadgets (smartphones, tablets, laptops), electronics
                      (TV, gaming consoles, etc..), watches, and even cameras.
                      To know more, visit our{' '}
                      <span className='underline'>Items We Accept page.</span>
                      <br />
                      <br />
                      PawnHero also offers one of the lowest interest rates in
                      the country going as low as 3.5% per month. Customers
                      also enjoy flexible loan terms which are renewable every
                      month without hidden costs or charges. We also provide
                      some of the highest appraisal rates which you won’t find
                      in traditional pawnshops.
                      <br />
                      <br />
                      With PawnHero Pawnshop, you can experience the complete
                      pawnshop services even from the comfort of your home. All
                      you have to do is pawn conveniently online through our
                      official website. Walk-in customers are also accepted at
                      the PawnHero Pawnshop office located at the 7/F, Avecshares Center, 
                      Bonifacio Global City, Taguig City. Pawn now!
                    </p>
                  </div>
                </div>
              </ContainerStyled>
            </div>
          </section>
          <section>
            <img
              className='mobile-images'
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-3-mobile.png'
              alt=''
            />
            <div className='section-wrapper'>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-sec-3.png'
                alt=''
              />
              <ContainerStyled className='text-content-wrapper '>
                <div className='half-wrapper'>
                  <div
                    className='context-wrapper'
                    style={{ padding: '100px 0' }}>
                    <h3 style={{ textAlign: 'left' }}>
                      Pawning experience, upgraded!
                    </h3>
                    <p>
                      Whether you are pawning online via our website or at our
                      physical pawnshop, you will enjoy the upgraded pawning
                      experience that PawnHero Pawnshop proudly offers – fast,
                      safe, convenient and discreet! Pawn online or visit us at
                      7th Floor, Avecshares Center, BGC, Taguig City
                      from 9:00 AM to 7:00 PM, Monday to Saturday.
                    </p>
                  </div>
                </div>
              </ContainerStyled>
            </div>
          </section>
          <section id='mission'>
            <ContainerStyled>
              <div className='content-wrapper'>
                <div>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-mission-1.svg'
                    alt=''
                  />
                  <h4>Mission</h4>
                  <p>
                    We provide fast, convenient, and hassle-free loans to all
                    Filipinos by employing advanced technologies and modern best
                    practices.
                  </p>
                </div>
                <div>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-mission-2.svg'
                    alt=''
                  />
                  <h4>Vision</h4>
                  <p>
                    We will disrupt the global pawnbroking industry by leading
                    the race to digitization through the use of technology and
                    best practices. We will be the pioneer in acquiring and
                    developing new, untapped, and underserved markets all over
                    the world.
                  </p>
                </div>
                <div>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/about-mission-3.svg'
                    alt=''
                  />
                  <h4>Core Values</h4>
                  <p>
                    • Customer Satisfaction
                    <br />
                    • Innovation and Technology
                    <br />
                    • Integrity
                    <br />
                    • Excellence
                    <br />• Camaraderie and Teamwork
                  </p>
                </div>
              </div>
            </ContainerStyled>
          </section>
          <section id='featured_in'>
            <ContainerStyled>
              <div>
                <h3>PawnHero has been featured at</h3>

                <div className='list-container'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-abs.svg'
                    alt='abs-cbn'
                    id='fea_abs'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-gma.svg'
                    alt='gma'
                    id='fea_gma'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-rappler.svg'
                    alt='rappler'
                    id='fea_rappler'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-inquirer.svg'
                    alt='inquirer'
                    id='fea_inquirer'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-forbes.svg'
                    alt='forbes'
                    id='fea_forbes'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-bloomberg.svg'
                    alt='bloomberg'
                    id='fea_bloomberg'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-wsj.svg'
                    alt='wsh'
                    id='fea_wsj'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-entrepreneur.svg'
                    alt='entrepreneur'
                    id='fea_entrepreneur'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-techcrunch.svg'
                    alt='techcrunch'
                    id='fea_techcrunch'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-inc.svg'
                    alt='inc.'
                    id='fea_inc'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/fea-techinasia.svg'
                    alt='techinasia'
                    id='fea_techniasia'
                  />
                </div>
              </div>
              <div>
                <h3>Awards won</h3>
                <div className='list-container'>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/award-top100.svg'
                    alt='top 100 pawnshop.'
                    id='award_top'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/award-startup.svg'
                    alt='most promising startup 2015.'
                    id='award_startup'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/award-osaka.svg'
                    alt='HackOsaka 2016 gold prize winner.'
                    id='award_osaka'
                  />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/award-ceo.svg'
                    alt='asia ceo gold prize'
                    id='award_asia'
                  />
                </div>
              </div>
            </ContainerStyled>
          </section>
          <section id='media'>
            <ContainerStyled>
              <h3>Press and Media</h3>

              <div className='press-list-wrapper'>
                {pressLink.map((e, i) => (
                  <Fragment key={i}>
                    <div className='press'>
                      <a
                        href={e.link}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <div className='press-img'>
                          <img src={e.img} alt='' />
                        </div>
                        <div className='press-data'>
                          <h4>{e.title}</h4>
                          <p>{e.content}</p>
                        </div>
                      </a>
                    </div>
                  </Fragment>
                ))}
              </div>
            </ContainerStyled>
          </section>
        </AboutUsStyled>
      </Layout>
    </>
  );
};

export default AboutUs;
