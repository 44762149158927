import styled from 'styled-components';
const { SlideInKeyframes, SlideInLeftKeyframes } = require('./layout.style');

export const StepStyled = styled.div`
  animation: ${(props) =>
      props.slideLeft ? SlideInLeftKeyframes : SlideInKeyframes}
    300ms both ease;
  border-radius: 5px 5px 0 0;
  display: ${(props) => (props.active ? 'block' : 'none')};

  .pawn-activity-body {
    & > div:last-child {
      border-bottom: 1px solid #c7c7cc;
      .entry-label-wrapper {
        border-bottom: unset !important;
      }
    }
  }
`;
