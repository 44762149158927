import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../layout';
import { SectionStyled } from '../../styles/section.style';
import { PawningStyled } from '../../styles/pawning.style';
import { ContainerStyled } from '../../styles/layout.style';

import Collapse from '../faq/collapse';
import { FAQ_data } from '../../../actions/global';

import NavMenu from './navMenu';
import NavMenuMob from './navMenuMob';
import FeedBacks from './feedBacks';
import TermsAndConditions from './termsAndConditions';
import Regulatory from './regulatory';

import SimpleReactLightbox from 'simple-react-lightbox'

import { SRLWrapper } from 'simple-react-lightbox';

const PawnheroPawning = (props) => {

const _half     = Math.ceil(FAQ_data.length / 2);
const _lefFAQ   = FAQ_data.slice(0, _half);
const _rightFAQ = FAQ_data.slice(_half);

const [toggleOption, toggleOptionHandler] = useState(false);
const [activeMenu, activeMenuHandler] = useState('About PawnHero')

const toggleMenu = () => {
    toggleOptionHandler(!toggleOption);
};  

const setActiveMenu = (selectedMenu) => {
    activeMenuHandler(selectedMenu)
    toggleOptionHandler(!toggleOption);
}

useEffect(() => {
    const hash = props.history.location.hash
    const id = hash.replace('#', '');
    if(id) {
        setTimeout(() => {
            const element = document.getElementById(id+"-anchor");
            var padding =  window.innerWidth < 950 ? 100 : 0;

            var pos = element.offsetTop - padding;
        
            window.scrollTo({top: pos, behavior: 'smooth'});
        }, 150);
    } 
});

return (
    <>
        <Layout>
            <SimpleReactLightbox>
            <SectionStyled bgColor="#354F65">
                <div >
                    <ContainerStyled>
                        <PawningStyled>
                            <div className="breadcrum fixed-breadcrum">
                                <p >
                                    <Link to='/' id='pawning_home_link'>
                                        Home
                                    </Link>{' '}
                                    •{' '}
                                    <span>
                                        Pawning
                                    </span>
                                </p>
                            </div>
                            <div className="nav-container">
                                <div className="left-content"> 
                                    <NavMenu />
                                </div>
                            </div>
                            <div className="nav-mobile"> 
                                <div className="breadcrum fixed-breadcrum-mob">
                                    <p >
                                        <Link to='/' id='pawning_home_link'>
                                            Home
                                        </Link>{' '}
                                        •{' '}
                                        <span>
                                            Pawning
                                        </span>
                                    </p>
                                </div>
                                <div className="nav-container-mob" onClick={toggleMenu}>
                                    <div className='active-nav-mob'>{activeMenu}</div>
                                    <div className={toggleOption ? 'nav-menu-mob active' : 'nav-menu-mob'} >
                                        <img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/down-arrow-white-ico.svg" alt="" />
                                    </div>  
                                </div>
                                <div className={toggleOption ? 'nav-container-link' : 'nav-container-link hide'}>
                                    <NavMenuMob onClick={(menu) => setActiveMenu(menu)}/>
                                </div>
                            </div>
                            <div className="content content-mob">
                                <div className="section bottom-zero">
                                    <div className="top-banner">
                                        <div className="banner-title">
                                            <h1 className="title-text">For your cash needs, <br/>there's PawnHero</h1>
                                        </div>
                                        <div className="banner-image">
                                            <img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/model-formal-big.png" alt=""></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="section" id="about_pawnhero">
                                    <h2 className="section-title">About PawnHero</h2>
                                    <h3 className="section-text section-subtitle">Pawning</h3>
                                    <p className="section-text">PawnHero is the first online pawn shop in the Philippines and is leading the modernization of the pawnbroking industry in the country since 2015. PawnHero’s mission is to provide the most convenient and reliable financial service to all Filipinos in need of quick cash loans.</p>
                                    <p className="section-text">Through the use of advanced technology, PawnHero is able to provide instant cash loans to its customers in a hassle-free and discreet manner. PawnHero is the pawn shop that goes to your homes which eliminates the need for people to go out into the streets in search of ways to turn their valuables into cash. A safer option for you, our pawners, especially during this time of pandemic.</p>
                                    <p className="section-text last-item">With PawnHero Pawnshop, you can experience the complete pawn shop services even from the comfort of your home. All you have to do is pawn conveniently online through our official website. Walk-in customers are also accepted at the PawnHero Pawnshop office.</p>
                                    <h3 className="section-text section-subtitle">Pawning Luxury</h3>
                                    <p className="section-text">PawnHero is your expert luxury pawn shop in the Philippines. Our expertise is on providing secured loans by using your designer bags, luxury watches and other high-end assets as collateral. We accept brands like Louis Vuitton, Hermès, Chanel, Givenchy, Prada, Goyard, Gucci, YSL, Celine, ROLEX, Audemars Piguet, Patek Philippe and more. We also accept gold, jewelry and more.</p>
                                    <p className="section-text last-item">The likes of businessmen, investors, entrepreneurs, small business owners, luxury resellers and such choose PawnHero Pawnshop when obtaining short term capital to address a financial situation or meet an opportunity fast and easy with less documentation and waiting time.</p>
                                    <h3 className="section-text section-subtitle">Pawning Smartphones and Other Gadgets</h3>
                                    <p className="section-text">PawnHero is your trusted go-to pawn shop for smartphones, other gadgets and electronics in the Philippines. Our expertise covers providing secured loans by using your smartphones, laptops and other high-end assets like cameras and Smart TVs as collateral. We accept brands like Apple, Samsung, Huawei, Vivo, OPPO, Sony, Fujifilm, Canon, Nikon, Microsoft, and more.</p>
                                    <p className="section-text last-item">Pawners choose PawnHero Pawnshop when obtaining short term capital to address a financial situation, cover emergency expenses or meet an opportunity fast and easy with less documentation and waiting time.</p>
                                    <div className="button-container">
                                        <a 
                                        className="button orange"
                                        href='/pawn' 
                                        target='_blank' 
                                        rel='noopener noreferrer'>
                                            Pawn now
                                        </a>
                                    </div>
                                    <h3 className="section-text section-subtitle">Pawning experience, upgraded!</h3>
                                    <p className="section-text last-item">Whether you are pawning online via our website or at our physical pawn shop, you will enjoy the upgraded pawning experience that PawnHero Pawnshop proudly offers – fast, safe, convenient and discreet! Pawn online or visit us at 7/F, Avecshares Center, BGC, Taguig City from 9:00 AM to 7:00 PM, Monday to Saturday.</p>
                                    <h3 className="section-text section-subtitle">What you should expect:</h3>
                                    <ul className="section-list">
                                        <li>Loan approval in as fast as 1 day</li>
                                        <li>Loan release real time upon approval</li>
                                        <li>Loan amount limit is only based on your per item’s value</li>
                                        <li>Less documentation and no credit check</li>
                                    </ul>
                                    <h3 className="section-text section-subtitle">What you will enjoy:</h3>
                                    <ul className="section-list">
                                        <li>Fast funding to address a situation or meet an opportunity</li>
                                        <li>Flexible terms to suit your specific cash needs</li>
                                        <li>Good alternative to selling your asset</li>
                                        <li>Concierge-type pawning experience</li>
                                    </ul>
                                </div>
                                <div className="section bg-gray" id="items_we_accept">
                                    <h2 className="section-title">Items We Accept</h2>
                                    <p className="section-text last-item">Unlike traditional pawn shops, at PawnHero, we accept a wide variety of valuable items such as luxury items like designer bags, luxury watches, designer belts, designer sunglasses, designer shoes and more. In addition, we also accept an expanded list of items like smartphones, other electronic gadgets like soundbars and speakers, gaming consoles, gold jewelry, precious metals, gemstones and more that are already traditionally accepted by pawn shops. All of these are acceptable as loan collateral at PawnHero Pawnshop. For a full list of items we accept, see the list below.</p>
                                    <h3 className="section-text section-subtitle">Get a FREE initial appraisal</h3>
                                    <div className="card-wrapper">
                                        <div className="card-wrapper-items">
                                            <a id="items_we_accept_categoy_jewelry" className="card" href="/items-we-accept/jewelry" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/35ec90698bd542b186083d0fc2d86003.png" alt="Jewelry" />
                                                </div>
                                                <p className="card-title">Jewelry</p>
                                            </a>
                                            <a id="items_we_accept_categoy_designer-bags" className="card" href="/items-we-accept/designer-bags" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/83a010ca8411435b9f3f694b63be0a8f.png" alt="Designer Bags" />
                                                </div>
                                                <p className="card-title">Designer Bags</p>
                                            </a>
                                            <a id="items_we_accept_categoy_luxury-watches" className="card" href="/items-we-accept/luxury-watches" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/307c7236a3c54d8b8636aa82e781d52c.png" alt="Watches" />
                                                </div>
                                                <p className="card-title">Watches</p>
                                            </a>
                                            <a id="items_we_accept_categoy_laptops" className="card" href="/items-we-accept/laptops" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/10742021f21844bbb6c66d310f448cf5.png" alt="Laptops" />
                                                </div>
                                                <p className="card-title" >Laptops</p>
                                            </a>
                                            <a id="items_we_accept_categoy_gaming-console" className="card" href="/items-we-accept/gaming-console" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/e0c5790a25da441ba60c67978fa626f4.png" alt="Gaming Consoles" />
                                                </div>
                                                <p className="card-title">Gaming Consoles</p>
                                            </a>
                                            <a id="items_we_accept_categoy_cameras" className="card" href="/items-we-accept/cameras" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/0aa3c28d3bc94b78a0f05fe1fcb70b04.png" alt="Cameras" />
                                                </div>
                                                <p className="card-title">Cameras</p>
                                            </a>
                                            <a id="items_we_accept_categoy_smartphones" className="card" href="/items-we-accept/smartphones" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/e964784f8c044a38b018427e9ff76ddc.png" alt="Smartphones" />
                                                </div>
                                                <p className="card-title">Smartphones</p>
                                            </a>
                                            <a id="items_we_accept_categoy_tablets" className="card" href="/items-we-accept/tablets" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/78b92e3519f04352b92b3e6e91c3df1c.png" alt="Tablets" />
                                                </div>
                                                <p className="card-title">Tablets</p>
                                            </a>
                                            <a id="items_we_accept_categoy_televisions" className="card" href="/items-we-accept/televisions" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/d42d60383bd745d38dc8637b38404c90.png" alt="Televisions"/>
                                                </div>
                                                <p className="card-title">Televisions</p>
                                            </a>
                                            <a id="items_we_accept_categoy_sunglasses" className="card" href="/items-we-accept/sunglasses" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/562c12abe5194940a1b0508c78cfdc3a.png" alt="Sunglasses" />
                                                </div>
                                                <p className="card-title">Sunglasses</p>
                                            </a>
                                            <a id="items_we_accept_categoy_soundbar-and-speakers" className="card" href="/items-we-accept/soundbar-and-speakers" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/caa78e47764a4b01af61f82711672a25.png" alt="Soundbar and Speakers" />
                                                </div>
                                                <p className="card-title">Soundbar and Speakers</p>
                                            </a>
                                            <a id="items_we_accept_categoy_belt" className="card" href="/items-we-accept/belt" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/06cb6472573343e6b7b9585d516af03c.png" alt="Belts" />
                                                </div>
                                                <p className="card-title">Belts</p>
                                            </a>
                                            <a id="items_we_accept_categoy_shoes" className="card" href="/items-we-accept/designer-shoes" target='_blank' rel='noopener noreferrer'>
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/86988bb27d844632a6860872c981db96.png" alt="Designer shoes" />
                                                </div>
                                                <p className="card-title">Designer Shoes</p>
                                            </a> 
                                            <a  className="card hidden" href="/items-we-accept/belt3">
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/06cb6472573343e6b7b9585d516af03c.png" alt="Belts" />
                                                </div>
                                                <p className="card-title">Designer Shoes</p>
                                            </a> 
                                            <a  className="card hidden" href="/items-we-accept/belt2">
                                                <div className="image-wrapper">
                                                    <img  src="https://d1v5w8bodpeh4i.cloudfront.net/06cb6472573343e6b7b9585d516af03c.png" alt="Belts" />
                                                </div>
                                                <p className="card-title">Designer Shoes</p>
                                            </a> 
                                            
                                        </div>
                                        
                                    </div>
                                        
                                </div>
                                <div id="interest_rates" className="section">
                                    <h2 className="section-title">Interest Rates and Other Charges</h2>
                                    <p className="section-text">
                                        PawnHero also offers one of the lowest interest rates in the country going as low as 3.5% per month. Customers also enjoy flexible loan terms which are renewable every month without hidden costs. We also provide some of the highest appraisal rates which you won’t find in traditional pawn shops.
                                    </p>
                                    <p className="section-text">
                                        PawnHero Pawnshop offers flexible loan terms and interest packages depending on the item category used as collateral for a loan.
                                    </p>
                                    <h3 className="section-text section-subtitle">Charges applied for gold and jewelry:</h3>
                                    <ul className="section-list list-decimal">
                                        <li>Interest of 3.5% for gold and jewelry or ₱ 500.00 whichever is higher</li>
                                        <li>Service charge of 1% of principal loan but shall not exceed ₱ 5.00</li>
                                        <li>Documentary stamp tax of ₱ 40.00 for loan amount up to ₱ 5,000.00, additional ₱ 20.00 on each ₱ 5,000.00 or fractional part in excess of ₱ 5,000.00</li>
                                    </ul>
                                    <div className="computation-wrapper">
                                        <img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/computation-gold-and-jewelry.svg" alt="Computation Gold" />
                                    </div>
                                    <h3 className="section-text section-subtitle">Charges applied for designer bags, luxury watches, gadgets and other item categories:</h3>
                                    <ul className="section-list list-decimal">
                                        <li>Interest of 4.99% or ₱ 500.00 whichever is higher</li>
                                        <li>Service charge of 1% of principal loan but shall not exceed ₱ 5.00</li>
                                        <li>Documentary stamp tax of ₱ 40.00 for loan amount up to ₱ 5,000.00, additional ₱ 20.00 on each ₱ 5,000.00 or fractional part in excess of ₱ 5,000.00</li>
                                    </ul>
                                    <div className="computation-wrapper">
                                        <img src="https://d1v5w8bodpeh4i.cloudfront.net/assets/computation-bags-watches-gadgets-smartphones-others.svg" alt="Computation Gold" />
                                    </div>
                                      
                                </div>
                                <div id="new-loan-term-policy-anchor"></div>
                                <div id="new_loan_term_policy" className="section bg-gray">
                                    <h2 className="section-title">New Loan Term Policy</h2>
                                    <p className="section-text"></p>
                                    <ul className="section-list list-decimal">
                                        <li>Effective March 15, 2022, Pawnhero Pawnshop Philippines, Inc ("PawnHero") shall have a maximum 3-month initial loan term for all new loans secured by personal property except for electronic gadgets;</li>
                                        <li>Maximum of 1-month loan term and no renewal shall still apply for electronic gadgets.</li>
                                    </ul>
                                </div>
                                <div id="newrenewalpolicy-anchor"></div>
                                <div id="new_renewal_policy" className="section">
                                    <h2 className="section-title">New Renewal Policy</h2>
                                    <p className="section-text"></p>
                                    <ul className="section-list list-decimal">
                                        <li>Effective Feb. 15, 2022, Pawnhero Pawnshop Philippines, Inc. (“PawnHero”) shall allow a limit of up to three (3) renewal applications (with the exception of the provision on #3) for all new loans secured by personal property other than electronic gadgets.</li>
                                        <li>One (1) loan renewal application shall be equivalent to 1 month loan renewal.</li>
                                        <li>Even though the loan renewal limit has not been reached, loan renewal applications shall be declined if the cumulative number of loan renewal applications exceed 3 months. Refer to “Illustration 4” for a sample.</li>
                                        <li>Loan renewal shall remain unavailable for electronic gadgets.</li>
                                        <li>The three-renewal limit policy shall not apply to existing loans made prior to Feb. 15, 2022.</li>
                                        <li>PawnHero shall issue a new pawn ticket indicating the new term of the loan agreed upon by PawnHero and pawner relating to the renewal.</li>
                                    </ul>

                                    <h3 className="section-text section-subtitle">See sample below.</h3>
                                    <SRLWrapper>
                                        <div className="card-wrapper">
                                            <div className="card-wrapper-items">
                                                <a id="new_renewal_policy_illustration_1" href="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_1.png" className="card" rel='noopener noreferrer'>
                                                    <div className="image-wrapper">
                                                        <img  src="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_1.png" alt="Illustration 1" />
                                                    </div>
                                                    <p className="card-title" >Illustration 1</p>
                                                </a>
                                                <a id="new_renewal_policy_illustration_2" className="card" href="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_2.png"  rel='noopener noreferrer'>
                                                    <div className="image-wrapper">
                                                        <img  src="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_2.png" alt="Illustration 2" />
                                                    </div>
                                                    <p className="card-title" >Illustration 2</p>
                                                </a>
                                                <a id="new_renewal_policy_illustration_3" className="card" href="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_3.png"  rel='noopener noreferrer'>
                                                    <div className="image-wrapper">
                                                        <img  src="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_3.png" alt="Illustration 3" />
                                                    </div>
                                                    <p className="card-title" >Illustration 3</p>
                                                </a>
                                                <a id="new_renewal_policy_illustration_4" className="card" href="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_4.png"  rel='noopener noreferrer'>
                                                    <div className="image-wrapper">
                                                        <img  src="https://pawnhero-main-v2.s3.ap-southeast-1.amazonaws.com/Illustration_4.png" alt="Illustration 4" />
                                                    </div>
                                                    <p className="card-title" >Illustration 4</p>
                                                </a>
                                            </div>
                                        </div> 
                                    </SRLWrapper>
                                </div>
                                <div id="how_to_pawn" className="section bg-gray" >
                                    <h2 className="section-title">How to Pawn</h2>
                                    <h3 className="section-text section-subtitle">How to #PawnSafelyAtHome (Web and App)</h3>
                                    <ul className="section-list list-decimal zero-bottom">
                                        <li>Click Pawn Now to pawn your valued item and create your pawn ticket.</li>
                                        <li>
                                            Fill out the required details of your online pawn ticket then book your item pick-up schedule. Our certified in-house courier (Pawntrooper) will be at your specified address to pick up your collateral. Enjoy loan approval within 1 DAY only after your collateral has been picked up and appraised!
                                        </li>
                                        <li>Once you’ve accepted our final loan offer, you may opt to cash out via bank transfer or any InstaPay and PESONet partner banks and electronic money issuers. Fast and secure!</li>
                                    </ul>
                                    <p className="section-text last-item">Enjoy convenient online transaction even after you #PawnSafelyAtHome! You may pay redemption of pawned items online and submit your proof of payment through the PawnHero website.</p>
                                    <h3 className="section-text section-subtitle">How to Pawn Privately at the PawnHero Pawnshop Lounge - BGC, Taguig</h3>
                                    <ul className="section-list list-decimal zero-bottom">
                                        <li>Via web or app, click Pawn Now to pawn your valuable item and create your pawn ticket. </li>
                                        <li>
                                            Fill out the required details of your online pawn ticket then book your walk-in schedule. 
                                        </li>
                                        <li>Once you have arrived at the PawnHero Pawnshop Lounge, you will be assisted by our trusted loan support team throughout your loan application journey and our expert in-house appraisers will determine the valuation of your asset based on the current physical condition of your item and its current value in the market.</li>
                                        <li>Once you’ve accepted our final loan offer, you will receive your cash on the spot real time. You may also opt to cash out via bank transfer or any InstaPay and PESONet partner banks and electronic money issuers. Fast and secure!</li>
                                    </ul>
                                    <p className="section-text last-item">
                                        Enjoy convenient online transactions even after you pawn privately from the PawnHero Lounge! You may pay renewal or redemption of pawned items online and submit your proof of payment through the PawnHero web or app.
                                    </p>
                                    <div className='computation-wrapper'>
                                        <iframe 
                                            width="525" 
                                            height="295" 
                                            src="https://www.youtube.com/embed/o2VS5RMA5W8" 
                                            title="#PawnHero101: Two Convenient Ways to Pawn with Us" 
                                            frameBorder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                </div>
                                <div id="feedback" className="section">
                                    <h2 className="section-title">Feedback</h2>
                                    <h3 className="section-text section-subtitle">What our customers are saying:</h3>
                                    <FeedBacks />
                                </div>
                                <div id="requirements" className="section bg-gray">
                                    <h2 className="section-title">Requirements</h2>
                                    <h3 className="section-text section-subtitle">IDs Accepted</h3>
                                    <p className="section-text">
                                        On or before the commencement of transaction, pawner must present the original and submit a copy of at least one (1) of the following valid photo-bearing identification document issued by an official authority:
                                    </p>
                                    <ul className="section-list">
                                        <li>Passport </li>
                                        <li>Driver’s License </li>
                                        <li>Professional Regulation Commission (PRC) ID</li>
                                        <li>National Bureau of Investigation (NBI) Clearance </li>
                                        <li>Police Clearance</li>
                                        <li>Postal ID</li>
                                        <li>Voter's ID</li>
                                        <li>Barangay Certification</li>
                                        <li>Government Service Insurance System (GSIS) e-Card </li>
                                        <li>Unified Multi-Purpose ID (UMID) Card</li>
                                        <li>Social Security System (SSS) Card</li>
                                        <li>Senior Citizen Card</li>
                                        <li>Overseas Workers Welfare Administration (OWWA) ID</li>
                                        <li>OFW ID</li>
                                        <li>Seaman’s Book</li>
                                        <li>Alien Certification of Registration/Immigration Certificate of Registration</li>
                                        <li>Government Office or GOCC ID </li>
                                        <li>Certification from National Council for the Welfare of Disabled Persons(NCWDP) </li>
                                        <li>Department of Social Welfare and Development (DSWD) Certification </li>
                                        <li>Integrated Bar of the Philippines ID</li>
                                        <li>Company ID issued by private entities or institutions registered with or supervised or regulated either by the BSP, SEC or IC </li>
                                        <li>PhilID or National ID</li>
                                    </ul>
                                    <p className="section-text">
                                        For pawners that are juridical persons,  the documents below must be submitted as a valid form of identification.
                                    </p>
                                    <ul className="section-list">
                                        <li>For sole proprietor -  Certificates of Registration issued by the Department of Trade and Industry (DTI)</li>
                                        <li>For corporation/partnership - 
                                            <ul className="section-list">
                                                <li>Certificate of Incorporation/Partnership issued by the Securities and Exchange Commission (SEC);</li>
                                                <li>Articles of Incorporation/Partnership;</li>
                                                <li>Registration Data Sheet/Latest General Information Sheet; and</li>
                                                <li>Secretary’s Certificate citing the pertinent portion of the Board or Partners’ Resolution authorizing the signatory to sign on behalf of the entity </li>
                                            </ul>
                                        </li>
                                        <li>For business supervised by the Bangko Sentral ng Pilipinas (BSP) - Certificate of Authority</li>
                                        <li>For business covered by the Anti-Money Laundering Council (AMLC) - Certificate of Registration </li>
                                        <li>For entities registered outside of the Philippines - similar documents and/or information duly authenticated by a senior officer of the covered person assigned in the country of registration; in the absence of said officer, the documents shall be authenticated by the Philippine Consulate, company register or notary public, where said entities are registered.</li>
                                    </ul>
                                    <h3 className="section-text section-subtitle">Additional Requirements</h3>
                                    <p className="section-text">
                                        For some instances, pawners are required to submit the following documents and information:
                                    </p>
                                    <p className="section-text">
                                        For Natural Person
                                    </p>
                                    <ul className="section-list list-decimal">
                                        <li>Utility bills, bank or credit card statement, or other documents showing the permanent address;</li>
                                        <li>Document/s supporting the source of fund and wealth, and the nature of occupation and/or business (e.g., Income Tax Return, Certificate of Employment with Salary Details, Registration Data Sheet, General Information Sheet, Articles of Incorporation, Articles of Partnership);</li>
                                        <li>List of companies where pawner is a stockholder, director, officer, or authorized signatory; and</li>
                                        <li>Reason/s for pawning</li>
                                    </ul>
                                    <p className="section-text">
                                        For Juridical Person
                                    </p>
                                    <ul className="section-list list-decimal">
                                        <li>Utility bills, bank or credit card statement, other documents showing the business address;</li>
                                        <li>Supporting information on source of funds, and nature of business(e.g., Income Tax Return, Audited Financial Statement, Bank References);</li>
                                        <li>Details of the name, present address, nationality, date of birth, nature of work, contact number and source of funds of the primary officers, the stockholders and the directors;</li>
                                        <li>Valid ID of primary officers, stockholders and directors; and</li>
                                        <li>Reason/s for pawning</li>
                                    </ul>
                                </div>
                                <div className="section">
                                    <h2 className="section-title">Renewals, Redemptions, and Payments</h2>
                                    <h3 className="section-text section-subtitle">How to Renew</h3>
                                    <p className="section-text">With the convenient technology of PawnHero, you may now request to renew your loan anywhere online using the PawnHero website. No need to go out and visit the PawnHero Pawnshop lounge in BGC. Manage your pawn ticket through this link: <a href="https://pawnhero.ph/guest" target='_blank' rel='noopener noreferrer'>https://pawnhero.ph/guest</a></p>
                                    <p className="section-text last-item">You may pay renewal of pawned items online and submit your proof of payment through this link: <a href="https://pawnhero.ph/manage/confirm_loan_payment" target='_blank' rel='noopener noreferrer'>https://pawnhero.ph/manage/confirm_loan_payment</a></p>
                                    <h3 className="section-text section-subtitle">How to Redeem</h3>
                                    <p className="section-text">Just like how you can renew online, with the convenient technology of PawnHero, you may now request to redeem anywhere online using the PawnHero website. You may choose to have your item delivered right at your specified location (subject to PawnHero area of service coverage) or visit the PawnHero Pawnshop lounge in BGC. Manage your pawn ticket through this link: <a href="https://pawnhero.ph/guest" target='_blank' rel='noopener noreferrer'>https://pawnhero.ph/guest</a></p>
                                    <p className="section-text last-item">You may pay redemption of pawned items online and submit your proof of payment through this link: <a href="https://pawnhero.ph/manage/confirm_loan_payment" target='_blank' rel='noopener noreferrer'>https://pawnhero.ph/manage/confirm_loan_payment</a></p>
                                    <h3 className="section-text section-subtitle">Payment Methods for Loan Renewal and Redemption</h3>
                                    <p className="section-text last-item">You may pay via these payment methods:</p>
                                    <ul className="section-list">
                                        <li>Over-the-Counter</li>
                                        <li>Cash Payment</li>
                                        <li>Online Bank Payment</li>
                                        <li>7-Eleven (7-CONNECT Service)</li>
                                        <li>Coins.ph</li>
                                    </ul>
                                    <p className="section-text text-italic">*Transaction and service fees may apply.</p>
                                </div>
                                <div id="faqs" className="section bg-gray" >
                                    <h2 className="section-title">Frequently Asked Questions (FAQs)</h2>
                                    <div className='items-wrapper'>
                                        <div className='column-wrapper'>
                                        {_lefFAQ.map((e, i) => (
                                            <Fragment key={i}>
                                            <Collapse title={e.title} className='faq-collapse'>
                                                {e.contentFaq ? e.contentFaq() : e.content()}
                                            </Collapse>
                                            </Fragment>
                                        ))}
                                        </div>
                                        <div className='column-wrapper'>
                                        {_rightFAQ.map((e, i) => (
                                            <Fragment key={i}>
                                            <Collapse title={e.title} className='faq-collapse'>
                                                {e.contentFaq ? e.contentFaq() : e.content()}
                                            </Collapse>
                                            </Fragment>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                                <TermsAndConditions />
                                <Regulatory />

                            </div>
                            
                        </PawningStyled>
                    </ContainerStyled>
                </div>
            </SectionStyled>
            </SimpleReactLightbox>
        </Layout>
    </>
  );
};

export default PawnheroPawning;
