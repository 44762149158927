import React from 'react';

const ElipseLoader = () => {
  return (
    <div
      style={{
        margin: 'auto',
      }}>
      <div className='snipper-loader'>
        <img
          className='loader'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
          alt='loading'
          style={{
            width: '32px',
            height: '32px',
            margin: 'auto',
            display: 'flex',
          }}
        />
      </div>
    </div>
  );
};

export default ElipseLoader;
