import React from 'react';
import Modal from 'react-modal';
import { ContainerStyled } from '../../styles/layout.style';

const ReactModal = (props) => {
  const {
    children,
    toggle,
    toggleHandler,
    contentContainerStyle,
    withHeader = true,
  } = props;

  Modal.setAppElement('#root');
  return (
    <>
      <Modal
        isOpen={toggle}
        className='react-modal'
        overlayClassName='react-overlay-modal'
        onRequestClose={() => toggleHandler(false)}>
        <div className='react-modal-content'>
          <ContainerStyled className='container' style={contentContainerStyle}>
            {withHeader && (
              <div className='react-modal-content-header'>
                <img
                  className='modal-close'
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/otp-modal-close-icon.svg'
                  alt='close'
                  onClick={() => {
                    toggleHandler(false);
                  }}
                />
              </div>
            )}
            <div className='react-modal-content-body'>{children}</div>
          </ContainerStyled>
        </div>
      </Modal>
    </>
  );
};

export default ReactModal;
