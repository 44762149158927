import React, { Fragment } from 'react';
import SharedCarousel from '../../components/shared/owlCarousel';
import { Link } from 'react-router-dom';

// styles
import { FaqStyled } from '../../styles/home.style';
import { FAQ_data } from '../../../actions/global';

const Faq = (props) => {
  const options = {
    center: true,
    loop: false,
    dots: true,
    dotsEach: true,
    margin: 30,
    startPosition: 0, //index
    responsive: {
      0: {
        items: 1,
        stagePadding: 20,
      },
      801: {
        items: 3,
        stagePadding: 50,
      },
      1024: {
        items: 3,
        stagePadding: 125,
      },
    },
  };
  return (
    <FaqStyled>
      <div className='swish first'>
        <img
          className='desktop'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-3-1.svg'
          alt=''
        />
        <img
          className='mobile'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-3-1-mobile.svg'
          alt=''
        />
      </div>
      <div className='swish last'>
        <img
          className='desktop'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-3-2.svg'
          alt=''
        />
        <img
          className='mobile'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/swish-3-2-mobile.svg'
          alt=''
        />
      </div>
      <div style={{ position: 'relative', zIndex: '1' }}>
        <h2>Frequently asked questions</h2>
        <SharedCarousel options={options} theme='aqua'>
          {FAQ_data.map((e, i) => (
            <Fragment key={i}>
              <div>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/faq-icon.svg'
                  alt='?'
                />
                <p>{e.title}</p>
                {e.content()}
              </div>
            </Fragment>
          ))}

          <div className='read-more' id='home_faq_read_more'>
            <Link to='/faq' id='home_faq_link'>
              <img
                src='https://d1v5w8bodpeh4i.cloudfront.net/assets/add-ico.svg'
                alt='read more'
              />
              <p>Read more</p>
            </Link>
          </div>
        </SharedCarousel>
      </div>
    </FaqStyled>
  );
};

export default Faq;
