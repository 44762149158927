import React, { useEffect, useState } from 'react';

// utilities
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateLoan } from '../../../actions/loan';
import { fetchAcceptanceOffer, fetchFinalOffer } from '../../../actions/ticket';

// styles
import { LoanOfferStyled } from '../../styles/acceptance.style';

const Offer = ({
  doUpdateLoan,
  loan,
  doFetchAcceptanceOffer,
  doFetchFinalOffer,
  animationDoneHandler,
  mode = 0, // 0 -> acceptance : 1 -> offer
  noOffer = false,
  productName,
}) => {
  const { slideLeft = false } = loan;

  // params
  const { ticketNum } = useParams();

  // states
  const [offers, offersHandler] = useState(false);
  const [error, errorHandler] = useState(null);
  const [promo, promoHandler] = useState(false);

  // methods
  const offerSelect = (offer) => {
    doUpdateLoan({
      position: 1,
      data: { ...loan.data, offer },
      slideLeft: false,
    });
  };

  //load events
  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      if (mode === 0) {
        getAcceptanceOffer();
      } else {
        if (!noOffer) {
          getFinalOffer();
        }
      }
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  const getAcceptanceOffer = async () => {
    animationDoneHandler(false);
    try {
      const { offers, promo } = await doFetchAcceptanceOffer({ ticketNum });
      promoHandler(promo);
      offersHandler(offers);
    } catch (err) {
      errorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
    setTimeout(() => {
      animationDoneHandler(true);
    }, 300);
  };

  const getFinalOffer = async () => {
    animationDoneHandler(false);
    try {
      const { offers, promo } = await doFetchFinalOffer({ ticketNum });
      promoHandler(promo);
      offersHandler(offers);
    } catch (err) {
      errorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
    setTimeout(() => {
      animationDoneHandler(true);
    }, 300);
  };

  return (
    <LoanOfferStyled slideLeft={slideLeft}>
      <div className='option-wrapper'>
        <div className='option-header'>
          <p>Pawn Ticket {ticketNum.toUpperCase()}</p>
        </div>
        <div className='option-list-wrapper'>
          <ul>
            {noOffer ? (
              <>
                <li>
                  <div className='list-content no-offer'>
                    <div>
                      <div>
                        <p className='title'>You tried to pawn</p>
                        <p className='primary-note'>{productName}</p>
                        <p className='note'>
                          <i>
                            Note: Your item will be delivered back to your
                            address, if we have it previously for appraisal
                          </i>
                        </p>
                      </div>
                    </div>
                    <div>
                      {/* <button className='primary'>Select loan offer</button> */}
                      <Link to='/pawn' className='btn' id='final_pawn_btn'>
                        Pawn another item
                      </Link>
                    </div>
                  </div>
                </li>
              </>
            ) : (
              <>
                {error ? (
                  <>
                    <p style={{ textAlign: 'center' }}>{error}</p>
                  </>
                ) : (
                  <>
                    {offers ? (
                      <>
                        {offers.map((offer, i) => {
                          return (
                            <li key={i}>
                              <div className='list-content'>
                                <div>
                                  <div>
                                    {offer.discount_raw > 0 ? 
                                      <p className='title-promo'> CASH BONUS PROMO (+{offer.discount})</p>
                                    :
                                      <p className='title'>You receive</p>
                                    }
                                    <p className='primary-note'>
                                      {offer.loanAmount}
                                    </p>
                                    <p className='note'>
                                      <span>
                                        Total Interest: {offer.totalInterest} •
                                        Interest Rate: {offer.interestRate}%
                                      </span>
                                      <span>
                                        Documentary Stamp Tax: {offer.dstCharge}{' '}
                                        + Service Fee: {offer.serviceCharge}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <p className='title'>Loan duration</p>
                                    <p className='primary-note'>{offer.term}</p>
                                    <p className='note'>
                                      <span>Pay {offer.amountToPay}</span>{' '}
                                      <span>
                                        on/before {offer.formattedDueDate}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    className='primary'
                                    onClick={() => {
                                      offerSelect(offer);
                                    }}>
                                    Select loan offer
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })}

                        {/* gold promo */}
                        {promo === 'fixed_interest' && (
                          <>
                            <li className='promo-li'>
                              <div className='pawnhero-gold-promo'>
                                <div>
                                  <img
                                    className='promo-banner'
                                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawnalo_gold-fix-3.svg'
                                    alt='pawnalo gold'
                                  />
                                </div>
                                <div>
                                  <p className='title'>PAWNALO DEAL:</p>
                                  <p>
                                    Get a higher appraisal up to 25% vs previous
                                    PawnHero valuation!
                                  </p>
                                  <br />
                                  <p className='title'>LIMITED OFFER:</p>
                                  <p>
                                    Enjoy a fixed 3.5% interest rate when you
                                    pawn gold and jewelry!
                                  </p>
                                  <br />
                                  <p className='note'>
                                    <Link to='/pawnalo-gold-jewelry-pawnshop-appraisal'>
                                      T&Cs apply.
                                    </Link>{' '}
                                    DTI Fair Trade Permit No. FTEB-121316 Series
                                    of 2021
                                  </p>
                                </div>
                              </div>
                            </li>
                          </>
                        )}
                        <li>
                          <div className='list-content-disclamer'>
                            {mode === 0 && (
                              <p>
                                Initial loan offer is based on the item
                                category, brand, model and attributes you
                                selected in the application.
                                <br />
                                Final offer may retain or change depending on
                                the item condition during physical appraisal.
                              </p>
                            )}

                            {mode === 1 && (
                              <p>
                                For any concerns about our loan offers, please
                                do not hesitate to contact our customer service
                                representatives at 8828-2274 between 9:00 AM and
                                7:00 PM.
                              </p>
                            )}
                          </div>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className='fetching-offer'>
                          <p>
                            {mode === 0 &&
                              ` Please wait while we calculate our best loan offers for you`}
                            {mode === 1 &&
                              `Please wait while we calculate our final loan offers
                            for you`}
                          </p>
                        </li>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </LoanOfferStyled>
  );
};

const mapStateToProps = pick(['loan']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateLoan: (loan) => dispatch(updateLoan(loan)),
  doFetchAcceptanceOffer: (ticket) => dispatch(fetchAcceptanceOffer(ticket)),
  doFetchFinalOffer: (ticket) => dispatch(fetchFinalOffer(ticket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
