import React from 'react';
import Layout from '../layout';
import { SectionStyled } from '../../styles/section.style';
import { AppStyled } from '../../styles/app.style';
import { ContainerStyled } from '../../styles/layout.style';

import AppPreviewCarousel from './appPreview';
import AppContext from './appContext';

const App = () => {

  return (
    <>
      <Layout>
        <SectionStyled bgColor="#2876B4" bgImage="https://d1v5w8bodpeh4i.cloudfront.net/assets/app-bg-blue.png">
            <AppStyled>
                <div className="tl-background">
                    <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/bg-tl-side.svg' alt="left-decor" />
                </div>    
                <ContainerStyled>
                    <div className="app-content">
                        <div className="left-content">
                            <img src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-mobile-money.png' alt="left-mobile" />        
                        </div>
                        <div className="right-content">
                            <div className="top"> 
                                <img className="right-mobile-money" style={{width: '115px'}} src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-mobile-money.png' alt="left-mobile" />        
                                <h1 className="title">PawnHero</h1>
                                <h3 className="sub-title">Your one-stop pawnshop app</h3>
                            </div>
                            <div className="middle">
                                <ul>
                                    <li>
                                        <img
                                            style={{width: '90px', height: '105px'}}
                                            src='http://d1v5w8bodpeh4i.cloudfront.net/assets/app-hand-pawn-lg2.svg'
                                            alt='app pawn'
                                        />
                                        <p>Pawn</p>
                                    </li>
                                    <li>
                                        <img
                                            style={{width: '90px', height: '105px'}}
                                            src='http://d1v5w8bodpeh4i.cloudfront.net/assets/app-hand-renew-lg2.svg'
                                            alt='app renew'
                                        />
                                        <p>Renew</p>
                                    </li>
                                    <li>
                                        <img
                                            style={{width: '90px', height: '105px'}}
                                            src='http://d1v5w8bodpeh4i.cloudfront.net/assets/app-hand-redeem-lg2.svg'
                                            alt='app redeem'
                                        />
                                        <p>Redeem</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="bottom">
                                <a 
                                href='https://apps.apple.com/us/app/pawnhero/id1580876430' 
                                target='_blank' 
                                rel='noopener noreferrer'>
                                    <img
                                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-download-ios.svg'
                                        alt='Download on the App Store'
                                    />
                                </a>
                                <a 
                                href='https://play.google.com/store/apps/details?id=com.pawnhero.mobileapp' 
                                target='_blank' 
                                rel='noopener noreferrer'>
                                    <img
                                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-download-android.svg'
                                        alt='Get it on Google Play'
                                    />
                                </a>
                                <img
                                    style={{
                                        width: '45px'
                                    }}
                                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/app-pawnhero-qr-code.png'
                                    alt='Pawnhero QR'
                                />
                            </div>
                        </div>
                    </div>
                </ContainerStyled>
                <div className="br-background">
                    <img alt="right-decor" src='https://d1v5w8bodpeh4i.cloudfront.net/assets/bg-lr-side.svg' />
                </div>    
            </AppStyled>
        </SectionStyled>
        <SectionStyled bgColor="#FFFFFF" >
            <AppStyled>
                <AppPreviewCarousel/>
            </AppStyled>
        </SectionStyled>
        <SectionStyled bgColor="#EFEFEF" >
            <AppStyled>
                <ContainerStyled>
                    <AppContext />
                </ContainerStyled>
            </AppStyled>
        </SectionStyled>
      </Layout>
    </>
  );
};

export default App;
