import React, { Fragment, useState } from 'react';

// components
import { advisoryData } from './advisoryData';
import ReactModal from '../../components/shared/reactModal';
import Layout from '../layout';

// style
import { ServiceAdvisoryStyled } from '../../styles/serviceAdvisory.style';
import { ContainerStyled } from '../../styles/layout.style';

const ServiceAdvisory = () => {
  const [toggle, toggleHandler] = useState(false);

  return (
    <>
      <Layout>
        <ServiceAdvisoryStyled>
          <section className='banner'>
            <ContainerStyled>
              <h1>PawnHero Service Advisory</h1>
            </ContainerStyled>
          </section>
          <section>
            <ContainerStyled>
              {advisoryData.map((e, i) => (
                <Fragment key={i}>
                  <div className='box'>
                    <h2>{e.title}</h2>

                    <p className='receiver'>{e.receiver}</p>
                    {e.subTitle && <p className='sub'>{e.subTitle}</p>}
                    <div
                      className='content'
                      dangerouslySetInnerHTML={{ __html: e.content }}
                    />
                    <p className='sender'>{e.sender}</p>
                    {e.readMore && (
                      <button
                        className='btn-readmore'
                        onClick={() => toggleHandler(`${i}-readmore`)}>
                        Read more details
                      </button>
                    )}
                    {e.faqData && (
                      <button
                        className='btn-faq'
                        onClick={() => toggleHandler(`${i}-faq`)}>
                        FAQs for online transactions
                      </button>
                    )}
                  </div>
                  {e.faqData && (
                    <ReactModal
                      toggle={toggle === `${i}-faq` ? true : false}
                      toggleHandler={toggleHandler}>
                      <div dangerouslySetInnerHTML={{ __html: e.content }} />
                    </ReactModal>
                  )}
                  {e.readMore && (
                    <ReactModal
                      toggle={toggle === `${i}-readmore` ? true : false}
                      toggleHandler={toggleHandler}>
                      <div dangerouslySetInnerHTML={{ __html: e.content }} />
                    </ReactModal>
                  )}
                </Fragment>
              ))}
            </ContainerStyled>
          </section>
        </ServiceAdvisoryStyled>
      </Layout>
    </>
  );
};

export default ServiceAdvisory;
