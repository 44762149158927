import React from 'react';
import { pick } from 'ramda.pick/pick';
import { connect } from 'react-redux';

const BubleCount = ({ type, socket, notification }) => {
  const { count: _notiCount } = notification;
  const { count: _chatCount } = socket;
  return (
    <>
      {type === 'notification' && _notiCount > 0 && <span>{_notiCount}</span>}
      {type === 'socket' && _chatCount > 0 && <span>{_chatCount}</span>}
    </>
  );
};

const mapStateToProps = pick(['socket', 'notification']);

export default connect(mapStateToProps, {})(BubleCount);
