import update from 'immutability-helper';
import { UPDATE_RELEASE } from '../actions/release';

const release = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_RELEASE:
      return update(state, { $merge: action.release });
    default:
      return state;
  }
};

export default release;
