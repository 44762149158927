import styled from 'styled-components';

export const GuestLoginStyled = styled.div`
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 150px 0;

  h1 {
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
  }

  p {
    color: #ffffff;
    text-align: center;
    font-size: 14px;
  }

  div.form-wrapper {
    margin: 0 auto;
    padding: 10px;
    max-width: 380px;

    @media (max-width: 768px) {
      margin: 0;
    }

    input {
      background: #ffffff;
      border: 1px solid #dadada;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: block;
      margin: auto;
      font-size: 14px;
      padding: 5.5px 10px;
      color: #354f65;
      outline: none;
    }

    .form-row {
      width: 100%;
      display: flex;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .field-wrapper {
      display: flex;
      width: 100%;
      margin: 5px 10px;
      padding: 14px 0 11px;

      @media (max-width: 768px) {
        margin: 0;
      }

      .field {
        display: flex;
        width: 100%;
        position: relative;

        label {
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          bottom: 6.5px;
          transition: 0.3s;
          left: 10px;
          color: #8b959c;
        }

        .ctm-select {
          width: 100%;
          & > div.ctm__control {
            min-height: unset;
            border: 1px solid #dadada !important;
            &.ctm__control--is-focused {
              border: 1px solid #327fbe !important;
            }
            & > div:first-child {
              padding: 3px 10px;

              & > div {
                margin: unset;
                font-size: 14px;
                color: #354f65;
                position: static;
                top: unset;
                transform: none;
              }
            }
            & > div:last-child {
              & > span {
                display: none;
              }

              & > div {
                padding: 3px;
              }
            }
          }

          &.no-val {
            & > div {
              & > div:first-child {
                & > div:not(.ctm__option) {
                  color: #b2b1b1;
                }
              }
            }
          }
        }

        .shared-calendar {
          padding: 0 5px;

          input {
            border: none;
          }
        }

        &.with-value label,
        input:focus + label,
        .phone-input.focus + label,
        .ctm-select.focus + label,
        .shared-calendar.focus + label {
          display: block;
          bottom: 100%;
          left: 0;
          color: #fff;
          font-size: 10px;
        }
      }
    }
  }

  div.action-wrapper {
    display: flex;
    justify-content: center;

    div {
      p.error-text {
        margin-top: 5px;
        color: red;
        font-size: 12px;
        text-align: left;
      }

      button {
        display: block;
        margin: auto;
        background: #ff7019;
        border: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        width: 120px;
        font-size: 18px;
        color: #ffffff;
        margin-top: 25px;
        outline: none;

        &:disabled {
          background: #979797;
        }
      }
    }
  }
`;
