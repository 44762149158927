import { getConvoMessages, getUnreadCount } from '../utils/chat';

export const fetchConvoMessages = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getConvoMessages(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUnreadCount = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getUnreadCount(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
