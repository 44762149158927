import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const getProductwithBrand = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { slug, category } = payload;

    try {
      const response = await axios.get(
        '/api/product/brand/' + slug + '/' + category,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProductBySlug = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { slug } = payload;

    try {
      const response = await axios.get('/api/product/slug/' + slug, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProductById = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { id } = payload;

    try {
      const response = await axios.get('/api/product/pid/' + id, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProductDetailBySlug = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { slug } = payload;

    try {
      const response = await axios.get('/api/product/detail/' + slug, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProductAttributeBySlug = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { slug } = payload;

    try {
      const response = await axios.get(
        '/api/product/attribute/' + slug,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProductAttrEntities = (payload) => {
  const { attribute_id, product_id } = payload;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/product/attribute/entities/${product_id}/${attribute_id}`,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
