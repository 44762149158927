import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

export default class MyContentLoader extends Component {
  render() {
    const Loader = () => {
      return (
        <ContentLoader
          height={30}
          width={320}
          speed={2}
          primaryColor='#f3f3f3'
          secondaryColor='#ecebeb'
          uniquekey='contentloader'>
          <rect x='51' y='13' rx='4' ry='4' width='252' height='3' />
          <circle cx='23' cy='15' r='13' />
        </ContentLoader>
      );
    };
    return (
      <>
        <Loader />
        <Loader />
        <Loader />
        <Loader />
        <Loader />
        <Loader />
      </>
    );
  }
}
