import { isEmpty } from '@ramda/isempty/isEmpty';
import { pick } from 'ramda.pick/pick';
import React, { Fragment, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// style
import { PawnOnlineStyle } from '../../styles/pawn-online.style';
import { testimonials } from './testimonials';

const PawnOnline = ({ release }) => {
  const [testimonialsArr, testimonialsArrHandler] = useState(testimonials);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (window) {
        testimonialsArrHandler(
          testimonials
            .filter((e) => e.item)
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
        );
      }
    }
  }, []);

  return (
    <PawnOnlineStyle className={'mobile-show'}>
      <div className='advantage-block'>
        <div className='advantage-block-header'>
          <h2>Why pawn online?</h2>
        </div>

        <div className='advantage-block-body'>
          <p>
            Traditional pawn shops and 5-6 loan sharks charge extremely high
            interest rates and often have hidden fees.{' '}
            <strong>
              PawnHero, on the other hand, charges interest rates as low as
              3.5%
            </strong>{' '}
            which is even lower than credit card rates. This is possible because
            PawnHero doesn’t maintain expensive physical outlets. PawnHero also
            accepts a wider selection of items other than jewellery. All of
            these without you needing to leave the comfort of your home.
          </p>

          <Link
            to='/pawn'
            id='pawn_online_try_now'
            className='btn-pawn-now btn'>
            Try it now
          </Link>
        </div>
      </div>

      <div className='cash-announcement'>
        <div className='header'>
          <h2>Who’s getting cash right now?</h2>
        </div>
        <div className='body'>
          <>
            {release &&
              Array.isArray(release) &&
              !isEmpty(release) &&
              release.slice(0, 5).map((e, i) => {
                const { amount, items } = e;
                const _itemName = items[0].item;

                return (
                  <Fragment key={i}>
                    <div className='announcement-block'>
                      <p>
                        {_itemName} for ₱{amount}
                      </p>
                    </div>
                  </Fragment>
                );
              })}
          </>
        </div>
      </div>

      <div className='customer-review'>
        <div className='header'>
          <h2>What do our customers say about PawnHero?</h2>
        </div>
        <div className='body'>
          {testimonialsArr.map((e, i) => {
            const ratingImg =
              e.rating === 5
                ? 'https://d1v5w8bodpeh4i.cloudfront.net/assets/rating-5.svg'
                : 'https://d1v5w8bodpeh4i.cloudfront.net/assets/rating-4.svg';
            return (
              <Fragment key={i}>
                <div className='review-block'>
                  <img className='rating-img' src={ratingImg} alt={e.rating} />
                  <p className='title'>{e.title}</p>
                  <p className='review'>{e.comment}</p>
                  <p className='item'>
                    - {e.item} for {e.price}
                  </p>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </PawnOnlineStyle>
  );
};
const mapStateToProps = pick(['release']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PawnOnline);
