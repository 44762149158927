import styled from 'styled-components';

export const ServiceAdvisoryStyled = styled.div`
  section.banner {
    background: radial-gradient(50% 50% at 50% 50%, #354f65 0%, #22394d 100%);
    padding: 50px 0;
  }

  h1 {
    font-family: 'Bebas Neue', cursive;
    font-weight: normal;
    font-size: 96px;
    text-align: center;
    color: #d6ebf5;
  }

  section {
    background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #d6f0f5 100%);

    .box {
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin: 30px auto;
      padding: 20px 50px;
      @media (max-width: 768px) {
        padding: 20px;
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        color: #327fbe;
        margin-bottom: 10px;
      }

      p.sub {
        color: #354f65;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
      }

      p.receiver,
      p.sender {
        color: #354f65;
        font-weight: 600;
        font-size: 14px;
      }

      p.receiver {
        margin-bottom: 20px;
      }

      div.content {
        color: #354f65;
        font-size: 14px;
        margin-bottom: 20px;
      }

      ol,
      ul {
        list-style-position: inside;

        ul,
        ol {
          padding-left: 20px;
          margin-bottom: 10px;
        }
      }

      .btn-faq,
      .btn-readmore {
        border: 0;
        font-size: 18px;
        line-height: 18px;
        background: #68c552;
        border-radius: 15px;
        padding: 7px 10px;
        margin: 10px auto;
        display: block;
        outline: none;
        width: 280px;
      }

      .btn-readmore {
        color: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .btn-faq {
        color: #68c552;
        background: #ffffff;
        border: 1px solid #68c552;
      }
    }
  }
`;
