import React, { useState, useEffect, useRef } from 'react';

// utility
import pick from 'ramda.pick';
import { connect } from 'react-redux';
import isEmpty from '@ramda/isempty';
import isNil from '@ramda/isnil';
import { fetchCategories } from '../../../actions/category';
import { fetchSearch } from '../../../actions/search';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// style
const { HomeSearchStyled } = require('../../styles/search.style');

const HomeSearchBar = (props) => {
  const history = useHistory();
  const { doFetchCategories, doPawnSearch } = props;
  const [categories, categoriesHandler] = useState(null);
  const [options, optionsHandler] = useState(null);
  const [value, valueHandler] = useState(null);
  // eslint-disable-next-line
  const [loading, loadingHandler] = useState(false);
  const [toggleOption, toggleOptionHandler] = useState(false);
  const inputRef = useRef(null);
  let timeout = null;

  const onChangeHandler = (e) => {
    const key = e.target.value;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!isEmpty(key.trim()) && key.trim().length > 2) {
        doSearch(key);
        clearTimeout(timeout);
      } else {
        optionsHandler(null);
        clearTimeout(timeout);
      }
    }, 300);
  };

  const doSearch = (key) => {
    loadingHandler(true);
    doPawnSearch({ key })
      .then((data) => {
        optionsHandler(data);
      })
      .then(() => {
        loadingHandler(false);
      });
  };

  const onCategoryClick = (data) => {
    valueHandler(data.slug);

    const { current } = inputRef;
    current.value = data.category_name;
  };

  const onOptionClick = (data) => {
    toggleOptionHandler(false);
    valueHandler([data.cc_slug, data.cb_slug, data.cp_slug].join('/'));

    const { current } = inputRef;
    current.value = data.product_name;
  };

  const doPawn = (value) => {
    if (value) {
      history.push('/pawn/' + value);
    } else {
      history.push('/pawn/');
    }
  };

  const doToggle = (toggleOption) => {
    toggleOptionHandler(!toggleOption);
  };

  const onMouseDown = (e) => {
    const {
      target: { id },
    } = e;

    if (id !== 'option_toggle_control') {
      if (id === 'home_option_input') {
        toggleOptionHandler(true);
      } else {
        setTimeout(() => {
          toggleOptionHandler(false);
        }, 300);
      }
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      doFetchCategories().then((data) => {
        categoriesHandler(data);
        // window.addEventListener('mousedown', onMouseDown);
      });
    }
    return function cleanup() {
      // console.log('remove call');
      // window.removeEventListener('mousedown', onMouseDown);
      isCancelled = true;
    };
  }, [doFetchCategories]);

  return (
    <HomeSearchStyled>
      <div onMouseDown={onMouseDown}>
        <LazyLoadImage
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/vector-dropdown.svg'
          alt='down-arrow'
          id='option_toggle_control'
          onClick={() => doToggle(toggleOption)}
        />
        <input
          id='home_option_input'
          ref={inputRef}
          className={toggleOption ? 'toggle' : ''}
          onChange={onChangeHandler}
          placeholder='Search or select your item'
          autoComplete='off'
        />

        <div className='option-wrapper-container'>
          <div className='option-wrapper' id='option_wrapper'>
            {!isNil(categories) && !isEmpty(categories) && isNil(options) && (
              <>
                {categories.map((category, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => onCategoryClick(category)}
                      className='option-val-wrapper'
                      id={`option_category_${category.slug}`}>
                      <div>
                        {!isNil(category.image) && !isEmpty(category.image) ? (
                          <div className='opt-image-wrapper'>
                            <LazyLoadImage src={category.image} alt='' />
                          </div>
                        ) : (
                          <div className='opt-image-wrapper'>
                            <LazyLoadImage
                              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/logo-filler.svg'
                              alt=''
                            />
                          </div>
                        )}
                        <p>{category.category_name}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {!isNil(options) && !isEmpty(options) && (
              <>
                {options.map((option, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => onOptionClick(option)}
                      className='option-val-wrapper'
                      id={`option_product_${option.product_id}`}>
                      <div>
                        {!isNil(option.image) && !isEmpty(option.image) ? (
                          <div className='opt-image-wrapper'>
                            {/* false eror here */}
                            <LazyLoadImage src={option.image} alt='' />
                          </div>
                        ) : (
                          <div className='opt-image-wrapper'>
                            <LazyLoadImage
                              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/logo-filler.svg'
                              alt=''
                            />
                          </div>
                        )}
                        <p>{option.product_name}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <button onClick={() => doPawn(value)} id='home_search_dropdown_btn'>
        Pawn now
      </button>
    </HomeSearchStyled>
  );
};

const mapStateToProps = pick(['']);
const mapDispatchToProps = (dispatch) => ({
  doFetchCategories: () => dispatch(fetchCategories()),
  doPawnSearch: (key) => dispatch(fetchSearch(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchBar);
