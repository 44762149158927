import {
  postLogin,
  postSignUp,
  postGuestLogin,
  postLogout,
  postForgot,
  postReset,
  postActivate,
  postChangeCredentials,
} from '../utils/auth';
import { getCurrentUser } from '../utils/user';
import { login } from './user';

const secret = process.env.REACT_APP_USER_SECRET;
const encryptor = require('simple-encryptor')({ key: `${secret}` });

export const makeLogin = (user) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postLogin(user)
      .then(async ({ user: data }) => {
        const _currenUser = await getCurrentUser();
        const _decUser = encryptor.decrypt(_currenUser);
        dispatch(login(_decUser));
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeLoginGuest = (user) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postGuestLogin(user)
      .then(({ user: data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeSignUp = (user) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postSignUp(user)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeLogOut = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    postLogout()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeForgot = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postForgot(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeReset = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postReset(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeActivate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postActivate(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeChangeCredentials = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postChangeCredentials(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
