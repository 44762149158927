import React from 'react';
import { DiscountModalStyled } from '../../styles/home.style';
import { Link } from 'react-router-dom';

const DiscountModal = ({ toggle, close }) => {
  return (
    <>
      <DiscountModalStyled toggle={toggle}>
        <div>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/close-mark-blue-ico.svg'
            className='btn-close'
            onClick={() => {
              close();
            }}
            alt=''
          />
          <h3>Promo Mechanics</h3>
          <p>
            1. For the customer to be eligible for the promo, he/she must apply
            for a loan ticket within the promo duration February 25 to March 31,
            2020.
          </p>
          <p>2. Promo valid for first time PawnHero pawners only.</p>
          <p>
            3. First time PawnHero pawners with successful loan release
            transactions from February 25 to March 31, 2020 will be given 50%
            off their interest rate for the first month only.
          </p>
          <p>
            4. Customers with loan value amount of Php 15,000 and above for gold
            and jewelries and Php 23,000 and above for luxury bags, watches and
            electronics are the only ones eligible for this promo discount
          </p>
          <Link to='/pawn' id='home_discount_pawn_link'>
            Pawn now
          </Link>
        </div>
      </DiscountModalStyled>
    </>
  );
};

export default DiscountModal;
