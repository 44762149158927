import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// child component
import Layout from '../layout';

// utility
import { connect } from 'react-redux';
import { makePaynamicParamsRenewal } from '../../../actions/payment';
import capitalize from 'lodash.capitalize';
import qs from 'qs';

const { ContainerStyled } = require('../../styles/layout.style');
const { PawnThankyouStyledV2 } = require('../../styles/pawn-thankyou.style');

const PaynamicThankyou = ({ doMakePaynamicParams }) => {
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const request_id = params.get('requestid');
  const response_id = params.get('responseid');

  // states
  const [isLoading, isLoadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);
  const [customer, customerHandler] = useState({ first_name: 'Guest' });
  const [isError, isErrorHandler] = useState(false);

  useEffect(() => {
    isErrorHandler(false);
    if (request_id && response_id) {
      isLoadingHandler(true);
      const _payload = {
        request_id,
        response_id,
      };

      doMakePaynamicParams(_payload)
        .then((response) => {
          const {
            data: { data: _ticketData, success },
          } = response;

          if (success) {
            ticketDataHandler(_ticketData);
            customerHandler(_ticketData.user);
            isLoadingHandler(false);
          } else {
            isLoadingHandler(false);
            var paramString = qs.stringify({
              requestid: request_id,
              responseid: response_id,
            });
            history.push(`/pawn/redeem/failed?${paramString}`);
          }
        })
        .catch((err) => {
          console.log('https error here');
          isLoadingHandler(false);
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        });
    } else {
      // no url params
      history.push('/guest');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <PawnThankyouStyledV2>
        {isError ? (
          <p className='error'>{isError}</p>
        ) : (
          <>
            {isLoading ? (
              <>
                <div style={{ padding: '50px 0', width: '100%' }}>
                  <img
                    className='loader'
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
                    alt='loading'
                    style={{
                      width: '32px',
                      height: '32px',
                      margin: 'auto',
                      display: 'flex',
                    }}
                  />
                </div>
              </>
            ) : (
              <section className='banner'>
                <ContainerStyled>
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/pawn-thankyou.svg'
                    alt='thank you'
                  />
                  <h2>
                    Thank you,{' '}
                    {capitalize(customer.first_name || customer.firstName)}!
                  </h2>
                  <h3>Your loan repayment is being processed.</h3>
                  <p className='highnotes'>
                    Your ticket number is{' '}
                    <span>
                      {ticketData && ticketData.ticket_no
                        ? ticketData.ticket_no
                        : ''}
                    </span>
                    .
                  </p>

                  <p className='notes'>
                    This is your official transaction number. For inquiries
                    about your payment, you can contact our customer service
                    representatives between 9:00 AM to 7:00 PM.
                  </p>

                  <div className='cta-wrapper'>
                    <Link to='/' className='btn'>
                      Back to Home
                    </Link>
                  </div>
                </ContainerStyled>
              </section>
            )}
          </>
        )}
      </PawnThankyouStyledV2>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakePaynamicParams: (payload) =>
    dispatch(makePaynamicParamsRenewal(payload)),
});

export default connect(null, mapDispatchToProps)(PaynamicThankyou);
