import { handleSuccess, handleError } from './globals';
const axios = require('axios');
const config = require('./axios.config').config;

export const postPaymentString = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('/api/payment/string', payload, config);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRenewalPaymentString = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        '/api/payment/renewal/string',
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRedeem = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        '/api/ticket/make/redeem',
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRenewalPayment = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        '/api/ticket/make/renewal/payment',
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postPaynamicParams = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        '/api/payment/paynamic/params',
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postPaynamicParamsRenewal = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        '/api/payment/renewal/paynamic/params',
        payload,
        config
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
