import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerStyled } from '../../styles/shared.style';

export const EasyDatePicker = (props) => {
  const { selectedDate, onChange, placeholder, id } = props;

  // states
  const [focus, focusHandler] = useState(false);

  return (
    <DatePickerStyled className={`shared-calendar ${focus ? 'focus' : ''}`}>
      <DatePicker
        id={id}
        calendarClassName='ctm-date-picker'
        selected={selectedDate}
        onChange={onChange}
        placeholderText={placeholder}
        onFocus={() => focusHandler(true)}
        onBlur={() => focusHandler(false)}
      />
      <img
        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/datepicker-ico.svg'
        alt='datepicker'
      />
    </DatePickerStyled>
  );
};
