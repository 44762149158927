import React, { Fragment, useState } from 'react';
import Layout from '../layout';

// styles
import { ContainerStyled } from '../../styles/layout.style';
import { PressStyled } from '../../styles/press.style';
import { pressData } from './pressData';
import { coverageData } from './coverageData';
import { Link } from 'react-router-dom';

const Press = (props) => {
  const step = 6;
  const [pressToggle, pressToggleHandler] = useState(6);
  const [coverageToggle, coverageToggleHandler] = useState(6);

  const loadMorePress = (pressToggle) => {
    const dataSize = pressData.length;
    const nextLimit = pressToggle + step;
    const _limit = dataSize < nextLimit ? dataSize : nextLimit;
    pressToggleHandler(_limit);
  };

  const loadMoreCoverage = (coverageToggle) => {
    const dataSize = coverageData.length;
    const nextLimit = coverageToggle + step;
    const _limit = dataSize < nextLimit ? dataSize : nextLimit;

    coverageToggleHandler(_limit);
  };

  return (
    <>
      <Layout>
        <PressStyled>
          <section>
            <ContainerStyled style={{ overflow: 'unset' }}>
              <h1>Latest Press Releases</h1>

              <div className='card-wrapper'>
                {pressData.slice(0, pressToggle).map((e, i) => {
                  return (
                    <Fragment key={i}>
                      <div className='card'>
                        <h3>{e.title}</h3>
                        <p>
                          {e.content}{' '}
                          {e.link && (
                            <a
                              href={e.link}
                              target='_blank'
                              rel='noopener noreferrer'>
                              Continue reading
                            </a>
                          )}
                          {e.localLink && (
                            <Link to={e.localLink}>Continue reading</Link>
                          )}
                        </p>
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              {pressToggle < pressData.length && (
                <button
                  className='btn load-more'
                  onClick={() => {
                    loadMorePress(pressToggle);
                  }}>
                  Load more stories
                </button>
              )}
            </ContainerStyled>
          </section>
          <section>
            <ContainerStyled>
              <h2>Coverages</h2>
              <div className='card-wrapper'>
                {coverageData.slice(0, coverageToggle).map((e, i) => {
                  return (
                    <Fragment key={i}>
                      <div className='card'>
                        <h3>{e.title}</h3>
                        <p>
                          {e.content}{' '}
                          {e.link && (
                            <a
                              href={e.link}
                              target='_blank'
                              rel='noopener noreferrer'>
                              Continue reading
                            </a>
                          )}
                        </p>
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              {coverageToggle < coverageData.length && (
                <button
                  className='btn load-more'
                  onClick={() => {
                    loadMoreCoverage(coverageToggle);
                  }}>
                  Load more articles
                </button>
              )}
            </ContainerStyled>
          </section>
        </PressStyled>
      </Layout>
    </>
  );
};

export default Press;
