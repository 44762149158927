import React from 'react';

//utils
import { connect } from 'react-redux';
import { pick } from 'ramda.pick/pick';

// child
import { menus } from '../../components/dashboard/layout';
import AccountForm from '../../components/dashboard/accountForm';

// styles
const { ContentStyled } = require('../../styles/dashboard.style');

const AccountPage = ({ dashboard }) => {
  const { activeMenu } = dashboard;

  return (
    <ContentStyled>
      <div className='content-header'>
        <img src={menus[activeMenu] ? menus[activeMenu].image : ''} alt='' />
        <h1>{menus[activeMenu] ? menus[activeMenu].text : ''}</h1>
      </div>
      <AccountForm />
    </ContentStyled>
  );
};

const mapStateToProps = pick(['dashboard']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
