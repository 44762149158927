import React, { useState, useEffect, useRef } from 'react';

//utility
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { updateRedeem } from '../../../actions/redeem';
import { makePaymentString, makeRedeem } from '../../../actions/payment';
import { useHistory, useParams } from 'react-router-dom';
import { timezone } from '../../../server/config/moment';
import momentTZ from 'moment-timezone';

// styles
import { CashOutStyled } from '../../styles/acceptance.style';

// components
import { EBanking } from '../paymentMethods';
import ReactModal from '../../components/shared/reactModal';

const Payment = (props) => {
  const {
    doUpdateRedeem,
    redeem,
    animationDoneHandler,
    doMakePaymentString,
    doMakeRedeem,
  } = props;

  const { ticketNum } = useParams();
  const history = useHistory();
  const formRef = useRef(null);
  const {
    data: { cashout: cashoutVal = {}, booking: bookingVal = {} },
    data,
    slideLeft = false,
  } = redeem;
  const { cashOutDetails: cashOutDetailVal = {}, type = 'direct_payment' } =
    cashoutVal;

  const [cashOutSelect, cashOutSelectHandler] = useState(type);
  const [cashOutDetails, cashOutDetailsHandler] = useState(cashOutDetailVal);
  const [paynamicString, paynamicStringHandler] = useState('');
  const [paynamicUrl, paynamicUrlHandler] = useState('');
  const [paynamicError, paynamicErrorHandler] = useState(false);
  const [isGettingPaynamicData, isGettingPaynamicDataHandler] = useState(false);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [modePayment, modePaymentHandler] = useState(false); //false -> offline , true -> online
  const [toggle, toggleHandler] = useState(false);
  const [errMsg, errMsgHandler] = useState();

  const cashOptions = [
    {
      text: 'Direct Payment',
      textLabel: 'PawnHero Direct Payment',
      value: 'direct_payment',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/direct-pawnhero-ico.svg',
      isOnline: false,
      cmsVal: 'cash',
      thankCallback: `/pawn/redeem/direct_success/`,
      note: 'You can either repay your loan in cash at PawnHero office, or deposit the payment directly to our bank accounts below:',
      noteComponent: () => {
        return (
          <>
            <p className='note-description-extra'>
              <span>Pawnhero Pawnshop Philippines, Inc.</span>
              Security Bank - 00000 1682 7011 <br />
              BDO – 0071 000 58845
            </p>
          </>
        );
      },
    },
    {
      text: 'Security Bank',
      value: 'security_bank',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/securitybank-ico.svg',
      isOnline: false,
      cmsVal: 'security-bank',
      thankCallback: `/pawn/redeem/sb_success/`,
      note: 'You can repay your loan via Security Bank’s online payment portal.',
    },
    {
      text: '7-Eleven',
      value: '7eleven',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/7eleven-ico.svg',
      isOnline: true,
      cmsVal: '7-11',
      note: 'You can repay your loan via 7-Eleven’s online payment portal.',
    },
    {
      text: 'Coins.ph',
      value: 'coins',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/coins-ico.svg',
      isOnline: false,
      cmsVal: 'coins',
      thankCallback: '/pawn/redeem/coins_success',
      note: 'You can repay your loan via Coins.ph bills payment.',
    },
    {
      text: 'GCash',
      value: 'GC',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/gcash-ico.svg',
      isOnline: true,
      cmsVal: 'g-cash',
      note: 'You can repay your loan via GCash’s online payment portal.',
    },
    {
      text: 'DragonPay',
      value: 'DP',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/dragonpay-ico.svg',
      isOnline: true,
      cmsVal: 'over-the-counter',
      note: 'You can repay your loan via Dragonpay’s online payment portal.',
    },
    {
      text: 'E-Banking',
      value: 'ebanking',
      img: 'https://d1v5w8bodpeh4i.cloudfront.net/assets/ebanking-ico.svg',
      isOnline: true,
      withChannel: true,
      cmsVal: 'bancnet',
      note: 'You can repay your loan via our available electronic banking options below.',
      fields: <EBanking onChange={(data) => cashOutDetailsHandler(data)} />,
    },
  ];

  const prev = () => {
    doUpdateRedeem({
      ...redeem,
      position: 1,
    });
  };

  useEffect(() => {
    // animation
    animationDoneHandler(false);
    setTimeout(() => {
      animationDoneHandler(true);
    }, 300);
  }, [animationDoneHandler]);

  useEffect(() => {
    doUpdateRedeem({ data: { offer: data.offer, booking: data.booking } });

    return () => {
      toggleHandler(false);
    };
    // eslint-disable-next-line
  }, []);

  const generateString = () => {
    isGettingPaynamicDataHandler(true);
    paynamicErrorHandler(false);
    const selectOption = cashOptions.find((e) => e.value === cashOutSelect);

    let _payload = { ticket_no: ticketNum };
    if (selectOption.withChannel) {
      _payload.type = cashOutDetails.channel.value;
    } else {
      _payload.type = selectOption.value;
    }

    const { mode, selectedAddress } = bookingVal;

    if (mode === 'delivery') {
      _payload.service = 'deliver';
      _payload.address = {
        address1: selectedAddress.street,
        barangay: selectedAddress.barangay.name,
        city: selectedAddress.city.name,
        province: selectedAddress.province.name,
      };
    } else {
      _payload.service = 'pickup';
      _payload.address = null;
    }

    doMakePaymentString(_payload)
      .then(({ data }) => {
        const { success } = data;

        if (success) {
          const {
            data: { payment_request, url },
          } = data;
          paynamicUrlHandler(url);
          paynamicStringHandler(payment_request);
        } else {
          console.log('err making string', data);
          paynamicErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        isGettingPaynamicDataHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        paynamicErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        isGettingPaynamicDataHandler(false);
      });
  };

  const validateForm = () => {
    errMsgHandler('');
    const selectOption = cashOptions.find((e) => e.value === cashOutSelect);

    if (selectOption.isOnline) {
      if (selectOption.withChannel) {
        const { channel = false } = cashOutDetails;
        if (channel) {
          return { paynamics: true, valid: true };
        } else {
          errMsgHandler('Please select a Payment Channel');
          return { paynamics: true, valid: false };
        }
      } else {
        return { paynamics: true, valid: true };
      }
    } else {
      return { paynamics: false, valid: true };
    }
  };

  const isBtnCtaDisabled = () => {
    const selectOption = cashOptions.find((e) => e.value === cashOutSelect);

    if (selectOption.isOnline) {
      if (selectOption.withChannel) {
        const { channel = false } = cashOutDetails;
        if (channel) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const cmsAction = () => {
    return new Promise((resolve, reject) => {
      isProcessingHandler(true);

      const selectOption = cashOptions.find((e) => e.value === cashOutSelect);

      const {
        data: { offer, booking },
      } = redeem;

      let _payload = {
        ticket_no: ticketNum,
        redeem: {
          amount: offer.raw.amount_to_be_paid,
          address: booking.selectedAddress
            ? booking.selectedAddress.street || null
            : null,
          barangay: booking.selectedAddress
            ? booking.selectedAddress.barangay.barangay_id || null
            : null,
          city: booking.selectedAddress
            ? booking.selectedAddress.city.city_id || null
            : null,
          province: booking.selectedAddress
            ? booking.selectedAddress.province.province_id || null
            : null,
          date: booking.date || null,
          type: booking.mode === 'pickup' ? 'pick-up' : 'delivery',
          payment_method: selectOption.cmsVal,
        },
      };

      doMakeRedeem(_payload)
        .then(({ data }) => {
          const { success } = data;
          isProcessingHandler(false);
          resolve({ success });
        })
        .catch((err) => {
          console.log('err', err);
          isProcessingHandler(false);
          paynamicErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
          resolve({ success: false });
        });
    });
  };

  useEffect(() => {
    cashOutDetailsHandler({});
    // eslint-disable-next-line
  }, [cashOutSelect]);

  return (
    <>
      <CashOutStyled slideLeft={slideLeft} className='payment'>
        <div className='cashout-content-wrapper'>
          <h2>How do you want to repay your loan?</h2>

          <div className='cashout-form-wrapper'>
            <div className='form-header'>
              <div onClick={prev}>
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/right-arrow-icon.svg'
                  alt='previous'
                />
                <p>Back to Item Return</p>
              </div>
            </div>
            <div className='form-body'>
              <div className='cashout-option-wrapper'>
                {cashOptions.map((option, key) => {
                  return (
                    <button
                      key={key}
                      className={`${
                        cashOutSelect === option.value ? 'selected' : ''
                      }`}
                      onClick={() => {
                        errMsgHandler(null);
                        cashOutSelectHandler(option.value);
                      }}>
                      <img src={option.img} alt={option.text} />
                      <p>{option.text}</p>
                    </button>
                  );
                })}
              </div>
              <div className='note-wrapper'>
                <p className='note'>
                  {cashOptions.find((option) => option.value === cashOutSelect)
                    .textLabel ||
                    cashOptions.find((option) => option.value === cashOutSelect)
                      .text}
                </p>
                <p className='note-description'>
                  {
                    cashOptions.find((option) => option.value === cashOutSelect)
                      .note
                  }
                </p>
                {cashOptions.find((option) => option.value === cashOutSelect)
                  .noteComponent
                  ? cashOptions
                      .find((option) => option.value === cashOutSelect)
                      .noteComponent()
                  : ''}
                {
                  cashOptions.find((option) => option.value === cashOutSelect)
                    .fields
                }
              </div>
              {errMsg && <p className='errMsg error'>{errMsg}</p>}
              <button
                className='btn-next orange'
                onClick={() => {
                  const { paynamics, valid } = validateForm();

                  if (valid) {
                    if (paynamics) {
                      generateString();
                      modePaymentHandler(true);
                    } else {
                      modePaymentHandler(false);
                    }
                    toggleHandler(true);
                  }
                }}
                disabled={isBtnCtaDisabled()}>
                Pay my loan now
              </button>
            </div>
          </div>
        </div>
      </CashOutStyled>
      <ReactModal
        toggle={toggle}
        toggleHandler={toggleHandler}
        contentContainerStyle={{
          background: '#327FBE',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          maxWidth: '554px',
          width: '100%',
          minHeight: '40vh',
          padding: '15px',
        }}>
        <>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/redeem-payment-modal-ico.svg'
            alt='confirm payment'
            style={{
              width: '120px',
              display: 'block',
              margin: 'auto',
            }}
          />
          <p
            style={{
              fontWeight: '600',
              fontSize: '18px',
              color: '#fff',
              textAlign: 'center',
              marginTop: '20px',
            }}>
            You have selected{' '}
            {cashOptions.find((option) => option.value === cashOutSelect).text}{' '}
            for loan repayment
          </p>
          <p
            style={{
              fontWeight: 'normal',
              fontSize: '14px',
              color: '#fff',
              textAlign: 'center',
              marginTop: '10px',
            }}>
            You can press the button below to proceed on repaying your loan with
            the selected repayment method. If you’re not sure, you can still opt
            to go back and review the other repayment methods available for you.
          </p>

          {paynamicError ? (
            <>
              <p
                style={{
                  color: 'red',
                  textAlign: 'center',
                  fontSize: '14px',
                  marginTop: '20px',
                }}>
                {paynamicError}
              </p>
            </>
          ) : (
            <>
              {modePayment ? (
                <>
                  {isGettingPaynamicData ? (
                    <>
                      <p
                        style={{
                          textAlign: 'center',
                          color: '#fff',
                          marginTop: '20px',
                        }}>
                        loading...
                      </p>
                    </>
                  ) : (
                    <>
                      <form action={paynamicUrl} method='post' ref={formRef}>
                        <input
                          type='hidden'
                          name='paymentrequest'
                          id='paymentrequest'
                          value={paynamicString}
                          style={{ display: 'none' }}
                        />
                        <button
                          type='submit'
                          style={{
                            fontWeight: 'normal',
                            fontSize: '18px',
                            lineHeight: '18px',
                            border: '0',
                            background: '#FF7019',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '15px',
                            color: `${isProcessing ? '#be5515' : '#fff'}`,
                            padding: '7px 20px',
                            margin: '20px auto',
                            display: 'block',
                            outline: 'none',
                            cursor: 'pointer',
                          }}
                          disabled={isProcessing}
                          onClick={async (e) => {
                            e.preventDefault();
                            paynamicErrorHandler(false);
                            const { success } = await cmsAction();

                            if (success) {
                              formRef.current.submit();
                            } else {
                              paynamicErrorHandler(
                                'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                              );
                            }
                          }}>
                          Pay my loan now
                        </button>
                      </form>
                    </>
                  )}
                </>
              ) : (
                <button
                  style={{
                    fontWeight: 'normal',
                    fontSize: '18px',
                    lineHeight: '18px',
                    border: '0',
                    background: '#FF7019',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '15px',
                    color: '#fff',
                    padding: '7px 20px',
                    margin: '20px auto',
                    display: 'block',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={async (e) => {
                    e.preventDefault();
                    paynamicErrorHandler(false);

                    const { success } = await cmsAction();

                    if (success) {
                      history.push(
                        `${
                          cashOptions.find(
                            (option) => option.value === cashOutSelect
                          ).thankCallback
                        }?ticketno=${ticketNum}`,
                        {
                          thankyouDateExpiry: momentTZ()
                            .add(30, 'm')
                            .tz(timezone)
                            .unix(),
                        }
                      );
                    } else {
                      paynamicErrorHandler(
                        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
                      );
                    }
                  }}>
                  Pay my loan now
                </button>
              )}
            </>
          )}

          <p
            onClick={(e) => {
              toggleHandler(false);
            }}
            style={{
              marginTop: '15px',
              fontWeight: 'normal',
              fontSize: '18px',
              color: '#fff',
              textAlign: 'center',
              cursor: 'pointer',
            }}>
            Go back to repayment methods
          </p>
        </>
      </ReactModal>
    </>
  );
};

const mapStateToProps = pick(['redeem']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateRedeem: (redeem) => dispatch(updateRedeem(redeem)),
  doMakePaymentString: (payload) => dispatch(makePaymentString(payload)),
  doMakeRedeem: (payload) => dispatch(makeRedeem(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
