import styled from 'styled-components';

export const PromoContainerStyle = styled.div`
    padding: 30px 0;
    &.background-img {
        background-image: ${props => props.bgImage ? 'url('+props.bgImage+')' : 'none' };
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        -o-background-size: contain, cover;
            -moz-background-size: contain, cover;
                -webkit-background-size: contain, cover;
        height: auto;
        min-height: 500px;
        width: 100%;
    } 
    .content {
        & .promo-img-container {
            margin: 0 auto;
            max-width: 640px;
            > img {
                width: 100%;
            }
        }
        & .promo-details-container {
            display: flex;
            justify-content: center;
            &.space-container {
                margin: 30px 0 0;
            }
            & .detail-item.center {
                padding: 0 30px;
                @media (max-width: 560px) { 
                    padding: 0 15px;
                }
                & .terms {
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 30px 0 10px 0;
                    text-align: center;
                    &.top-b {
                        font-weight: 400;
                        padding: 15px 0;
                    }
                    &.top-m {
                        padding: 0;
                        font-size: 24px;
                        font-weight: 800;
                        width: 240px;
                        margin: 0 auto;
                    }
                }   
                & .permit {
                    color: #FFFFFF;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    text-align: center; 
                }
            }
            & .detail-item.edge {
                align-self: center;
            }
            & .detail-item.end {
                align-self: end !important;
            }
            @media (max-width: 981px) { 
                & .detail-item.edge {
                    display: none;
                }
            }
            & .learn-more {
                text-align: center;
                &.space { padding: 15px 0 10px 0 }
                & .learn-more-btn {
                    background: #FFF;
                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                    -webkit-border-radius: 25px;
                        -moz-border-radius: 25px;
                        border-radius: 25px;    
                    color: #354F65;
                    font-size: 18px;
                    font-weight: 400;
                    padding: 5px 30px;
                    &.green {
                        background: #68C552;
                        color: #FFF;
                    }
                }
            }
            & .bsp-pioneer {
                margin: 0 15px
            }
        }
    }
`;

export const PromoLandingStyle = styled.div`
    color: #FFFFFF;
    padding: 30px 0;
    & .promo-img-container {
        margin: 10px auto;
        max-width: 640px;
        > img {
            width: 100%;
        }
    }  
    & .promo-headline {
        margin-top: 30px;
        text-align: center;
        & .promo-headline-description {
            margin: 30px auto;
            width: 50%;
            @media (max-width: 981px) { 
                width: 100%;
            }
            > h5 { font-weight: 600; }
        }
    }
    & .promo-computation {
        & .promo-computation-title {
            text-align: center;
            > h3 { font-weight: 600; }
        }
        & .promo-computation-box-container {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            max-width: 615px;
            @media (max-width: 981px) { 
                padding: 0 15px;
            }
            margin: 30px auto;
        }
        & .promo-computation-disclaimer > p {
            font-size: 14px;
            font-style: italic;
            text-align: center;
        }
    }
    & .promo-terms {
        margin: 0 auto;
        width: 50%;
        padding: 30px 0;
        &.contain {
            width: 90%;
        }
        @media (max-width: 981px) { 
            width: 100%;
            padding: 30px 15px;
            &.contain {
                width: 100%;
            }
        }
        &.full-width {
            padding: 15px;
            width: 100%;
        }
        & .promo-terms-head {
            font-size: 14px;
            font-weight: 600;
            &.left {
                margin-left: -15px;
            }
            &.center {
                text-align: center;
            }
            &.bottom {
                margin-bottom: 15px;;
            }
            &.large {
                font-size: 24px;
            }
        }
        & .promo-terms-item {       
            &.top-space {
                padding: 15px 0;
            }
            > ul > li {
                text-align: justify;
                font-size: 14px;
                &.italic {
                    font-style: italic;
                }
                &.bottom {
                    margin-bottom: 15px;;
                }
            }
            > p { 
                font-size: 14px;
                text-align: center;
                &.left {
                    text-align: left;
                }
            }
            > ul.list-decimal { list-style: decimal; } 
            > ul.list-decimal.pad-left { padding-left: 15px; } 
        }
    }

    & .promo-terms-title {
        > h3 {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }
        > h1 {
            color: #D8C896;
            font-family: 'Playfair Display';
            font-size: 32px;
            text-align: center;
        }
    }

    .button-container {
        text-align: center;
        padding: 30px;
        & .button {
            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
            border-radius: 25px;
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            padding: 5px 30px;
            text-align: center;
            border: 0;
            outline: none !important;
            &.orange {
                background: #ff7019;
                color: #ffffff;
            }
            &.green {
                background: #68C552;
                color: #ffffff;
            }
        }
    }
    
    & .promo-details-container {
        display: flex;
        justify-content: center;
        &.space-container {
            margin: 30px 0 0;
        }
        & .bsp-pioneer {
            margin: 0 30px;
            width: 100%;
            & > img {
                max-width: 250px;
            }
            &.right {
                text-align: right;
            }
            &.left {
                text-align: left;
            }
        }
    }

    & .learn-more-btn-pawning {
        color: #FFFFFF;
        margin-top: 30px;
        text-decoration: underline !important;
    }

    & .promo-dark-background {
        background: #231113;
        -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;    
        margin: 0 auto;
        padding: 30px 0;
        width: 70%;
        @media (max-width: 981px) { 
            padding: 30px 25px;
            width: 100%;
        }
    }

    

`;