import React from 'react';
import { connect } from 'react-redux';
import pick from 'ramda.pick';
import { RadioStyled } from '../../styles/field-radio.style';
import { updatePawn } from '../../../actions/pawn';
import { isNil } from '@ramda/isnil/isNil';

const Radio = ({ pawn, entities, code, doUpdatePawn }) => {
  const { formValues, attrStep, selectedEntities = {} } = pawn;
  const onChange = (e) => {
    const dom = e.target;
    const { name, value } = dom;
    const _formValues = {
      ...formValues,
      [name]: parseInt(value),
    };

    const _selectedEntities = entities.find(
      (e) => e.attribute_entity_id === parseInt(value)
    );

    doUpdatePawn({
      formValues: _formValues,
      selectedEntities: { ...selectedEntities, [code]: _selectedEntities },
    });
  };

  const dom = entities.map((entry, key) => {
    return (
      <RadioStyled key={key}>
        <input
          id={entry.attribute_entity_id}
          type='radio'
          value={entry.attribute_entity_id}
          name={code}
          onChange={onChange}
          onClick={() => {
            const _attrStep = attrStep + 1;
            doUpdatePawn({ attrStep: _attrStep });
          }}
          checked={
            !isNil(formValues) && formValues[code] === entry.attribute_entity_id
              ? true
              : false
          }
        />
        <label htmlFor={entry.attribute_entity_id}>
          <div className='entry-icon-wrapper'>
            <p>
              {entry.attribute_entity_name
                .replace(/[^a-zA-Z0-9- ]/g, '')
                .match(/\b\w/g)
                .join('')
                .substring(0, 2)
                .toUpperCase()}
            </p>
          </div>
          <div className='entry-label-wrapper'>
            <p>{entry.attribute_entity_name}</p>
            {entry.attribute_entity_description && (
              <p
                className='note'
                dangerouslySetInnerHTML={{
                  __html: entry.attribute_entity_description,
                }}
              />
            )}
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-blue-ico.svg'
              alt='>'
            />
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/left-arrow-white-ico.svg'
              alt='>'
            />
          </div>
        </label>
      </RadioStyled>
    );
  });

  return dom;
};

const mapStateToProps = pick(['pawn']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePawn: (pawn) => dispatch(updatePawn(pawn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radio);
