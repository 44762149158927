import React from 'react';
import { SharedModalStyled } from '../../styles/shared.style';

const SharedModal = (props) => {
  const { children, background, isOpen, handleClose } = props;
  return (
    <SharedModalStyled background={background} isOpen={isOpen}>
      <div className='modal-content-wrapper'>
        <img
          className='modal-close'
          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/otp-modal-close-icon.svg'
          alt='close'
          onClick={handleClose}
        />
        {children}
      </div>
    </SharedModalStyled>
  );
};

export default SharedModal;
