import React from 'react';

// components
import Layout from '../layout';
import WhyPawnhero from '../../components/whyPawnhero';

// style
import { ContainerStyled } from '../../styles/layout.style';

import { Link } from 'react-router-dom';
import { PolicyStyled } from '../../styles/policy.style';

const PawnhHeroEchelon = () => {
  return (
    <>
      <Layout>
        <h1 className='d-none'>PawnHero at the Echelon Summit 2015</h1>
        <PolicyStyled>
          <ContainerStyled>
            <div className='breadcrum'>
              <p>
                <Link to='/'>Home</Link> •{' '}
                <span>PawnHero snags Judges’ Choice, winning Echelon 2015</span>
              </p>
            </div>
            <div className='content-wrapper'>
              <div className='content'>
                <section>
                  <h2>PawnHero snags Judges’ Choice, winning Echelon 2015</h2>

                  <p>
                    In a regional competition for most promising startup in
                    Asia, fintech startup{' '}
                    <a href='http://pawnhero.ph/' id='press-echelon-article'>
                      <u>PawnHero</u>
                    </a>{' '}
                    emerged as the champion, being the first Philippine startup
                    to take the prestigious award home for the country.
                  </p>
                  <p>
                    Held in Singapore, the{' '}
                    <a
                      href='http://e27.co/echelon/asia/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <u>Echelon Asia Summit</u>
                    </a>{' '}
                    annually invites a wide network of startup founders,
                    investors, and company delegates who seek to benefit from
                    the workshops, partnerships, and exhibitions present in the
                    conference.
                  </p>
                  <p>
                    Organized by startup and technology website,{' '}
                    <a
                      href='http://e27.co'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <u>e27</u>
                    </a>
                    , the Echelon Asia Summit also seeks out top 100 startups
                    around Asia to have the opportunity to present their product
                    in their unique exhibit.
                  </p>
                  <p>
                    From the 100 startups, startups were required to pass
                    qualifier rounds to be part of the Top 17. Afterwards, these
                    startups competed for the Top 10, ending in a final pitch on
                    the Echelon main stage.
                  </p>
                  <p>
                    “According to a McKinsey report, half the world is
                    unbanked,” began David Margendorff, founder of PawnHero.
                    “That means 2.5 billion people in the world are not able to
                    make important investments in healthcare and education.”
                  </p>
                  <p>
                    PawnHero, Margendorff explains, is an online pawnshop that
                    will provide customers access to affordable loans in quick
                    and hassle-free manner. Customers just only need to post
                    pictures of their item, accept the loan estimate, and have
                    the delivery and pickup of the item taken care of by the
                    startup.
                  </p>
                  <p>
                    Because financial inclusion is important to PawnHero,
                    customers will receive their loan through a PawnHero debit
                    card, which will allow them to withdraw cash from the ATMs
                    and pay their bills without undergoing the hassle of setting
                    up a bank account.
                  </p>
                  <p>
                    To date, Margendorff concludes, PawnHero has received
                    tremendous traction from the Philippines, having given over
                    8.6 million pesos worth of loans to Filipinos. PawnHero has
                    also started expanding in the region, soon launching in
                    Vietnam.
                  </p>
                  <p>
                    Aside from being handed the Judges’ Choice award, PawnHero
                    also received the TechVenture award from the{' '}
                    <a
                      href='http://www.nrf.gov.sg/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <u>National Research Foundation</u>
                    </a>
                    , a department of the Prime Minister’s Office in Singapore.
                  </p>
                  <p>
                    “Everyone who pitched, everyone who presented, it was quite
                    good quality content. But of course there needs to be only
                    one winner, and the judges have decided to award this year's
                    Judges' Choice award to PawnHero." concluded Minette
                    Navarrete, CEO of Kickstart Ventures, one of the five judges
                    for Echelon Asia Summit.
                  </p>
                </section>
              </div>
              <WhyPawnhero />
            </div>
          </ContainerStyled>
        </PolicyStyled>
      </Layout>
    </>
  );
};

export default PawnhHeroEchelon;
