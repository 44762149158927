import React, { Fragment } from 'react';
import Layout from '../layout';
import { FaqStyled } from '../../styles/faq.style';
import { ContainerStyled } from '../../styles/layout.style';
import Collapse from './collapse';
import { FAQ_data } from '../../../actions/global';

const FaqPage = (props) => {
  const _half = Math.ceil(FAQ_data.length / 2);
  const _lefFAQ = FAQ_data.slice(0, _half);
  const _rightFAQ = FAQ_data.slice(_half);

  return (
    <>
      <Layout>
        <FaqStyled>
          <section>
            <ContainerStyled>
              <div>
                <h1>Frequently asked questions</h1>
                <p>
                  We would love to answer all your questions to improve your
                  pawning experience
                </p>
              </div>
              <div className='items-wrapper'>
                <div className='column-wrapper'>
                  {_lefFAQ.map((e, i) => (
                    <Fragment key={i}>
                      <Collapse title={e.title} className='faq-collapse'>
                        {e.contentFaq ? e.contentFaq() : e.content()}
                      </Collapse>
                    </Fragment>
                  ))}
                </div>
                <div className='column-wrapper'>
                  {_rightFAQ.map((e, i) => (
                    <Fragment key={i}>
                      <Collapse title={e.title} className='faq-collapse'>
                        {e.contentFaq ? e.contentFaq() : e.content()}
                      </Collapse>
                    </Fragment>
                  ))}
                </div>
              </div>
            </ContainerStyled>
          </section>
          <section>
            <ContainerStyled>
              <h2>Here are some videos to help you with the basics</h2>

              <div className='videos-wrapper'>
                <div className='video-wrapper'>
                  <iframe
                    title='13 Best Items to Pawn for Instant Cash!'
                    src='https://www.youtube.com/embed/hg2567sWf4I'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen></iframe>
                </div>
                <div className='video-wrapper'>
                  <iframe
                    title='Advantages of Pawning with PawnHero'
                    src='https://www.youtube.com/embed/nl-uNQ915wE'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen></iframe>
                </div>
                <div className='video-wrapper'>
                  <iframe
                    title='How to Get High Appraisal'
                    src='https://www.youtube.com/embed/92CHsoaz0AA'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen></iframe>
                </div>
              </div>

              <p>
                If you need more help, you can call us at <span>8828–CASH</span>
                , or send us your inquiry here.
              </p>
            </ContainerStyled>
          </section>
        </FaqStyled>
      </Layout>
    </>
  );
};

export default FaqPage;
