import { UPDATE_LOAN, RESET_LOAN } from '../actions/loan';
import update from 'immutability-helper';

const loan = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOAN:
      return update(state, { $merge: action.loan });
    case RESET_LOAN:
      return {};
    default:
      return state;
  }
};

export default loan;
